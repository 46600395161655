import {
  TemplatesFilter,
  TemplatesType,
} from '@erp_core/erp-types/dist/modules/communication';
import { TemplateHttpRepo } from '../datalayer/repository/http/admin/communication/template';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { TemplateInterface } from '../models/interfaces/admin/communication/template';
import { erpV2Api } from './admin';
import { COMMUNICATION_SERVER_URI } from './server-uri';

export const communcationTemplateService = new TemplateHttpRepo(
  COMMUNICATION_SERVER_URI,
  erpV2Api
);

export const useTemplate = createUseResource<
  TemplatesType,
  TemplatesFilter,
  TemplateInterface
>({
  useService: () => communcationTemplateService,
});

export const useTemplates = createUseResources<
  TemplatesType,
  TemplatesFilter,
  TemplateInterface
>({
  useService: () => communcationTemplateService,
});
