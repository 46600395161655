/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';

import { useEffect } from 'react';
import { UseUsers } from '../../../hooks/admin/user-admin/use-users-admin';
import { UseSalesOrders } from '../../../hooks/order/sales/order/use-orders';
import { UsePaginatedSalesOrders } from '../../../hooks/order/sales/order/use-paginated-orders';
import { UserRendererInterface } from '../../common/fragments/user';

type Props = {
  id: string;
  useSalesOrders: UseSalesOrders;
  usePaginatedSalesOrders: UsePaginatedSalesOrders;
  useUsers: UseUsers;
  userRendererService: UserRendererInterface;
};

function renderClientSalesOrderDetails({
  id,
  useSalesOrders,
  useUsers,
  userRendererService,
}: Props) {
  const Table = renderTableComponent();

  const Card = renderCardComponent();

  return function ClientSalesOrderDetails() {
    const { getAll: getAllSalesOrder, data: salesOrders } = useSalesOrders();

    useEffect(() => {}, [salesOrders]);

    useEffect(() => {
      getAllSalesOrder({ clientId: id });
      // eslint-disable-next-line
    }, []);

    const tableHeader: TableHeader = [
      [
        { name: 'Name', rowSpan: 2 },
        { name: 'Deliver By', rowSpan: 2 },
        { name: 'Items', rowSpan: 2 },
        { name: 'Remarks', rowSpan: 2 },
        { name: 'Attachments', rowSpan: 2 },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),

          rowSpan: 2,
        },
        { name: 'Status', rowSpan: 2 },
      ],
    ];

    const tableBody: TableBody =
      salesOrders?.map((l, idx) => ({
        cells: [
          { value: l.name },
          {
            value:
              l.orderItems.map((oi) => oi.deliverBy).length > 1
                ? l.orderItems
                    .map((oi) => oi.deliverBy)
                    .reduce((first, second) =>
                      first > second ? first : second
                    )
                : l.orderItems.map((oi) => oi.deliverBy)[0],
          },
          {
            value: (
              <div>
                {l.orderItems?.map((a, i) => (
                  <a key={i}>{a.item.name}, </a>
                ))}
              </div>
            ),
          },
          { value: l.details.remarks || '' },
          { value: '' },
          {
            value: (
              <userRendererService.userCard
                size='small'
                link={true}
                id={l.createdBy?.id}
                name={l.createdBy?.name}
                extraInfo={moment.utc(l.createdAt).fromNow()}
              />
            ),
          },
          { value: l.status || '' },
        ],
      })) || [];

    const cardHeader: CardHeader = {
      title: 'Sales Orders',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: <Table header={tableHeader} body={tableBody} />,
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}

export default renderClientSalesOrderDetails;
