/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import {
  AttendanceStatus,
  AttendanceType,
  CombinedAttDataType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import toast from 'react-hot-toast';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import {
  AttendanceFilter,
  AttendanceInterface,
  EmployeePendingActionsRes,
  GetMonthlySummary,
  GetMonthlySummaryReq,
} from '../../../../../models/interfaces/hrd/attendance';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class AttendanceHttpRepo
  extends GenericHttpWithLogsRepo<AttendanceType, AttendanceFilter>
  implements AttendanceInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/attendance',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getEmployeeBook(
    month: string,
    employeeId: string
  ): Promise<Array<CombinedAttDataType>> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/employee/${employeeId}/month/${month}`
    );
    if (res.status === 200) {
      const { data } = res;
      return data as Promise<Array<CombinedAttDataType>>;
    }

    return [];
  }

  async getAttendanceByDate({
    date,
    location,
  }: {
    date: string;
    location: string;
  }): Promise<Array<CombinedAttDataType>> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/date/${date}/location/${location}`
    );
    if (res.status === 200) {
      const { data } = res;
      return data as Array<CombinedAttDataType>;
    }

    return [] as Array<CombinedAttDataType>;
  }

  async getMonthlySummary(p: GetMonthlySummaryReq): Promise<GetMonthlySummary> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/summary/${p?.id}?date=${p?.query?.date}`
    );
    if (res.status === 200) {
      const { data } = res;
      return data as GetMonthlySummary;
    }

    return {} as GetMonthlySummary;
  }

  async getSummary(month: string): Promise<any> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/summary?month=${month}`
    );
    if (res.status === 200) {
      const { data } = res;
      return data as any;
    }

    return {} as any;
  }

  async getPendingActions(month: string): Promise<any> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/pending-actions/${month}`
    );
    if (res.status === 200) {
      const { data } = res;
      return data as any;
    }

    return {} as any;
  }

  async getEmployeePendingActions({
    month,
    employee,
    toRefresh,
  }: {
    month: string;
    employee: string;
    toRefresh?: string;
  }): Promise<Array<EmployeePendingActionsRes>> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    let empPendingActnsURL = '';
    if (toRefresh) {
      empPendingActnsURL = `${this.resourceEndpoint}/pending-actions/${month}/employee/${employee}?refresh=${toRefresh}`;
    } else {
      empPendingActnsURL = `${this.resourceEndpoint}/pending-actions/${month}/employee/${employee}`;
    }

    const res = await httpInstance.get(empPendingActnsURL);
    if (res.status === 200) {
      const { data } = res;
      return data as Array<EmployeePendingActionsRes>;
    }
    return [] as Array<EmployeePendingActionsRes>;
  }

  async finalize(props: {
    summary: any;
    employeeId: string;
    date: string;
    attendanceId: string;
    companyGroupId: string;
    status: AttendanceStatus;
  }): Promise<any> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/finalize`,
      props
    );

    if (res.status === 200) {
      const { data } = res;
      return data as any;
    }

    return {} as any;
  }

  async autofinalize(date: string): Promise<any> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/auto-finalize?date=${date}`
    );
    if (res.status === 200) {
      const { data } = res;
      return data as any;
    }

    return {} as any;
  }

  async autoIgnorePunches(date: string, persist: boolean): Promise<any> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/ignore-punches/${date}`,
      { persist }
    );
    if (res.status === 200) {
      const { data } = res;
      return data as any;
    }

    return {} as any;
  }

  async autofinalizeEmployee(empId: string, date: string): Promise<any> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/auto-finalize/employee/${empId}/date/${date}`
    );
    if (res.status === 200) {
      const { data } = res;
      return data as any;
    }

    return {} as any;
  }

  async addManualPunch(u: {
    punch: any;
    id?: string;
    employee: IdName;
    date: string;
    employeeCompany: string;
  }): Promise<{ success: boolean; message?: string }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/add-manual-punch`,
      u
    );

    if (res.status === 200) {
      const { data } = res;

      if (data.success) {
        toast.success(data.message || 'Punch added successfully');
      } else {
        toast.error(data.message);
      }
      return data as any;
    }

    return {} as any;
  }

  async substituteLeave(u: {
    attendanceId: string;
    substituteBy: 'compensatory-off';
    reason: string;
  }): Promise<{
    status: boolean;
    reason?: string;
    attendance?: AttendanceType;
  }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/substitute-leave`,
      {
        attendanceId: u.attendanceId,
        substituteBy: u.substituteBy,
        reason: u.reason,
      }
    );

    if (res.status === 200) {
      const { data } = res;
      return data as any;
    }

    return {} as any;
  }

  async emailDailyReport(date: string): Promise<any> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/daily-report?date=${date}`
    );
    if (res.status === 200) {
      const { data } = res;
      return data as any;
    }

    return {} as any;
  }
}
