import { PayrollReport } from '../../../../../hooks/analytics/hrd-dashboard-report/use-hrd-dashboard-report';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { HrdDashboardReportInterface } from '../../../../../models/interfaces/analytics/hrd-dashboard-report/report';
import {
  HrdDashboardReportFilter,
  HrdDashboardReportQuery,
  HrdDashboardReportType,
} from '../../../../../models/types/analytics/hrd-dashboard';
import { GenericHttpRepo } from '../../generic-resource';

export class HttpHrdDashboardReportRepo
  extends GenericHttpRepo<HrdDashboardReportType, HrdDashboardReportFilter>
  implements HrdDashboardReportInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/hrd/hrd-dashboard',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getReport(data: HrdDashboardReportQuery) {
    const http = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.apiUrl,
    });
    const result = await http.post(this.resourceEndpoint, data);
    return result.data;
  }

  async getPayrollReport(month: string, report: PayrollReport): Promise<any> {
    const http = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.apiUrl,
    });
    const result = await http.get(
      `/api/v1/hrd/payroll/${month}/reports/${report}`
    );
    return result.data;
  }
}
