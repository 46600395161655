import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderPureHistory,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseChangeAssesments } from '../../../../hooks/quality-assurance/change-assesment/use-change-assesments';

type CreateRequisionDtlsCardType = {
  useChangeAssesments: UseChangeAssesments;
};

export function renderRequisitionDetails({
  useChangeAssesments,
}: CreateRequisionDtlsCardType): () => JSX.Element {
  return function RequisitionDetails(): JSX.Element {
    const History = renderPureHistory();

    const { id } = useParams();

    const {
      data: changeAssesments,
      getAll: getChangeAssesments,
    } = useChangeAssesments();

    useEffect(() => {}, [changeAssesments]);

    useEffect(() => {
      getChangeAssesments();

      // eslint-disable-next-line
    }, []);

    const comments = changeAssesments?.find((x) => x.id === id);
    type commentsType = {
      name: string;
      createdAt: string;
    };
    let records: Array<commentsType> = [];
    if (comments?.details.crossFunctionalTeam) {
      for (let k = 0; k < comments?.details.crossFunctionalTeam?.length; k++) {
        if (comments?.details.deptComments) {
          for (let p = 0; p < comments?.details.deptComments.length; p++) {
            if (
              comments?.details.crossFunctionalTeam[k].dept.id ===
              comments?.details.deptComments[p].dept.id
            ) {
              records.push({
                name:
                  comments?.details.deptComments[p].dept.name +
                  ': -' +
                  comments?.details.deptComments[p].comments +
                  ' commented by ' +
                  comments?.details.deptComments[p].commentedBy.name,
                createdAt: comments?.details.deptComments[p].createdAt,
              });
              break;
            } else if (
              comments?.details.crossFunctionalTeam[k].dept.id !==
              comments?.details.deptComments[p].dept.id
            ) {
              records.push({
                name:
                  comments?.details.crossFunctionalTeam[p].dept.name +
                  ': - comments pending',
                createdAt: '',
              });
              records.push();
              break;
            }
          }
        }
      }
    }

    console.log('Records', records);

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Departmental Comments',
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <History
          logs={
            records?.map((t) => ({
              event: `${t.name}`,
              time: t.createdAt,
              action: t.createdAt,
              details: '',
            })) || []
          }
        />
      ),
    };

    return (
      <div className='w-full p-4'>
        <Card header={header} body={body} />
      </div>
    );
  };
}
