import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseStockGroups } from '../../../../hooks/inventory/stockgroup/use-stockgroups';

type RenderAddStockGroupFormProps = {
  onSave: (form: StockGroupFormType) => Promise<void>;
  useStockGroups: UseStockGroups;
};

type StockGroupFormType = {
  name: string;
  parentStockGroup: { id: string; name: string };
  description: string;
  targetDate: string;
};

export function renderAddStockGroupForm({
  onSave,
  useStockGroups,
}: RenderAddStockGroupFormProps): () => JSX.Element {
  return function PurchaseRequisitionEditForm(): JSX.Element {
    const onSubmit = async (form: Partial<StockGroupFormType>) => {
      await onSave(form as StockGroupFormType);
    };

    const formProps: FormProps<Partial<StockGroupFormType>> = {
      fieldsData: [
        { property: 'name', label: ' Name:', type: 'input', required: true },
        {
          property: 'description',
          label: 'Description',
          type: 'input',
          required: true,
        },
        {
          property: 'parentStockGroup',
          label:
            'Parent Stock Group (Do not add if want to create root stockgroup)',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useStockGroups,
            onSearchValueSelect(u) {},
          },
          required: true,
        },
      ],
      allowUnchanged: true,
      initialFormState: {
        name: '',
        description: '',
        parentStockGroup: { id: '', name: '' },
      },
      mapTToU: mapPFDToP,
      onSubmit: onSubmit,
    };

    const Form = renderFormV2<Partial<StockGroupFormType>>(formProps);

    return (
      <div>
        <Form />
      </div>
    );

    function mapPFDToP(
      b: FormDataType<Partial<StockGroupFormType>>
    ): StockGroupFormType {
      return b as StockGroupFormType;
    }
  };
}
