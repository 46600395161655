/* eslint-disable react-hooks/exhaustive-deps */
import {
  ColorFilterType,
  ColorType,
} from '@erp_core/erp-types/dist/modules/constants';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseColor } from '../../../hooks/admin/constants/color/use-color';
import { UseColors } from '../../../hooks/admin/constants/color/use-colors';
import { UsePaginatedColors } from '../../../hooks/admin/constants/color/use-paginated-color';
import { renderAddColorForm } from './form/add-color-form';

export type RenderColorProps = {
  useColors: UseColors;
  usePaginatedColors: UsePaginatedColors;
  useColor: UseColor;
};

export function renderColor({
  useColors,
  usePaginatedColors,
  useColor,
}: RenderColorProps): () => JSX.Element {
  return function Color(): JSX.Element {
    const Table = renderTableWithMapperComponent<ColorType, ColorFilterType>();
    const { getAll: getColors } = useColors();

    const { syncSet: setColor } = useColor();

    useEffect(() => {
      getColors();
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Color',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Color',
            behaviour: 'modal',
            modal: {
              title: 'Add Color',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveColor(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddColorForm();

    const saveColor = async (form: Partial<ColorType>) => {
      try {
        const finalData = {
          ...form,
        } as ColorType;
        await setColor(finalData as ColorType);
        toast('Data added sucessfully');
        getColors();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [[{ name: 'Name' }]];

    const bodyMapper = (r: ColorType) => {
      const cells: Array<TableCell> = [{ value: r.name }];
      return {
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full h-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              useResources={useColors}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedColors,
              }}
            />
          </div>
        </div>
      ),
    };
    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
