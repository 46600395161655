import {
  Daybook,
  DaybookFilterType,
  VouchersType,
  VoucherTransactionsType,
} from '@erp_core/erp-types/dist/modules/accounts';
import { HttpDaybookRepo } from '../datalayer/repository/http/accounts/daybook';
import { HttpAccountGroupRepo } from '../datalayer/repository/http/accounts/tally/account-groups';
import { HttpLedgerRepo } from '../datalayer/repository/http/accounts/tally/ledger';
import { HttpVoucherTransactionsRepo } from '../datalayer/repository/http/accounts/vouchers/voucher-transactions';
import { HttpVouchersRepo } from '../datalayer/repository/http/accounts/vouchers/vouchers';
import { createUsePaginatedResources } from '../hooks/resource/use-paginated-resources';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { DaybookInterface } from '../models/interfaces/accounts/daybook';
import { AccountGroupInterface } from '../models/interfaces/accounts/tally/account-group';
import { LedgerInterface } from '../models/interfaces/accounts/tally/ledger';
import { VoucherTransactionsInterface } from '../models/interfaces/accounts/vouchers/voucher-transactions';
import { VouchersInterface } from '../models/interfaces/accounts/vouchers/vouchers';
import {
  AccountGroup,
  AccountGroupFilterType,
} from '../models/types/accounts/account-group';
import { Ledger, LedgerFilterType } from '../models/types/accounts/ledger';
import { VoucherTransactionsFilterType } from '../models/types/accounts/voucher-transactions';
import { VouchersFilterType } from '../models/types/accounts/vouchers';
import { erpV2Api } from './admin';
import { ACCOUNTS_SERVER_URI } from './server-uri';

export const daybookService = new HttpDaybookRepo(
  ACCOUNTS_SERVER_URI,
  erpV2Api
);
export const useDaybook = createUseResource<
  Daybook,
  DaybookFilterType,
  DaybookInterface
>({ useService: () => daybookService });

export const useDaybooks = createUseResources<
  Daybook,
  DaybookFilterType,
  DaybookInterface
>({ useService: () => daybookService });

export const usePaginatedDaybook = createUsePaginatedResources<
  Daybook,
  DaybookFilterType,
  DaybookInterface
>({
  useService: () => daybookService,
});

export const accountGroupService = new HttpAccountGroupRepo(
  ACCOUNTS_SERVER_URI,
  erpV2Api
);
export const useAccountGroup = createUseResource<
  AccountGroup,
  AccountGroupFilterType,
  AccountGroupInterface
>({ useService: () => accountGroupService });

export const useAccountGroups = createUseResources<
  AccountGroup,
  AccountGroupFilterType,
  AccountGroupInterface
>({ useService: () => accountGroupService });

export const ledgerService = new HttpLedgerRepo(ACCOUNTS_SERVER_URI, erpV2Api);
export const useLedger = createUseResource<
  Ledger,
  LedgerFilterType,
  LedgerInterface
>({ useService: () => ledgerService });

export const useLedgers = createUseResources<
  Ledger,
  LedgerFilterType,
  LedgerInterface
>({ useService: () => ledgerService });

export const voucherService = new HttpVouchersRepo(
  ACCOUNTS_SERVER_URI,
  erpV2Api
);
export const useVoucher = createUseResource<
  VouchersType,
  VouchersFilterType,
  VouchersInterface
>({ useService: () => voucherService });

export const useVouchers = createUseResources<
  VouchersType,
  VouchersFilterType,
  VouchersInterface
>({ useService: () => voucherService });

export const voucherTransactionService = new HttpVoucherTransactionsRepo(
  ACCOUNTS_SERVER_URI,
  erpV2Api
);
export const useVoucherTransaction = createUseResource<
  VoucherTransactionsType,
  VoucherTransactionsFilterType,
  VoucherTransactionsInterface
>({ useService: () => voucherTransactionService });

export const useVoucherTransactions = createUseResources<
  VoucherTransactionsType,
  VoucherTransactionsFilterType,
  VoucherTransactionsInterface
>({ useService: () => voucherTransactionService });
