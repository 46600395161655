import {
  EmployeeShiftScheduleFilterType,
  EmployeeShiftScheduleType,
  LocationHolidayFilterType,
  LocationHolidayType,
  LogRecordFilterType,
  LogRecordsType,
  Machine,
  MachineFilterType,
  MachineSchedule,
  MachineScheduleFilter,
  MachineShift,
  MachineShiftFilterType,
  RotationalShiftDayType,
  ShiftScheduleFilterType,
  ShiftScheduleType,
  SwitchShiftScheduleType,
} from '@erp_core/erp-types/dist/modules/planning';
import { RotationalShiftDayFilter } from '@erp_core/erp-types/dist/types/modules/planning/attendance/rotational-shift-day';
import {
  RotationalShiftScheduleFilter,
  RotationalShiftScheduleType,
} from '@erp_core/erp-types/dist/types/modules/planning/attendance/rotational-shift-schedule';
import { RotationalShiftDayHttpRepo } from '../datalayer/repository/http/hrd/rotational-shift-day';
import { RotationalShiftScheduleHttpRepo } from '../datalayer/repository/http/hrd/rotational-shift-schedule';
import { SwitchShiftScheduleHttpRepo } from '../datalayer/repository/http/hrd/switch-shift-schedule';
import { HttpEmpShiftScheduleRepo } from '../datalayer/repository/http/planning/emp-shift-schedule';
import { HttpLocationHolidaysRepo } from '../datalayer/repository/http/planning/location-holidays';
import { HttpLogRecordRepo } from '../datalayer/repository/http/planning/log-record';
import { HttpMachineRepo } from '../datalayer/repository/http/planning/production/machine';
import { HttpMachineScheduleRepo } from '../datalayer/repository/http/planning/production/machine-schedule';
import { HttpMachineShiftRepo } from '../datalayer/repository/http/planning/production/machine-shift';
import { HttpShiftScheduleRepo } from '../datalayer/repository/http/planning/shift-schedule';
import { createGetMachineScheduleHook } from '../hooks/planning/production/machine-schedule';
import { createUsePaginatedResources } from '../hooks/resource/use-paginated-resources';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { RotationalShiftDayInterface } from '../models/interfaces/hrd/rotational-shift-day';
import { RotationalShiftScheduleInterface } from '../models/interfaces/hrd/rotational-shift-schedule';
import {
  SwitchShiftSchduleInterface,
  SwitchShiftScheduleFilter,
} from '../models/interfaces/hrd/switch-shift-schedule';
import { EmpShiftScheduleInterface } from '../models/interfaces/planning/emp-shift-schedule';
import { LocationHolidayInterface } from '../models/interfaces/planning/location-holidays';
import { LogRecordInterface } from '../models/interfaces/planning/log-record';
import { MachineInterface } from '../models/interfaces/planning/production/machine';
import { MachineScheduleInterface } from '../models/interfaces/planning/production/machine-schedule';
import { MachineShiftInterface } from '../models/interfaces/planning/production/machine-shift';
import { ShiftScheduleInterface } from '../models/interfaces/planning/shift-schedule';
import { erpV2Api } from './admin';
import { PLANNING_SERVER_URI } from './server-uri';

export const rotationalShiftScheduleService = new RotationalShiftScheduleHttpRepo(
  PLANNING_SERVER_URI,
  erpV2Api
);
export const useRotationalShiftSchedules = createUseResources<
  RotationalShiftScheduleType,
  RotationalShiftScheduleFilter,
  RotationalShiftScheduleInterface
>({
  useService: () => rotationalShiftScheduleService,
});

export const useRotationalShiftSchedule = createUseResource<
  RotationalShiftScheduleType,
  RotationalShiftScheduleFilter,
  RotationalShiftScheduleInterface
>({
  useService: () => rotationalShiftScheduleService,
});

export const rotationalShiftDayService = new RotationalShiftDayHttpRepo(
  PLANNING_SERVER_URI,
  erpV2Api
);
export const useRotationalShiftDay = createUseResource<
  RotationalShiftDayType,
  RotationalShiftDayFilter,
  RotationalShiftDayInterface
>({
  useService: () => rotationalShiftDayService,
});
export const useRotationalShiftDays = createUseResources<
  RotationalShiftDayType,
  RotationalShiftDayFilter,
  RotationalShiftDayInterface
>({
  useService: () => rotationalShiftDayService,
});

export const switchShiftScheduleService = new SwitchShiftScheduleHttpRepo(
  PLANNING_SERVER_URI,
  erpV2Api
);
export const useSwitchShift = createUseResource<
  SwitchShiftScheduleType,
  SwitchShiftScheduleFilter,
  SwitchShiftSchduleInterface
>({
  useService: () => switchShiftScheduleService,
});

export const useSwitchShifts = createUseResources<
  SwitchShiftScheduleType,
  SwitchShiftScheduleFilter,
  SwitchShiftSchduleInterface
>({
  useService: () => switchShiftScheduleService,
});

export const shiftScheduleService = new HttpShiftScheduleRepo(
  PLANNING_SERVER_URI,
  erpV2Api
);
export const useShiftSchedule = createUseResource<
  ShiftScheduleType,
  ShiftScheduleFilterType,
  ShiftScheduleInterface
>({
  useService: () => shiftScheduleService,
});

export const useShiftSchedules = createUseResources<
  ShiftScheduleType,
  ShiftScheduleFilterType,
  ShiftScheduleInterface
>({
  useService: () => shiftScheduleService,
});

export const empShiftScheduleService = new HttpEmpShiftScheduleRepo(
  PLANNING_SERVER_URI,
  erpV2Api
);
export const useEmpShiftSchedule = createUseResource<
  EmployeeShiftScheduleType,
  EmployeeShiftScheduleFilterType,
  EmpShiftScheduleInterface
>({
  useService: () => empShiftScheduleService,
});

export const useEmpShiftSchedules = createUseResources<
  EmployeeShiftScheduleType,
  EmployeeShiftScheduleFilterType,
  EmpShiftScheduleInterface
>({
  useService: () => empShiftScheduleService,
});

export const locationHolidaysService = new HttpLocationHolidaysRepo(
  PLANNING_SERVER_URI,
  erpV2Api
);
export const useLocationHoliday = createUseResource<
  LocationHolidayType,
  LocationHolidayFilterType,
  LocationHolidayInterface
>({
  useService: () => locationHolidaysService,
});

export const useLocationHolidays = createUseResources<
  LocationHolidayType,
  LocationHolidayFilterType,
  LocationHolidayInterface
>({
  useService: () => locationHolidaysService,
});

export const logRecordService = new HttpLogRecordRepo(
  PLANNING_SERVER_URI,
  erpV2Api
);
export const useLogRecords = createUseResources<
  LogRecordsType,
  LogRecordFilterType,
  LogRecordInterface
>({
  useService: () => logRecordService,
});

export const usePaginatedLogRecords = createUsePaginatedResources<
  LogRecordsType,
  LogRecordFilterType,
  LogRecordInterface
>({
  useService: () => logRecordService,
});

export const machineShiftService = new HttpMachineShiftRepo(
  PLANNING_SERVER_URI,
  erpV2Api
);
export const useMachineShifts = createUseResources<
  MachineShift,
  MachineShiftFilterType,
  MachineShiftInterface
>({ useService: () => machineShiftService });

export const machineScheduleService = new HttpMachineScheduleRepo(
  PLANNING_SERVER_URI,
  erpV2Api
);
export const useMachineSchedule = createUseResource<
  MachineSchedule,
  MachineScheduleFilter,
  MachineScheduleInterface
>({ useService: () => machineScheduleService });
export const useMachineSchedules = createUseResources<
  MachineSchedule,
  MachineScheduleFilter,
  MachineScheduleInterface
>({ useService: () => machineScheduleService });
export const useGetMachineSchedule = createGetMachineScheduleHook({
  useService: () => machineScheduleService,
});

export const machineService = new HttpMachineRepo(
  PLANNING_SERVER_URI,
  erpV2Api
);
export const useMachines = createUseResources<
  Machine,
  MachineFilterType,
  MachineInterface
>({ useService: () => machineService });

export const useMachine = createUseResource<
  Machine,
  MachineFilterType,
  MachineInterface
>({ useService: () => machineService });
