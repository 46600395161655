import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { Client } from '@erp_core/erp-types/dist/modules/order';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  CardBody,
  CardHeader,
  ColumnizedContent,
  renderCardComponent,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { deliveryModesData } from '../../../datalayer/repository/in-memory/admin/delivery-mode';
import { UseDeliveryTerms } from '../../../hooks/constants/delivery-term/use-delivery-terms';
import { UsePaymentTerms } from '../../../hooks/constants/payment-term/use-payment-terms';

type CreateItemCardType = {
  client: Client;
  loading: boolean;
  setClient: (s: Client) => Promise<Client>;
  usePaymentTerms: UsePaymentTerms;
  useDeliveryTerms: UseDeliveryTerms;
};

export function renderClientTerms({
  client,
  loading,
  setClient,
  useDeliveryTerms,
  usePaymentTerms,
}: CreateItemCardType) {
  return function ClientTerms(): JSX.Element {
    const onEditVendorTerms = async (f: any) => {
      f.modeOfTransport = deliveryModesData.find(
        (i) => i.id === f.modeOfTransport
      );
      const saveData = { id: client.id, details: client?.details };
      saveData.details = {
        ...saveData.details,
        termsOfBusiness: {
          ...f,
        },
      };
      await setClient(saveData as Client);
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: `Terms of Business: ${client.name}`,
      actions: [
        {
          type: 'button',
          show: () => !loading,
          button: {
            name: 'Edit',
            behaviour: 'modal',
            modal: {
              title: 'Edit Vendor Terms',
              content: ({ onClose }) => {
                const Form = renderFormV2({
                  fieldsData: [
                    {
                      property: 'paymentTerms',
                      label: 'Payment Term',
                      searchOptions: {
                        useSearch: usePaymentTerms,
                        onSearchValueSelect(u) {},
                      },
                      type: 'searchable-select',
                      required: true,
                    },
                    {
                      property: 'creditPeriod',
                      label: 'Credit Period',
                      type: 'number',
                      required: true,
                    },
                    {
                      property: 'deliveryTerms',
                      label: 'Delivery Term',
                      type: 'searchable-select',
                      searchOptions: {
                        useSearch: useDeliveryTerms,
                        onSearchValueSelect(u) {},
                      },
                      required: true,
                    },
                    {
                      property: 'modeOfTransport',
                      label: 'Mode Of Transport',
                      type: 'select',
                      options: deliveryModesData.map((d) => ({
                        text: d.name,
                        value: d.id,
                      })),
                      required: true,
                    },
                    {
                      property: 'preCarraigeBy',
                      label: 'Pre Carraige By',
                      type: 'select',
                      options: deliveryModesData.map((d) => ({
                        text: d.name,
                        value: d.name,
                      })),
                      required: true,
                    },
                    {
                      property: 'placeOfReceiptByCarrier',
                      label: 'Place Of Receipt By Carrier',
                      type: 'input',
                      required: true,
                    },
                  ],
                  initialFormState: {
                    paymentTerms: (client?.details?.termsOfBusiness
                      ?.paymentTerms as IdName) || { id: '', name: '' },
                    creditPeriod:
                      client?.details?.termsOfBusiness?.creditPeriod || '',
                    deliveryTerms:
                      (client?.details?.termsOfBusiness
                        ?.deliveryTerms as IdName) || '',
                    modeOfTransport:
                      client?.details?.termsOfBusiness?.modeOfTransport?.id ||
                      '',
                    preCarraigeBy:
                      client?.details?.termsOfBusiness?.preCarraigeBy || '',
                    placeOfReceiptByCarrier:
                      client?.details?.termsOfBusiness
                        ?.placeOfReceiptByCarrier || '',
                  },
                  style: 'md:w-1/2',
                  button: {
                    style: 'w-fit',
                  },
                  onSubmit: async (f) => {
                    onEditVendorTerms(f);
                    onClose();
                  },
                  allowUnchanged: true,
                  mapTToU(p) {
                    return (p as unknown) as any;
                  },
                });

                return <Form />;
              },
            },
          },
        },
      ],
    };

    const body: ColumnizedContent = [
      [
        {
          key: 'Payment Terms:',
          value: client?.details?.termsOfBusiness?.paymentTerms?.name || '',
          type: 'text',
        },
        {
          key: 'Credit Period, Days:',
          value:
            client?.details?.termsOfBusiness?.creditPeriod?.toString() || '0',
          type: 'text',
        },
      ],
      [
        {
          key: 'Delivery Terms:',
          value: client?.details?.termsOfBusiness?.deliveryTerms?.name || '',
          type: 'text',
        },
        {
          key: 'Mode of Transport:',
          value: client?.details?.termsOfBusiness?.modeOfTransport?.name || '',
          type: 'text',
        },
      ],
      [
        {
          key: 'Pre-Carraige By:',
          value: client?.details?.termsOfBusiness?.preCarraigeBy || '',
          type: 'text',
        },
        {
          key: 'Place of Receipt by Carrier:',
          value:
            client?.details?.termsOfBusiness?.placeOfReceiptByCarrier || '',
          type: 'text',
        },
      ],
    ];

    const cardBody: CardBody = {
      type: 'columns',
      body,
    };
    if (loading)
      return (
        <div className='w-full h-36 flex justify-center items-center'>
          <Recat className='h-5 inline animate-pulse mx-4' />
        </div>
      );
    return (
      <div className='w-full space-y-3'>
        <Card body={cardBody} header={cardHeader} />
      </div>
    );
  };
}
