import { BomRegister } from '@erp_core/erp-icons/icons/web/bom-register';
import { TaskType } from '@erp_core/erp-types/dist/modules/admin';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import cronstrue from 'cronstrue';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UseTask } from '../../../../hooks/admin/schedular-task/use-task';
import { UseTasks } from '../../../../hooks/admin/schedular-task/use-tasks';
import { UserRendererInterface } from '../../../../modules/common/fragments/user';
import { renderAddEditTaskForm } from './form/add-edit-task-form';

type RenderSchedulerTask = {
  useTasks: UseTasks;
  useTask: UseTask;
  userRendererService: UserRendererInterface;
};
export const renderSchedulerTask = ({
  useTasks,
  useTask,
  userRendererService,
}: RenderSchedulerTask) => {
  return function SchedulerTask() {
    const { user: currentUser } = useContext(UserContext);

    const { companyGroup } = useContext(CurrentContext);

    const { data: tasks, getAll: getTasks } = useTasks();

    const { syncSet: setTask } = useTask();

    useEffect(() => {}, [tasks]);

    useEffect(() => {
      getTasks();
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Tasks',
      icon: (
        <BomRegister className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Task',
            behaviour: 'modal',
            modal: {
              title: 'Add Task',
              content: ({ onClose }) => {
                const Form = renderAddEditTaskForm();
                return (
                  <Form
                    onSave={async (form) => {
                      await saveTask(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const saveTask = async (form: Partial<TaskType>) => {
      try {
        const finalData = {
          ...form,
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as TaskType;
        await setTask(finalData as TaskType);
        toast('Data added sucessfully');
        getTasks();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Resource' },
        { name: 'Cron Expression' },
        { name: 'Forecast Range' },
        { name: 'Status' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    const tableBody: TableBody =
      tasks?.map((l) => ({
        rowData: { task: l },
        cells: [
          { value: l.name, link: `/system/scheduler/tasks/${l.id}/jobs` },
          { value: l.jobDetails.resource || '-' },
          {
            value: (
              <div>
                <div className='font-bold'>{l.cronExpression}</div>
                <div className='italic'>
                  {cronstrue.toString(l.cronExpression, {
                    verbose: true,
                    tzOffset: 5.5,
                  })}
                </div>
              </div>
            ),
          },
          { value: <>{l.forecastRange} days</> },
          { value: l.status },
          {
            value: (
              <userRendererService.userCard
                size='small'
                id={l.createdBy?.id}
                name={l.createdBy?.name}
                extraInfo={moment.utc(l.createdAt).fromNow()}
                link={true}
              />
            ),
          },
          {
            value: (
              <userRendererService.userCard
                size='small'
                id={l.lastModifiedBy?.id}
                name={l.lastModifiedBy?.name}
                extraInfo={moment.utc(l.lastModifiedAt).fromNow()}
                link={true}
              />
            ),
          },
        ],
      })) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Edit',
                  behaviour: 'modal',
                  show: () => true,
                  modal: {
                    title: 'Edit',
                    content: ({ data: { task }, onClose }) => {
                      const Form = renderAddEditTaskForm();
                      return (
                        <Form
                          task={task}
                          onSave={async (form) => {
                            await saveTask(form);
                            onClose();
                          }}
                        />
                      );
                    },
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
};
