/* eslint-disable no-unused-vars */
import {
  GodownTransfer,
  ItemBatchGodown,
} from '@erp_core/erp-types/dist/modules/inventory';
import { LoadingButton } from '@erp_core/erp-ui-components';
import { CubeIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { UseGodownTransfer } from '../../../../../hooks/inventory/godown-transfer/use-godown-transfer';
import { UseBatchItemGodown } from '../../../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UseBatchItemGodowns } from '../../../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { MRSType } from '../../../../../hooks/inventory/mrs/use-mrs';

/* eslint-disable no-unused-vars */
function CollectForm({
  formData,
  onCancel,
  onSave,
  useBatchItemGodowns,
  useBatchItemGodown,
  useGodownTransfer,
}: {
  formData: MRSType;
  onCancel: any;
  onSave: any;
  useBatchItemGodowns: UseBatchItemGodowns;
  useBatchItemGodown: UseBatchItemGodown;
  useGodownTransfer: UseGodownTransfer;
}) {
  const [userAllotedErrors, setUserAllotedErrors] = useState<
    Array<{
      error: boolean;
      reason: string;
    }>
  >([]);

  const [userAllotedValues, setUserAllotedValues] = useState<
    Array<{
      id: string;
      // bomId: string;
      quantity: number;
    }>
  >([]);

  const { data: batchItems, getAll } = useBatchItemGodowns();
  const { set: setBatchItem } = useBatchItemGodown();
  const { set: setGodownTransfer } = useGodownTransfer();

  const totalAllocatedQuantity = userAllotedValues?.reduce(
    (prev, curr) => prev + curr.quantity,
    0
  );
  const remainingQuantity = formData?.physicalStock - totalAllocatedQuantity;

  const handleSaveClick = () => {
    userAllotedValues
      .filter((u) => u.quantity !== 0)
      .forEach((v) => {
        const updatedBatchItem = {
          id: v.id,
          quantity:
            (batchItems?.find((i) => i.id === v.id)?.quantity || 0) -
            v.quantity,
        };
        setBatchItem((updatedBatchItem as unknown) as ItemBatchGodown);

        const godownTransferData = {
          from: batchItems?.find((i) => i.id === v.id)?.godown.id,
          to: formData?.bom?.details?.dispatchTo,
          details: {
            item: {
              id: formData?.bom?.rawItem?.id,
              name: formData?.bom?.rawItem?.name,
            },
          },
          quantity: v.quantity,
          type: 'M_REQ',
          referenceId: formData?.bom?.id,
          // TODO: company, location. cg mapping
        };
        setGodownTransfer((godownTransferData as unknown) as GodownTransfer);
      });

    onSave();
  };

  useEffect(() => {
    getAll({
      item_id: formData?.bom?.rawItem.id,
      reference_id: formData?.bom?.id,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (batchItems) {
      const values: Array<{
        quantity: number;
        id: string;
      }> = _.times(batchItems.length).map((i) => ({
        id: batchItems[i].id,
        quantity: 0,
      }));
      setUserAllotedValues(values);

      const errors: Array<{
        error: boolean;
        reason: string;
      }> = _.times(batchItems.length).map((i) => ({
        error: false,
        reason: '',
      }));
      setUserAllotedErrors(errors);
    }
  }, [batchItems]);

  useEffect(() => {
    if (userAllotedValues.length && batchItems?.length) {
      const updatedErrors: Array<{
        error: boolean;
        reason: string;
      }> = _.times(batchItems.length).map((i) => ({
        error: false,
        reason: '',
      }));
      // eslint-disable-next-line
      let updated: boolean = false;

      userAllotedValues.forEach((val, idx) => {
        if (batchItems && val.quantity > batchItems[idx].quantity) {
          updated = true;
          updatedErrors[idx].error = true;
          updatedErrors[
            idx
          ].reason = `can't exceed ${batchItems[idx].quantity}`;
        }
      });
      setUserAllotedErrors(updatedErrors);
    }
    // eslint-disable-next-line
  }, [userAllotedValues]);

  return (
    <div className='p-5 flex flex-col space-y-4'>
      <div>
        {/* { bomData.rawItem.quantity === physicallyAllocated && <div className='flex space-x-2 justify-center'>
                <InformationCircleIcon className='w-6 h-6 text-green-400' />
                Total required Items are already allocated.
            </div> } */}
      </div>
      <div className='flex flex-row'>
        <div className='border p-1 m-1'>
          <label className='text-gray-800 text-md font-bold mb-2'>
            Available:&nbsp;
          </label>
          <span className='text-gray-500'>{formData?.physicalStock}</span>
        </div>
        <div
          className={`p-1 m-1 ${
            remainingQuantity < 0 ? 'border-2 border-red-600' : 'border'
          }`}
        >
          <label className='text-gray-800 text-md font-bold mb-2'>
            Total Allocated:&nbsp;
          </label>
          <span className='text-gray-500'>{totalAllocatedQuantity}</span>
        </div>
        <div
          className={`p-1 m-1 ${
            remainingQuantity < 0 ? 'border-2 border-red-600' : 'border'
          }`}
        >
          <label className='text-gray-800 text-md font-bold mb-2'>
            Remaining:&nbsp;
          </label>
          <span className='text-gray-500'>{remainingQuantity}</span>
        </div>
      </div>

      <form>
        <div className='flex flex-row flex-wrap'>
          {batchItems?.map((i, index) => (
            <div key={index} className='border p-1 w-1/4'>
              <div
                className={`p-1 mx-auto text-center m-1 bg-blue-100 ${
                  userAllotedErrors[index]?.error
                    ? 'border-2 border-red-600'
                    : ''
                }`}
              >
                <CubeIcon className='inline-block w-8 h-8 text-blue-500' />
                <sup className='bg-green-200 text-green-800 rounded sups text-lg'>
                  {i.quantity}
                </sup>
                <input
                  className='mb-3 ml-1 shadow appearance-none border rounded w-14  text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  id='alloted'
                  onChange={(e) => {
                    const { value } = e.target;
                    setUserAllotedValues((val) => {
                      const res = [...val];
                      res[index].quantity = parseInt(value || '0', 10);
                      return res;
                    });
                  }}
                  type='number'
                  value={userAllotedValues[index]?.quantity || 0}
                />
                {userAllotedErrors[index]?.error ? (
                  <label className='block text-red-700 text-sm font-bold mb-2'>
                    {userAllotedErrors[index]?.reason}
                  </label>
                ) : null}
              </div>

              <div className='mx-auto text-center'>
                <label className='block text-blue-600 text-md font-bold'>
                  {i.godown.name}
                </label>
              </div>
            </div>
          ))}
        </div>

        {remainingQuantity < 0 ? (
          <div className='mx-auto w-1/2 text-center'>
            <label className='block text-red-700 text-sm font-bold mb-2'>
              Total allocation cannot exceed {formData?.physicalStock}
            </label>
          </div>
        ) : null}

        <div className='mt-5'>
          Moving to godown {formData?.bom?.details?.dispatchTo}
        </div>
        <div className='flex items-center mt-12 justify-end'>
          <LoadingButton
            defaultStyle='bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            behaviorFn={onCancel}
            text='Cancel'
          />
          <button
            className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            type='button'
            disabled={!!userAllotedErrors.find((a) => a.error)}
            onClick={handleSaveClick}
          >
            Collect
          </button>
        </div>
      </form>
    </div>
  );
}

export default CollectForm;
