import {
  Godown,
  ItemBatchGodown,
  ItemRes,
} from '@erp_core/erp-types/dist/modules/inventory';
import { Grade } from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import { StockGroup } from '@erp_core/erp-types/dist/types/modules/inventory/stockgroup';
import {
  DateSelector,
  renderCardComponent,
  renderSearchBox,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseGodowns } from '../../../../hooks/inventory/godown-inventory/use-godowns';
import { UseGrades } from '../../../../hooks/inventory/grade/use-grades';
import { UseBatchItemGodowns } from '../../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UseItems } from '../../../../hooks/inventory/item/use-items';
import { UseStockGroups } from '../../../../hooks/inventory/stockgroup/use-stockgroups';

export function renderStockItemSummary({
  useStockGroups,
  useItems,
  useGrades,
  useBatches,
  useGodowns,
}: {
  useStockGroups: UseStockGroups;
  useItems: UseItems;
  useGrades: UseGrades;
  useBatches: UseBatchItemGodowns;
  useGodowns: UseGodowns;
}): () => JSX.Element {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return function StockItemSummary(): JSX.Element {
    const [level, selectLevel] = useState<
      'all' | 'item' | 'stock-group' | 'grade' | 'batch'
    >('all');
    const [view, selectView] = useState<
      'detailed' | 'monthly' | 'quarterly' | 'yearly' | 'transaction'
    >('detailed');
    const [area, selectArea] = useState<
      'current-location' | 'all-location' | 'godown'
    >('current-location');
    const [header, setHeader] = useState<TableHeader>([]);
    const [body, setBody] = useState<TableBody>([]);

    const [selectedStockGroup, setSelectedStockGroup] = useState<
      StockGroup | undefined
    >();
    const SGSearchBox = renderSearchBox<StockGroup>({
      useSearchValues: useStockGroups,
      onSearchValueSelect: (x) => {
        setSelectedStockGroup(x);
      },
    });

    const [selectedItem, setSelectedItem] = useState<ItemRes | undefined>();
    const ItemSearchBox = renderSearchBox<ItemRes>({
      useSearchValues: useItems,
      onSearchValueSelect: (x) => {
        setSelectedItem(x);
      },
    });

    const [selectedGrade, setSelectedGrade] = useState<Grade | undefined>();
    const GradeSearchBox = renderSearchBox<Grade>({
      useSearchValues: useGrades,
      onSearchValueSelect: (x) => {
        setSelectedGrade(x);
      },
    });

    const [selectedBatch, setSelectedBatch] = useState<
      ItemBatchGodown | undefined
    >();
    const BatchSearchBox = renderSearchBox<ItemBatchGodown>({
      useSearchValues: useBatches,
      onSearchValueSelect: (x) => {
        setSelectedBatch(x);
      },
    });

    const [selectedGodown, setSelectedGodown] = useState<Godown | undefined>();
    const GodownSearchBox = renderSearchBox<Godown>({
      useSearchValues: useGodowns,
      onSearchValueSelect: (x) => {
        setSelectedGodown(x);
      },
    });

    const [duration, setDuration] = useState<{ from: string; to: string }>({
      from: '2023-04-01',
      to: moment().format('YYYY-MM-DD'),
    });

    const [financialMoment, setFinancialMoment] = useState<{
      from: moment.Moment;
      to: moment.Moment;
    }>({
      from: moment(duration.from),
      to: moment(duration.to),
    });

    const [stock, setStock] = useState<{
      resource: string;
      name: string;
      view: string;
      area: string;
    }>({
      resource: '',
      name: '',
      view: '',
      area: '',
    });

    useEffect(() => {
      if (moment(duration.from).isBefore(moment(duration.to))) {
        setFinancialMoment({
          from: moment(duration.from),
          to: moment(duration.to),
        });
      }
    }, [duration]);

    useEffect(() => {
      const res = {
        resource: '',
        name: '',
        view: '',
        area: '',
      };
      if (level === 'all') {
        res.resource = 'All Resources';
      } else {
        res.name = level;
      }

      if (level === 'stock-group' && selectedStockGroup) {
        res.name += '-' + selectedStockGroup.name;
      }

      if (level === 'item' && selectedItem) {
        res.name += '-' + selectedItem.name;
      }

      if (level === 'grade' && selectedGrade) {
        res.name += '-' + selectedGrade.name;
      }

      if (level === 'batch' && selectedBatch) {
        res.name += '-' + selectedBatch.name;
      }

      res.view = view;

      res.area = area;

      if (area === 'godown' && selectedGodown) {
        res.area += '-' + selectedGodown.name;
      }

      setStock(res);
    }, [
      level,
      view,
      area,
      selectedStockGroup,
      selectedGodown,
      selectedGrade,
      selectedItem,
      selectedBatch,
    ]);

    useEffect(() => {
      const months: Array<string> = ['Opening Balance'];

      if (view === 'detailed') {
        setHeader([
          [
            { name: 'Particulars', style: 'w-2/3', rowSpan: 3 },
            {
              name: (
                <div className='text-center'>
                  <div className='italic capitalize'>
                    {stock.resource} {stock.name}
                  </div>
                  <div className='font-semibold text-gray-800 capitalize'>
                    {stock.view} view of {stock.area}
                  </div>
                  <div className='font-light'>
                    {moment(financialMoment.from).format('DD-MMM-YY')} to{' '}
                    {moment(financialMoment.to).format('DD-MMM-YY')}
                  </div>
                </div>
              ),
              colSpan: 7,
            },
          ],
          [
            {
              name: 'Closing Balance',
              style: 'text-center border-l border-gray-200 ',
              colSpan: 3,
            },
          ],
          [
            {
              name: 'Quantity',
              style: 'text-center text-gray-500 border-gray-200 border-l ',
            },
            {
              name: 'Rate',
              style: 'text-center text-gray-500',
            },
            {
              name: 'Value',
              style: 'text-center text-gray-500',
            },
          ],
        ]);

        setBody(
          months.map((x) => {
            return {
              cells: [
                { value: x },
                { value: '' },
                { value: '' },
                { value: '' },
              ],
            };
          })
        );
      }

      if (['monthly', 'quarterly', 'yearly'].includes(view)) {
        setHeader([
          [
            { name: 'Particulars', style: 'w-1/2', rowSpan: 3 },
            {
              name: (
                <div className='text-center'>
                  <div className='italic capitalize'>
                    {stock.resource} {stock.name}
                  </div>
                  <div className='font-semibold text-gray-800 capitalize'>
                    {stock.view} view of {stock.area}
                  </div>
                  <div className='font-light'>
                    {moment(financialMoment.from).format('DD-MMM-YY')} to{' '}
                    {moment(financialMoment.to).format('DD-MMM-YY')}
                  </div>
                </div>
              ),
              colSpan: 7,
            },
          ],
          [
            {
              name: 'Inwards',
              style: 'border-l border-gray-200 text-center',
              colSpan: 2,
            },
            { name: 'Outwards', style: 'text-center', colSpan: 3 },
            {
              name: 'Closing Balance',
              style: 'text-center',
              colSpan: 2,
            },
          ],
          [
            {
              name: 'Quantity',
              style: 'border-l border-gray-200 text-center   text-gray-500',
            },
            {
              name: 'Value',
              style: 'text-center text-gray-500',
            },
            {
              name: 'Quantity',
              style: 'text-center text-gray-500',
            },
            {
              name: 'Value',
              style: 'text-center text-gray-500',
            },
            {
              name: 'Gross Value',
              style: 'text-center text-gray-500',
            },
            {
              name: 'Quantity',
              style: 'text-center text-gray-500',
            },
            {
              name: 'Value',
              style: 'text-center text-gray-500',
            },
          ],
        ]);

        if (view === 'monthly') {
          for (
            let mon = moment(financialMoment.from);
            mon.isBefore(moment(financialMoment.to));
            mon = moment(mon).add(1, 'month')
          ) {
            months.push(mon.format('MMM-YY'));
          }
        }

        if (view === 'quarterly') {
          for (
            let mon = moment(financialMoment.from);
            mon.isBefore(moment(financialMoment.to));
            mon = moment(mon).add(3, 'month')
          ) {
            months.push(mon.format('MMM-YY'));
          }
        }

        if (view === 'yearly') {
          for (
            let mon = moment(financialMoment.from);
            mon.isBefore(moment(financialMoment.to));
            mon = moment(mon).add(1, 'year')
          ) {
            months.push(mon.format('MMM-YY'));
          }
        }

        setBody(
          months.map((x) => {
            return {
              cells: [
                { value: x },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
              ],
            };
          })
        );
      }

      if (view === 'transaction') {
        setHeader([
          [
            { name: 'Date', style: 'w-1/12', rowSpan: 3 },
            { name: 'Particulars', style: 'w-6/12', rowSpan: 3 },
            {
              name: (
                <div className='text-center capitalize'>
                  <div className='italic'>
                    {stock.resource} {stock.name}
                  </div>
                  <div className='font-semibold text-gray-800 capitalize'>
                    {stock.view} view of {stock.area}
                  </div>
                </div>
              ),
              colSpan: 9,
            },
          ],
          [
            {
              name: 'Operation',
              style: 'text-center w-1/12 border-l border-gray-200',
              colSpan: 2,
            },
            {
              name: 'Inwards',
              style: 'text-center w-1/12',
              colSpan: 2,
            },
            {
              name: 'Outwards',
              style: 'text-center w-2/12',
              colSpan: 3,
            },
            {
              name: 'Closing',
              style: 'text-center w-1/12',
              colSpan: 2,
            },
          ],
          [
            {
              name: 'Type',
              style: 'border-l border-gray-200',
            },
            { name: 'Name', style: '' },
            {
              name: 'Quantity',
              style: 'text-center text-gray-500',
            },
            {
              name: 'Value',
              style: 'text-center text-gray-500',
            },
            {
              name: 'Quantity',
              style: 'text-center text-gray-500',
            },
            {
              name: 'Value',
              style: 'text-center text-gray-500',
            },
            {
              name: 'Gross Value',
              style: 'text-center text-gray-500',
            },
            {
              name: 'Quantity',
              style: 'text-center text-gray-500',
            },
            {
              name: 'Value',
              style: 'text-center text-gray-500',
            },
          ],
        ]);

        setBody(
          months.map((x) => {
            return {
              cells: [
                { value: x },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: '' },
              ],
            };
          })
        );
      }
    }, [view, financialMoment, stock]);

    return (
      <div>
        <Card
          header={{
            title: 'Stock Item Summary',
            subheading: (
              <div className='flex'>
                <div className='border border-gray-200 rounded-lg p-1'>
                  <div className='text-center'>Duration</div>
                  <div className='flex'>
                    <DateSelector
                      initialState={duration.from}
                      max={moment().format('YYYY-MM-DD')}
                      format='YYYY-MM-DD'
                      onChange={(date) =>
                        setDuration((d) => {
                          return { to: d.to, from: date };
                        })
                      }
                    />

                    <DateSelector
                      initialState={duration.to}
                      max={moment().format('YYYY-MM-DD')}
                      format='YYYY-MM-DD'
                      onChange={(date) =>
                        setDuration((d) => {
                          return { to: date, from: d.from };
                        })
                      }
                    />
                  </div>
                </div>
                <div className='border border-gray-200 rounded-lg p-1'>
                  <div className='text-center'>Resource</div>
                  <select
                    value={level}
                    onChange={(evt) => selectLevel(evt.target.value as any)}
                    className='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                  >
                    <option value='all'>All</option>
                    <option value='stock-group'>Stockgroup</option>
                    <option value='item'>Item</option>
                    <option value='grade'>Grade</option>
                    <option value='batch'>Batch</option>
                  </select>
                </div>
                {level === 'stock-group' ? (
                  <div className='border border-gray-200 rounded-lg p-1'>
                    <div className='text-center'>Stockgroup</div>
                    <SGSearchBox
                      currentSelected={selectedStockGroup}
                      searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                    />
                  </div>
                ) : null}
                {level === 'item' ? (
                  <div className='border border-gray-200 rounded-lg p-1'>
                    <div className='text-center'>Item</div>
                    <ItemSearchBox
                      currentSelected={selectedItem}
                      searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                    />
                  </div>
                ) : null}
                {level === 'grade' ? (
                  <div className='border border-gray-200 rounded-lg p-1'>
                    <div className='text-center'>Grade</div>
                    <GradeSearchBox
                      currentSelected={selectedGrade}
                      searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                    />
                  </div>
                ) : null}
                {level === 'batch' ? (
                  <div className='border border-gray-200 rounded-lg p-1'>
                    <div className='text-center'>Batch</div>
                    <BatchSearchBox
                      currentSelected={selectedBatch}
                      searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                    />
                  </div>
                ) : null}
                <div className='border border-gray-200 rounded-lg p-1'>
                  <div className='text-center'>View</div>
                  <select
                    value={view}
                    onChange={(evt) => selectView(evt.target.value as any)}
                    className='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                  >
                    <option value='detailed'>Detailed</option>
                    <option value='monthly'>Month</option>
                    <option value='quarterly'>Quarter</option>
                    <option value='yearly'>Year</option>
                    <option value='transaction'>Transaction</option>
                  </select>
                </div>
                <div className='border border-gray-200 rounded-lg p-1'>
                  <div className='text-center'>Area</div>
                  <select
                    value={area}
                    onChange={(evt) => selectArea(evt.target.value as any)}
                    className='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                  >
                    <option value='current-location'>Current Location</option>
                    <option value='all-location'>All Location</option>
                    <option value='godown'>Godown</option>
                  </select>
                </div>
                {area === 'godown' ? (
                  <div className='border border-gray-200 rounded-lg p-1'>
                    <div className='text-center'>Godown</div>
                    <GodownSearchBox
                      currentSelected={selectedGodown}
                      searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                    />
                  </div>
                ) : null}
              </div>
            ),
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <Table header={header} body={body} />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
