import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile';
import {
  CardBody,
  CardHeader,
  MonthSelector,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseFileTransfer } from '../../../../../../hooks/file-transfer/use-file-transfer';
import { UseMisconduct } from '../../../../../../hooks/hrd/disciplinary/misconduct/use-misconduct';
import { UseMisconducts } from '../../../../../../hooks/hrd/disciplinary/misconduct/use-miscounducts';
import { UseEmployeeProfiles } from '../../../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UserRendererInterface } from '../../../../../common/fragments/user';
import { ShowMisconductDoc } from '../../../../disciplinary/misconduct/utility/misconduct-upload-fun';

// eslint-disable-next-line
export function renderEmployeeMisconductForm(): ({
  useMisconducts,
  useFileTransfer,
  useMisconduct,
  employee,
  userRendererService,
}: {
  useMisconducts: UseMisconducts;
  useFileTransfer: UseFileTransfer;
  useMisconduct: UseMisconduct;
  useEmployeeProfiles: UseEmployeeProfiles;
  employee: EmployeeProfileType;
  userRendererService: UserRendererInterface;
}) => JSX.Element {
  return function Misconduct({
    useMisconducts,
    useFileTransfer,
    useMisconduct,
    employee,
    userRendererService,
  }: {
    useMisconducts: UseMisconducts;
    useFileTransfer: UseFileTransfer;
    useMisconduct: UseMisconduct;
    useEmployeeProfiles: UseEmployeeProfiles;
    employee: EmployeeProfileType;
    userRendererService: UserRendererInterface;
  }): JSX.Element {
    const [date, setDate] = useState(
      localStorage.getItem('disciplinary-month') ||
        moment.utc().format('YYYY-MM')
    );

    const {
      data: misconducts,
      getAll: getMisconducts,
      loading,
    } = useMisconducts();

    // eslint-disable-next-line

    useEffect(() => {}, [misconducts]);

    useEffect(() => {
      getMisconducts({
        date,
        employeeId: employee.id,
      });
      localStorage.setItem('disciplinary-month', date);
      // eslint-disable-next-line
    }, [date]);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Misconducts',
      subheading: (
        <span className='text-blue-700 font-bold'>
          {moment(date).format('MMM YYYY')}
        </span>
      ),
      actions: [
        {
          type: 'jsx',
          jsx: (
            <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
              <span className='font-bold truncate'>Select month</span>
              <MonthSelector
                format='YYYY-MM'
                initialState={date}
                onChange={(m) => setDate(m)}
              />
            </div>
          ),
        },
      ],
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Date' },
        { name: 'Category' },
        { name: 'Reason' },
        { name: 'Amount' },
        { name: 'Status' },
        { name: 'Document' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
      ],
    ];

    const tableData =
      misconducts?.map((r, idx) => ({
        cells: [
          { value: r.date },
          { value: r.category },
          { value: r.reason },
          { value: r.amount },
          { value: r.status },
          {
            value: (
              <ShowMisconductDoc
                r={r}
                useFileTransfer={useFileTransfer}
                useMisconduct={useMisconduct}
              />
            ),
          },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.createdBy?.id}
                name={r.createdBy?.name}
                extraInfo={moment.utc(r.createdAt).fromNow()}
              />
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            {loading ? (
              <Recat className='h-5 inline animate-pulse mx-4' />
            ) : (
              <Table header={tableHeader} body={tableBody} />
            )}
          </div>
        </div>
      ),
    };
    return (
      <div className='border border-gray-200 rounded-lg p-2'>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
