/* eslint-disable no-unused-vars */
import { Authorization } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseActions } from '../../../hooks/admin/action-admin/use-actions-admin';
import { UseAuthorization } from '../../../hooks/admin/authorization/use-authorization';
import { UseModules } from '../../../hooks/admin/module-admin/use-modules-admin';
import { UseResourcesAdmin } from '../../../hooks/admin/use-resource-admin/use-resources-admin';

export type RenderEditAuthorizationFormProps = {
  useAuthorization: UseAuthorization;
  useResourcesAdmin: UseResourcesAdmin;
  useActions: UseActions;
  useModules: UseModules;
  closeEditModal: () => void;
};

export function renderEditAuthorizationForm({
  useAuthorization,
  useResourcesAdmin,
  useActions,
  useModules,
  closeEditModal,
}: RenderEditAuthorizationFormProps): (AuthorizationType) => JSX.Element {
  return function EditAuthorizationForm({
    r,
    onClose,
  }: {
    r: Authorization;
    onClose: () => void;
  }): JSX.Element {
    const { syncSet: setAuthorization } = useAuthorization();

    const saveAuthorization = async (form) => {
      if (r.id) form.id = r.id;
      await setAuthorization(form);
      closeEditModal();
      onClose();
    };

    const formProps: FormProps<Partial<Authorization>> = {
      fieldsData: [
        { property: 'name', label: 'Name', type: 'input' },
        {
          property: 'module',
          label: 'Module',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useModules,
            onSearchValueSelect(u) {},
          },
        },
        {
          property: 'resource',
          label: 'Resource',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useResourcesAdmin,
            onSearchValueSelect(u) {},
          },
        },
        {
          property: 'action',
          label: 'Action',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useActions,
            onSearchValueSelect(u) {},
          },
        },
      ],
      initialFormState: {
        name: r.name,
        module: { id: r.module?.id, name: r.module?.name } as any,
        resource: { id: r.resource?.id, name: r.resource?.name } as any,
        action: { id: r.action?.id, name: r.action?.name } as any,
      },
      mapTToU: mapPFDToP,
      onSubmit: saveAuthorization,
    };

    const Form = renderFormV2<Partial<Authorization>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<Authorization>>
    ): Partial<Authorization> {
      return (b as unknown) as Authorization;
    }
  };
}
