import { PolicyType } from '@erp_core/erp-types/dist/modules/hrd';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFileViewerUploader,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { CurrentContext } from '../../../../../contexts/current';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UsePolicies } from '../../../../../hooks/hrd/insurance/policy/use-policies';
import { UsePolicy } from '../../../../../hooks/hrd/insurance/policy/use-policy';
import { UsePremiums } from '../../../../../hooks/hrd/insurance/premiums/use-premiums';
import { UserRendererInterface } from '../../../../common/fragments/user';
import { EmployeePremiumsForm } from './component/emp-premiums';

export function renderEmpInsurance(): ({
  usePolicy,
  usePolicies,
  usePremiums,
  useFileTransfer,
  employee,
  userRendererService,
}: {
  usePolicy: UsePolicy;
  usePolicies: UsePolicies;
  usePremiums: UsePremiums;
  useFileTransfer: UseFileTransfer;
  employee: EmployeeProfileType;
  userRendererService: UserRendererInterface;
}) => JSX.Element {
  return function EmployeeInsuranceForm({
    usePolicy,
    usePolicies,
    usePremiums,
    useFileTransfer,
    employee,
    userRendererService,
  }: {
    usePolicy: UsePolicy;
    usePolicies: UsePolicies;
    usePremiums: UsePremiums;
    useFileTransfer: UseFileTransfer;
    employee: EmployeeProfileType;
    userRendererService: UserRendererInterface;
  }): JSX.Element {
    const FileViewerUploader = renderFileViewerUploader();

    const { company, companyGroup } = useContext(CurrentContext);

    const { syncSet: setPolicy } = usePolicy();
    const { data: policies, getAll: getPolicies } = usePolicies();
    const { data: premiums, getAll: getPremiums } = usePremiums();

    useEffect(() => {}, [policies]);

    useEffect(() => {
      getPolicies({ employeeId: employee.id });
      getPremiums();
      // eslint-disable-next-line
    }, []);

    function showuploadDoc(r: PolicyType) {
      if (r.details) {
        return (
          <div>
            {!r.details.document ? 'Pending Proof' : 'Proof'}{' '}
            <FileViewerUploader
              mode='upload'
              url={r.details.document || ''}
              useFileTransfer={useFileTransfer}
              path={`${companyGroup.id}/${company.id}/premiums/${r.id}/proofs.pdf`.replaceAll(
                ' ',
                '-'
              )}
              type='private'
              onUpload={async (path) => {
                let insurancedoc = r.details?.document || {};
                if (insurancedoc) {
                  insurancedoc = path.url;
                  await setPolicy({
                    id: r.id,
                    details: insurancedoc,
                  } as PolicyType);
                }
              }}
            />
          </div>
        );
      }
      return <div></div>;
    }

    function showLastPremiumMonth(id: string) {
      try {
        const lastPremiumMonth = premiums?.filter((x) => x.policy.id === id);

        const premiumRcds = _.orderBy(
          lastPremiumMonth,
          (e) => [moment(e.monthYear).format('YYYYMM')],
          ['desc', 'desc']
        );

        return (
          <div>
            <>{premiumRcds[0].monthYear ? premiumRcds[0].monthYear : '-'}</>
          </div>
        );
      } catch (e) {
        return <>-</>;
      }
    }

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Insurance',
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Employee' },
        { name: 'Ins Company Name' },
        { name: 'Policy Number' },
        { name: 'Frequency' },
        { name: 'Amount' },
        { name: 'Last Premium Month' },
        { name: 'Document' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
      ],
    ];
    const tableData: TableBody =
      policies?.map((r, idx) => ({
        rowData: {
          policy: r,
        },
        cells: [
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.employee?.id}
                name={r.employee.name}
              />
            ),
          },
          { value: r.insuranceCompany },
          { value: r.policyNumber },
          { value: r.frequency },
          { value: r.amount },
          { value: showLastPremiumMonth(r.id) },
          { value: showuploadDoc(r) },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.createdBy?.id}
                name={r.createdBy?.name}
                extraInfo={moment.utc(r.createdAt).fromNow()}
              />
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Premiums',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Premiums',
                    size: 'large',
                    content: ({ data: { policy }, onClose }) => {
                      return (
                        <EmployeePremiumsForm
                          userRendererService={userRendererService}
                          usePremiums={usePremiums}
                          policyId={policy.id}
                        />
                      );
                    },
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <div className='border border-gray-200 rounded-lg p-2'>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
