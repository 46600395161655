import { Check } from '@erp_core/erp-icons/icons/web/check';
import { Vendor as VendorIcon } from '@erp_core/erp-icons/icons/web/vendor';
import { Vendor } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  Filter,
  FormField,
  renderCardComponent,
  renderFormV2,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UsePaginatedVendors } from '../../../../hooks/inventory/purchase/vendors/use-paginated-vendors';
import { UseVendor } from '../../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseVendors } from '../../../../hooks/inventory/purchase/vendors/use-vendors';
import { VendorFilterType } from '../../../../models/interfaces/order/purchase/vendors';
import { UserRendererInterface } from '../../../common/fragments/user';

export type RenderVendorListProps = {
  useVendors: UseVendors;
  useVendor: UseVendor;
  usePaginatedVendors: UsePaginatedVendors;
  useUsers: UseUsers;
  userRendererService: UserRendererInterface;
};

export type SearchVendor = {
  id: string;
  name: string;
};

// eslint-disable-next-line
export function renderVendorList({
  useVendors,
  useVendor,
  usePaginatedVendors,
  useUsers,
  userRendererService,
}: RenderVendorListProps): () => JSX.Element {
  const Card = renderCardComponent();
  return function VendorList(): JSX.Element {
    const { syncSet: setVendor } = useVendor();
    const { getAllSync: getVendors } = useVendors();

    const cardHeader: CardHeader = {
      title: 'Vendors List',
      actions: [
        {
          type: 'button',
          button: {
            behaviour: 'modal',
            name: 'Add Vendor',
            modal: {
              title: 'Add Vendor',
              content: ({ onClose }) => {
                const fields: Array<FormField<{
                  name: string;
                  description: string;
                  type: 'trader' | '' | '';
                }>> = [
                  {
                    property: 'name',
                    type: 'input',
                    required: true,
                    label: 'Vendor name',
                    validate: async (form) => {
                      if (form.name) {
                        const matchingVendors = await getVendors({
                          name: form.name as string,
                        });
                        if (matchingVendors.length) {
                          return {
                            name: 'Vendor with same name exists',
                          };
                        }
                      }

                      return (await {}) as any;
                    },
                  },
                  {
                    property: 'description',
                    type: 'input',
                  },
                  {
                    property: 'type',
                    type: 'select',
                    required: true,
                    options: [
                      { text: 'Select', value: '' },
                      { text: 'Trader', value: 'trader' },
                      { text: 'Manufacturer', value: 'manufacturer' },
                      { text: 'Job Work', value: 'job-work' },
                    ],
                  },
                ];
                const Form = renderFormV2<any>({
                  style: 'md:w-1/2',
                  fieldsData: fields,
                  initialFormState: {
                    name: '',
                    description: '',
                    type: 'trader',
                  },
                  onSubmit: async (data) => {
                    await setVendor({
                      name: data.name,
                      description: data.description,
                      type: data.type,
                    } as Vendor);
                    onClose();
                  },
                  mapTToU: (b) => b,
                });
                return <Form />;
              },
            },
          },
        },
      ],
    };

    const Table = renderTableWithMapperComponent<Vendor, VendorFilterType>();

    const tableHeader: TableHeader = [
      [
        {
          name: (
            <>
              <VendorIcon className='w-5 h-5 inline mr-1 fill-white' />
              Vendor Name
            </>
          ),
        },
        { name: 'Type' },
        // { name: 'Approval Status' },
        // { name: <><InformationCircleIcon className="w-5 h-5 inline mr-1" />Description</>,  },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    const bodyMapper = (r: Vendor) => {
      const cells: Array<TableCell> = [
        {
          value: (
            <>
              {r.name}
              {r.status === 'approved' ? (
                <Check className='border rounded-full w-3 ml-1 inline bg-green-500 text-white' />
              ) : null}
            </>
          ),
          link: `/purchase/masters/vendors/${r.id}/profile`,
        },
        {
          value: r.type,
          style: 'capitalize',
        },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={r.lastModifiedBy?.id}
              name={r.lastModifiedBy?.name}
              extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
            />
          ),
        },
      ];

      return {
        cells,
      };
    };

    const filter: Filter<VendorFilterType> = {
      version: 'v2',
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'approvalStatus', // TODO: Filter when BE is migrated to postgresql
          value: 'all',
          type: 'drop-down',
          options: [
            { text: 'all', value: 'all' },
            { text: 'approved', value: 'approved' },
            { text: 'unapproved', value: 'unapproved' },
          ],
        },
        {
          key: 'createdBy',
          type: 'search-select',
          value: '',
        },
        {
          key: 'lastModifiedBy',
          type: 'search-select',
          value: '',
        },
      ],
      sortFields: [
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'Name',
          value: 'name',
          defaultOrder: 'asc',
        },
      ],
      filterMapper: (filterSelection: VendorFilterType) => {
        const filterData: VendorFilterType = {};

        if (filterSelection.approvalStatus !== 'all') {
          filterData.approvalStatus = filterSelection.approvalStatus;
        }

        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }

        if (filterSelection.createdBy) {
          filterData.createdBy = filterSelection.createdBy;
        }

        if (filterSelection.lastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.lastModifiedBy;
        }

        return filterData as VendorFilterType;
      },
      filterResources: {
        createdBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
        lastModifiedBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              useResources={useVendors}
              filter={filter}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedVendors,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
