import { renderCardComponent } from '@erp_core/erp-ui-components';
import { useContext } from 'react';
import { CurrentContext } from '../../../../contexts/current';
import { UseCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { CGConfigRenderer, CGConfigs } from '../config-renderer';
import { renderEditGRNNamePatternForm } from './forms/edit-grn-name-pattern';
import { renderEditPEDefaultValidityForm } from './forms/edit-pe-default-validity-form';
import { renderEditPENamePatternForm } from './forms/edit-pe-name-pattern';
import { renderEditPONamePatternForm } from './forms/edit-po-name-pattern';
import { renderEditPRNamePatternForm } from './forms/edit-pr-name-pattern';
import { renderEditLandingCostFactorsForm } from './forms/landing-cost-factors';
import { renderRMGradeCalculatorForm } from './forms/rm-grade-calculator';
import { renderRMQualityMetric } from './forms/rm-quality-metric';

type Props = {
  useCompanyGroupSetting: UseCompanyGroupSetting;
};

export type GenericEditPatternFormProps = {
  closeModal: () => void;
  useCompanyGroupSetting: UseCompanyGroupSetting;
};

export const createPurchaseConfigPage = ({ useCompanyGroupSetting }: Props) => {
  const Card = renderCardComponent();
  return function () {
    const { cgSetting } = useContext(CurrentContext);

    const handleEditPEDefaultValidity = ({
      onClose,
    }: {
      onClose: () => void;
    }) => {
      const EditPEDefaultValidityForm = renderEditPEDefaultValidityForm({
        id: cgSetting?.id || '',
        comGrpSetDetails: cgSetting?.details || {},
        closeModal: onClose,
        useCompanyGroupSetting,
      });
      return <EditPEDefaultValidityForm />;
    };

    const configs: CGConfigs[] = [
      {
        settingName: 'Default Purchase Enquiry Validity',
        settingValue: cgSetting?.details?.purchase?.purchaseEnquiry?.defaultValidity?.toString(),
        settingValueSuffix: ' days after sending enquiry',
        editAction: handleEditPEDefaultValidity,
      },
      {
        settingName: 'Purchase Requisition Name Pattern',
        settingValue: cgSetting?.details?.purchase?.purchaseRequisition?.prNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderEditPRNamePatternForm({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={cgSetting} />;
        },
      },
      {
        settingName: 'Purchase Order Name Pattern',
        settingValue: cgSetting?.details?.purchase?.purchaseOrder?.poNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderEditPONamePatternForm({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={cgSetting} />;
        },
      },
      {
        settingName: 'Purchase Enquiry Name Pattern',
        settingValue: cgSetting?.details?.purchase?.purchaseEnquiry?.peNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderEditPENamePatternForm({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={cgSetting} />;
        },
      },
      {
        settingName: 'GRN Name Pattern',
        settingValue: cgSetting?.details?.purchase?.grn?.grnNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderEditGRNNamePatternForm({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={cgSetting} />;
        },
      },
      {
        settingName: 'Raw Material Grades Calculator',
        settingValue: (cgSetting?.details?.purchase?.rmGrades || [])
          .map((t) => t.gradeName)
          .toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderRMGradeCalculatorForm({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={cgSetting} />;
        },
      },
      {
        settingName: 'Raw Material Quality Metric',
        settingValue: (cgSetting?.details?.purchase?.rmQualityMetric || [])
          .map((t) => t.name)
          .toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderRMQualityMetric({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={cgSetting} />;
        },
      },
      {
        settingName: 'Landing cost Calculator Factors pattern.',
        settingValue: cgSetting?.details?.purchase?.landingCostFactors || '',
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const Form = renderEditLandingCostFactorsForm({
            closeModal: onClose,
            useCompanyGroupSetting,
          });

          return <Form comGrpSet={cgSetting} />;
        },
      },
    ];

    return (
      <div className='w-full'>
        <div className='flex flex-wrap'>
          {configs.map((config) => (
            <div className='w-1/2' key={config.settingName}>
              <Card
                header={{ title: config.settingName }}
                body={{
                  type: 'jsx-with-behaviour',
                  body: {
                    behaviour: 'modal',
                    content: (
                      <CGConfigRenderer
                        configs={[config]}
                        loading={cgSetting?.id ? false : true}
                      />
                    ),
                    modal: {
                      title: config.settingName,
                      content: ({ onClose }) => {
                        return config.editAction({ onClose });
                      },
                    },
                  },
                }}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };
};
