import {
  Company,
  LocationType,
  OnboardEmployeeType,
} from '@erp_core/erp-types/dist/modules/admin';
import { DepartmentType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCompanies } from '../../../../hooks/admin/company-admin/use-companies-admin';
import { UseEmployeeCategories } from '../../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';

export type OnboardEmployeeFormType = {
  empId: string;
  empFirstName: string;
  empLastName: string;
  onboardingCompany: string;
  department: string;
  location: string;
  date: string;
  reason: string;
  shiftType: string;
  email: string;
  designation: string;
  grade: string;
  weeklyOff: string;
  joiningDate: string;
  attendanceSupervisor: string;
  attendanceAuthorizer: string;
  attendanceSupervisorMonitor: string;
  loanAuthorizer: string;
  salaryAdvanceAuthorizer: string;
  salaryBankAccountAuthorizer: string;
  salaryRevisionAuthorizer: string;
  insuranceAuthorizer: string;
  disciplinaryActionsAuthorizer: string;
  onboardToAttendance: string;
};

type RenderAddEmployeeOnboardForm = {
  newEmpId: string;
  locations: LocationType[];
  useEmployeeCategories: UseEmployeeCategories;
  useEmployeeProfiles: UseEmployeeProfiles;
  useCompanies: UseCompanies;
};

export function renderAddEmployeeOnboardForm({
  newEmpId,
  locations,
  useEmployeeCategories,
  useEmployeeProfiles,
  useCompanies,
}: RenderAddEmployeeOnboardForm) {
  return function AddEmployeeOnboardForm({
    onSave,
    data,
    currentCompany,
    departments,
    isEdit,
  }: {
    onSave: (data: OnboardEmployeeType) => Promise<void>;
    currentCompany: Company;
    data?: OnboardEmployeeType;
    departments: DepartmentType[];
    isEdit?: boolean;
  }): JSX.Element {
    console.log('data', data);
    const formProps: FormProps<Partial<OnboardEmployeeFormType>> = {
      fieldsData: [
        {
          groupName: '1. General',
          property: 'empId',
          type: 'input',
          required: true,
          label: 'Employee Id',
        },
        {
          groupName: '1. General',
          property: 'empFirstName',
          type: 'input',
          required: true,
          label: 'Employee First Name',
        },
        {
          groupName: '1. General',
          property: 'empLastName',
          type: 'input',
          required: true,
          label: 'Employee Last Name',
        },
        {
          groupName: '1. General',
          property: 'onboardingCompany',
          type: 'searchable-select',
          label: 'Company',
          required: true,
          searchOptions: {
            useSearch: useCompanies,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
        },
        {
          groupName: '1. General',
          property: 'location',
          type: 'select',
          options: [
            {
              text: 'Select',
              value: '',
            },
            ...locations.map((l) => {
              return {
                text: l.name,
                value: l.id,
              };
            }),
          ],
          required: true,
          label: 'Location',
        },
        {
          groupName: '1. General',
          property: 'email',
          type: 'input',
          required: true,
          label: 'Email',
        },
        {
          groupName: '1. General',
          property: 'joiningDate',
          type: 'date',
          required: true,
          label: 'Joining Date',
        },
        {
          groupName: '2. Job Details',
          property: 'shiftType',
          type: 'select',
          required: true,
          options: [
            { value: '', text: 'select' },
            { value: 'Rotational', text: 'Rotational' },
            { value: 'General', text: 'General' },
            { value: 'Fixed', text: 'Fixed' },
            { value: 'Flexible', text: 'Flexible' },
          ],
        },
        {
          groupName: '2. Job Details',
          property: 'department',
          type: 'select',
          options: [
            {
              text: 'Select',
              value: '',
            },
            ...departments.map((x) => {
              return {
                text: x.name,
                value: x.id,
              };
            }),
          ],
          required: true,
          label: 'Department',
        },
        {
          groupName: '2. Job Details',
          property: 'designation',
          type: 'input',
          required: true,
          label: 'Designation',
        },
        {
          groupName: '2. Job Details',
          property: 'onboardToAttendance',
          label: 'Onboard To Attendance',
          required: true,
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
        },
        {
          groupName: '2. Job Details',
          property: 'grade',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useEmployeeCategories,
            onSearchValueSelect: () => {},
          },
          label: 'Grade',
        },
        {
          groupName: '2. Job Details',
          dependentOn: (f) => {
            if (
              ['General', 'Flexible', 'Fixed'].includes(f.shiftType as string)
            ) {
              return true;
            }
            return false;
          },
          property: 'weeklyOff',
          type: 'select',
          // required: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'Sunday', value: 'Sunday' },
            { text: 'Monday', value: 'Monday' },
            { text: 'Tuesday', value: 'Tuesday' },
            { text: 'Wednesday', value: 'Wednesday' },
            { text: 'Thursday', value: 'Thursday' },
            { text: 'Friday', value: 'Friday' },
            { text: 'Saturday', value: 'Saturday' },
          ],
        },
        {
          groupName: '3. Authorizations',
          property: 'attendanceSupervisor',
          label: 'Attendance Supervisor',
          type: 'searchable-select',
          required: false,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
        },
        {
          groupName: '3. Authorizations',
          property: 'attendanceAuthorizer',
          label: 'Attendance Authorizer',
          type: 'searchable-select',
          required: false,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
        },
        {
          groupName: '3. Authorizations',
          property: 'attendanceSupervisorMonitor',
          label: 'Attendance Supervisor Monitor',
          type: 'searchable-select',
          required: false,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
        },
        {
          groupName: '3. Authorizations',
          property: 'loanAuthorizer',
          label: 'Loan Authorizer',
          type: 'searchable-select',
          required: false,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
        },
        {
          groupName: '3. Authorizations',
          property: 'salaryAdvanceAuthorizer',
          label: 'Salary Advance Authorizer',
          type: 'searchable-select',
          required: false,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
        },
        {
          groupName: '3. Authorizations',
          property: 'salaryBankAccountAuthorizer',
          label: 'Salary Bank  Account Authorizer',
          type: 'searchable-select',
          required: false,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
        },
        {
          groupName: '3. Authorizations',
          property: 'salaryRevisionAuthorizer',
          label: 'Salary Revision Authorizer',
          type: 'searchable-select',
          required: false,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
        },
        {
          groupName: '3. Authorizations',
          property: 'insuranceAuthorizer',
          label: 'Insurance Authorizer',
          type: 'searchable-select',
          required: false,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
        },
        {
          groupName: '3. Authorizations',
          property: 'disciplinaryActionsAuthorizer',
          label: 'Disciplinary Actions Authorizer',
          type: 'searchable-select',
          required: false,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
        },
      ],
      initialFormState: {
        empId: newEmpId || data?.employeeId,
        location: '' || data?.details.location?.id,
        department: data?.details?.department?.id || '',
        onboardingCompany: data?.onboardingCompany || currentCompany,
        joiningDate: '' || data?.joiningDate,
        empFirstName: '' || data?.employeeFirstName,
        empLastName: '' || data?.employeeLastName,
        email: '' || data?.details.email,
        designation: '' || data?.details.designation,
        shiftType: '' || data?.details.shiftType,
        weeklyOff: '' || data?.details.weeklyOff,
        onboardToAttendance: '' || data?.details?.onboardToAttendance,
        grade: data?.details?.grade || { id: '', name: '' },
        attendanceSupervisor: data?.details?.authorizations
          ?.attendanceSupervisor || { id: '', name: '' },
        attendanceAuthorizer: data?.details?.authorizations
          ?.attendanceAuthorizer || { id: '', name: '' },
        attendanceSupervisorMonitor: data?.details?.authorizations
          ?.attendanceSupervisorMonitor || { id: '', name: '' },
        loanAuthorizer: data?.details?.authorizations?.loanAuthorizer || {
          id: '',
          name: '',
        },
        salaryAdvanceAuthorizer: data?.details?.authorizations
          ?.salaryAdvanceAuthorizer || { id: '', name: '' },
        salaryBankAccountAuthorizer: data?.details?.authorizations
          ?.salaryBankAccountAuthorizer || { id: '', name: '' },
        salaryRevisionAuthorizer: data?.details?.authorizations
          ?.salaryRevisionAuthorizer || { id: '', name: '' },
        insuranceAuthorizer: data?.details?.authorizations
          ?.insuranceAuthorizer || { id: '', name: '' },
        disciplinaryActionsAuthorizer: data?.details?.authorizations
          ?.disciplinaryActionsAuthorizer || { id: '', name: '' },
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/5',
      onSubmit: async (f) => {
        await onSave(({
          employeeId: f.empId || newEmpId || data?.employeeId,
          employeeFirstName: f.empFirstName || data?.employeeFirstName,
          employeeLastName: f.empLastName || data?.employeeLastName,
          onboardingCompany:
            f.onboardingCompany || data?.onboardingCompany || currentCompany,
          joiningDate: f.joiningDate || data?.joiningDate,
          details: {
            email: f.email || data?.details.email,
            department: {
              id:
                departments.find((x) => f.department === x.id)?.id ||
                data?.details.department?.id,
              name:
                departments.find((x) => f.department === x.id)?.name ||
                data?.details.department?.name,
            },
            grade: f.grade || data?.details.grade,
            authorizations: {
              attendanceSupervisor:
                f.attendanceSupervisor ||
                data?.details?.authorizations?.attendanceSupervisor,
              attendanceAuthorizer:
                f.attendanceAuthorizer ||
                data?.details?.authorizations?.attendanceAuthorizer,
              attendanceSupervisorMonitor:
                f.attendanceSupervisorMonitor ||
                data?.details?.authorizations?.attendanceSupervisorMonitor,
              loanAuthorizer:
                f.loanAuthorizer ||
                data?.details?.authorizations?.loanAuthorizer,
              salaryAdvanceAuthorizer:
                f.salaryAdvanceAuthorizer ||
                data?.details?.authorizations?.salaryAdvanceAuthorizer,
              salaryBankAccountAuthorizer:
                f.salaryBankAccountAuthorizer ||
                data?.details?.authorizations?.salaryBankAccountAuthorizer,
              salaryRevisionAuthorizer:
                f.salaryRevisionAuthorizer ||
                data?.details?.authorizations?.salaryRevisionAuthorizer,
              insuranceAuthorizer:
                f.insuranceAuthorizer ||
                data?.details?.authorizations?.insuranceAuthorizer,
              disciplinaryActionsAuthorizer:
                f.disciplinaryActionsAuthorizer ||
                data?.details?.authorizations?.disciplinaryActionsAuthorizer,
            },
            weeklyOff: f.weeklyOff || data?.details.weeklyOff,
            shiftType: f.shiftType || data?.details.shiftType,
            location: {
              id:
                locations.find((x) => f.location === x.id)?.id ||
                data?.details.location?.id,
              name:
                locations.find((x) => f.location === x.id)?.name ||
                data?.details.location?.name,
            },
            designation: f.designation || data?.details.designation,
            onboardToAttendance:
              f.onboardToAttendance || data?.details.onboardToAttendance,
          },
        } as unknown) as OnboardEmployeeType);
      },
    };

    const Form = renderFormV2<Partial<OnboardEmployeeFormType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<OnboardEmployeeFormType>>
    ): OnboardEmployeeFormType {
      return (b as unknown) as OnboardEmployeeFormType;
    }
  };
}
