import {
  DashboardReportType,
  DashboardStatus,
  DashboardType,
  ModuleType,
} from '@erp_core/erp-types/dist/modules/analytics';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseRoles } from '../../../../hooks/admin/role-admin/use-roles-admin';
import { UseDashboard } from '../../../../hooks/analytics/dashboard/use-dashboard';

type ReportType = {
  id: string;
  name: string;
  description: string;
  module: ModuleType;
  subModule: string;
  type: DashboardReportType;
  status: DashboardStatus;
  rowSpan: number;
  colSpan: number;
  sequence: number;
  recommended: 'yes' | 'no';
  url: string;
  roles: Array<IdName>;
};

export function renderAddEditReport({
  useRoles,
  useDashboard,
}: {
  useRoles: UseRoles;
  useDashboard: UseDashboard;
}) {
  return function AddEditReport({
    rep,
    module,
    onClose,
  }: {
    rep?: DashboardType;
    module: ModuleType;
    onClose: () => void;
  }) {
    const { syncSet: setReport } = useDashboard();
    const Form = renderFormV2<ReportType>({
      initialFormState: {
        id: rep?.id || '',
        name: rep?.name || '',
        description: rep?.description || '',
        module: module,
        subModule: rep?.subModule || '',
        type: rep?.type || 'invalid-report',
        status: rep?.status || 'draft',
        rowSpan: rep?.details?.displaySettings?.rowSpan || 0,
        colSpan: rep?.details?.displaySettings?.colSpan || 0,
        sequence: rep?.details?.displaySettings?.sequence || 0,
        url: rep?.details?.url || '',
        roles: rep?.roles || [],
        recommended: rep?.details?.displaySettings?.recommended ? 'yes' : 'no',
      },
      fieldsData: [
        {
          groupName: '1. General',
          property: 'module',
          type: 'input',
          readonly: true,
          required: true,
        },
        {
          groupName: '1. General',
          property: 'name',
          type: 'input',
          readonly: rep?.name ? true : false,
          required: true,
        },
        {
          groupName: '1. General',
          property: 'description',
          type: 'input',
          required: true,
        },
        {
          groupName: '1. General',
          property: 'subModule',
          type: 'input',
        },
        {
          groupName: '1. General',
          property: 'type',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Invalid Report', value: 'invalid-report' },
            { text: 'List', value: 'list' },
            { text: 'Line Graph', value: 'line-graph' },
            { text: 'Bar Graph', value: 'bar-graph' },
            { text: 'Numeric', value: 'numeric' },
            { text: 'Polar Graph', value: 'polar-graph' },
            { text: 'Pie Graph', value: 'pie-graph' },
          ],
        },
        {
          groupName: '1. General',
          property: 'status',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Draft', value: 'draft' },
            { text: 'Published', value: 'published' },
          ],
          required: true,
        },
        {
          groupName: '1. General',
          type: 'searchable-multi-select',
          property: 'roles',
          searchOptions: {
            useSearch: useRoles,
            onSearchValueSelect: () => {},
          },
        },
        {
          groupName: '2. Display Settings',
          property: 'rowSpan',
          type: 'number',
        },
        {
          groupName: '2. Display Settings',
          property: 'colSpan',
          type: 'number',
        },
        {
          groupName: '2. Display Settings',
          property: 'sequence',
          type: 'number',
        },
        {
          groupName: '2. Display Settings',
          label: 'Recommended By System',
          property: 'recommended',
          type: 'select',
          options: [
            { text: 'yes', value: 'yes' },
            { text: 'no', value: 'no' },
          ],
        },
        {
          groupName: '3. Configuration',
          property: 'url',
          type: 'input',
        },
      ],
      mapTToU: (t) => t as any,
      onSubmit: async (formData) => {
        if (!formData.recommended) {
          formData.recommended = rep?.details?.displaySettings?.recommended
            ? 'yes'
            : 'no';
        }

        let finalData: DashboardType = {} as any;
        finalData.id = rep?.id || '';
        finalData.module = rep?.module || module;
        finalData.subModule = formData?.subModule || rep?.subModule || '';
        finalData.name = formData?.name || rep?.name || '';
        finalData.type = formData?.type || rep?.type || 'invalid-report';
        finalData.description = formData?.description || rep?.description || '';
        finalData.status = formData?.status || rep?.status || '';
        finalData.roles = formData?.roles?.length
          ? formData.roles
          : rep?.roles || [];
        finalData.details = rep?.details || ({} as any);
        finalData.details.displaySettings = {
          sequence:
            formData.sequence ||
            finalData.details?.displaySettings?.sequence ||
            0,
          rowSpan:
            formData.rowSpan ||
            finalData.details?.displaySettings?.rowSpan ||
            0,
          colSpan:
            formData.colSpan ||
            finalData.details?.displaySettings?.colSpan ||
            0,
          recommended: formData.recommended === 'yes' ? true : false,
        };
        finalData.details.url = formData.url || finalData.details?.url || '';

        await setReport(finalData);
        onClose();
      },
      style: 'md:w-1/2',
    });

    return (
      <div>
        <Form />
      </div>
    );
  };
}
