/* eslint-disable react-hooks/exhaustive-deps */
import { AdvancedLoadingButton } from '@erp_core/erp-ui-components';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { CurrentContext } from '../../../../contexts/current';
import { UseCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { renderEditBomNamePatternForm } from './forms/edit-bom-name-pattern';

type CreateWorkOrderConfigPage = {
  useCompanyGroupSetting: UseCompanyGroupSetting;
};

export const createWorkOrderConfigPage = ({
  useCompanyGroupSetting,
}: CreateWorkOrderConfigPage) => {
  return function () {
    const { cgSetting } = useContext(CurrentContext);

    return (
      <div>
        <div>
          <div className='my-2'>
            <span className='font-bold text-md mr-3'>Bom name pattern: </span>
            {cgSetting?.details?.workOrder?.bom?.bomNamePattern || ''}
            <AdvancedLoadingButton
              defaultStyle='ml-4'
              behaviour='modal'
              modal={{
                title: 'Edit BOM name pattern',
                content: ({ onClose }: { onClose: () => void }) => {
                  const EditBomNamePatternForm = renderEditBomNamePatternForm({
                    closeModal: onClose,
                    useCompanyGroupSetting,
                  });
                  return <EditBomNamePatternForm data={cgSetting} />;
                },
              }}
              text={<PencilSquareIcon className='h-4 w-4' />}
            />
          </div>
        </div>
      </div>
    );
  };
};
