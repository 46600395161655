import { OffSiteWorkHoursType } from '@erp_core/erp-types/dist/modules/hrd';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseEmployeeProfiles } from '../../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { validateTimeFormat } from '../../util/validateTime';

export type OffSiteWorkHoursFormType = {
  employee: IdName;
  date: string;
  startTime: string;
  endTime: string;
  reason: string;
};

type RenderOffSiteWorkHoursForm = {
  useEmployeeProfiles: UseEmployeeProfiles;
};

export const renderOffSiteWorkHoursForm = ({
  useEmployeeProfiles,
}: RenderOffSiteWorkHoursForm) => {
  return function OffSiteWorkHoursForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (d: OffSiteWorkHoursFormType) => Promise<void>;
    isEdit?: boolean;
    data?: OffSiteWorkHoursType;
  }) {
    const formProps: FormProps<OffSiteWorkHoursFormType> = {
      fieldsData: [
        {
          property: 'employee',
          type: 'searchable-select',
          label: 'Employee Name',
          required: true,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            filter: { crossGroup: 'true' },
            onSearchValueSelect: () => {},
          },
          readonly: isEdit,
        },
        { property: 'date', type: 'date', label: 'Date ', required: true },
        {
          property: 'startTime',
          type: 'input',
          label: 'Start Time ',
          required: true,
          validate: async (fd: any) => {
            return {
              startTime: validateTimeFormat(fd.startTime),
            };
          },
        },
        {
          property: 'endTime',
          type: 'input',
          label: 'End Time',
          required: true,
          validate: async (fd: any) => {
            return {
              endTime: validateTimeFormat(fd.endTime, fd.startTime),
            };
          },
        },
        {
          property: 'reason',
          type: 'input',
          required: true,
          label: 'Reason',
        },
      ],
      initialFormState: {
        employee: data?.employee || '',
        date: data?.date || '',
        startTime: data?.startTime || '',
        endTime: data?.endTime || '',
        reason: data?.reason || '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<OffSiteWorkHoursFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<OffSiteWorkHoursFormType>
    ): OffSiteWorkHoursFormType {
      return b as OffSiteWorkHoursFormType;
    }
  };
};
