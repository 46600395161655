import {
  MachineShift,
  MachineShiftFilterType,
} from '@erp_core/erp-types/dist/modules/planning';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpRepo } from '../../generic-resource';

export class HttpMachineShiftRepo extends GenericHttpRepo<
  MachineShift,
  MachineShiftFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/production/machines/shifts',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
