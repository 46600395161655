import { EmployeeProfileType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile';
import { SalaryAdvanceType } from '@erp_core/erp-types/dist/types/modules/hrd/salary-advance';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useContext, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { UserContext } from '../../../../../contexts/user';
import { UseSalaryAdvance } from '../../../../../hooks/hrd/salary-advance/use-salary-advance';
import { UseSalaryAdvanceEligibleAmt } from '../../../../../hooks/hrd/salary-advance/use-salary-advance-eligibleAmount';
import { UseSalaryAdvances } from '../../../../../hooks/hrd/salary-advance/use-salary-advances';
import { renderAddSalaryAdvanceForm } from './form/salary-advance-add-form';

export function renderEmployeeSalaryAdvance(): ({
  useSalaryAdvances,
  useSalaryAdvance,
  employee,
  useSalaryAdvanceEligibleAmt,
}: {
  useSalaryAdvances: UseSalaryAdvances;
  useSalaryAdvance: UseSalaryAdvance;
  employee: EmployeeProfileType;
  useSalaryAdvanceEligibleAmt: UseSalaryAdvanceEligibleAmt;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
}) => JSX.Element {
  return function EmployeeSalaryAdvanceForm({
    useSalaryAdvances,
    useSalaryAdvance,
    employee,
    useSalaryAdvanceEligibleAmt,
    actions,
  }: {
    useSalaryAdvances: UseSalaryAdvances;
    useSalaryAdvance: UseSalaryAdvance;
    employee: EmployeeProfileType;
    useSalaryAdvanceEligibleAmt: UseSalaryAdvanceEligibleAmt;
    actions: {
      [key: string]: {
        action: boolean;
        locations?: string[] | undefined;
      };
    };
  }): JSX.Element {
    const { user: currentUser } = useContext(UserContext);

    const { syncSet: setSalaryAdvance } = useSalaryAdvance();

    const {
      data: salaryAdvances,
      getAll: getSalaryAdvances,
    } = useSalaryAdvances();

    const {
      data: eligibleAmount,
      getSalaryAdvanceEligibleAmt: getEligibleAdvanceAmt,
    } = useSalaryAdvanceEligibleAmt();

    useEffect(() => {
      getEligibleAdvanceAmt({ employeeId: employee.id });
      getSalaryAdvances({
        employeeId: employee.id,
      });
      // eslint-disable-next-line
    }, []);

    useEffect(() => {}, [salaryAdvances]);
    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Salary Advances',
      actions: [
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          type: 'button',
          button: {
            behaviour: 'modal',
            modal: {
              title: 'Request Salary Advance',
              content: ({ onClose }) => {
                return (
                  <SalaryAdvanceForm
                    onSave={async (form) => {
                      saveSalaryAdvance(form);
                      onClose();
                    }}
                  />
                );
              },
            },
            name: 'Request Salary Advance',
          },
        },
      ],
    };

    const SalaryAdvanceForm = renderAddSalaryAdvanceForm({ eligibleAmount });

    const saveSalaryAdvance = async (form: Partial<SalaryAdvanceType>) => {
      try {
        const finalData = {
          ...form,
          employee: {
            id: employee.id,
            name: employee.name,
          },
          authorizer: {
            id: currentUser.id,
            name: currentUser.name,
          },
          status: 'pending',
        } as SalaryAdvanceType;
        await setSalaryAdvance(finalData);
        toast('Data added sucessfully');
        getSalaryAdvances({
          employeeId: employee.id,
        });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Requested Date' },
        { name: 'Reason' },
        { name: 'Eligible Amount' },
        { name: 'Requested Amount' },
        { name: 'Released Date' },
        { name: 'Released Amount' },
        { name: 'Status' },
      ],
    ];

    const tableData =
      salaryAdvances?.map((r, idx) => ({
        cells: [
          { value: r.date },
          { value: r.reason },
          { value: r.feasibleAmount },
          { value: r.amount },
          { value: r.details.releasedDate || '-' },
          { value: r.details.relasedAmount || '-' },
          { value: r.status },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            {
              <div>
                <Table header={tableHeader} body={tableBody} />
              </div>
            }
          </div>
        </div>
      ),
    };
    return (
      <div className='border border-gray-200 rounded-lg p-2'>
        <Card header={cardHeader} body={cardBody} auth={{ actions }} />
      </div>
    );
  };
}
