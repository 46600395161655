import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseItemCategories } from '../../../../hooks/inventory/item/item-category/use-item-categories';
import { UseStockGroups } from '../../../../hooks/inventory/stockgroup/use-stockgroups';

type ItemDetails = {
  name: string;
  category: string;
  stockgroup: string;
};

type RenderAddItemForm = {
  useStockGroups: UseStockGroups;
  useItemCategories: UseItemCategories;
};

export const renderAddItemForm = ({
  useStockGroups,
  useItemCategories,
}: RenderAddItemForm) => {
  return function AddItemForm({ onSave }: { onSave: any }) {
    const formProps: FormProps<ItemDetails> = {
      fieldsData: [
        { property: 'name', type: 'input' },
        {
          property: 'category',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useItemCategories,
            onSearchValueSelect: () => {},
          },
          required: true,
        },
        {
          property: 'stockgroup',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useStockGroups,
            onSearchValueSelect: () => {},
          },
          required: true,
        },
      ],
      initialFormState: {
        name: '',
        category: '',
        stockgroup: '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<ItemDetails>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<ItemDetails>): ItemDetails {
      return {
        name: b.name as string,
        category: b.category as string,
        stockgroup: b.stockgroup as string,
      };
    }
  };
};
