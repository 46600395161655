/* eslint-disable no-unused-vars */
import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import { Input } from '@erp_core/erp-ui-components';
import { UseItem } from '../../../../hooks/inventory/item/use-item';
import { dateGetter } from '../../../../utils/common';

type ComponentProps = {
  data: ItemRes;
  reload(): void;
};

type Props = {
  useItem: UseItem;
};

/* eslint-disable arrow-body-style */
export const createItemSideView = ({
  useItem,
}: Props): ((props: ComponentProps) => JSX.Element) => {
  return function ({ data, reload }: ComponentProps): JSX.Element {
    const { syncSet } = useItem();

    if (!data) return <div />;
    const editItem = async (field: string, value: string | boolean) => {
      const d: any = {};
      d[field] = value;
      const res = await syncSet({ id: data.id, ...d });
      if (res) reload();
    };

    return (
      <div className='w-2/4 h-full mt-4 overflow-y-auto p-3 pb-20 relative border-l-2 border-zinc-200 bg-zinc-50'>
        <div className='flex flex-col md:flex-row'>
          <div className='mr-4'>
            <img
              className='w-28 h-28 border border-zinc-200 rounded-md'
              alt='godown'
              src='https://cdn-icons-png.flaticon.com/512/3565/3565607.png'
            />
          </div>

          <div className='border flex-1 border-zinc-200'>
            <div className='rounded-md p-2'>
              <Input
                className='text-2xl font-bold'
                type='text'
                field='name'
                initialValue={data.name}
                onOk={editItem}
              />
            </div>
            <div className='px-2 font-bold'>{data.category.name}</div>
          </div>
        </div>
        <div>
          <div>
            <div className='border relative border-zinc-200 my-2 rounded-md p-2'>
              <div className='text-lg font-bold'>Profile</div>
              <div className='my-2'>
                <div className='flex space-x-2'>
                  <div className='font-bold'>Alias: </div>
                  <div className='text-gray-700'>
                    {' '}
                    {data.details?.alias || 'Not present'}
                  </div>
                </div>
                <div className='flex space-x-2'>
                  <div className='font-bold'>Abbreviation: </div>
                  <div className='text-gray-700'>
                    {' '}
                    {data.details?.abbreviation || 'Not present'}
                  </div>
                </div>
                <div className='flex space-x-2'>
                  <div className='font-bold'>Status: </div>
                  <div className='text-gray-700'>
                    {' '}
                    {data.status || 'Not present'}
                  </div>
                </div>

                <div className='flex space-x-2 items-center'>
                  <div className='font-bold'>Inspectors: </div>
                  <div className='text-gray-700 flex space-x-2'>
                    {data.details?.inspectors?.map((i) => (
                      <div
                        key={i.id}
                        className='p-0.5 font-semibold rounded border border-gray-300'
                      >
                        {i.name}
                      </div>
                    ))}
                  </div>
                </div>

                <div className='flex absolute right-2 bottom-2 space-x-2'>
                  <a
                    href={`/inventory/items/${data.id}/properties`}
                    target='_blank'
                    rel='noreferrer'
                    className='bg-transparent underline text-blue-600'
                  >
                    see more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='absolute bottom-2 right-4 flex items-center text-gray-600 space-x-2'>
          <div className='mt-2'>
            <div className='flex items-center space-x-2'>
              <div className='font-bold'>Last modified</div>
              <div>
                {data.lastModifiedBy.name},{' '}
                {data.lastModifiedAt
                  ? dateGetter(new Date(data.lastModifiedAt))
                  : '-'}
              </div>
            </div>
          </div>

          <div className='mt-2'>
            <div className='flex items-center space-x-2'>
              <div className='font-bold'>Created</div>
              <div>
                {data.createdBy.name},{' '}
                {data.createdAt ? dateGetter(new Date(data.createdAt)) : '-'}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};
