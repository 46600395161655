import { renderHistory } from '@erp_core/erp-ui-components';
import { UseCompanyAdmin } from '../../../../../hooks/admin/company-admin/use-company-admin';

export function renderHistoryTab({
  id,
  useCompanyAdmin,
}: {
  id: string;
  useCompanyAdmin: UseCompanyAdmin;
}): () => JSX.Element {
  return function HistoryTab(): JSX.Element {
    const History = renderHistory();

    return <History useResource={useCompanyAdmin} id={id} />;
  };
}
