import { eval as expEval, parse } from 'expression-eval';

type Props = {
  pattern: string;
  parameters: {
    baseCost: number;
    transportCost: number;
    quantity: number;
    customDuty?: number;
    freight?: number;
    lc?: number;
    pf?: number;
    bankInterest?: number;
    creditPeriod?: number;
  };
};

type ReturnType = {
  success: boolean;
  landingCost?: number;
  message?: string;
};

type CalculateLandingCost = (p: Props) => ReturnType;

const calculateLandingCost: CalculateLandingCost = ({
  pattern,
  parameters,
}) => {
  try {
    const {
      baseCost,
      transportCost,
      freight,
      quantity,
      customDuty,
      lc,
      pf,
      bankInterest,
      creditPeriod,
    } = parameters;
    const formula = pattern
      .replaceAll('%{BASE}', 'baseCost')
      .replaceAll('%{TRANSPORT}', 'transportCost')
      .replaceAll('%{QNT}', 'quantity')
      .replaceAll('%{FREIGHT}', 'freight')
      .replaceAll('%{CUSTOM}', 'customDuty')
      .replaceAll('%{LC}', 'lc')
      .replaceAll('%{PF}', 'pf')
      .replaceAll('%{BANK-INTEREST}', 'bankInterest')
      .replaceAll('%{CREDIT-PERIOD}', 'creditPeriod');

    const ast = parse(formula); // abstract syntax tree (AST)
    const value = expEval(ast, {
      baseCost: Number(baseCost),
      transportCost: Number(transportCost || 0),
      quantity: Number(quantity || 1),
      freight: freight || 0,
      customDuty: customDuty || 0,
      lc: lc || 0,
      bankInterest: bankInterest || 0,
      creditPeriod: creditPeriod || 0,
      pf: pf || 0,
    });

    return {
      success: true,
      landingCost: value,
    };
  } catch (e) {
    return { success: false, message: (e as any).message || e };
  }
};

export { calculateLandingCost };
