import { PurchaseEnquiry } from '@erp_core/erp-types/dist/modules/order';
import { LoadingButton } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UsePurchaseEnquiries } from '../../../../../hooks/inventory/purchase/purchase-enquiry/use-purchase-enquiries';
import { UseVendor } from '../../../../../hooks/inventory/purchase/vendors/use-vendor';

export type DEProps = {
  purchaseEnquiry: PurchaseEnquiry;
  onClose: () => void;
};
export function renderDispatchEnquiry({
  usePurchaseEnquiries,
  setPurchaseEnquiry,
  useVendor,
}: {
  usePurchaseEnquiries: UsePurchaseEnquiries;
  setPurchaseEnquiry: (s: PurchaseEnquiry) => Promise<PurchaseEnquiry>;
  useVendor: UseVendor;
}): (p: DEProps) => JSX.Element {
  return function DispatchEnquiry({ purchaseEnquiry, onClose }): JSX.Element {
    const { getAllSync: getPEs } = usePurchaseEnquiries();
    const { getSync: getVendor } = useVendor();
    const [vendors, setVendors] = useState<{ id: string; emails: string[] }[]>(
      []
    );
    const [openEnquiries, setOpenEnquiries] = useState<PurchaseEnquiry[]>([]);
    useEffect(() => {
      getPEs({
        status: 'draft',
      }).then(async (peList) => {
        const vendors: Array<{ id: string; emails: string[] }> = [];
        for (const pe of peList) {
          for (const ven of pe.details.vendors) {
            let emailVen = vendors.find((x) => x.id === ven.id);
            if (!emailVen) {
              emailVen = { id: ven.id, emails: [] };
              vendors.push(emailVen);
            }

            if (emailVen) {
              const vendor = await getVendor(ven.id);
              console.log(
                'searching for',
                pe?.purchaseRequisitions[0]?.grade?.item?.id
              );
              const item = vendor.items?.find(
                (it) =>
                  it?.item?.id === pe?.purchaseRequisitions[0]?.grade?.item?.id
              );

              if (item && item.details?.contactPerson?.id) {
                const person = vendor.details?.contact?.find(
                  (c) => c.id === item.details?.contactPerson?.id
                );
                if (person) {
                  person.emails?.forEach((e) => {
                    if (!emailVen?.emails.includes(e)) {
                      emailVen?.emails.push(e);
                    }
                  });
                }
              }
            }
          }
        }
        setVendors(vendors);
        setOpenEnquiries(peList);
      });
      // eslint-disable-next-line
    }, []);

    return (
      <div>
        Are yout sure you want to dispatch {purchaseEnquiry.name} enquiries to:
        <div className='border border-gray-200 p-1'>
          {purchaseEnquiry.details?.vendors.map((x) => (
            <div className='flex' key={x.id}>
              <div className='w-1/3'>{x.name}</div>
              <div className='w-1/3'>
                {
                  openEnquiries?.filter((y) => {
                    if (y.details?.vendors?.find((z) => z.id === x.id)) {
                      return true;
                    }
                    return false;
                  }).length
                }{' '}
                items
              </div>
              <div className='w-1/3'>
                {vendors.find((v) => v.id === x.id)?.emails.join(', ')}
              </div>
            </div>
          ))}
        </div>
        <div className='flex justify-center'>
          <LoadingButton
            text='Yes'
            behaviourParams={{}}
            behaviorFn={async () => {
              await setPurchaseEnquiry({
                id: purchaseEnquiry.id,
                status: 'enquiry-open',
              } as any);
              onClose();
            }}
          />
        </div>
      </div>
    );
  };
}
