import { renderHistory } from '@erp_core/erp-ui-components';
import { UsePurchaseRequisition } from 'hooks/inventory/purchase/purchase-requisition/use-purchase-requisition';

export function renderHistoryTab({
  id,
  usePurchaseRequisition,
}: {
  id: string;
  usePurchaseRequisition: UsePurchaseRequisition;
}): () => JSX.Element {
  return function HistoryTab(): JSX.Element {
    const History = renderHistory();

    return <History useResource={usePurchaseRequisition} id={id} />;
  };
}
