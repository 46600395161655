import {
  SalaryAdvanceFilter,
  SalaryAdvanceType,
} from '@erp_core/erp-types/dist/types/modules/hrd/salary-advance';
import { SalaryAdvanceHttpRepo } from '../datalayer/repository/http/salary-advance';
import { createUseSalaryAdvanceEligibleAmt } from '../hooks/hrd/salary-advance/use-salary-advance-eligibleAmount';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { SalaryAdvanceInterface } from '../models/interfaces/hrd/salary-advance';
import { erpV2Api } from './admin';
import { SALARY_ADVANCE_SERVER_URI } from './server-uri';

export const salaryAdvanceService = new SalaryAdvanceHttpRepo(
  SALARY_ADVANCE_SERVER_URI,
  erpV2Api
);

export const useSalaryAdvance = createUseResource<
  SalaryAdvanceType,
  SalaryAdvanceFilter,
  SalaryAdvanceInterface
>({
  useService: () => salaryAdvanceService,
});

export const useSalaryAdvances = createUseResources<
  SalaryAdvanceType,
  SalaryAdvanceFilter,
  SalaryAdvanceInterface
>({
  useService: () => salaryAdvanceService,
});

export const useSalaryAdvanceEligibleAmt = createUseSalaryAdvanceEligibleAmt({
  useSalaryAdvanceEligibleAmtService: () => salaryAdvanceService,
});
