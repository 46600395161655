/* eslint-disable react-hooks/exhaustive-deps */

import {
  ConstantsFilterType,
  GSTType,
} from '@erp_core/erp-types/dist/modules/constants';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseGst } from '../../../hooks/admin/constants/gst/use-gst';
import { UsePaginatedGST } from '../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseGsts } from '../../../hooks/admin/constants/gst/use-gsts';
import { renderAddGSTForm } from './form/add-gst-form';

export type RenderGstProps = {
  useGsts: UseGsts;
  usePaginatedGST: UsePaginatedGST;
  useGst: UseGst;
};

export function renderGst({
  useGsts,
  usePaginatedGST,
  useGst,
}: RenderGstProps): () => JSX.Element {
  return function Gst(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      GSTType,
      ConstantsFilterType
    >();
    const { getAll: getGst } = useGsts();

    const { syncSet: setGst } = useGst();

    useEffect(() => {
      getGst();
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'GST',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add GST',
            behaviour: 'modal',
            modal: {
              title: 'Add GST',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveCurrency(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddGSTForm();

    const saveCurrency = async (form: Partial<GSTType>) => {
      try {
        const finalData = {
          ...form,
        } as GSTType;
        await setGst(finalData as GSTType);
        toast('Data added sucessfully');
        getGst();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [[{ name: 'Name' }, { name: 'Value' }]];

    const bodyMapper = (r: GSTType) => {
      const cells: Array<TableCell> = [{ value: r.name }, { value: r.value }];
      return {
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full h-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              useResources={useGsts}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedGST,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
