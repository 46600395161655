import { VouchersType } from '@erp_core/erp-types/dist/modules/accounts';
import {
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseLedgers } from '../../../../hooks/accounts/tally/ledger/use-ledgers';
import { UseVoucher } from '../../../../hooks/accounts/vouchers/use-voucher';
import { UseGodowns } from '../../../../hooks/inventory/godown-inventory/use-godowns';
import { UseGrades } from '../../../../hooks/inventory/grade/use-grades';
import { UseItemCategories } from '../../../../hooks/inventory/item/item-category/use-item-categories';
import { UseVendor } from '../../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseVendors } from '../../../../hooks/inventory/purchase/vendors/use-vendors';
import { UseClient } from '../../../../hooks/order/sales/client/use-client';
import { UseClients } from '../../../../hooks/order/sales/client/use-clients';
import { VouchersInterface } from '../../../../models/interfaces/accounts/vouchers/vouchers';
import { ItemInterface } from '../../../../models/interfaces/inventory/item';
import { CreateVoucher } from './vouchers';
import { renderConsumptionVoucher } from './vouchers/consumption-voucher';
import { renderGodownTransfer } from './vouchers/godown-transfer';
import { renderPurchaseOrder } from './vouchers/purchase-order';

export function renderTallyDaybook({
  voucherService,
  itemService,
  useVoucher,
  useLedgers,
  useVendor,
  useVendors,
  useItemCategories,
  useGrades,
  useGodowns,
  useClients,
  useClient,
}: {
  useVoucher: UseVoucher;
  itemService: ItemInterface;
  voucherService: VouchersInterface;
  useLedgers: UseLedgers;
  useVendor: UseVendor;
  useVendors: UseVendors;
  useClients: UseClients;
  useClient: UseClient;

  useItemCategories: UseItemCategories;
  useGrades: UseGrades;
  useGodowns: UseGodowns;
}) {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return function Daybook() {
    const [range, setRange] = useState<{
      start: string;
      end: string;
    }>({
      start: moment().subtract(3, 'M').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    });

    const [entries, setEntries] = useState<VouchersType[]>([]);
    const { syncSet: setVoucher } = useVoucher();

    useEffect(() => {
      voucherService
        .getDaybook({ from: range.start, to: range.end })
        .then((res) => {
          setEntries(
            res.sort((a, b) => {
              return moment(a.dateTime).diff(moment(b.dateTime));
            })
          );
        });
    }, [range]);

    return (
      <div>
        <Card
          header={{
            title: 'Daybook',
            actions: [
              {
                type: 'button',
                button: {
                  name: `${range.start} to ${range.end}`,
                  behaviour: 'modal',
                  modal: {
                    title: 'Change range',
                    content: ({ onClose }) => {
                      const Form = renderFormV2({
                        initialFormState: {
                          start: range.start,
                          end: range.end,
                        },
                        style: 'w-1/2',
                        fieldsData: [
                          { type: 'date', property: 'start', required: true },
                          { type: 'date', property: 'end', required: true },
                        ],
                        mapTToU: (t) => t,
                        onSubmit: async (form) => {
                          setRange(
                            (r) =>
                              ({
                                start: form.start || r.start || '',
                                end: form.end || r.end || '',
                              } as any)
                          );
                          onClose();
                        },
                      });
                      return <Form />;
                    },
                  },
                },
              },
              {
                type: 'button',
                button: {
                  name: 'Vouchers',
                  behaviour: 'modal',
                  modal: {
                    title: 'Create Voucher',
                    size: 'large',
                    content: ({ onClose }) => {
                      return (
                        <CreateVoucher
                          useGodowns={useGodowns}
                          itemService={itemService}
                          useGrades={useGrades}
                          useItemCategories={useItemCategories}
                          useVoucher={useVoucher}
                          useLedgers={useLedgers}
                          useVendors={useVendors}
                          useVendor={useVendor}
                          useClients={useClients}
                          useClient={useClient}
                          onClose={onClose}
                        />
                      );
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <Table
                  header={[
                    [
                      { name: 'Date', rowSpan: 2 },
                      { name: 'Particulars', rowSpan: 2 },
                      { name: 'Voucher Type', rowSpan: 2 },
                      { name: 'Voucher #', rowSpan: 2 },
                      { name: 'Debit Amount' },
                      { name: 'Credit Amount' },
                    ],
                    [
                      {
                        name: 'Inwards Qty',
                        style: 'border border-left-gray-100',
                      },
                      { name: 'Outwards Qty' },
                    ],
                  ]}
                  body={entries.map((x) => {
                    return {
                      rowData: {
                        voucher: x,
                      },
                      cells: [
                        { value: x.dateTime },
                        { value: getParticulars(x) },
                        { value: x.voucherType },
                        { value: x.voucherNumber },
                        { value: '' },
                        { value: '' },
                      ],
                    };
                  })}
                  actions={[
                    {
                      name: 'Edit',
                      behaviour: 'modal',
                      show: () => true,
                      modal: {
                        title: 'Edit',
                        size: 'large',
                        content: ({
                          data: { voucher },
                          onClose,
                        }: {
                          data: { voucher: VouchersType };
                          onClose: () => void;
                        }) => {
                          const PurchaseOrderVoucher = renderPurchaseOrder({
                            itemService,
                            useGrades,
                            useVendor,
                            useItemCategories,
                            useVendors,
                          });

                          if (voucher.voucherType === 'purchase-order') {
                            return (
                              <PurchaseOrderVoucher
                                po={voucher}
                                onSave={async (c) => {
                                  await setVoucher(c);
                                  onClose();
                                }}
                              />
                            );
                          }

                          const GodownTransferVoucher = renderGodownTransfer({
                            itemService,
                            useGrades,
                            useGodowns,
                          });

                          if (voucher.voucherType === 'godown-transfer') {
                            return (
                              <GodownTransferVoucher
                                gt={voucher}
                                onSave={async (c) => {
                                  await setVoucher(c);
                                  onClose();
                                }}
                              />
                            );
                          }

                          const ConsumptionVoucher = renderConsumptionVoucher({
                            itemService,
                            useGodowns,
                            useGrades,
                          });

                          if (voucher.voucherType === 'consumption-voucher') {
                            return (
                              <ConsumptionVoucher
                                gt={voucher}
                                onSave={async (c) => {
                                  await setVoucher(c);
                                  onClose();
                                }}
                              />
                            );
                          }

                          return <div></div>;
                        },
                      },
                    },
                  ]}
                />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}

function getParticulars(x: VouchersType) {
  const details =
    typeof x.details === 'string' ? JSON.parse(x.details) : x.details;
  if (x.voucherType === 'purchase-order') {
    return details.vendor?.name;
  }

  return x.name;
}
