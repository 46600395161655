/* eslint-disable react-hooks/exhaustive-deps */
import { ItemCategoryRes } from '@erp_core/erp-types/dist/modules/inventory';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import {
  ItemCategoryFilter,
  UseItemCategories,
} from '../../../hooks/inventory/item/item-category/use-item-categories';
import { UseItemCategory } from '../../../hooks/inventory/item/item-category/use-item-category';
import { UsePaginatedItemCategories } from '../../../hooks/inventory/item/item-category/use-paginated-item-categories';
import { renderAddItemCategoryForm } from './form/add-item-category';

export type RenderItemCategoryProps = {
  useItemCategories: UseItemCategories;
  usePaginatedItemCategories: UsePaginatedItemCategories;
  useItemCategory: UseItemCategory;
};

export function renderItemCategoryV2({
  useItemCategories,
  usePaginatedItemCategories,
  useItemCategory,
}: RenderItemCategoryProps): () => JSX.Element {
  return function ItemCategoryV2(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      ItemCategoryRes,
      ItemCategoryFilter
    >();
    const { getAll: getItemCategories } = useItemCategories();

    const { syncSet: setItemCategory } = useItemCategory();

    useEffect(() => {
      getItemCategories();
    }, []);
    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Item Category',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Item Category',
            behaviour: 'modal',
            modal: {
              title: 'Add Item Category',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveItemCategory(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddItemCategoryForm();

    const saveItemCategory = async (form: Partial<ItemCategoryRes>) => {
      try {
        const finalData = {
          ...form,
        } as ItemCategoryRes;
        await setItemCategory(finalData as ItemCategoryRes);
        toast('Data added sucessfully');
        getItemCategories();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Item Category Name' },
        { name: 'Short-Form' },
        { name: 'Description' },
      ],
    ];

    const bodyMapper = (r: ItemCategoryRes) => {
      const cells: Array<TableCell> = [
        { value: r.name },
        { value: r.id },
        { value: r.description },
      ];
      return {
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full h-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              useResources={useItemCategories}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedItemCategories,
              }}
            />
          </div>
        </div>
      ),
    };
    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
