import { LoadingButton } from '@erp_core/erp-ui-components';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import Calendar from '@toast-ui/react-calendar';
import moment from 'moment';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CurrentContext } from '../../../../../../contexts/current';
import { UseRotationalShiftDays } from '../../../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-days';
import { UseShiftSchedules } from '../../../../../../hooks/planning/shift-schedule/use-shift-schedules';
import { getTemplate, shiftColorDecider } from '../utils/calendar-template';

type CalendarShift = {
  id: string;
  calendarId: string;
  color: string;
  title: string;
  body: string;
  attendees: Array<string>;
  backgroundColor: string;
  category: 'time';
  borderColor: string;
  start: string;
  end: string;
};

export function renderShiftCalendar({
  useShifts,
  useShiftSchedules,
}: {
  useShifts: UseRotationalShiftDays;
  useShiftSchedules: UseShiftSchedules;
}): ({ id }: { id: string }) => JSX.Element {
  return function ShiftCalendar({ id }: { id: string }): JSX.Element {
    const { location: currentLocation } = useContext(CurrentContext);
    const [month, setMonth] = useState<moment.Moment>(moment().startOf('M'));
    const calendarRef = useRef<Calendar>(null);
    const {
      getAll: getShiftSchedules,
      data: shiftSchedules,
    } = useShiftSchedules();
    const { getAll: getShifts, data: shiftData } = useShifts();
    const [shifts, setShifts] = useState<Array<CalendarShift>>([]);
    const [calendars, setCalendars] = useState<
      {
        id: string;
        name: string;
        backgroundColor: string;
        borderColor: string;
      }[]
    >([]);

    useEffect(() => {
      getShifts({
        from: `more-than::${month.format('YYYY-MM')}-00`,
        to: `less-than::${month.format('YYYY-MM')}-${month.daysInMonth() + 1}`,
      });
      // eslint-disable-next-line
    }, [month]);

    useEffect(() => {
      if (shiftData?.length) {
        const filteredShifts = shiftData.filter(
          (x) => x.details?.workArea?.id === id
        );
        const newShifts: Array<CalendarShift> = [...shifts];
        filteredShifts.forEach((x) => {
          const found = newShifts.find((y) => y.id === x.id);
          if (!found) {
            const shiftName = x.details.shiftId?.name || 'default';
            const cal = shiftColorDecider(x.details.shiftId?.name || 'default');

            newShifts.push({
              id: x.id,
              calendarId: shiftName,
              color: '#000000',
              title: x.employee.name,
              body: shiftName,
              attendees: [x.employee.name],
              backgroundColor: cal.backgroundColor,
              category: 'time',
              borderColor: '',
              start: `${x.date}${cal.start}`,
              end: `${x.date}${cal.end}`,
            });
          }
        });

        setShifts(newShifts);
      }
      // eslint-disable-next-line
    }, [shiftData]);

    useEffect(() => {
      if (shifts.length) {
        calendarRef.current?.getInstance()?.clear();
        calendarRef.current?.getInstance()?.createEvents(shifts);
      }
    }, [shifts]);

    const beforeCreateEvent: any = useCallback((scheduleData) => {
      // calendarRef?.current?.getInstance()?.createEvents();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      getShiftSchedules({
        location: currentLocation?.name?.toLowerCase().trim(),
      });
      // eslint-disable-next-line
    }, [currentLocation]);

    useEffect(() => {
      if (shiftSchedules?.length) {
        const cals: Array<{
          id: string;
          name: string;
          backgroundColor: string;
          borderColor: string;
        }> = [];
        shiftSchedules.forEach((x) => {
          const cal = shiftColorDecider(x.name);
          cals.push({
            id: x.id,
            name: x.name,
            backgroundColor: cal.backgroundColor,
            borderColor: cal.borderColor,
          });
        });
        setCalendars(cals);
      }
    }, [shiftSchedules]);

    return (
      <div className='m-1 p-1 border border-gray-200 rounded-lg'>
        <div className='flex flex-row-reverse'>
          <LoadingButton
            defaultStyle='bg-slate-100 p-2 border border-gray-200 rounded-sm'
            behaviorFn={async () => {
              const newMoment = moment(month.add(1, 'M'));
              calendarRef.current?.getInstance()?.setDate(newMoment.toDate());
              setMonth(newMoment);
            }}
            text={
              <>
                Next <ChevronRightIcon className='inline w-4' />
              </>
            }
          />
          <div className='my-auto mx-2 w-24 text-center'>
            {month.format('MMMM')}
          </div>
          <LoadingButton
            defaultStyle='bg-slate-100 p-2 border border-gray-200 rounded-sm'
            behaviorFn={async () => {
              const newMoment = moment(month.subtract(1, 'M'));
              calendarRef.current?.getInstance()?.setDate(newMoment.toDate());
              setMonth(newMoment);
            }}
            text={
              <>
                <ChevronLeftIcon className='inline w-4' /> Prev{' '}
              </>
            }
          />
        </div>
        <Calendar
          usageStatistics={false}
          ref={calendarRef}
          isReadOnly={true}
          useDetailPopup={true}
          useFormPopup={false}
          onClickSchedule={() => {
            console.log('sch clicked');
          }}
          onBeforeCreateEvent={beforeCreateEvent}
          onBeforeDeleteEvent={() => {
            console.log('before delete');
          }}
          onBeforeUpdateEvent={() => {
            console.log('before update');
          }}
          onAfterRenderEvent={() => {
            console.log('after render');
          }}
          view={'month'}
          month={
            {
              // visibleWeeksCount: 2,
            }
          }
          week={{
            taskView: false,
            eventView: ['time'],
            startDayOfWeek: 1,
          }}
          calendars={calendars}
          events={[]}
          template={getTemplate()}
        />
      </div>
    );
  };
}
