import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UsePolicies } from '../../../../hooks/hrd/insurance/policy/use-policies';
import { UsePolicy } from '../../../../hooks/hrd/insurance/policy/use-policy';
import { UsePremiums } from '../../../../hooks/hrd/insurance/premiums/use-premiums';
import { UseSalaryAdvance } from '../../../../hooks/hrd/salary-advance/use-salary-advance';
import { UseSalaryAdvanceEligibleAmt } from '../../../../hooks/hrd/salary-advance/use-salary-advance-eligibleAmount';
import { UseSalaryAdvances } from '../../../../hooks/hrd/salary-advance/use-salary-advances';
import { UseLoan } from '../../../../hooks/loans/loan/use-loan';
import { UseLoans } from '../../../../hooks/loans/loan/use-loans';
import { UseAdvPaymentData } from '../../../../hooks/loans/repayment-schedule/use-advance-payment';
import { UseRepaymentSchedule } from '../../../../hooks/loans/repayment-schedule/use-repayment-schedule';
import { UseRepaymentSchedules } from '../../../../hooks/loans/repayment-schedule/use-repayment-schedules';
import { UseSkipRepaymentData } from '../../../../hooks/loans/repayment-schedule/use-repayment-skipped';
// import { renderAdvance } from './loan-advance-tab/advance';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderCompanyLoan } from './loan-advance-tab/company';
import { renderEmpInsurance } from './loan-advance-tab/emp-insurance';
import { renderInsurance } from './loan-advance-tab/insurance';
import { renderEmployeeLoan } from './loan-advance-tab/loan';
import { renderEmployeeSalaryAdvance } from './loan-advance-tab/salary-advance';

type CreateEmployeeLoanAdvanceCardType = {
  data: EmployeeProfileType;
  useSalaryAdvances: UseSalaryAdvances;
  useSalaryAdvance: UseSalaryAdvance;
  useSalaryAdvanceEligibleAmt: UseSalaryAdvanceEligibleAmt;
  usePolicy: UsePolicy;
  usePolicies: UsePolicies;
  usePremiums: UsePremiums;
  useLoan: UseLoan;
  useLoans: UseLoans;
  useFileTransfer: UseFileTransfer;
  useRepaymentSchedules: UseRepaymentSchedules;
  useSkipRepaymentData: UseSkipRepaymentData;
  useAdvPaymentData: UseAdvPaymentData;
  useRepaymentSchedule: UseRepaymentSchedule;
  setEmployee: (s: EmployeeProfileType) => void;
  userRendererService: UserRendererInterface;
};

export function renderEmployeeLoanAdvance({
  data,
  setEmployee,
  useSalaryAdvances,
  useSalaryAdvance,
  useSalaryAdvanceEligibleAmt,
  usePolicy,
  usePolicies,
  usePremiums,
  useLoan,
  useLoans,
  useFileTransfer,
  useRepaymentSchedules,
  useSkipRepaymentData,
  useAdvPaymentData,
  useRepaymentSchedule,
  userRendererService,
}: CreateEmployeeLoanAdvanceCardType): (props: {
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
  isHr;
}) => JSX.Element {
  // const Advance = renderAdvance();
  const Insurance = renderInsurance({
    employee: data,
    setEmployee,
  });
  const SalaryAdvance = renderEmployeeSalaryAdvance();

  const EmpInsurance = renderEmpInsurance();

  const Loan = renderEmployeeLoan();

  return function EmployeeLoanAdvance({
    actions,
    isHr,
  }: {
    isHr;
    actions: {
      [key: string]: {
        action: boolean;
        locations?: string[] | undefined;
      };
    };
  }): JSX.Element {
    const CompanyLoan = renderCompanyLoan({
      employee: data,
      setEmployee,
      actions,
      isHr,
    });
    return (
      <>
        <div>
          <Loan
            userRendererService={userRendererService}
            actions={actions}
            useLoans={useLoans}
            useLoan={useLoan}
            employee={data}
            useRepaymentSchedules={useRepaymentSchedules}
            useRepaymentSchedule={useRepaymentSchedule}
          />
        </div>
        <br />
        <div>
          <CompanyLoan id={data.id} />
        </div>
        <br />
        {/* <div>
          <Advance employee={data} setEmployee={setEmployee} />
        </div> */}
        <br />
        <div>
          <Insurance id={data.id} actions={actions} isHr={isHr} />
        </div>
        <br />
        <div>
          <SalaryAdvance
            useSalaryAdvances={useSalaryAdvances}
            useSalaryAdvance={useSalaryAdvance}
            employee={data}
            useSalaryAdvanceEligibleAmt={useSalaryAdvanceEligibleAmt}
            actions={actions}
          />
        </div>
        <br />
        <div>
          <EmpInsurance
            userRendererService={userRendererService}
            usePolicy={usePolicy}
            usePolicies={usePolicies}
            usePremiums={usePremiums}
            useFileTransfer={useFileTransfer}
            employee={data}
          />
        </div>
      </>
    );
  };
}
