import { DepartmentAppType } from '@erp_core/erp-types/dist/modules/hrd';
import { Grade } from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import {
  DateSelector,
  renderCardComponent,
  renderSearchBox,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseDepartments } from '../../../../hooks/hrd/department/use-departments';
import { UseGrades } from '../../../../hooks/inventory/grade/use-grades';

export function renderCashFlowDetails({
  useGrades,
  useDepartments,
}: {
  useGrades: UseGrades;
  useDepartments: UseDepartments;
}) {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return function CashFlowDetails() {
    const [resource, selectResource] = useState<'all' | 'department' | 'grade'>(
      'all'
    );
    const [view, selectView] = useState<
      'detailed' | 'monthly' | 'quarterly' | 'yearly'
    >('detailed');

    const [header, setHeader] = useState<TableHeader>([]);
    const [body, setBody] = useState<TableBody>([]);

    const [selectedDepartment, setSelectedDepartment] = useState<
      DepartmentAppType | undefined
    >();
    const DepartmentSearchBox = renderSearchBox<DepartmentAppType>({
      useSearchValues: useDepartments,
      onSearchValueSelect: (x) => {
        setSelectedDepartment(x);
      },
    });

    const [selectedGrade, setSelectedGrade] = useState<Grade | undefined>();
    const GradeSearchBox = renderSearchBox<Grade>({
      useSearchValues: useGrades,
      onSearchValueSelect: (x) => {
        setSelectedGrade(x);
      },
    });

    const [duration, setDuration] = useState<{ from: string; to: string }>({
      from: '2023-04-01',
      to: moment().format('YYYY-MM-DD'),
    });

    const [cashflow, setCashflow] = useState<{
      resource: string;
      name: string;
      view: string;
    }>({
      resource: '',
      name: '',
      view: '',
    });

    useEffect(() => {
      const res = {
        resource: '',
        name: '',
        view: '',
      };
      if (resource === 'all') {
        res.resource = 'All Resources';
      } else {
        res.name = resource;
      }

      if (resource === 'department' && selectedDepartment) {
        res.name += '-' + selectedDepartment.name;
      }

      if (resource === 'grade' && selectedGrade) {
        res.name += '-' + selectedGrade.name;
      }

      res.view = view;

      setCashflow(res);
    }, [resource, view, selectedDepartment, selectedGrade]);

    useEffect(() => {
      const months: Array<string> = ['Opening Balance'];

      setHeader([
        [
          { name: 'Particulars', style: 'w-1/3', rowSpan: 2 },
          {
            name: 'Debit',
            style: 'text-center w-1/6 border-l border-gray-200',
            colSpan: 2,
          },
          {
            name: 'Credit',
            style: 'text-center w-1/6',
            colSpan: 2,
          },
          {
            name: 'Closing Balance',
            style: 'text-center w-1/6',
            colSpan: 2,
          },
          {
            name: 'Summary',
            style: 'text-center w-1/6',
            colSpan: 1,
            rowSpan: 2,
          },
        ],
        [
          {
            name: 'Estimated',
            style: 'border-l border-gray-200',
          },
          { name: 'Actual', style: '' },
          {
            name: 'Estimated',
            style: 'text-center text-gray-500',
          },
          {
            name: 'Actual',
            style: 'text-center text-gray-500',
          },
          {
            name: 'Estimated',
            style: 'text-center text-gray-500',
          },
          {
            name: 'Actual',
            style: 'text-center text-gray-500',
          },
        ],
      ]);

      setBody(
        months.map((x) => {
          return {
            cells: [
              { value: x },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
            ],
          };
        })
      );
    }, [view, cashflow]);

    return (
      <div>
        <Card
          header={{
            title: 'Cashflow Details',
            subheading: (
              <div className='flex'>
                <div className='border border-gray-200 rounded-lg p-1'>
                  <div className='text-center'>Duration</div>
                  <div className='flex'>
                    <DateSelector
                      initialState={duration.from}
                      max={moment().format('YYYY-MM-DD')}
                      format='YYYY-MM-DD'
                      onChange={(date) =>
                        setDuration((d) => {
                          return { to: d.to, from: date };
                        })
                      }
                    />

                    <DateSelector
                      initialState={duration.to}
                      max={moment().format('YYYY-MM-DD')}
                      format='YYYY-MM-DD'
                      onChange={(date) =>
                        setDuration((d) => {
                          return { to: date, from: d.from };
                        })
                      }
                    />
                  </div>
                </div>
                <div className='border border-gray-200 rounded-lg p-1'>
                  <div className='text-center'>Resource</div>
                  <select
                    value={resource}
                    onChange={(evt) => selectResource(evt.target.value as any)}
                    className='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                  >
                    <option value='all'>All</option>
                    <option value='department'>Department</option>
                    <option value='grade'>Grade</option>
                  </select>
                </div>
                {resource === 'department' ? (
                  <div className='border border-gray-200 rounded-lg p-1'>
                    <div className='text-center'>Department</div>
                    <DepartmentSearchBox
                      currentSelected={selectedDepartment}
                      searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                    />
                  </div>
                ) : null}
                {resource === 'grade' ? (
                  <div className='border border-gray-200 rounded-lg p-1'>
                    <div className='text-center'>Grade</div>
                    <GradeSearchBox
                      currentSelected={selectedGrade}
                      searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                    />
                  </div>
                ) : null}
                <div className='border border-gray-200 rounded-lg p-1'>
                  <div className='text-center'>View</div>
                  <select
                    value={view}
                    onChange={(evt) => selectView(evt.target.value as any)}
                    className='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                  >
                    <option value='monthly'>Month</option>
                    <option value='quarterly'>Quarter</option>
                    <option value='yearly'>Year</option>
                  </select>
                </div>
                {/* <div className='border border-gray-200 rounded-lg p-1'>
                  <div className='text-center'>Area</div>
                  <select
                    value={area}
                    onChange={(evt) => selectArea(evt.target.value as any)}
                    className='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                  >
                    <option value='current-location'>Current Location</option>
                    <option value='all-location'>All Location</option>
                    <option value='godown'>Godown</option>
                  </select>
                </div> */}
                {/* {area === 'godown' ? (
                  <div className='border border-gray-200 rounded-lg p-1'>
                    <div className='text-center'>Godown</div>
                    <GodownSearchBox
                      currentSelected={selectedGodown}
                      searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm w-64'
                    />
                  </div>
                ) : null} */}
              </div>
            ),
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <Table header={header} body={body} />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
