import { Hourglass } from '@erp_core/erp-icons/icons/web/hourglass';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  Log,
  renderCardComponent,
  renderPureHistory,
} from '@erp_core/erp-ui-components';
import {
  ArrowsPointingOutIcon,
  CheckIcon,
  CurrencyRupeeIcon,
  ReceiptPercentIcon,
  ScissorsIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { UseLoan } from '../../../../hooks/loans/loan/use-loan';
import { LoanInterface } from '../../../../models/interfaces/loans/loan';

export function renderLoanDetails({
  useLoan,
}: {
  loanService: LoanInterface;
  useLoan: UseLoan;
}) {
  const Card = renderCardComponent();
  const History = renderPureHistory();

  return function LoanDetails(): JSX.Element {
    const { id } = useParams();
    const { get: getLoan, data: loan } = useLoan();
    useEffect(() => {
      if (id) {
        getLoan(id);
      }
      // eslint-disable-next-line
    }, [id]);
    return (
      <div>
        <Card
          header={{
            title: `Loan Details of ${loan?.employee?.name}`,
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                {loan ? (
                  <div>
                    <div className='flex'>
                      <div className='w-1/4 border-2 border-gray-100 p-2'>
                        <div className='text-center font-semibold'>
                          Loan Requests
                        </div>
                        {loan?.details?.loanRequests.map((x, idx) => (
                          <div key={idx}>
                            <CurrencyRupeeIcon className='inline w-5 text-green-500 mx-1' />
                            {x.amount} requested on{' '}
                            {moment(x.date).format('YYYY-MM-DD')} -
                            <Icon status={x.status} />
                          </div>
                        ))}
                      </div>
                      <div className='w-1/4 border-2 border-gray-100 p-2'>
                        <div className='text-center font-semibold'>
                          Disbursements
                        </div>
                        {loan?.details?.disbursements.map((x, idx) => (
                          <div key={idx}>
                            <ArrowsPointingOutIcon className='inline w-5 text-blue-500 mx-1' />
                            {x.amount} from{' '}
                            {moment(x.date).format('YYYY-MM-DD')} -
                            <Icon status={x.status} />
                          </div>
                        ))}
                        <div className='text-center font-semibold'>
                          Pending Disbursements
                        </div>
                        <div className='text-center'>
                          <CurrencyRupeeIcon className='inline w-5 text-orange-500 mx-1' />
                          {loan.details?.pendingDisbursements}
                        </div>
                      </div>
                      <div className='w-1/4 border-2 border-gray-100 p-2'>
                        <div className='text-center font-semibold'>
                          Interest Rates
                        </div>
                        {loan?.details?.interestRates.map((x, idx) => (
                          <div key={idx}>
                            <ReceiptPercentIcon className='inline w-5 text-blue-500 mx-1' />
                            {x.rate}% from {moment(x.date).format('YYYY-MM-DD')}{' '}
                            -
                            <Icon status={x.status} />
                          </div>
                        ))}
                      </div>
                      <div className='w-1/4 border-2 border-gray-100 p-2'>
                        <div className='text-center font-semibold'>
                          Monthly Deductions
                        </div>
                        {loan?.details?.monthlyDeductions.map((x, idx) => (
                          <div key={idx}>
                            <ScissorsIcon className='inline w-5 text-green-500 mx-1' />
                            {x.amount} from{' '}
                            {moment(x.date).format('YYYY-MM-DD')} -
                            <Icon status={x.status} />
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className='my-2'>
                      <div className='text-center text-2xl'>Activities</div>
                      {loan.details?.activities ? (
                        <History
                          logs={getActivities(loan.details.activities)}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            ),
          }}
        />
      </div>
    );
  };
}

function computeDetails(x: {
  name: string;
  by: IdName;
  at: string;
  details: any;
}) {
  if (x.name === 'loan-approved') {
    return `Loan was approved at interest rate of ${x.details?.interestRate}`;
  }

  if (x.name === 'disbursement-requested') {
    return `A disbursement of ${x.details?.amount} was requested`;
  }

  if (x.name === 'disbursement-approved') {
    return `Disbursment of ${x.details?.amount} was approved`;
  }

  if (x.name === 'topup-requested') {
    return `A top-up of ${x.details?.amount} was requested for the reason: ${x.details?.reason}`;
  }

  if (x.name === 'topup-request-approved') {
    return `Top-up of ${x.details?.amount} was approved`;
  }

  if (x.name === 'adhoc-repayment-requested') {
    return `An Adhoc repayment amount ${
      x.details?.amount
    } was requested for ${moment(x.details?.month).format('MMM DD, YYYY')}`;
  }

  if (x.name === 'adhoc-repayment-approved') {
    return 'Adhoc repayment amount was approved';
  }

  if (x.name === 'skip-repayment-requested') {
    return `Skip repayment was requested for ${moment(x.details?.month).format(
      'MMM, YYYY'
    )} for reason: ${x.details?.reason}`;
  }

  if (x.name === 'skip-repayment-approved') {
    return 'Skip repayment was approved';
  }

  if (x.name === 'edit-repayment-requested') {
    return `Edit repayment of ${x.details?.amount} was requested for ${moment(
      x.details?.month
    ).format('MMM, YYYY')}`;
  }

  if (x.name === 'edit-repayment-approved') {
    return 'Edit repayment was approved';
  }

  if (x.name === 'edit-monthly-deduction-requested') {
    return `Edit monthly deduction was requested of ${
      x.details?.amount
    } from ${moment(x.details?.date).format('YYYY-MM-DD')}`;
  }

  if (x.name === 'edit-monthly-deduction-approved') {
    return 'Edit monthly deduction was approved';
  }

  if (x.name === 'edit-interest-rate-requested') {
    return `Edit Interest Rate was requested of ${
      x.details?.rate
    } from ${moment(x.details?.date).format('YYYY-MM-DD')}`;
  }

  if (x.name === 'edit-interest-rate-approved') {
    return 'Edit interest rate was approved';
  }

  return '';
}

function getActivities(
  activities: {
    name: string;
    by: IdName;
    at: string;
    details: any;
  }[]
): Log[] {
  return activities.map((x) => {
    return {
      event: x.name,
      time: x.at,
      details: <>{computeDetails(x)}</>,
      action: 'some action',
      user: x.by,
    };
  });
}

function Icon({ status }: { status: string }): JSX.Element {
  switch (status) {
    case 'pending':
      return (
        <span className='mx-1 px-1 border border-orange-500 rounded-md'>
          <Hourglass className='w-5 mx-1 text-orange-500 inline' />
          {status}
        </span>
      );
    case 'approved':
      return (
        <span className='mx-1 px-1 border border-green-500 rounded-md'>
          <CheckIcon className='w-5 text-green-500 inline' />
          {status}
        </span>
      );
    case 'rejected':
      return (
        <span className='mx-1 px-1 border border-red-500 rounded-md'>
          <XMarkIcon className='w-5 text-red-500 inline' />
          {status}
        </span>
      );
    default:
      return <></>;
  }
}
