import { ActionType } from '@erp_core/erp-types/dist/modules/admin';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseActionAdmin } from '../../../hooks/admin/action-admin/use-action-admin';
import { UseActions } from '../../../hooks/admin/action-admin/use-actions-admin';
import { renderEditActionForm } from './edit-action-form';

export type RenderActionAdminProps = {
  useActions: UseActions;
  useActionAdmin: UseActionAdmin;
};

export function renderActionAdmin({
  useActions,
  useActionAdmin,
}: RenderActionAdminProps): () => JSX.Element {
  return function ActionAdmin(): JSX.Element {
    const { data, getAll: getActions } = useActions();
    useEffect(() => {
      getActions();
      // eslint-disable-next-line
    }, []);
    const closeEditModal = () => {
      getActions();
    };

    const EditActionForm = renderEditActionForm({
      useActionAdmin,
      closeEditModal,
    });

    const { delete: deleteActionAdmin } = useActionAdmin();

    const deleteAction = (r: ActionType) => {
      deleteActionAdmin(r.id);
      getActions();
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Action Admin Screen',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Action',
            behaviour: 'modal',
            modal: {
              title: 'Add Action',
              content: ({ onClose }) => {
                const ac: ActionType = {
                  id: '',
                  name: '',
                  description: '',
                  isSystemAction: false,
                } as ActionType;
                return <EditActionForm r={ac} onClose={() => onClose()} />;
              },
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Description' },

        // { name: 'Edit',  },
        // { name: 'Delete',  },
      ],
    ];

    const tableBody: TableBody = data
      ? data.map((r) => ({
          rowData: { action: r },
          cells: [{ value: r.name }, { value: r.description }],
        }))
      : [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Edit',
                  show: (r) => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit',
                    content: ({ data, onClose }) => {
                      return (
                        <EditActionForm r={data.action} onClose={onClose} />
                      );
                    },
                  },
                },
                {
                  name: 'Delete',
                  show: (r) => true,
                  behaviour: 'confirm',
                  onConfirm: ({ action }) => {
                    return {
                      title: 'Are you sure you want to delete action?',
                      type: 'warning',
                      onConfirm: async () => {
                        await deleteAction(action);
                      },
                    };
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
