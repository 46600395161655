import { BomDependency } from '@erp_core/erp-types/dist/modules/inventory';
import {
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { UseGrades } from '../../../../hooks/inventory/grade/use-grades';
import { UseBomDepedencies } from '../../../../hooks/order/work-order/bom-dependency/use-bom-dependencies';
import { UseBomDepedency } from '../../../../hooks/order/work-order/bom-dependency/use-bom-dependency';
import { UseBomFlow } from '../../../../hooks/order/work-order/bom-flow/use-bom-flow';
import { renderAddBomDependency } from './forms/add-bom-dependency';

export function renderBomFlowDetails({
  useBomFlow,
  useBomDependencies,
  useGrades,
  useBomDependency,
}: {
  useBomFlow: UseBomFlow;
  useBomDependencies: UseBomDepedencies;
  useBomDependency: UseBomDepedency;
  useGrades: UseGrades;
}): () => JSX.Element {
  const Card = renderCardComponent();
  const Table = renderTableComponent();

  return function BomFlowDetails(): JSX.Element {
    const { id } = useParams();
    const { data: bomFlow, get: getBomFlow } = useBomFlow();
    const {
      data: bomDependencies,
      getAll: getDependencies,
    } = useBomDependencies();
    const { syncSet: setBomDependency } = useBomDependency();
    useEffect(() => {
      if (id) {
        getBomFlow(id);
        getDependencies({
          getAll: true,
          bomFlow: id,
        });
      }
      // eslint-disable-next-line
    }, [id]);

    return (
      <div>
        <Card
          header={{
            title: bomFlow?.name,
            subheading: bomFlow?.description,
            actions: [
              {
                type: 'button',
                button: {
                  name: 'Add',
                  behaviour: 'modal',
                  modal: {
                    title: 'Add Bom Dependency',
                    content: ({ onClose }) => {
                      const Form = renderAddBomDependency();
                      return (
                        <Form
                          save={async (data) => {
                            await setBomDependency({
                              name: data.name,
                              bomFlow: bomFlow,
                              consumedGrade: data.consumedGrade,
                              producedGrade: data.producedGrade,
                              consumptionRatio: data.consumptionRatio,
                              costContribution: data.costContribution,
                              processId: data.processId,
                            } as BomDependency);
                            onClose();
                          }}
                          useGrades={useGrades}
                        />
                      );
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <Card
                  header={{ title: 'Bom Dependencies', actions: [] }}
                  body={{
                    type: 'jsx-component',
                    body: (
                      <div>
                        <Table
                          header={[
                            [
                              {
                                name: 'Consumed Product',
                              },
                              {
                                name: 'Produced Product',
                              },
                              {
                                name: 'Process',
                              },
                              {
                                name: 'Ratio',
                              },
                            ],
                          ]}
                          body={(bomDependencies || []).map((x) => {
                            return {
                              cells: [
                                { value: x.consumedGrade.name },
                                { value: x.producedGrade.name },
                                { value: x.processId },
                                { value: x.consumptionRatio },
                              ],
                            };
                          })}
                        />
                      </div>
                    ),
                  }}
                />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
