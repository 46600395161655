import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import _ from 'lodash';
// import { BankAccountDetail } from '@erp_core/erp-types/dist/types/modules/admin/company';
import {
  EmployeeRegistrationFilter,
  EmployeeRegistrationType,
} from '@erp_core/erp-types/dist/types/modules/hrd/employee-registration';
import { SalaryType } from '@erp_core/erp-types/dist/types/modules/payroll/salary';
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import moment from 'moment';
import { SRType } from '../components/create-statutory-reports';
import { findParamType, formatParam } from './generate-salary';

export type StatutoryReportType = {
  type:
    | 'bank-report'
    | 'esic'
    | 'epf'
    | 'pt'
    | 'lic'
    | 'mlwf'
    | 'bank-loan'
    | 'pt-summary'
    | 'salary-summary'
    | 'loans-recovery'
    | 'retention-deductions'
    | 'advance-recovery'
    | 'tds-report'
    | 'bonus-retentions-report';
  owner:
    | 'ICICI INR'
    | 'DBS INR'
    | 'AU BANK'
    | 'LIC'
    | 'ESIC'
    | 'EPF'
    | 'MLWF'
    | 'PT'
    | 'CATAPHARMA';
  isApplicable: (data: any) => boolean;
  fileName: () => string;
  lineBreak: '\n' | '\r\n' | '#~#\n';
  details: {
    type: 'summary' | 'detailed';
    separator: 'comma' | 'semi-colon' | 'hash-tilda';
    rows: Array<{
      type: 'header' | 'employee-details' | 'summary';
      values: Array<{ value: (data: any) => Promise<string> }>;
    }>;
    recordEligibility: (data: any) => boolean;
  };
  fileType: string;
  ext: string;
};

export type ExtSal = SalaryType & {
  selectedSalaryParam: SalaryParamType;
};

export async function createStatutoryReports(
  // data: Array<{
  //   employee: EmployeeProfileType;
  //   salary: ExtSal;
  //   bank: BankAccountDetail;
  // }>,
  // month: string
  { data, month }: SRType,
  name: string,
  docs: Array<
    | 'bank-report'
    | 'esic'
    | 'epf'
    | 'pt'
    | 'lic'
    | 'mlwf'
    | 'bank-loan'
    | 'pt-summary'
    | 'salary-summary'
    | 'loans-recovery'
    | 'retention-deductions'
    | 'advance-recovery'
    | 'tds-report'
    | 'bonus-retentions-report'
  >,
  getRegistrations: (
    filter?: EmployeeRegistrationFilter
  ) => Promise<EmployeeRegistrationType[]>
): Promise<
  Array<{
    name: string;
    data: string;
    fileType: string;
    ext: string;
  }>
> {
  let momentUnix = moment().unix();
  const reports: Array<StatutoryReportType> = [
    {
      type: 'retention-deductions',
      owner: 'CATAPHARMA',
      isApplicable: (data) => {
        if (
          data.find((x) =>
            ['approved', 'sent-for-release', 'released'].includes(
              x.salary?.status
            )
          )
        ) {
          return true;
        }

        return false;
      },
      fileType: 'text/csv',
      ext: 'csv',
      fileName: () =>
        `${data[0].company.shortName}-${month}-retention-deductions.csv`,
      lineBreak: '\n',
      details: {
        type: 'detailed',
        separator: 'comma',
        rows: [
          {
            type: 'header',
            values: [
              {
                value: async (data) => {
                  return data[0].company?.name;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              {
                value: async () =>
                  moment(month, 'YYYY-MM').format('MMMM - YYYY'),
              },
            ],
          },
          {
            type: 'header',
            values: [
              {
                value: async () => {
                  const address = data[0].company?.details?.address;
                  return `${address.addressLine1}, ${address.addressLine2}, ${address.city} - ${address.pincode}, ${address.state}`;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
            ],
          },
          {
            type: 'header',
            values: [
              { value: async () => 'Employee Id' },
              { value: async () => 'Category' },
              { value: async () => 'Name' },
              { value: async () => 'Retention Deduction' },
            ],
          },
          {
            type: 'employee-details',
            values: [
              {
                value: async (x) => {
                  return x.employee.details.employeeId;
                },
              },
              {
                value: async (x) => {
                  return x.category;
                },
              },
              {
                value: async (x) => {
                  return x.employee.name;
                },
              },
              {
                value: async (x) => {
                  const deductions =
                    x.salary.details?.salaryParams?.deduction
                      ?.retentionTakeHomeDeduction ||
                    // x.salary.details?.salaryParams?.other?.retentionAmount ||
                    // x.salary.details?.salaryParams?.deduction
                    // ?.otherDeductions ||
                    0;

                  return `${deductions}`;
                },
              },
            ],
          },
        ],
        recordEligibility: (data) => {
          return (
            data.salary &&
            data.salary.details?.salaryParams?.deduction
              ?.retentionTakeHomeDeduction > 0
            // ||
            // data.salary.details?.salaryParams?.other?.retentionAmount > 0 ||
            // data.salary.details?.salaryParams?.deduction?.otherDeductions > 0
          );
        },
      },
    },
    {
      type: 'bonus-retentions-report',
      owner: 'CATAPHARMA',
      isApplicable: (data) => {
        if (
          data.find((x) =>
            ['approved', 'sent-for-release', 'released'].includes(
              x.salary?.status
            )
          )
        ) {
          return true;
        }

        return false;
      },
      fileType: 'text/csv',
      ext: 'csv',
      fileName: () =>
        `${data[0].company.shortName}-${month}-bonus-retention.csv`,
      lineBreak: '\n',
      details: {
        type: 'detailed',
        separator: 'comma',
        rows: [
          {
            type: 'header',
            values: [
              {
                value: async (data) => {
                  return data[0].company?.name;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              {
                value: async () =>
                  moment(month, 'YYYY-MM').format('MMMM - YYYY'),
              },
            ],
          },
          {
            type: 'header',
            values: [
              {
                value: async () => {
                  const address = data[0].company?.details?.address;
                  return `${address.addressLine1}, ${address.addressLine2}, ${address.city} - ${address.pincode}, ${address.state}`;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
            ],
          },
          {
            type: 'header',
            values: [
              { value: async () => 'Employee Id' },
              { value: async () => 'Name' },
              { value: async () => 'Bonus Deductions' },
              { value: async () => 'Ex-Gratia Paid (Monthly' },
              { value: async () => 'ex-Gratia Paid (Quarterly)' },
            ],
          },
          {
            type: 'employee-details',
            values: [
              {
                value: async (x) => {
                  return x.employee.details.employeeId;
                },
              },
              {
                value: async (x) => {
                  return x.employee.name;
                },
              },
              {
                value: async (x) => {
                  return `${
                    x.salary.details?.salaryParams?.earning?.bonus || 0
                  }`;
                },
              },
              {
                value: async (x) => {
                  return `${
                    x.salary.details?.salaryParams?.earning?.exGratiaMonthly ||
                    0
                  }`;
                },
              },
              {
                value: async (x) => {
                  // TODO
                  return `${0}`;
                },
              },
            ],
          },
        ],
        recordEligibility: (data) => {
          return (
            (data.salary &&
              data.salary.details?.salaryParams?.earning?.bonus > 0) ||
            data.salary.details?.salaryParams?.earning?.exGratiaMonthly > 0
          );
        },
      },
    },
    {
      type: 'tds-report',
      owner: 'CATAPHARMA',
      isApplicable: (data) => {
        if (
          data.find((x) =>
            ['approved', 'sent-for-release', 'released'].includes(
              x.salary?.status
            )
          )
        ) {
          return true;
        }

        return false;
      },
      fileType: 'text/csv',
      ext: 'csv',
      fileName: () => `${data[0].company.shortName}-${month}-tds-report.csv`,
      lineBreak: '\n',
      details: {
        type: 'detailed',
        separator: 'comma',
        rows: [
          {
            type: 'header',
            values: [
              {
                value: async (data) => {
                  return data[0].company?.name;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              {
                value: async () =>
                  moment(month, 'YYYY-MM').format('MMMM - YYYY'),
              },
            ],
          },
          {
            type: 'header',
            values: [
              {
                value: async () => {
                  const address = data[0].company?.details?.address;
                  return `${address.addressLine1}, ${address.addressLine2}, ${address.city} - ${address.pincode}, ${address.state}`;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
            ],
          },
          {
            type: 'header',
            values: [
              { value: async () => 'Employee Id' },
              { value: async () => 'Category' },
              { value: async () => 'Name' },
              { value: async () => 'Gross Earnings' },
              { value: async () => 'Income Tax Deductions' },
              { value: async () => 'Bonus Disbursed' },
            ],
          },
          {
            type: 'employee-details',
            values: [
              {
                value: async (x) => {
                  return x.employee.details.employeeId;
                },
              },
              {
                value: async (x) => {
                  return x.category;
                },
              },
              {
                value: async (x) => {
                  return x.employee.name;
                },
              },
              {
                value: async (x) => {
                  return `${
                    x.salary.details?.salaryParams?.earning?.grossEarnings || 0
                  }`;
                },
              },
              {
                value: async (x) => {
                  return `${
                    x.salary.details?.salaryParams?.deduction
                      .incomeTaxDeduction || 0
                  }`;
                },
              },
              {
                value: async (x) => {
                  // TODO
                  return `${0}`;
                },
              },
            ],
          },
        ],
        recordEligibility: (data) => {
          return (
            data.salary &&
            data.salary.details?.salaryParams?.deduction?.incomeTaxDeduction > 0
          );
        },
      },
    },
    {
      type: 'advance-recovery',
      owner: 'CATAPHARMA',
      fileType: 'text/csv',
      ext: 'csv',
      isApplicable: (data) => {
        if (
          data.find((x) =>
            ['approved', 'sent-for-release', 'released'].includes(
              x.salary?.status
            )
          )
        ) {
          return true;
        }

        return false;
      },
      fileName: () =>
        `${data[0].company.shortName}-${month}-advance-recovery.csv`,
      lineBreak: '\n',
      details: {
        type: 'detailed',
        separator: 'comma',
        rows: [
          {
            type: 'header',
            values: [
              {
                value: async (data) => {
                  return data[0].company?.name;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              {
                value: async () =>
                  moment(month, 'YYYY-MM').format('MMMM - YYYY'),
              },
            ],
          },
          {
            type: 'header',
            values: [
              {
                value: async () => {
                  const address = data[0].company?.details?.address;
                  return `${address.addressLine1}, ${address.addressLine2}, ${address.city} - ${address.pincode}, ${address.state}`;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
            ],
          },
          {
            type: 'header',
            values: [
              { value: async () => 'Employee Id' },
              { value: async () => 'Category' },
              { value: async () => 'Name' },
              { value: async () => 'Advance_1' },
              { value: async () => 'Advance_2' },
              { value: async () => 'Total' },
            ],
          },
          {
            type: 'employee-details',
            values: [
              {
                value: async (x) => {
                  return x.employee.details.employeeId;
                },
              },
              {
                value: async (x) => {
                  return x.category;
                },
              },
              {
                value: async (x) => {
                  return x.employee.name;
                },
              },
              {
                value: async (x) => {
                  return `${
                    x.salary.details?.salaryParams?.deduction
                      .salaryAdvanceTaken || 0
                  }`;
                },
              },
              {
                value: async (x) => {
                  return `${
                    x.salary.details?.salaryParams?.deduction
                      .adjustedAdvances || 0
                  }`;
                },
              },
              {
                value: async (x) => {
                  const salaryAdvanceTaken =
                    x.salary.details?.salaryParams?.deduction
                      .salaryAdvanceTaken || 0;
                  const adjustedAdvances =
                    x.salary.details?.salaryParams?.deduction
                      .adjustedAdvances || 0;

                  return `${salaryAdvanceTaken + adjustedAdvances}`;
                },
              },
            ],
          },
        ],
        recordEligibility: (data) => {
          return (
            (data.salary &&
              data.salary.details?.salaryParams?.deduction?.salaryAdvanceTaken >
                0) ||
            data.salary.details?.salaryParams?.deduction?.adjustedAdvances > 0
          );
        },
      },
    },
    {
      type: 'loans-recovery',
      owner: 'CATAPHARMA',
      fileType: 'text/csv',
      ext: 'csv',
      isApplicable: (data) => {
        if (
          data.find((x) =>
            ['approved', 'sent-for-release', 'released'].includes(
              x.salary?.status
            )
          )
        ) {
          return true;
        }

        return false;
      },
      fileName: () => `${data[0].company.shortName}-${month}-loan-recovery.csv`,
      lineBreak: '\n',
      details: {
        type: 'detailed',
        separator: 'comma',
        rows: [
          {
            type: 'header',
            values: [
              {
                value: async (data) => {
                  return data[0].company?.name;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              {
                value: async () =>
                  moment(month, 'YYYY-MM').format('MMMM - YYYY'),
              },
            ],
          },
          {
            type: 'header',
            values: [
              {
                value: async () => {
                  const address = data[0].company?.details?.address;
                  return `${address.addressLine1}, ${address.addressLine2}, ${address.city} - ${address.pincode}, ${address.state}`;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
            ],
          },
          {
            type: 'header',
            values: [
              { value: async () => 'Employee Id' },
              { value: async () => 'Category' },
              { value: async () => 'Name' },
              { value: async () => 'Loan EMI' },
              { value: async () => 'Loan Interest EMI' },
              { value: async () => 'Total Recovered' },
            ],
          },
          {
            type: 'employee-details',
            values: [
              {
                value: async (x) => {
                  return x.employee.details.employeeId;
                },
              },
              {
                value: async (x) => {
                  return x.category;
                },
              },
              {
                value: async (x) => {
                  return x.employee.name;
                },
              },
              {
                value: async (x) => {
                  return `${
                    x.salary.details?.salaryParams?.deduction
                      ?.loanEmiDeductions || 0
                  }`;
                },
              },
              {
                value: async (x) => {
                  return `${
                    x.salary.details?.salaryParams?.deduction
                      .loanInterestEmiDeductions || 0
                  }`;
                },
              },
              {
                value: async (x) => {
                  const loanEmi =
                    x.salary.details?.salaryParams?.deduction
                      ?.loanEmiDeductions || 0;
                  const loanInterestEmi =
                    x.salary.details?.salaryParams?.deduction
                      .loanInterestEmiDeductions || 0;

                  return `${loanEmi + loanInterestEmi}`;
                },
              },
            ],
          },
        ],
        recordEligibility: (data) => {
          return (
            data.salary &&
            (data.salary.details?.salaryParams?.deduction?.loanEmiDeductions >
              0 ||
              data.salary.details?.salaryParams?.deduction
                ?.loanInterestEmiDeductions > 0)
          );
        },
      },
    },
    {
      type: 'salary-summary',
      owner: 'CATAPHARMA',
      fileType: 'text/csv',
      ext: 'csv',
      isApplicable: (data) => {
        if (
          data.find((x) =>
            ['approved', 'sent-for-release', 'released'].includes(
              x.salary?.status
            )
          )
        ) {
          return true;
        }

        return false;
      },
      fileName: () =>
        `${data[0].company.shortName}-${month}-salary-summary.csv`,
      lineBreak: '\n',
      details: {
        type: 'detailed',
        separator: 'comma',
        rows: [
          {
            type: 'header',
            values: [
              {
                value: async (data) => {
                  return data[0].company?.name;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              {
                value: async () =>
                  moment(month, 'YYYY-MM').format('MMMM - YYYY'),
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
            ],
          },
          {
            type: 'header',
            values: [
              {
                value: async () => {
                  const address = data[0].company?.details?.address;
                  return `${address.addressLine1}, ${address.addressLine2}, ${address.city} - ${address.pincode}, ${address.state}`;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
            ],
          },
          {
            type: 'header',
            values: [
              { value: async () => 'CATEGORY' },
              { value: async () => 'GROSS_EARNING' },
              { value: async () => 'PF' },
              { value: async () => 'ESIC' },
              { value: async () => 'PT' },
              { value: async () => 'MLWF' },
              { value: async () => 'ADVANCE_1' },
              { value: async () => 'ADVANCE_2' },
              { value: async () => 'INSURANCE' },
              { value: async () => 'LOAN_EMI' },
              { value: async () => 'LOAN_INTEREST_EMI' },
              { value: async () => 'INCOME_TAX' },
              { value: async () => 'ADHOC_DEDUCTION' },
              { value: async () => 'OTHERS' },
              { value: async () => 'NET_PAYABLE' },
            ],
          },
          {
            type: 'employee-details',
            values: [
              {
                value: async (x) => {
                  return x.category;
                },
              },
              {
                value: async (x) => {
                  let grossEarning = 0;
                  grossEarning =
                    x.salary.details?.salaryParams?.earning?.grossEarnings || 0;
                  return `${grossEarning}`;
                },
              },
              {
                value: async (x) => {
                  let employeePf = 0;
                  employeePf =
                    x.salary.details?.salaryParams?.deduction?.employeePf || 0;

                  return `${employeePf}`;
                },
              },
              {
                value: async (x) => {
                  let employeeEsic =
                    x.salary.details?.salaryParams?.deduction?.employeeEsic ||
                    0;

                  return `${employeeEsic}`;
                },
              },
              {
                value: async (x) => {
                  let employeePt =
                    x.salary.details?.salaryParams?.deduction?.employeePt || 0;

                  return `${employeePt}`;
                },
              },
              {
                value: async (x) => {
                  let employeeMlwf =
                    x.salary.details?.salaryParams?.deduction?.employeeMlwf ||
                    0;

                  return `${employeeMlwf}`;
                },
              },
              {
                value: async (x) => {
                  let salaryAdvanceTaken =
                    x.salary.details?.salaryParams?.deduction
                      ?.salaryAdvanceTaken || 0;

                  return `${salaryAdvanceTaken}`;
                },
              },
              {
                value: async (x) => {
                  let adjustedAdvances =
                    x.salary.details?.salaryParams?.deduction
                      ?.adjustedAdvances || 0;

                  return `${adjustedAdvances}`;
                },
              },

              {
                value: async (x) => {
                  let insurancePremiumDeductions =
                    x.salary.details?.salaryParams?.deduction
                      ?.insurancePremiumDeductions || 0;

                  return `${insurancePremiumDeductions}`;
                },
              },
              {
                value: async (x) => {
                  let loanEmiDeductions =
                    x.salary.details?.salaryParams?.deduction
                      ?.loanEmiDeductions || 0;

                  return `${loanEmiDeductions}`;
                },
              },

              {
                value: async (x) => {
                  let loanInterestEmiDeductions =
                    x.salary.details?.salaryParams?.deduction
                      ?.loanInterestEmiDeductions || 0;

                  return `${loanInterestEmiDeductions}`;
                },
              },

              {
                value: async (x) => {
                  let incomeTaxDeduction =
                    x.salary.details?.salaryParams?.deduction
                      ?.incomeTaxDeduction || 0;

                  return `${incomeTaxDeduction}`;
                },
              },

              {
                value: async (x) => {
                  let adhocDeductionsVal =
                    x.salary.details?.salaryParams?.deduction
                      ?.adhocDeductionsVal || 0;

                  return `${adhocDeductionsVal}`;
                },
              },

              {
                value: async (x) => {
                  let otherDeductions =
                    x.salary.details?.salaryParams?.deduction
                      ?.otherDeductions || 0;

                  return `${otherDeductions}`;
                },
              },
              {
                value: async (x) => {
                  let employeeTakeHome =
                    x.salary.details?.salaryParams?.earning?.employeeTakeHome ||
                    0;

                  return `${employeeTakeHome}`;
                },
              },
            ],
          },
        ],
        recordEligibility: (data) => {
          return (
            data.salary &&
            ['approved', 'sent-for-release', 'released'].includes(
              data.salary?.status
            )
          );
        },
      },
    },
    {
      type: 'pt-summary',
      owner: 'CATAPHARMA',
      fileType: 'text/csv',
      ext: 'csv',
      isApplicable: (data) => {
        if (
          data.find((x) =>
            ['approved', 'sent-for-release', 'released'].includes(
              x.salary?.status
            )
          )
        ) {
          return true;
        }

        return false;
      },
      fileName: () => `${data[0].company.shortName}-${month}-pt-summary.csv`,
      lineBreak: '\n',
      details: {
        type: 'detailed',
        separator: 'comma',
        rows: [
          {
            type: 'header',
            values: [
              {
                value: async (data) => {
                  return data[0].company?.name;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              {
                value: async () =>
                  moment(month, 'YYYY-MM').format('MMMM - YYYY'),
              },
            ],
          },
          {
            type: 'header',
            values: [
              {
                value: async () => {
                  const address = data[0].company?.details?.address;
                  return `${address.addressLine1}, ${address.addressLine2}, ${address.city} - ${address.pincode}, ${address.state}`;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
            ],
          },
          {
            type: 'header',
            values: [
              { value: async () => 'CATEGORY' },
              { value: async () => 'PT_AMOUNT' },
              { value: async () => 'NO_OF_EMPLOYEES' },
              { value: async () => 'TOTAL_PT' },
            ],
          },
          {
            type: 'employee-details',
            values: [
              {
                value: async (x) => {
                  return x.category;
                },
              },
              {
                value: async (x) => {
                  let employeePt =
                    x.salary.details?.salaryParams?.deduction?.employeePt || 0;
                  return `${employeePt}`;
                },
              },
              {
                value: async (x) => {
                  let numberOfEmployees =
                    x.salary.details?.salaryParams?.numberOfEmployees || 0;
                  return `${numberOfEmployees}`;
                },
              },
              {
                value: async (x) => {
                  let totalPt = x.salary.details?.salaryParams?.totalPt || 0;
                  return `${totalPt}`;
                },
              },
            ],
          },
        ],
        recordEligibility: (data) => {
          return (
            data.salary &&
            ['approved', 'sent-for-release', 'released'].includes(
              data.salary?.status
            )
          );
        },
      },
    },
    {
      type: 'bank-report',
      owner: 'ICICI INR',
      fileType: 'text/csv',
      ext: 'csv',
      isApplicable: (data) => {
        if (
          data.find(
            (x) =>
              ['approved', 'sent-for-release', 'released'].includes(
                x.salary?.status
              ) && x.salary.bankAccountName === 'ICICI INR'
          )
        ) {
          return true;
        }

        return false;
      },
      fileName: () => `${data[0].company.shortName}-${month}-ICICI-report.csv`,
      lineBreak: '\n',
      details: {
        type: 'detailed',
        separator: 'comma',
        rows: [
          {
            type: 'header',
            values: [
              { value: async () => 'PYMT_PROD_TYPE_CODE' },
              { value: async () => 'PYMT_MODE' },
              { value: async () => 'DEBIT_ACC_NO' },
              { value: async () => 'BNF_NAME' },
              { value: async () => 'BENE_ACC_NO' },
              { value: async () => 'BENE_IFSC' },
              { value: async () => 'AMOUNT' },
              // { value: () => 'DEBIT_NARR' },
              { value: async () => 'CREDIT_NARR' },
              { value: async () => 'PYMT_DATE' },
              { value: async () => 'MOBILE_NUM' },
              { value: async () => 'EMAIL_ID' },
              { value: async () => 'REMARK' },
              { value: async () => 'REF_NO' },
            ],
          },
          {
            type: 'employee-details',
            values: [
              { value: async () => 'PAB_VENDOR' },
              { value: async () => 'NEFT' },
              {
                value: async (data) => {
                  return data.salary?.bankAccountNumber || '';
                },
              },
              {
                value: async (data) => {
                  return data.employee?.name || '';
                },
              },
              {
                value: async (data) => {
                  return (
                    data.employee?.details?.bankDetails[0]?.accountNo || ''
                  );
                },
              },
              {
                value: async (data) => {
                  return data.employee?.details?.bankDetails[0].ifsc || '';
                },
              },

              {
                value: async (data) => {
                  // In case oldSalaryId is present then it is recaluclated salary
                  const value =
                    data.salary?.details?.oldSalaryId &&
                    data.salary?.details?.amount &&
                    data.salary?.details?.action === 'disburse'
                      ? data.salary.details.amount
                      : data.salary?.details?.salaryParams?.earning
                          ?.employeeTakeHome;
                  const earning = formatParam({
                    value: value,
                    metric: 'number',
                    scale: findParamType(
                      'employeeTakeHome',
                      data.salary.selectedSalaryParam.details.inner,
                      'scale'
                    ),
                    plain: true,
                  });

                  return earning;
                  // return (
                  //   data.salary?.details?.salaryParams?.earning
                  //     ?.employeeTakeHome || ''
                  // );
                },
              },
              { value: async () => '' },
              { value: async () => moment().format('DD-MM-YYYY') },
              // { value: () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
            ],
          },
        ],
        recordEligibility: (data) => {
          return (
            data.salary &&
            ['approved', 'sent-for-release', 'released'].includes(
              data.salary?.status
            )
          );
        },
      },
    },
    {
      type: 'bank-report',
      owner: 'DBS INR',
      fileType: 'text/plain',
      ext: 'txt',
      lineBreak: '\n',
      isApplicable: (data) => {
        if (
          data.find(
            (x) =>
              ['approved', 'sent-for-release', 'released'].includes(
                x.salary?.status
              ) && x.salary.bankAccountName === 'DBS INR'
          )
        ) {
          return true;
        }

        return false;
      },
      fileName: () =>
        `${data[0].company.shortName}-${month}-DBS-report-${
          data[0].salary?.releaseDetails?.batchNumber || 'no-batch'
        }.txt`,
      details: {
        type: 'detailed',
        separator: 'comma',
        rows: [
          {
            type: 'header',
            values: [
              { value: async () => 'HEADER' },
              {
                value: async () => {
                  return moment().format('DDMMYYYY');
                },
              },
              {
                value: async (data) => {
                  return data[0].bank?.accountId || 'Unknown';
                },
              },
              {
                value: async (data) => {
                  const emp = data[0].employee as EmployeeProfileType;
                  return emp.company.name;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
            ],
          },
          {
            type: 'employee-details',
            values: [
              {
                value: async (data) => {
                  return 'PAYMENT';
                },
              },
              {
                value: async (data) => {
                  return 'SAL';
                },
              }, // Maybe different for a different module
              {
                value: async (data) => {
                  return data.salary?.bankAccountNumber || '';
                },
              },
              {
                value: async (data) => {
                  return 'INR';
                },
              },
              {
                value: async (data) => {
                  return '';
                },
              },
              {
                value: async (data) => {
                  return 'INR';
                },
              },
              {
                value: async (data) => {
                  return `${momentUnix.toString().slice(-5)}`;
                },
              },
              {
                value: async (data) => {
                  return moment().format('DDMMYYYY');
                },
              },
              {
                value: async (data) => {
                  return '';
                },
              },
              {
                value: async (data) => {
                  return '';
                },
              },
              {
                value: async (data) => {
                  const emp = data.employee as EmployeeProfileType;
                  return emp.name;
                },
              },
              {
                value: async (data) => {
                  return '';
                },
              },
              {
                value: async (data) => {
                  return '';
                },
              },
              {
                value: async (data) => {
                  return '';
                },
              },
              {
                value: async (data) => {
                  return '';
                },
              },
              {
                value: async (data) => {
                  const accountNo =
                    (data.employee?.details?.bankDetails &&
                      _.find(
                        data.employee?.details?.bankDetails,
                        (b) => b.active === 'yes'
                      )?.accountNo) ||
                    '';

                  return accountNo;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              {
                value: async (data) => {
                  const ifsc =
                    (data.employee?.details?.bankDetails &&
                      _.find(
                        data.employee?.details?.bankDetails,
                        (b) => b.active === 'yes'
                      )?.ifsc) ||
                    '';
                  return ifsc;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              {
                value: async (data) => {
                  // In case oldSalaryId is present then it is recaluclated salary
                  const value =
                    data.salary?.details?.oldSalaryId &&
                    data.salary?.details?.amount &&
                    data.salary?.details?.action === 'disburse'
                      ? data.salary.details.amount
                      : data.salary?.details?.salaryParams?.earning
                          ?.employeeTakeHome;

                  const earning = formatParam({
                    value: value,
                    metric: 'number',
                    scale: findParamType(
                      'employeeTakeHome',
                      data.salary.selectedSalaryParam.details.inner,
                      'scale'
                    ),
                    plain: true,
                  });

                  return earning;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '22' }, // ALways this value (maybe different for other module)
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => 'Employee Salary' }, // TODO include month year
            ],
          },
          {
            type: 'header',
            values: [
              { value: async () => 'TRAILER' },
              {
                value: async (list) => {
                  const count = list.filter(
                    (x) =>
                      ['approved', 'sent-for-release', 'released'].includes(
                        x.salary?.status
                      ) && x.salary.bankAccountName === 'DBS INR'
                  );
                  return `${count.length}`;
                },
              },
              {
                value: async (list) => {
                  const count = list.filter(
                    (x) =>
                      ['approved', 'sent-for-release', 'released'].includes(
                        x.salary?.status
                      ) && x.salary.bankAccountName === 'DBS INR'
                  );
                  let value = 0;
                  count.forEach((data) => {
                    // In case oldSalaryId is present then it is recaluclated salary
                    const earningValue =
                      data.salary?.details?.oldSalaryId &&
                      data.salary?.details?.amount &&
                      data.salary?.details?.action === 'disburse'
                        ? data.salary.details.amount
                        : data.salary?.details?.salaryParams?.earning
                            ?.employeeTakeHome;
                    const earning = parseFloat(
                      formatParam({
                        value: earningValue,
                        metric: 'number',
                        scale: findParamType(
                          'employeeTakeHome',
                          data.salary.selectedSalaryParam.details.inner,
                          'scale'
                        ),
                        plain: true,
                      })
                    );

                    value += earning;
                  });
                  return `${value}`;
                },
              },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
              { value: async () => '' },
            ],
          },
        ],
        recordEligibility: (data) => {
          return ['approved', 'sent-for-release', 'released'].includes(
            data.salary?.status
          );
        },
      },
    },
    {
      type: 'bank-report',
      owner: 'AU BANK',
      fileType: 'text/csv',
      ext: 'csv',
      lineBreak: '\n',
      isApplicable: (data) => {
        if (
          data.find(
            (x) =>
              ['approved', 'sent-for-release', 'released'].includes(
                x.salary?.status
              ) && x.salary.bankAccountName === 'AU BANK'
          )
        ) {
          return true;
        }

        return false;
      },
      fileName: () =>
        `${data[0].company.shortName}-${month}-AU-report-${
          data[0].salary?.releaseDetails?.batchNumber || 'no-batch'
        }.txt`,
      details: {
        type: 'detailed',
        separator: 'comma',
        rows: [
          {
            type: 'header',
            values: [
              { value: async () => 'Remitter Account No' },
              { value: async () => 'Remitter Name' },
              { value: async () => 'Amount' },
              { value: async () => 'IFSC CODE' },
              { value: async () => 'BENEFICERY ACCOUNT NO' },
              { value: async () => 'BENEFICERY NAME' },
              { value: async () => 'EMAIL/MOBILE NO' },
              { value: async () => 'TRANSACTION DESC CREDIT' },
            ],
          },
          {
            type: 'employee-details',
            values: [
              {
                value: async (data) => {
                  return data.salary?.bankAccountNumber || '';
                },
              },
              {
                value: async (data) => {
                  return data.salary?.bankAccountName || '';
                },
              },
              {
                value: async (data) => {
                  // In case oldSalaryId is present then it is recaluclated salary
                  const takeHomeMapper = {
                    '0836C6F1-207E-4990-9BB4-605572012E4D': 'takeHome',
                    'A49079C2-8852-471B-A86E-FCD70D53DBDE': 'employeeTakeHome',
                  };
                  const takeHomeVariable =
                    takeHomeMapper[data.employee?.companyGroup?.id] ||
                    'employeeTakeHome';
                  const value =
                    data.salary?.details?.oldSalaryId &&
                    data.salary?.details?.amount &&
                    data.salary?.details?.action === 'disburse'
                      ? data.salary.details.amount
                      : data.salary?.details?.salaryParams?.earning?.[
                          takeHomeVariable
                        ];

                  const earning = formatParam({
                    value: value,
                    metric: 'number',
                    scale: findParamType(
                      takeHomeVariable,
                      data.salary.selectedSalaryParam.details.inner,
                      'scale'
                    ),
                    plain: true,
                  });

                  return earning;
                },
              },
              {
                value: async (data) => {
                  const ifsc =
                    (data.employee?.details?.bankDetails &&
                      _.find(
                        data.employee?.details?.bankDetails,
                        (b) => b.active === 'yes'
                      )?.ifsc) ||
                    '';
                  return ifsc;
                },
              },
              {
                value: async (data) => {
                  const accountNo =
                    (data.employee?.details?.bankDetails &&
                      _.find(
                        data.employee?.details?.bankDetails,
                        (b) => b.active === 'yes'
                      )?.accountNo) ||
                    '';

                  return accountNo;
                },
              },
              {
                value: async (data) => {
                  const emp = data.employee as EmployeeProfileType;
                  return emp.name;
                },
              },
              {
                value: async (data) => {
                  const emp = data.employee as EmployeeProfileType;
                  return (
                    emp.details?.contactDetails?.emailAddress?.personalEmail ||
                    emp.details?.contactDetails?.emailAddress?.officeEmail ||
                    ''
                  );
                },
              },
              {
                value: async (data) => {
                  return `Salary ${month}`;
                },
              },
            ],
          },
        ],
        recordEligibility: (data) => {
          return ['approved', 'sent-for-release', 'released'].includes(
            data.salary?.status
          );
        },
      },
    },
    {
      type: 'esic',
      owner: 'ESIC',
      fileType: 'text/csv',
      ext: 'csv',
      lineBreak: '\n',
      isApplicable: (data) => {
        if (
          data.find((x) =>
            ['approved', 'sent-for-release', 'released'].includes(
              x.salary?.status
            )
          )
        ) {
          return true;
        }

        return false;
      },
      fileName: () => `${data[0].company.shortName}-${month}-ESIC-report.csv`,
      details: {
        type: 'detailed',
        separator: 'comma',
        rows: [
          {
            type: 'header',
            values: [
              { value: async () => 'IP Number' },
              { value: async () => 'IP Name' },
              {
                value: async () =>
                  'No of Days for which wages paid/payable during the month',
              },
              { value: async () => 'Total Monthly Wages' },
              { value: async () => 'Reason Code for Zero workings days' },
              { value: async () => 'Last Working Day' },
            ],
          },
          {
            type: 'employee-details',
            values: [
              {
                value: async (data) => {
                  const emp = data.employee as EmployeeProfileType;

                  const registrations = await getRegistrations({
                    employeeId: emp.id,
                  });
                  const accNo =
                    registrations.find((x) => x.regDocumentType === 'esic')
                      ?.accountNo || 'not-set-in-profile';
                  return accNo;
                },
              },
              {
                value: async (data) => {
                  const emp = data.employee as EmployeeProfileType;
                  return emp.name;
                },
              },
              {
                value: async (data) => {
                  const sal = data.salary as SalaryType;
                  return `${Math.round(
                    sal.details.salaryParams?.other?.adjustedPayableDays
                  )}`;
                },
              },
              {
                value: async (data) => {
                  return `${Math.round(
                    data.salary.details?.salaryParams?.other
                      ?.wagesForEsicCalculation || ''
                  )}`;
                },
              },
              {
                value: async (data) => {
                  let code = 0;
                  // Check if employee is resigned
                  const emp = data.employee as EmployeeProfileType;
                  if (emp.details.resigned === 'yes') {
                    const resignDate = emp.details.workTimeLines?.find(
                      (s) => s.activity === 'resigned'
                    )?.date;
                    if (resignDate) {
                      if (
                        moment(resignDate).add(1, 'month').format('YYYY-MM') ===
                        month
                      ) {
                        // Condition when resign date is one month prev to current month
                        return '2';
                      }
                    }
                  }

                  const esicApplicability =
                    data.salRevision?.details?.esicApplicability;
                  if (
                    esicApplicability?.value === 'True initially then false' &&
                    esicApplicability.data?.until
                  ) {
                    const until = moment(esicApplicability.data?.until);
                    if (until.format('YYYY-MM') === month) {
                      // This means esic from this month is 0, we add previous day as date of resign.
                      return '4';
                    }
                  }

                  return `${code}`;
                },
              },
              {
                value: async (data) => {
                  const emp = data.employee as EmployeeProfileType;
                  if (emp.details.resigned === 'yes') {
                    const resignDate = emp.details.workTimeLines?.find(
                      (s) => s.activity === 'resigned'
                    )?.date;
                    if (resignDate) {
                      if (
                        moment(resignDate).add(1, 'month').format('YYYY-MM') ===
                        month
                      ) {
                        // Condition when resign date is one month prev to current month
                        return moment(resignDate).format('DD-MM-YYYY');
                      }
                    }
                  }
                  const esicApplicability =
                    data.salRevision?.details?.esicApplicability;
                  if (
                    esicApplicability?.value === 'True initially then false' &&
                    esicApplicability.data?.until
                  ) {
                    const until = moment(esicApplicability.data?.until);
                    if (until.format('YYYY-MM') === month) {
                      // This means esic from this month is 0, we add previous day as date of resign.
                      return moment(until)
                        .subtract(1, 'day')
                        .format('DD-MM-YYYY');
                    }
                  }

                  return ''; // TODO: For resigned employees we want this to be computed from salary param
                },
              },
            ],
          },
        ],
        recordEligibility: (data) => {
          return (
            data.salary.details?.salaryParams?.other?.profile_EsicApplicable ===
            'Yes'
          );
          //data.employee?.details?.esicApplicable === 'Yes';
        },
      },
    },
    {
      type: 'epf',
      owner: 'EPF',
      fileType: 'text/plain',
      ext: 'txt',
      lineBreak: '#~#\n',
      isApplicable: (data) => {
        if (
          data.find((x) =>
            ['approved', 'sent-for-release', 'released'].includes(
              x.salary?.status
            )
          )
        ) {
          return true;
        }

        return false;
      },
      fileName: () => `${data[0].company.shortName}${month}EpfReport.txt`,
      details: {
        type: 'detailed',
        separator: 'hash-tilda',
        rows: [
          // {
          //   type: 'header',
          //   values: [
          //     { value: () => 'UAN Number' },
          //     { value: () => 'Employee Name' },
          //     { value: () => 'Gross Wages' },
          //     { value: () => 'EPF Wages' },
          //     { value: () => 'EPS Wages' },
          //     { value: () => 'ELDI Wages' },
          //     { value: () => 'EPF Contri Remitted' },
          //     { value: () => 'EPS Contri Remitted' },
          //     { value: () => 'EPF EPS DIFF Remitted' },
          //     { value: () => 'NCP Days' }
          //   ]
          // },
          {
            type: 'employee-details',
            values: [
              {
                value: async (data) => {
                  const emp = data.employee as EmployeeProfileType;
                  const registrations = await getRegistrations({
                    employeeId: emp.id,
                  });
                  const accNo =
                    registrations.find(
                      (x) => x.regDocumentType === 'employee-provident-fund'
                    )?.accountNo || 'not-set-in-profile';

                  return accNo;
                },
              },
              {
                value: async (data) => {
                  const emp = data.employee as EmployeeProfileType;
                  return emp.name;
                },
              },
              {
                value: async (data) => {
                  const grossEarnings = formatParam({
                    value:
                      data.salary?.details.salaryParams?.earning?.grossEarnings,
                    metric: 'number',
                    scale: findParamType(
                      'grossEarnings',
                      data.salary.selectedSalaryParam.details.inner,
                      'scale'
                    ),
                    plain: true,
                  });

                  return grossEarnings;
                  // const sal = data.salary as SalaryType;
                  // return sal.details.salaryParams?.earning?.grossEarnings || '';
                },
              },
              {
                value: async (data) => {
                  const epfWagesMapper = {
                    '0836C6F1-207E-4990-9BB4-605572012E4D': 'epfWagesEmployee',
                    'A49079C2-8852-471B-A86E-FCD70D53DBDE': 'epfWages',
                  };
                  const epfWagesVariable =
                    epfWagesMapper[data.employee?.companyGroup?.id] ||
                    'epfWages';

                  const epfWages = formatParam({
                    value:
                      data.salary?.details.salaryParams?.other?.[
                        epfWagesVariable
                      ],
                    metric: 'number',
                    scale: findParamType(
                      epfWagesVariable,
                      data.salary.selectedSalaryParam.details.inner,
                      'scale'
                    ),
                    plain: true,
                  });

                  return epfWages;

                  // const sal = data.salary as SalaryType;
                  // return sal.details.salaryParams?.earning?.basicDa || '';
                },
              },
              {
                value: async (data) => {
                  const epsWages = formatParam({
                    value: data.salary?.details.salaryParams?.other?.epsWages,
                    metric: 'number',
                    scale: findParamType(
                      'epsWages',
                      data.salary.selectedSalaryParam.details.inner,
                      'scale'
                    ),
                    plain: true,
                  });

                  return epsWages;
                  // const sal = data.salary as SalaryType;
                  // return sal.details.salaryParams?.earning?.basicDa || '';
                },
              },
              {
                value: async (data) => {
                  const edliWages = formatParam({
                    value: data.salary?.details.salaryParams?.other?.edliWages,
                    metric: 'number',
                    scale: findParamType(
                      'edliWages',
                      data.salary.selectedSalaryParam.details.inner,
                      'scale'
                    ),
                    plain: true,
                  });

                  return edliWages;

                  // const sal = data.salary as SalaryType;
                  // return sal.details.salaryParams?.earning?.basicDa || '';
                },
              },
              {
                value: async (data) => {
                  const employeePfMapper = {
                    '0836C6F1-207E-4990-9BB4-605572012E4D': 'pfEmployee',
                    'A49079C2-8852-471B-A86E-FCD70D53DBDE': 'employeePf',
                  };
                  const employeePfVariable =
                    employeePfMapper[data.employee?.companyGroup?.id] ||
                    'employeePf';

                  const employeePf = formatParam({
                    value:
                      data.salary?.details.salaryParams?.deduction?.[
                        employeePfVariable
                      ],
                    metric: 'number',
                    scale: findParamType(
                      employeePfVariable,
                      data.salary.selectedSalaryParam.details.inner,
                      'scale'
                    ),
                    plain: true,
                  });

                  return employeePf;

                  // const sal = data.salary as SalaryType;
                  // return (sal.details.salaryParams?.deduction?.pf || 0).toFixed(
                  //   2
                  // );
                },
              },
              {
                value: async (data) => {
                  const epsContribution = formatParam({
                    value:
                      data.salary?.details.salaryParams?.other?.epsContribution,
                    metric: 'number',
                    scale: findParamType(
                      'epsContribution',
                      data.salary.selectedSalaryParam.details.inner,
                      'scale'
                    ),
                    plain: true,
                  });

                  return epsContribution;

                  // const sal = data.salary as SalaryType;
                  // return (sal.details.salaryParams?.deduction?.esic).toFixed(2); // TODO: To be confirmed
                },
              },
              {
                value: async (data) => {
                  const epfContributionMapper = {
                    '0836C6F1-207E-4990-9BB4-605572012E4D': 'netPfContribution',
                    'A49079C2-8852-471B-A86E-FCD70D53DBDE': 'epfContribution',
                  };
                  const epfContributionVariable =
                    epfContributionMapper[data.employee?.companyGroup?.id] ||
                    'epfContribution';

                  const epfContribution = formatParam({
                    value:
                      data.salary?.details.salaryParams?.other?.[
                        epfContributionVariable
                      ], // TODO
                    metric: 'number',
                    scale: findParamType(
                      epfContributionVariable,
                      data.salary.selectedSalaryParam.details.inner,
                      'scale'
                    ),
                    plain: true,
                  });

                  return epfContribution;

                  // const sal = data.salary as SalaryType;
                  // // const epfContri = Number(((sal.details.salaryParams?.earning?.basicDa || 0) * 12 /100).toFixed(2));
                  // // const epsContri = Number(((sal.details.salaryParams?.earning?.basicDa || 0) * 8.33 /100).toFixed(2));
                  // return (
                  //   sal.details.salaryParams?.deduction?.pf -
                  //   sal.details.salaryParams?.deduction?.esic
                  // );
                  // // return epfContri - epsContri;
                },
              },
              {
                value: async (data) => {
                  const pfNcp = formatParam({
                    value: data.salary?.details.salaryParams?.other?.pfNcp,
                    metric: 'number',
                    scale: findParamType(
                      'pfNcp',
                      data.salary.selectedSalaryParam.details.inner,
                      'scale'
                    ),
                    plain: true,
                  });

                  return pfNcp;
                  // const sal = data.salary as SalaryType;

                  // return (
                  //   (parseInt(
                  //     sal.details.salaryParams?.other?.daysCurrentMonth
                  //   ) || 0) -
                  //   (parseInt(sal.details.salaryParams?.other?.payableDays) ||
                  //     0)
                  // ); // TODO This is non-working days Need to be confirmed
                },
              },
            ],
          },
        ],
        recordEligibility: (data) => {
          return data.salary && data.employee?.details?.pfApplicable === 'Yes';
        },
      },
    },
  ];
  const result: Array<{
    name: string;
    data: string;
    fileType: string;
    ext: string;
  }> = [];

  for (const r of reports.filter((x) => docs.includes(x.type))) {
    if (r.isApplicable(data)) {
      const g = await generateReport(r, data);
      result.push(g);
    }
  }

  return result;
}

export async function generateReport(
  rep: StatutoryReportType,
  data: Array<{
    employee: EmployeeProfileType;
    salary: SalaryType;
  }>
): Promise<{
  name: string;
  data: string;
  ext: string;
  fileType: string;
}> {
  let detailsData: string = '';

  for (const r of rep.details.rows) {
    if (r.type === 'header') {
      let line = '';
      for (const v of r.values) {
        const value = await v.value(data);
        line += `${line.length ? sep[rep.details.separator] : ''}${value}`;
      }

      detailsData += line + rep.lineBreak;
    }

    if (r.type === 'employee-details') {
      const filteredEmployees = data.filter((x) =>
        rep.details.recordEligibility(x)
      );

      for (const x of filteredEmployees) {
        if (rep.details.recordEligibility(x)) {
          let line = '';
          for (const v of r.values) {
            const value = await v.value(x);
            line += `${line.length ? sep[rep.details.separator] : ''}${value}`;
          }

          detailsData += line;
          // if (idx !== filteredEmployees.length - 1) {
          detailsData += rep.lineBreak;
          // }
        }
      }
    }

    if (r.type === 'summary') {
    }

    // if (rowIdx !== rep.details.rows.length - 1) {
    //   detailsData += 'Sagar' + rep.lineBreak;
    // }
  }

  return {
    name: rep.fileName(),
    data: detailsData,
    fileType: rep.fileType,
    ext: rep.ext,
  };
}

const sep = {
  comma: ',',
  'semi-colon': ';',
  'hash-tilda': '#~#',
};
