import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { UseHazardGhsClass } from '../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseHazardGhsClasses } from '../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-classes';
import { UsePaginatedHazardGhsClasses } from '../../../hooks/admin/hazard-regulations/ghs-class/use-paginated-hazard-ghs-classes';
import { UseGhsPrecautionaryStatement } from '../../../hooks/admin/hazard-regulations/ghs-precautionary-statements/use-ghs-precautionary-statement';
import { UseGhsPrecautionaryStatements } from '../../../hooks/admin/hazard-regulations/ghs-precautionary-statements/use-ghs-precautionary-statements';
import { UsePaginatedPrecautionaryStatements } from '../../../hooks/admin/hazard-regulations/ghs-precautionary-statements/use-paginated-precautionary-statements';
import { UsePaginatedHazardStatement } from '../../../hooks/admin/hazard-regulations/hazard-statements/use-hazard-paginated';
import { UseHazardStatement } from '../../../hooks/admin/hazard-regulations/hazard-statements/use-hazard-statement';
import { UseHazardStatements } from '../../../hooks/admin/hazard-regulations/hazard-statements/use-hazard-statements';
import { UsePublicTransfer } from '../../../hooks/file-transfer/use-public-transfer';
import { renderHazardGhsClass } from './hazard-class-category-list';
import { renderGhsPrecautionaryStatement } from './hazard-precautions';
import { renderHazardStatements } from './hazard-statements';

export type RenderHazardRegulationsProps = {
  useHazardGhsClasses: UseHazardGhsClasses;
  useHazardGhsClass: UseHazardGhsClass;
  useHazardStatements: UseHazardStatements;
  useHazardStatement: UseHazardStatement;
  useGhsPrecautionaryStatements: UseGhsPrecautionaryStatements;
  usePaginatedPrecautionaryStatements: UsePaginatedPrecautionaryStatements;
  useGhsPrecautionaryStatement: UseGhsPrecautionaryStatement;
  usePublicTransfer: UsePublicTransfer;
  usePaginatedHazardGhsClasses: UsePaginatedHazardGhsClasses;
  usePaginatedHazardStatement: UsePaginatedHazardStatement;
};

// eslint-disable-next-line
export function renderHazardRegulations({
  useHazardGhsClasses,
  useHazardGhsClass,
  useHazardStatements,
  useHazardStatement,
  useGhsPrecautionaryStatements,
  usePaginatedPrecautionaryStatements,
  useGhsPrecautionaryStatement,
  usePublicTransfer,
  usePaginatedHazardGhsClasses,
  usePaginatedHazardStatement,
}: RenderHazardRegulationsProps): () => JSX.Element {
  return function HazardRegulations(): JSX.Element {
    const Card = renderCardComponent();
    const TabsV2 = renderTabsV2Component();
    const header: CardHeader = {
      title: 'Hazard Regulations',
    };

    const GHSClassCategory = renderHazardGhsClass({
      useHazardGhsClasses,
      useHazardGhsClass,
      usePublicTransfer,
      usePaginatedHazardGhsClasses,
    });

    const GhsStatement = renderHazardStatements({
      useHazardStatements,
      useHazardStatement,
      usePaginatedHazardStatement,
    });

    const GhsPrecautions = renderGhsPrecautionaryStatement({
      useGhsPrecautionaryStatements,
      useGhsPrecautionaryStatement,
      usePaginatedPrecautionaryStatements,
    });

    const newCategories: DataArray = [
      { name: 'ghs-class-category', Fn: GHSClassCategory },
      { name: 'h-statements', Fn: GhsStatement },
      { name: 'p-statements', Fn: GhsPrecautions },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: <TabsV2 data={newCategories} manipulateLocation={true} />,
    };

    return <Card header={header} body={body} />;
  };
}
