import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeCategory } from '@erp_core/erp-types/dist/modules/constants';
import { LoadingButton, renderSearchBox } from '@erp_core/erp-ui-components';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { UseEmployeeCategories } from '../../../../../../../hooks/admin/constants/employee-category/use-employee-categories';

export const renderAttendanceRuleForm3 = ({
  onSave,
  useEmployeeCategories,
  valueType,
}: {
  onSave: (form) => void;
  useEmployeeCategories: UseEmployeeCategories;
  valueType: 'number' | 'string';
}) => {
  type Props = {
    categories: EmployeeCategory[];
    comGrpSet: CompanyGroupSetting;
    ruleName: string;
  };

  type Rule = { id: string; name: string; value?: number | string };

  return function ({ categories, comGrpSet, ruleName }: Props): JSX.Element {
    if (!categories || categories.length === 0) return <div></div>;

    const initialFormState: { categories: Rule[]; designations: Rule[] } = {
      categories: [],
      designations: [],
    };

    comGrpSet.details.hrd?.attendanceRules[ruleName]?.categories?.forEach(
      (ss: { id: string; name: string; value: any }) => {
        initialFormState.categories.push({
          id: ss.id,
          name: ss.name,
          value: ss.value,
        });
      }
    );

    comGrpSet.details.hrd?.attendanceRules[ruleName]?.designations?.forEach(
      (ss: { id: string; name: string; value: any }) => {
        initialFormState.designations.push({ id: ss.id, name: ss.name });
      }
    );

    const [selectedCategories, setSelectedCategories] = useState<Rule[]>(
      initialFormState.categories || []
    );
    const [selectedDesignations, setSelectedDesignations] = useState<Rule[]>(
      initialFormState.designations || []
    );

    const onSubmit = () => {
      onSave({
        categories: selectedCategories,
        designations: selectedDesignations,
      });
    };

    const CategorySearchBox = renderSearchBox<EmployeeCategory>({
      useSearchValues: useEmployeeCategories,
      onSearchValueSelect: (x) => {
        setSelectedCategories([...selectedCategories, { ...x }]);
      },
    });

    const DesignationSearchBox = renderSearchBox<EmployeeCategory>({
      useSearchValues: useEmployeeCategories,
      onSearchValueSelect: (x) => {
        setSelectedDesignations([...selectedDesignations, { ...x }]);
      },
    });

    return (
      <div>
        <div>
          <div className='font bold'>Selected Categories</div>
          <CategorySearchBox searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm' />
          <div className='my-1'>
            {selectedCategories.length > 0 ? (
              <div className='flex flex-wrap'>
                {selectedCategories.map((sc, i) => {
                  return (
                    <div
                      className='p-2 mr-1 border rp-2 mr-1 border rounded-full text-gray-600 bg-gray-200 font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease'
                      key={i}
                    >
                      <label>{sc.name}</label>
                      <input
                        onChange={(e) => {
                          const newState = [...selectedCategories];
                          newState[i] = {
                            ...newState[i],
                            value:
                              valueType === 'number'
                                ? Number(e.target.value)
                                : e.target.value,
                          };
                          setSelectedCategories([...newState]);
                        }}
                        value={sc.value}
                        className={`border-2 ml-3  ${
                          valueType === 'number' ? 'w-14 text-center' : 'w-32'
                        } border-gray-800 outline-none rounded`}
                        type={valueType === 'number' ? 'number' : 'text'}
                      />
                      <span
                        className='mx-1'
                        onClick={() => {
                          const copy = [...selectedCategories];
                          copy.splice(i, 1);
                          setSelectedCategories(copy);
                        }}
                      >
                        <XMarkIcon className='inline h-4 text-black' />
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <div className='font bold'>Selected Designations</div>
          <DesignationSearchBox searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm' />
          {selectedDesignations.length > 0 ? (
            <div className='flex flex-wrap'>
              {selectedDesignations.map((sc, i) => {
                return (
                  <div
                    className='p-2 mr-1 border rp-2 mr-1 border rounded-full text-gray-600 bg-gray-200 font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease'
                    key={i}
                  >
                    <label>{sc.name}</label>
                    <input
                      onChange={(e) => {
                        const newState = [...selectedDesignations];
                        newState[i] = {
                          ...newState[i],
                          value:
                            valueType === 'number'
                              ? Number(e.target.value)
                              : e.target.value,
                        };
                        setSelectedDesignations(newState);
                      }}
                      value={sc.value}
                      className={`border-2 ml-3 ${
                        valueType === 'number' ? 'w-14 text-center' : 'w-32'
                      } border-gray-800 outline-none rounded`}
                      type={valueType === 'number' ? 'number' : 'text'}
                    />
                    <span
                      className='mx-1'
                      onClick={() => {
                        const copy = [...selectedDesignations];
                        copy.splice(i, 1);
                        setSelectedDesignations(copy);
                      }}
                    >
                      <XMarkIcon className='inline h-4' />
                    </span>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <div>
          <LoadingButton
            behaviorFn={async () => onSubmit()}
            defaultStyle='mx-auto p-2 bg-blue-500 text-white border rounded-lg'
            text='Save'
          />
        </div>
      </div>
    );
  };
};
