import {
  Godown,
  GodownTransfer,
  HazardCategory,
  HazardClass,
  HazardPrecaution,
  HazardStatement,
  ItemBatchGodown,
  ItemCategoryRes,
  ItemRes,
  PhysicalStockType,
  Revision,
  RevisionFilterType,
} from '@erp_core/erp-types/dist/modules/inventory';
import {
  Grade,
  GradeFilter,
} from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import {
  StockGroup,
  StockGroupTempFilterType,
} from '@erp_core/erp-types/dist/types/modules/inventory/stockgroup';
import { HttpHazardGhsClassRepo } from '../datalayer/repository/http/admin/hazard-regulations/ghs-class';
import { HttpGhsPrecautionaryStatementRepo } from '../datalayer/repository/http/admin/hazard-regulations/ghs-precautionary-statement';
import { HttpHazardCategoryRepo } from '../datalayer/repository/http/admin/hazard-regulations/hazard-category';
import { HttpHazardStatementRepo } from '../datalayer/repository/http/admin/hazard-regulations/hazard-statements';
import { HttpGodownRepo } from '../datalayer/repository/http/inventory/generic/godown';
import { HttpGradeRepo } from '../datalayer/repository/http/inventory/generic/grade';
import { HttpItemV2Repo } from '../datalayer/repository/http/inventory/generic/item';
import { HttpItemCategoryV2Repo } from '../datalayer/repository/http/inventory/generic/item-category';
import { HttpRevisionRepo } from '../datalayer/repository/http/inventory/generic/revision';
import { HttpStockGroupRepo } from '../datalayer/repository/http/inventory/generic/stock-group';
import { HttpGodownTransferRepo } from '../datalayer/repository/http/inventory/godown-transfer';
import { HttpBatchItemGodownRepo } from '../datalayer/repository/http/inventory/item/batch/batch-item-godown';
import { HttpPhysicalStockRepo } from '../datalayer/repository/http/inventory/item/stock/physical-stock';
import { ItemCategoryHttpRepo } from '../datalayer/repository/http/inventory/itemCategory';
import { createUseGetAllGodown } from '../hooks/inventory/godown-inventory/use-godowns-tree';
import { createUseBatchItemAllocate } from '../hooks/inventory/item/batch/use-batch-item-allocate';
import { createUseMRS } from '../hooks/inventory/mrs/use-mrs';
import { createUsePaginatedResources } from '../hooks/resource/use-paginated-resources';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import {
  HazardClassFilterType,
  HazardGhsClassInterface,
} from '../models/interfaces/admin/hazard-regulations/ghs-class';
import {
  GhsPrecautionaryStatementInterface,
  HazardPrecautionFilterType,
} from '../models/interfaces/admin/hazard-regulations/ghs-precautionary-statement';
import {
  HazardCategoryFilterType,
  HazardCategoryInterface,
} from '../models/interfaces/admin/hazard-regulations/hazard-category';
import {
  HazardStatementFilterType,
  HazardStatementInterface,
} from '../models/interfaces/admin/hazard-regulations/hazard-statement';
import {
  GodownFilterType,
  GodownInterface,
} from '../models/interfaces/inventory/generic/godown';
import { ItemCategoryV2Interface } from '../models/interfaces/inventory/generic/item-category';
import {
  GodownTransferFilterType,
  GodownTransferInterface,
} from '../models/interfaces/inventory/godown-transfer';
import { GradeInterface } from '../models/interfaces/inventory/grade';
import {
  BatchItemGodownFilterType,
  BatchItemGodownInterface,
} from '../models/interfaces/inventory/item/batch/batch-item-godown';
import { ItemInterface } from '../models/interfaces/inventory/item/index';
import { ItemCategoryFilterType } from '../models/interfaces/inventory/item/item-categoory';
import {
  PhysicalStockFilterType,
  PhysicalStockInterface,
} from '../models/interfaces/inventory/item/stock/physical-stock';
import { RevisionInterface } from '../models/interfaces/inventory/revision';
import { StockGroupInterface } from '../models/interfaces/inventory/stock-group';
import { ItemFilter } from '../models/types/inventory/item';
import { erpV2Api } from './admin';
import { INVENTORY_SERVER_URI } from './server-uri';

export const godownService = new HttpGodownRepo(INVENTORY_SERVER_URI, erpV2Api);
// eslint-disable-next-line
import { bomService, purchaseOrderService } from './order';
export const useGodownsTree = createUseGetAllGodown({
  useGodownService: () => godownService,
});

export const useGodown = createUseResource<
  Godown,
  GodownFilterType,
  GodownInterface
>({
  useService: () => godownService,
});

export const useGodowns = createUseResources<
  Godown,
  GodownFilterType,
  GodownInterface
>({
  useService: () => godownService,
});

export const usePaginatedGodowns = createUsePaginatedResources<
  Godown,
  GodownFilterType,
  GodownInterface
>({
  useService: () => godownService,
});

export const stockGroupService = new HttpStockGroupRepo(
  INVENTORY_SERVER_URI,
  erpV2Api
);
export const useStockGroup = createUseResource<
  StockGroup,
  StockGroupTempFilterType,
  StockGroupInterface
>({
  useService: () => stockGroupService,
});

export const useStockGroups = createUseResources<
  StockGroup,
  StockGroupTempFilterType,
  StockGroupInterface
>({
  useService: () => stockGroupService,
});

export const usePaginatedStockGroups = createUsePaginatedResources<
  StockGroup,
  StockGroupTempFilterType,
  StockGroupInterface
>({
  useService: () => stockGroupService,
});

export const itemV2Service = new HttpItemV2Repo(INVENTORY_SERVER_URI, erpV2Api);
export const useItem = createUseResource<ItemRes, ItemFilter, ItemInterface>({
  useService: () => itemV2Service,
});

export const useItems = createUseResources<ItemRes, ItemFilter, ItemInterface>({
  useService: () => itemV2Service,
});

export const usePaginatedItems = createUsePaginatedResources<
  ItemRes,
  ItemFilter,
  ItemInterface
>({
  useService: () => itemV2Service,
});

export const gradeService = new HttpGradeRepo(INVENTORY_SERVER_URI, erpV2Api);
export const useGrade = createUseResource<Grade, GradeFilter, GradeInterface>({
  useService: () => gradeService,
});

export const useGrades = createUseResources<Grade, GradeFilter, GradeInterface>(
  {
    useService: () => gradeService,
  }
);

export const itemCategoryService = new ItemCategoryHttpRepo(
  INVENTORY_SERVER_URI,
  erpV2Api
);

export const itemCategoryV2Service = new HttpItemCategoryV2Repo(
  INVENTORY_SERVER_URI,
  erpV2Api
);
export const useItemCategories = createUseResources<
  ItemCategoryRes,
  ItemCategoryFilterType,
  ItemCategoryV2Interface
>({
  useService: () => itemCategoryV2Service as any,
});

export const useItemCategory = createUseResource<
  ItemCategoryRes,
  ItemCategoryFilterType,
  ItemCategoryV2Interface
>({
  useService: () => itemCategoryV2Service as any,
});

export const usePaginatedItemCategories = createUsePaginatedResources<
  ItemCategoryRes,
  ItemCategoryFilterType,
  ItemCategoryV2Interface
>({
  useService: () => itemCategoryV2Service as any,
});

export const godownTransferService = new HttpGodownTransferRepo(
  INVENTORY_SERVER_URI,
  erpV2Api
);
export const useGodownTransfer = createUseResource<
  GodownTransfer,
  GodownTransferFilterType,
  GodownTransferInterface
>({
  useService: () => godownTransferService,
});

export const useGodownTransfers = createUseResources<
  GodownTransfer,
  GodownTransferFilterType,
  GodownTransferInterface
>({
  useService: () => godownTransferService,
});

export const physicalStockService = new HttpPhysicalStockRepo(
  INVENTORY_SERVER_URI,
  erpV2Api
);
export const usePhysicalStock = createUseResource<
  PhysicalStockType,
  PhysicalStockFilterType,
  PhysicalStockInterface
>({
  useService: () => physicalStockService,
});

export const batchItemGodownService = new HttpBatchItemGodownRepo(
  INVENTORY_SERVER_URI,
  erpV2Api,
  bomService
);
export const useBatchItemGodown = createUseResource<
  ItemBatchGodown,
  BatchItemGodownFilterType,
  BatchItemGodownInterface
>({
  useService: () => batchItemGodownService,
});

export const useBatchItemGodowns = createUseResources<
  ItemBatchGodown,
  BatchItemGodownFilterType,
  BatchItemGodownInterface
>({
  useService: () => batchItemGodownService,
});

export const usePaginatedBatchItemGodowns = createUsePaginatedResources<
  ItemBatchGodown,
  BatchItemGodownFilterType,
  BatchItemGodownInterface
>({
  useService: () => batchItemGodownService,
});

export const ghsPrecautionaryStatementService = new HttpGhsPrecautionaryStatementRepo(
  INVENTORY_SERVER_URI,
  erpV2Api
);
export const useGhsPrecautionaryStatement = createUseResource<
  HazardPrecaution,
  HazardPrecautionFilterType,
  GhsPrecautionaryStatementInterface
>({
  useService: () => ghsPrecautionaryStatementService,
});

export const useGhsPrecautionaryStatements = createUseResources<
  HazardPrecaution,
  HazardPrecautionFilterType,
  GhsPrecautionaryStatementInterface
>({
  useService: () => ghsPrecautionaryStatementService,
});

export const usePaginatedPrecautionaryStatements = createUsePaginatedResources<
  HazardPrecaution,
  HazardPrecautionFilterType,
  GhsPrecautionaryStatementInterface
>({
  useService: () => ghsPrecautionaryStatementService,
});

export const hazardCategoryService = new HttpHazardCategoryRepo(
  INVENTORY_SERVER_URI,
  erpV2Api
);
export const useHazardCategory = createUseResource<
  HazardCategory,
  HazardCategoryFilterType,
  HazardCategoryInterface
>({
  useService: () => hazardCategoryService,
});

export const useHazardCategories = createUseResources<
  HazardCategory,
  HazardCategoryFilterType,
  HazardCategoryInterface
>({
  useService: () => hazardCategoryService,
});

export const hazardStatementService = new HttpHazardStatementRepo(
  INVENTORY_SERVER_URI,
  erpV2Api
);
export const useHazardStatement = createUseResource<
  HazardStatement,
  HazardStatementFilterType,
  HazardStatementInterface
>({
  useService: () => hazardStatementService,
});

export const useHazardStatements = createUseResources<
  HazardStatement,
  HazardStatementFilterType,
  HazardStatementInterface
>({
  useService: () => hazardStatementService,
});

export const usePaginatedHazardStatement = createUsePaginatedResources<
  HazardStatement,
  HazardStatementFilterType,
  HazardStatementInterface
>({
  useService: () => hazardStatementService,
});

export const hazardGhsClassService = new HttpHazardGhsClassRepo(
  INVENTORY_SERVER_URI,
  erpV2Api
);
export const useHazardGhsClass = createUseResource<
  HazardClass,
  HazardClassFilterType,
  HazardGhsClassInterface
>({
  useService: () => hazardGhsClassService,
});

export const useHazardGhsClasses = createUseResources<
  HazardClass,
  HazardClassFilterType,
  HazardGhsClassInterface
>({
  useService: () => hazardGhsClassService,
});

export const usePaginatedHazardGhsClasses = createUsePaginatedResources<
  HazardClass,
  HazardClassFilterType,
  HazardGhsClassInterface
>({
  useService: () => hazardGhsClassService,
});

export const useBatchGodownItemAllocate = createUseBatchItemAllocate({
  batchItemGodownService,
});

export const useMRS = createUseMRS({
  useBomService: () => bomService,
  useBatchItemGodownService: () => batchItemGodownService,
  usePurchaseOrderService: () => purchaseOrderService,
  useGodownTransferService: () => godownTransferService,
});

export const revisionService = new HttpRevisionRepo(
  INVENTORY_SERVER_URI,
  erpV2Api
);
export const useRevision = createUseResource<
  Revision,
  RevisionFilterType,
  RevisionInterface
>({
  useService: () => revisionService,
});

export const useRevisions = createUseResources<
  Revision,
  RevisionFilterType,
  RevisionInterface
>({
  useService: () => revisionService,
});

export const usePaginatedRevisions = createUsePaginatedResources<
  Revision,
  RevisionFilterType,
  RevisionInterface
>({
  useService: () => revisionService,
});
