/* eslint-disable no-unused-vars */
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormProps,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { UseCompanyGroupSetting } from '../../../../../hooks/admin/company-group-setting/use-company-group-setting';

export type RenderRmGradeCalculator = {
  closeModal: () => void;
  useCompanyGroupSetting: UseCompanyGroupSetting;
};

type Props = {
  comGrpSet?: CompanyGroupSetting;
};

export function renderRMGradeCalculatorForm({
  closeModal,
  useCompanyGroupSetting,
}: RenderRmGradeCalculator): (data: Props) => JSX.Element {
  return function ({ comGrpSet }: Props): JSX.Element {
    const { syncSet: setCompanyGroupSetting } = useCompanyGroupSetting();
    if (!comGrpSet) return <Recat className='h-5 inline animate-pulse mx-4' />;
    const rmGrades = comGrpSet?.details?.purchase?.rmGrades || [];
    const saveRmGrade = async (form: FormType) => {
      const finalData = {
        id: comGrpSet.id,
        details: {
          ...comGrpSet.details,
          purchase: {
            ...comGrpSet.details.purchase,
            rmGrades: comGrpSet.details.purchase?.rmGrades || [],
          },
        },
      };

      // This logic is bit complicated to understand in first go in can user don't have cleared concept of spread operators and ES6 syntax
      finalData.details.purchase.rmGrades = (
        finalData.details?.purchase?.rmGrades || []
      ).find((k) => k.gradeName === form.gradeName)
        ? (finalData.details?.purchase?.rmGrades || []).map((r) => {
            if (r.gradeName === form.gradeName) {
              return {
                gradeName: r.gradeName,
                data: r.data.find((m) => m.parameter === form.parameter)
                  ? r.data.map((t) => {
                      if (t.parameter === form.parameter) {
                        return {
                          ...t,
                          lessThan: form.lessThan,
                          greaterThan: form.greaterThan,
                        };
                      }
                      return t;
                    })
                  : [
                      ...r.data,
                      {
                        parameter: form.parameter,
                        lessThan: form.lessThan,
                        greaterThan: form.greaterThan,
                      },
                    ],
              };
            }
            return r;
          })
        : [
            ...(finalData.details?.purchase?.rmGrades || []),
            {
              gradeName: form.gradeName,
              data: [
                {
                  parameter: form.parameter,
                  greaterThan: form.greaterThan,
                  lessThan: form.lessThan,
                },
              ],
            },
          ];

      await setCompanyGroupSetting(finalData as CompanyGroupSetting);
      closeModal();
    };

    type FormType = {
      gradeName: string;
      lessThan: number;
      greaterThan: number;
      parameter: string;
    };

    const Card = renderCardComponent();

    const Table = renderTableComponent();

    return (
      <div>
        <Card
          header={{
            title: 'Raw Material Grade Calculator',
            actions: [
              {
                type: 'button',
                button: {
                  name: 'Add New Grade',
                  behaviour: 'modal',
                  modal: {
                    title: 'Add New RM Grade',
                    content: ({ onClose }) => {
                      const formProps: FormProps<FormType> = {
                        fieldsData: [
                          {
                            property: 'gradeName',
                            label: 'Grade Name',
                            type: 'input',
                            required: true,
                          },
                          {
                            property: 'parameter',
                            label: 'Parameter',
                            type: 'select',
                            options: [{ value: 'cost', text: 'Cost' }],
                            required: true,
                          },
                          {
                            property: 'greaterThan',
                            label: 'Greater Than % (Lower Limit)',
                            type: 'number',
                            required: true,
                          },
                          {
                            property: 'lessThan',
                            label: 'Less Than % (Upper Limit)',
                            type: 'number',
                            required: true,
                          },
                        ],
                        initialFormState: {
                          gradeName: '',
                          lessThan: 0,
                          greaterThan: 0,
                          parameter: 'cost',
                        },
                        allowUnchanged: true,
                        mapTToU: (d) => {
                          return d as FormType;
                        },
                        style: 'md:w-1/2',
                        onSubmit: async (form) => {
                          await saveRmGrade(form);
                          onClose();
                        },
                      };

                      const Form = renderFormV2<any>(formProps);
                      return <Form />;
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <Table
                header={[[{ name: 'Grade' }, { name: 'Equations' }]]}
                body={rmGrades.map((r) => {
                  return {
                    cells: [
                      { value: r.gradeName },
                      {
                        value: (
                          <div>
                            {r.data.map((d) => {
                              return (
                                <div key={`${r.gradeName}-${d.parameter}`}>
                                  <div className='font-bold'>
                                    {d.greaterThan} &#60;{' '}
                                    {d.parameter?.toUpperCase()} &#60;{' '}
                                    {d.lessThan}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ),
                      },
                    ],
                  };
                })}
              />
            ),
          }}
        />
      </div>
    );
  };
}
