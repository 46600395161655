import { ReportsHttpRepo } from '../datalayer/repository/http/reporting/reports';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { ReportsInterface } from '../models/interfaces/reporting/reports';
import {
  ReportsFilterType,
  ReportsType,
} from '../models/types/reporting/reports';
import { erpV2Api } from './admin';
import { REPORTING_SERVER_URI } from './server-uri';

export const reportsService = new ReportsHttpRepo(
  REPORTING_SERVER_URI,
  erpV2Api
);

export const useReport = createUseResource<
  ReportsType,
  ReportsFilterType,
  ReportsInterface
>({
  useService: () => reportsService,
});

export const useReports = createUseResources<
  ReportsType,
  ReportsFilterType,
  ReportsInterface
>({
  useService: () => reportsService,
});
