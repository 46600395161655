import { ApprovedBadge } from '@erp_core/erp-icons/icons/web/approved-badge';
import { RejectedBadge } from '@erp_core/erp-icons/icons/web/rejected-badge';
import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import {
  CardBody,
  CardHeader,
  FormDataType,
  FormField,
  renderCardComponent,
  renderFormV2,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';

// import { Download } from '@erp_core/erp-icons/icons/web/download';
import {
  downloadPdfFile,
  renderInlineInput,
  UnAuthorizedPage,
  UnderConstruction,
} from '@erp_core/erp-ui-components';
import {
  CubeIcon,
  InboxStackIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseAppearances } from '../../../../hooks/admin/constants/appearance/use-appearances';
import { UseColors } from '../../../../hooks/admin/constants/color/use-colors';
import { UseGsts } from '../../../../hooks/admin/constants/gst/use-gsts';
import { UseMetrics } from '../../../../hooks/admin/constants/metrics/use-metrics';
import { UseOdours } from '../../../../hooks/admin/constants/odour/use-odours';
import { UseHazardGhsClass } from '../../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseHazardGhsClasses } from '../../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-classes';
import { UseGhsPrecautionaryStatements } from '../../../../hooks/admin/hazard-regulations/ghs-precautionary-statements/use-ghs-precautionary-statements';
import { UseHazardStatements } from '../../../../hooks/admin/hazard-regulations/hazard-statements/use-hazard-statements';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseRoles } from '../../../../hooks/admin/role-admin/use-roles-admin';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseGrade } from '../../../../hooks/inventory/grade/use-grade';
import { UseGrades } from '../../../../hooks/inventory/grade/use-grades';
import { UsePhysicalStock } from '../../../../hooks/inventory/item/stock/use-physical-stock';
import { UseItem } from '../../../../hooks/inventory/item/use-item';
import { UseItems } from '../../../../hooks/inventory/item/use-items';
import { UseVendors } from '../../../../hooks/inventory/purchase/vendors/use-vendors';
import { UseRevision } from '../../../../hooks/inventory/revision/use-revision';
import { UseRevisions } from '../../../../hooks/inventory/revision/use-revisions';
import { UseClients } from '../../../../hooks/order/sales/client/use-clients';
import { ItemInterface as ItemV2Interface } from '../../../../models/interfaces/inventory/item/index';
import { StockGroupInterface } from '../../../../models/interfaces/inventory/stock-group';
import { CategoryIcons } from '../item-categories';
// import { downloadItemSpec } from '../utils/item-spec-downloader';
import { UseItemCategories } from '../../../../hooks/inventory/item/item-category/use-item-categories';
import { getItemSpecList } from '../utils/item-spec-downloader';
import { renderItemAuthorisers } from './authoriser-tab';
import { renderItemBomTab } from './bom-tab';
import { renderEndUseTab } from './end-use';
import { renderItemGrades } from './grade-tab';
import { renderItemGHSRegulations } from './hazard-regulation';
import { renderHistoryTab } from './history-tab';
import { renderItemlabel } from './item-specification-tab';
import { renderItemPacking } from './packing';
import { renderPropertiesTab } from './properties-tab';
import { renderItemShiftConfig } from './shift-config';
import { renderStockTab } from './stock-tab/stockTab';
import { renderVendorTab } from './vendor-tab/vendorTab';

type RenderItemV2Profile = {
  hooks: {
    useItems: UseItems;
    useItem: UseItem;
    useFileTransfer: UseFileTransfer;
    useVendors: UseVendors;
    useClients: UseClients;
    usePhysicalStock: UsePhysicalStock;
    useHazardClass: UseHazardGhsClass;
    useHazardClasses: UseHazardGhsClasses;
    useHazardStatements: UseHazardStatements;
    useGhsPrecautionaryStatements: UseGhsPrecautionaryStatements;
    useMetrics: UseMetrics;
    useGsts: UseGsts;
    useOdours: UseOdours;
    useAppearances: UseAppearances;
    useColors: UseColors;
    useUserAuthorization: UseUserAuthorization;
    useGrades: UseGrades;
    useGrade: UseGrade;
    useEmployees: UseEmployeeProfiles;
    useCurrentUserRoles: UseCurrentUserRoles;
    useRevision: UseRevision;
    useRevisions: UseRevisions;
    useRoles: UseRoles;
    useUsers: UseUsers;
    useItemCategories: UseItemCategories;
  };
  services: {
    itemV2Service: ItemV2Interface;
    stockGroupService: StockGroupInterface;
  };
};

export function renderItemV2Profile({
  hooks: {
    useItems,
    useItem,
    useFileTransfer,
    useVendors,
    useClients,
    usePhysicalStock,
    useHazardClass,
    useHazardClasses,
    useHazardStatements,
    useGhsPrecautionaryStatements,
    useMetrics,
    useGsts,
    useRevision,
    useUserAuthorization,
    useGrades,
    useGrade,
    useEmployees,
    useCurrentUserRoles,
    useRevisions,
    useRoles,
    useUsers,
    useAppearances,
    useColors,
    useOdours,
    useItemCategories,
  },
  services: { itemV2Service },
}: RenderItemV2Profile): () => JSX.Element {
  const Card = renderCardComponent();

  const ShiftConfig = renderItemShiftConfig();
  const ItemAuthorisers = renderItemAuthorisers({ useEmployees });

  return function ItemProfile(): JSX.Element {
    const { id } = useParams();
    const [specs, setSpecs] = useState<Array<{ name: string; url: string }>>(
      []
    );
    const NewTabs = renderTabsV2Component();
    const { get: getAllowedActions } = useUserAuthorization();
    // const { get: getPdf } = useFileTransfer();
    const { data: item, get, syncSet: setItem, loading } = useItem();
    const { syncSet: setRevision } = useRevision();
    const Grades = renderItemGrades();
    const PropertiesTab = renderPropertiesTab();
    const { list, get: getFile } = useFileTransfer();

    const { data: physicalStock, get: getPhysicalStock } = usePhysicalStock();

    // eslint-disable-next-line
    useEffect(() => {
      if (id) {
        get(id);
        getPhysicalStock(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
      if (item.stockgroup?.id && item.companyGroup?.id) {
        getItemSpecList({
          companyGroupId: item.companyGroup.id,
          stockGroupId: item.stockgroup.id,
          itemId: item.id,
          list,
        }).then((specs) => {
          if (specs.length) {
            setSpecs(specs);
          }
        });
      }
      // eslint-disable-next-line
    }, [item]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const InlineInput = renderInlineInput({ useResource: useItem, id });

    const header: CardHeader = {
      title: (
        <>
          <span>{item.name || id}</span>{' '}
          <span className='italic font-thin'>
            version {item.version || '0.0.0'}
          </span>
        </>
      ),
      icon: item.details?.profile?.logo ? (
        <img
          alt={item.name}
          src={item.details?.profile.logo}
          className='w-24 h-24 self-center'
        />
      ) : (
        <CubeIcon className='text-gray-800 w-24 h-full' />
      ),
      subheading: (
        <>
          <div className='text-gray-700 italic'>
            {/* { data.description || 'Add a description for the item...' } */}
            <InlineInput
              name='description'
              value={item.description || 'Add a description for the item...'}
            />
          </div>
          <div className='text-gray-700'>
            <a
              className='font-bold'
              href={`/inventory/masters/stock-groups/${item.stockgroup?.id}/profile`}
              target='_blank'
              rel='noreferrer'
            >
              <InboxStackIcon className='w-6 inline pr-1' />
              {item.stockgroup?.name || ''}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${item.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' /> {item.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${item.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {item.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(item.lastModifiedAt).fromNow()}
          </div>
          <div className='flex space-x-2 my-2'>
            {item.categories?.map((x) => (
              <div
                key={x.id}
                className='border flex border-blue-400 rounded-md px-2 py-1 text-sm text-blue-600'
              >
                <CategoryIcons category={x.category} key={x.id} />
                {x.category?.name}
              </div>
            ))}
            {item.status === 'approved' ? (
              <span className='border border-green-600 rounded-md px-2 py-1 text-sm text-green-600'>
                <ApprovedBadge className='mr-1 inline w-4 text-green-600' />
                <span className='capitalize'>Approved</span>
              </span>
            ) : (
              <span className='border border-red-600 rounded-md px-2 py-1 text-sm text-red-600'>
                <RejectedBadge className='mr-1 inline w-4 text-red-600' />
                <span className='capitalize'>Unapproved</span>
              </span>
            )}

            <span className='border flex justify-center border-blue-400 rounded-md px-2 py-1 text-sm text-amber-900'>
              <CubeIcon className='mr-1 inline w-4' />
              Current Stock: {physicalStock.physicalQuantity}
            </span>
          </div>
        </>
      ),
      actions: [
        // {
        //   type: 'button',
        //   button: {
        //     suffix: <Download className='text-green-500 w-5 inline' />,
        //     name: 'Specs',
        //     onClick: () => {
        //       downloadItemSpec({
        //         itemService: itemV2Service,
        //         companyGroupId: item.companyGroup.id,
        //         stockGroupId: item.stockgroup.id,
        //         item,
        //         getSpecTemplate: getPdf,
        //       });
        //     },
        //     style: 'bg-gray-50 mx-1',
        //   },
        // },
        {
          type: 'button',
          button: {
            name: 'Approve',
            behaviour: 'modal',
            modal: {
              title: 'Approve',
              content: ({ onClose }) => {
                let Form = renderFormV2<any>({
                  style: 'md:w-1/2',
                  fieldsData: getFields(),
                  initialFormState: { ...(item as any) },
                  onSubmit: async (data) => {
                    await setItem(({
                      id: item.id,
                      status: data.status || item.status,
                    } as unknown) as ItemRes);
                    onClose();
                  },
                  mapTToU: (b: FormDataType<any>) => b,
                });
                return <Form />;
              },
            },
          },
        },
      ],
      menu: {
        actions: [
          ...specs.map((x) => {
            return {
              name: x.name,
              show: () => true,
              behaviour: 'click',
              onClick: async () => {
                const res = await getFile(x.url, true);
                const fName = `${item.name}-${x.name}.pdf`;
                downloadPdfFile({ result: res, fileName: fName });
              },
            };
          }),
          {
            name: 'Edit Categories',
            show: () => true,
            behaviour: 'modal',
            modal: {
              title: 'Edit Categories',
              content: ({ onClose }) => {
                const Form = renderFormV2({
                  onSubmit: async (form) => {
                    await itemV2Service.changeItemCategories({
                      itemId: item.id,
                      categories: form.categories as Array<any>,
                    });
                    get(id);
                    onClose();
                  },
                  mapTToU: (x) => x,
                  fieldsData: [
                    {
                      property: 'categories',
                      type: 'searchable-multi-select',
                      searchOptions: {
                        useSearch: useItemCategories,
                        onSearchValueSelect: (e) => {},
                      },
                      label: 'Categories',
                    },
                  ],
                  initialFormState: {
                    categories: (item.categories || []).map((x) => {
                      return {
                        id: x.category.id,
                        name: x.category.name,
                      };
                    }),
                  },
                });

                return <Form />;
              },
            },
          } as any,
        ],
      },
    };
    const allowedActions = getAllowedActions();

    const newCategories = [
      {
        name: 'properties',
        Fn: () => (
          <>
            <PropertiesTab
              useMetrics={useMetrics}
              useGsts={useGsts}
              useAppearances={useAppearances}
              useColors={useColors}
              useOdours={useOdours}
              useRevision={useRevision}
              useEmployees={useEmployees}
              itemService={itemV2Service}
              item={item}
              setItem={async (s) => {
                console.log('attempted to set item ', s);
                return s;
              }}
              useCurrentUserRoles={useCurrentUserRoles}
              setRevision={setRevision}
              useRevisions={useRevisions}
              useFileTransfer={useFileTransfer}
              useRoles={useRoles}
              useUsers={useUsers}
            />
          </>
        ),
      },
      {
        name: 'grades',
        Fn: () => (
          <Grades item={item} useGrades={useGrades} useGrade={useGrade} />
        ),
      },
      {
        name: 'label',
        Fn: renderItemlabel({ id, useFileTransfer }),
      },
      { name: 'stp', Fn: UnderConstruction },
      {
        name: 'haz-reg-msds',
        Fn: renderItemGHSRegulations({
          id,
          setItem,
          itemData: item,
          useHazardClass,
          useHazardClasses,
          useHazardStatements,
          useGhsPrecautionaryStatements,
          useFileTransfer,
        }),
      },
      {
        name: 'end-use',
        Fn: renderEndUseTab({
          itemEnduse: item,
          useItems,
          useUserAuthorization,
          loading,
          setItemEnduse: setItem,
        }),
      },
      {
        name: 'packing',
        Fn: ['FG', 'RM/FG'].includes(item.category?.id || '')
          ? renderItemPacking({ itemData: item })
          : () => <></>,
      },
      {
        name: 'shift-config',
        Fn:
          item?.category?.id === 'FA'
            ? () => <ShiftConfig setItem={setItem} item={item} />
            : () => <></>,
      },
      {
        name: 'bom',
        Fn: ['INT', 'FG', 'RM/FG', 'FA'].includes(item.category?.id || '')
          ? renderItemBomTab({
              itemData: item,
              itemService: itemV2Service,
              useItems,
              id,
              useItem,
            })
          : () => <></>,
      },
      {
        name: 'batches',
        Fn: UnderConstruction,
      },
      {
        name: 'stock',
        Fn: renderStockTab({
          services: { itemService: itemV2Service },
          itemId: id,
        }),
      },
      {
        name: 'vendors-customers',
        Fn: ['FG', 'RM/FG', 'RM'].includes(item.category?.id || '')
          ? renderVendorTab({ itemId: id, hooks: { useVendors, useClients } })
          : () => <></>,
      },
      {
        name: 'authorizers',
        Fn: allowedActions.actions['UI:ITEM-AUTHZ:VIEW']?.action
          ? () => (
              <ItemAuthorisers
                id={id}
                loading={loading}
                setItem={setItem}
                item={item}
              />
            )
          : UnAuthorizedPage,
      },
      {
        name: 'history',
        Fn: renderHistoryTab({ id, useItem }),
      },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return (
      <>
        <Card header={header} body={body} />
      </>
    );
  };
}
function getFields(): FormField<ItemRes>[] {
  return [
    {
      property: 'status',
      type: 'select',
      options: [
        { text: 'approved', value: 'approved' },
        { text: 'unapproved', value: 'unapproved' },
      ],
    },
  ];
}
