import {
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseBomProject } from '../../../../hooks/order/work-order/bom-project/use-bom-project';
import { UseBomProjects } from '../../../../hooks/order/work-order/bom-project/use-bom-projects';
import { renderAddEditBomProject } from './form/add-bom-project';

export function renderBomProjectDetails({
  useBomProject,
  useBomProjects,
  useUsers,
}: {
  useBomProject: UseBomProject;
  useBomProjects: UseBomProjects;
  useUsers: UseUsers;
}) {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return function BomProjectDetails(): JSX.Element {
    let { id } = useParams();
    const {
      data: bomProject,
      get: getBomProject,
      syncSet: setBomProject,
    } = useBomProject();
    const { data: bomProjects, getAll: getBomProjects } = useBomProjects();

    useEffect(() => {
      if (id) {
        getBomProject(id);
        getBomProjects({ parentBomProjectId: id });
      }
      // eslint-disable-next-line
    }, [id]);

    return (
      <Card
        header={{
          title: bomProject?.name || 'Bom Details',
          subheading: (
            <>
              {bomProject ? (
                <>
                  <span className='border border-gray-200 mx-1 px-2 rounded-md'>
                    Type: {bomProject.type}
                  </span>
                  <span className='border border-gray-200 mx-1 px-2 rounded-md'>
                    Agency: {bomProject.details?.agency}
                  </span>
                </>
              ) : null}
            </>
          ),
        }}
        body={{
          type: 'jsx-component',
          body: (
            <div>
              <Table
                header={[
                  [
                    { name: 'Project Name' },
                    { name: 'Type' },
                    { name: 'Design Engineer' },
                    { name: 'Target Date' },
                    { name: 'Budget' },
                    { name: 'Agency' },
                    { name: 'Completion' },
                    { name: 'Project Manager' },
                  ],
                ]}
                body={(bomProjects || []).map((x) => {
                  return {
                    cells: [
                      {
                        value: (
                          <>
                            <div className='font-semibold'>{x.name}</div>
                            <div className='italic'>{x.description}</div>
                          </>
                        ),
                      },
                      { value: x.type },
                      { value: x.details?.designEng?.name || '-' },
                      { value: x.details?.targateDate || '-' },
                      { value: x.details?.budget || '-' },
                      { value: x.details?.agency || '-' },
                      { value: x.details?.completion || '-' },
                      { value: x.details?.siteEng?.name || '-' },
                    ],
                    rowData: {
                      project: x,
                    },
                  };
                })}
                actions={[
                  {
                    name: 'Edit',
                    show: () => true,
                    behaviour: 'modal',
                    modal: {
                      title: 'Edit',
                      content: ({ data: { project }, onClose }) => {
                        const AddEdit = renderAddEditBomProject({
                          useBomProjects,
                          useUsers,
                        });
                        return (
                          <AddEdit
                            setBomProject={setBomProject}
                            bomProject={project}
                            onClose={() => {
                              onClose();
                              getBomProjects({ parentBomProjectId: id });
                            }}
                          />
                        );
                      },
                    },
                  },
                ]}
              />
            </div>
          ),
        }}
      />
    );
  };
}
