import {
  Company,
  CompanyGroupSetting,
  CurrentUserType,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  AttendanceType,
  CombinedAttDataType,
  EmployeeProfileType,
  LeaveAppType,
  OffSiteWorkHoursType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { GatePassType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import { OvertimeType } from '@erp_core/erp-types/dist/types/modules/hrd/overtime';
import { TableActionsType } from '@erp_core/erp-ui-components';
import { sleep } from '@erp_core/erp-utils';
import moment from 'moment';
import toast from 'react-hot-toast';
import { UseOvertimes } from '../../../../../../hooks/hrd/employee/profile/overtime/use-overtimes';
import { UseEmployeeProfiles } from '../../../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseLeaves } from '../../../../../../hooks/hrd/leave/use-leaves';
import { AttendanceInterface } from '../../../../../../models/interfaces/hrd/attendance';
import {
  LeaveFilterType,
  LeaveInterface,
} from '../../../../../../models/interfaces/hrd/leave';
import { LogRecordInterface } from '../../../../../../models/interfaces/planning/log-record';
import { renderAddOvertimeForm } from '../../../forms/overtime-reporting';
import { renderRequestLeaveFormV2 } from '../../../leaves-book/forms/request-leave/modal-content-v2';
import { renderOffSiteWorkHoursForm } from '../../../off-site-work-hours/forms/add-off-site-work-hours';
import { renderAddGatePassForm } from '../buttons/add-gatepass';
import { renderAddManualPunchForm } from '../buttons/add-manual-punch';
import { renderCustomFinalize } from '../buttons/finalize';
import { renderLateMarkForms } from '../buttons/late-mark';
import { renderUnFinalize } from '../buttons/un-finalize';
import { renderUnVerify } from '../buttons/un-verify';
import { renderSearchPunches } from '../search-punches';

export function renderActions({
  isHr,
  setAttendance,
  leaveService,
  currentUser,
  useEmployees,
  useLeaves,
  getEmployeeSync,
  setGatePass,
  setOffsite,
  useOvertimes,
  saveNewOvertime,
  setOvertime,
  currentCompany,
  companyGroupSetting,
  fetchData,
  isSystemAdmin,
  attendanceService,
  logRecordService,
  getLeavesSync,
  setLeave,
}: {
  isHr: boolean;
  isSystemAdmin: boolean;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  leaveService: LeaveInterface;
  currentUser: CurrentUserType;
  useEmployees: UseEmployeeProfiles;
  useLeaves: UseLeaves;
  getEmployeeSync: (id: string) => Promise<EmployeeProfileType>;
  setGatePass: (s: GatePassType) => Promise<GatePassType>;
  setOffsite: (s: OffSiteWorkHoursType) => Promise<OffSiteWorkHoursType>;
  useOvertimes: UseOvertimes;
  saveNewOvertime: (p: any) => Promise<void>;
  setOvertime: (s: OvertimeType) => Promise<OvertimeType>;
  currentCompany: Company;
  companyGroupSetting?: CompanyGroupSetting;
  fetchData: () => Promise<void>;
  attendanceService: AttendanceInterface;
  logRecordService: LogRecordInterface;
  getLeavesSync: (filter?: LeaveFilterType) => Promise<LeaveAppType[]>;
  setLeave: (s: LeaveAppType) => Promise<LeaveAppType>;
}) {
  return function Actions(): Array<TableActionsType> {
    return [
      {
        name: 'Mark Flexy Present',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          if (attendance.finalized) {
            return false;
          }

          if (
            isHr &&
            attendance?.employeeProfile?.details?.jobProfile?.shiftType ===
              'Flexible' &&
            attendance?.punchResult.totalWorkHours === 0
          ) {
            return true;
          }

          return true;
        },
        behaviour: 'confirm',
        onConfirm: ({ attendance }) => {
          return {
            title: 'Are you sure you want to mark as Flexy Present ?',
            type: 'warning',
            onConfirm: async () => {
              const summary = attendance.details?.summary || {};
              (summary as any).flexyPresent = true;
              await setAttendance({
                date: attendance.date,
                employee: attendance.employee,
                id: attendance.id,
                finalizedBy: currentUser,
                details: {
                  summary,
                },
              } as any);
              toast.success(
                `Marked Flexy Present for ${
                  attendance?.employee?.name || 'user'
                }`
              );
              await sleep(1000);
              fetchData();
            },
          };
        },
      },
      {
        name: 'Ignore Checkin Proximity',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          if (attendance.finalized) {
            return false;
          }

          if (isHr && attendance?.punchResult?.totalWorkHours > 8) {
            return true;
          }

          return true;
        },
        behaviour: 'confirm',
        onConfirm: ({ attendance }) => {
          return {
            title: 'Are you sure you want to ignore Proximity ?',
            onConfirm: async () => {
              const summary = attendance.details?.summary || {};
              (summary as any).ignoreCheckinProximity = true;
              await setAttendance({
                id: attendance.id,
                details: {
                  summary,
                },
              } as any);
              toast.success(
                `Overtime ignored for ${attendance?.employee?.name || 'user'}`
              );
              await sleep(1000);
              fetchData();
            },
          };
        },
      },
      {
        name: 'Add Leave',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          if (
            attendance.finalized ||
            attendance.isOnWeeklyOff ||
            attendance.isLocationOff
          ) {
            return false;
          }

          if (attendance?.punchResult?.totalWorkHours > 9) {
            return false;
          }

          return true;
        },
        behaviour: 'modal',
        modal: {
          title: 'Add Leave',
          content: ({ data, onClose }) => {
            const RequestLeave = renderRequestLeaveFormV2({
              leaveService,
              currentUser,
              useEmployeeProfiles: useEmployees,
              useLeaves,
            });

            return (
              <RequestLeave
                data={data}
                onClose={async () => {
                  await sleep(1000);
                  fetchData();
                  onClose();
                }}
              />
            );
          },
        },
      },
      {
        name: 'Add Gatepass',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }

          if (attendance.finalized) {
            return false;
          }

          // Case when employees directly for home
          if (
            attendance.punchResult?.checkInList?.length === 1 &&
            attendance.punchResult?.checkInList?.length === 1 &&
            attendance.punchResult?.totalWorkHours < 8
          ) {
            return true;
          }

          return (
            !attendance.finalized && attendance.punchResult?.totalOutHours > 0
          );
        },
        behaviour: 'modal',
        modal: {
          title: 'Add Gatepass',
          content: ({ data, onClose }) => {
            const AddGatepass = renderAddGatePassForm({
              getEmployeeSync,
              currentUser,
              setGatePass,
              useEmployees,
              useLeaves,
            });

            return (
              <AddGatepass
                data={data}
                onClose={async () => {
                  await sleep(1000);
                  fetchData();
                  onClose();
                }}
              />
            );
          },
        },
      },
      {
        name: 'Add Off-Site',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          if (
            attendance.finalized ||
            attendance.isOnWeeklyOff ||
            attendance.isLocationOff
          ) {
            return false;
          }

          if (
            !attendance.punchResult?.checkInList ||
            attendance.punchResult?.checkInList?.length === 0
          ) {
            return true;
          }

          return false;
        },
        behaviour: 'modal',
        modal: {
          title: 'Add Off-Site',
          content: ({ data, onClose }) => {
            const AddOffsite = ({
              data,
              onClose,
            }: {
              data: {
                attendance: CombinedAttDataType;
              };
              onClose: () => void;
            }) => {
              const OffSiteWorkHrsForm = renderOffSiteWorkHoursForm({
                useEmployeeProfiles: useEmployees,
              });
              return (
                <OffSiteWorkHrsForm
                  data={
                    {
                      employee: {
                        id: data.attendance.employee.id,
                        name: data.attendance.employee.name,
                      },
                      date: data.attendance.date,
                    } as any
                  }
                  onSave={async (form) => {
                    const finalData = {
                      ...{
                        employee: {
                          id: data.attendance.employee.id,
                          name: data.attendance.employee.name,
                        },
                        date: data.attendance.date,
                      },
                      ...form,
                      appliedBy: {
                        id: currentUser.id,
                        name: currentUser.name,
                      },
                      // time being fix as approvedBy nullable true didn't worked
                      approvedBy: {
                        id: currentUser.id,
                        name: currentUser.name,
                      },
                    };
                    console.log(finalData);
                    await setOffsite(finalData as any);
                    toast.success(
                      `Offsite created successfully for ${
                        data.attendance?.employee?.name || 'user'
                      }`
                    );
                    await sleep(1000);
                    fetchData();
                    onClose();
                  }}
                />
              );
            };

            return <AddOffsite data={data} onClose={onClose} />;
          },
        },
      },
      {
        name: 'Add Overtime',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }

          if (attendance.finalized) {
            return false;
          }
          if (attendance.punchResult?.overtimeHours > 0) {
            return true;
          }

          if (attendance.status === 'worked-on-week-off') {
            return true;
          }

          if (attendance.status === 'worked-on-location-off') {
            return true;
          }

          if (
            attendance.isOnWeeklyOff &&
            attendance.punchResult?.totalWorkHours
          ) {
            return true;
          }

          if (
            attendance.isLocationOff &&
            attendance.punchResult?.totalWorkHours
          ) {
            return true;
          }

          return false;
        },
        behaviour: 'modal',
        modal: {
          title: 'Add Overtime',
          content: ({ data, onClose }) => {
            const AddOvertime = renderAddOvertimeForm({
              useOvertimes,
              useUsers: useEmployees,
              formData: {},
              onSave: async (form) => {
                await saveNewOvertime({
                  form,
                  getUserSync: getEmployeeSync,
                  currentUser,
                  onClose: () => {
                    onClose();
                  },
                  setOvertime,
                });
                toast.success('Overtime created successfully for user');
                await sleep(1000);
                fetchData();
              },
            });

            return <AddOvertime data={data} onClose={onClose} />;
          },
        },
      },
      {
        name: 'Ignore Overtime',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          if (attendance.finalized) {
            return false;
          }

          if (
            !attendance.finalized &&
            attendance.punchResult?.overtimeHours > 0.9
          ) {
            return true;
          }

          if (attendance.status === 'worked-on-week-off') {
            return true;
          }

          if (attendance.status === 'worked-on-location-off') {
            return true;
          }

          return false;
        },
        behaviour: 'confirm',
        onConfirm: ({ attendance }) => {
          return {
            title: `Are you sure you want to Ignore ${attendance.employee.name} Overtime?`,
            message: 'This will assist this record to be auto-finalized.',
            onConfirm: async () => {
              const summary = attendance.details?.summary || {};
              summary.ignoreOvertime = true;
              await setAttendance({
                id: attendance.id,
                details: {
                  summary,
                },
              } as any);
              toast.success(
                `Overtime ignored for ${attendance?.employee?.name || 'user'}`
              );
              await sleep(1000);
              fetchData();
            },
          };
        },
      },
      {
        name: 'Ignore Punches',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          if (attendance.finalized) {
            return false;
          }

          if (
            attendance.employeeProfile?.details?.authorizations
              ?.attendanceAuthorizer?.id !== currentUser.id &&
            !isHr
          ) {
            return false;
          }

          if (
            attendance?.punchResult?.checkInList &&
            attendance?.punchResult?.checkInList?.length > 0
          ) {
            return true;
          }

          return false;
        },
        behaviour: 'confirm',
        onConfirm: ({ attendance }) => {
          return {
            title: `Are you sure you want to Ignore Punches for ${attendance.employee.name}?`,
            message:
              'Ignoring punches will allow user to add a full-day leave.',
            onConfirm: async () => {
              const summary = attendance.details?.summary || {};
              summary.ignorePunches = true;
              await setAttendance({
                id: attendance.id,
                details: {
                  summary,
                },
              } as any);
              toast.success(
                `Punches ignored for ${attendance?.employee?.name || 'user'}`
              );
              await sleep(1000);
              fetchData();
            },
          };
        },
      },
      {
        name: 'Un-Ignore Overtime',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          if (
            !attendance.finalized &&
            attendance.details?.summary?.ignoreOvertime
          ) {
            return true;
          }

          return false;
        },
        behaviour: 'confirm',
        onConfirm: ({ attendance }) => {
          return {
            title: `Are you sure you want to UnIgnore ${attendance.employee.name} Overtime?`,
            onConfirm: async () => {
              const summary = attendance.details?.summary || {};
              summary.ignoreOvertime = false;
              await setAttendance({
                id: attendance.id,
                details: {
                  summary,
                },
              } as any);
              toast.success(
                `Overtime unignored for ${attendance?.employee?.name || 'user'}`
              );
              await sleep(1000);
              fetchData();
            },
          };
        },
      },
      {
        name: 'Add Manual Punch',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          return !attendance.finalized;
        },
        auth: 'UI:BTN-ADD-MANUAL-PUNCH:VIEW',
        behaviour: 'modal',
        modal: {
          title: 'Add Manual Punch',
          content: ({ data, onClose }) => {
            const ManualPunch = renderAddManualPunchForm({
              attendanceService,
              useEmployees,
              currentCompany,
              companyGroupSetting,
              currentUser,
              setAttendance,
              getData: async () => {
                await sleep(1000);
                fetchData();
                toast.success('Manual Punch recorded');
              },
            });

            return <ManualPunch data={data} onClose={onClose} />;
          },
        },
      },
      {
        name: 'Late Mark',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          if (
            !attendance.isLateForShift ||
            (attendance.isLateForShift &&
              attendance.details?.lateMark &&
              !attendance.details?.lateMark?.status) ||
            attendance.finalized
          ) {
            return false;
          }
          if (
            attendance.employeeProfile.details?.authorizations
              ?.attendanceAuthorizer?.id === currentUser.id
          ) {
            return true;
          }

          if (
            attendance.employeeProfile.details?.authorizations
              ?.attendanceSupervisorMonitor?.id === currentUser.id
          ) {
            return true;
          }

          if (isHr) {
            return true;
          }

          return false;
        },
        // auth: 'UI:BTN-ATTEN-FINALIZE:VIEW',
        behaviour: 'modal',
        modal: {
          title: 'Late Mark',
          content: ({ data, onClose }) => {
            const LateMark = renderLateMarkForms({
              setAttendance,
              currentUser,
              refreshData: async () => {
                await sleep(1000);
                fetchData();
                toast.success('Late Mark handled');
              },
            });

            return <LateMark data={data} onClose={onClose} />;
          },
        },
      },
      {
        name: 'Mark Uninformed Absent',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          if (
            attendance.finalized ||
            attendance.isOnWeeklyOff ||
            attendance.isLocationOff
          ) {
            return false;
          }

          if (attendance.empLeaves?.length) {
            return false;
          }

          if (attendance.punchResult?.checkInList?.length !== 0) {
            return false;
          }

          if (
            attendance.offSiteWorkHours?.length &&
            attendance.offSiteWorkHours.filter((x) => x.status !== 'cancelled')
              .length
          ) {
            return false;
          }

          return true;
        },
        behaviour: 'confirm',
        onConfirm: ({ attendance }) => {
          return {
            title: `Are you sure you want to Mark ${attendance.employee.name} as Absent?`,
            message:
              'This will assist this record to be auto-finalized as Absent with Penalty',
            onConfirm: async () => {
              const summary: any = attendance.details?.summary || {};
              summary.payableDayPenalty = true;
              if (attendance.id) {
                await setAttendance({
                  id: attendance.id,
                  details: {
                    summary,
                  },
                } as AttendanceType);
              } else {
                if (attendance?.date && attendance.employee) {
                  const summary: any = attendance.details?.summary || {};
                  summary.payableDayPenalty = true;
                  await setAttendance({
                    name: attendance.date,
                    date: attendance.date,
                    employee: {
                      id: attendance?.employee?.id,
                      name: attendance?.employee.name,
                    },
                    status: attendance?.status,
                    details: {
                      summary,
                    },
                    verified: attendance.verified,
                    finalizedBy: {
                      id: currentUser.id,
                      name: currentUser.name,
                    },
                  } as AttendanceType);
                } else {
                  console.log('unable to save attendance');
                }
              }
              toast.success(
                `Successfully marked ${
                  attendance?.employee?.name || 'user'
                } as absent`
              );
              await sleep(1000);
              fetchData();
            },
          };
        },
      },
      {
        name: 'Custom Finalize',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          if (!attendance.finalized && isSystemAdmin) {
            return true;
          }

          return false;
        },
        auth: 'UI:BTN-ATTEN-FINALIZE:VIEW',
        behaviour: 'modal',
        modal: {
          title: 'Custom Finalize',
          content: ({ data, onClose }) => {
            const CustomFinalize = renderCustomFinalize({
              attendanceService,
              getData: async () => {
                toast.success('Record Finalized');
                await sleep(1000);
                fetchData();
              },
              currentUser,
            });

            return <CustomFinalize data={data} onClose={onClose} />;
          },
        },
      },
      {
        name: 'Auto-Finalize',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }

          if (attendance.finalized) {
            return false;
          }

          if (!attendance.finalized && isHr) {
            return true;
          }

          return false;
        },
        auth: 'UI:BTN-ATTEN-FINALIZE:VIEW',
        behaviour: 'confirm',
        onConfirm: ({ attendance }: { attendance: AttendanceType }) => {
          return {
            title: `Are you sure you want to attempt auto-finalization for ${attendance.employee.name}'s ${attendance.date} attendance?`,
            type: 'warning',
            onConfirm: async () => {
              await attendanceService.autofinalizeEmployee(
                attendance.employee.id,
                attendance.date
              );
              toast.success('Auto-finalize triggered!');
              fetchData();
            },
          };
        },
      },
      {
        name: 'Verify',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          if (attendance.finalized) {
            return false;
          }

          if (attendance.verified?.verified === 'yes') {
            return false;
          }

          const authorizations =
            attendance.employeeProfile?.details?.authorizations ||
            (attendance.employee as any)?.details?.authorizations;

          if (
            attendance.id &&
            (authorizations?.attendanceSupervisor?.id === currentUser?.id ||
              attendance.shiftDay?.supervisor?.id === currentUser.id)
          ) {
            return true;
          }

          return false;
        },
        behaviour: 'confirm',
        onConfirm: ({ attendance }: { attendance: CombinedAttDataType }) => {
          return {
            title: `Are you sure you want to mark ${attendance.employee.name}'s attendance as verified for ${attendance.date}?`,
            type: 'warning',
            onConfirm: async () => {
              const verified = {
                verified: 'yes',
                verifiedAt: moment().utc().format(),
                verifiedBy: {
                  id: currentUser.id,
                  name: currentUser.name,
                },
              };
              await setAttendance({
                id: attendance.id,
                verified,
              } as AttendanceType);
              await sleep(1000);
              fetchData();
            },
          };
        },
      },
      {
        name: 'Un-Verify',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          return (
            !attendance.finalized && attendance.verified?.verified === 'yes'
          );
        },
        auth: 'UI:BTN-ATTEN-UNVERIFY:VIEW',
        behaviour: 'modal',
        modal: {
          title: 'Un-Verify',
          content: ({ onClose, data }) => {
            const UnVerify = renderUnVerify({
              setAttendance,
              getData: async () => {
                toast.success('Record Un-Verified!');
                fetchData();
                await sleep(1000);
                fetchData();
              },
            });

            return <UnVerify data={data} onClose={onClose} />;
          },
        },
      },
      {
        name: 'Search Punch',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          return !attendance.finalized;
        },
        auth: 'UI:BTN-ATTEN-UNVERIFY:VIEW',
        behaviour: 'modal',
        modal: {
          title: 'Search Punch',
          content: ({ data, onClose }) => {
            const SearchPunches = renderSearchPunches({
              setAttendance,
              currentUser,
              logRecordService,
              getData: async () => {
                toast.success('Punches Updated!');
                await sleep(1000);
                fetchData();
              },
            });

            return <SearchPunches data={data} onClose={onClose} />;
          },
        },
      },
      {
        name: 'Un-Finalize',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          if (
            attendance.finalized &&
            !attendance.details?.summary?.overtime &&
            !attendance.details?.summary?.leaves
          ) {
            return true;
          }

          const reason = [
            'worked-week-off-salary',
            'worked-week-off-comp-off',
            'ot-to-salary',
            'ot-to-comp-off',
          ];

          if (
            attendance.finalized &&
            attendance.details?.summary?.autofinalizeReason &&
            reason.includes(attendance.details?.summary?.autofinalizeReason)
          ) {
            return true;
          }

          if (
            attendance.finalized &&
            attendance.details?.summary?.autofinalizeReason ===
              'leaves-approved'
          ) {
            return true;
          }

          if (
            attendance.finalized &&
            [
              'present-half-day',
              'leaves-approved',
              'weekoff-leaves-approved',
            ].includes(attendance.details?.summary?.autofinalizeReason || '')
          ) {
            return true;
          }

          return false;
        },
        auth: 'UI:BTN-ATTEN-UNFINALIZE:VIEW',
        behaviour: 'modal',
        modal: {
          title: 'Un-Finalize',
          content: ({ onClose, data }) => {
            const UnFinalize = renderUnFinalize({
              setAttendance,
              setOvertime,
              getLeaves: getLeavesSync,
              setLeave,
              getData: async () => {
                toast.success('Record Un-finalized!');
                await sleep(1000);
                fetchData();
              },
            });

            return <UnFinalize onClose={onClose} data={data} />;
          },
        },
      },
      {
        name: 'Substitute Leave',
        show: ({ attendance }: { attendance: CombinedAttDataType }) => {
          if (!attendance) {
            return false;
          }
          if (
            attendance.finalized &&
            attendance.details?.summary?.leaves &&
            attendance.details?.summary?.leaves.find(
              (x) => x.type === 'unpaid-leave'
            )
          ) {
            return true;
          }

          return false;
        },
        auth: 'UI:BTN-LEAVE-SUBS:VIEW',
        behaviour: 'confirm',
        onConfirm: ({ attendance }) => {
          return {
            title: `Are you sure you want to substitute the unpaid leave with  unconsumed Comp-off for ${attendance.employee.name} on ${attendance.date} ?`,
            message: 'Reason: Replacing unpaid leave with earned Comp-off',
            onConfirm: async () => {
              await attendanceService
                .substituteLeave({
                  attendanceId: attendance.id,
                  substituteBy: 'compensatory-off',
                  reason: 'Replacing unpaid leave with earned Comp-off',
                })
                .then(async (response) => {
                  if (response.status) {
                    toast.success('Leave Substituted Successfully');
                    await sleep(1000);
                    fetchData();
                  } else {
                    toast.error(
                      response.reason || 'Failed to substitute Leave'
                    );
                    await sleep(1000);
                    fetchData();
                  }
                });
            },
          };
        },
      },
    ];
  };
}
