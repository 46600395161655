/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import {
  SalaryFilterType,
  SalaryType,
} from '@erp_core/erp-types/dist/types/modules/payroll/salary';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { SalaryInterface } from '../../../../../models/interfaces/hrd/salary';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class SalaryHttpRepo
  extends GenericHttpWithLogsRepo<SalaryType, SalaryFilterType>
  implements SalaryInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/salaries',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async calculateSalary({
    employeeId,
    month,
    mode,
    salaryRevisionId,
    companyGroupSetting,
    salaryParamId,
    persist,
    salaryUntil,
  }: {
    employeeId: string;
    month: string;
    mode: 'real' | 'simulate' | 'recalculate' | 'salary-advance';
    persist?: boolean;
    companyGroupSetting: CompanyGroupSetting;
    salaryRevisionId: string;
    salaryParamId: string;
    salaryUntil: string | null;
  }): Promise<{
    salary: any;
  }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const body: any = {
      mode,
      salaryRevisionId,
      companyGroupSetting,
      salaryParamId,
    };
    if (persist) {
      body.persist = true;
    }
    if (salaryUntil) {
      body.salaryUntil = salaryUntil;
    }
    const res = await httpInstance.post(
      `${this.resourceEndpoint}/calculate-salary/employee/${employeeId}/month/${month}`,
      body
    );
    if (res.status === 200) {
      const { data } = res;

      console.log(data);
      return {
        salary: data.salary,
      };
    }

    return {
      salary: {},
    };
  }

  async recalculateSalary({
    employeeId,
    month,
    salaryRevisionId,
    companyGroupSetting,
    salaryParamId,
    persist,
    salaryId,
  }: {
    employeeId: string;
    month: string;
    persist?: boolean;
    companyGroupSetting: CompanyGroupSetting;
    salaryId: string;
    salaryRevisionId: string;
    salaryParamId: string;
  }): Promise<{
    result: any;
  }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const body: any = {
      salaryRevisionId,
      companyGroupSetting,
      salaryParamId,
      salaryId,
    };
    if (persist) {
      body.persist = true;
    }
    const res = await httpInstance.post(
      `${this.resourceEndpoint}/recalculate-salary/employee/${employeeId}/month/${month}`,
      body
    );
    if (res.status === 200) {
      const { data } = res;

      if (data.errors) {
        alert(data.reason);
      }

      return {
        result: data.result,
      };
    }

    return {
      result: {},
    };
  }

  async estimateDeductions({ month }: { month: string }): Promise<Array<any>> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });
    const res = await httpInstance.get(
      `${this.resourceEndpoint}/estimate-deductions/${month}`
    );
    if (res.status === 200) {
      const { data } = res;

      if (data.errors) {
        alert(data.reason);
      }

      return data;
    }

    return [];
  }
}
