import { Account } from '@erp_core/erp-icons/icons/web/account';
import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { UseActionAdmin } from '../../hooks/admin/action-admin/use-action-admin';
import { UseActions } from '../../hooks/admin/action-admin/use-actions-admin';
import { UseAuthorization } from '../../hooks/admin/authorization/use-authorization';
import { UseAuthorizations } from '../../hooks/admin/authorization/use-authorizations';
import { UsePaginatedAuthorizations } from '../../hooks/admin/authorization/use-paginated-authorizations';
import { UseCompanies } from '../../hooks/admin/company-admin/use-companies-admin';
import { UseCompanyAdmin } from '../../hooks/admin/company-admin/use-company-admin';
import { UseCompanyGroupAdmin } from '../../hooks/admin/company-group-admin/use-company-group-admin';
import { UseLocationAdmin } from '../../hooks/admin/location-admin/use-location-admin';
import { UseLocations } from '../../hooks/admin/location-admin/use-locations';
import { UseModuleAdmin } from '../../hooks/admin/module-admin/use-module-admin';
import { UseModules } from '../../hooks/admin/module-admin/use-modules-admin';
import { UseNotificationAction } from '../../hooks/admin/notification-admin/use-authorization';
import { UseNotificationActions } from '../../hooks/admin/notification-admin/use-authorizations';
import { UsePaginatedRoles } from '../../hooks/admin/role-admin/use-paginated-roles-admin';
import { UseRoleAdmin } from '../../hooks/admin/role-admin/use-role-admin';
import { UseRoles } from '../../hooks/admin/role-admin/use-roles-admin';
import { UsePaginatedResourcesAdmin } from '../../hooks/admin/use-resource-admin/use-paginated-resources-admin';
import { UseResourceAdmin } from '../../hooks/admin/use-resource-admin/use-resource-admin';
import { UseResourcesAdmin } from '../../hooks/admin/use-resource-admin/use-resources-admin';
import { UseUserAdmin } from '../../hooks/admin/user-admin/use-user-admin';
import {
  UsePaginatedUsers,
  UseUsers,
} from '../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../hooks/file-transfer/use-public-transfer';
import { UseNotificationConfigurator } from '../../hooks/notification/configurator/use-notification-configurator';
import { UseNotificationConfigurators } from '../../hooks/notification/configurator/use-notification-configurators';
import { renderActionAdmin } from './action-admin';
import { renderAuthorizationAdmin } from './authorization-admin';
import { renderCompanyAdmin } from './company-admin';
import { renderCompanyGroupAdmin } from './company-group-admin';
import { renderLocationAdmin } from './location-admin';
import { renderModuleAdmin } from './module-admin';
import { renderNotificationAction } from './notification-admin';
import { renderNotificationV2Action } from './notification-v2-admin';
import { renderResourceAdmin } from './resource-admin';
import { renderRoleAdmin } from './role-admin';
import { renderUserAdminV2 } from './user-admin-v2';

type RenderAdminMastersProps = {
  useCompanies: UseCompanies;
  useCompanyAdmin: UseCompanyAdmin;
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
  useCompanyGroupAdmin: UseCompanyGroupAdmin;
  useLocations: UseLocations;
  useLocationAdmin: UseLocationAdmin;
  useModules: UseModules;
  useModuleAdmin: UseModuleAdmin;
  useResourcesAdmin: UseResourcesAdmin;
  useResourceAdmin: UseResourceAdmin;
  useAuthorizations: UseAuthorizations;
  useAuthorization: UseAuthorization;
  useActions: UseActions;
  useActionAdmin: UseActionAdmin;
  useGetAllRoles: UseRoles;
  useRoleAdmin: UseRoleAdmin;
  useNotificationActions: UseNotificationActions;
  useUsers: UseUsers;
  usePaginatedUsers: UsePaginatedUsers;
  useUser: UseUserAdmin;
  useRoles: UseRoles;
  useNotificationAction: UseNotificationAction;
  usePaginatedResourcesAdmin: UsePaginatedResourcesAdmin;
  usePaginatedAuthorizations: UsePaginatedAuthorizations;
  usePaginatedRoles: UsePaginatedRoles;
  useNotificationConfigurators: UseNotificationConfigurators;
  useNotificationConfigurator: UseNotificationConfigurator;
};

export function renderAdminMasters({
  useCompanies,
  useCompanyAdmin,
  useFileTransfer,
  usePublicTransfer,
  useCompanyGroupAdmin,
  useLocations,
  useLocationAdmin,
  useModules,
  useModuleAdmin,
  useResourceAdmin,
  useResourcesAdmin,
  useAuthorization,
  useGetAllRoles,
  useAuthorizations,
  useRoleAdmin,
  useActions,
  useActionAdmin,
  useNotificationActions,
  useUsers,
  useUser,
  usePaginatedUsers,
  useRoles,
  useNotificationAction,
  usePaginatedResourcesAdmin,
  usePaginatedAuthorizations,
  usePaginatedRoles,
  useNotificationConfigurators,
  useNotificationConfigurator,
}: RenderAdminMastersProps): () => JSX.Element {
  return function AdminMasters(): JSX.Element {
    const NewTabs = renderTabsV2Component();

    const CompanyGroupAdmin = renderCompanyGroupAdmin({
      useCompanyGroupAdmin,
    });

    const CompanyAdmin = renderCompanyAdmin({
      useCompanies,
      useCompanyAdmin,
      useFileTransfer,
      usePublicTransfer,
    });
    const LocationAdmin = renderLocationAdmin({
      useLocations,
      useLocationAdmin,
      useCompanies,
    });

    const ModuleAdmin = renderModuleAdmin({
      useModules,
      useModuleAdmin,
    });

    const ResourceAdmin = renderResourceAdmin({
      useResourceAdmin,
      useModules,
      useResourcesAdmin,
      usePaginatedResourcesAdmin,
    });

    const AuthorizationAdmin = renderAuthorizationAdmin({
      useAuthorizations,
      useAuthorization,
      useResourcesAdmin,
      useActions,
      useModules,
      usePaginatedAuthorizations,
    });

    const ActionAdmin = renderActionAdmin({
      useActions,
      useActionAdmin,
    });

    const RoleAdmin = renderRoleAdmin({
      useGetAllRoles,
      useLocations,
      useAuthorizations,
      useRoleAdmin,
      useActions,
      useNotificationActions,
      usePaginatedRoles,
    });

    const UserAdminV2 = renderUserAdminV2({
      useUser,
      usePaginatedUsers,
      useRoles,
    });

    const NotificationAction = renderNotificationAction({
      useNotificationAction,
      useNotificationActions,
      useRoles,
    });

    const NotificationActionV2 = renderNotificationV2Action({
      useNotificationConfigurators,
      useNotificationConfigurator,
      useRoles,
      useAuthorizations,
      useUsers,
    });

    const newCategories: DataArray = [
      { name: 'group', Fn: CompanyGroupAdmin },
      { name: 'company', Fn: CompanyAdmin },
      { name: 'location', Fn: () => <LocationAdmin /> },
      { name: 'module', Fn: ModuleAdmin },
      { name: 'resource', Fn: ResourceAdmin },
      { name: 'action', Fn: ActionAdmin },
      { name: 'authorization', Fn: AuthorizationAdmin },
      { name: 'role', Fn: RoleAdmin },
      { name: 'user', Fn: UserAdminV2 },
      { name: 'notification', Fn: NotificationAction },
      { name: 'notification-v2', Fn: NotificationActionV2 },
    ];

    const Card = renderCardComponent();
    const header: CardHeader = {
      icon: (
        <Account className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      title: 'Admin Masters',
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
