/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  SalesOrder,
  SalesOrderItem,
} from '@erp_core/erp-types/dist/modules/order';
import { SalesOrderFilter } from '@erp_core/erp-types/dist/types/modules/order/sales/order';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseUsers } from '../../../hooks/admin/user-admin/use-users-admin';
import { UseItems } from '../../../hooks/inventory/item/use-items';
import { UseClients } from '../../../hooks/order/sales/client/use-clients';
import { UseSalesOrder } from '../../../hooks/order/sales/order/use-order';
import { UseSalesOrders } from '../../../hooks/order/sales/order/use-orders';
import { UsePaginatedSalesOrders } from '../../../hooks/order/sales/order/use-paginated-orders';
import { UserRendererInterface } from '../../common/fragments/user';
import { renderAddSalesOrderForm } from './forms/add-sales-order';
import { renderAllocateSalesOrderForm } from './forms/allocate';

type Props = {
  useItems: UseItems;
  useClients: UseClients;
  useSalesOrders: UseSalesOrders;
  useSalesOrder: UseSalesOrder;
  usePaginatedSalesOrders: UsePaginatedSalesOrders;
  useUsers: UseUsers;
  userRendererService: UserRendererInterface;
};

function renderSalesOrderDetails({
  useItems,
  useClients,
  useSalesOrder,
  useSalesOrders,
  usePaginatedSalesOrders,
  useUsers,
  userRendererService,
}: Props) {
  const Table = renderTableWithMapperComponent<SalesOrder, SalesOrderFilter>();
  const Card = renderCardComponent();

  return function SalesOrderDetails() {
    const { set: SetSalesOrder } = useSalesOrder();

    const { getAll: getAllSalesOrder, data: salesOrders } = useSalesOrders();

    const [, setData] = useState<SalesOrder[]>();

    useEffect(() => {
      getAllSalesOrder();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (salesOrders) {
        setData(salesOrders);
      }
    }, [salesOrders]);

    const handleAddSalesEnquiry = (form: any) => {
      try {
        const finalData: Partial<SalesOrder> = {
          name: form.name,
          client: {
            id: form.client,
          } as any,
          orderItems: form.items.map((i) => ({
            item: {
              id: i.id,
              name: '',
            },
            quantity: i.quantity,
            deliverBy: form.deliverBy,
            details: {
              specifications: {
                grade: i.grade || '',
                color: i.color || '',
                stateOfMatter: i.stateOfMatter,
                packaging: i.packaging,
              },
            },
          })) as SalesOrderItem[],
          details: { remarks: form.remark },
        };
        SetSalesOrder(finalData as SalesOrder);
        toast('Order created');
      } catch (error) {
        toast((error as any).message);
      }
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Name', rowSpan: 2 },
        { name: 'Deliver By', rowSpan: 2 },
        { name: 'Client', rowSpan: 2 },
        { name: 'Items', rowSpan: 2 },
        { name: 'Remarks', rowSpan: 2 },
        { name: 'Attachments', rowSpan: 2 },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),

          rowSpan: 2,
        },
        { name: 'Status', rowSpan: 2 },
      ],
    ];

    const bodyMapper = (item: SalesOrder) => {
      const deliveryDates = item.orderItems.map((oi) => oi.deliverBy);
      const cells: Array<TableCell> = [
        { value: item.name, link: `/sales/sales-order/details/${item.id}` },
        {
          value:
            deliveryDates.length > 1
              ? deliveryDates.reduce((first, second) =>
                  first > second ? first : second
                )
              : deliveryDates[0],
        },
        { value: item.client.name },
        {
          value: (
            <div>
              {item.orderItems?.map((a, i) => (
                <a key={i}>{a.item.name}, </a>
              ))}
            </div>
          ),
        },
        { value: item.details.remarks || '' },
        { value: '' },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={item.createdBy?.id}
              name={item.createdBy?.name}
              extraInfo={moment.utc(item.createdAt).fromNow()}
            />
          ),
        },
        { value: item.status || '' },
      ];
      return {
        cells,
        rowData: {
          salesOrder: item,
        },
      };
    };

    const filter: Filter<SalesOrderFilter> = {
      version: 'v2',
      sortFields: [
        {
          key: 'Name',
          value: 'name',
          defaultOrder: 'asc',
        },
        // {
        //     key: 'Client', // TODO: sort filter not working
        //     value: 'client',
        //     defaultOrder: 'asc',
        // },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'createdBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: SalesOrderFilter) => {
        const filterData: SalesOrderFilter = {};

        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }
        if (filterSelection.createdBy) {
          filterData.createdBy = filterSelection.createdBy;
        }

        return filterData as SalesOrderFilter;
      },
      filterResources: {
        createdBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const cardHeader: CardHeader = {
      title: 'Sales Orders',
      actions: [
        {
          type: 'button',
          button: {
            name: 'add',
            behaviour: 'modal',
            modal: {
              title: 'Add Order',
              content: ({ onClose }) => {
                const Form = renderAddSalesOrderForm({
                  useItems,
                  useClients,
                  onClose,
                });
                return (
                  <Form
                    data={{}}
                    onSave={async (form) => {
                      handleAddSalesEnquiry(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          bodyMapper={bodyMapper}
          useResources={useSalesOrders}
          filter={filter}
          pagination={{
            enabled: true,
            usePaginatedResources: usePaginatedSalesOrders,
          }}
          actions={[
            {
              name: 'Send Fulfillment',
              show: () => true,
              behaviour: 'modal',
              modal: {
                title: 'Send Fulfillment',
                content: ({ data, onClose }) => {
                  const Form = renderAllocateSalesOrderForm();
                  return (
                    <Form
                      onSave={() => {
                        toast('Dispatched successfully');
                        onClose();
                      }}
                    />
                  );
                },
              },
            },
            {
              name: 'Close Order',
              show: () => true,
              behaviour: 'click',
              onClick: async () => {
                toast('Order Closed.');
              },
            },
          ]}
        />
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}

export default renderSalesOrderDetails;
