import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
// import { renderTabsComponent } from '@erp_core/erp-ui-components';
import { DataArray, renderTabsV2Component } from '@erp_core/erp-ui-components';
// import { nanoid } from 'nanoid';
import { UseUsers } from '../../../hooks/admin/user-admin/use-users-admin';
import { UseBatchItemAllocate } from '../../../hooks/inventory/item/batch/use-batch-item-allocate';
import { UseBatchItemGodowns } from '../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UsePhysicalStock } from '../../../hooks/inventory/item/stock/use-physical-stock';
import { UseVirtualMFStock } from '../../../hooks/inventory/item/stock/use-virtual-manufacturing-stock';
import { UseItems } from '../../../hooks/inventory/item/use-items';
import { UseClients } from '../../../hooks/order/sales/client/use-clients';
import { UseSalesOrder } from '../../../hooks/order/sales/order/use-order';
import { UseSalesOrders } from '../../../hooks/order/sales/order/use-orders';
import { UsePaginatedSalesOrders } from '../../../hooks/order/sales/order/use-paginated-orders';
import { UseManufacturingVouchers } from '../../../hooks/order/work-order/mf-voucher/use-manufacturing-vouchers';
import { UseVirtuallyReserveMfVoucher } from '../../../hooks/order/work-order/mf-voucher/use-virtually-reserve-manufacturing-voucher';
import { UserRendererInterface } from '../../common/fragments/user';
import renderSalesItemOrderDetails from '../item-order';
import renderSalesManufacturingOrderDetails from '../manufacturing-order';
import renderSalesOrderDetails from '../sales-order';

type RenderSalesOrderTabsProps = {
  hooks: {
    useItems: UseItems;
    useClients: UseClients;
    useSalesOrder: UseSalesOrder;
    usePaginatedSalesOrders: UsePaginatedSalesOrders;
    useSalesOrders: UseSalesOrders;
    usePhysicalStock: UsePhysicalStock;
    useBatchItemGodowns: UseBatchItemGodowns;
    useBatchGodownItemAllocate: UseBatchItemAllocate;
    useVirtualMfStock: UseVirtualMFStock;
    useManufacturingVouchers: UseManufacturingVouchers;
    useVirtuallyReserveMfVoucher: UseVirtuallyReserveMfVoucher;
    useUsers: UseUsers;
    userRendererService: UserRendererInterface;
  };
};

export function renderSalesOrderTabs({
  hooks: {
    useItems,
    useClients,
    useSalesOrder,
    useSalesOrders,
    usePaginatedSalesOrders,
    usePhysicalStock,
    useBatchGodownItemAllocate,
    useBatchItemGodowns,
    useVirtualMfStock,
    useManufacturingVouchers,
    useVirtuallyReserveMfVoucher,
    useUsers,
    userRendererService,
  },
}: RenderSalesOrderTabsProps): () => JSX.Element {
  return function SalesOrderTabs(): JSX.Element {
    // const Tabs = renderTabsComponent();
    const NewTabs = renderTabsV2Component();
    const Orders = renderSalesOrderDetails({
      useItems,
      useClients,
      useSalesOrder,
      useSalesOrders,
      usePaginatedSalesOrders,
      useUsers,
      userRendererService,
    });
    const Allocate = renderSalesItemOrderDetails({
      useSalesOrders,
      usePhysicalStock,
      useBatchGodownItemAllocate,
      useBatchItemGodowns,
      useVirtualMfStock,
      useManufacturingVouchers,
      useVirtuallyReserveMfVoucher,
    });

    const Manufacture = renderSalesManufacturingOrderDetails();

    // const categories: Map<string, () => JSX.Element> = new Map<
    //   string,
    //   () => JSX.Element
    // >([
    //   ['Orders', Orders],
    //   ['Allocate', Allocate],
    //   ['Manufacture', Manufacture],
    // ]);

    const newCategories: DataArray = [
      { name: 'orders', Fn: Orders },
      { name: 'allocate', Fn: Allocate },
      { name: 'manufacture', Fn: Manufacture },
    ];

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Sales Orders',
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          {/* <Tabs
            data={categories}
            keyTab={nanoid()}
            defaultTabIndex={0}
            components={{ Tab }}
          /> */}
          <NewTabs data={newCategories} manipulateLocation={true} />
        </>
      ),
    };

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
