type Props = {
  pattern: string;
  parameters: {
    termsOfDelivery: string;
    transportersCostFactor:
      | 'per-kg-per-km'
      | 'lump-sum'
      | 'per-kilo-cube-per-km'
      | 'state-vise-cost';
    rate: number;
    quantity: number;
    distanceInKm?: number;
    insuranceCost?: number;
    perUnitWeight?: number;
    perUnitVolume?: number;
    lumpSumAmount?: number;
  };
};

type CalculateTransportationCost = (p: Props) => number;

function EXWTransport({ parameters }: Props) {
  const {
    transportersCostFactor,
    quantity,
    insuranceCost = 0,
    distanceInKm = 0,
    perUnitVolume = 0,
    perUnitWeight = 0,
    lumpSumAmount = 0,
  } = parameters;

  if (transportersCostFactor === 'lump-sum') {
    return (lumpSumAmount + insuranceCost) / quantity;
  }

  if (transportersCostFactor === 'per-kg-per-km') {
    return perUnitWeight * distanceInKm;
  }

  if (transportersCostFactor === 'per-kilo-cube-per-km') {
    return perUnitVolume * distanceInKm;
  }

  if (transportersCostFactor === 'state-vise-cost') {
    return 0; // TODO:
  }
  return 0;
}

export const calculateTransportationCost: CalculateTransportationCost = ({
  pattern,
  parameters,
}) => {
  const { termsOfDelivery } = parameters;

  switch (termsOfDelivery) {
    case 'CIF':
      return 0;

    case 'EXW': {
      // When TOD is EXW all transportation cost is in byers account
      return EXWTransport({ parameters, pattern });
    }

    case 'DPU': {
      // TODO
      return 0;
    }

    case 'CIP': {
      // TODO
      return 0;
    }

    case 'FCA': {
      // TODO
      return 0;
    }

    case 'DAP': {
      // TODO
      return 0;
    }

    case 'DDP': {
      // TODO
      return 0;
    }

    case 'CPT': {
      // TODO
      return 0;
    }

    case 'CFR': {
      // TODO
      return 0;
    }

    case 'FOB': {
      // TODO
      return 0;
    }

    case 'FAS': {
      // TODO
      return 0;
    }
  }

  if (termsOfDelivery === 'CIF') {
    // When TOD is CIF all transportation cost is in sellers account
    // Thats why returning 0
    return 0;
  }

  return 0;
};
