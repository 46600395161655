/* eslint-disable no-unused-vars */
import { OnboardEmployeeType } from '@erp_core/erp-types/dist/modules/admin';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../contexts/current';
import { UseCompanies } from '../../../../hooks/admin/company-admin/use-companies-admin';
import { UseEmployeeCategories } from '../../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseLocations } from '../../../../hooks/admin/location-admin/use-locations';
import { UseOnboardEmployee } from '../../../../hooks/admin/onboard-employee/use-onboard-employee';
import { UseOnboardEmployees } from '../../../../hooks/admin/onboard-employee/use-onboard-employees';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseDepartments } from '../../../../hooks/hrd/department/use-departments';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { renderAddEmployeeOnboardForm } from '../form/add-employee-onboard';

export type renderEmployeeOnboardProps = {
  useOnboardEmployees: UseOnboardEmployees;
  useUserAuthorization: UseUserAuthorization;
  useOnboardEmployee: UseOnboardEmployee;
  useDepartments: UseDepartments;
  useLocations: UseLocations;
  useEmployeeCategories: UseEmployeeCategories;
  useEmployeeProfiles: UseEmployeeProfiles;
  useCompanies: UseCompanies;
};

export function renderEmployeeOnboardsRequest({
  useOnboardEmployees,
  useOnboardEmployee,
  useUserAuthorization,
  useDepartments,
  useLocations,
  useEmployeeCategories,
  useEmployeeProfiles,
  useCompanies,
}: renderEmployeeOnboardProps): () => JSX.Element {
  return function EmployeeOnboards(): JSX.Element {
    // eslint-disable-next-line
    const {
      data: employees,
      getAll: getAllOnboardEmployees,
    } = useOnboardEmployees();
    const { get: getAllowedActions } = useUserAuthorization();
    const { syncSet: setOnboardEmployee } = useOnboardEmployee();
    const { data: departments, getAll: getDepartments } = useDepartments();
    const { company: currentCompany } = useContext(CurrentContext);
    const { data: locations, getAll: getLocations } = useLocations();

    useEffect(() => {
      getAllOnboardEmployees();
      getDepartments();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (currentCompany.id) {
        getLocations({
          getAll: true,
          companyId: currentCompany.id,
          type: 'real',
        });
      }
      // eslint-disable-next-line
    }, [currentCompany]);

    const Form = renderAddEmployeeOnboardForm({
      newEmpId: '',
      locations: locations || [],
      useEmployeeCategories,
      useEmployeeProfiles,
      useCompanies,
    });

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'Edit',
          show: ({
            onboardEmployee,
          }: {
            onboardEmployee: OnboardEmployeeType;
          }) => {
            // we want to give ability to edit only when the gatepass
            // is in pending state.
            if (['pending'].includes(onboardEmployee.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Edit',
            content: ({
              data: { onboardEmployee },
              onClose,
            }: {
              data: {
                onboardEmployee: OnboardEmployeeType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <Form
                    departments={departments || []}
                    currentCompany={currentCompany}
                    onSave={async (e) => {
                      await editOnboardEmployee(e, onboardEmployee.id);
                      onClose();
                    }}
                    data={onboardEmployee as any}
                    isEdit={true}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Reject request',
          show: ({
            onboardEmployee,
          }: {
            onboardEmployee: OnboardEmployeeType;
          }) => {
            // we want to give ability to cancel only when the gatepass
            // is in pending state.
            if (['pending'].includes(onboardEmployee.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ onboardEmployee }) => {
            return {
              title: `Are you sure you want to reject onboard employee{' '}
                ${
                  employees?.find((p) => p.id === onboardEmployee.id)
                    ?.employeeFirstName
                }{' '}
                ${
                  employees?.find((p) => p.id === onboardEmployee.id)
                    ?.employeeLastName
                }`,
              onConfirm: async () => {
                await rejectOnboardEmployee(onboardEmployee.id);
              },
            };
          },
          // style: 'flex flex-col',
        },
        {
          name: 'Approve request',
          show: ({
            onboardEmployee,
          }: {
            onboardEmployee: OnboardEmployeeType;
          }) => {
            // we want to give ability to cancel only when the gatepass
            // is in pending state.
            if (['pending'].includes(onboardEmployee.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ onboardEmployee }) => {
            return {
              title: `Are you sure you want to approve onboard employee{' '}
                ${
                  employees?.find((p) => p.id === onboardEmployee.id)
                    ?.employeeFirstName
                }{' '}
                ${
                  employees?.find((p) => p.id === onboardEmployee.id)
                    ?.employeeLastName
                }`,
              onConfirm: async () => {
                await approveOnboardEmployee(onboardEmployee.id);
              },
            };
          },
          // style: 'flex flex-col',
        },
      ];
    }

    const editOnboardEmployee = async (form: any, id: string) => {
      const finalData = {
        id,
        ...form,
      };
      await setOnboardEmployee(finalData);
      toast('Onboard employee edited sucessfully');
      getAllOnboardEmployees();
    };

    const rejectOnboardEmployee = async (id: string) => {
      const finalData = {
        id,
        status: 'rejected',
      };
      await setOnboardEmployee(finalData as any);
      toast('Rejected sucessfully');
      getAllOnboardEmployees();
    };

    const approveOnboardEmployee = async (id: string) => {
      const finalData = {
        id,
        status: 'approved',
      };
      await setOnboardEmployee(finalData as any);
      toast('Approved sucessfully');
      getAllOnboardEmployees();
    };

    const OnboardEmployeeTable = renderTableComponent();
    const OnboardEmployeeTableHeader: TableHeader = [
      [
        { name: 'Employee ID' },
        { name: 'First Name' },
        { name: 'Last Name' },
        { name: 'Joining Date' },
        { name: 'Company' },
        { name: 'Department' },
        { name: 'Designation' },
        { name: 'Location' },
        { name: 'Status' },
        { name: 'Created At' },
      ],
    ];

    const tableData =
      employees?.map((r, idx) => ({
        rowData: {
          onboardEmployee: r,
        },
        cells: [
          { value: r.employeeId },
          { value: r.employeeFirstName },
          { value: r.employeeLastName },
          { value: moment(r.joiningDate).format('DD-MMM-YYYY') },
          { value: r.onboardingCompany?.name },
          { value: r.details.department?.name || '' },
          { value: r.details.designation || '' },
          { value: r.details.location?.name || '' },
          { value: r.status },
          { value: moment(r.createdAt).format('DD-MMM-YYYY') },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Onboarding Requests',
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <OnboardEmployeeTable
          header={OnboardEmployeeTableHeader}
          body={tableBody}
          actions={addActions()}
          auth={{ actions: getAllowedActions().actions }}
        />
      ),
    };

    return (
      <>
        <Card header={header} body={body} />
      </>
    );
  };
}
