import { Revision } from '@erp_core/erp-types/dist/modules/inventory';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseGsts } from '../../../../../../../hooks/admin/constants/gst/use-gsts';
import { UseMetrics } from '../../../../../../../hooks/admin/constants/metrics/use-metrics';

type EditPropertyValueProps = {
  currentValue: IdName;
  onSave: (data: Partial<Revision>) => Promise<void>;
  useResources: string;
};

export type EditPropertyIdNameValFormType = {
  currentValue: IdName;
  newValue: IdName;
  reason: string;
};

export const renderEditSearchableSelectPropertyValue = ({
  useMetrics,
  useGsts,
}: {
  useMetrics: UseMetrics;
  useGsts: UseGsts;
}) => {
  return function EditSearchableSelectPropertyValue({
    currentValue,
    onSave,
    useResources,
  }: EditPropertyValueProps) {
    const useSearchResource = (useResources: string) => {
      switch (useResources) {
        case 'useMetrics':
          return useMetrics;
        case 'useGST':
          return useGsts;
        default:
          return null;
      }
    };

    const searchResource = useSearchResource(useResources);
    if (!searchResource) {
      return <></>;
    }

    const formProps: FormProps<EditPropertyIdNameValFormType> = {
      fieldsData: [
        {
          property: 'currentValue',
          type: 'input',
          label: 'Current Value',
          readonly: true,
        },
        {
          property: 'newValue',
          type: 'searchable-select',
          required: true,
          label: 'New Value',
          searchOptions: {
            useSearch: searchResource,
            onSearchValueSelect(u) {},
          },
        },
      ],
      initialFormState: {
        currentValue: currentValue.name,
        newValue: { id: '', name: '' },
        reason: '',
      },
      onSubmit: async (f) => {
        await onSave({
          reason: f.reason,
          changeRequest: {
            currentValue: {
              value: {
                data: f.currentValue,
              },
            },
            newValue: {
              value: {
                data: f.newValue,
              },
            },
          },
        } as Revision);
      },
      mapTToU(p) {
        return (p as unknown) as EditPropertyIdNameValFormType;
      },
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EditPropertyIdNameValFormType>(formProps);

    return <Form />;
  };
};
