import { renderHistory } from '@erp_core/erp-ui-components';
import { UseGodown } from '../../../../../hooks/inventory/godown-inventory/use-godown';
import { GodownInterface } from '../../../../../models/interfaces/inventory/generic/godown';

export function renderHistoryTab({
  id,
  godownService,
  useGodown,
}: {
  id: string;
  godownService: GodownInterface;
  useGodown: UseGodown;
}): () => JSX.Element {
  const History = renderHistory();

  return function HistoryTab(): JSX.Element {
    return <History useResource={useGodown} id={id} />;
  };
}
