import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { useState } from 'react';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseGodowns } from '../../../../hooks/inventory/godown-inventory/use-godowns';
import { UseGrades } from '../../../../hooks/inventory/grade/use-grades';
import { UseBomProjects } from '../../../../hooks/order/work-order/bom-project/use-bom-projects';
import { UseBom } from '../../../../hooks/order/work-order/bom/use-bom';
import { BomFlowInterface } from '../../../../models/interfaces/inventory/bom-flow';
import { GradeInterface } from '../../../../models/interfaces/inventory/grade';
import { renderStep1 } from './step-1';
import { renderStep2 } from './step-2';
import { renderStep3 } from './step-3';

export function renderBOMFormV2({
  useGrades,
  useUsers,
  useGodowns,
  useBomProjects,
  bomFlowService,
  useBom,
  onClose,
  currentUser,
  gradeService,
}: {
  useGrades: UseGrades;
  useUsers: UseUsers;
  useGodowns: UseGodowns;
  useBomProjects: UseBomProjects;
  bomFlowService: BomFlowInterface;
  useBom: UseBom;
  onClose: () => void;
  currentUser: CurrentUserType;
  gradeService: GradeInterface;
}): () => JSX.Element {
  return function BOMFormV2(): JSX.Element {
    const { syncSet: setBomSync } = useBom();
    const [form1, setForm1] = useState<any>({});
    const [form2, setForm2] = useState<any>({});
    const Step1 = renderStep1({
      useGrades,
      useUsers,
      useGodowns,
      useBomProjects,
      currentUser,
      gradeService,
    });
    const Step2 = renderStep2({ bomFlowService });
    const Step3 = renderStep3({ onClose });
    // () => <div>Step 3 {form2.selectedItems.length}</div>  ///renderStep3({  });

    if (!form1.grade) {
      return (
        <Step1
          onSave={async (form) => {
            setForm1(form);
          }}
        />
      );
    }

    if (!form2.selectedItems) {
      return (
        <Step2
          form={form1}
          onSave={(list) => {
            setForm2({ selectedItems: list });
          }}
        />
      );
    }

    return <Step3 form1={form1} form2={form2} setBomSync={setBomSync} />;
  };
}
