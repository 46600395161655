import { SubGroup } from '@erp_core/erp-icons/icons/web/subgroup';
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import {
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts/user';
import { UseSalaryParam } from '../../../hooks/hrd/salary-parameter/use-salary-parameter';
import { UseSalaryParams } from '../../../hooks/hrd/salary-parameter/use-salary-params';
import { renderAddSalaryParam } from './form/add-salary-param';

export type CreateSalaryParametersPageProps = {
  useSalaryParams: UseSalaryParams;
  useSalaryParam: UseSalaryParam;
};

export function createSalaryParametersPage({
  useSalaryParams,
  useSalaryParam,
}: CreateSalaryParametersPageProps): () => JSX.Element {
  let Card = renderCardComponent();

  return function SalaryParametersPage(): JSX.Element {
    const Table = renderTableComponent(); // SalaryParamType, SalaryParamFilterType>();
    const { syncSet: setSalaryParamSync } = useSalaryParam();
    const {
      data: salaryParamsList,
      getAll: getAllSalaryParams,
    } = useSalaryParams();
    const { user: currentUser } = useContext(UserContext);

    const [selectedParam, setSelectedParam] = useState<SalaryParamType>(
      {} as SalaryParamType
    );

    const tableBody: TableBody =
      salaryParamsList?.map((r) => ({
        rowData: {
          salaryParam: r,
        },
        cells: [
          {
            value: r.name,
            link: `/hrd/payroll/salary-parameters/${r.id}?revision=1`,
          },
          { value: r.revisionNum },
          { value: r.date },
          { value: r.status },
        ],
      })) || [];

    const header: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Revision' },
        { name: 'Effective From Date' },
        { name: 'Status' },
      ],
    ];

    useEffect(() => {
      getAllSalaryParams();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (salaryParamsList?.length) {
        const maxPublished = _.maxBy(
          salaryParamsList,
          (param: SalaryParamType) => {
            return param.revisionNum;
          }
        );

        if (maxPublished && maxPublished?.id !== selectedParam.id) {
          setSelectedParam(maxPublished);
        }
      }
      // eslint-disable-next-line
    }, [salaryParamsList]);

    return (
      <div className='pb-52'>
        <Card
          header={{
            title: 'Salary Parameters',
            icon: (
              <SubGroup className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
            ),
            subheading: <div className='flex items-center'></div>,
            actions: [
              {
                type: 'button',
                button: {
                  name: 'Add',
                  behaviour: 'modal',
                  modal: {
                    title: '',
                    content: ({ onClose }) => {
                      const AddForm = renderAddSalaryParam({
                        onSave: async (data) => {
                          const reqData = {
                            ...data,
                            revisionNum:
                              Number(selectedParam.revisionNum || 0) + 1,
                            details: selectedParam.details || {},
                            approvedBy: {
                              id: currentUser.id,
                              name: currentUser.name,
                            },
                          };
                          await setSalaryParamSync(reqData as any);
                          onClose();
                        },
                      });

                      return (
                        <AddForm
                          revisionNum={Number(selectedParam.revisionNum) + 1}
                        />
                      );
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <Table
                  header={header}
                  body={tableBody}
                  actions={[
                    {
                      name: 'View Previous Revisions',
                      show: ({
                        salaryParam,
                      }: {
                        salaryParam: SalaryParamType;
                      }) => salaryParam.revisionNum > 1,
                      behaviour: 'modal',
                      modal: {
                        title: 'View Previous Revisions',
                        content: ({
                          data: { salaryParam },
                          onClose,
                        }: {
                          data: { salaryParam: SalaryParamType };
                          onClose: () => void;
                        }) => {
                          return (
                            <div>
                              {_.times(salaryParam.revisionNum, Number).map(
                                (x) => (
                                  <div key={x}>
                                    <Link
                                      title={`Revision ${x + 1}`}
                                      to={`/hrd/payroll/salary-parameters/${
                                        salaryParam.id
                                      }?readOnly=true&revision=${x + 1}`}
                                      target='_blank'
                                    >
                                      Revision {x + 1}
                                    </Link>
                                  </div>
                                )
                              )}
                            </div>
                          );
                        },
                      },
                    },
                  ]}
                />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
