import { renderHistory } from '@erp_core/erp-ui-components';
import { UseSalesEnquiry } from '../../../../../hooks/order/sales/enquiry/use-enquiry';

export function renderHistoryTab({
  id,
  useSalesEnquiry,
}: {
  id: string;
  useSalesEnquiry: UseSalesEnquiry;
}): () => JSX.Element {
  return function HistoryTab(): JSX.Element {
    const History = renderHistory();

    return <History useResource={useSalesEnquiry} id={id} />;
  };
}
