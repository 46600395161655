import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { UseEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseEmployeeProfiles } from '../../../hooks/hrd/employee/profile/use-employee-profiles';
import { renderExitDateAndReason } from './exitdate-reason-tab/exitdate-reason-tab';
import { renderOffBoardingActions } from './off-boarding-actions';

type RenderEmployeeExitProps = {
  hooks: {
    useEmployeeProfiles: UseEmployeeProfiles;
    useEmployeeProfile: UseEmployeeProfile;
  };
};

export function renderEmployeeExit({
  hooks: { useEmployeeProfiles, useEmployeeProfile },
}: RenderEmployeeExitProps): () => JSX.Element {
  return function EmployeeExit(): JSX.Element {
    const NewTabs = renderTabsV2Component();

    const ExitDateAndReason = renderExitDateAndReason({
      useEmployeeProfiles,
      useEmployeeProfile,
    });

    const OffBoardingActions = renderOffBoardingActions({
      useEmployees: useEmployeeProfiles,
    });

    const newCategories: DataArray = [
      { name: 'exit-date-and-reason', Fn: ExitDateAndReason },
      { name: 'off-boarding-actions', Fn: OffBoardingActions },
    ];

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Employee Exit',
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
