import {
  Daybook,
  DaybookFilterType,
} from '@erp_core/erp-types/dist/modules/accounts';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import { UseDaybooks } from '../../../hooks/accounts/use-daybooks';
import { UsePaginatedDaybook } from '../../../hooks/accounts/use-paginated-daybook';
import { UseUsers } from '../../../hooks/admin/user-admin/use-users-admin';

export type RenderDaybookProps = {
  useDaybooks: UseDaybooks;
  usePaginatedDaybook: UsePaginatedDaybook;
  useUsers: UseUsers;
};

export function renderDaybook({
  useDaybooks,
  usePaginatedDaybook,
  useUsers,
}: RenderDaybookProps): () => JSX.Element {
  return function Daybook(): JSX.Element {
    const { data, getAll } = useDaybooks();

    useEffect(() => {
      getAll();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Table = renderTableWithMapperComponent<Daybook, DaybookFilterType>();
    const tableHeader: TableHeader = [
      [
        { name: 'Date', rowSpan: 2 },
        { name: 'Statements', rowSpan: 2 },
        { name: 'Party', rowSpan: 2 },
        { name: 'Credit', rowSpan: 2 },
        { name: 'Debit', rowSpan: 2 },
      ],
    ];

    const bodyMapper = (e: Daybook) => {
      const transactionType = amountFlow(e);
      const cells: Array<TableCell> = [
        { value: moment(e.transactionDate).format('YYYY-MM-DD') },
        { value: createStatement({ data: e }) },
        { value: e.partyId },
        { value: `${transactionType === 'Cr' ? `${e.amount} Cr` : ''}` },
        { value: `${transactionType === 'Dr' ? `${e.amount} Dr` : ''}` },
      ];
      return {
        cells,
      };
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Daybook',
      actions: [],
    };

    const today = moment().format().split('T')[0];
    let from = today;
    data?.forEach((i) => {
      if (from < i.transactionDate) {
        return;
      } else from = i.transactionDate;
    });
    const lastTrasncDate = moment(from).format().split('T')[0];

    const filter: Filter<DaybookFilterType> = {
      version: 'v2',
      sortFields: [
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'from',
          value: '',
          type: 'date',
        },
        {
          key: 'to',
          value: '',
          type: 'date',
        },
        {
          key: 'lastModifiedBy',
          value: '',
          type: 'search-select',
        },
      ],
      filterMapper: (filterSelection: DaybookFilterType) => {
        const filterData: DaybookFilterType = {};

        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }
        if (filterSelection.lastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.lastModifiedBy;
        }
        if (filterSelection.from !== 'all' && filterSelection.from !== '') {
          filterData.from = `more-than::${filterSelection.from}`;
        }
        if (filterSelection.to !== 'all' && filterSelection.to !== '') {
          filterData.to = `less-than::${filterSelection.to}`;
        }

        return filterData as DaybookFilterType;
      },
      filterResources: {
        lastModifiedBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
        from: {
          dateAttribute: {
            maxDate: '',
            minDate: lastTrasncDate,
          },
        },
        to: {
          dateAttribute: {
            maxDate: today,
            minDate: lastTrasncDate,
          },
        },
      },
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          bodyMapper={bodyMapper}
          useResources={useDaybooks}
          filter={filter}
          pagination={{
            enabled: true,
            usePaginatedResources: usePaginatedDaybook,
          }}
        />
      ),
    };

    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
}

export function createStatement({ data }: { data: Daybook }): JSX.Element {
  return (
    <>
      {data.name} completed transaction of&nbsp;{data.operations} for&nbsp;
      <a
        href='/accounts'
        target='_blank'
        rel='noreferrer'
        className='text-blue-600'
      >
        {data.referenceId}
      </a>
    </>
  );
}

export const amountFlow = (e: Daybook): 'Cr' | 'Dr' => {
  if (e.type === 'credit') {
    return 'Cr';
  } else return 'Dr';
};
