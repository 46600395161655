import {
  ItemDetails,
  ItemRes,
} from '@erp_core/erp-types/dist/modules/inventory';
import {
  LoadingButton,
  Node,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { StarIcon, TrashIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { CurrentContext } from '../../../../../../contexts/current';
import { UseItem } from '../../../../../../hooks/inventory/item/use-item';
import { UseItems } from '../../../../../../hooks/inventory/item/use-items';
import { getGrade } from '../../../../../../utils/purchase/getGrade';
import { renderAddNewRawItemForm } from './forms/addNewItemForm';

const RmHeader: TableHeader = [
  [
    { name: 'Raw Material' },
    { name: 'Quantity' },
    { name: 'Grade' },
    { name: 'Cost Contribution %' },
    { name: 'Direct/Indirect' },
    { name: 'Action' },
  ],
];
export function renderRawMaterialsLists({
  id,
  useItems,
  useItem,
}: {
  id: string;
  useItems: UseItems;
  useItem: UseItem;
}): ({ nodeGraphData }: { nodeGraphData: Node }) => JSX.Element {
  return function RawMaterialsList({
    nodeGraphData,
  }: {
    nodeGraphData: Node;
  }): JSX.Element {
    const [quantity, setQuantity] = useState<number>(1000);
    const [itemData, setItemData] = useState<ItemRes | null>(null);
    const [data, setData] = useState<TableBody>([]);
    // const [cgs, setCgs] = useState<CompanyGroupSetting>();
    const { cgSetting } = useContext(CurrentContext);
    const { syncSet: setItemSync, getSync: getItem } = useItem();
    // const {
    //   getAll: getAllCompanyGroupSetting,
    //   data: companyGroupSettings,
    // } = useCompanyGroupSettings();

    // useEffect(() => {
    //   getAllCompanyGroupSetting();
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // useEffect(() => {
    //   if (companyGroupSettings && companyGroupSettings.length > 0) {
    //     setCgs(companyGroupSettings[0]);
    //   }
    // }, [companyGroupSettings]);

    const NumberInput = numberInput(itemData);
    const Card = renderCardComponent();
    const Stocks = renderTableComponent();

    const item = async () => (await getItem(id)) || null;

    const onDelete = async (deleteId: string) => {
      const details: ItemDetails = {
        rawMaterials: (await item())?.details?.rawMaterials || null,
      } as ItemDetails;

      const newData: Partial<ItemRes> = {
        id,
        details,
      };
      if (newData?.details?.rawMaterials) {
        newData.details.rawMaterials.items = _.remove(
          newData.details.rawMaterials.items,
          (i: any) => i.id !== deleteId
        );
        await setItemSync(newData as ItemRes);
        setItem();
      }
    };

    const setItem = async () => {
      const res = await getItem(id);
      setItemData(res);
      if (res.details.rawMaterials) {
        const d = await mapRawMaterialToTableData({
          data: res.details.rawMaterials,
          getItemSync: getItem,
          onDelete,
          quantity,
        });
        setData(d);
      }
    };

    const addRawMaterials = async (form) => {
      // if (Number(form.ratio) < 0 || Number(form.ratio) > 100) {
      //   toast('Ratio must be between 0 - 100');
      //   return
      // }

      // if (Number(form.costContributionPerc) < 0 || Number(form.costContributionPerc) > 100) {
      //   toast('Provide valid costContribution 0 - 100');
      //   return
      // }

      const init = (await item()).details?.rawMaterials?.items || [];

      // Get Grade of Item based on const contribution
      const rmItemRes = await getItem(form.itemId);
      const currentGrade: string = rmItemRes.details?.profile?.grade || '';

      const calculatedGrade = getGrade({
        gradeData: cgSetting?.details.purchase?.rmGrades || [],
        parameter: 'cost',
        value: Number(form.costContributionPerc),
      });

      let finalGrade: string = currentGrade;
      if (calculatedGrade) {
        const f = (cgSetting?.details.purchase?.rmGrades || []).find((g) => {
          return (
            g.gradeName === calculatedGrade || g.gradeName === currentGrade
          );
        });
        if (f) {
          finalGrade = f.gradeName;
        }
      }

      // Updating bom rm info
      const data: Partial<ItemRes> = {
        id,
        details: {
          rawMaterials: {
            processId: form.processId,
            items: _.unionBy(
              [
                ...init,
                {
                  id: form.itemId,
                  ratio: Number(form.ratio / 100),
                  reason: form.reason,
                  costContributionPerc: form.costContributionPerc,
                  grade: calculatedGrade || finalGrade,
                },
              ],
              (a) => a.id
            ),
          },
        } as ItemDetails,
      };
      await setItemSync(data as ItemRes);

      // Updating RM grade if its changed
      if (finalGrade !== currentGrade) {
        setItemSync({
          id: form.itemId,
          details: {
            ...rmItemRes.details,
            profile: {
              ...rmItemRes.details.profile,
              grade: finalGrade,
            },
          },
        } as ItemRes);
      }

      // Closing Modal and refreshing page
      setItem();
    };

    useEffect(() => {
      setItem();
      // eslint-disable-next-line
    }, [quantity]);
    const AddNewRaw = renderAddNewRawItemForm({
      createUseItems: useItems,
      onSave: addRawMaterials,
    });
    return (
      <>
        <Card
          header={{
            title: 'Raw Material List',
            actions: [
              {
                type: 'jsx',
                jsx: <NumberInput value={quantity} setValue={setQuantity} />,
              },
              {
                type: 'button',
                button: {
                  behaviour: 'modal',
                  modal: {
                    title: 'Add New Item',
                    content: ({ onClose }) => {
                      return <AddNewRaw />;
                    },
                  },
                  name: 'Add new item',
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: <Stocks header={RmHeader} body={data} />,
          }}
        />
      </>
    );
  };
}

// eslint-disable-next-line no-undef
type NumberInput = {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
};

const numberInput = (itemData) =>
  function ({ value, setValue }: NumberInput) {
    return (
      <div className='flex items-center space-x-1 justify-center'>
        <div className='font-bold'>
          Quantity: {itemData?.details?.profile?.userUom?.name || ''}
        </div>
        <form
          onSubmit={(v) => {
            v.preventDefault();
            setValue(Number(v.target[0].value));
          }}
        >
          <input
            type='number'
            className='p-2 border border-zinc-500'
            defaultValue={value}
            placeholder='Change ratio'
          />
        </form>
      </div>
    );
  };

type RawMaterials = {
  processId: string;
  items: {
    id: string;
    ratio: number;
    reason: string;
    costContributionPerc: number;
  }[];
};

type MapRawMaterialToTable = {
  data: RawMaterials;
  // itemService: ItemInterface,
  getItemSync: (id: string) => Promise<ItemRes>;
  onDelete: Function;
  quantity: number;
};

const mapRawMaterialToTableData = async ({
  data,
  getItemSync,
  onDelete,
  quantity,
}: MapRawMaterialToTable): Promise<TableBody> => {
  const res: TableBody = [];
  if (data.items) {
    for (let i = 0; i < data.items.length; i++) {
      const item = await getItemSync(data.items[i].id);
      if (item) {
        res.push({
          cells: [
            {
              value: (
                <div className='flex space-x-2'>
                  {item.details.profile.grade === 'A' ? (
                    <StarIcon className='w-5 text-yellow-800 fill-yellow-300 h-5' />
                  ) : (
                    <div className='w-5' />
                  )}{' '}
                  {item.name}{' '}
                </div>
              ),
              link: `/inventory/masters/items/${item.id}/properties`,
            },
            {
              value: `${Number(data.items[i].ratio * quantity).toFixed(2)} ${
                item.details?.profile?.userUom?.name || 'Units'
              }`,
            },
            { value: (data.items[i] as any).grade },
            { value: data.items[i].costContributionPerc },
            { value: 'Direct' },
            {
              value: (
                <LoadingButton
                  defaultStyle='bg-transparent text-red-600'
                  behaviorFn={async () => onDelete(data.items[i].id)}
                  text={<TrashIcon className='w-5 h-5 hover:text-red-500' />}
                />
              ),
            },
          ],
        });
      }
    }
  }
  return res;
};
