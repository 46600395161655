import { LogRecordFilterType } from '@erp_core/erp-types/dist/modules/planning';
import { LogRecordsType } from '@erp_core/erp-types/dist/types/modules/planning/attendance/log-records';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { LogRecordInterface } from '../../../../../models/interfaces/planning/log-record';
import { GenericHttpRepo } from '../../generic-resource';

export class HttpLogRecordRepo
  extends GenericHttpRepo<LogRecordsType, LogRecordFilterType>
  implements LogRecordInterface {
  apiUrl: string;
  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/attendance/shift-schedule/logs',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async searchPunches({
    employeeId,
    date,
  }: {
    employeeId: string;
    date: string;
  }): Promise<{
    sucess: boolean;
    message?: string;
    finalPunches: any[];
  }> {
    const instance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.apiUrl,
    });

    const response = await instance.get(
      `/api/v1/attendance/punches/${employeeId}/date/${date}`
    );

    return response.data as any;
  }
}
