import {
  BomDependency,
  BomDependencyFilter,
  BomFlow,
  BomFlowFilter,
  BomProjectFilterType,
  BomProjectType,
} from '@erp_core/erp-types/dist/modules/inventory';
import {
  BOMRes,
  Client,
  ClientFilterType,
  ClientItem,
  ClientItemFilterType,
  EnquiryQuotes,
  LeadCustomerEnquiryFilterType,
  LeadCustomerEnquiryType,
  LeadCustomerFilterType,
  LeadCustomerType,
  ManufacturingVoucher,
  ManufacturingVoucherFilter,
  PurchaseEnquiry,
  PurchaseOrder,
  PurchaseOrderFulfilment,
  PurchaseRequisition,
  SalesEnquiry,
  SalesOrder,
  Vendor,
  VirtualMFStock,
  VirtualMFStockFilter,
  VirtualStockType,
} from '@erp_core/erp-types/dist/modules/order';
import { SalesEnquiryFilter } from '@erp_core/erp-types/dist/types/modules/order/sales/enquiry';
import { SalesOrderFilter } from '@erp_core/erp-types/dist/types/modules/order/sales/order';
import { ORDER_SERVER_URI } from './server-uri';
// import { HttpVirtualStockRepo } from '../../../v2/datalayer/repository/http/inventory/item/stock/virtual-stock.ts';
import { HttpVirtualStockRepo } from '../datalayer/repository/http/inventory/item/stock/virtual-stock';
import { HttpEnquiryQuotesRepo } from '../datalayer/repository/http/order/purchase/enquiry-quotes';
import { HttpPurchaseEnquiryRepo } from '../datalayer/repository/http/order/purchase/purchase-enquiry';
import { HttpPurchaseOrderRepo } from '../datalayer/repository/http/order/purchase/purchase-order';
import { HttpPurchaseOrderFulfilmentRepo } from '../datalayer/repository/http/order/purchase/purchase-order-fulfilment';
import { HttpPurchaseRequisitionRepo } from '../datalayer/repository/http/order/purchase/purchase-requisition';
import { HttpVendorRepo } from '../datalayer/repository/http/order/purchase/vendors';
import { HttpClientRepo } from '../datalayer/repository/http/order/sales/client';
import { HttpClientItemRepo } from '../datalayer/repository/http/order/sales/client-item';
import { HttpSalesEnquiryRepo } from '../datalayer/repository/http/order/sales/enquiry';
import { HttpLeadCustomerRepo } from '../datalayer/repository/http/order/sales/lead-customer';
import { HttpLeadCustomerEnquiryRepo } from '../datalayer/repository/http/order/sales/lead-customer-enquiry';
import { HttpLeadEnquiryRepo } from '../datalayer/repository/http/order/sales/lead-enquiry';
import { HttpSalesOrderRepo } from '../datalayer/repository/http/order/sales/order';
import { HttpBomRepo } from '../datalayer/repository/http/order/work-order/bom';
import { HttpBomDependencyRepo } from '../datalayer/repository/http/order/work-order/bom-dependency';
import { HttpBomFlowRepo } from '../datalayer/repository/http/order/work-order/bom-flow';
import { HttpBomProjectRepo } from '../datalayer/repository/http/order/work-order/bom-project';
import { HttpManufacturingVoucherRepo } from '../datalayer/repository/http/order/work-order/manufacturing-voucher';
import { HttpVirtualManufacturingStockRepo } from '../datalayer/repository/http/order/work-order/virtual-manufacturing-stock-repo';
import { createUseVirtualItemAllocate } from '../hooks/inventory/purchase/purchase-order/use-allocate-virtual-item';
import { createUseVendorItem } from '../hooks/inventory/purchase/vendors/use-vendor-item';
import { createUseVirtuallyReserveMfVoucher } from '../hooks/order/work-order/mf-voucher/use-virtually-reserve-manufacturing-voucher';
import { createUsePaginatedResources } from '../hooks/resource/use-paginated-resources';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { BomDependencyInterface } from '../models/interfaces/inventory/bom-dependency';
import { BomFlowInterface } from '../models/interfaces/inventory/bom-flow';
import { BomProjectInterface } from '../models/interfaces/inventory/bom-project';
import { VirtualStockInterface } from '../models/interfaces/inventory/item/stock/virtual-stock';
import {
  EnquiryQuotesFilterType,
  EnquiryQuotesInterface,
} from '../models/interfaces/order/purchase/enquiry-quotes';
import {
  PurchaseEnquiryFilterType,
  PurchaseEnquiryInterface,
} from '../models/interfaces/order/purchase/purchase-enquiry';
import {
  PurchaseOrderFilterType,
  PurchaseOrderInterface,
} from '../models/interfaces/order/purchase/purchase-order';
import {
  PurchaseOrderFulfilmentFilterType,
  PurchaseOrderFulfilmentInterface,
} from '../models/interfaces/order/purchase/purchase-order-fulfilment';
import {
  PurchaseRequisitionFilterType,
  PurchaseRequisitionInterface,
} from '../models/interfaces/order/purchase/purchase-requisition';
import {
  VendorFilterType,
  VendorInterface,
} from '../models/interfaces/order/purchase/vendors';
import { ClientInterface } from '../models/interfaces/order/sales/client';
import { ClientItemInterface } from '../models/interfaces/order/sales/client-item';
import { SalesEnquiryInterface } from '../models/interfaces/order/sales/enquiry';
import { LeadCustomerInterface } from '../models/interfaces/order/sales/lead-customer';
import { LeadCustomerEnquiryInterface } from '../models/interfaces/order/sales/lead-customer-enquiry';
import { LeadEnquiryInterface } from '../models/interfaces/order/sales/lead-enqiry';
import { SalesOrderInterface } from '../models/interfaces/order/sales/order';
import {
  BOMFilterType,
  BOMInterface,
} from '../models/interfaces/order/work-order/bom';
import { ManufacturingVoucherInterface } from '../models/interfaces/order/work-order/manufacturing-voucher.ts';
import {
  VirtualMFStockInterface,
  VirtualStockFilterType,
} from '../models/interfaces/order/work-order/virtual-manufacturing-stock';
import {
  LeadEnquiryFilterType,
  LeadEnquiryType,
} from '../models/types/sales/lead-enquiry';
import { erpV2Api } from './admin';

export const manufacturingVoucherService = new HttpManufacturingVoucherRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const useManufacturingVouchers = createUseResources<
  ManufacturingVoucher,
  ManufacturingVoucherFilter,
  ManufacturingVoucherInterface
>({
  useService: () => manufacturingVoucherService,
});

export const usePaginatedManufacturingVouchers = createUsePaginatedResources<
  ManufacturingVoucher,
  ManufacturingVoucherFilter,
  ManufacturingVoucherInterface
>({
  useService: () => manufacturingVoucherService,
});

export const useManufacturingVoucher = createUseResource<
  ManufacturingVoucher,
  ManufacturingVoucherFilter,
  ManufacturingVoucherInterface
>({ useService: () => manufacturingVoucherService });

export const virtualManufacturingStockService = new HttpVirtualManufacturingStockRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const useVirtualMfStock = createUseResource<
  VirtualMFStock,
  VirtualMFStockFilter,
  VirtualMFStockInterface
>({
  useService: () => virtualManufacturingStockService,
});

export const clientService = new HttpClientRepo(ORDER_SERVER_URI, erpV2Api);
export const useClients = createUseResources<
  Client,
  ClientFilterType,
  ClientInterface
>({ useService: () => clientService });

export const useClient = createUseResource<
  Client,
  ClientFilterType,
  ClientInterface
>({ useService: () => clientService });

export const usePaginatedClients = createUsePaginatedResources<
  Client,
  ClientFilterType,
  ClientInterface
>({
  useService: () => clientService,
});

export const leadCustomersService = new HttpLeadCustomerRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const useLeadCustomers = createUseResources<
  LeadCustomerType,
  LeadCustomerFilterType,
  LeadCustomerInterface
>({ useService: () => leadCustomersService });

export const useLeadCustomer = createUseResource<
  LeadCustomerType,
  LeadCustomerFilterType,
  LeadCustomerInterface
>({ useService: () => leadCustomersService });

export const usePaginatedLeadCustomers = createUsePaginatedResources<
  LeadCustomerType,
  LeadCustomerFilterType,
  LeadCustomerInterface
>({
  useService: () => leadCustomersService,
});

export const leadCustomerEnquiryService = new HttpLeadCustomerEnquiryRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const useLeadCustomerEnquiries = createUseResources<
  LeadCustomerEnquiryType,
  LeadCustomerEnquiryFilterType,
  LeadCustomerEnquiryInterface
>({ useService: () => leadCustomerEnquiryService });

export const useLeadCustomerEnquiry = createUseResource<
  LeadCustomerEnquiryType,
  LeadCustomerEnquiryFilterType,
  LeadCustomerEnquiryInterface
>({ useService: () => leadCustomerEnquiryService });

export const usePaginatedLeadCustomerEnquiries = createUsePaginatedResources<
  LeadCustomerEnquiryType,
  LeadCustomerEnquiryFilterType,
  LeadCustomerEnquiryInterface
>({
  useService: () => leadCustomerEnquiryService,
});

export const clientItemService = new HttpClientItemRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const useClientItems = createUseResources<
  ClientItem,
  ClientItemFilterType,
  ClientItemInterface
>({ useService: () => clientItemService });

export const useClientItem = createUseResource<
  ClientItem,
  ClientItemFilterType,
  ClientItemInterface
>({ useService: () => clientItemService });

export const vendorService = new HttpVendorRepo(ORDER_SERVER_URI, erpV2Api);
export const useVendors = createUseResources<
  Vendor,
  VendorFilterType,
  VendorInterface
>({
  useService: () => vendorService,
});

export const useVendor = createUseResource<
  Vendor,
  VendorFilterType,
  VendorInterface
>({
  useService: () => vendorService,
});

export const usePaginatedVendors = createUsePaginatedResources<
  Vendor,
  VendorFilterType,
  VendorInterface
>({
  useService: () => vendorService,
});

export const purchaseRequisitionService = new HttpPurchaseRequisitionRepo(
  ORDER_SERVER_URI,
  erpV2Api
);

export const usePurchaseRequisition = createUseResource<
  PurchaseRequisition,
  PurchaseRequisitionFilterType,
  PurchaseRequisitionInterface
>({
  useService: () => purchaseRequisitionService,
});

export const usePurchaseRequisitions = createUseResources<
  PurchaseRequisition,
  PurchaseRequisitionFilterType,
  PurchaseRequisitionInterface
>({
  useService: () => purchaseRequisitionService,
});

export const usePaginatedPurchaseRequisitions = createUsePaginatedResources<
  PurchaseRequisition,
  PurchaseRequisitionFilterType,
  PurchaseRequisitionInterface
>({
  useService: () => purchaseRequisitionService,
});

export const purchaseEnquiryService = new HttpPurchaseEnquiryRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const usePurchaseEnquiry = createUseResource<
  PurchaseEnquiry,
  PurchaseEnquiryFilterType,
  PurchaseEnquiryInterface
>({
  useService: () => purchaseEnquiryService,
});

export const usePurchaseEnquiries = createUseResources<
  PurchaseEnquiry,
  PurchaseEnquiryFilterType,
  PurchaseEnquiryInterface
>({
  useService: () => purchaseEnquiryService,
});

export const usePaginatedPurchaseEnquiries = createUsePaginatedResources<
  PurchaseEnquiry,
  PurchaseEnquiryFilterType,
  PurchaseEnquiryInterface
>({
  useService: () => purchaseEnquiryService,
});

export const enquiryQuotesService = new HttpEnquiryQuotesRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const useEnquiryQuote = createUseResource<
  EnquiryQuotes,
  EnquiryQuotesFilterType,
  EnquiryQuotesInterface
>({
  useService: () => enquiryQuotesService,
});

export const useEnquiryQuotes = createUseResources<
  EnquiryQuotes,
  EnquiryQuotesFilterType,
  EnquiryQuotesInterface
>({
  useService: () => enquiryQuotesService,
});

export const purchaseOrderFulfilmentService = new HttpPurchaseOrderFulfilmentRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const usePurchaseOrderFulfilment = createUseResource<
  PurchaseOrderFulfilment,
  PurchaseOrderFulfilmentFilterType,
  PurchaseOrderFulfilmentInterface
>({
  useService: () => purchaseOrderFulfilmentService,
});
export const usePurchaseOrderFulfilments = createUseResources<
  PurchaseOrderFulfilment,
  PurchaseOrderFulfilmentFilterType,
  PurchaseOrderFulfilmentInterface
>({
  useService: () => purchaseOrderFulfilmentService,
});

export const salesEnquiryService = new HttpSalesEnquiryRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const useEnquiry = createUseResource<
  SalesEnquiry,
  SalesEnquiryFilter,
  SalesEnquiryInterface
>({
  useService: () => salesEnquiryService,
});

export const useEnquiries = createUseResources<
  SalesEnquiry,
  SalesEnquiryFilter,
  SalesEnquiryInterface
>({
  useService: () => salesEnquiryService,
});
export const usePaginatedEnquiries = createUsePaginatedResources<
  SalesEnquiry,
  SalesEnquiryFilter,
  SalesEnquiryInterface
>({
  useService: () => salesEnquiryService,
});

export const salesOrderService = new HttpSalesOrderRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const useSalesOrder = createUseResource<
  SalesOrder,
  SalesOrderFilter,
  SalesOrderInterface
>({
  useService: () => salesOrderService,
});

export const useOrders = createUseResources<
  SalesOrder,
  SalesOrderFilter,
  SalesOrderInterface
>({
  useService: () => salesOrderService,
});

export const usePaginatedSalesOrders = createUsePaginatedResources<
  SalesOrder,
  SalesOrderFilter,
  SalesOrderInterface
>({
  useService: () => salesOrderService,
});

export const virtualStockService = new HttpVirtualStockRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const useVirtualStock = createUseResource<
  VirtualStockType,
  VirtualStockFilterType,
  VirtualStockInterface
>({
  useService: () => virtualStockService,
});

export const bomService = new HttpBomRepo(
  ORDER_SERVER_URI,
  erpV2Api
  // godownService
);
export const useBoms = createUseResources<BOMRes, BOMFilterType, BOMInterface>({
  useService: () => bomService,
});

export const usePaginatedBoms = createUsePaginatedResources<
  BOMRes,
  BOMFilterType,
  BOMInterface
>({
  useService: () => bomService,
});

export const useBom = createUseResource<BOMRes, BOMFilterType, BOMInterface>({
  useService: () => bomService,
});

export const purchaseOrderService = new HttpPurchaseOrderRepo(
  ORDER_SERVER_URI,
  erpV2Api,
  bomService
);
export const usePurchaseOrder = createUseResource<
  PurchaseOrder,
  PurchaseOrderFilterType,
  PurchaseOrderInterface
>({
  useService: () => purchaseOrderService,
});

export const usePaginatedPurchaseOrders = createUsePaginatedResources<
  PurchaseOrder,
  PurchaseOrderFilterType,
  PurchaseOrderInterface
>({
  useService: () => purchaseOrderService,
});

export const usePurchaseOrders = createUseResources<
  PurchaseOrder,
  PurchaseOrderFilterType,
  PurchaseOrderInterface
>({
  useService: () => purchaseOrderService,
});

export const useVirtuallyReserveMfVoucher = createUseVirtuallyReserveMfVoucher({
  manufacturingVoucherService,
});

export const useVendorItem = createUseVendorItem({
  vendorService,
});

export const useVirtualItemAllocate = createUseVirtualItemAllocate({
  purchaseOrderService,
});

export const bomFlowService = new HttpBomFlowRepo(ORDER_SERVER_URI, erpV2Api);
export const useBomFlow = createUseResource<
  BomFlow,
  BomFlowFilter,
  BomFlowInterface
>({
  useService: () => bomFlowService,
});

export const usePaginatedBomFlows = createUsePaginatedResources<
  BomFlow,
  BomFlowFilter,
  BomFlowInterface
>({
  useService: () => bomFlowService,
});

export const useBomFlows = createUseResources<
  BomFlow,
  BomFlowFilter,
  BomFlowInterface
>({
  useService: () => bomFlowService,
});

export const bomDependencyService = new HttpBomDependencyRepo(
  ORDER_SERVER_URI,
  erpV2Api
);

export const useBomDependencies = createUseResources<
  BomDependency,
  BomDependencyFilter,
  BomDependencyInterface
>({
  useService: () => bomDependencyService,
});

export const useBomDependency = createUseResource<
  BomDependency,
  BomDependencyFilter,
  BomDependencyInterface
>({
  useService: () => bomDependencyService,
});

export const bomProjectService = new HttpBomProjectRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const useBomProject = createUseResource<
  BomProjectType,
  BomProjectFilterType,
  BomProjectInterface
>({
  useService: () => bomProjectService,
});

export const usePaginatedBomProjects = createUsePaginatedResources<
  BomProjectType,
  BomProjectFilterType,
  BomProjectInterface
>({
  useService: () => bomProjectService,
});

export const useBomProjects = createUseResources<
  BomProjectType,
  BomProjectFilterType,
  BomProjectInterface
>({
  useService: () => bomProjectService,
});

export const salesLeadEnquiryService = new HttpLeadEnquiryRepo(
  ORDER_SERVER_URI,
  erpV2Api
);
export const useLeadEnquiry = createUseResource<
  LeadEnquiryType,
  LeadEnquiryFilterType,
  LeadEnquiryInterface
>({
  useService: () => salesLeadEnquiryService,
});

export const useLeadEnquiries = createUseResources<
  LeadEnquiryType,
  LeadEnquiryFilterType,
  LeadEnquiryInterface
>({
  useService: () => salesLeadEnquiryService,
});
