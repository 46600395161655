import {
  SalaryFilterType,
  SalaryType,
} from '@erp_core/erp-types/dist/types/modules/payroll/salary';
import {
  SalaryParamFilterType,
  SalaryParamType,
} from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import {
  SalaryRevisionFilterType,
  SalaryRevisionType,
} from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import { PayrollReportsHttpRepo } from '../datalayer/repository/http/hrd/payroll-reports';
import { SalaryHttpRepo } from '../datalayer/repository/http/hrd/salary';
import { SalaryParamHttpRepo } from '../datalayer/repository/http/hrd/salary-param';
import { SalaryRevisionHttpRepo } from '../datalayer/repository/http/hrd/salary-revision';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { PayrollReportsInterface } from '../models/interfaces/hrd/payroll-reports';
import { SalaryInterface } from '../models/interfaces/hrd/salary';
import { SalaryParamInterface } from '../models/interfaces/hrd/salary-param';
import { SalaryRevisionInterface } from '../models/interfaces/hrd/salary-revision';
import {
  PayrollReport,
  PayrollReportFilter,
} from '../models/types/payroll-reports';
import { erpV2Api } from './admin';
import { PAYROLL_SERVER_URI } from './server-uri';

export const salaryService = new SalaryHttpRepo(PAYROLL_SERVER_URI, erpV2Api);
export const useSalary = createUseResource<
  SalaryType,
  SalaryFilterType,
  SalaryInterface
>({
  useService: () => salaryService,
});
export const useSalaries = createUseResources<
  SalaryType,
  SalaryFilterType,
  SalaryInterface
>({
  useService: () => salaryService,
});

export const salaryParamService = new SalaryParamHttpRepo(
  PAYROLL_SERVER_URI,
  erpV2Api
);
export const useSalaryParam = createUseResource<
  SalaryParamType,
  SalaryParamFilterType,
  SalaryParamInterface
>({
  useService: () => salaryParamService,
});

export const useSalaryParams = createUseResources<
  SalaryParamType,
  SalaryParamFilterType,
  SalaryParamInterface
>({
  useService: () => salaryParamService,
});

export const salaryRevisionService = new SalaryRevisionHttpRepo(
  PAYROLL_SERVER_URI,
  erpV2Api
);
export const useSalaryRevision = createUseResource<
  SalaryRevisionType,
  SalaryRevisionFilterType,
  SalaryRevisionInterface
>({
  useService: () => salaryRevisionService,
});

export const useSalaryRevisions = createUseResources<
  SalaryRevisionType,
  SalaryRevisionFilterType,
  SalaryRevisionInterface
>({
  useService: () => salaryRevisionService,
});

export const payrollReportsService = new PayrollReportsHttpRepo(
  PAYROLL_SERVER_URI,
  erpV2Api
);
export const usePayrollReport = createUseResource<
  PayrollReport,
  PayrollReportFilter,
  PayrollReportsInterface
>({
  useService: () => payrollReportsService,
});

export const usePayrollReports = createUseResources<
  PayrollReport,
  PayrollReportFilter,
  PayrollReportsInterface
>({
  useService: () => payrollReportsService,
});
