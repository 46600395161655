/* eslint-disable react-hooks/exhaustive-deps */

import { EmployeeDesignation } from '@erp_core/erp-types/dist/modules/constants';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseEmployeeCategories } from '../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseEmployeeDesignation } from '../../../hooks/admin/constants/employee-designation/use-employee-designation';
import { UseEmployeeDesignations } from '../../../hooks/admin/constants/employee-designation/use-employee-designations';
import { UsePaginatedEmployeeDesignations } from '../../../hooks/admin/constants/employee-designation/use-paginated-employee-designations';
import { renderAddEmployeeDesignationForm } from './form/add-employee-designation';

export type RenderEmployeeDesignationProps = {
  useEmployeeDesignations: UseEmployeeDesignations;
  usePaginatedEmployeeDesignations: UsePaginatedEmployeeDesignations;
  useEmployeeCategories: UseEmployeeCategories;
  useEmployeeDesignation: UseEmployeeDesignation;
};

export function renderEmployeeDesignation({
  useEmployeeDesignations,
  usePaginatedEmployeeDesignations,
  useEmployeeCategories,
  useEmployeeDesignation,
}: RenderEmployeeDesignationProps): () => JSX.Element {
  return function Designation(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      EmployeeDesignation,
      { search?: string }
    >();
    const { getAll: getDesignations } = useEmployeeDesignations();

    const { syncSet: setDesignation } = useEmployeeDesignation();

    useEffect(() => {
      getDesignations();
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Employee Designations',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Employee Designation',
            behaviour: 'modal',
            modal: {
              title: 'Add Employee Designation',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveEmployeeDesignation(form);
                      onClose();
                    }}
                    useEmployeeCategories={useEmployeeCategories}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddEmployeeDesignationForm();

    const saveEmployeeDesignation = async (
      form: Partial<EmployeeDesignation>
    ) => {
      try {
        const finalData = {
          ...form,
        } as EmployeeDesignation;
        await setDesignation(finalData as EmployeeDesignation);
        toast('Data added sucessfully');
        getDesignations();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [
      [{ name: 'Id' }, { name: 'Name' }, { name: 'Grade' }],
    ];

    const bodyMapper = (r: EmployeeDesignation) => {
      const cells: Array<TableCell> = [
        { value: r.id },
        { value: r.name },
        { value: r.category.name },
      ];
      return {
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full h-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              useResources={useEmployeeDesignations}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedEmployeeDesignations,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
