/* eslint-disable no-unused-vars */
import { Company } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useContext } from 'react';
import { CurrentContext } from '../../../contexts/current';
import { UseCompanyAdmin } from '../../../hooks/admin/company-admin/use-company-admin';
import { UsePublicTransfer } from '../../../hooks/file-transfer/use-public-transfer';
import { CompanyRequest } from '../../../models/interfaces/admin/company-admin';

export type RenderEditCompanyFormProps = {
  useCompanyAdmin: UseCompanyAdmin;
  usePublicTransfer: UsePublicTransfer;
};

export function renderEditCompanyForm({
  useCompanyAdmin,
  usePublicTransfer,
}: RenderEditCompanyFormProps): (Company) => JSX.Element {
  return function EditCompanyForm({
    r,
    onClose,
  }: {
    r: Partial<Company>;
    onClose: () => void;
  }): JSX.Element {
    const { syncSet: setCompanyAdmin } = useCompanyAdmin();
    const { companyGroup } = useContext(CurrentContext);

    const saveCompany = async (form) => {
      if (r.id) form.id = r.id;

      const details = {
        address: {
          addressLine1: form.addressLine1 || r.details?.address?.addressLine1,
          addressLine2: form.addressLine2 || r.details?.address?.addressLine2,
          city: form.city || r.details?.address?.city,
          district: form.district || r.details?.address?.district,
          pincode: form.pincode || r.details?.address?.pincode,
          state: form.state || r.details?.address?.state,
        },
        contact: {
          mobile: form.mobile || r.details?.contact?.mobile,
          email: form.email || r.details?.contact?.email,
        },
      };

      form.details = details;
      await setCompanyAdmin(form);
      onClose();
    };

    const formProps: FormProps<CompanyRequest> = {
      fieldsData: [
        {
          groupName: '1. General',
          property: 'name',
          label: 'Company Name',
          type: 'input',
          required: true,
        },
        {
          groupName: '1. General',
          property: 'shortName',
          label: 'Short Name',
          type: 'input',
          required: true,
        },
        {
          groupName: '1. General',
          property: 'logo',
          label: 'Company Logo',
          type: 'file',
          accept: '.png, .jpg, .svg',
        },

        {
          groupName: '2. Registered Office Address',
          property: 'addressLine1',
          label: 'Address Line 1',
          type: 'input',
        },
        {
          groupName: '2. Registered Office Address',
          property: 'addressLine2',
          label: 'Address Line 2',
          type: 'input',
        },
        {
          groupName: '2. Registered Office Address',
          property: 'city',
          label: 'City',
          type: 'input',
        },
        {
          groupName: '2. Registered Office Address',
          property: 'district',
          label: 'District',
          type: 'input',
        },
        {
          groupName: '2. Registered Office Address',
          property: 'state',
          label: 'State',
          type: 'input',
        },
        {
          groupName: '2. Registered Office Address',
          property: 'pincode',
          label: 'Pincode',
          type: 'input',
        },
        {
          groupName: '2. Registered Office Address',
          property: 'mobile',
          label: 'Mobile',
          type: 'input',
        },
        {
          groupName: '2. Registered Office Address',
          property: 'email',
          label: 'Email',
          type: 'email',
        },
      ],
      initialFormState: {
        name: r.name || '',
        shortName: r.shortName || '',
        logo: '',
        addressLine1: r.details?.address?.addressLine1 || '',
        addressLine2: r.details?.address?.addressLine2 || '',
        city: r.details?.address?.city || '',
        district: r.details?.address?.district || '',
        state: r.details?.address?.state || '',
        email: r.details?.contact?.email || '',
        pincode: r.details?.address?.pincode || '',
        mobile: r.details?.contact?.mobile || '',
      },
      mapTToU: mapPFDToP,
      style: 'w-1/3',
      onSubmit: saveCompany,
      upload: {
        useFileTransfer: usePublicTransfer,
        config: [
          {
            name: 'logo',
            path: `${companyGroup.id}/${r.id}`,
            type: 'public',
          },
        ],
      },
    };

    const Form = renderFormV2<CompanyRequest>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<CompanyRequest>): CompanyRequest {
      return (b as unknown) as CompanyRequest;
    }
  };
}
