import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import {
  AdvancedLoadingButton,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { AddItemShiftRolesForm } from './forms/addRolesForm';
import { AddItemShiftSkillsForm } from './forms/addSkillsForm';

type Props = {
  item: ItemRes;
  setItem: (data: ItemRes, options?: any) => Promise<ItemRes>;
};

export const renderItemShiftConfig = () => {
  const Table = renderTableComponent();

  return function ({ item, setItem }: Props) {
    const itemDetails: any = item.details;

    const addItemSkills = async (form) => {
      const finalData = {
        id: item.id,
        details: {
          shiftConfig: {
            ...itemDetails?.shiftConfig,
            skills: [...(itemDetails?.shiftConfig?.skills || []), form],
          },
        },
      };
      await setItem((finalData as unknown) as ItemRes);
    };

    const addItemRoles = async (form) => {
      const finalData = {
        id: item.id,
        details: {
          shiftConfig: {
            ...itemDetails?.shiftConfig,
            roles: [...(itemDetails?.shiftConfig?.roles || []), form],
          },
        },
      };
      await setItem((finalData as unknown) as ItemRes);
    };

    const editItemSkills = async (form, index) => {
      const skills = itemDetails?.shiftConfig?.skills || [];
      skills[index] = form;
      const finalData = {
        id: item.id,
        details: {
          shiftConfig: {
            ...itemDetails?.shiftConfig,
            skills: skills,
          },
        },
      };
      await setItem((finalData as unknown) as ItemRes);
    };

    const editItemRoles = async (form, index) => {
      const roles = itemDetails?.shiftConfig?.roles || [];
      roles[index] = form;
      const finalData = {
        id: item.id,
        details: {
          shiftConfig: {
            ...itemDetails?.shiftConfig,
            roles,
          },
        },
      };
      await setItem((finalData as unknown) as ItemRes);
    };

    const skillsTableBody: TableBody =
      itemDetails?.shiftConfig?.skills?.map((s, index) => {
        return {
          rowData: {
            s,
            index,
          },
          cells: [{ value: s?.name }, { value: s?.min }, { value: s?.max }],
        };
      }) || [];

    const rolesTableBody: TableBody =
      itemDetails?.shiftConfig?.roles?.map((s, index) => {
        return {
          rowData: {
            s,
            index,
          },
          cells: [
            { value: s?.name },
            { value: s?.level || '' },
            { value: s?.min },
            { value: s?.max },
          ],
        };
      }) || [];

    return (
      <div className='flex flex-col space-y-4'>
        <div>
          <div className='flex justify-between pb-2'>
            <div className='font-bold'>Skills required</div>
            <AdvancedLoadingButton
              defaultStyle='bg-blue-500 p-2 rounded shadow text-white font-semibold hover:bg-blue-600'
              behaviour='modal'
              modal={{
                title: 'Add Skill',
                content: ({ onClose }) => {
                  return (
                    <AddItemShiftSkillsForm
                      save={async (form) => {
                        await addItemSkills(form);
                        onClose();
                      }}
                      item={item}
                    />
                  );
                },
              }}
              text='Add skills'
            />
          </div>
          <div>
            <Table
              header={[[{ name: 'name' }, { name: 'min' }, { name: 'max' }]]}
              body={skillsTableBody}
              actions={[
                {
                  name: 'Edit',
                  behaviour: 'modal',
                  show: () => true,
                  modal: {
                    title: 'Edit',
                    content: ({ data: { s, index }, onClose }) => {
                      return (
                        <AddItemShiftSkillsForm
                          save={async (form) => {
                            editItemSkills(form, index);
                            onClose();
                          }}
                          item={item}
                          data={s}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Delete',
                  behaviour: 'confirm',
                  show: () => true,
                  onConfirm: ({ s, index }) => {
                    return {
                      title: 'Are you sure you want to delete',
                      onConfirm: async () => {
                        let skills: any[] =
                          itemDetails.shiftConfig.skills || [];
                        skills.splice(index, 1);
                        const finalData = {
                          id: item.id,
                          details: {
                            shiftConfig: {
                              ...itemDetails.shiftConfig,
                              skills: skills,
                            },
                          } as any,
                        };
                        await setItem(finalData as ItemRes);
                      },
                    };
                  },
                },
              ]}
            />
          </div>
        </div>

        <div>
          <div className='flex justify-between pb-2'>
            <div className='font-bold'>Roles required</div>
            <AdvancedLoadingButton
              defaultStyle='bg-blue-500 p-2 rounded shadow text-white font-semibold hover:bg-blue-600'
              behaviour='modal'
              modal={{
                title: 'Add Role',
                content: ({ onClose }) => {
                  return (
                    <AddItemShiftRolesForm
                      save={async (form) => {
                        await addItemRoles(form);
                        onClose();
                      }}
                      item={item}
                    />
                  );
                },
              }}
              text='Add Role'
            />
          </div>
          <div>
            <Table
              header={[
                [
                  { name: 'Name' },
                  { name: 'Level' },
                  { name: 'Min' },
                  { name: 'Max' },
                ],
              ]}
              body={rolesTableBody}
              actions={[
                {
                  name: 'Edit',
                  behaviour: 'modal',
                  show: () => true,
                  modal: {
                    title: 'Edit',
                    content: ({ data: { s, index }, onClose }) => {
                      return (
                        <AddItemShiftRolesForm
                          save={async (form) => {
                            await editItemRoles(form, index);
                            onClose();
                          }}
                          item={item}
                          data={s}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Delete',
                  behaviour: 'confirm',
                  show: () => true,
                  onConfirm: ({ s, index }) => {
                    return {
                      title: 'Are you sure you want to Delete?',
                      onConfirm: async () => {
                        let roles: any[] = itemDetails.shiftConfig.roles || [];
                        roles.splice(index, 1);
                        const finalData = {
                          id: item.id,
                          details: {
                            shiftConfig: {
                              ...itemDetails.shiftConfig,
                              roles,
                            },
                          } as any,
                        };
                        await setItem(finalData as ItemRes);
                      },
                    };
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  };
};
