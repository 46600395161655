import {
  JobsType,
  TaskFilter,
  TaskType,
} from '@erp_core/erp-types/dist/modules/admin';
import { JobsFilter } from '@erp_core/erp-types/dist/types/modules/admin/scheduler/job';
import { SchedulerJobHttpRepo } from '../datalayer/repository/http/scheduler/jobs';
import { SchedulerTaskHttpRepo } from '../datalayer/repository/http/scheduler/task';
import { createUsePaginatedResources } from '../hooks/resource/use-paginated-resources';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { JobInterface } from '../models/interfaces/admin/scheduler/job';
import { TaskInterface } from '../models/interfaces/admin/scheduler/task';
import { erpV2Api } from './admin';
import { SCHEDULER_SERVER_URI } from './server-uri';

export const schedulertaskService = new SchedulerTaskHttpRepo(
  SCHEDULER_SERVER_URI,
  erpV2Api
);

export const useTask = createUseResource<TaskType, TaskFilter, TaskInterface>({
  useService: () => schedulertaskService,
});

export const useTasks = createUseResources<TaskType, TaskFilter, TaskInterface>(
  {
    useService: () => schedulertaskService,
  }
);

export const schedulerjobService = new SchedulerJobHttpRepo(
  SCHEDULER_SERVER_URI,
  erpV2Api
);

export const useJob = createUseResource<JobsType, JobsFilter, JobInterface>({
  useService: () => schedulerjobService,
});

export const useJobs = createUseResources<JobsType, JobsFilter, JobInterface>({
  useService: () => schedulerjobService,
});

export const usePaginatedJobs = createUsePaginatedResources<
  JobsType,
  JobsFilter,
  JobInterface
>({
  useService: () => schedulerjobService,
});
