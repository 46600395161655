import { Account } from '@erp_core/erp-icons/icons/web/account';
import { History } from '@erp_core/erp-icons/icons/web/history';
import { Label } from '@erp_core/erp-icons/icons/web/label';
import { MachineIcon } from '@erp_core/erp-icons/icons/web/machine-icon';
import { Mail } from '@erp_core/erp-icons/icons/web/mail';
import { UnderConstruction } from '@erp_core/erp-ui-components';
import {
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
  BookOpenIcon,
  BuildingOfficeIcon,
  CalendarIcon,
  CheckBadgeIcon,
  CheckIcon,
  CircleStackIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  ComputerDesktopIcon,
  CreditCardIcon,
  CubeTransparentIcon,
  CurrencyRupeeIcon,
  DocumentIcon,
  DocumentTextIcon,
  EllipsisHorizontalCircleIcon,
  FingerPrintIcon,
  InboxStackIcon,
  PaperAirplaneIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  TableCellsIcon,
  TagIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { renderBankSalaries } from '../../models/interfaces/accounts/disbursements';
import { Config } from '../../models/interfaces/common/app-config';
import { renderBill } from '../../modules/accounts/bills';
import { renderCashFlowDetails } from '../../modules/accounts/cashflow/cashflow-details';
import { renderDaybook } from '../../modules/accounts/daybook';
import { renderAccountsSummary } from '../../modules/accounts/tally/account-summary';
import { renderBalanceSheet } from '../../modules/accounts/tally/balance-sheet';
import { renderTallyDaybook } from '../../modules/accounts/tally/daybook';
import { renderGroupTree } from '../../modules/accounts/tally/groups/group-tree';
import { renderLedgerTree } from '../../modules/accounts/tally/ledgers/ledger-tree';
import { renderProfitNLoss } from '../../modules/accounts/tally/profit-n-loss';
import { renderAdminMasters } from '../../modules/admin';
import { renderTemplate } from '../../modules/admin/communication/template';
import { renderCompanyDetails } from '../../modules/admin/company-admin/company-profile';
import { renderCompanyGroupConfig } from '../../modules/admin/company-group-config';
import { renderAttendanceDevices } from '../../modules/admin/company-group-config/hrd/attendance-device';
import { renderConstants } from '../../modules/admin/constants';
import { renderDashboardSettings } from '../../modules/admin/dashboard';
import { renderDashboardModule } from '../../modules/admin/dashboard/module';
import { renderDatabaseSettings } from '../../modules/admin/database-settings';
import { renderHazardRegulations } from '../../modules/admin/hazard-regulations';
import { renderHazardClassPage } from '../../modules/admin/hazard-regulations/hazard-class-page';
import { renderLocationDetails } from '../../modules/admin/location-admin/location-profile';
import { renderSchedulerJob } from '../../modules/admin/scheduler/jobs';
import { renderSchedulerTask } from '../../modules/admin/scheduler/task';
import { renderAdhocPayments } from '../../modules/hrd/adhoc-payments';
import { createNewAttendancePage } from '../../modules/hrd/attendance/day-book';
import { createEmployeeAttendancePage } from '../../modules/hrd/attendance/employee-book';
import { renderEmployeePendingActionsPage } from '../../modules/hrd/attendance/employee-pending-actions';
import { renderMonthGatePasses } from '../../modules/hrd/attendance/gate-passes';
import { renderEmployeeLeaves } from '../../modules/hrd/attendance/leaves-bank';
import { renderEmployeeMonthLeavesBook } from '../../modules/hrd/attendance/leaves-book';
import { renderOffSiteMonthWorkHours } from '../../modules/hrd/attendance/off-site-work-hours';
import { renderMonthOvertimes } from '../../modules/hrd/attendance/overtime';
import { renderRefillLeavesBook } from '../../modules/hrd/attendance/refill-leaves-bank';
import { renderDepartmentMaster } from '../../modules/hrd/department';
import { renderDepartmentList } from '../../modules/hrd/department/department-master';
import { renderMisconduct } from '../../modules/hrd/disciplinary/misconduct';
import { renderRuleRequestDetails } from '../../modules/hrd/disciplinary/misconduct/component/ruleRequestDetails';
import { renderEmployeeExit } from '../../modules/hrd/employee-exit';
import { renderOffBoardingActions } from '../../modules/hrd/employee-exit/off-boarding-actions';
import { renderEmployeeOnboard } from '../../modules/hrd/employee-onboard';
import { renderEmployeeJobTask } from '../../modules/hrd/employee/emp-job-task';
import { renderEmployeeV2Profile } from '../../modules/hrd/employee/employee-profile';
import { renderEmployeeProfileV2List } from '../../modules/hrd/employee/employeelist';
import { renderPolicies } from '../../modules/hrd/insurance';
import { renderLoanList } from '../../modules/hrd/loan-management';
import { renderLoanDetails } from '../../modules/hrd/loan-management/loan-details';
import { renderReportList } from '../../modules/hrd/report';
import { createSalaryPage } from '../../modules/hrd/salary';
import { renderSalaryAdvances } from '../../modules/hrd/salary-advances';
import { createSalaryParametersPage } from '../../modules/hrd/salary-parameters';
import { createSalaryParametersDetailsPage } from '../../modules/hrd/salary-parameters/details';
import { createSalaryRevisionPage } from '../../modules/hrd/salary-revisions';
import { createEmployeeShiftsPage } from '../../modules/hrd/shifts/employee-shifts';
import { renderMonthSchedule } from '../../modules/hrd/shifts/month-schedule';
import { renderEmployeeSwitchShifts } from '../../modules/hrd/shifts/switch-shift';
import { renderWorkAreaPage } from '../../modules/hrd/shifts/work-area';
import { renderWorkAreaV2Page } from '../../modules/hrd/shifts/work-area-v2';
import { renderWorkAreaDetailsPage } from '../../modules/hrd/shifts/work-area-v2/details-page';
import { renderGodownTransfer } from '../../modules/inventory/godown-transfer';
import { renderGodownProfile } from '../../modules/inventory/godowns/godown-profile';
import { renderGodownList } from '../../modules/inventory/godowns/godowns-list';
import { renderItemBatches } from '../../modules/inventory/item-batches';
import { renderGrade } from '../../modules/inventory/items/grade';
import { renderItemV2List } from '../../modules/inventory/items/item-list';
import { renderItemV2Profile } from '../../modules/inventory/items/item-profile';
import { renderStockItemSummary } from '../../modules/inventory/reports/stock-item-summary';
import { renderRequisitionList } from '../../modules/inventory/requisitions';
import { renderBOMDetails } from '../../modules/inventory/requisitions/bom-details';
import { renderBomFlow } from '../../modules/inventory/requisitions/bom-flow';
import { renderBomFlowDetails } from '../../modules/inventory/requisitions/bom-flow-details';
import { renderBomProjectDetails } from '../../modules/inventory/requisitions/bom-project-register/bom-project-details';
import { renderRevisionDetails } from '../../modules/inventory/revision';
import { renderRevisionProfile } from '../../modules/inventory/revision/component/revision-details';
import { renderStockGroupProfile } from '../../modules/inventory/stock-group/profile';
import { renderStockGroupList } from '../../modules/inventory/stock-group/stock-group-list';
import { createInventoryTree } from '../../modules/inventory/tree';
import { renderQuestionForm } from '../../modules/miscellaneous/questionnaire/question';
import { renderSubmittedAnswers } from '../../modules/miscellaneous/questionnaire/submitted-answers';
import { renderSurveyAnswersPage } from '../../modules/miscellaneous/questionnaire/survey-answers';
import { SurveyAnswerSubmitComponent } from '../../modules/miscellaneous/questionnaire/survey-answers/component/survey-answers-submit';
import { renderTopicForm } from '../../modules/miscellaneous/questionnaire/topic';
import { renderWorkLogReport } from '../../modules/miscellaneous/questionnaire/workLog-report';
import { renderStatutoryReportBuilder } from '../../modules/miscellaneous/report-builder';
import { renderLocationHolidaysList } from '../../modules/planning/location-holidays';
import { createManufacturingVoucherList } from '../../modules/planning/production-planning/manufacturing-voucher/manufacturing-voucher-list';
import { renderManufacturingVoucherDetails } from '../../modules/planning/production-planning/manufacturing-voucher/manufacturing-voucher-list-details';
import { renderMachine } from '../../modules/planning/production-planning/schedule/machine';
import { renderMachineSchedule } from '../../modules/planning/production-planning/schedule/machine/machine-schedule';
import { renderMachinesList } from '../../modules/planning/production-planning/schedule/machines';
import { renderPlan } from '../../modules/planning/production-planning/schedule/plan';
import { renderGoodsReceived } from '../../modules/purchase/goods-received';
import { renderPurchaseItemInspection } from '../../modules/purchase/inspection';
import { renderPurchaseRequisitions } from '../../modules/purchase/requistions';
import { renderPurchaseOrderDetails } from '../../modules/purchase/requistions/purchase-order/purchase-order-details';
import { renderPRDetails } from '../../modules/purchase/requistions/purchase-requisition/pr-flow-details';
import { PurchaseItemTransfer } from '../../modules/purchase/transfer';
import { renderVendorGodowns } from '../../modules/purchase/vendor/vendor-godowns';
import { renderVendorList } from '../../modules/purchase/vendor/vendor-list';
import { renderVendorV2Profile } from '../../modules/purchase/vendor/vendor-profile';
import { renderVendorItemSpec } from '../../modules/purchase/vendor/vendor-profile/vendor-items/component/vendor-item-specification';
import { renderClosureDatePeriod } from '../../modules/quality-assurance/closure-date-period';
import { renderRequisitionDetails } from '../../modules/quality-assurance/qa-requests/component/requisition-details';
import { renderChangeControlReqDetails } from '../../modules/quality-assurance/qa-requests/requestList';
import { renderSalesMasters } from '../../modules/sales';
import { renderClientV2Profile } from '../../modules/sales/client-profile';
import { renderClientGodowns } from '../../modules/sales/client/client-godowns';
import { renderClientList } from '../../modules/sales/client/client-list';
import renderSalesItemEnquiryDetails from '../../modules/sales/item-enquiry';
import { renderSalesEnquiryItemsDetailsV2 } from '../../modules/sales/item-enquiry/item-enquiry-details';
import renderSalesItemOrderDetails from '../../modules/sales/item-order';
import { renderLeadCustomerEnquiryDetails } from '../../modules/sales/lead-customer-enquiry-details';
import { renderLeadCustomerList } from '../../modules/sales/lead-customer-list';
import { renderLeadCustomerProfile } from '../../modules/sales/lead-customer-profile';
import renderSalesManufacturingEnquiryDetails from '../../modules/sales/manufacturing-enquiry';
import renderSalesManufacturingOrderDetails from '../../modules/sales/manufacturing-order';
import renderSalesQcEnquiryDetails from '../../modules/sales/qc-enquiry';
import renderSalesQcOrderDetails from '../../modules/sales/qc-order';
import { renderSalesEnquiryDetailsV2 } from '../../modules/sales/sales-enquiry/sales-enquiry-details';
import { renderSalesOrderTabs } from '../../modules/sales/sales-order-tabs';
import { renderSalesOrderDetailsV2 } from '../../modules/sales/sales-order/sales-order-details/index-details';
import { renderUserProfile } from '../../modules/users/user-profile';
import { renderEnquiryDetails } from '../purchase/requistions/purchase-enquiry/enquiry-details';

export type ModuleType = {
  name: string;
  icon: (p: { className: string }) => JSX.Element;
  path: string;
  auth?: string;
  hidden?: boolean;
  content?: () => JSX.Element;
  sidebarLinks: ModuleSidebarLink[];
};

export type ModuleSidebarLink = {
  name: string;
  icon?: (p: { className: string }) => JSX.Element;
  path: string;
  content?: any;
  type: 'link' | 'menu';
  auth?: string;
  menu?: Array<{
    name: string;
    icon?: (p: { className: string }) => JSX.Element;
    path: string;
    content: any;
    hidden?: boolean;
    auth?: string;
  }>;
  hidden?: boolean;
};

export class ModuleRenderer {
  modules: ModuleType[];

  constructor({ config }: { config: Config }) {
    const {
      services: {
        voucherService,
        companyGroupSettingService,
        userRendererService,
        employeeProfileService,
        attendanceService,
        leaveService,
        logRecordService,
        salaryService,
        loansService,
        purchaseEnquiryService,
        purchaseOrderService,
        itemV2Service,
        stockGroupService,
        fileTransferService,
        revisionService,
        purchaseRequisitionService,
        bomService,
        bomFlowService,
        gradeService,
        godownService,
        machineService,
        batchItemGodownService,
        vendorService,
        switchShiftScheduleService,
        salaryRevisionService,
        dashboardService,
      },
      hooks: {
        useVoucher,
        useAccountGroups,
        useAccountGroup,
        useLedgers,
        useLedger,
        useCompanyGroupAdmin,
        useUserAdmin,
        useGetAllRoles,
        useCompanies,
        usePaginatedEmployeeCategories,
        useCurrencies,
        usePaginatedCurrencies,
        useMetrics,
        useItemCategories,
        useGsts,
        useDeliveryTerms,
        usePaginatedDeliveryTerms,
        usePaginatedMetrics,
        usePaginatedItemCategories,
        usePaginatedGST,
        usePaginatedPaymentTerms,
        usePaymentTerms,
        usePaginatedEmployeeDesignations,
        useCurrency,
        useItemCategory,
        useMetric,
        useGst,
        useDeliveryTerm,
        usePaymentTerm,
        useEmployeeDesignation,
        useEmployeeCategory,
        useAppearances,
        usePaginatedAppearances,
        useAppearance,
        useOdours,
        usePaginatedOdours,
        useOdour,
        useColor,
        usePaginatedColors,
        useColors,
        useCompanyAdmin,
        useFileTransfer,
        usePublicTransfer,
        useLocations,
        useLocationAdmin,
        useModules,
        useModuleAdmin,
        useResourceAdmin,
        useResourcesAdmin,
        useAuthorization,
        useAuthorizations,
        useRoleAdmin,
        useActions,
        useActionAdmin,
        useNotificationActions,
        useUsers,
        usePaginatedUsers,
        useNotificationAction,
        usePaginatedResourcesAdmin,
        usePaginatedAuthorizations,
        usePaginatedRoles,
        useNotificationConfigurators,
        useNotificationConfigurator,
        useHazardGhsClasses,
        useHazardGhsClass,
        useHazardStatements,
        useHazardStatement,
        useGhsPrecautionaryStatements,
        usePaginatedPrecautionaryStatements,
        useGhsPrecautionaryStatement,
        usePaginatedHazardGhsClasses,
        usePaginatedHazardStatement,
        useCompanyGroupSetting,
        useAttendanceDevices,
        useAttendanceDevice,
        useEmployeeCategories,
        useEmployeeDesignations,
        useShiftSchedule,
        useShiftSchedules,
        useGetMachineSchedule,
        useMachine,
        useLocationHoliday,
        useLocationHolidays,
        useTasks,
        useTask,
        useJobs,
        usePaginatedJobs,
        useTemplates,
        useTemplate,
        usePayrollReports,
        useSalaries,
        useSalary,
        usePayrollReport,
        useDaybooks,
        usePaginatedDaybook,
        useEmployeeProfiles,
        usePaginatedEmployeeProfiles,
        useDepartments,
        useDepartment,
        useUserAuthorization,
        useEmployeeRegistrations,
        useHrdDashboardReport,
        useAttendance,
        useAttendances,
        useCurrentUserRoles,
        useEmployeeProfile,
        useLeave,
        useLeaves,
        useGatePass,
        useGatePasses,
        useOvertime,
        useOvertimes,
        useOffSiteWorkHour,
        useOffSiteWorkHours,
        useRotationalShiftDay,
        useSalaryParams,
        useSalaryParam,
        useSalaryRevisions,
        useSalaryRevision,
        useMisconducts,
        useSalaryAdvances,
        useSalaryAdvance,
        usePremiums,
        useLoans,
        useRepaymentSchedules,
        useGodowns,
        useGodown,
        useRotationalShiftDays,
        useSwitchShift,
        useSwitchShifts,
        useLoan,
        useRepaymentSchedule,
        useDisbursementData,
        useDisbursementPostData,
        useAdvPaymentData,
        useSkipRepaymentData,
        useOnboardEmployee,
        useOnboardEmployees,
        useMisconduct,
        useRuleRequest,
        useMisconductRuleBooks,
        useRuleRequests,
        useMisconductRuleBook,
        usePolicy,
        usePolicies,
        usePremium,
        useEmployeeRegistration,
        useDocument,
        useDocuments,
        useEmployeeEducations,
        useEmployeeEducation,
        useEmployeeFamilies,
        useEmployeeFamily,
        useEmployeeLetters,
        useEmpShiftSchedule,
        useAttendanceMonthlySummary,
        useSalaryAdvanceEligibleAmt,
        useEmpShiftSchedules,
        useQuestionnaireSurveyAnswer,
        useQuestionnaireSurveyAnswers,
        useBatchItemGodowns,
        useItems,
        useClientItem,
        useVendorItem,
        useStockGroup,
        useStockGroups,
        useItem,
        usePaginatedItems,
        usePaginatedStockGroups,
        useGodownsTree,
        useBatchItemGodown,
        usePaginatedGodowns,
        usePaginatedBatchItemGodowns,
        useRevisions,
        usePaginatedRevisions,
        useRevision,
        useChangeAssesment,
        useChangeAssesments,
        useClosureDatePeriods,
        useClosureDatePeriod,
        useBomFlows,
        usePaginatedBomFlows,
        useBomFlow,
        useBoms,
        useGrades,
        usePurchaseOrders,
        usePurchaseEnquiries,
        usePaginatedPurchaseOrders,
        usePaginatedPurchaseEnquiries,
        useBom,
        usePhysicalStock,
        useVirtualStock,
        usePurchaseRequisition,
        useBatchGodownItemAllocate,
        useVirtualItemAllocate,
        useMRS,
        useGodownTransfer,
        usePaginatedBoms,
        usePurchaseRequisitions,
        usePaginatedPurchaseRequisitions,
        usePurchaseEnquiry,
        usePurchaseOrder,
        useBomProjects,
        usePaginatedBomProjects,
        useBomProject,
        useVendors,
        useVendor,
        usePaginatedVendors,
        useClients,
        useGrade,
        useBomDependencies,
        useBomDependency,
        useReport,
        useReports,
        useQuestionnaireTopics,
        useQuestionnaireTopic,
        useQuestionnaireQuestions,
        useQuestionnaireQuestion,
        usePurchaseOrderFulfilment,
        usePurchaseOrderFulfilments,
        useMachines,
        useManufacturingVouchers,
        useMachineSchedules,
        useManufacturingVoucher,
        usePaginatedManufacturingVouchers,
        useEnquiryQuote,
        useEnquiryQuotes,
        usePaginatedClients,
        useLeadCustomer,
        useLeadCustomers,
        useLeadEnquiries,
        useClient,
        usePaginatedLeadCustomers,
        useEnquiries,
        useVirtualMfStock,
        useVirtuallyReserveMfVoucher,
        useEnquiry,
        useSalesOrder,
        usePaginatedEnquiries,
        useLeadCustomerEnquiry,
        useLeadCustomerEnquiries,
        usePaginatedLeadCustomerEnquiries,
        useOrders,
        usePaginatedSalesOrders,
        useHsns,
        useHsn,
        usePaginatedHsns,
        useDashboards,
        useDashboard,
      },
    } = config;

    const AdminMasters = renderAdminMasters({
      useCompanyGroupAdmin,
      useUser: useUserAdmin,
      useRoles: useGetAllRoles,
      useCompanies,
      useCompanyAdmin,
      useFileTransfer,
      usePublicTransfer,
      useLocations,
      useLocationAdmin,
      useModules,
      useModuleAdmin,
      useResourceAdmin,
      useResourcesAdmin,
      useAuthorization,
      useGetAllRoles,
      useAuthorizations,
      useRoleAdmin,
      useActions,
      useActionAdmin,
      useNotificationActions,
      useUsers,
      usePaginatedUsers,
      useNotificationAction,
      usePaginatedResourcesAdmin,
      usePaginatedAuthorizations,
      usePaginatedRoles,
      useNotificationConfigurators,
      useNotificationConfigurator,
    });

    const CompanyDetails = renderCompanyDetails({
      hooks: {
        useCompanyAdmin: useCompanyAdmin,
        useFileTransfer,
        useCompanies,
        useLocationAdmin,
        useLocations,
      },
    });

    const LocationDetails = renderLocationDetails({
      hooks: {
        useLocationAdmin,
      },
    });

    const HazardRegulations = renderHazardRegulations({
      useHazardGhsClasses,
      useHazardGhsClass,
      useHazardStatements,
      useHazardStatement,
      useGhsPrecautionaryStatements,
      usePaginatedPrecautionaryStatements,
      useGhsPrecautionaryStatement,
      usePublicTransfer,
      usePaginatedHazardGhsClasses,
      usePaginatedHazardStatement,
    });

    const HazardClassPage = renderHazardClassPage({
      useHazardGhsClass,
      useHazardStatements,
      useHazardPrecautions: useGhsPrecautionaryStatements,
    });

    const CompanyGroupConfig = renderCompanyGroupConfig({
      useCompanyGroupSetting,
      companyGroupSettingService,
      useFileTransfer,
      useLocations,
      useAttendanceDevices,
      useAttendanceDevice,
      useEmployeeCategories,
      useEmployeeDesignations,
      useShiftSchedule,
      useShiftSchedules,
      useLocationHoliday,
      useLocationHolidays,
    });

    const Constants = renderConstants({
      useEmployeeCategories,
      usePaginatedEmployeeCategories,
      useCurrencies,
      usePaginatedCurrencies,
      useMetrics,
      useItemCategories,
      useGsts,
      useDeliveryTerms,
      usePaginatedDeliveryTerms,
      usePaginatedMetrics,
      usePaginatedItemCategories,
      usePaginatedGST,
      usePaginatedPaymentTerms,
      usePaymentTerms,
      useEmployeeDesignations,
      usePaginatedEmployeeDesignations,
      useCurrency,
      useItemCategory,
      useMetric,
      useGst,
      useDeliveryTerm,
      usePaymentTerm,
      useEmployeeDesignation,
      useEmployeeCategory,
      useAppearances,
      usePaginatedAppearances,
      useAppearance,
      useOdours,
      usePaginatedOdours,
      useOdour,
      useColor,
      usePaginatedColors,
      useColors,
      useHsn,
      useHsns,
      usePaginatedHsns,
    });

    const DatabaseSettings = renderDatabaseSettings({
      dashboardService,
    });

    const DashboardSettings = renderDashboardSettings({
      useDashboards,
      useDashboard,
      useRoles: useGetAllRoles,
    });
    const DashboardModule = renderDashboardModule({
      useDashboard,
      useDashboards,
      useRoles: useGetAllRoles,
    });

    const Tasks = renderSchedulerTask({
      useTasks,
      useTask,
      userRendererService,
    });

    const Jobs = renderSchedulerJob({
      useJobs,
      usePaginatedJobs,
      userRendererService,
    });

    const Template = renderTemplate({
      useTemplates,
      useTemplate,
      useFileTransfer,
      userRendererService,
    });

    // Accounts

    const TallyDayBook = renderTallyDaybook({
      useGodowns,
      itemService: itemV2Service,
      useVoucher,
      useLedgers,
      useVendor,
      useVendors,
      useClient,
      useClients,
      useItemCategories,
      useGrades,
      voucherService,
    });
    const BalanceSheet = renderBalanceSheet();
    const AccountSummary = renderAccountsSummary({
      useAccountGroups,
      useLedgers,
    });
    const ProfitNLoss = renderProfitNLoss();
    const GroupsPage = renderGroupTree({ useAccountGroups, useAccountGroup });
    const LedgersPage = renderLedgerTree({
      useAccountGroups,
      useLedgers,
      useLedger,
    });

    const BankSalaries = renderBankSalaries({
      usePayrollReports,
      useFileTransfer,
      useSalaries,
      useSalary,
      usePayrollReport,
    });

    const CashFlowReport = renderCashFlowDetails({
      useDepartments,
      useGrades,
    });

    const Daybook = renderDaybook({
      useDaybooks,
      usePaginatedDaybook,
      useUsers,
    });

    const Bill = renderBill({
      useDaybooks,
    });

    // HRD
    const EmployeeV2List = renderEmployeeProfileV2List({
      userRendererService,
      useEmployeeProfiles,
      usePaginatedEmployeeProfiles,
    });

    const EmployeeV2Profile = renderEmployeeV2Profile({
      leaveService,
      userRendererService,
      useRotationalShiftDays,
      useFileTransfer,
      useEmployeeProfiles,
      usePublicTransfer,
      useEmployeeProfile,
      useGatePass,
      useGatePasses,
      useEmployeeCategories,
      useUserAuthorization,
      useUsers,
      useShiftSchedules,
      useLeaves,
      useCompanies,
      useMisconducts,
      useMisconduct,
      useSalaryAdvances,
      useSalaryAdvance,
      useAttendanceMonthlySummary,
      useSalaryAdvanceEligibleAmt,
      usePolicy,
      usePolicies,
      usePremiums,
      useLoan,
      useLoans,
      useRepaymentSchedules,
      useSkipRepaymentData,
      useAdvPaymentData,
      useRepaymentSchedule,
      useSalaryParams,
      useEmployeeRegistrations,
      useCurrentUserRoles,
      useAttendances,
      useDepartments,
      useEmployeeRegistration,
      useDocument,
      useDocuments,
      useEmployeeEducations,
      useEmployeeEducation,
      useEmployeeFamilies,
      useEmployeeFamily,
      useEmployeeLetters,
      useEmpShiftSchedule,
      useEmpShiftSchedules,
    });

    const Holidays = renderLocationHolidaysList({
      useLocationHoliday,
      useLocationHolidays,
    });

    const AttendanceDevice = renderAttendanceDevices({
      mode: 'view',
      useAttendanceDevice,
      useAttendanceDevices,
      refreshData: () => {},
    });

    const Department = renderDepartmentMaster({ useEmployeeProfiles });

    const DepartmentList = renderDepartmentList({
      useDepartments,
      useDepartment,
      useEmployees: useEmployeeProfiles,
      useUserAuthorization,
      userRenderService: userRendererService,
    });

    const WorkLogReport = renderWorkLogReport({
      employeeProfileService,
      useEmployees: useEmployeeProfiles,
    });

    const NewAttendancePage = createNewAttendancePage({
      userRendererService,
      attendanceService,
      useAttendance,
      useAttendances,
      useCurrentUserRoles,
      useUserAuthorization,
      useEmployeeProfile,
      useEmployees: useEmployeeProfiles,
      useLeave,
      useLeaves,
      useGatePass,
      useOvertime,
      useOvertimes,
      useOffSiteWorkHour,
      leaveService,
      logRecordService,
      useShiftSchedules,
      useRotationalShiftDay,
    });

    const EmployeeAttendacePage = createEmployeeAttendancePage({
      useAttendances,
      useEmployees: useEmployeeProfiles,
      useAttendance,
      useUserAuthorization,
      useCurrentUserRoles,
      attendanceService,
      userRendererService,
      useEmployeeProfile,
      useGatePass,
      useLeave,
      useLeaves,
      useOffSiteWorkHour,
      useOvertime,
      useOvertimes,
      leaveService,
      logRecordService,
    });

    const EmployeePendingActionsPage = renderEmployeePendingActionsPage({
      attendanceService,
      userRendererService,
      leaveService,
      useUserAuthorization,
      useEmployeeProfile,
      useEmployees: useEmployeeProfiles,
      useLeaves,
      useGatePass,
      useOffSiteWorkHour,
      useOvertimes,
      useOvertime,
      useAttendance,
      useAttendances,
      useCurrentUserRoles,
      useLeave,
      logRecordService,
    });

    const SalaryPage = createSalaryPage({
      useSalaryAdvance,
      useEmployeeRegistrations,
      useHrdDashboardReport,
      usePayrollReport,
      useSalaryParams,
      useSalary: useSalary,
      useEmployees: useEmployeeProfiles,
      useUserAuthorization: useUserAuthorization,
      useSalaries: useSalaries,
      useSalaryRevisions: useSalaryRevisions,
      useAttendances,
      useFileTransfer,
      useCurrentUserRoles,
      useMisconducts,
      useSalaryAdvances,
      usePremiums,
      useLoans,
      useRepaymentSchedules,
      salaryService,
      useDepartments,
    });

    const SalaryRevisionPage = createSalaryRevisionPage({
      useEmployeeCategories,
      useCurrentUserRoles,
      useEmployees: useEmployeeProfiles,
      useUserAuthorization: useUserAuthorization,
      useSalaries: useSalaries,
      useSalaryRevisions: useSalaryRevisions,
      useSalaryRevision: useSalaryRevision,
      useSalaryParams,
      useMisconducts,
      useSalaryAdvances,
      usePremiums,
      useLoans,
      useRepaymentSchedules,
      userRendererService,
      salaryRevisionService,
    });

    const SalaryParametersPage = createSalaryParametersPage({
      useSalaryParams,
      useSalaryParam,
    });

    const SalaryParametersDetailsPage = createSalaryParametersDetailsPage({
      useEmployeeCategories,
      useSalaryParam,
      useFileTransfer,
    });

    const SalaryAdvancesPage = renderSalaryAdvances({
      useSalaryAdvance,
      useSalaryAdvances,
      useEmployees: useEmployeeProfiles,
      useUserAuthorization,
      userRendererService,
      useCompanies,
    });

    const AdhocPaymentsPage = renderAdhocPayments({
      useEmployees: useEmployeeProfiles,
      useEmployee: useEmployeeProfile,
    });

    const EmployeeLeavesBank = renderEmployeeLeaves({
      useEmployeeProfiles,
      useLeaves,
      useFileTransfer,
      useCurrentUserRoles,
      leaveService,
      userRendererService,
    });

    const EmployeeRefillLeavesBank = renderRefillLeavesBook({
      useEmployeeProfiles,
      useLeaves,
      leaveService,
    });

    const EmployeeMonthLeavesBook = renderEmployeeMonthLeavesBook({
      useLeaves,
      useFileTransfer,
      useEmployeeProfiles,
      useLeave,
      useUserAuthorization,
      leaveService,
      userRendererService,
    });

    const MonthGatePasses = renderMonthGatePasses({
      mode: 'month-book',
      useEmployee: useEmployeeProfile,
      useGatePass,
      useGatePasses,
      useEmployees: useEmployeeProfiles,
      useEmployeeProfiles,
      useUserAuthorization,
      useCurrentUserRoles,
      userRendererService,
    });

    const MonthOvertimes = renderMonthOvertimes({
      useUser: useEmployeeProfile,
      useOvertime,
      useOvertimes,
      useUsers: useEmployeeProfiles,
      useEmployeeProfiles,
      useUserAuthorization,
      useCurrentUserRoles,
      userRendererService,
      useHolidays: useLocationHolidays,
    });

    const OffSiteMonthWorkHours = renderOffSiteMonthWorkHours({
      useOffSiteWorkHour,
      useOffSiteWorkHours,
      useEmployeeProfiles,
      useUserAuthorization,
      userRendererService,
      useCurrentUserRoles,
    });

    const SchedulePage = renderWorkAreaPage({
      useGodowns,
      useEmployees: useEmployeeProfiles,
      useGodown,
    });

    const SchedulePage1 = renderWorkAreaV2Page({
      useGodowns,
    });

    const WorkAreaDetails = renderWorkAreaDetailsPage({
      useGodown,
      useGodowns,
      useBatchItemGodowns,
      useShiftSchedules,
      useEmployees: useEmployeeProfiles,
      useShifts: useRotationalShiftDays,
      useEmployee: useEmployeeProfile,
      useRotationalShiftDay,
    });

    const EmployeeShiftsPage = createEmployeeShiftsPage({
      useCurrentUserRoles,
      useEmployees: useEmployeeProfiles,
      useShiftSchedules,
      useGodowns,
      useOvertimes,
      useRotationalShiftDay,
      useOvertime,
      useEmployee: useEmployeeProfile,
      useRotationalShiftDays,
      useSwitchShift,
      useUserAuthorization,
      userRendererService,
    });

    const EmployeeSwitchShiftsPage = renderEmployeeSwitchShifts({
      useSwitchShifts,
      useUserAuthorization,
      useSwitchShift,
      useRotationalShiftDay,
      useEmployeeProfiles,
      userRendererService,
      switchShiftService: switchShiftScheduleService,
    });

    const MonthSchedule = renderMonthSchedule({
      useRotationalShiftDays,
      useGodowns,
    });

    const LoanManagement = renderLoanList({
      loansService,
      useLoan,
      useDisbursementData,
      useDisbursementPostData,
      useLoans,
      useRepaymentSchedules,
      useEmployees: useEmployeeProfiles,
      useCurrentUserRoles,
      useEmployeeProfiles,
      useUserAuthorization,
      userRendererService,
    });

    const LoanDetails = renderLoanDetails({
      useLoan,
      loanService: loansService,
    });

    const EmployeeOnboard = renderEmployeeOnboard({
      hooks: {
        useEmployeeProfiles,
        useEmployeeProfile,
        useCompanies,
        useDepartments,
        useLocations,
        useOnboardEmployee,
        useOnboardEmployees,
        useUserAuthorization,
        useEmployeeCategories,
      },
    });

    const EmployeeExit = renderEmployeeExit({
      hooks: {
        useEmployeeProfiles,
        useEmployeeProfile,
      },
    });

    const OffBoardingActions = renderOffBoardingActions({
      useEmployees: useEmployeeProfiles,
    });

    const DisciplinaryActions = renderMisconduct({
      useMisconducts,
      useMisconduct,
      useRuleRequest,
      useMisconductRuleBooks,
      useEmployees: useEmployeeProfiles,
      useUserAuthorization,
      useFileTransfer,
      useCurrentUserRoles,
      userRenderService: userRendererService,
    });

    const RuleRequests = renderRuleRequestDetails({
      useRuleRequests,
      useMisconductRuleBook,
      useRuleRequest,
      useMisconducts,
      useUserAuthorization,
      userRenderService: userRendererService,
    });

    const Insurance = renderPolicies({
      usePolicy,
      usePolicies,
      usePremium,
      useEmployees: useEmployeeProfiles,
      usePremiums,
      useCurrentUserRoles,
      useFileTransfer,
      useUserAuthorization,
      userRendererService,
    });

    const EmployeeJobTask = renderEmployeeJobTask({
      useEmployeeProfile,
      useQuestionnaireSurveyAnswers,
    });

    // Inventory Modules
    const ItemV2List = renderItemV2List({
      useUsers,
      useItems,
      useStockGroup,
      useStockGroups,
      useItem,
      usePaginatedItems,
      itemService: itemV2Service,
      useUserAuthorization,
      useItemCategories,
      userRendererService,
    });
    const StockGroupList = renderStockGroupList({
      useStockGroups,
      useStockGroup,
      useUserAuthorization,
      usePaginatedStockGroups,
      useUsers,
      userRendererService,
    });

    const InventoryTree = createInventoryTree({
      useGodownsTree,
      useBatchItem: useBatchItemGodown,
      useItems,
      useItem,
      useBatchItems: useBatchItemGodowns,
      useGodown,
      fileTransferService,
      useStockGroups,
    });

    const GodownsList = renderGodownList({
      useGodown,
      useGodownsTree,
      useGodowns,
      useUserAuthorization,
      usePaginatedGodowns,
      useUsers,
      userRendererService,
    });

    const ItemBatches = renderItemBatches({
      useBatchItemGodowns,
      useItems,
      useUsers,
      useGodowns,
      usePaginatedBatchItemGodowns,
      userRendererService,
    });

    const Revision = renderRevisionDetails({
      useRevisions,
      usePaginatedRevisions,
      useRevision,
      useChangeAssesment,
      revisionService,
      useUserAuthorization,
      useFileTransfer,
      userRendererService,
    });

    const RevisionDetails = renderRevisionProfile({ useRevisions });

    const GodownTransfer = renderGodownTransfer();

    const ItemRequisitionList = renderRequisitionList({
      hooks: {
        useGodowns,
        useUsers,
        useBatchItemGodowns,
        useBatchItemGodown,
        useBoms,
        useGrades,
        useBom,
        usePhysicalStock,
        useVirtualStock,
        usePurchaseRequisition,
        useMRS,
        useGodownTransfer,
        usePaginatedBoms,
        useBomProjects,
        usePaginatedBomProjects,
        useBomProject,
      },
      services: {
        itemV2Service: itemV2Service,
        purchaseRequisitionService,
        bomFlowService,
        gradeService,
      },
    });

    const BomProjectDetails = renderBomProjectDetails({
      useBomProject,
      useBomProjects,
      useUsers,
    });

    const BomFlow = renderBomFlow({
      useBomFlows,
      usePaginatedBomFlows,
      useBomFlow,
    });

    const StockItemSummary = renderStockItemSummary({
      useStockGroups,
      useItems,
      useGrades,
      useBatches: useBatchItemGodowns,
      useGodowns,
    });

    const ItemV2Profile = renderItemV2Profile({
      hooks: {
        useItems,
        useItem,
        useVendors,
        useClients,
        useGrade,
        useFileTransfer,
        usePhysicalStock,
        useHazardClass: useHazardGhsClass,
        useHazardClasses: useHazardGhsClasses,
        useHazardStatements,
        useGhsPrecautionaryStatements,
        useMetrics,
        useGsts,
        useAppearances,
        useColors,
        useOdours,
        useRevision,
        useUserAuthorization,
        useGrades,
        useEmployees: useEmployeeProfiles,
        useCurrentUserRoles,
        useRevisions,
        useRoles: useGetAllRoles,
        useUsers,
        useItemCategories,
      },
      services: { itemV2Service, stockGroupService },
    });

    const GradeDetails = renderGrade({
      useGrade,
      bomFlowService,
      useCurrentUserRoles,
      useGsts,
      useMetrics,
      useAppearances,
      useColors,
      useOdours,
      useRevisions,
      useEmployees: useEmployeeProfiles,
      gradeService,
      useRevision,
      useRoles: useGetAllRoles,
      useUsers,
      useFileTransfer,
    });

    const GodownProfile = renderGodownProfile({
      hooks: {
        useGodown,
        // useBatchItemGodowns,
        useGodowns,
        // useShiftSchedules,
        useEmployees: useEmployeeProfiles,
        useFileTransfer,
        // useEmployeeProfile,
        useUserAuthorization,
      },
      services: { godownService },
    });

    const StockGroupProfile = renderStockGroupProfile({
      hooks: {
        useStockGroup,
        useFileTransfer,
        useMetrics,
        useAppearances,
        useColors,
        useOdours,
        useGsts: useGsts,
        useEmployees: useEmployeeProfiles,
        useCurrentUserRoles,
        useRevision,
        useRevisions,
        useRoles: useGetAllRoles,
        useUsers,
      },
      services: { stockGroupService },
    });

    const BomDetails = renderBOMDetails({
      hooks: {
        useBom,
        usePhysicalStock,
        useVirtualStock,
        useBatchItemGodowns,
        useBatchGodownItemAllocate,
        usePurchaseOrders,
        useVirtualItemAllocate,
      },
    });

    const BomFlowDetails = renderBomFlowDetails({
      useBomFlow,
      useGrades,
      useBomDependencies,
      useBomDependency,
    });

    // Miscellaneous

    const ReportList = renderReportList({
      useReport,
      useReports,
      userRendererService,
    });

    const QuestionnaireTopic = renderTopicForm({
      useQuestionnaireTopics,
      useQuestionnaireTopic,
      useQuestionnaireQuestions,
      useQuestionnaireSurveyAnswer,
      userRendererService,
    });

    const ReportBuilder = renderStatutoryReportBuilder({ useReport });

    const Questions = renderQuestionForm({
      useQuestionnaireQuestions,
      useQuestionnaireQuestion,
      userRendererService,
      useQuestionnaireTopic,
    });

    const SurveyAnswersPage = renderSurveyAnswersPage({
      useQuestionnaireQuestions,
      useQuestionnaireSurveyAnswer,
    });

    const SubmittedAnswersPage = renderSubmittedAnswers({
      useQuestionnaireSurveyAnswer,
      useQuestionnaireQuestions,
    });

    // Production
    const MachineSchedule = renderMachineSchedule({ useGetMachineSchedule });

    const MachineList = renderMachinesList({
      hooks: { useMachines, useShiftSchedules, useMachine },
    });

    const Plan = renderPlan({
      hooks: {
        useMachines,
        useManufacturingVouchers,
        useMachineSchedules,
        useManufacturingVoucher,
      },
    });

    const ManufacturingVoucherList = createManufacturingVoucherList({
      hooks: {
        useManufacturingVouchers,
        useItems,
        useUsers,
        useBoms,
        useGodownTransfer,
        usePaginatedManufacturingVouchers,
      },
      services: {
        machineService,
        batchItemService: batchItemGodownService,
      },
    });

    const Machine = renderMachine({ hooks: { useGetMachineSchedule } });

    const ManufacturingVoucherDetails = renderManufacturingVoucherDetails({
      hooks: { useManufacturingVoucher },
    });

    // Purchase
    const VendorList = renderVendorList({
      useVendors,
      useVendor,
      usePaginatedVendors,
      useUsers,
      userRendererService,
    });

    const VendorGodowns = renderVendorGodowns({
      useLocations,
      useGodowns,
    });

    const VendorV2Profile = renderVendorV2Profile({
      useBatchItemGodowns,
      useVendor,
      useFileTransfer,
      usePublicTransfer,
      useItems,
      useVendorItem,
      useUsers,
      useUserAuthorization,
      useItem,
      useStockGroup,
      useDeliveryTerms,
      usePaymentTerms,
      itemService: itemV2Service,
      useGodowns,
    });

    const PurchaseRequisitions = renderPurchaseRequisitions({
      purchaseOrderService,
      purchaseEnquiryService,
      useGrade,
      useUsers,
      usePurchaseRequisition,
      usePurchaseRequisitions,
      usePaginatedPurchaseRequisitions,
      usePurchaseEnquiry,
      usePurchaseOrder,
      useVendor,
      useVendors,
      useDeliveryTerms,
      usePaymentTerms,
      useBom,
      useVirtualItemAllocate,
      bomService,
      usePurchaseOrders,
      usePurchaseEnquiries,
      usePaginatedPurchaseOrders,
      usePaginatedPurchaseEnquiries,
      vendorService,
      useUserAuthorization,
      useFileTransfer,
      itemService: itemV2Service,
      useItem,
      userRendererService,
    });

    const PurchaseOrderDetails = renderPurchaseOrderDetails({
      hooks: {
        usePurchaseOrderFulfilment,
        usePurchaseOrderFulfilments,
        usePurchaseOrder,
        usePurchaseOrders,
        useBoms,
        useItem,
        useBatchItemGodown,
        useUsers,
        useFileTransfer,
        useQuestionnaireQuestions,
        useTemplate,
      },
      services: {
        itemService: itemV2Service,
      },
    });

    const PurchaseItemInspection = renderPurchaseItemInspection({
      usePurchaseOrderFulfilment,
      usePurchaseOrderFulfilments,
      useQuestionnaireQuestions,
      itemService: itemV2Service,
      useTemplate,
      useFileTransfer,
    });

    const GoodsReceived = renderGoodsReceived({
      useUsers,
      usePurchaseOrderFulfilment,
      usePurchaseOrderFulfilments,
      useQuestionnaireQuestions,
      itemService: itemV2Service,
      useTemplate,
      useFileTransfer,
    });

    const ItemTransfer = () => {
      return (
        <PurchaseItemTransfer
          usePurchaseOrderFulfilments={usePurchaseOrderFulfilments}
        />
      );
    };

    const PRDetails = renderPRDetails({
      services: { purchaseRequisitionService },
      hooks: {
        usePurchaseRequisition,
        useBom,
      },
    });

    const VendorItemSpec = renderVendorItemSpec({
      itemService: itemV2Service,
      useGsts,
      useMetrics,
      useVendors,
      useVendor,
    });

    const EnquiryDetails = renderEnquiryDetails({
      hooks: {
        useEnquiryQuote,
        useEnquiryQuotes,
        usePurchaseOrder,
        useUserAuthorization,
        useVirtualItemAllocate,
        usePurchaseEnquiry,
        useFileTransfer,
        useBom,
        useItem,
        useStockGroup,
        usePurchaseOrders,
        usePurchaseRequisition,
      },
    });

    // Quality Assurance
    const ChangeControlList = renderChangeControlReqDetails({
      useChangeAssesments,
      useClosureDatePeriods,
      useChangeAssesment,
      useDepartments,
      useUserAuthorization,
      useEmployees: useEmployeeProfiles,
      useFileTransfer,
    });

    const ClosureDatePeriod = renderClosureDatePeriod({
      useClosureDatePeriods,
      useClosureDatePeriod,
      useUserAuthorization,
      userRendererService,
    });

    const RequisitionDtls = renderRequisitionDetails({ useChangeAssesments });

    // Sales
    const ClientList = renderClientList({
      useClients,
      useUsers,
      usePaginatedClients,
      useLeadCustomer,
      useLeadCustomers,
      useClient,
      userRendererService,
    });

    const ClientGodowns = renderClientGodowns({
      useLocations,
      useGodowns,
    });

    const LeadCustomerList = renderLeadCustomerList({
      useUsers,
      useLeadCustomer,
      useLeadCustomers,
      usePaginatedLeadCustomers,
      userRendererService,
    });

    const SalesEnquiryTabs = renderSalesMasters({
      useEnquiries,
      useVirtualMfStock,
      useVirtuallyReserveMfVoucher,
      useEnquiry,
      useOrder: useSalesOrder,
      usePaginatedEnquiries,
      useLeadCustomerEnquiry,
      useLeadCustomerEnquiries,
      usePaginatedLeadCustomerEnquiries,
      usePhysicalStock,
      useBatchItemGodowns,
      useBatchGodownItemAllocate,
      useManufacturingVouchers,
      useItems,
      useClient,
      useClients,
      useUsers,
      useEmployeeProfiles,
      useMetrics,
      useLeadCustomer,
      useLeadCustomers,
      userRendererService,
      useLeadEnquiries,
    });

    const SalesOrderTabs = renderSalesOrderTabs({
      hooks: {
        useItems,
        useClients,
        useSalesOrder: useSalesOrder,
        useSalesOrders: useOrders,
        usePaginatedSalesOrders,
        usePhysicalStock,
        useBatchGodownItemAllocate,
        useBatchItemGodowns,
        useVirtualMfStock,
        useManufacturingVouchers,
        useVirtuallyReserveMfVoucher,
        useUsers,
        userRendererService,
      },
    });

    const SalesEnquiryDetailsV2 = renderSalesEnquiryDetailsV2({
      hooks: {
        useSalesEnquiry: useEnquiry,
      },
    });

    const ClientV2Profile = renderClientV2Profile({
      useGodowns,
      useClient,
      useClients,
      useSalesOrders: useOrders,
      usePaginatedSalesOrders,
      useUsers,
      useItems,
      useGrades,
      useClientItem,
      useUserAuthorization,
      usePaymentTerms,
      useDeliveryTerms,
      useFileTransfer,
      usePublicTransfer,
      itemService: itemV2Service,
      userRendererService,
    });

    const LeadCustomerProfile = renderLeadCustomerProfile({
      useLeadCustomer,
      useClient,
      useFileTransfer,
      usePublicTransfer,
    });

    const LeadCustomerEnquirDetails = renderLeadCustomerEnquiryDetails({
      useLeadCustomerEnquiry,
      useItems,
      useEmployeeProfiles,
      useLeadCustomers,
      useLeadCustomer,
      useMetrics,
      useClient,
      useClients,
      useClientItem,
      itemService: itemV2Service,
    });

    const SalesOrderDetailsV2 = renderSalesOrderDetailsV2({
      hooks: {
        useSalesOrder: useSalesOrder,
      },
    });

    const SalesItemEnquiryDetails = renderSalesItemEnquiryDetails({
      useEnquiries,
      usePhysicalStock,
      useBatchItemGodowns,
      useBatchGodownItemAllocate,
      useVirtualMfStock,
      useManufacturingVouchers,
      useVirtuallyReserveMfVoucher,
    });

    const SalesItemOrderDetails = renderSalesItemOrderDetails({
      useSalesOrders: useOrders,
      usePhysicalStock,
      useBatchGodownItemAllocate,
      useBatchItemGodowns,
      useVirtualMfStock,
      useManufacturingVouchers,
      useVirtuallyReserveMfVoucher,
    });

    const SalesManufacturingEnquiryDetails = renderSalesManufacturingEnquiryDetails();

    const SalesManufacturingOrderDetails = renderSalesManufacturingOrderDetails();

    const SalesQcEnquiryDetails = renderSalesQcEnquiryDetails();

    const SalesQcOrderDetails = renderSalesQcOrderDetails();

    const SalesEnquiryItemsDetailsV2 = renderSalesEnquiryItemsDetailsV2({
      hooks: {
        useSalesEnquiry: useEnquiry,
      },
    });

    // Users
    const UserProfile = renderUserProfile({ useEmployeeProfile });

    this.modules = [
      // {
      //   name: 'Login',
      //   icon: LuLogIn,
      //   path: '/login',
      //   sidebarLinks: [],
      //   content: this.LoginPage,
      //   hidden: true,
      // },
      {
        name: 'System',
        icon: ComputerDesktopIcon,
        path: '/system',
        sidebarLinks: [
          {
            name: 'Administration',
            icon: () => <Account className='w-5 inline stroke-0' />,
            path: '/system/admin',
            type: 'menu',
            auth: 'UI:ADM:VIEW',
            menu: [
              {
                name: 'Company Group',
                path: '/system/admin/group',
                auth: 'UI:RECAT-ADM:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Company',
                path: '/system/admin/company',
                auth: 'UI:ADM:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Company',
                path: '/system/admin/company/:id',
                auth: 'UI:ADM:VIEW',
                hidden: true,
                content: CompanyDetails,
              },
              {
                name: 'Company',
                path: '/system/admin/company/:id/company-profile',
                auth: 'UI:ADM:VIEW',
                hidden: true,
                content: CompanyDetails,
              },
              {
                name: 'Company',
                path: '/system/admin/company/:id/registration',
                auth: 'UI:ADM:VIEW',
                hidden: true,
                content: CompanyDetails,
              },
              {
                name: 'Company',
                path: '/system/admin/company/:id/history',
                auth: 'UI:ADM:VIEW',
                hidden: true,
                content: CompanyDetails,
              },
              {
                name: 'Company',
                path: '/system/admin/company/:id/location',
                auth: 'UI:ADM:VIEW',
                hidden: true,
                content: CompanyDetails,
              },
              {
                name: 'Location',
                path: '/system/admin/location',
                auth: 'UI:ADM:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Location',
                hidden: true,
                path: '/system/admin/location/profile/:id/location-profile',
                auth: 'UI:ADM:VIEW',
                content: LocationDetails,
              },
              {
                name: 'Module',
                path: '/system/admin/module',
                auth: 'UI:PGMODULE:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Resource',
                path: '/system/admin/resource',
                auth: 'UI:PGRESOURCE:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Authorization',
                path: '/system/admin/authorization',
                auth: 'UI:PGAUTHORIZATION:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Action',
                path: '/system/admin/action',
                content: AdminMasters,
                auth: 'UI:PGACTION:VIEW',
              },
              {
                name: 'Role',
                path: '/system/admin/role',
                content: AdminMasters,
                auth: 'UI:PGUSERS:VIEW',
              },
              {
                name: 'Users',
                path: '/system/admin/user',
                content: AdminMasters,
                auth: 'UI:PGUSERS:VIEW',
              },
              {
                name: 'Notification',
                path: '/system/admin/notification',
                auth: 'UI:PGAUTHORIZATION:VIEW',
                content: AdminMasters,
              },
              {
                name: 'Notification-v2',
                path: '/system/admin/notification-v2',
                auth: 'UI:PGAUTHORIZATION:VIEW',
                content: AdminMasters,
              },
              // {
              //   name: 'Users',
              //   type: 'path',
              //   path: '/users-master',
              //   component: component['UserAdmin'],
              //   auth: 'UI:PGUSERS:VIEW',
              //   permissions: []
              // },
            ],
          },
          {
            name: 'Group Setting',
            icon: CubeTransparentIcon as () => JSX.Element,
            auth: 'UI:ADM:VIEW',
            type: 'menu',
            path: '/system/group-settings',
            menu: [
              {
                name: 'Hazard Regulations',
                path:
                  '/system/group-settings/hazard-regulations/ghs-class-category',
                auth: '',
                content: HazardRegulations,
              },
              {
                name: 'Hazard Regulations',
                path:
                  '/system/group-settings/hazard-regulations/hazard-class/:id',
                auth: '',
                hidden: true,
                content: HazardClassPage,
              },
              {
                name: 'Hazard Regulations',
                path:
                  '/system/group-settings/hazard-regulations/ghs-class-category',
                auth: '',
                hidden: true,
                content: HazardRegulations,
              },
              {
                name: 'Hazard Regulations',
                path:
                  '/system/group-settings/hazard-regulations/ghs-class-category',
                auth: '',
                hidden: true,
                content: HazardRegulations,
              },
              {
                name: 'Hazard Regulations',
                path:
                  '/system/group-settings/hazard-regulations/ghs-class-category',
                auth: '',
                hidden: true,
                content: HazardRegulations,
              },
              {
                name: 'Config',
                path: '/system/group-settings/config/inventory',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/inventory-templates',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/work-order',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/quality-assurance',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/purchase',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/sales',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/company-group',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/hrd',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Config',
                hidden: true,
                path: '/system/group-settings/config/history',
                content: CompanyGroupConfig,
                auth: '',
              },
              {
                name: 'Constants',
                path: '/system/group-settings/constants',
                content: Constants,
                auth: '',
              },
              {
                name: 'Database Settings',
                path: '/system/group-settings/database',
                content: DatabaseSettings,
                auth: 'UI:RECAT-ADM:VIEW',
              },
              {
                name: 'Dashboard',
                path: '/system/group-settings/dashboard',
                content: DashboardSettings,
                auth: '',
              },
              {
                name: 'Dashboard Module',
                path: '/system/group-settings/dashboard/module/:module',
                content: DashboardModule,
                hidden: true,
              },
            ],
          },
          {
            name: 'Scheduler',
            icon: () => <History className='w-5 inline stroke-0' />, // as () => JSX.Element,
            auth: 'UI:TSK-SCH:VIEW',
            type: 'menu',
            path: '/system/scheduler',
            menu: [
              {
                name: 'Task',
                path: '/system/scheduler/tasks',
                auth: 'UI:TSK-SCH:VIEW',
                content: Tasks,
              },
              {
                name: 'Task Jobs',
                path: '/system/scheduler/tasks/:id/jobs',
                auth: 'UI:TSK-SCH:VIEW',
                hidden: true,
                content: Jobs,
              },
            ],
          },
          {
            name: 'Email',
            icon: () => <Mail className='w-5 inline stroke-0' />, // as () => JSX.Element,
            auth: 'UI:DES-TEMP:VIEW',
            type: 'menu',
            path: '/system/emails',
            menu: [
              {
                name: 'Template',
                path: '/system/emails/template',
                auth: 'UI:DES-TEMP:VIEW',
                content: Template,
              },
            ],
          },
        ],
      },
      {
        name: 'Accounts',
        icon: BookOpenIcon,
        path: '/accounts',
        sidebarLinks: [
          {
            name: 'Tally Masters',
            path: '/accounts/tally-masters',
            icon: BookOpenIcon,
            type: 'menu',
            menu: [
              {
                name: 'Daybook',
                path: '/accounts/tally-masters/daybook',
                content: TallyDayBook,
              },
              {
                name: 'Groups',
                path: '/accounts/tally-masters/groups',
                content: GroupsPage,
              },
              {
                name: 'Ledgers',
                path: '/accounts/tally-masters/ledgers',
                content: LedgersPage,
              },
            ],
          },
          {
            name: 'Tally Reports',
            path: '/accounts/tally-reports',
            icon: BookOpenIcon,
            type: 'menu',
            menu: [
              {
                name: 'Account-Summary',
                path: '/accounts/tally-reports/account-summary',
                content: AccountSummary,
              },
              {
                name: 'Balance-Sheet',
                path: '/accounts/tally-reports/balance-sheets',
                content: BalanceSheet,
              },
              {
                name: 'Profit-and-Loss',
                path: '/accounts/tally-reports/profit-n-loss',
                content: ProfitNLoss,
              },
            ],
          },
          {
            name: 'Disbursements',
            path: '/accounts/disbursements',
            icon: ArrowRightOnRectangleIcon,
            type: 'menu',
            auth: 'UI:SAL-REP:VIEW',
            menu: [
              {
                name: 'Bank-Salary-Details',
                path: '/accounts/disbursements/bank-salary-reports',
                auth: 'UI:SAL-REP:VIEW',
                content: BankSalaries,
              },
            ],
          },
          {
            name: 'Cashflow',
            path: '/accounts/cashflow',
            icon: CurrencyRupeeIcon,
            type: 'menu',
            auth: 'UI:CSH-FLW:VIEW',
            menu: [
              {
                name: 'Cashflow',
                path: '/accounts/cashflow/cashflow-details',
                auth: 'UI:CSH-FLW:VIEW',
                content: CashFlowReport,
              },
            ],
          },
          {
            name: 'Activities',
            path: '/accounts/activities',
            icon: CheckIcon,
            type: 'menu',
            auth: 'UI:ACC-ACT:VIEW',
            menu: [
              {
                name: 'Daybook',
                path: '/accounts/activities/daybook',
                auth: 'UI:ACC-ACT:VIEW',
                content: Daybook,
              },
            ],
          },
          {
            name: 'Ledgers',
            path: '/accounts/ledgers',
            icon: BookOpenIcon,
            type: 'menu',
            auth: 'UI:ACC-LEG:VIEW',
            menu: [
              {
                name: 'Bills',
                path: '/accounts/ledgers/bills',
                auth: 'UI:ACC-LEG:VIEW',
                content: Bill,
              },
              {
                name: 'Party',
                path: '/accounts/ledgers/party',
                auth: 'UI:ACC-LEG:VIEW',
                content: UnderConstruction,
              },
            ],
          },
          {
            name: 'Reports',
            path: '/accounts/reports',
            icon: DocumentIcon,
            type: 'menu',
            auth: 'UI:ACC-REP:VIEW',
            menu: [],
          },
        ],
      },
      {
        name: 'HRD',
        icon: UserCircleIcon,
        path: '/hrd',
        sidebarLinks: [
          {
            name: 'Resources',
            path: '/hrd/resources',
            icon: CircleStackIcon,
            type: 'menu',
            auth: 'UI:EMP-LIST:VIEW',
            menu: [
              {
                name: 'Employees',
                path: '/hrd/resources/employees',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2List,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/profile',
                auth: '', // Skipping as it is disabling self profile
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/job-profile',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/letters',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/disciplinary-actions',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/leave',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/attendance',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/history',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/loan-advance',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Employees',
                hidden: true,
                path: '/hrd/resources/employees/:id/authorisers',
                auth: 'UI:EMP-LIST:VIEW',
                content: EmployeeV2Profile,
              },
              {
                name: 'Holidays',
                path: '/hrd/resources/holidays',
                auth: 'UI:LOC-HOL:VIEW',
                content: Holidays,
              },
              {
                name: 'Attendance Devices',
                path: '/hrd/resources/attendance-devices',
                auth: 'UI:LOC-HOL:VIEW',
                content: () => <AttendanceDevice cgsDetails={{}} />,
              },
            ],
          },
          {
            name: 'Department',
            path: '/hrd/department',
            icon: BuildingOfficeIcon,
            type: 'menu',
            auth: 'UI:DEPT-LIST:VIEW',
            menu: [
              {
                name: 'Department (Old)',
                path: '/hrd/department/old-list',
                auth: 'UI:DEPT-LIST:VIEW',
                content: Department,
              },
              {
                name: 'Department List',
                path: '/hrd/department/list',
                auth: 'UI:DEPT-LIST:VIEW',
                content: DepartmentList,
              },
              {
                name: 'WorkLog Report',
                path: '/hrd/department/workLog-report',
                auth: 'UI:WRK-LOG:VIEW',
                content: WorkLogReport,
              },
              {
                name: 'Employee Job Task',
                hidden: true,
                path: '/hrd/department/emp-job-task/:id',
                auth: 'UI:WRK-LOG:VIEW',
                content: EmployeeJobTask,
              },
            ],
          },
          {
            name: 'Attendance',
            path: '/hrd/attendance',
            icon: FingerPrintIcon,
            type: 'menu',
            auth: 'UI:ATTEN:VIEW',
            menu: [
              {
                name: 'Day-Book',
                path: '/hrd/attendance/day-book',
                auth: 'UI:ATTEN:VIEW',
                content: NewAttendancePage,
              },
              {
                name: 'Employee-Book',
                path: '/hrd/attendance/employee-attendance',
                auth: 'UI:ATTEN-EBOOK:VIEW',
                content: EmployeeAttendacePage,
              },
              {
                name: 'Pending-Actions',
                path: '/hrd/attendance/employee-pending-actions',
                auth: 'UI:ATTEN:VIEW',
                content: EmployeePendingActionsPage,
              },
            ],
          },
          {
            name: 'Payroll',
            path: '/hrd/payroll',
            icon: CurrencyRupeeIcon,
            type: 'menu',
            auth: 'UI:SAL:VIEW',
            menu: [
              {
                name: 'Salary-Book',
                path: '/hrd/payroll/salary-book',
                auth: 'UI:SAL:VIEW',
                content: SalaryPage,
              },
              {
                name: 'Salary-Revisions',
                path: '/hrd/payroll/salary-revisions',
                auth: 'UI:SAL-REV:VIEW',
                content: SalaryRevisionPage,
              },
              {
                name: 'Salary-Parameters',
                path: '/hrd/payroll/salary-parameters',
                auth: 'UI:SAL-PAR:VIEW',
                content: SalaryParametersPage,
              },
              {
                name: 'Salary-Parameters',
                hidden: true,
                path: '/hrd/payroll/salary-parameters/:id',
                auth: 'UI:SAL-PAR:VIEW',
                content: SalaryParametersDetailsPage,
              },
              {
                name: 'Salary-Advances',
                path: '/hrd/payroll/salary-advances',
                auth: 'UI:LON-ADV:VIEW',
                content: SalaryAdvancesPage,
              },
              {
                name: 'Adhoc-Payments',
                path: '/hrd/payroll/adhoc-payments',
                auth: 'UI:ADHC-PYMT:VIEW',
                content: AdhocPaymentsPage,
              },
            ],
          },
          {
            name: 'Leaves',
            path: '/hrd/leaves',
            icon: CalendarIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Leaves-Bank',
                path: '/hrd/leaves/leaves-bank',
                auth: 'UI:LEAVE-BANK:VIEW',
                content: EmployeeLeavesBank,
              },
              {
                name: 'Refill-Leaves-Bank',
                path: '/hrd/leaves/refill-leaves-bank',
                auth: 'UI:REFILL-LEAVE-BANK:VIEW',
                content: EmployeeRefillLeavesBank,
              },
              {
                name: 'Leaves-Book',
                path: '/hrd/leaves/leaves-book',
                auth: 'UI:LEAVE:VIEW',
                content: EmployeeMonthLeavesBook,
              },
              {
                name: 'Gate-Passes',
                path: '/hrd/leaves/gate-passes',
                auth: 'UI:GATEP:VIEW',
                content: MonthGatePasses,
              },
              {
                name: 'Overtimes',
                path: '/hrd/leaves/overtimes',
                auth: 'UI:OVERT:VIEW',
                content: MonthOvertimes,
              },
              {
                name: 'Off-site Work-hours',
                path: '/hrd/leaves/off-site-work-hours',
                auth: 'UI:OFFSITEWORK:VIEW',
                content: OffSiteMonthWorkHours,
              },
            ],
          },
          {
            name: 'Shift Management',
            path: '/hrd/shift-management',
            icon: ClockIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Employee-Shifts',
                path: '/hrd/shift-management/employee-shifts',
                auth: 'UI:EMP-SHFTS:VIEW',
                content: EmployeeShiftsPage,
              },
              {
                name: 'Switch-Shifts',
                path: '/hrd/shift-management/employee-switch-shifts',
                auth: 'UI:EMP-SHFT-SCH:VIEW',
                content: EmployeeSwitchShiftsPage,
              },
              // {
              //   name: 'Employees Shift-Schedule-List',
              //   type: 'path',
              //   auth: 'UI:EMP-SHFT-SCH:VIEW',
              //   path: '/employee-shift-schedule',
              //   component: component['EmpShiftScheduleList'],
              //   permissions: []
              // },
              {
                name: 'Month-Schedule',
                path: '/hrd/shift-management/month-schedule',
                auth: 'UI:MON-SHFT-SCH:VIEW',
                content: MonthSchedule,
              },
              {
                name: 'Work-Areas',
                path: '/hrd/shift-management/schedule-page-v2',
                auth: 'UI:WORK-AREA:VIEW',
                content: SchedulePage1,
              },
              {
                name: 'Work-Areas',
                hidden: true,
                path: '/hrd/shift-management/work-area/:id',
                auth: 'UI:WORK-AREA:VIEW',
                content: WorkAreaDetails,
              },
              {
                name: 'WA-Allocator',
                path: '/hrd/shift-management/schedule-page',
                auth: 'UI:WA-ALLOC:VIEW',
                content: SchedulePage,
              },
              // {
              //   name: 'Schedule Allocator',
              //   type: 'path',
              //   auth: 'UI:EMP-SHFT-SCH:VIEW',
              //   path: '/schedule-allocator',
              //   component: component['ScheduleAllocatorPage'],
              //   permissions: []
              // },
              // {
              //   name: 'Schedule Allocator',
              //   type: 'path',
              //   auth: 'UI:EMP-SHFT-SCH:VIEW',
              //   path: '/schedule-allocator-1',
              //   component: component['ScheduleAllocatorPage1'],
              //   permissions: [],
              // },
            ],
          },
          {
            name: 'Loan Management',
            path: '/hrd/loan-management',
            icon: CreditCardIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Loans',
                path: '/hrd/loan-management/loans',
                auth: 'UI:LOANS-LIST:VIEW',
                content: LoanManagement,
              },
              {
                name: 'Loans',
                path: '/hrd/loan-management/loans/:id',
                hidden: true,
                auth: 'UI:LOANS-LIST:VIEW',
                content: LoanDetails,
              },
            ],
          },
          {
            name: 'Employee Onboard',
            path: '/hrd/employee-onboard',
            icon: ArrowLeftOnRectangleIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Employee Onboard',
                path: '/hrd/employee-onboard/employee-onboard',
                auth: 'UI:EMP-ONBD:VIEW',
                content: EmployeeOnboard,
              },
            ],
          },
          {
            name: 'Employee Exit',
            path: '/hrd/employee-exit',
            icon: ArrowRightOnRectangleIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Exit Date And Reason',
                path: '/hrd/employee-exit/exit-date-and-reason',
                auth: 'UI:EMP-EXIT:VIEW',
                content: EmployeeExit,
              },
              {
                name: 'Off-Boarding Actions',
                path: '/hrd/employee-exit/off-boarding-actions',
                auth: 'UI:EMP-EXIT:VIEW',
                content: OffBoardingActions,
              },
            ],
          },
          {
            name: 'Disciplinary Actions',
            path: '/hrd/disciplinary-actions',
            icon: ScaleIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Misconduct',
                path: '/hrd/disciplinary-actions/misconduct',
                auth: 'UI:DIS-ACT:VIEW',
                content: DisciplinaryActions,
              },
              {
                name: 'Rule Request',
                path: '/hrd/disciplinary-actions/rule-request',
                auth: 'UI:DIS-ACT:VIEW',
                content: RuleRequests,
              },
            ],
          },
          {
            name: 'Insurance',
            path: '/hrd/insurance',
            icon: ShieldCheckIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Policies',
                path: '/hrd/insurance/policies',
                auth: 'UI:INS-POL:VIEW',
                content: Insurance,
              },
            ],
          },
        ],
      },
      {
        name: 'Inventory',
        icon: InboxStackIcon,
        path: '/inventory',
        sidebarLinks: [
          {
            name: 'Masters',
            path: '/inventory/masters',
            icon: InboxStackIcon,
            type: 'menu',
            auth: 'UI:INV-MGMT:VIEW',
            menu: [
              {
                name: 'Items',
                path: '/inventory/masters/items',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2List,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/properties',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/grades',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/specifications',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/label',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/stp',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/haz-reg-msds',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/end-use',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/packing',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/bom',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/shift-config',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/batches',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/stock',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/vendors-customers',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/vendors',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/customers',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/authorizers',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Items',
                hidden: true,
                path: '/inventory/masters/items/:id/history',
                auth: 'UI:INV-LIST:VIEW',
                content: ItemV2Profile,
              },
              {
                name: 'Item-Grade',
                hidden: true,
                path: '/inventory/masters/items/:id/grade/:id/properties',
                auth: 'UI:INV-LIST:VIEW',
                content: GradeDetails,
              },
              {
                name: 'Item-Grade',
                hidden: true,
                path: '/inventory/masters/items/:id/grade/:id/bom-charts',
                auth: 'UI:INV-LIST:VIEW',
                content: GradeDetails,
              },
              {
                name: 'StockGroups',
                path: '/inventory/masters/stock-groups',
                auth: 'UI:SG-LIST:VIEW',
                content: StockGroupList,
              },
              {
                name: 'StockGroups',
                hidden: true,
                path: '/inventory/masters/stock-groups/:id/profile',
                auth: 'UI:SG-LIST:VIEW',
                content: StockGroupProfile,
              },
              {
                name: 'Godowns',
                path: '/inventory/masters/godowns',
                auth: 'UI:GDN-LIST:VIEW',
                content: GodownsList,
              },
              {
                name: 'Godowns',
                hidden: true,
                path: '/inventory/masters/godowns/:id/profile',
                auth: 'UI:GDN-LIST:VIEW',
                content: GodownProfile,
              },
              {
                name: 'Godowns',
                hidden: true,
                path: '/inventory/masters/godowns/:id/authorisers',
                auth: 'UI:GDN-LIST:VIEW',
                content: GodownProfile,
              },
              {
                name: 'Godowns',
                hidden: true,
                path: '/inventory/masters/godowns/:id/shift-config',
                auth: 'UI:GDN-LIST:VIEW',
                content: GodownProfile,
              },
              {
                name: 'Godowns',
                hidden: true,
                path: '/inventory/masters/godowns/:id/history',
                auth: 'UI:GDN-LIST:VIEW',
                content: GodownProfile,
              },
              {
                name: 'Tree',
                path: '/inventory/masters/tree',
                auth: 'UI:INV-TREE:VIEW',
                content: InventoryTree,
              },
              {
                name: 'Item Batches',
                path: '/inventory/masters/item-batches',
                auth: 'UI:ITM-BATCH:VIEW',
                content: ItemBatches,
              },
              {
                name: 'Revisions',
                path: '/inventory/masters/revisions',
                auth: 'UI:INV-LIST:VIEW',
                content: Revision,
              },
              {
                name: 'Revisions',
                hidden: true,
                path: '/inventory/masters/revisions/:id',
                auth: 'UI:INV-LIST:VIEW',
                content: RevisionDetails,
              },
            ],
          },
          {
            name: 'Operations',
            path: '/inventory/operations',
            icon: PaperAirplaneIcon,
            type: 'menu',
            auth: 'UI:INV-MGMT:VIEW',
            menu: [
              {
                name: 'Godown Transfers',
                path: '/inventory/operations/godown-transfer',
                auth: 'UI:GDN-TRANS:VIEW',
                content: GodownTransfer,
              },
            ],
          },
          {
            name: 'Registers',
            path: '/inventory/registers',
            icon: ClipboardDocumentListIcon,
            type: 'menu',
            auth: 'UI:INV-REQ:VIEW',
            menu: [
              {
                name: 'BOM Project Register',
                path: '/inventory/registers/bom-project-register',
                auth: 'UI:INV-REQ:VIEW',
                content: ItemRequisitionList,
              },
              {
                name: 'BOM Project Register',
                path: '/inventory/registers/bom-projects/:id',
                auth: 'UI:INV-REQ:VIEW',
                hidden: true,
                content: BomProjectDetails,
              },
              {
                name: 'BOM Register',
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/bom-register',
                content: ItemRequisitionList,
              },
              {
                name: 'BOM Draft Register',
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/bom-project-draft',
                content: ItemRequisitionList,
              },
              {
                name: 'BOM Draft',
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/bom-draft',
                content: ItemRequisitionList,
              },
              {
                name: 'MRS Register',
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/mrs-register',
                content: ItemRequisitionList,
              },
              {
                name: 'MRS Completed',
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/mrs-completed',
                content: ItemRequisitionList,
              },
              {
                name: 'BOM Flow',
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/bom-flow',
                content: BomFlow,
              },
              {
                name: 'BOM Flow',
                hidden: true,
                auth: 'UI:INV-REQ:VIEW',
                path: '/inventory/registers/bom-flow/:id',
                content: BomFlowDetails,
              },
              {
                name: 'BOM',
                auth: 'UI:INV-REQ:VIEW',
                hidden: true,
                path: '/inventory/registers/bom/:id/details',
                content: BomDetails,
              },
              {
                name: 'BOM',
                auth: 'UI:INV-REQ:VIEW',
                hidden: true,
                path: '/inventory/registers/bom/:id/history',
                content: BomDetails,
              },
            ],
          },
          {
            name: 'Reports',
            path: '/inventory/reports',
            icon: TableCellsIcon,
            type: 'menu',
            auth: 'UI:INV-MGMT:VIEW',
            menu: [
              {
                name: 'Stock Item Summary',
                path: '/inventory/reports/stock-item-summary',
                auth: 'UI:ITM-BATCH:VIEW',
                content: StockItemSummary,
              },
              // {
              //   name: 'Item Transactions',
              //   type: 'path',
              //   path: '/management/item-transactions',
              //   // Icon: Square2StackIcon as () => JSX.Element,
              //   auth: 'UI:ITM-BATCH:VIEW',
              //   component: component['ItemTransactions'],
              //   permissions: [],
              // },
            ],
          },
        ],
      },
      {
        name: 'Miscellaneous',
        icon: EllipsisHorizontalCircleIcon,
        path: '/miscellaneous',
        sidebarLinks: [
          {
            name: 'Reports',
            path: '/miscellaneous/reports',
            icon: DocumentTextIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Report List',
                path: '/miscellaneous/reports/list',
                auth: '',
                content: ReportList,
              },
              {
                name: 'Report Builder',
                hidden: true,
                path: '/miscellaneous/reports/:id',
                auth: '',
                content: ReportBuilder,
              },
            ],
          },
          {
            name: 'Questionnaire',
            path: '/miscellaneous/questionnaire',
            icon: QuestionMarkCircleIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Topics',
                path: '/miscellaneous/questionnaire/topics',
                auth: '',
                content: QuestionnaireTopic,
              },
              {
                name: 'Questions',
                hidden: true,
                path: '/miscellaneous/questionnaire/questions/:id',
                auth: '',
                content: Questions,
              },
              {
                name: 'Survey Answers',
                hidden: true,
                path:
                  '/miscellaneous/questionnaire/survey-answers/:id/date/:date',
                auth: '',
                content: SurveyAnswersPage,
              },
              {
                name: 'Submitted Answers',
                hidden: true,
                path: '/miscellaneous/questionnaire/:id/survey-answers/:ans',
                auth: '',
                content: SubmittedAnswersPage,
              },
              {
                name: 'Survey Ansers',
                hidden: true,
                path: '/miscellaneous/questionnaire/survey-successful',
                auth: '',
                content: SurveyAnswerSubmitComponent,
              },
            ],
          },
        ],
      },
      {
        name: 'Production',
        icon: MachineIcon,
        path: '/production',
        sidebarLinks: [
          {
            name: 'Scheduler',
            path: '/production/scheduler',
            icon: () => <History className='w-5 inline stroke-0' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Schedule Production',
                path: '/production/scheduler/schedules/:id',
                auth: 'UI:MCN-SCH:VIEW',
                content: MachineSchedule,
              },
              {
                name: 'Machine List',
                path: '/production/scheduler/machines',
                auth: 'UI:MCN-LIST:VIEW',
                content: MachineList,
              },
              {
                name: 'Machine',
                path: '/production/scheduler/machines/:id',
                auth: 'UI:MCN-LIST:VIEW',
                content: Machine,
              },
              {
                name: 'Production Plan',
                path: '/production/scheduler/plan',
                auth: 'UI:PRD-PLAN:VIEW',
                content: Plan,
              },
            ],
          },
          {
            name: 'Manufacturing Voucher',
            path: '/production/manufacturing-voucher',
            icon: () => <Label className='w-5 inline stroke-0' />,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'List',
                path: '/production/manufacturing-voucher/list',
                auth: 'UI:MAN-VCHR:VIEW',
                content: ManufacturingVoucherList,
              },
              {
                name: 'Details',
                path: '/production/manufacturing-voucher/details/:id',
                auth: 'UI:MAN-VCHR:VIEW',
                content: ManufacturingVoucherDetails,
              },
            ],
          },
        ],
      },
      {
        name: 'Purchase',
        icon: ShoppingCartIcon,
        path: '/purchase',
        sidebarLinks: [
          {
            name: 'Vendors',
            path: '/purchase/masters',
            icon: CurrencyRupeeIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Vendor List',
                path: '/purchase/masters/vendors',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorList,
              },
              {
                name: 'Vendor Godowns',
                path: '/purchase/masters/vendor-godowns',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorGodowns,
              },
              {
                name: 'Contracts',
                path: '/purchase/masters/vendor-contracts',
                auth: 'UI:VEN-LIST:VIEW',
                content: () => <div>Vendor Contracts</div>,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/overview',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/purchase-orders',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/godown',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/contracts',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/profile',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/transactions',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/enquiry-and-offers',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor',
                hidden: true,
                path: '/purchase/masters/vendors/:id/history',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorV2Profile,
              },
              {
                name: 'Vendor Items',
                hidden: true,
                path:
                  '/purchase/masters/vendor-items/:id/:vendorId/specifications',
                auth: 'UI:VEN-LIST:VIEW',
                content: VendorItemSpec,
              },
            ],
          },
          {
            name: 'Registers',
            path: '/purchase/registers',
            icon: TagIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Purchase Requisition',
                path: '/purchase/registers/purchase-requisition-register',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseRequisitions,
              },
              {
                name: 'PR Details',
                hidden: true,
                path: '/purchase/registers/purchase-requisitions/:id',
                auth: 'UI:PR-REQ:VIEW',
                content: PRDetails,
              },
              {
                name: 'Purchase Enquiry',
                path: '/purchase/registers/purchase-enquiry-register',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseRequisitions,
              },
              {
                name: 'PO Enquiry Details',
                hidden: true,
                path: '/purchase/registers/enquiry/:id/details',
                auth: 'UI:PR-REQ:VIEW',
                content: EnquiryDetails,
              },
              {
                name: 'PO Enquiry Details',
                hidden: true,
                path: '/purchase/registers/enquiry/:id/quotes',
                auth: 'UI:PR-REQ:VIEW',
                content: EnquiryDetails,
              },

              {
                name: 'Purchase Order',
                path: '/purchase/registers/purchase-order-register',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseRequisitions,
              },
              {
                name: 'PO Details',
                hidden: true,
                path: '/purchase/registers/purchase-order/:id/details',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseOrderDetails,
              },
              {
                name: 'PO Details',
                hidden: true,
                path: '/purchase/registers/purchase-order/:id/fulfilments',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseOrderDetails,
              },
              {
                name: 'Purchase Item Inspection',
                hidden: true,
                path: '/purchase/registers/purchase-item-inspection',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseOrderDetails,
              },
            ],
          },
          {
            name: 'Deliveries',
            path: '/purchase/deliveries',
            icon: TagIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Goods Received',
                path: '/purchase/deliveries/goods-received',
                auth: 'UI:PR-REQ:VIEW',
                content: GoodsReceived,
              },
              {
                name: 'Inspection',
                path: '/purchase/deliveries/purchase-item-inspection',
                auth: 'UI:PR-REQ:VIEW',
                content: PurchaseItemInspection,
              },
              {
                name: 'Item Transfer',
                path: '/purchase/deliveries/purchase-item-transfer',
                auth: 'UI:PR-REQ:VIEW',
                content: ItemTransfer,
              },
            ],
          },
        ],
      },
      {
        name: 'Quality Assurance',
        icon: CheckBadgeIcon,
        path: '/quality-assurance',
        sidebarLinks: [
          {
            name: 'Change Control',
            path: '/quality-assurance/change-control',
            icon: DocumentTextIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Requisition',
                path: '/quality-assurance/change-control/requisition',
                auth: '',
                content: ChangeControlList,
              },
              {
                name: 'Requisition Details',
                hidden: true,
                path: '/quality-assurance/change-control/requisition/:id',
                auth: '',
                content: RequisitionDtls,
              },
              {
                name: 'Closure Date Period',
                path: '/quality-assurance/change-control/closure-date-period',
                auth: '',
                content: ClosureDatePeriod,
              },
            ],
          },
        ],
      },
      {
        name: 'Sales',
        icon: ShoppingBagIcon,
        path: '/sales',
        sidebarLinks: [
          {
            name: 'Masters',
            path: '/sales/masters',
            icon: DocumentTextIcon,
            type: 'menu',
            auth: 'UI:CLT-LIST:VIEW',
            menu: [
              {
                name: 'Client List',
                path: '/sales/masters/clients',
                auth: 'UI:CLT-LIST:VIEW',
                content: ClientList,
              },
              {
                name: 'Client Godowns',
                path: '/sales/masters/client-godowns',
                auth: 'UI:CLT-LIST:VIEW',
                content: ClientGodowns,
              },
              {
                name: 'Client Details',
                hidden: true,
                path: '/sales/masters/clients/:id/profile',
                auth: 'UI:CLT-LIST:VIEW',
                content: ClientV2Profile,
              },
              {
                name: 'Lead Customer List',
                path: '/sales/masters/lead-customers',
                auth: 'UI:CLT-LIST:VIEW',
                content: LeadCustomerList,
              },
              {
                name: 'Lead Customer',
                hidden: true,
                path: '/sales/masters/lead-customers/:id/profile',
                auth: 'UI:CLT-LIST:VIEW',
                content: LeadCustomerProfile,
              },
            ],
          },
          {
            name: 'Enquiries',
            path: '/sales/enquiries',
            icon: QuestionMarkCircleIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Enquiry Details',
                path: '/sales/enquiries/sales-enquiries',
                auth: 'UI:SALE-ENQ:VIEW',
                content: SalesEnquiryTabs,
              },
              {
                name: 'Enquiry Details',
                hidden: true,
                path: '/sales/enquiries/enquiry/:id',
                auth: 'UI:SALE-ENQ:VIEW',
                content: SalesEnquiryDetailsV2,
              },
              {
                name: 'Enquiry Details-v2',
                path: '/sales/enquiries/sales-enquiries-v2',
                auth: 'UI:SALE-ENQ:VIEW',
                content: SalesEnquiryTabs,
              },
              {
                name: 'Lead Enquiry',
                path: '/sales/enquiries/lead-enquiries',
                auth: 'UI:SALE-ENQ:VIEW',
                content: SalesEnquiryTabs,
              },
              {
                name: 'Item Enquiry',
                path: '/sales/enquiries/item-enquiries',
                auth: 'UI:SALE-ENQ:VIEW',
                content: SalesEnquiryTabs,
              },
              {
                name: 'Item Enquiry',
                hidden: true,
                path: '/sales/enquiries/item-enquiry',
                auth: 'UI:SALE-ENQ:VIEW',
                content: SalesItemEnquiryDetails,
              },
              {
                name: 'Item Enquiry',
                hidden: true,
                path: '/sales/enquiries/item-enquiry/details/:id',
                auth: 'UI:SALE-ENQ:VIEW',
                content: SalesEnquiryItemsDetailsV2,
              },
              {
                name: 'Lead Customer Enquiries',
                path: '/sales/enquiries/lead-customer-enquiry/:id/details',
                hidden: true,
                auth: '',
                content: LeadCustomerEnquirDetails,
              },
              {
                name: 'Manufacturing Enquiry',
                hidden: true,
                path: '/sales/enquiries/manufacturing-enquiry',
                auth: '',
                content: SalesManufacturingEnquiryDetails,
              },
              {
                name: 'QC Enquiry',
                hidden: true,
                path: '/sales/enquiries/qc-enquiry',
                auth: '',
                content: SalesQcEnquiryDetails,
              },
            ],
          },
          {
            name: 'Sales Order',
            path: '/sales/sales-order',
            icon: DocumentIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                name: 'Order',
                path: '/sales/sales-order/orders',
                auth: 'UI:SO:VIEW',
                content: SalesOrderTabs,
              },
              {
                name: 'Order',
                hidden: true,
                path: '/sales/sales-order/details/:id',
                auth: 'UI:SO:VIEW',
                content: SalesOrderDetailsV2,
              },
              {
                name: 'Item Order',
                hidden: true,
                path: '/sales/sales-order/item-order',
                auth: 'UI:SO:VIEW',
                content: SalesItemOrderDetails,
              },
              {
                name: 'Allocate',
                path: '/sales/sales-order/allocate',
                auth: 'UI:SO:VIEW',
                content: SalesOrderTabs,
              },
              {
                name: 'Manufacture',
                path: '/sales/sales-order/manufacture',
                auth: 'UI:SO:VIEW',
                content: SalesOrderTabs,
              },
              {
                name: 'Manufacturing Order',
                hidden: true,
                path: '/sales/sales-order/manufacturing-order',
                auth: '',
                content: SalesManufacturingOrderDetails,
              },
              {
                name: 'QC Order',
                hidden: true,
                path: '/sales/sales-order/qc-enquiry',
                auth: '',
                content: SalesQcOrderDetails,
              },
            ],
          },
        ],
      },
      {
        name: 'Users',
        icon: UserCircleIcon,
        path: '/users',
        sidebarLinks: [
          {
            name: 'Profile',
            path: '/users/profile',
            icon: DocumentTextIcon,
            type: 'menu',
            auth: '',
            menu: [
              {
                hidden: true,
                name: 'Profile Details',
                path: '/users/profile/:id',
                auth: '',
                content: UserProfile,
              },
            ],
          },
        ],
      },
    ];
  }
}
