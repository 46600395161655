import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTabsComponent,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseSalesEnquiry } from '../../../../hooks/order/sales/enquiry/use-enquiry';
import { renderHistoryTab } from './history-tab';

export type RenderSalesEnquiryDetailsV2Prop = {
  hooks: {
    useSalesEnquiry: UseSalesEnquiry;
  };
};

export function renderSalesEnquiryDetailsV2({
  hooks: { useSalesEnquiry },
}: RenderSalesEnquiryDetailsV2Prop): () => JSX.Element {
  return function SalesEnquiryDetailsV2(): JSX.Element {
    const { id } = useParams();
    const Tabs = renderTabsComponent();
    const { data, get: getSalesEnquiry } = useSalesEnquiry();
    const Card = renderCardComponent();

    useEffect(() => {
      if (id) {
        getSalesEnquiry(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const header: CardHeader = {
      title: `${data.name || id}`,
      subheading: (
        <>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${data.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' /> {data.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${data.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {data.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(data.lastModifiedAt).fromNow()}
          </div>
        </>
      ),
    };

    const categories: Map<string, () => JSX.Element> = new Map<
      string,
      () => JSX.Element
    >([['History', renderHistoryTab({ id, useSalesEnquiry })]]);

    const body: CardBody = {
      type: 'jsx-component',
      body: <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={0} />,
    };

    return <Card header={header} body={body} />;
  };
}
