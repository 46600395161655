import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';

export type RenderUserProfile = {
  useEmployeeProfile: UseEmployeeProfile;
};

export const renderUserProfile = ({
  useEmployeeProfile,
}: RenderUserProfile) => {
  return function () {
    const { id } = useParams();
    const { data: user, get: getUser, loading } = useEmployeeProfile();
    useEffect(() => {
      if (id) {
        getUser(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const joiningDate =
      user?.details?.workTimeLines?.find((x) => x.activity === 'joined')
        ?.date || 'not-set';

    if (loading || !user?.id)
      return (
        <div className='flex justify-center items-center p-20'>
          <Recat className='h-5 inline animate-pulse mx-4' />
        </div>
      );
    return (
      <div>
        <div className='ml-8 p-2 font-semibold text-xs'>{user?.name}</div>
        <div className='w-full py-5 flex justify-center items-center bg-zinc-50 border-y'>
          <div className='flex space-x-10'>
            <div>
              <img
                className='w-20 h-20 rounded-full border'
                alt={user?.name}
                src={user?.details?.photo || '/images/person.png'}
              />
            </div>

            <div>
              <div className='font-bold text-2xl'>{user.name}</div>
              <div className='flex space-x-3 items-center flex-row'>
                <div className=''>{user?.company?.name}</div>
                <div className='w-[2px] h-[2px] bg-indigo-900 rounded-full' />
                <div className=''>{user.details?.designation}</div>
                <div className='w-[2px] h-[2px] bg-indigo-900 rounded-full' />
                {user?.details?.resigned === 'yes' ? (
                  <div>Resigned</div>
                ) : (
                  <div className=''>
                    Working since{' '}
                    {joiningDate !== 'not-set'
                      ? moment(joiningDate).format('MMM DD, YYYY')
                      : joiningDate}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};
