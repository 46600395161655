import { renderHistory } from '@erp_core/erp-ui-components';
import { UseEmployeeProfile } from '../../../../../hooks/hrd/employee/profile/use-employee-profile';

export function renderHistoryTab({
  id,
  useEmployeeProfile,
}: {
  id: string;
  useEmployeeProfile: UseEmployeeProfile;
}): () => JSX.Element {
  const History = renderHistory();

  return function HistoryTab(): JSX.Element {
    return <History useResource={useEmployeeProfile} id={id} />;
  };
}
