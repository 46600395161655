import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseAppearances } from '../../../../hooks/admin/constants/appearance/use-appearances';
import { UseColors } from '../../../../hooks/admin/constants/color/use-colors';
import { UseGsts } from '../../../../hooks/admin/constants/gst/use-gsts';
import { UseMetrics } from '../../../../hooks/admin/constants/metrics/use-metrics';
import { UseOdours } from '../../../../hooks/admin/constants/odour/use-odours';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { ChangeRequestDataType } from './types';

type EditChangeReqProps = {
  currentValue: IdName;
  onSave: (data: ChangeRequestDataType) => Promise<void>;
  useResources: string;
};

type EditPropertyIdNameChangeReqFormType = {
  currentValue: IdName;
  newValue: IdName;
  reason: string;
};

export const renderEditSearchableSelectPropertyChangeReq = ({
  useMetrics,
  useGsts,
  useEmployees,
  useColors,
  useOdours,
  useAppearances,
}: {
  useMetrics: UseMetrics;
  useGsts: UseGsts;
  useEmployees: UseEmployeeProfiles;
  useColors: UseColors;
  useOdours: UseOdours;
  useAppearances: UseAppearances;
}) => {
  return function EditSearchableSelectPropertyChangeReq({
    currentValue,
    onSave,
    useResources,
  }: EditChangeReqProps) {
    const useSearchResource = (useResources: string) => {
      switch (useResources) {
        case 'useMetrics':
          return useMetrics;
        case 'useGST':
          return useGsts;
        case 'useEmployees':
          return useEmployees;
        case 'useColors':
          return useColors;
        case 'useOdours':
          return useOdours;
        case 'useAppearances':
          return useAppearances;
        default:
          return null;
      }
    };

    const searchResource = useSearchResource(useResources);
    if (!searchResource) {
      return <></>;
    }

    const formProps: FormProps<EditPropertyIdNameChangeReqFormType> = {
      fieldsData: [
        {
          property: 'currentValue',
          type: 'input',
          label: 'Current Value',
          readonly: true,
        },
        {
          property: 'newValue',
          type: 'searchable-select',
          required: true,
          label: 'New Value',
          searchOptions: {
            useSearch: searchResource,
            filter:
              searchResource === useEmployees
                ? { crossGroup: 'true' }
                : undefined,
            onSearchValueSelect(u) {},
          },
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
      ],
      initialFormState: {
        currentValue: currentValue.name,
        newValue: { id: '', name: '' },
        reason: '',
      },
      onSubmit: async (f) => {
        await onSave({
          reason: f.reason || '',
          changeRequest: {
            currentValue: {
              value: {
                data: f.currentValue,
              },
            },
            newValue: {
              value: {
                data: f.newValue,
              },
            },
          },
        });
      },
      mapTToU(p) {
        return (p as unknown) as EditPropertyIdNameChangeReqFormType;
      },
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EditPropertyIdNameChangeReqFormType>(formProps);

    return <Form />;
  };
};
