/* eslint-disable react-hooks/exhaustive-deps */
import {
  AppearanceFilterType,
  AppearanceType,
} from '@erp_core/erp-types/dist/modules/constants';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseAppearance } from '../../../hooks/admin/constants/appearance/use-appearance';
import { UseAppearances } from '../../../hooks/admin/constants/appearance/use-appearances';
import { UsePaginatedAppearances } from '../../../hooks/admin/constants/appearance/use-paginated-appearances';
import { renderAddAppearanceForm } from './form/add-apperance-form';

export type RenderAppearanceProps = {
  useAppearances: UseAppearances;
  usePaginatedAppearances: UsePaginatedAppearances;
  useAppearance: UseAppearance;
};

export function renderAppearance({
  useAppearances,
  usePaginatedAppearances,
  useAppearance,
}: RenderAppearanceProps): () => JSX.Element {
  return function Appearance(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      AppearanceType,
      AppearanceFilterType
    >();
    const { getAll: getAppearances } = useAppearances();

    const { syncSet: setAppearance } = useAppearance();

    useEffect(() => {
      getAppearances();
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Appearance',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Appearance',
            behaviour: 'modal',
            modal: {
              title: 'Add Appearance',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveAppearance(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddAppearanceForm();

    const saveAppearance = async (form: Partial<AppearanceType>) => {
      try {
        const finalData = {
          ...form,
        } as AppearanceType;
        await setAppearance(finalData as AppearanceType);
        toast('Data added sucessfully');
        getAppearances();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [[{ name: 'Name' }]];

    const bodyMapper = (r: AppearanceType) => {
      const cells: Array<TableCell> = [{ value: r.name }];
      return {
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full h-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              useResources={useAppearances}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedAppearances,
              }}
            />
          </div>
        </div>
      ),
    };
    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
