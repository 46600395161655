import {
  AttendanceDeviceType,
  AttendanceType,
  DocumentsFilter,
  DocumentsType,
  EmployeeEducationFilter,
  EmployeeEducationType,
  EmployeeExitType,
  EmployeeFamilyFilter,
  EmployeeFamilyType,
  EmployeeFilterType,
  EmployeeProfileType,
  EmployeeRegistrationType,
  LeaveAppType,
  OffSiteWorkHoursFilterType,
  OffSiteWorkHoursType,
  PfReturnsType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { EmployeeExitFilterType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-exit/fandf-settlement';
import { EmployeeLettersType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-letters';
import { GatePassType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import { EmployeeRegistrationFilter } from '@erp_core/erp-types/dist/types/modules/hrd/employee-registration';
import { OvertimeType } from '@erp_core/erp-types/dist/types/modules/hrd/overtime';
import {
  StatutoryReturnFilterType,
  StatutoryReturnType,
} from '@erp_core/erp-types/dist/types/modules/hrd/statutory-returns/statutory-returns';
import { AttendanceHttpRepo } from '../datalayer/repository/http/hrd/attendance';
import { AttendanceDeviceHttpRepo } from '../datalayer/repository/http/hrd/attendance-device';
import { EmployeeEducationHttpRepo } from '../datalayer/repository/http/hrd/employee-education-details';
import { HttpFAndFSettlementRepo } from '../datalayer/repository/http/hrd/employee-exit/fandfSettlement';
import { EmployeeFamilyHttpRepo } from '../datalayer/repository/http/hrd/employee-family-details';
import { EmployeeLettersHttpRepo } from '../datalayer/repository/http/hrd/employee-letters';
import { GatePassHttpRepo } from '../datalayer/repository/http/hrd/employee/gate-pass';
import { OvertimeHttpRepo } from '../datalayer/repository/http/hrd/employee/overtime';
import { HttpEmployeeProfileRepo } from '../datalayer/repository/http/hrd/employee/profile';
import { HttpESICReturnRepo } from '../datalayer/repository/http/hrd/esic-return/esic-return';
import { LeavesHttpRepo } from '../datalayer/repository/http/hrd/leaves';
import { HttpMinimumWagesRepo } from '../datalayer/repository/http/hrd/minimumWages/minimum-wages';
import { OffSiteWorkHoursHttpRepo } from '../datalayer/repository/http/hrd/off-site-work-hours';
import { HttpPFReturnRepo } from '../datalayer/repository/http/hrd/pf-return/pf-return';
import { HttpPTReturnRepo } from '../datalayer/repository/http/hrd/pt-returns/pt-returns';
import { EmployeeRegistrationHttpRepo } from '../datalayer/repository/http/hrd/registration-details';
import { DocumentsHttpRepo } from '../datalayer/repository/http/hrd/registration-document';
import { HttpStatutoryReturnRepo } from '../datalayer/repository/http/hrd/statutory-return/statutoryreturn';
import { createUseAttendanceMonthlySummary } from '../hooks/hrd/attendance/use-monthly-summary';
import { createUsePaginatedResources } from '../hooks/resource/use-paginated-resources';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import {
  AttendanceFilter,
  AttendanceInterface,
} from '../models/interfaces/hrd/attendance';
import {
  AttendanceDeviceFilter,
  AttendanceDeviceInterface,
} from '../models/interfaces/hrd/attendance-interface';
import { EmployeeEducationInterface } from '../models/interfaces/hrd/employee-education-details';
import { EmployeeExitInterface } from '../models/interfaces/hrd/employee-exit/fandf-settlement';
import { EmployeeFamilyInterface } from '../models/interfaces/hrd/employee-family-details';
import {
  EmployeeLettersFilterType,
  EmployeeLettersInterface,
} from '../models/interfaces/hrd/employee-letters';
import { EmployeeRegistrationInterface } from '../models/interfaces/hrd/employee-registration-details';
import { DocumentsInterface } from '../models/interfaces/hrd/employee-registration-doc';
import { EmployeeProfileInterface } from '../models/interfaces/hrd/employee/empProfile';
import {
  GatePassFilterType,
  GatePassInterface,
} from '../models/interfaces/hrd/employee/gate-pass';
import {
  OvertimeFilterType,
  OvertimeInterface,
} from '../models/interfaces/hrd/employee/overtime';
import { ESICReturnInterface } from '../models/interfaces/hrd/esic-return/esic-return';
import {
  LeaveFilterType,
  LeaveInterface,
} from '../models/interfaces/hrd/leave';
import { MinimumWagesInterface } from '../models/interfaces/hrd/minimumWage/minimumWage';
import { OffSiteWorkHoursInterface } from '../models/interfaces/hrd/off-site-work-hours';
import { PFReturnInterface } from '../models/interfaces/hrd/pf-returns/pf-returns';
import { PTReturnInterface } from '../models/interfaces/hrd/pt-returns/pt-returns';
import { StatutoryReturnInterface } from '../models/interfaces/hrd/statutory-return/statutory-return';
import { erpV2Api } from './admin';
import { HRD_SERVER_URI } from './server-uri';

export const employeeProfileService = new HttpEmployeeProfileRepo(
  HRD_SERVER_URI,
  erpV2Api
);

export const employeeLettersService = new EmployeeLettersHttpRepo(
  HRD_SERVER_URI,
  erpV2Api
);

export const useEmployeeLetter = createUseResource<
  EmployeeLettersType,
  EmployeeLettersFilterType,
  EmployeeLettersInterface
>({
  useService: () => employeeLettersService,
});

export const useEmployeeLetters = createUseResources<
  EmployeeLettersType,
  EmployeeLettersFilterType,
  EmployeeLettersInterface
>({
  useService: () => employeeLettersService,
});

export const useEmployeeProfile = createUseResource<
  EmployeeProfileType,
  EmployeeFilterType,
  EmployeeProfileInterface
>({
  useService: () => employeeProfileService,
});

export const usePaginatedEmployeeProfiles = createUsePaginatedResources<
  EmployeeProfileType,
  EmployeeFilterType,
  EmployeeProfileInterface
>({
  useService: () => employeeProfileService,
});

export const useEmployeeProfiles = createUseResources<
  EmployeeProfileType,
  EmployeeFilterType,
  EmployeeProfileInterface
>({
  useService: () => employeeProfileService,
});

export const statutoryReturnService = new HttpStatutoryReturnRepo(
  HRD_SERVER_URI,
  erpV2Api
);
export const useStatutoryReturn = createUseResource<
  StatutoryReturnType,
  StatutoryReturnFilterType,
  StatutoryReturnInterface
>({
  useService: () => statutoryReturnService,
});

export const useStatutoryReturns = createUseResources<
  StatutoryReturnType,
  StatutoryReturnFilterType,
  StatutoryReturnInterface
>({
  useService: () => statutoryReturnService,
});

export const attendanceService = new AttendanceHttpRepo(
  HRD_SERVER_URI,
  erpV2Api
);
export const useAttendances = createUseResources<
  AttendanceType,
  AttendanceFilter,
  AttendanceInterface
>({ useService: () => attendanceService });
export const useAttendance = createUseResource<
  AttendanceType,
  AttendanceFilter,
  AttendanceInterface
>({ useService: () => attendanceService });
export const useAttendanceMonthlySummary = createUseAttendanceMonthlySummary({
  useAttendanceService: () => attendanceService,
});

export const gatePassService = new GatePassHttpRepo(HRD_SERVER_URI, erpV2Api);
export const useGatePasses = createUseResources<
  GatePassType,
  GatePassFilterType,
  GatePassInterface
>({ useService: () => gatePassService });
export const useGatePass = createUseResource<
  GatePassType,
  GatePassFilterType,
  GatePassInterface
>({ useService: () => gatePassService });

export const overtimeService = new OvertimeHttpRepo(HRD_SERVER_URI, erpV2Api);
export const useOvertimes = createUseResources<
  OvertimeType,
  OvertimeFilterType,
  OvertimeInterface
>({ useService: () => overtimeService });
export const useOvertime = createUseResource<
  OvertimeType,
  OvertimeFilterType,
  OvertimeInterface
>({ useService: () => overtimeService });

export const attendanceDeviceService = new AttendanceDeviceHttpRepo(
  HRD_SERVER_URI,
  erpV2Api
);
export const useAttendanceDevice = createUseResource<
  AttendanceDeviceType,
  AttendanceDeviceFilter,
  AttendanceDeviceInterface
>({ useService: () => attendanceDeviceService });

export const useAttendanceDevices = createUseResources<
  AttendanceDeviceType,
  AttendanceDeviceFilter,
  AttendanceDeviceInterface
>({ useService: () => attendanceDeviceService });

export const leaveService = new LeavesHttpRepo(HRD_SERVER_URI, erpV2Api);
export const useLeave = createUseResource<
  LeaveAppType,
  LeaveFilterType,
  LeaveInterface
>({ useService: () => leaveService });
export const useLeaves = createUseResources<
  LeaveAppType,
  LeaveFilterType,
  LeaveInterface
>({ useService: () => leaveService });

export const offSiteWorkHoursService = new OffSiteWorkHoursHttpRepo(
  HRD_SERVER_URI,
  erpV2Api
);
export const useOffSiteWorkHour = createUseResource<
  OffSiteWorkHoursType,
  OffSiteWorkHoursFilterType,
  OffSiteWorkHoursInterface
>({ useService: () => offSiteWorkHoursService });
export const useOffSiteWorkHours = createUseResources<
  OffSiteWorkHoursType,
  OffSiteWorkHoursFilterType,
  OffSiteWorkHoursInterface
>({ useService: () => offSiteWorkHoursService });

export const pfReturnService = new HttpPFReturnRepo(HRD_SERVER_URI, erpV2Api);
export const usePFReturn = createUseResource<
  PfReturnsType,
  StatutoryReturnFilterType,
  PFReturnInterface
>({
  useService: () => pfReturnService,
});

export const usePFReturns = createUseResources<
  PfReturnsType,
  StatutoryReturnFilterType,
  PFReturnInterface
>({
  useService: () => pfReturnService,
});

export const usePaginatedPFReturns = createUsePaginatedResources<
  PfReturnsType,
  StatutoryReturnFilterType,
  PFReturnInterface
>({
  useService: () => pfReturnService,
});

export const minimumWagesService = new HttpMinimumWagesRepo(
  HRD_SERVER_URI,
  erpV2Api
);
export const useMinimumWage = createUseResource<
  StatutoryReturnType,
  StatutoryReturnFilterType,
  MinimumWagesInterface
>({
  useService: () => minimumWagesService,
});

export const useMinimumWages = createUseResources<
  StatutoryReturnType,
  StatutoryReturnFilterType,
  MinimumWagesInterface
>({
  useService: () => minimumWagesService,
});

export const ptReturnService = new HttpPTReturnRepo(HRD_SERVER_URI, erpV2Api);
export const usePTReturn = createUseResource<
  StatutoryReturnType,
  StatutoryReturnFilterType,
  PTReturnInterface
>({
  useService: () => ptReturnService,
});

export const usePTReturns = createUseResources<
  StatutoryReturnType,
  StatutoryReturnFilterType,
  PTReturnInterface
>({
  useService: () => ptReturnService,
});

export const esicReturnService = new HttpESICReturnRepo(
  HRD_SERVER_URI,
  erpV2Api
);
export const useESICReturn = createUseResource<
  StatutoryReturnType,
  StatutoryReturnFilterType,
  ESICReturnInterface
>({
  useService: () => esicReturnService,
});

export const useESICReturns = createUseResources<
  StatutoryReturnType,
  StatutoryReturnFilterType,
  ESICReturnInterface
>({
  useService: () => esicReturnService,
});

export const employeeExitService = new HttpFAndFSettlementRepo(
  HRD_SERVER_URI,
  erpV2Api
);
export const useEmployeeExit = createUseResource<
  EmployeeExitType,
  EmployeeExitFilterType,
  EmployeeExitInterface
>({
  useService: () => employeeExitService,
});

export const useEmployeeExits = createUseResources<
  EmployeeExitType,
  EmployeeExitFilterType,
  EmployeeExitInterface
>({
  useService: () => employeeExitService,
});

export const employeeRegistrationService = new EmployeeRegistrationHttpRepo(
  HRD_SERVER_URI,
  erpV2Api
);
export const useEmployeeRegistration = createUseResource<
  EmployeeRegistrationType,
  EmployeeRegistrationFilter,
  EmployeeRegistrationInterface
>({
  useService: () => employeeRegistrationService,
});

export const useEmployeeRegistrations = createUseResources<
  EmployeeRegistrationType,
  EmployeeRegistrationFilter,
  EmployeeRegistrationInterface
>({
  useService: () => employeeRegistrationService,
});

export const employeeDocRegistrationService = new DocumentsHttpRepo(
  HRD_SERVER_URI,
  erpV2Api
);
export const useDocument = createUseResource<
  DocumentsType,
  DocumentsFilter,
  DocumentsInterface
>({
  useService: () => employeeDocRegistrationService,
});

export const useDocuments = createUseResources<
  DocumentsType,
  DocumentsFilter,
  DocumentsInterface
>({
  useService: () => employeeDocRegistrationService,
});

export const employeeEducationService = new EmployeeEducationHttpRepo(
  HRD_SERVER_URI,
  erpV2Api
);
export const useEmployeeEducation = createUseResource<
  EmployeeEducationType,
  EmployeeEducationFilter,
  EmployeeEducationInterface
>({
  useService: () => employeeEducationService,
});

export const useEmployeeEducations = createUseResources<
  EmployeeEducationType,
  EmployeeEducationFilter,
  EmployeeEducationInterface
>({
  useService: () => employeeEducationService,
});

export const employeeFamilyService = new EmployeeFamilyHttpRepo(
  HRD_SERVER_URI,
  erpV2Api
);
export const useEmployeeFamily = createUseResource<
  EmployeeFamilyType,
  EmployeeFamilyFilter,
  EmployeeFamilyInterface
>({
  useService: () => employeeFamilyService,
});

export const useEmployeeFamilies = createUseResources<
  EmployeeFamilyType,
  EmployeeFamilyFilter,
  EmployeeFamilyInterface
>({
  useService: () => employeeFamilyService,
});
