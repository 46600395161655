import { renderCardComponent } from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { UseQuestionnaireQuestions } from '../../../../hooks/hrd/questionnaire/questionnairequestion/usequestionnairequestions';
import { UseQuestionnaireSurveyAnswer } from '../../../../hooks/hrd/questionnaire/questionnairesurveyans/usequestionnairesurveyanswer';

export function renderSubmittedAnswers({
  useQuestionnaireQuestions,
  useQuestionnaireSurveyAnswer,
}: {
  useQuestionnaireQuestions: UseQuestionnaireQuestions;
  useQuestionnaireSurveyAnswer: UseQuestionnaireSurveyAnswer;
}) {
  const Card = renderCardComponent();
  return function SubmittedAnswers() {
    const { id, ans } = useParams();
    const {
      data: questions,
      getAll: getQuestions,
    } = useQuestionnaireQuestions();
    const { data: answer, get: getAnswer } = useQuestionnaireSurveyAnswer();

    useEffect(() => {
      if (id && ans) {
        getAnswer(ans);
        getQuestions({
          topicId: id,
        });
      }
      // eslint-disable-next-line
    }, []);

    return (
      <div>
        {answer ? (
          <Card
            header={{
              title: (
                <div>
                  Submitted Answers for {answer.topic?.name} on{' '}
                  {moment(answer.createdAt).format('MMM DD')}
                </div>
              ),
            }}
            body={{
              type: 'jsx-component',
              body: (
                <div>
                  {questions
                    ?.sort((a, b) => a.orderNo - b.orderNo)
                    .map((q) => {
                      const ans = answer.details?.surveyAns.find(
                        (x) => x.questionId === q.id
                      );

                      return (
                        <div key={q.id} className='my-1'>
                          <div>
                            <span className='bg-gray-50 p-1 font-semibold'>
                              {q.orderNo}. {q.name} -
                            </span>
                            <span>
                              {ans?.answerValue}{' '}
                              {ans?.reason ? `- ${ans.reason}` : ''}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ),
            }}
          />
        ) : null}
      </div>
    );
  };
}
