import {
  RotationalShiftDayType,
  ShiftScheduleType,
} from '@erp_core/erp-types/dist/modules/planning';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { LoadingButton, renderFormV2 } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseEmployeeProfiles } from '../../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseRotationalShiftDays } from '../../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-days';
import { shiftSwitchTypeList } from './select-switch-weekly-off-form';

export type AdjustEmployeeOvertimProps = {
  empShift: RotationalShiftDayType;
  re1: RotationalShiftDayType;
  re2?: RotationalShiftDayType;
  reason?: string;
  type: string;
  markCurrentEmployeeOnLeave: boolean;
};

export const SelectSwitchAdjustEmployeeForm = ({
  targetedEmployee,
  markCurrentEmployeeOnLeave,
  editCurrentEmployeeOnLeave,
  date,
  useRotationalShiftDays,
  useEmployees,
  schedules,
  adjustEmployeeOvertime,
  emp1Shift,
  data,
}: {
  targetedEmployee: { id: string; name: string };
  markCurrentEmployeeOnLeave: boolean;
  editCurrentEmployeeOnLeave: boolean;
  date: string;
  useRotationalShiftDays: UseRotationalShiftDays;
  useEmployees: UseEmployeeProfiles;
  schedules: ShiftScheduleType[] | undefined;
  adjustEmployeeOvertime: (p: AdjustEmployeeOvertimProps) => void;
  emp1Shift: RotationalShiftDayType;
  data?: {
    emp1?: IdName;
    emp2?: IdName;
    reason?: string;
    type?: string;
  } | null;
}) => {
  const {
    data: replacementEmployee1Shift,
    getAll: getReplacementEmployee1Shifts,
  } = useRotationalShiftDays();
  const {
    data: replacementEmployee2Shift,
    getAll: getReplacementEmployee2Shifts,
  } = useRotationalShiftDays();

  type FormData = {
    replacementEmployee1: IdName;
    replacementEmployee2?: IdName;
    replacementDate1: string;
    replacementDate2?: string;
    type: string;
    reason: string;
    markCurrentEmployeeOnLeave: boolean;
  };

  const [formData, setFormData] = useState<FormData>({
    markCurrentEmployeeOnLeave: markCurrentEmployeeOnLeave || false,
    replacementEmployee1: data?.emp1 ? data.emp1 : { id: '', name: '' },
    replacementEmployee2: data?.emp2 ? data.emp2 : { id: '', name: '' },
    replacementDate1: date,
    replacementDate2: date,
    type: data?.type || '',
    reason: data?.reason || '',
  });

  useEffect(() => {
    const newData = {
      ...formData,
      replacementDate1: date,
      replacementDate2: date,
      type: data?.type || '',
      reason: data?.reason || '',
    };
    getData(newData);
    setFormData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const getData = (formData: FormData) => {
    if (formData.replacementEmployee2?.id) {
      getReplacementEmployee2Shifts({
        employeeId: `equal::${formData.replacementEmployee2.id}` || '',
        date: formData.replacementDate2,
      });
    }

    getReplacementEmployee1Shifts({
      employeeId: `equal::${formData.replacementEmployee1.id}` || '',
      date: formData.replacementDate1,
    });
  };

  const Form = renderFormV2<any>({
    fieldsData: [
      {
        type: 'select',
        property: 'markCurrentEmployeeOnLeave',
        readonly: !editCurrentEmployeeOnLeave,
        label: `Mark ${targetedEmployee.name} on Leave`,
        options: [
          { text: 'Select', value: '' },
          { text: 'Yes', value: 'yes' },
          { text: 'No', value: 'no' },
        ],
      },
      {
        type: 'searchable-select',
        property: 'replacementEmployee1',
        required: true,
        label: 'Employee 1',
        searchOptions: {
          useSearch: useEmployees,
          filter: { crossGroup: 'true' },
          onSearchValueSelect: () => {},
        },
      },
      {
        type: 'date',
        property: 'replacementDate1',
        required: true,
        label: 'Employee 1 shift date',
      },
      {
        type: 'searchable-select',
        property: 'replacementEmployee2',
        label: 'Employee 2 (Optional)',
        searchOptions: {
          useSearch: useEmployees,
          filter: { crossGroup: 'true' },
          onSearchValueSelect: () => {},
        },
      },
      {
        type: 'date',
        property: 'replacementDate2',
        label: 'Employee 2 shift date',
      },
      {
        type: 'input',
        property: 'reason',
        required: true,
        label: 'Reason',
      },
      {
        type: 'select',
        property: 'type',
        required: true,
        label: 'Select type',
        options: [{ value: '', text: 'select' }, ...shiftSwitchTypeList],
      },
    ],
    onSubmit: async (data) => {
      setFormData(data);
      getData(data);
    },
    initialFormState: {
      ...formData,
      markCurrentEmployeeOnLeave: formData.markCurrentEmployeeOnLeave
        ? 'yes'
        : 'no',
    },
    style: 'w-1/2 p-1 text-xs',
    button: {
      text: 'Search',
      style: 'w-fit text-xs',
    },
    allowUnchanged: true,
    mapTToU: (t) => {
      const u: any = { ...t };
      if (t.markCurrentEmployeeOnLeave === 'yes') {
        u.markCurrentEmployeeOnLeave = true;
      } else {
        u.markCurrentEmployeeOnLeave = false;
      }
      return u;
    },
  });

  const validation = (
    es1: RotationalShiftDayType,
    s1?: RotationalShiftDayType,
    s2?: RotationalShiftDayType
  ) => {
    if (!s1) return 'Select employee';

    if (s1.employee.id === es1.employee.id) return 'Same Employee selected';

    if (s2 && s2.employee?.id === es1.employee.id)
      return 'Same Employee is selected';

    if (s2 && s1.employee?.id === s2.employee.id)
      return 'Same employee is selected';

    if (s1.attendance !== 'working')
      return `Emp1 ${s1.employee.name} is on leave or weekly off`;

    if (s2 && s2.attendance !== 'working')
      return `Emp2 ${s2.employee.name} is on leave or weekly off`;

    if (s1.date === es1.date && s1.details.shiftId === es1.details.shiftId)
      return 'Emp1 shift is same';

    if (
      s2 &&
      s2.date === es1.date &&
      s2.details.shiftId === es1.details.shiftId
    )
      return 'Emp2 shift is same';

    if (s1 && es1.details?.workArea?.id !== s1.details?.workArea?.id)
      return 'Emp1 Work area is not matching';

    if (s2 && es1.details?.workArea?.id !== s2.details?.workArea?.id)
      return 'Emp2 Work area is not matching';

    return null; // Here null means all conditions are passed successfully
  };

  const val = validation(
    emp1Shift,
    replacementEmployee1Shift?.[0],
    replacementEmployee2Shift?.[0]
  );

  return (
    <div>
      <Form />
      <div className='flex'>
        {formData.replacementEmployee1?.id &&
        formData.replacementDate1 &&
        replacementEmployee1Shift &&
        replacementEmployee1Shift.length > 0 ? (
          <div className='w-1/2'>
            <div className='font-bold'>
              Emp1 {replacementEmployee1Shift[0].employee.name}
            </div>
            <Emp1ShiftDetails
              shiftData={replacementEmployee1Shift[0]}
              emp1Shift={emp1Shift}
              schedules={schedules}
              hours={formData.replacementEmployee2?.id ? 4 : 8}
            />
          </div>
        ) : null}
        {formData.replacementEmployee2?.id &&
        formData.replacementDate2 &&
        replacementEmployee2Shift &&
        replacementEmployee2Shift.length > 0 ? (
          <div className='w-1/2'>
            <div className='font-bold'>
              Emp2: {replacementEmployee2Shift[0].employee.name}
            </div>
            <Emp1ShiftDetails
              shiftData={replacementEmployee2Shift[0]}
              emp1Shift={emp1Shift}
              schedules={schedules}
              hours={4}
            />
          </div>
        ) : null}
      </div>
      <div className='w-full flex justify-center'>
        {!val &&
        replacementEmployee1Shift &&
        replacementEmployee1Shift.length > 0 ? (
          <LoadingButton
            defaultStyle='bg-blue-500 p-2 rounded shadow text-white font-semibold hover:bg-blue-600'
            behaviorFn={async () => {
              adjustEmployeeOvertime({
                empShift: emp1Shift,
                re1: replacementEmployee1Shift[0],
                re2: replacementEmployee2Shift?.[0],
                reason: formData.reason,
                type: formData.type,
                markCurrentEmployeeOnLeave: formData.markCurrentEmployeeOnLeave,
              });
            }}
            text='Confirm'
          />
        ) : (
          <div className='text-center text-red-500'>{val}</div>
        )}
      </div>
    </div>
  );
};

const Emp1ShiftDetails = ({
  shiftData,
  emp1Shift,
  schedules,
  hours,
}: {
  shiftData: RotationalShiftDayType;
  emp1Shift: RotationalShiftDayType;
  schedules?: ShiftScheduleType[];
  hours: number;
}) => {
  const sch = schedules?.find((s) => s.id === shiftData.details.shiftId?.id);

  return (
    <div className='p-2 w-full border rounded'>
      <div>Date: {shiftData.date}</div>
      <div>Schedule: {sch?.name}</div>
      <div>Attendance status: {shiftData.attendance}</div>
      <div>Work Area: {shiftData.details?.workArea?.name}</div>
      <div>Roles: {shiftData.details?.roleName}</div>
      <div>Overtime Hours: {hours}</div>
    </div>
  );
};
