import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import moment from 'moment';
import { ApprenticeCategory } from '../components/generate-salary';

export function calculateApprenticeCategory(
  emp: EmployeeProfileType
): ApprenticeCategory {
  if (!['G-1', 'G-2'].includes(emp.details.grade?.id)) {
    return 'NA';
  }

  if (
    emp.details.educationDetails?.find(
      (x) =>
        x.statusOfEducation === 'Graduation' ||
        x.statusOfEducation === 'Post-Graduation'
    )
  ) {
    return '7';
  }

  if (
    emp.details.educationDetails?.find(
      (x) => x.statusOfEducation === 'Engineering-Diploma'
    )
  ) {
    return '6';
  }

  if (
    emp.details.educationDetails?.find(
      (x) => x.statusOfEducation === 'Vocational-Diploma'
    )
  ) {
    return '5';
  }

  if (
    emp.details.educationDetails?.find((x) => x.statusOfEducation === 'ITI')
  ) {
    return '4';
  }

  if (
    emp.details.educationDetails?.find((x) => x.statusOfEducation === 'XII')
  ) {
    return '3';
  }

  if (emp.details.educationDetails?.find((x) => x.statusOfEducation === 'X')) {
    return '2';
  }

  if (
    emp.details.educationDetails?.find((x) => x.statusOfEducation === 'V-IX')
  ) {
    return '1';
  }

  return '0';
}

export function getJoiningInfo(
  employee: EmployeeProfileType
): 'before-feb24' | 'before-mar24' | 'after-mar24' {
  const joiningDate = employee.details?.workTimeLines?.find(
    (x) => x.activity === 'joined'
  )?.date;

  if (joiningDate) {
    if (moment(joiningDate).isBefore(moment('2024-02-01', 'YYYY-MM-DD'))) {
      return 'before-feb24';
    }

    if (moment(joiningDate).isBefore(moment('2024-03-01', 'YYYY-MM-DD'))) {
      return 'before-mar24';
    }
  }

  return 'after-mar24';
}

export function getHighestQualification(emp: EmployeeProfileType): string {
  // 'Below-V' | 'V-IX' | 'X' | 'XII' | 'ITI' | 'Vocational-Diploma' | 'Engineering-Diploma' | 'Graduation' | 'Post-Graduation' |
  if (
    emp.details.educationDetails?.find(
      (x) => x.statusOfEducation === 'Post-Graduation'
    )
  ) {
    return 'Post-Graduation';
  }

  if (
    emp.details.educationDetails?.find(
      (x) => x.statusOfEducation === 'Graduation'
    )
  ) {
    return 'Graduation';
  }

  if (
    emp.details.educationDetails?.find(
      (x) => x.statusOfEducation === 'Engineering-Diploma'
    )
  ) {
    return 'Engineering-Diploma';
  }

  if (
    emp.details.educationDetails?.find((x) => x.statusOfEducation === 'ITI')
  ) {
    return 'ITI';
  }

  if (
    emp.details.educationDetails?.find((x) => x.statusOfEducation === 'XII')
  ) {
    return 'XII';
  }

  if (emp.details.educationDetails?.find((x) => x.statusOfEducation === 'X')) {
    return 'X';
  }

  if (
    emp.details.educationDetails?.find((x) => x.statusOfEducation === 'V-IX')
  ) {
    return 'V-IX';
  }

  if (
    emp.details.educationDetails?.find((x) => x.statusOfEducation === 'V-IX')
  ) {
    return 'Below-V';
  }

  return 'Not-set';
}
