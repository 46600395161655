import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { Vendor, VendorItem } from '@erp_core/erp-types/dist/modules/order';
import { VendorItemContract } from '@erp_core/erp-types/dist/types/modules/order/purchase/vendor';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import { UseUserAuthorization } from '../../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseDeliveryTerms } from '../../../../../hooks/constants/delivery-term/use-delivery-terms';
import { UsePaymentTerms } from '../../../../../hooks/constants/payment-term/use-payment-terms';
import { UseVendor } from '../../../../../hooks/inventory/purchase/vendors/use-vendor';
import { renderAddVendorItemContractForm } from '../vendor-items/forms/add-contract-form';

type CreateItemCardType = {
  useVendor: UseVendor;
  usePaymentTerms: UsePaymentTerms;
  useDeliveryTerms: UseDeliveryTerms;
  useUserAuthorization: UseUserAuthorization;
  id: string;
};

export function renderVendorContracts({
  useVendor,
  id,
  useUserAuthorization,
  useDeliveryTerms,
  usePaymentTerms,
}: CreateItemCardType): () => JSX.Element {
  const Card = renderCardComponent();
  const Table = renderTableComponent();

  return function VendorContracts(): JSX.Element {
    const {
      data: vendor,
      get: getVendor,
      loading,
      syncSet: setVendorSync,
    } = useVendor();
    const { get: getAllowedActions } = useUserAuthorization();

    useEffect(() => {
      getVendor(id);
      // eslint-disable-next-line
    }, []);

    const saveVendorItemContract = async (
      vendorItemId: string,
      vendorItemContract: VendorItemContract
    ) => {
      const save: Vendor = {
        id: vendor.id,
        items: vendor.items.map((i) => {
          if (i.id === vendorItemId) {
            return {
              ...i,
              details: {
                contract: vendorItemContract,
              },
            };
          }
          return i;
        }),
      } as Vendor;
      await setVendorSync(save);
    };

    const cardHeader: CardHeader = {
      title: 'Contracts',
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Item Name' },
        { name: 'Validity' },
        { name: 'Rate' },
        { name: 'Batch size' },
        { name: 'Delivery Term' },
        { name: 'Delivery schedule' },
        { name: 'Delivery mode' },
        { name: 'Payment Term' },
        { name: 'Payment Schedule' },
        { name: 'Attachments' },
      ],
    ];

    const today = moment().format('YYYY-MM-DD');

    const tableBody: TableBody = vendor.items?.length
      ? vendor.items.map((i: VendorItem) => ({
          rowData: {
            vendorItem: i,
          },
          cells: [
            {
              value: i.item.name,
              link: `/inventory/masters/items/${i.item.id}/properties`,
            },
            {
              value: i.details?.contract?.validity ? (
                <div
                  className={`${
                    i.details?.contract?.validity < today ? 'text-red-600' : ''
                  }`}
                >
                  {i.details?.contract?.validity}
                </div>
              ) : (
                ''
              ),
            },
            { value: i.details?.contract?.rate || '' },
            { value: i.details?.contract?.batchSize?.size || '' },
            { value: i.details?.contract?.deliveryTerm?.name || '' },
            {
              value: i.details?.contract?.deliverySchedule?.gapBetweenBatches
                ? `Gap in days ${i.details?.contract?.deliverySchedule?.gapBetweenBatches}`
                : '',
            },
            { value: i.details?.contract?.deliveryMode?.name || '' },
            { value: i.details?.contract?.paymentTerm?.name || '' },
            {
              value: i.details?.contract?.paymentSchedule
                ? 'Payment Schedule available'
                : '',
            },
            { value: '' },
          ],
        }))
      : [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              auth={{ actions: getAllowedActions().actions }}
              body={tableBody}
              actions={[
                {
                  name: 'Add / Edit Contract',
                  behaviour: 'modal',
                  show: () => true,
                  auth: 'UI:BTN-ADD-VENDOR-ITEM-SPECS:VIEW', // should be UI:BTN-ADD-VENDOR-ITEM-CONTRACT:VIEW
                  modal: {
                    title: 'Add / Edit Contract',
                    content: ({ data: { vendorItem }, onClose }) => {
                      const Form = renderAddVendorItemContractForm({
                        vendorItem,
                        usePaymentTerms,
                        useDeliveryTerms,
                        onSubmit: async (contract) => {
                          await saveVendorItemContract(vendorItem.id, contract);
                          onClose();
                        },
                      });
                      return <Form />;
                    },
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };
    if (loading)
      return (
        <div className='w-full h-36 flex justify-center items-center'>
          <Recat className='h-5 inline animate-pulse mx-4' />
        </div>
      );
    return (
      <div>
        <Card body={cardBody} header={cardHeader} />
      </div>
    );
  };
}
