/* eslint-disable no-unused-vars */
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormProps,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { UseCompanyGroupSetting } from '../../../../../hooks/admin/company-group-setting/use-company-group-setting';

export type RenderRmQualityMetric = {
  closeModal: () => void;
  useCompanyGroupSetting: UseCompanyGroupSetting;
};

type Props = {
  comGrpSet?: CompanyGroupSetting;
};

export function renderRMQualityMetric({
  closeModal,
  useCompanyGroupSetting,
}: RenderRmQualityMetric): (data: Props) => JSX.Element {
  return function ({ comGrpSet }: Props): JSX.Element {
    const { syncSet: setCompanyGroupSetting } = useCompanyGroupSetting();
    if (!comGrpSet) return <Recat className='h-5 inline animate-pulse mx-4' />;
    const rmQualityMetric = comGrpSet?.details?.purchase?.rmQualityMetric || [];
    const saveRmQualityMetric = async (form: FormType) => {
      const finalData = {
        id: comGrpSet.id,
        details: {
          ...comGrpSet.details,
          purchase: {
            ...comGrpSet.details.purchase,
            rmQualityMetric: comGrpSet.details.purchase?.rmQualityMetric,
          },
        },
      };

      finalData.details.purchase.rmQualityMetric = (
        finalData.details?.purchase?.rmQualityMetric || []
      ).find((k) => k.id === form.id)
        ? (finalData.details?.purchase?.rmQualityMetric || []).map((r) => {
            if (r.id === form.id) {
              return {
                id: r.id,
                name: r.name,
              };
            }
            return r;
          })
        : [
            ...(finalData.details?.purchase?.rmQualityMetric || []),
            {
              id: form.id,
              name: form.name,
            },
          ];

      await setCompanyGroupSetting(finalData as CompanyGroupSetting);
      closeModal();
    };

    type FormType = {
      id: string;
      name: string;
    };

    const Card = renderCardComponent();

    const Table = renderTableComponent();

    return (
      <div>
        <Card
          header={{
            title: 'Raw Material Quality Metric',
            actions: [
              {
                type: 'button',
                button: {
                  name: 'Add new Quality Metric',
                  behaviour: 'modal',
                  modal: {
                    title: 'Add new Rm Quality Metric',
                    content: ({ onClose }) => {
                      const formProps: FormProps<FormType> = {
                        fieldsData: [
                          {
                            property: 'id',
                            label: 'Quality Id',
                            type: 'input',
                            required: true,
                          },
                          {
                            property: 'name',
                            label: 'Quality Name',
                            type: 'input',
                            required: true,
                          },
                        ],
                        initialFormState: {
                          id: '',
                          name: '',
                        },
                        allowUnchanged: true,
                        mapTToU: (d) => {
                          return d as FormType;
                        },
                        style: 'md:w-1/2',
                        onSubmit: async (form) => {
                          await saveRmQualityMetric(form);
                          onClose();
                        },
                      };

                      const Form = renderFormV2<any>(formProps);
                      return <Form />;
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <Table
                header={[[{ name: 'Id' }, { name: 'Name' }]]}
                body={rmQualityMetric.map((r) => {
                  return { cells: [{ value: r.id }, { value: r.name }] };
                })}
              />
            ),
          }}
        />
      </div>
    );
  };
}
