import { PremiumsType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UsePremium } from '../../../../hooks/hrd/insurance/premiums/use-premium';
import { UsePremiums } from '../../../../hooks/hrd/insurance/premiums/use-premiums';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderAddPremiumForm } from '../form/premium-addform';

export function PremiumsForm({
  usePremium,
  usePremiums,
  policyId,
  userRendererService,
}: {
  usePremium: UsePremium;
  usePremiums: UsePremiums;
  policyId: string;
  userRendererService: UserRendererInterface;
}): JSX.Element {
  const { user: currentUser } = useContext(UserContext);
  const { companyGroup } = useContext(CurrentContext);

  const { data: premiums, getAll: getPremiums } = usePremiums();

  const { syncSet: setPremium } = usePremium();

  const premiumRcds = _.orderBy(
    premiums,
    (e) => [moment(e.monthYear).format('YYYYMM')],
    ['asc', 'asc']
  );

  useEffect(() => {}, [premiums]);

  useEffect(() => {
    getPremiums({
      policyId: policyId,
    });
    // eslint-disable-next-line
  }, []);

  const Card = renderCardComponent();
  const cardHeader: CardHeader = {
    title: 'Premiums',
    actions: [
      {
        type: 'button',
        button: {
          name: 'Add Bulk Premiums',
          behaviour: 'modal',
          modal: {
            title: 'Add Bulk Premiums',
            content: ({ onClose }) => {
              const Form = renderAddPremiumForm();
              return (
                <Form
                  onSave={(x) => {
                    savePremiums(x);
                    onClose();
                  }}
                />
              );
            },
          },
        },
      },
    ],
  };

  function differenceInMonths(startDate: Moment, endDate: Moment) {
    const startDt = moment(startDate, 'DD.MM.YYYY');
    const endDt = moment(endDate, 'DD.MM.YYYY');
    const calMonths = endDt.diff(startDt, 'months');
    return calMonths;
  }

  const savePremiums = async (form: any) => {
    try {
      const from = moment(form.fromMonthYear).format('YYYY-MM') + '-01'; // moment(props.queryReq?.month);
      const to = moment(form.toMonthYear).format('YYYY-MM') + '-01';
      const difference = differenceInMonths(moment(from), moment(to));

      for (let i = 0; i < difference + 1; i++) {
        const finalData = {
          monthYear: moment(form.fromMonthYear)
            .add(i, 'month')
            .format('YYYY-MM'),
          amount: form.amount,
          status: 'pending',
          policy: {
            id: policyId,
          },
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          paidBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as PremiumsType;
        await setPremium(finalData);
      }
      toast('Data added sucessfully');
      getPremiums({ policyId: policyId });
    } catch (error) {
      toast('Something went wrong');
    }
  };

  const Table = renderTableComponent();

  const tableHeader: TableHeader = [
    [
      { name: 'Month (YYYY-MM)' },
      { name: 'Amount' },
      { name: 'Status' },
      { name: 'Paid By' },
      { name: 'Action' },
    ],
  ];

  const tableData =
    premiumRcds?.map((r, idx) => ({
      cells: [
        { value: r.monthYear },
        { value: r.amount },
        { value: r.status },
        {
          value: (
            <userRendererService.userCard
              link={true}
              size='small'
              id={r.lastModifiedBy?.id}
              name={r.lastModifiedBy?.name}
              extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
            />
          ),
        },
      ],
    })) || [];

  const tableBody: TableBody = tableData;

  const cardBody: CardBody = {
    type: 'jsx-component',
    body: (
      <div>
        <div className='w-full'>
          <Table header={tableHeader} body={tableBody} />
        </div>
      </div>
    ),
  };

  return (
    <>
      <div className='p-16 border border-gray-200 rounded-lg h-18 border rounded-md cursor-pointer p-2'>
        <Card header={cardHeader} body={cardBody} />
      </div>
    </>
  );
}
