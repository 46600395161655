/* eslint-disable react-hooks/exhaustive-deps */
import {
  OdourFilterType,
  OdourType,
} from '@erp_core/erp-types/dist/modules/constants';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseOdour } from '../../../hooks/admin/constants/odour/use-odour';
import { UseOdours } from '../../../hooks/admin/constants/odour/use-odours';
import { UsePaginatedOdours } from '../../../hooks/admin/constants/odour/use-paginated-odours';
import { renderAddOdourForm } from './form/add-odour-form';

export type RenderOdourProps = {
  useOdours: UseOdours;
  usePaginatedOdours: UsePaginatedOdours;
  useOdour: UseOdour;
};

export function renderOdour({
  useOdours,
  usePaginatedOdours,
  useOdour,
}: RenderOdourProps): () => JSX.Element {
  return function Odour(): JSX.Element {
    const Table = renderTableWithMapperComponent<OdourType, OdourFilterType>();
    const { getAll: getOdours } = useOdours();

    const { syncSet: setOdour } = useOdour();

    useEffect(() => {
      getOdours();
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Odour',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Odour',
            behaviour: 'modal',
            modal: {
              title: 'Add Odour',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveOdour(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddOdourForm();

    const saveOdour = async (form: Partial<OdourType>) => {
      try {
        const finalData = {
          ...form,
        } as OdourType;
        await setOdour(finalData as OdourType);
        toast('Data added sucessfully');
        getOdours();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [[{ name: 'Name' }]];

    const bodyMapper = (r: OdourType) => {
      const cells: Array<TableCell> = [{ value: r.name }];
      return {
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full h-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              useResources={useOdours}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedOdours,
              }}
            />
          </div>
        </div>
      ),
    };
    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
