import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { LoanType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  FormProps,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import {
  CalendarIcon,
  CurrencyRupeeIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { UserContext } from '../../../contexts/user';
import { UseCurrentUserRoles } from '../../../hooks/admin/role-admin/use-current-user-roles';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseEmployeeProfiles } from '../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseLoan } from '../../../hooks/loans/loan/use-loan';
import { UseDisbursementData } from '../../../hooks/loans/loan/use-loan-disbursement';
import { UseDisbursementPostData } from '../../../hooks/loans/loan/use-loan-post-disbursement';
import { UseLoans } from '../../../hooks/loans/loan/use-loans';
import { UseRepaymentSchedules } from '../../../hooks/loans/repayment-schedule/use-repayment-schedules';
import { LoanInterface } from '../../../models/interfaces/loans/loan';
import { UserRendererInterface } from '../../common/fragments/user';
import { RepaymentScheduleForm } from './components/repayment-schedule';
import { renderSimulateRepayments } from './components/simulate-repayments';
import { ViewCurrMonthDisbursementsForm } from './components/view-curr-month-disbursements';
import { renderViewRepayments } from './components/view-repayments';
import { renderAddLoanForm } from './form/add-loan-form';
import { DisbursementForm } from './form/disbursement-form';
import { LoanV2StatusType, LoanV2Type } from './types/loan-v2-type';

type RenderLoanDetailsProps = {
  useLoan: UseLoan;
  useLoans: UseLoans;
  useRepaymentSchedules: UseRepaymentSchedules;
  useEmployees: UseEmployeeProfiles;
  useDisbursementData: UseDisbursementData;
  useDisbursementPostData: UseDisbursementPostData;
  useCurrentUserRoles: UseCurrentUserRoles;
  useEmployeeProfiles: UseEmployeeProfiles;
  useUserAuthorization: UseUserAuthorization;
  userRendererService: UserRendererInterface;
  loansService: LoanInterface;
};

export function renderLoanList({
  useLoan,
  useLoans,
  useRepaymentSchedules,
  useEmployees,
  useDisbursementData,
  useDisbursementPostData,
  useCurrentUserRoles,
  useEmployeeProfiles,
  useUserAuthorization,
  userRendererService,
  loansService,
}: RenderLoanDetailsProps): () => JSX.Element {
  const ViewRepayments = renderViewRepayments({ loansService });
  const SimulateRepayments = renderSimulateRepayments({ loansService });
  return function LoanDetails(): JSX.Element {
    const { user: currentUser } = useContext(UserContext);

    const { data: loans, getAll: getLoans, loading } = useLoans();

    const {
      // data: repaymentSchedules,
      getAll: getRepaymentSchedules,
    } = useRepaymentSchedules();

    const { data: employees, getAll: getAllEmployees } = useEmployeeProfiles();

    useEffect(() => {
      getAllEmployees({ crossGroup: 'true' });
      // eslint-disable-next-line
    }, []);

    const { syncSet: setLoan } = useLoan();

    useEffect(() => {}, [loans]);

    useEffect(() => {
      getLoans();
      getRepaymentSchedules();
      // eslint-disable-next-line
    }, []);

    const { data: loansData, getAll: getAllLoans } = useLoans();

    const { get: getAllowedActions } = useUserAuthorization();

    useEffect(() => {
      getAllLoans({
        status: 'notify-to-disburse',
        createdAt: moment().format('YYYY-MM'),
      });
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Loans',
      actions: [
        // {
        //   type: 'button',
        //   button: {
        //     name: 'Add Loan',
        //     style: 'float-right bg-green-600',
        //     suffix: <PlusIcon className='w-5 h-5 text-black-800 inline' />,
        //     onClick: () => handleAddLoan(),
        //   },
        // },
        {
          type: 'button',
          show: () => Boolean(loansData && loansData.length > 0),
          button: {
            name: 'View Current Month Disbursement',
            behaviour: 'modal',
            modal: {
              title: 'View Current Moneth Disbursement',
              content: ({ onClose }) => {
                return <ViewCurrentMonthDisbursement />;
              },
            },
          },
          // jsx: (
          //   <>
          //     {loansData && loansData.length > 0 ? (
          //       <LoadingButton
          //         defaultStyle='p-2 shadow cursor-pointer bg-green-600'
          //         behaviorFn={async () => handleViewDisbursements()}
          //         text={
          //           <>
          //             View Disbursements
          //             <PlusIcon className='w-4 f-4 inline' />
          //           </>
          //         }
          //       />
          //     ) : (
          //       <></>
          //     )}
          //   </>
          // ),
        },
        {
          type: 'button',
          button: {
            name: 'Add Loan V2',
            behaviour: 'modal',
            modal: {
              title: 'Add Loan V2',
              content: ({ onClose }) => {
                const formProps: FormProps<any> = {
                  fieldsData: [
                    {
                      property: 'employee',
                      type: 'searchable-select',
                      searchOptions: {
                        useSearch: useEmployees,
                        filter: { crossGroup: true },
                        onSearchValueSelect: () => {},
                      },
                      label: 'Employee Name',
                      required: true,
                    },
                    {
                      property: 'reason',
                      type: 'input',
                      label: 'Reason',
                      required: true,
                    },
                    {
                      property: 'amount',
                      type: 'number',
                      label: 'Amount',
                      required: true,
                    },
                  ],
                  initialFormState: {
                    employee: { id: '', name: '' },
                    amount: 0,
                    reason: '',
                  },
                  mapTToU: (x) => x,
                  style: 'md:w-1/2',
                  onSubmit: async (l) => {
                    const emp = await employees?.find(
                      (e) => e.id === l.employee.id
                    );
                    const loanAuthorizer = { id: '', name: '' };
                    if (emp) {
                      loanAuthorizer.id =
                        emp.details?.authorizations?.loanAuthorizer?.id || '';
                      loanAuthorizer.name =
                        emp.details?.authorizations?.loanAuthorizer?.name || '';
                    }
                    await loansService.requestLoan({
                      employee: l.employee,
                      amount: l.amount,
                      reason: l.reason,
                      authorizer: loanAuthorizer,
                    });
                    onClose();
                  },
                };

                const Form = renderFormV2(formProps);
                return (
                  <div>
                    <Form />
                  </div>
                );
              },
            },
          },
        },
      ],
    };

    const ViewCurrentMonthDisbursement = ViewCurrMonthDisbursementsForm({
      useLoans,
      useEmployees,
      useCurrentUserRoles,
    });

    const Form = renderAddLoanForm({ useUsers: useEmployees, useLoans });

    const approveRequest = async (data: LoanType) => {
      try {
        const finalData = {
          ...data,
          authorizer: {
            id: currentUser.id,
            name: currentUser.name,
          },
        };
        await setLoan(finalData as any);
        toast('Data updated sucessfully');
        getLoans();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Table = renderTableComponent();
    const TableHeader: TableHeader = [
      [
        { name: 'Employee Name' },
        { name: 'Version' },
        { name: 'Amount' },
        { name: 'Rate of Interest %' },
        { name: 'Tenure (in Months)' },
        { name: 'Details' },
        { name: 'Status' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Authorizer
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Issued By
            </>
          ),
        },
        // { name: 'Actions',  },
      ],
    ];

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'Approve V2',
          auth: 'UI:LOAN-APRV:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              ['pending'].includes(loan.status) &&
              loan?.details?.version === 'v2'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ loan }: { loan: LoanV2Type }) => {
            return {
              title: `Are you sure you want to approve this Loan request of ${
                loan.details?.loanRequests?.find((x) => x.status === 'pending')
                  ?.amount || '-'
              } amount ?`,
              type: 'warning',
              message: 'Please provide rate of interest',
              showInput: true,
              onConfirm: async (inputValue) => {
                console.log(parseFloat(`${inputValue}`));
                loansService.approveLoan({
                  id: loan.id,
                  interestRate: parseFloat(`${inputValue}`),
                });
              },
            };
          },
        },
        {
          name: 'Request Loan Disbursement V2',
          auth: 'UI:LOAN-REQ:VIEW',
          behaviour: 'confirm',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              ['approved', 'partially-disbursed'].includes(loan.status) &&
              loan?.details?.version === 'v2' &&
              loan.details?.pendingDisbursements
            ) {
              return true;
            }
            return false;
          },
          onConfirm: ({ loan }: { loan: LoanV2Type }) => {
            return {
              title: 'Are you sure want to raise disbursement request?',
              type: 'warning',
              message: 'Please provide disbursement amount',
              showInput: true,
              onConfirm: async (inputValue) => {
                console.log(parseFloat(`${inputValue}`));
                loansService.requestDisburseAmount({
                  id: loan.id,
                  amount: parseFloat(`${inputValue}`),
                });
              },
            };
          },
        },
        {
          name: 'Approve Loan Disbursement V2',
          auth: 'UI:LOAN-APRV:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              (loan.status as LoanV2StatusType) === 'disbursement-requested' &&
              loan?.details?.version === 'v2'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ loan }: { loan: LoanV2Type }) => {
            return {
              title: `Are you sure want to approve disbursement request of ${
                loan.details?.disbursements?.find((x) => x.status === 'pending')
                  ?.amount || '-'
              } amount?`,
              type: 'warning',
              message: 'Please provide monthly repayment amount',
              showInput: true,
              onConfirm: async (inputValue) => {
                console.log(parseFloat(`${inputValue}`));
                loansService.approveDisbursementAmount({
                  id: loan.id,
                  monthlyRepayment: parseFloat(`${inputValue}`),
                });
              },
            };
          },
        },
        {
          name: 'View Repayment Schedule V2',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (loan?.details?.version === 'v2') {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Repayment Schedule V2',
            size: 'large',
            content: ({ onClose, data: { loan } }) => {
              return <ViewRepayments loan={loan} />;
            },
          },
        },
        {
          name: 'Simulate Repayment Schedule V2',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (loan?.details?.version === 'v2') {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Simulate Repayment Schedule V2',
            size: 'large',
            content: ({ onClose, data: { loan } }) => {
              return <SimulateRepayments loan={loan} />;
            },
          },
        },
        {
          name: 'Request Topup V2',
          auth: 'UI:LOAN-REQ:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              loan?.details?.version === 'v2' &&
              (loan.status as LoanV2StatusType) === 'disbursed'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Request Topup V2',
            size: 'normal',
            content: ({ onClose, data: { loan } }) => {
              const formProps: FormProps<any> = {
                fieldsData: [
                  {
                    property: 'reason',
                    type: 'input',
                    label: 'Reason',
                    required: true,
                  },
                  {
                    property: 'amount',
                    type: 'number',
                    label: 'Amount',
                    required: true,
                  },
                ],
                initialFormState: {
                  amount: 0,
                  reason: '',
                },
                mapTToU: (x) => x,
                style: 'md:w-1/2',
                onSubmit: async (l) => {
                  await loansService.requestTopUpLoan({
                    id: loan.id,
                    amount: parseInt(`${l.amount}`),
                    reason: l.reason,
                  });
                  onClose();
                },
              };

              const Form = renderFormV2(formProps);
              return (
                <div>
                  <Form />
                </div>
              );
            },
          },
        },
        {
          name: 'Approve Topup V2',
          auth: 'UI:LOAN-APRV:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              ['topup-requested'].includes(loan.status) &&
              loan?.details?.version === 'v2'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ loan }: { loan: LoanV2Type }) => {
            return {
              title: `Are you sure you want to approve this Topup request of ${
                loan.details?.loanRequests?.find((x) => x.status === 'pending')
                  ?.amount || '-'
              } amount?`,
              type: 'warning',
              onConfirm: async (inputValue) => {
                console.log(parseFloat(`${inputValue}`));
                loansService.approveTopup({
                  id: loan.id,
                });
              },
            };
          },
        },
        {
          name: 'Request Adhoc Repayment V2',
          auth: 'UI:LOAN-REQ:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              loan?.details?.version === 'v2' &&
              ['disbursed', 'partially-disbursed'].includes(
                loan.status as LoanV2StatusType
              )
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ loan }: { loan: LoanV2Type }) => {
            return {
              title: 'Are you sure you want to request adhoc repayment?',
              type: 'warning',
              message: `Please provide repayment amount for ${moment().format(
                'MMMM, YYYY'
              )}`,
              showInput: true,
              onConfirm: async (inputValue) => {
                console.log(parseFloat(`${inputValue}`));
                loansService.requestAdhocRepayment({
                  id: loan.id,
                  amount: parseFloat(`${inputValue}`),
                  month: `${moment().format(
                    'YYYY-MM'
                  )}-${moment().daysInMonth()}`,
                });
              },
            };
          },
        },
        {
          name: 'Approve Adhoc Repayment V2',
          auth: 'UI:LOAN-APRV:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              ['adhoc-repayment-requested'].includes(loan.status) &&
              loan?.details?.version === 'v2'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ loan }: { loan: LoanV2Type }) => {
            return {
              title: `Are you sure you want to approve this adhoc repayment request of ${
                loan.details?.singleRepaymentEdits?.find(
                  (x) => x.type === 'adhoc-repayment' && x.status === 'pending'
                )?.amount || '-'
              } amount?`,
              type: 'warning',
              onConfirm: async () => {
                loansService.approveAdhocRepayment({
                  id: loan.id,
                });
              },
            };
          },
        },
        {
          name: 'Request Skip Repayment V2',
          auth: 'UI:LOAN-REQ:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              loan?.details?.version === 'v2' &&
              ['disbursed', 'partially-disbursed'].includes(
                loan.status as LoanV2StatusType
              )
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ loan }: { loan: LoanV2Type }) => {
            return {
              title: 'Are you sure you want to request skip repayment?',
              type: 'warning',
              message: `Please provide reason for skipping ${moment().format(
                'MMMM, YYYY'
              )} repayment`,
              showInput: true,
              onConfirm: async (inputValue) => {
                loansService.requestSkipRepayment({
                  id: loan.id,
                  reason: inputValue || '',
                  month: `${moment().format(
                    'YYYY-MM'
                  )}-${moment().daysInMonth()}`,
                });
              },
            };
          },
        },
        {
          name: 'Approve Skip Repayment V2',
          auth: 'UI:LOAN-APRV:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              ['skip-repayment-requested'].includes(loan.status) &&
              loan?.details?.version === 'v2'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ loan }: { loan: LoanV2Type }) => {
            return {
              title: `Are you sure you want to approve this skip repayment request of ${
                loan.details?.singleRepaymentEdits?.find(
                  (x) => x.type === 'skip' && x.status === 'pending'
                )?.month || '-'
              } ?`,
              type: 'warning',
              onConfirm: async () => {
                loansService.approveSkipRepayment({
                  id: loan.id,
                });
              },
            };
          },
        },
        {
          name: 'Request Edit Repayment V2',
          auth: 'UI:LOAN-REQ:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              loan?.details?.version === 'v2' &&
              ['disbursed', 'partially-disbursed'].includes(
                loan.status as LoanV2StatusType
              )
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ loan }: { loan: LoanV2Type }) => {
            return {
              title: 'Are you sure you want to request edit repayment?',
              type: 'warning',
              message: `Please provide amount for editing ${moment().format(
                'MMMM, YYYY'
              )} repayment`,
              showInput: true,
              onConfirm: async (inputValue) => {
                loansService.requestEditUpcomingRepayment({
                  id: loan.id,
                  amount: parseInt(inputValue || ''),
                  month: `${moment().format(
                    'YYYY-MM'
                  )}-${moment().daysInMonth()}`,
                });
              },
            };
          },
        },
        {
          name: 'Approve Edit Repayment V2',
          auth: 'UI:LOAN-APRV:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              ['edit-repayment-requested'].includes(loan.status) &&
              loan?.details?.version === 'v2'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ loan }: { loan: LoanV2Type }) => {
            return {
              title: `Are you sure you want to approve this edit repayment request of ${
                loan.details?.singleRepaymentEdits?.find(
                  (x) => x.type === 'edit-existing' && x.status === 'pending'
                )?.amount || '-'
              } ?`,
              type: 'warning',
              onConfirm: async () => {
                loansService.approveEditUpcomingRepayment({
                  id: loan.id,
                });
              },
            };
          },
        },
        {
          name: 'Request Edit All Monthly Repayments V2',
          auth: 'UI:LOAN-REQ:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              loan?.details?.version === 'v2' &&
              ['disbursed', 'partially-disbursed'].includes(
                loan.status as LoanV2StatusType
              )
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Request All Monthly Repayments V2',
            size: 'normal',
            content: ({ onClose, data: { loan } }) => {
              const formProps: FormProps<any> = {
                fieldsData: [
                  {
                    property: 'date',
                    type: 'date',
                    label: 'Month',
                    required: true,
                  },
                  {
                    property: 'amount',
                    type: 'number',
                    label: 'Amount',
                    required: true,
                  },
                ],
                initialFormState: {
                  amount: 0,
                  date: '',
                },
                mapTToU: (x) => x,
                style: 'md:w-1/2',
                onSubmit: async (l) => {
                  await loansService.requestEditAllRepayments({
                    id: loan.id,
                    amount: parseInt(`${l.amount}`),
                    date: l.date,
                  });
                  onClose();
                },
              };

              const Form = renderFormV2(formProps);
              return (
                <div>
                  <Form />
                </div>
              );
            },
          },
        },
        {
          name: 'Approve Edit All Monthly Repayments V2',
          auth: 'UI:LOAN-APRV:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              ['edit-monthly-deduction-requested'].includes(loan.status) &&
              loan?.details?.version === 'v2'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ loan }: { loan: LoanV2Type }) => {
            return {
              title: `Are you sure you want to approve this edit all monthly repayments request ${
                loan.details?.monthlyDeductions?.find(
                  (x) => x.status === 'pending'
                )?.amount || '-'
              } from
              ${
                loan.details?.monthlyDeductions?.find(
                  (x) => x.status === 'pending'
                )?.date || '-'
              }?`,
              type: 'warning',
              onConfirm: async () => {
                loansService.approveEditAllRepayments({
                  id: loan.id,
                });
              },
            };
          },
        },
        {
          name: 'Request Edit Interest Rate V2',
          auth: 'UI:LOAN-REQ:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              loan?.details?.version === 'v2' &&
              ['disbursed', 'partially-disbursed'].includes(
                loan.status as LoanV2StatusType
              )
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Request Edit Interest Rate V2',
            size: 'normal',
            content: ({ onClose, data: { loan } }) => {
              const formProps: FormProps<any> = {
                fieldsData: [
                  {
                    property: 'date',
                    type: 'date',
                    label: 'Month',
                    required: true,
                  },
                  {
                    property: 'rate',
                    type: 'number',
                    label: 'rate',
                    required: true,
                  },
                ],
                initialFormState: {
                  rate: 0,
                  date: '',
                },
                mapTToU: (x) => x,
                style: 'md:w-1/2',
                onSubmit: async (l) => {
                  await loansService.requestEditInterestRate({
                    id: loan.id,
                    rate: parseInt(`${l.rate}`),
                    date: l.date,
                  });
                  onClose();
                },
              };

              const Form = renderFormV2(formProps);
              return (
                <div>
                  <Form />
                </div>
              );
            },
          },
        },
        {
          name: 'Approve Edit Interest Rate V2',
          auth: 'UI:LOAN-APRV:VIEW',
          show: ({ loan }: { loan: LoanV2Type }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (
              ['edit-interest-rate-requested'].includes(loan.status) &&
              loan?.details?.version === 'v2'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ loan }: { loan: LoanV2Type }) => {
            return {
              title: `Are you sure you want to approve this edit interest rate request ${
                loan.details?.interestRates?.find((x) => x.status === 'pending')
                  ?.rate || '-'
              }% from ${
                loan.details?.interestRates?.find((x) => x.status === 'pending')
                  ?.date || '-'
              }?`,
              type: 'warning',
              onConfirm: async () => {
                loansService.approveEditInterestRate({
                  id: loan.id,
                });
              },
            };
          },
        },
        {
          name: 'Approve/Reject',
          show: ({ loan }: { loan: LoanType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            const version =
              (loan as LoanV2Type).details?.version === 'v2' ? 'v2' : 'v1';
            if (['pending'].includes(loan.status) && version === 'v1') {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Approve/Reject',
            content: ({
              data: { loan },
              onClose,
            }: {
              data: {
                loan: LoanType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <Form data={loan} onSave={approveRequest} />
                </div>
              );
            },
          },
        },
        {
          name: 'Repayment Schedule',
          show: ({ loan }: { loan: LoanType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            const version =
              (loan as LoanV2Type).details?.version === 'v2' ? 'v2' : 'v1';
            if (
              ['notify-to-disburse'].includes(loan.status) &&
              version === 'v1'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Repayment Schedule',
            size: 'large',
            // eslint-disable-next-line
            content: ({
              data: { loan },
              onClose,
            }: {
              data: {
                loan: LoanType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <RepaymentScheduleForm
                    useRepaymentSchedules={useRepaymentSchedules}
                    loanId={loan.id}
                    useLoans={useLoans}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Simulate Disbursement',
          show: ({ loan }: { loan: LoanType }) => {
            const version =
              (loan as LoanV2Type).details?.version === 'v2' ? 'v2' : 'v1';
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (['pending'].includes(loan.status) && version === 'v1') {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Simulate Disbursement',
            size: 'large',
            // eslint-disable-next-line
            content: ({
              data: { loan },
              onClose,
            }: {
              data: {
                loan: LoanType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <DisbursementForm
                    useDisbursementData={useDisbursementData}
                    useDisbursementPostData={useDisbursementPostData}
                    loanId={loan.id}
                    useLoan={useLoan}
                    closeModal={onClose}
                    action='simulate'
                    useLoans={useLoans}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Disbursement',
          show: ({ loan }: { loan: LoanType }) => {
            const version =
              (loan as LoanV2Type).details?.version === 'v2' ? 'v2' : 'v1';
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (['approved'].includes(loan.status) && version === 'v1') {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Disbursement',
            size: 'large',
            // eslint-disable-next-line
            content: ({
              data: { loan },
              onClose,
            }: {
              data: {
                loan: LoanType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <DisbursementForm
                    useDisbursementData={useDisbursementData}
                    useDisbursementPostData={useDisbursementPostData}
                    loanId={loan.id}
                    useLoan={useLoan}
                    closeModal={onClose}
                    action='Disbursement'
                    useLoans={useLoans}
                  />
                </div>
              );
            },
          },
        },
      ];
    }

    function renderTableBodyMapper(loans?: LoanV2Type[]): TableBody {
      return (
        loans?.map((l) => ({
          rowData: {
            loan: l,
          },
          cells: [
            {
              value: l.employee?.name,
              link: `/hrd/loan-management/loans/${l.id}`,
            },
            { value: l.details?.version || 'v1' },
            {
              value:
                l.details?.version === 'v2' ? (
                  <div>
                    {l.details?.loanRequests?.map((x) => (
                      <div>
                        <span className='border border-green-500 px-2 py-1 rounded-md'>
                          {x.amount} on {moment(x.date).format('MMM,YY')}
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  l.amount
                ),
            },
            {
              value:
                l.details?.version === 'v2' ? (
                  <div>
                    <b>Interest Rates:</b>
                    {l.details?.interestRates?.map((x) => (
                      <div>
                        - {x.rate}% from {moment(x.date).format('DD MMM,YY')}
                      </div>
                    ))}
                  </div>
                ) : (
                  l.rateOfInterest
                ),
            },
            {
              value:
                l.details?.version === 'v2' ? (
                  <div>
                    <b>Monthly Deductions:</b>
                    {l.details?.monthlyDeductions?.map((x) => (
                      <div>
                        - {x.amount} from {moment(x.date).format('MMM,YY')}
                      </div>
                    ))}
                  </div>
                ) : (
                  l.tenure
                ),
            },
            {
              value:
                l.details?.version === 'v2' ? (
                  <div>
                    <div>
                      <b>Pending Disbursements:</b>{' '}
                      {l.details.pendingDisbursements || 0}
                    </div>
                    {l.details.disbursements?.length ? (
                      <>
                        <b>Disbursement Requests:</b>
                        {l.details.disbursements?.map((x) => (
                          <div>
                            <span className='border border-purple-500 px-2 py-1 rounded-md'>
                              {x.amount} on {moment(x.date).format('MMM,YY')} [
                              {x.status}]
                            </span>
                          </div>
                        ))}
                      </>
                    ) : null}
                  </div>
                ) : (
                  <>
                    {l.details.disbursedDate ? (
                      <>
                        <div className='font-semibold'>Disbursements</div>
                        {l.details.disbursedDate.map((x) => (
                          <div key={x.date}>
                            <CalendarIcon className='inline w-5 text-blue-500 mx-1' />
                            {x.date}
                            <CurrencyRupeeIcon className='inline w-5 text-green-500 mx-1' />
                            {x.amount}
                          </div>
                        ))}
                        {l.details.emiPerMonth ? (
                          <>
                            <div className='font-semibold'>Emi Per Month:</div>
                            <CurrencyRupeeIcon className='inline w-5 text-green-500 mx-1' />
                            {l.details.emiPerMonth}
                          </>
                        ) : null}
                        {l.details.emiPerMonth ? (
                          <>
                            <div className='font-semibold'>
                              Expected Last Date of Payment:
                            </div>
                            <CalendarIcon className='inline w-5 text-purple-500 mx-1' />
                            {l.details.expectedLastDateOfPayment}
                          </>
                        ) : null}
                      </>
                    ) : null}
                    <div></div>
                  </>
                ),
            },
            { value: l.status },
            {
              value: (
                <userRendererService.userCard
                  link={true}
                  size='small'
                  id={l.authorizer?.id}
                  name={l.authorizer?.name}
                  extraInfo={moment.utc(l.lastModifiedAt).fromNow()}
                />
              ),
            },
            {
              value: (
                <userRendererService.userCard
                  link={true}
                  size='small'
                  id={l.createdBy?.id}
                  name={l.createdBy?.name}
                  extraInfo={moment.utc(l.createdAt).fromNow()}
                />
              ),
            },
          ],
        })) || []
      );
    }

    const TableBody: TableBody = renderTableBodyMapper(loans);

    const filteredLoans = _.sortBy(
      loans?.filter((x) => {
        const emp = employees?.find((e) => e.id === x.employee.id);
        if (
          emp &&
          emp.details?.authorizations?.loanAuthorizer?.id === currentUser.id
        ) {
          return true;
        }
        return false;
      }),
      (l) => {
        switch (
          (l as LoanType).details?.advPaymentStatus ||
          (l as LoanType).details?.skipRepaymentStatus
        ) {
          case 'requested':
            return 0;
          default:
            return 1;
        }
      }
    );

    const FilteredTableBody: TableBody = renderTableBodyMapper(filteredLoans);

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            {loading ? (
              <div className='flex my-24 justify-center'>
                <Recat className='h-5 inline animate-pulse mx-4' />
              </div>
            ) : (
              <>
                <div className='my-5'>
                  <Card
                    header={{ title: 'Requests for you' }}
                    body={{
                      type: 'jsx-component',
                      body: (
                        <Table
                          header={TableHeader}
                          body={FilteredTableBody}
                          actions={addActions()}
                          auth={{ actions: getAllowedActions().actions }}
                        />
                      ),
                    }}
                  />
                </div>

                <div className='my-5'>
                  <Card
                    header={{ title: 'All Requests' }}
                    body={{
                      type: 'jsx-component',
                      body: (
                        <Table
                          header={TableHeader}
                          body={TableBody}
                          actions={addActions()}
                          auth={{ actions: getAllowedActions().actions }}
                        />
                      ),
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ),
    };

    return (
      <>
        <div>
          <Card
            header={cardHeader}
            body={cardBody}
            auth={{ actions: getAllowedActions().actions }}
          />
        </div>
      </>
    );
  };
}
