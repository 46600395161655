import { Godown } from '@erp_core/erp-types/dist/modules/inventory';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderInlineInput,
  useConfirm,
} from '@erp_core/erp-ui-components';
import { Switch } from '@headlessui/react';
import {
  CubeIcon,
  HomeModernIcon,
  InboxStackIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CurrentContext } from '../../../../../contexts/current';
import { UseEmployeeProfile } from '../../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseEmployeeProfiles } from '../../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseRotationalShiftDay } from '../../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-day';
import { UseRotationalShiftDays } from '../../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-days';
import { UseGodown } from '../../../../../hooks/inventory/godown-inventory/use-godown';
import { UseGodowns } from '../../../../../hooks/inventory/godown-inventory/use-godowns';
import { UseBatchItemGodowns } from '../../../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UseShiftSchedules } from '../../../../../hooks/planning/shift-schedule/use-shift-schedules';
import { renderShiftCalendar } from './components/calendar';
import { SelectFinalEmployees } from './components/select-final-employees';
import { renderShiftBuilderTable } from './components/shift-builder-table';
import { renderGodownShiftConfigV2 } from './components/shift-config-v2';
import { renderWorkAreaBlock } from './components/work-area-block';

type RenderWorkAreaDetailsProps = {
  useGodown: UseGodown;
  useGodowns: UseGodowns;
  useBatchItemGodowns: UseBatchItemGodowns;
  useShiftSchedules: UseShiftSchedules;
  useEmployees: UseEmployeeProfiles;
  useShifts: UseRotationalShiftDays;
  useRotationalShiftDay: UseRotationalShiftDay;
  useEmployee: UseEmployeeProfile;
};

export function renderWorkAreaDetailsPage({
  useGodown,
  useGodowns,
  useBatchItemGodowns,
  useShiftSchedules,
  useEmployees,
  useShifts,
  useEmployee,
  useRotationalShiftDay,
}: RenderWorkAreaDetailsProps): () => JSX.Element {
  return function WorkAreaDetailsPage(): JSX.Element {
    const { id } = useParams();
    const { getAllSync: getShifts } = useShifts();
    const { getSync: getEmployee } = useEmployee();
    const { location: currentLocation } = useContext(CurrentContext);
    const { syncSet: setRotationalShiftDay } = useRotationalShiftDay();

    const ShiftCalendar = renderShiftCalendar({
      useShifts,
      useShiftSchedules,
    });
    const Card = renderCardComponent();
    const { data: godown, get, syncSet: setGodown } = useGodown();
    const { Confirm, openConfirm } = useConfirm();
    const ShiftConfig = renderGodownShiftConfigV2({
      useBatchItemGodowns,
      useShiftSchedules,
      useEmployees,
    });

    const WorkAreaBlock = renderWorkAreaBlock({
      useGodowns,
    });

    const ShiftBuilderTable = renderShiftBuilderTable();

    useEffect(() => {
      if (id) {
        get(id);
      }
      // eslint-disable-next-line
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const InlineInput = renderInlineInput({ useResource: useGodown, id });
    const updateWorkAreaStatus = async (currentStatus: boolean) => {
      openConfirm({
        message: `Are your sure to ${
          currentStatus ? 'remove' : 'mark'
        } this godown as work area.`,
        onConfirm: async () => {
          await setGodown({
            id: godown.id,
            type: !currentStatus ? 'work-area' : 'physical',
          } as Godown);
          get(id);
        },
      });
    };
    const updateSupervisorWorkAreaStatus = async (currentStatus: boolean) => {
      openConfirm({
        message: `Are your sure to ${
          currentStatus ? 'remove' : 'mark'
        } this godown as supervisor work area.`,
        onConfirm: async () => {
          await setGodown({
            id: godown.id,
            type: !currentStatus ? 'supervisor-work-area' : 'physical',
          } as Godown);
          get(id);
        },
      });
    };

    const header: CardHeader = {
      title: `${godown.name || id} [${godown.legacyGodownId}]`,
      icon: godown.details?.imageUrl ? (
        <img
          alt={godown.name}
          src={godown.details?.imageUrl}
          className='w-24 h-24 self-center'
        />
      ) : (
        <CubeIcon className='text-gray-800 w-24 h-full' />
      ),
      subheading: (
        <>
          <div className='text-gray-700 italic'>
            <InlineInput
              name='description'
              value={
                godown.description || 'Add a description for the godown...'
              }
            />
          </div>
          <div className='text-gray-700'>
            <a
              className='font-bold'
              href={`/inventory/masters/godowns/${godown.parentGodownId}`}
              target='_blank'
              rel='noreferrer'
            >
              <InboxStackIcon className='w-6 inline pr-1' />
              {godown.parentGodownId === 'root'
                ? 'Root Godown'
                : godown.parentGodownId}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${godown.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {godown.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${godown.lastModifiedBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {godown.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(godown.lastModifiedAt).fromNow()}
          </div>
          {godown && godown.id ? (
            <div className='flex space-x-2 w-fit justify-center items-center'>
              <span className='font-semibold'>
                <HomeModernIcon className='inline-block w-5 h-5 text-amber-600' />{' '}
                Work Area
              </span>
              <Switch
                checked={godown.type === 'work-area'}
                onChange={() =>
                  updateWorkAreaStatus((godown.details as any)?.workArea)
                }
                className={`${
                  godown.type === 'work-area' ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-4 w-8 items-center rounded-full`}
              >
                <span className='sr-only'>Enable notifications</span>
                <span
                  className={`${
                    godown.type === 'work-area'
                      ? 'translate-x-4'
                      : 'translate-x-1'
                  } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          ) : null}
          {godown && godown.id ? (
            <div className='flex space-x-2 w-fit justify-center items-center'>
              <span className='font-semibold'>🕵️‍♂️ Supervisor Work Area</span>
              <Switch
                checked={godown.type === 'supervisor-work-area'}
                onChange={() =>
                  updateSupervisorWorkAreaStatus(
                    godown.type === 'supervisor-work-area'
                  )
                }
                className={`${
                  godown.type === 'supervisor-work-area'
                    ? 'bg-blue-600'
                    : 'bg-gray-200'
                } relative inline-flex h-4 w-8 items-center rounded-full`}
              >
                <span className='sr-only'>Enable notifications</span>
                <span
                  className={`${
                    godown.type === 'supervisor-work-area'
                      ? 'translate-x-4'
                      : 'translate-x-1'
                  } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          ) : null}
        </>
      ),
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          {godown.type === 'work-area' ||
          godown.type === 'supervisor-work-area' ? (
            <>
              <ShiftConfig godown={godown} setGodown={setGodown} />
              {/* Segregating tables */}
              <WorkAreaBlock godown={godown} setGodown={setGodown} />
              {godown.type === 'work-area' ? (
                <SelectFinalEmployees godown={godown} setGodown={setGodown} />
              ) : null}
              <ShiftBuilderTable
                currentLocation={currentLocation}
                getShifts={getShifts}
                getEmployee={getEmployee}
                setRotationalShiftDay={setRotationalShiftDay}
                godown={godown}
                setGodown={setGodown}
                openConfirm={openConfirm}
              />
            </>
          ) : (
            <></>
          )}
          <ShiftCalendar id={id} />
        </>
      ),
    };

    return (
      <div>
        <Confirm />
        <Card header={header} body={body} />
      </div>
    );
  };
}
