/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { LeadCustomerEnquiryType } from '@erp_core/erp-types/dist/modules/order';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import {
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { PencilIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseItems } from '../../../../../hooks/inventory/item/use-items';
import { UseClientItem } from '../../../../../hooks/order/sales/client-item/use-client-item';
import { UseClient } from '../../../../../hooks/order/sales/client/use-client';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';
import { CategoryIcons } from '../../../../inventory/items/item-categories';

type Props = { onSave: any };

export function renderAddProductForm({
  useItems,
  useClient,
  leadCustomerEnquiry,
  itemService,
  useClientItem,
}: {
  useItems: UseItems;
  useClient: UseClient;
  leadCustomerEnquiry: LeadCustomerEnquiryType;
  itemService: ItemInterface;
  useClientItem: UseClientItem;
}): (props: Props) => JSX.Element {
  return function AddProductForm({ onSave }: Props): JSX.Element {
    const initialOptions = [{ value: '', text: 'Select' }];
    const { data: client, get: getClient } = useClient();
    const { data: clientItem, get: getClientItem } = useClientItem();
    const [selectedProduct, setSelectedProduct] = useState<IdName | undefined>(
      undefined
    );
    const [properties, setProperties] = useState<Array<ItemPropertyValue>>([]);

    useEffect(() => {
      if (selectedProduct?.id) {
        getClientItem(selectedProduct?.id);
      }
    }, [selectedProduct]);

    useEffect(() => {
      if (leadCustomerEnquiry?.leadCustomer?.id) {
        getClient(leadCustomerEnquiry?.leadCustomer?.id);
      }
    }, [leadCustomerEnquiry]);

    useEffect(() => {
      if (clientItem?.item?.id) {
        itemService.getProperties(clientItem.item.id).then((c) => {
          setProperties(c);
        });
      }
    }, [clientItem]);

    const purUOMFiltered = properties.filter((r) => r.name === 'pur-uom');

    const uomProductData = _.map(purUOMFiltered, (o, i) => ({
      id: o.value?.data?.id,
      name: o.value?.data?.name,
    }));

    // const endUseFiltered = properties.filter((r) => r.name === 'end-use');

    // const endUseProductData = _.map(endUseFiltered, (o, i) =>
    //   _.map(o.value?.data, (m, idx) => ({
    //     id: idx + 1,
    //     name: m?.['end-use-product'],
    //   }))
    // );

    type AddProductForm1Type = {
      product: { id: string; name: string };
    };

    type AddProductForm2Type = {
      product?: { id: string; name: string };
      endUse: { id: string; name: string }[];
      totalQuantity: string;
      totalUom: string;
      info: any;
    };

    const Form1 = renderFormV2<AddProductForm1Type>({
      // style: 'md:w-1/2',
      fieldsData: [
        {
          property: 'product',
          label: 'Product',
          required: true,
          type: 'searchable-select',
          searchOptions: {
            onSearchValueSelect: () => {},
            useSearch: () => {
              return {
                data: client?.items || [],
                getAll: (filter: { search?: string | undefined }) => {},
              };
            },
            // useSearch: useItems,
            searchOptionsBody: {
              customBody(data) {
                return (
                  <div>
                    {data.categories?.map((x) => (
                      <CategoryIcons category={x.category} key={x.id} />
                    ))}
                    {data?.name}
                  </div>
                );
              },
            },
          },
        },
      ],
      initialFormState: {
        product: { id: '', name: '' },
      },
      onSubmit: async (formData) => {
        if (formData.product.id) {
          setSelectedProduct({
            id: formData.product.id,
            name: formData.product.name,
          });
        }
      },
      allowUnchanged: true,
      mapTToU: (b) => b as AddProductForm1Type,
    });

    const Form2 = renderFormV2<AddProductForm2Type>({
      // style: 'md:w-1/2',
      formName: `Product Name: ${selectedProduct?.name}`,
      fieldsData: [
        {
          type: 'searchable-multi-select',
          // type: 'input',
          property: 'endUse',
          required: true,
          label: 'End Use',
          searchOptions: {
            useSearch: () => {
              return {
                data: clientItem?.details?.endUse || [],
                getAll: (filter: { search?: string | undefined }) => {},
              };
            },
            onSearchValueSelect: () => {},
          },
        },
        {
          property: 'totalQuantity',
          type: 'number',
          required: true,
          label: 'Total Quantity',
        },
        {
          property: 'totalUom',
          type: 'select',
          required: true,
          readonly: true,
          label: 'Total UOM',
          options: [
            ...initialOptions,
            ...((uomProductData
              ? uomProductData?.map((m) => ({
                  value: m.name,
                  text: m.name,
                }))
              : '') || []),
          ],
        },
        {
          property: 'info',
          label: 'Products',
          type: 'jsx',
          // groupName:'Products',
          renderObject({ formData, field, onSave }) {
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                <ProductsInfoRender
                  formData={formData}
                  field={field.property}
                  onSave={onSave}
                  uomProductData={uomProductData}
                />
              </div>
            );
          },
        },
      ],
      initialFormState: {
        endUse: clientItem?.details?.endUse || [],
        totalQuantity: '',
        totalUom: uomProductData ? uomProductData.map((i) => i.name) : '',
        product: {
          id: selectedProduct?.id || '',
          name: selectedProduct?.name || '',
        },
        info: {} as any,
      },
      onSubmit: onSave,
      allowUnchanged: true,
      mapTToU: (b) => b as AddProductForm2Type,
    });

    if (!selectedProduct) {
      return <Form1 />;
    }

    return <Form2 />;
  };
}

type AddProductInfoProps = {
  mode: 'add' | 'edit';
  initialValues: any;
  saveProductInfo: (s: any) => void;
  avoidSubmitBehavior?: boolean;
};

export function renderAddProductsInfoForm({
  uomProductData,
}: {
  uomProductData: { id: any; name: any }[];
}): (p: AddProductInfoProps) => JSX.Element {
  return function AddProductsForm({
    mode,
    initialValues,
    saveProductInfo,
    avoidSubmitBehavior,
  }: AddProductInfoProps): JSX.Element {
    const initialOptions = [{ value: '', text: 'Select' }];

    const onSave = async (data) => {
      saveProductInfo(data);
      toast.success('Added product details!');
    };

    const Form = renderFormV2({
      button: {
        avoidSubmitBehavior,
      },
      fieldsData: [
        {
          property: 'quantity',
          type: 'number',
          required: true,
          label: 'Quantity',
          // readonly: mode === 'edit',
          //Validation from Total Quantity
        },
        {
          property: 'uom',
          type: 'select',
          required: true,
          readonly: true,
          label: 'Unit of Measurement UOM',
          options: [
            ...initialOptions,
            ...((uomProductData
              ? uomProductData?.map((m) => ({
                  value: m.name,
                  text: m.name,
                }))
              : '') || []),
          ],
        },
        {
          property: 'deliveryDate',
          type: 'date',
          required: true,
          label: 'Delivery Date',
        },
      ],
      onSubmit: onSave,
      initialFormState: {
        quantity: initialValues.quantity || '',
        uom: uomProductData ? uomProductData.map((i) => i.name) : '',
        deliveryDate: initialValues.deliveryDate || '',
      },
      mapTToU: (r) => {
        return r;
      },
      // style: 'w-1/2',
    });
    return <Form />;
  };
}

function ProductsInfoRender({
  formData,
  field,
  onSave,
  uomProductData,
}): JSX.Element {
  const [props, setProps] = useState<any>(formData[field] || {});
  const [selectedProp, setSelectedProp] = useState<any | null>(null);
  const AddProductInfo = renderAddProductsInfoForm({ uomProductData });
  const Table = renderTableComponent();

  return (
    <div>
      <div className='flex row-reverse'>
        <span
          className='bg-green-500 p-1 ronded-sm float-right cursor-pointer'
          onClick={() => {
            onSave(props);
            toast.success('Saved product details!');
          }}
        >
          Save
        </span>
      </div>

      <Table
        header={[
          [
            { name: 'Quantity' },
            { name: 'UOM' },
            { name: 'Delivery Date' },
            {
              name: '',
            },
          ],
        ]}
        body={_.keys(props).map((x) => {
          return {
            cells: [
              { value: props[x].quantity },
              { value: props[x].uom },
              { value: props[x].deliveryDate },
              {
                value: (
                  <PencilIcon
                    className='w-5 h-5 inline px-1'
                    onClick={() => {
                      setSelectedProp(props[x]);
                    }}
                  />
                ),
              },
            ],
          };
        })}
      />

      {selectedProp ? (
        <AddProductInfo
          mode={'edit'}
          avoidSubmitBehavior={true}
          initialValues={selectedProp}
          saveProductInfo={(x) => {
            setProps((p) => ({ ...p, ...{ [`${moment().unix()}`]: x } }));
            setSelectedProp(null);
          }}
        />
      ) : (
        <AddProductInfo
          mode={'add'}
          initialValues={{}}
          avoidSubmitBehavior={true}
          saveProductInfo={(x) => {
            if (x.quantity) {
              // console.log(x);
              setProps((p) => ({ ...p, ...{ [`${moment().unix()}`]: x } }));
            }
          }}
        />
      )}
    </div>
  );
}
