import { ConfiguratorStatusDetailsType } from '@erp_core/erp-types/dist/modules/notification';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseAuthorizations } from '../../../../hooks/admin/authorization/use-authorizations';
import { UseRoles } from '../../../../hooks/admin/role-admin/use-roles-admin';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';

export type AddConfiguratorDetailsFormType = {
  status: string;
  roles: Array<{ id: string; name: string }>;
  actions: Array<{ id: string; name: string }>;
  users: Array<{ id: string; name: string }>;
  email: string;
  emailContentType: string;
  emailHtml: string;
  emailTemplate: string;
  emailProducer: string;
  notification: string;
  notificationHtml: string;
  notificationProducer: string;
};

type RenderAddConfiguratorDetailsFormProps = {
  useRoles: UseRoles;
  useAuthorizations: UseAuthorizations;
  useUsers: UseUsers;
};

export function renderAddConfiguratorDetailsForm({
  useRoles,
  useAuthorizations,
  useUsers,
}: RenderAddConfiguratorDetailsFormProps) {
  return function AddConfiguratorDetailsForm({
    onSave,
    statusData,
  }: {
    onSave: (data: AddConfiguratorDetailsFormType) => Promise<void>;
    statusData?: ConfiguratorStatusDetailsType;
  }): JSX.Element {
    const formProps: FormProps<AddConfiguratorDetailsFormType> = {
      fieldsData: [
        {
          property: 'status',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'pending', value: 'pending' },
            { text: 'issued', value: 'issued' },
            { text: 'approved', value: 'approved' },
            { text: 'cancelled', value: 'cancelled' },
            { text: 'redeemed', value: 'redeemed' },
          ],
          label: 'Status',
          required: true,
        },
        {
          type: 'searchable-multi-select',
          label: 'Roles',
          property: 'roles',
          searchOptions: {
            useSearch: useRoles,
            onSearchValueSelect: () => {},
          },
          groupName: 'Assign-By',
        },
        {
          type: 'searchable-multi-select',
          label: 'Actions',
          property: 'actions',
          searchOptions: {
            useSearch: useAuthorizations,
            onSearchValueSelect: () => {},
          },
          groupName: 'Assign-By',
        },
        {
          type: 'searchable-multi-select',
          label: 'Users',
          property: 'users',
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: () => {},
          },
          groupName: 'Assign-By',
        },
        {
          property: 'email',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'yes', value: 'yes' },
            { text: 'no', value: 'no' },
          ],
          label: 'Email',
          groupName: 'Email-Group',
        },
        {
          property: 'emailContentType',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'html', value: 'html' },
            { text: 'template', value: 'template' },
          ],
          label: 'Email Content-type',
          groupName: 'Email-Group',
          dependentOn: (formData) => {
            return formData?.email === 'yes';
          },
        },
        {
          property: 'emailHtml',
          type: 'input',
          label: 'Email Html',
          groupName: 'Email-Group',
          dependentOn: (formData) => {
            return (
              formData?.email === 'yes' && formData?.emailContentType === 'html'
            );
          },
        },
        {
          property: 'emailTemplate',
          type: 'input',
          label: 'Email Template',
          groupName: 'Email-Group',
          dependentOn: (formData) => {
            return (
              formData?.email === 'yes' &&
              formData?.emailContentType === 'template'
            );
          },
        },
        {
          property: 'emailProducer',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Generic Email Consumer', value: 'generic_email_consumer' },
            { text: 'Custom Email Consumer', value: 'custom_email_consumer' },
          ],
          label: 'Email Producer',
          groupName: 'Email-Group',
          dependentOn: (formData) => {
            return formData?.email === 'yes';
          },
        },
        {
          property: 'notification',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'yes', value: 'yes' },
            { text: 'no', value: 'no' },
          ],
          label: 'Notification',
          groupName: 'Notification-Group',
        },
        {
          property: 'notificationProducer',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            {
              text: 'Generic Notification Consumer',
              value: 'generic_notification_consumer',
            },
            {
              text: 'Custom Notification Consumer',
              value: 'custom_notification_consumer',
            },
          ],
          label: 'Notification Producer',
          groupName: 'Notification-Group',
          dependentOn: (formData) => {
            return formData?.notification === 'yes';
          },
        },
        {
          property: 'notificationHtml',
          type: 'input',
          label: 'Notification Html',
          groupName: 'Notification-Group',
          dependentOn: (formData) => {
            return (
              formData?.notification === 'yes' &&
              formData?.notificationProducer === 'custom_notification_consumer'
            );
          },
        },
      ],
      initialFormState: {
        status: statusData?.status || '',
        roles: statusData?.roles || [],
        actions: statusData?.actions || [],
        users: statusData?.users || [],
        email: statusData?.email || '',
        emailContentType: statusData?.emailContentType || '',
        emailHtml: statusData?.emailHtml || '',
        emailTemplate: statusData?.emailTemplate || '',
        emailProducer: statusData?.emailProducer || '',
        notification: statusData?.notification || '',
        notificationHtml: statusData?.notificationHtml || '',
        notificationProducer: statusData?.notificationProducer || '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          status: l.status,
          roles: l.roles,
          actions: l.actions,
          users: l.users,
          email: l.email,
          emailContentType: l?.email === 'yes' ? l.emailContentType : '',
          emailHtml: l?.email === 'yes' ? l.emailHtml : '',
          emailTemplate: l?.email === 'yes' ? l.emailTemplate : '',
          emailProducer: l?.email === 'yes' ? l.emailProducer : '',
          notification: l.notification,
          notificationHtml: l?.notification === 'yes' ? l.notificationHtml : '',
          notificationProducer:
            l?.notification === 'yes' ? l.notificationProducer : '',
        } as AddConfiguratorDetailsFormType);
      },
      allowUnchanged: true,
    };

    const Form = renderFormV2<AddConfiguratorDetailsFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<AddConfiguratorDetailsFormType>
    ): AddConfiguratorDetailsFormType {
      return (b as unknown) as AddConfiguratorDetailsFormType;
    }
  };
}
