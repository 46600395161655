import { DeliveryModesShortForm } from '@erp_core/erp-types/dist/modules/constants';
import {
  PurchaseEnquiry,
  PurchaseRequisition,
} from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import {
  ArrowDownTrayIcon,
  ClockIcon,
  PaperClipIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { deliveryModesData } from '../../../../datalayer/repository/in-memory/admin/delivery-mode';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseDeliveryTerms } from '../../../../hooks/constants/delivery-term/use-delivery-terms';
import { UsePaymentTerms } from '../../../../hooks/constants/payment-term/use-payment-terms';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UsePurchaseEnquiries } from '../../../../hooks/inventory/purchase/purchase-enquiry/use-purchase-enquiries';
import { UsePurchaseEnquiry } from '../../../../hooks/inventory/purchase/purchase-enquiry/use-purchase-enquiry';
import { UsePurchaseOrder } from '../../../../hooks/inventory/purchase/purchase-order/use-purchase-order';
import { UsePaginatedPurchaseRequisitions } from '../../../../hooks/inventory/purchase/purchase-requisition/use-paginated-purchase-resources';
import { UsePurchaseRequisition } from '../../../../hooks/inventory/purchase/purchase-requisition/use-purchase-requisition';
import { UsePurchaseRequisitions } from '../../../../hooks/inventory/purchase/purchase-requisition/use-purchase-requisitions';
import { UseVendors } from '../../../../hooks/inventory/purchase/vendors/use-vendors';
import { UseBom } from '../../../../hooks/order/work-order/bom/use-bom';
import { ItemInterface } from '../../../../models/interfaces/inventory/item';
import { VendorInterface } from '../../../../models/interfaces/order/purchase/vendors';
import { renderBOMName } from '../../../common/fragments/bom-name';
import { UserRendererInterface } from '../../../common/fragments/user';
import { downloadLatestSpec } from '../../../inventory/items/utils/item-spec-downloader';
import { renderPREditForm } from './forms/edit';
import { renderVendorSelectForEnquiry } from './forms/enquiry-draft';
import { DeliverySchedule } from './forms/enquiry/delivery-schedule';
import {
  CreateEnquiryFormType,
  renderCreateEnquiryForm,
} from './forms/enquiry/enquiry';
import { PaymentSchedule } from './forms/enquiry/payment-schedule';
import { EnquiryFormData } from './types/enquiry-form';
import { PrEditFormData, PrEditSaveData, PrFilter } from './types/pr-edit-form';

type PORequisitionProps = {
  useUsers: UseUsers;
  usePaginatedPurchaseRequisitions: UsePaginatedPurchaseRequisitions;
  usePurchaseRequisitions: UsePurchaseRequisitions;
  useDeliveryTerms: UseDeliveryTerms;
  usePurchaseRequisition: UsePurchaseRequisition;
  useVendors: UseVendors;
  usePurchaseEnquiry: UsePurchaseEnquiry;
  usePurchaseOrder: UsePurchaseOrder;
  useBom: UseBom;
  usePaymentTerms: UsePaymentTerms;
  vendorService: VendorInterface;
  usePurchaseEnquiries: UsePurchaseEnquiries;
  useUserAuthorization: UseUserAuthorization;
  useFileTransfer: UseFileTransfer;
  itemService: ItemInterface;
  userRenderService: UserRendererInterface;
};

export function renderPurchaseOrderRequisition({
  useUsers,
  usePurchaseRequisition,
  useDeliveryTerms,
  usePurchaseRequisitions,
  usePaginatedPurchaseRequisitions,
  usePaymentTerms,
  useVendors,
  usePurchaseEnquiry,
  usePurchaseOrder,
  useBom,
  vendorService,
  usePurchaseEnquiries,
  useUserAuthorization,
  useFileTransfer,
  itemService,
  userRenderService,
}: PORequisitionProps): () => JSX.Element {
  const Card = renderCardComponent();
  const BOMName = renderBOMName({ useBom });
  const PaginatedTable = renderTableWithMapperComponent<any, any>();

  return function PurchaseOrderRequisition(): JSX.Element {
    const { syncSet: setPurchaseRequisition } = usePurchaseRequisition();
    const { data: deliveryTermsOptions, getAll: getDT } = useDeliveryTerms();
    const { get: getAllowedActions } = useUserAuthorization();

    const {
      data: purchaseEnquiry,
      syncSet: setPurchaseEnquiry,
    } = usePurchaseEnquiry();
    const {
      data: purchaseOrder,
      // syncSet: setPurchaseOrder,
    } = usePurchaseOrder();
    // const { set: setAllocation } = useVirtualItemAllocate();

    useEffect(() => {
      getDT();
      //   getPT();
      // eslint-disable-next-line
    }, []);

    const { user: currentUser } = useContext(UserContext);
    const { list, get: getFile } = useFileTransfer();
    const { companyGroup: currentCompanyGroup } = useContext(CurrentContext);

    useEffect(() => {
      if (purchaseEnquiry.id) {
        toast(
          <div
            className='cursor-pointer'
            onClick={() =>
              (window.location.href =
                '/purchase/registers/purchase-enquiry-register')
            }
          >
            <div>Enquiry created</div>
            <div>Click to view</div>
          </div>
        );
      }
    }, [purchaseEnquiry]);

    const handleSaveEdit = async (
      data: PrEditSaveData,
      activeItem: PurchaseRequisition
    ) => {
      const activePr = activeItem;
      const finalData = {
        id: activePr?.id,
        details: {
          quantity: data.quantity,
          remarks: data.remarks ? data.remarks : activePr?.details.remarks,
          targetDate: data.targetDate,
        },
      };

      try {
        await setPurchaseRequisition(
          (finalData as unknown) as PurchaseRequisition
        );
      } catch (error) {
        toast((error as any).message);
      }
    };

    type E = {
      deliverySchedule: DeliverySchedule[];
      paymentSchedule: PaymentSchedule[];
    };
    const handleCreateEnquiry = async (
      data: CreateEnquiryFormType & E,
      activeItem: PurchaseRequisition
    ) => {
      const activePr = activeItem;
      const prActivity = {
        action: 'raised-enquiry',
        details: '',
        user: currentUser,
        event: `${currentUser.name} created an enquiry`,
        time: moment.utc().format(),
      };

      const finalData = {
        name: data.name,
        purchaseRequisition: { id: activePr?.id },
        suppliers: data.suppliers,
        vendors: data.vendors,
        details: {
          paymentTerm: data.paymentTerm,
          deliveryMode: deliveryModesData.find(
            (i) => i.id === data.deliveryMode
          ) || { id: '' as DeliveryModesShortForm, name: '' },
          deliveryTerm: data.deliveryTerm,
          validity: Number(data.validity),
          deliverySchedule: data.deliverySchedule,
          paymentSchedule: {
            amountIn: 'per',
            schedule: data.paymentSchedule,
          },
          status: 'open',
          activity: [prActivity],
        },
        extra: {
          suppliers: data.suppliers,
          vendors: data.vendors,
        },
      };

      try {
        const pe = await setPurchaseEnquiry(
          (finalData as unknown) as PurchaseEnquiry
        );
        const prUpdates = {
          id: activePr?.id,
          status: 'raised-enquiry',
          details: {
            activity: activePr?.details.activity
              ? [prActivity, ...activePr.details.activity]
              : [prActivity],
            enquiryId: pe.id,
          },
        };
        await setPurchaseRequisition(prUpdates as PurchaseRequisition);
      } catch (error) {
        toast((error as any).message);
      }
    };

    useEffect(() => {
      if (purchaseOrder.id) {
        toast(
          <div
            className='cursor-pointer'
            onClick={() => (window.location.href = '/inventory/purchase-order')}
          >
            <div>PO created</div>
            <div>Click to view</div>
          </div>
        );
      }
    }, [purchaseOrder]);

    const tableHeader: TableHeader = [
      [
        { name: 'PR' },
        { name: 'Item name' },
        { name: 'Target Date' },
        { name: 'PR Qty' },
        { name: 'Status' },
        { name: 'Created By' },
        { name: 'Summary' },
        {
          name: <PaperClipIcon className='w-5 inline text-white fill-black' />,
        },
      ],
    ];

    const cardHeader: CardHeader = {
      title: 'Purchase Requisition Register',
    };

    const filter: Filter<PrFilter> = {
      version: 'v2',
      sortFields: [
        {
          key: 'PR',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'createdBy',
          type: 'search-select',
          value: '',
        },
        {
          key: 'lastModifiedBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: PrFilter) => {
        const filterData: PrFilter = {};

        if (filterSelection.createdBy) {
          filterData.createdBy = filterSelection.createdBy;
        }
        if (filterSelection.lastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.lastModifiedBy;
        }
        return filterData as PrFilter;
      },
      filterResources: {
        createdBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
        lastModifiedBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const getItemDetails = async (id: string) => {
      const resp = await itemService.get(id);
      return resp;
    };

    const bodyMapper = (item: PurchaseRequisition) => {
      const cells: Array<TableCell> = [
        {
          value: <>{item.name || item.id}</>,
          link: `/purchase/registers/purchase-requisitions/${item.id}`,
        },
        {
          value: (
            <>
              <div className='flex'>
                <div>
                  <div className='font-semibold'>{item.grade.name}</div>
                  <div className='font-thin'>{item.grade.item?.name}</div>
                </div>
              </div>
            </>
          ),
          link: item.grade.item?.id
            ? `/inventory/masters/items/${item.grade.item?.id}/properties`
            : `/purchase/items/${item.bomIds}`,
        },

        {
          value: (
            <span>
              <ClockIcon className='w-5 inline text-blue-800' />{' '}
              {item.details.targetDate}
            </span>
          ),
        },
        // {
        //   value: item.details.priority || 'medium',
        //   style: PrPriorityColor[item.details.priority || 'medium'],
        // },
        {
          value: (
            <>
              {item.details.quantity} {item.itemDetails.uom}
            </>
          ),
        },
        {
          value: item.status,
        },
        {
          value: (
            <userRenderService.userCard
              size='small'
              link={true}
              id={item.createdBy.id}
              name={item.createdBy.name}
            />
          ),
        },
        {
          value: (
            <div>
              {item.details?.remarks ? (
                <div>Remarks: {item.details.remarks}</div>
              ) : null}
              <div>
                BOMs:{' '}
                {item.bomIds.map((id, idx) => (
                  <BOMName key={`${id}-${idx}`} id={id} />
                ))}
              </div>
            </div>
          ),
          style: 'w-1/4',
        },
        {
          value: (
            <div className='flex justify-center'>
              <ArrowDownTrayIcon
                className='h-6 w-6 inline cursor-pointer'
                onClick={async () => {
                  const itemDetails = await getItemDetails(item.grade.item.id);
                  downloadLatestSpec({
                    companyGroupId: currentCompanyGroup.id,
                    stockGroupId: itemDetails.stockgroup.id,
                    itemId: itemDetails.id,
                    itemName: itemDetails.name,
                    list: list,
                    getFile: getFile,
                  });
                }}
              />
            </div>
          ),
        },
      ];
      return {
        cells,
        rowData: { purchaseRequisition: item },
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <PaginatedTable
            auth={{ actions: getAllowedActions().actions }}
            header={tableHeader}
            bodyMapper={bodyMapper}
            actions={[
              {
                name: 'Edit',
                auth: 'UI:EDIT-PR:VIEW',
                show: ({
                  purchaseRequisition,
                }: {
                  purchaseRequisition: PurchaseRequisition;
                }) => {
                  if (
                    ['enquiry-draft', 'pr-open'].includes(
                      purchaseRequisition.status
                    )
                  ) {
                    return true;
                  }

                  return false;
                },
                behaviour: 'modal',
                modal: {
                  title: 'Edit',
                  content: ({ data, onClose }) => {
                    const activePr = data.purchaseRequisition;
                    const prEditFormData = {
                      item: activePr?.itemDetails.name,
                      uom: activePr?.itemDetails.uom,
                      quantity: activePr?.details.quantity,
                      targetDate: activePr?.details.targetDate,
                    };
                    const PurchaseRequisitionEditForm = renderPREditForm({
                      prData: prEditFormData as PrEditFormData,
                      onSave: (data) => handleSaveEdit(data, activePr),
                    });
                    return <PurchaseRequisitionEditForm />;
                  },
                },
              },
              {
                // TODO: Delete
                name: 'create enquiry',
                behaviour: 'modal',
                modal: {
                  title: 'Create Enquiry',
                  content: ({ data, onClose }) => {
                    const activePr = data.purchaseRequisition;
                    const createEnquiryFormData = {
                      itemId: activePr?.itemDetails.id,
                      item: activePr?.itemDetails.name,
                      uom: activePr?.itemDetails.uom,
                      quantity: activePr?.details.quantity,
                      targetDate: activePr?.details.targetDate,
                    };

                    const CreateEnquiryForm = renderCreateEnquiryForm({
                      usePaymentTerms,
                      useVendors: useVendors,
                      useDeliveryTerms,
                      efData: createEnquiryFormData as EnquiryFormData,
                      onSave: (data) =>
                        handleCreateEnquiry(data, data.purchaseRequisition),
                      usePurchaseEnquiries,
                      vendorService,
                    });

                    return <CreateEnquiryForm />;
                  },
                },
                auth: 'UI:CREATE-PE:VIEW',
                show: (p) => false,
              },
              {
                name: 'Enquiry Draft',
                auth: 'UI:CREATE-PE:VIEW',
                show: (p) => {
                  if (p.purchaseRequisition.status === 'pr-open') {
                    return true;
                  }
                  return false;
                },
                behaviour: 'modal',
                modal: {
                  title: 'Enquiry Draft',
                  size: 'large',
                  content: ({ data, onClose }) => {
                    const CreateEnquiry = renderVendorSelectForEnquiry({
                      useBom,
                      purchaseRequisition: data.purchaseRequisition,
                      onClose,
                      useVendors,
                      usePurchaseRequisitions,
                      setPurchaseEnquiry,
                      setPurchaseRequisition,
                      // paymentTermsOptions: paylmentTermsOptions || [],
                      // deliveryModeOptions: deliveryModesData,
                      modeOfTransportOptions: deliveryModesData,
                      deliveryTermsOptions: deliveryTermsOptions || [],
                    });
                    return <CreateEnquiry />;
                  },
                },
              },
            ]}
            useResources={usePurchaseRequisitions}
            filter={filter}
            pagination={{
              enabled: true,
              usePaginatedResources: usePaginatedPurchaseRequisitions,
            }}
          />
        </div>
      ),
    };

    return <Card header={cardHeader} body={cardBody} />;
  };
}
