import {
  ActionType,
  Authorization,
  Company,
  CompanyFilterType,
  CompanyGroup,
  CompanyGroupSetting,
  LocationFilterType,
  LocationType,
  ModuleType,
  NotificationAction,
  OnboardEmployeeFilterType,
  OnboardEmployeeType,
  ResourceType,
  RoleType,
  User,
  UsersFilterType,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  AppearanceFilterType,
  AppearanceType,
  ColorFilterType,
  ColorType,
  ConstantsFilterType,
  CurrencyType,
  DeliveryTerm,
  EmployeeCategory,
  EmployeeDesignation,
  GSTType,
  HsnFilterType,
  HsnType,
  MetricsType,
  OdourFilterType,
  OdourType,
  PaymentTerm,
} from '@erp_core/erp-types/dist/modules/constants';
import {
  DepartmentAppType,
  DepartmentFilterType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { ErpV2ApiAdapter } from '../adapter/erp-v2-api';
import { HttpActionAdminRepo } from '../datalayer/repository/http/admin/action';
import { HttpAuthorizationRepo } from '../datalayer/repository/http/admin/authorization';
import { HttpCompanyRepo } from '../datalayer/repository/http/admin/company';
import {
  CompanyGroupFilter,
  HttpCompanyGroupRepo,
} from '../datalayer/repository/http/admin/company-group';
import { HttpCompanyGroupSettingRepo } from '../datalayer/repository/http/admin/company-group-settings';
import { HttpAppearanceRepo } from '../datalayer/repository/http/admin/constants/appearance';
import { HttpColorRepo } from '../datalayer/repository/http/admin/constants/color';
import { HttpCurrencyRepo } from '../datalayer/repository/http/admin/constants/currency';
import { HttpDeliveryTermRepo } from '../datalayer/repository/http/admin/constants/delivery-term';
import { HttpEmployeeCategoryRepo } from '../datalayer/repository/http/admin/constants/employee-category';
import { HttpEmployeeDesignationRepo } from '../datalayer/repository/http/admin/constants/employee-designation';
import { HttpGstRepo } from '../datalayer/repository/http/admin/constants/gst';
import { HttpHsnRepo } from '../datalayer/repository/http/admin/constants/hsn';
import { HttpMetricsRepo } from '../datalayer/repository/http/admin/constants/metrics';
import { HttpOdourRepo } from '../datalayer/repository/http/admin/constants/odour';
import { HttpPaymentTermRepo } from '../datalayer/repository/http/admin/constants/payment-term';
import { HttpLocationsRepo } from '../datalayer/repository/http/admin/location';
import { HttpModuleAdminRepo } from '../datalayer/repository/http/admin/module';
import { HttpNotificationActionRepo } from '../datalayer/repository/http/admin/notification-action';
import { HttpOnboardEmployeeRepo } from '../datalayer/repository/http/admin/onborad-employee';
import { HttpResourceAdminRepo } from '../datalayer/repository/http/admin/resource';
import { HttpRoleAdminRepo } from '../datalayer/repository/http/admin/role';
import { HttpUsersRepo } from '../datalayer/repository/http/admin/user';
import { HttpUserRolesRepo } from '../datalayer/repository/http/admin/user-roles';
import { HttpDepartmentRepo } from '../datalayer/repository/http/hrd/department';
import { HttpLoginRepo } from '../datalayer/repository/http/login';
import { HttpResetPasswordRepo } from '../datalayer/repository/http/reset-password';
import { HttpSetPasswordRepo } from '../datalayer/repository/http/set-password';
import { createUseCurrentCompany } from '../hooks/admin/company-admin/use-current-company';
import { createUseCurrentCompanyGroup } from '../hooks/admin/company-group-admin/use-current-company-group';
import { createUseCurrentLocation } from '../hooks/admin/location-admin/use-current-location';
import { createUseCurrentUserRoles } from '../hooks/admin/role-admin/use-current-user-roles';
import { createUseSisterLocation } from '../hooks/admin/sister-location/use-sister-location';
import { createUseCurrentUser } from '../hooks/admin/user-admin/use-current-user';
import { createUseUserRolesAdmin } from '../hooks/admin/user-admin/use-user-roles-admin';
import { createUseUserAuthorization } from '../hooks/admin/user-authorization/use-user-authorization';
import { DeliveryTermFilter } from '../hooks/constants/delivery-term/use-delivery-terms';
import { PaymentTermFilter } from '../hooks/constants/payment-term/use-payment-terms';
import { createUseLogin } from '../hooks/login/use-login';
import { createUseLogout } from '../hooks/logout/use-log-out';
import { createUseResetPassword } from '../hooks/reset-password/use-reset-password';
import { createUsePaginatedResources } from '../hooks/resource/use-paginated-resources';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { createUseSetPassword } from '../hooks/set-password/use-set-password';
import {
  ActionAdminInterface,
  ActionFilterType,
} from '../models/interfaces/admin/action-admin';
import {
  AuthorizationFilter,
  AuthorizationInterface,
} from '../models/interfaces/admin/authorization';
import { CompanyAdminInterface } from '../models/interfaces/admin/company-admin';
import { CompanyGroupAdminInterface } from '../models/interfaces/admin/company-group';
import {
  CompanyGroupSettingFilterType,
  CompanyGroupSettingInterface,
} from '../models/interfaces/admin/company-group-settings';
import { AppearanceInterface } from '../models/interfaces/admin/constants/appearance';
import { ColorInterface } from '../models/interfaces/admin/constants/color';
import { CurrencyInterface } from '../models/interfaces/admin/constants/currency';
import { DeliveryTermsInterface } from '../models/interfaces/admin/constants/delivery-term';
import { EmployeeCategoryInterface } from '../models/interfaces/admin/constants/employee-category';
import { EmployeeDesignationInterface } from '../models/interfaces/admin/constants/employee-designation';
import { GstInterface } from '../models/interfaces/admin/constants/gst';
import { HsnInterface } from '../models/interfaces/admin/constants/hsn';
import { MetricsInterface } from '../models/interfaces/admin/constants/metrics';
import { OdourInterface } from '../models/interfaces/admin/constants/odour';
import { PaymentTermsInterface } from '../models/interfaces/admin/constants/payment-term';
import { DepartmentInterface } from '../models/interfaces/admin/department';
import { LocationInterface } from '../models/interfaces/admin/location-admin';
import {
  ModuleAdminInterface,
  ModuleFilterType,
} from '../models/interfaces/admin/module-admin';
import {
  NotificationActionFilter,
  NotificationActionInterface,
} from '../models/interfaces/admin/notification-action';
import { OnboardEmployeeInterface } from '../models/interfaces/admin/onboard-employee';
import {
  ResourceAdminFilterType,
  ResourceAdminInterface,
} from '../models/interfaces/admin/resource-admin';
import {
  RoleAdminInterface,
  RoleFilterType,
} from '../models/interfaces/admin/role-admin';
import { UserAdminInterface } from '../models/interfaces/admin/user-admin';
import { ADMIN_SERVER_URI } from './server-uri';

export const erpV2Api = new ErpV2ApiAdapter({ adminApiUrl: ADMIN_SERVER_URI });

// ADMIN DOMAIN
export const loginService = new HttpLoginRepo(ADMIN_SERVER_URI, erpV2Api);
export const useLogin = createUseLogin({
  useLoginService: () => loginService,
});
export const useLogout = createUseLogout({});

export const resetPasswordService = new HttpResetPasswordRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useResetPassword = createUseResetPassword({
  useResetPasswordService: () => resetPasswordService,
});

export const setPasswordService = new HttpSetPasswordRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useSetPassword = createUseSetPassword({
  useSetPasswordService: () => setPasswordService,
});

export const locationsService = new HttpLocationsRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useLocations = createUseResources<
  LocationType,
  LocationFilterType,
  LocationInterface
>({
  useService: () => locationsService,
});
export const useLocationAdmin = createUseResource<
  LocationType,
  LocationFilterType,
  LocationInterface
>({
  useService: () => locationsService,
});

export const roleAdminService = new HttpRoleAdminRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useRoleAdmin = createUseResource<
  RoleType,
  RoleFilterType,
  RoleAdminInterface
>({
  useService: () => roleAdminService,
});
export const useGetAllRoles = createUseResources<
  RoleType & { isSystemRole: boolean },
  RoleFilterType,
  RoleAdminInterface
>({
  useService: () => roleAdminService,
});
export const usePaginatedRoles = createUsePaginatedResources<
  RoleType,
  RoleFilterType,
  RoleAdminInterface
>({
  useService: () => roleAdminService,
});

export const moduleAdminService = new HttpModuleAdminRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useModules = createUseResources<
  ModuleType,
  ModuleFilterType,
  ModuleAdminInterface
>({
  useService: () => moduleAdminService,
});

export const useModuleAdmin = createUseResource<
  ModuleType,
  ModuleFilterType,
  ModuleAdminInterface
>({
  useService: () => moduleAdminService,
});

export const resourceAdminService = new HttpResourceAdminRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useResourcesAdmin = createUseResources<
  ResourceType,
  ResourceAdminFilterType,
  ResourceAdminInterface
>({
  useService: () => resourceAdminService,
});

export const usePaginatedResourcesAdmin = createUsePaginatedResources<
  ResourceType,
  ResourceAdminFilterType,
  ResourceAdminInterface
>({
  useService: () => resourceAdminService,
});

export const useResourceAdmin = createUseResource<
  ResourceType,
  ResourceAdminFilterType,
  ResourceAdminInterface
>({
  useService: () => resourceAdminService,
});

export const actionAdminService = new HttpActionAdminRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useActions = createUseResources<
  ActionType,
  ActionFilterType,
  ActionAdminInterface
>({
  useService: () => actionAdminService,
});

export const useActionAdmin = createUseResource<
  ActionType,
  ActionFilterType,
  ActionAdminInterface
>({
  useService: () => actionAdminService,
});

export const companyAdminService = new HttpCompanyRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useCompanies = createUseResources<
  Company,
  CompanyFilterType,
  CompanyAdminInterface
>({
  useService: () => companyAdminService,
});

export const useCompanyAdmin = createUseResource<
  Company,
  CompanyFilterType,
  CompanyAdminInterface
>({
  useService: () => companyAdminService,
});

export const companyGroupAdminService = new HttpCompanyGroupRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const userAdminService = new HttpUsersRepo(ADMIN_SERVER_URI, erpV2Api);
export const useUsers = createUseResources<
  User,
  UsersFilterType,
  UserAdminInterface
>({
  useService: () => userAdminService,
});

export const usePaginatedUsers = createUsePaginatedResources<
  User,
  UsersFilterType,
  UserAdminInterface
>({
  useService: () => userAdminService,
});

export const useUserAdmin = createUseResource<
  User,
  UsersFilterType,
  UserAdminInterface
>({
  useService: () => userAdminService,
});

export const userRolesAdminService = new HttpUserRolesRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useUserRoles = createUseUserRolesAdmin({
  useUserRolesAdminService: () => userRolesAdminService,
});

export const companyGroupSettingService = new HttpCompanyGroupSettingRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useCompanyGroupSetting = createUseResource<
  CompanyGroupSetting,
  CompanyGroupSettingFilterType,
  CompanyGroupSettingInterface
>({
  useService: () => companyGroupSettingService,
});

export const useCompanyGroupSettings = createUseResources<
  CompanyGroupSetting,
  CompanyGroupSettingFilterType,
  CompanyGroupSettingInterface
>({
  useService: () => companyGroupSettingService,
});

export const authorizationService = new HttpAuthorizationRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useAuthorization = createUseResource<
  Authorization,
  AuthorizationFilter,
  AuthorizationInterface
>({ useService: () => authorizationService });
export const useAuthorizations = createUseResources<
  Authorization,
  AuthorizationFilter,
  AuthorizationInterface
>({ useService: () => authorizationService });

export const usePaginatedAuthorizations = createUsePaginatedResources<
  Authorization,
  AuthorizationFilter,
  AuthorizationInterface
>({ useService: () => authorizationService });

export const notificationActionService = new HttpNotificationActionRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useNotificationAction = createUseResource<
  NotificationAction,
  NotificationActionFilter,
  NotificationActionInterface
>({ useService: () => notificationActionService });
export const useNotificationActions = createUseResources<
  NotificationAction,
  NotificationActionFilter,
  NotificationActionInterface
>({ useService: () => notificationActionService });

export const employeeCategoryService = new HttpEmployeeCategoryRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useEmployeeCategories = createUseResources<
  EmployeeCategory,
  { search?: string },
  EmployeeCategoryInterface
>({
  useService: () => employeeCategoryService,
});

export const useEmployeeCategory = createUseResource<
  EmployeeCategory,
  { search?: string },
  EmployeeCategoryInterface
>({
  useService: () => employeeCategoryService,
});

export const usePaginatedEmployeeCategories = createUsePaginatedResources<
  EmployeeCategory,
  { search?: string },
  EmployeeCategoryInterface
>({
  useService: () => employeeCategoryService,
});

export const employeeDesignationService = new HttpEmployeeDesignationRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useEmployeeDesignations = createUseResources<
  EmployeeDesignation,
  { search?: string },
  EmployeeDesignationInterface
>({
  useService: () => employeeDesignationService,
});

export const useEmployeeDesignation = createUseResource<
  EmployeeDesignation,
  { search?: string },
  EmployeeDesignationInterface
>({
  useService: () => employeeDesignationService,
});

export const usePaginatedEmployeeDesignations = createUsePaginatedResources<
  EmployeeDesignation,
  { search?: string },
  EmployeeDesignationInterface
>({
  useService: () => employeeDesignationService,
});

export const useCurrentUser = createUseCurrentUser({
  useUserAdminService: () => userAdminService,
});
export const useCurrentCompanyGroup = createUseCurrentCompanyGroup({
  useCompanyGroupService: () => companyGroupAdminService,
});
export const useCompanyGroupAdmin = createUseResource<
  CompanyGroup,
  CompanyGroupFilter,
  CompanyGroupAdminInterface
>({
  useService: () => companyGroupAdminService,
});
export const useCurrentCompany = createUseCurrentCompany({
  useCompanyAdminService: () => companyAdminService,
  useCurrentCompanyGroup,
  useCurrentUser,
});
export const useCurrentLocation = createUseCurrentLocation({
  useLocationsService: () => locationsService,
  useCurrentCompany,
  useCurrentCompanyGroup,
  useCurrentUser,
});
export const useCurrentUserRoles = createUseCurrentUserRoles({
  useUser: useUserAdmin,
});
export const useUserAuthorization = createUseUserAuthorization({
  userService: userAdminService,
});
export const useSisterLocation = createUseSisterLocation({
  useCompanyGroupSettings,
});

// CONSTANTS DOMAIN
export const currencyService = new HttpCurrencyRepo(ADMIN_SERVER_URI, erpV2Api);
export const useCurrencies = createUseResources<
  CurrencyType,
  ConstantsFilterType,
  CurrencyInterface
>({
  useService: () => currencyService,
});

export const useCurrency = createUseResource<
  CurrencyType,
  ConstantsFilterType,
  CurrencyInterface
>({
  useService: () => currencyService,
});

export const usePaginatedCurrencies = createUsePaginatedResources<
  CurrencyType,
  ConstantsFilterType,
  CurrencyInterface
>({
  useService: () => currencyService,
});

export const deliveryTermsService = new HttpDeliveryTermRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useDeliveryTerm = createUseResource<
  DeliveryTerm,
  DeliveryTermFilter,
  DeliveryTermsInterface
>({ useService: () => deliveryTermsService });

export const useDeliveryTerms = createUseResources<
  DeliveryTerm,
  DeliveryTermFilter,
  DeliveryTermsInterface
>({ useService: () => deliveryTermsService });

export const usePaginatedDeliveryTerms = createUsePaginatedResources<
  DeliveryTerm,
  DeliveryTermFilter,
  DeliveryTermsInterface
>({ useService: () => deliveryTermsService });

export const paymentTermsService = new HttpPaymentTermRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const usePaymentTerm = createUseResource<
  PaymentTerm,
  PaymentTermFilter,
  PaymentTermsInterface
>({ useService: () => paymentTermsService });

export const usePaymentTerms = createUseResources<
  PaymentTerm,
  PaymentTermFilter,
  PaymentTermsInterface
>({ useService: () => paymentTermsService });

export const usePaginatedPaymentTerms = createUsePaginatedResources<
  PaymentTerm,
  DeliveryTermFilter,
  PaymentTermsInterface
>({ useService: () => paymentTermsService });

export const gstService = new HttpGstRepo(ADMIN_SERVER_URI, erpV2Api);
export const useGsts = createUseResources<
  GSTType,
  ConstantsFilterType,
  GstInterface
>({
  useService: () => gstService,
});

export const useGst = createUseResource<
  GSTType,
  ConstantsFilterType,
  GstInterface
>({
  useService: () => gstService,
});

export const usePaginatedGST = createUsePaginatedResources<
  GSTType,
  ConstantsFilterType,
  GstInterface
>({
  useService: () => gstService,
});

export const hsnService = new HttpHsnRepo(ADMIN_SERVER_URI, erpV2Api);
export const useHsns = createUseResources<HsnType, HsnFilterType, HsnInterface>(
  {
    useService: () => hsnService,
  }
);

export const useHsn = createUseResource<HsnType, HsnFilterType, HsnInterface>({
  useService: () => hsnService,
});

export const usePaginatedHsns = createUsePaginatedResources<
  HsnType,
  HsnFilterType,
  HsnInterface
>({
  useService: () => hsnService,
});

export const metricsService = new HttpMetricsRepo(ADMIN_SERVER_URI, erpV2Api);
export const useMetrics = createUseResources<
  MetricsType,
  ConstantsFilterType,
  MetricsInterface
>({
  useService: () => metricsService,
});

export const useMetric = createUseResource<
  MetricsType,
  ConstantsFilterType,
  MetricsInterface
>({
  useService: () => metricsService,
});

export const usePaginatedMetrics = createUsePaginatedResources<
  MetricsType,
  ConstantsFilterType,
  MetricsInterface
>({
  useService: () => metricsService,
});
export const departmentService = new HttpDepartmentRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useDepartment = createUseResource<
  DepartmentAppType,
  DepartmentFilterType,
  DepartmentInterface
>({ useService: () => departmentService });

export const useDepartments = createUseResources<
  DepartmentAppType,
  DepartmentFilterType,
  DepartmentInterface
>({ useService: () => departmentService });

export const onboardEmployeeService = new HttpOnboardEmployeeRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useOnboardEmployee = createUseResource<
  OnboardEmployeeType,
  OnboardEmployeeFilterType,
  OnboardEmployeeInterface
>({ useService: () => onboardEmployeeService });

export const useOnboardEmployees = createUseResources<
  OnboardEmployeeType,
  OnboardEmployeeFilterType,
  OnboardEmployeeInterface
>({ useService: () => onboardEmployeeService });

export const appearanceService = new HttpAppearanceRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useAppearances = createUseResources<
  AppearanceType,
  AppearanceFilterType,
  AppearanceInterface
>({
  useService: () => appearanceService,
});

export const useAppearance = createUseResource<
  AppearanceType,
  AppearanceFilterType,
  AppearanceInterface
>({
  useService: () => appearanceService,
});

export const usePaginatedAppearances = createUsePaginatedResources<
  AppearanceType,
  AppearanceFilterType,
  AppearanceInterface
>({
  useService: () => appearanceService,
});

export const odourService = new HttpOdourRepo(ADMIN_SERVER_URI, erpV2Api);
export const useOdours = createUseResources<
  OdourType,
  OdourFilterType,
  OdourInterface
>({
  useService: () => odourService,
});

export const useOdour = createUseResource<
  OdourType,
  OdourFilterType,
  OdourInterface
>({
  useService: () => odourService,
});

export const usePaginatedOdours = createUsePaginatedResources<
  OdourType,
  OdourFilterType,
  OdourInterface
>({
  useService: () => odourService,
});

export const colorService = new HttpColorRepo(ADMIN_SERVER_URI, erpV2Api);
export const useColors = createUseResources<
  ColorType,
  ColorFilterType,
  ColorInterface
>({
  useService: () => colorService,
});

export const useColor = createUseResource<
  ColorType,
  ColorFilterType,
  ColorInterface
>({
  useService: () => colorService,
});

export const usePaginatedColors = createUsePaginatedResources<
  ColorType,
  ColorFilterType,
  ColorInterface
>({
  useService: () => colorService,
});
