import { EmployeeEducationType } from '@erp_core/erp-types/dist/modules/hrd';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFileViewerUploader,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../../../contexts/current';
import { UseUserAuthorization } from '../../../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseFileTransfer } from '../../../../../../hooks/file-transfer/use-file-transfer';
import { UseEmployeeEducation } from '../../../../../../hooks/hrd/employee-education-dtls/use-employee-education';
import { UseEmployeeEducations } from '../../../../../../hooks/hrd/employee-education-dtls/use-employee-educations';
import { UserRendererInterface } from '../../../../../common/fragments/user';
import { renderAddEmployeeEducationForm } from '../../education/employee-education-add-form';

type RenderEmployeeEducationProps = {
  useEmployeeEducations: UseEmployeeEducations;
  employee: EmployeeProfileType;
  useEmployeeEducation: UseEmployeeEducation;
  useFileTransfer: UseFileTransfer;
  useUserAuthorization: UseUserAuthorization;
  isHr: boolean;
  userRendererService: UserRendererInterface;
};

export function renderEmployeeEducationDetails(): ({
  useEmployeeEducations,
  employee,
  useEmployeeEducation,
  useFileTransfer,
  useUserAuthorization,
}: RenderEmployeeEducationProps) => JSX.Element {
  return function EmployeeEducation({
    useEmployeeEducations,
    useEmployeeEducation,
    employee,
    useFileTransfer,
    useUserAuthorization,
    isHr,
    userRendererService,
  }: RenderEmployeeEducationProps): JSX.Element {
    const FileViewerUploader = renderFileViewerUploader();

    const {
      data: educations,
      getAll: getEmployeeEducations,
    } = useEmployeeEducations();

    const { syncSet: setEducation } = useEmployeeEducation();

    const { company, companyGroup } = useContext(CurrentContext);

    const { get: getAllowedActions } = useUserAuthorization();

    useEffect(() => {}, [educations]);

    useEffect(() => {
      getEmployeeEducations({ employeeId: employee.id });
      // eslint-disable-next-line
    }, []);

    function addActions(): TableActionsType[] {
      return [
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          name: 'Edit',
          show: ({ education }: { education: EmployeeEducationType }) => {
            // we want to give ability to edit only when the gatepass
            // is in pending state.
            return true;
          },
          behaviour: 'modal',
          modal: {
            title: 'Edit',
            content: ({
              data: { education },
              onClose,
            }: {
              data: {
                education: EmployeeEducationType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <Form
                    onSave={async (e) => {
                      await editEducation(
                        e,
                        education.id,
                        education.employee.id
                      );
                      onClose();
                    }}
                    data={education as any}
                  />
                </div>
              );
            },
          },
        },
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          name: 'Attach Document',
          show: ({ education }: { education: EmployeeEducationType }) => {
            // we want to give ability to edit only when the gatepass
            // is in pending state.
            return true;
          },
          behaviour: 'modal',
          modal: {
            title: 'Attach Document',
            content: ({
              data: { education },
              onClose,
            }: {
              data: {
                education: EmployeeEducationType;
              };
              onClose: () => void;
            }) => {
              return (
                <FileViewerUploader
                  mode={isHr ? 'upload' : 'view'}
                  url={education.document}
                  useFileTransfer={useFileTransfer}
                  path={computePath(education)}
                  type='private'
                  onUpload={async (path) => {
                    let registrationDoc = path.url;
                    if (registrationDoc) {
                      registrationDoc = path.url;
                      await setEducation({
                        id: education.id,
                        document: registrationDoc,
                      } as any);
                    }
                  }}
                />
              );
            },
          },
        },
      ];
    }

    const editEducation = async (form: any, id: string, employeeId: string) => {
      const finalData = {
        id,
        ...form,
      };
      await setEducation(finalData);
      toast('Education details edited sucessfully');
      getEmployeeEducations({ employeeId: employeeId });
    };

    const Form = renderAddEmployeeEducationForm();

    const saveEducation = async (form: Partial<EmployeeEducationType>) => {
      try {
        const finalData = {
          ...form,
          employee: {
            id: employee.id,
            name: employee.name,
          },
        } as EmployeeEducationType;
        await setEducation(finalData as EmployeeEducationType);
        toast('Data added sucessfully');
        getEmployeeEducations({ employeeId: employee.id });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Employee Education Details',
      actions: [
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          type: 'button',
          button: {
            style: 'bg-white',
            behaviour: 'modal',
            modal: {
              title: 'Add Education',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveEducation(form);
                      onClose();
                    }}
                  />
                );
              },
            },
            name: 'Add',
          },
        },
      ],
    };

    function computePath(ert: EmployeeEducationType) {
      if (ert.document) {
        return ert.document;
      }

      return `${companyGroup.id}/${company.id}/employees/education-details/${
        ert.id
      }/${ert.statusOfEducation || 'unknown'}-${
        ert.degreeTitle
      }.pdf`.replaceAll(' ', '-');
    }

    function showuploadDoc(ert: EmployeeEducationType) {
      // if (r.url) {
      return (
        <div>
          <FileViewerUploader
            mode={isHr ? 'upload' : 'view'}
            url={ert.document || ''}
            useFileTransfer={useFileTransfer}
            path={computePath(ert)}
            type='private'
            onUpload={async (path) => {
              let registrationDoc = path.url;
              if (registrationDoc) {
                registrationDoc = path.url;
                await setEducation({
                  id: ert.id,
                  document: registrationDoc,
                } as any);
              }
            }}
          />
        </div>
      );
    }

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Status of Education' },
        { name: 'Degree Title' },
        { name: 'College' },
        { name: 'Year Of Passing' },
        { name: 'Marks' },
        { name: 'Document' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
      ],
    ];

    const tableData =
      educations?.map((l) => ({
        rowData: {
          education: l,
        },
        cells: [
          { value: l.statusOfEducation },
          { value: l.degreeTitle },
          { value: l.college },
          { value: l.yearOfPassing },
          { value: l.details.marks },
          { value: showuploadDoc(l) },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={l.createdBy?.id}
                name={l.createdBy?.name}
                extraInfo={moment.utc(l.createdAt).fromNow()}
              />
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={addActions()}
              auth={{ actions: getAllowedActions().actions }}
            />
          </div>
        </div>
      ),
    };

    return (
      <div className='border border-gray-200 rounded-lg p-2'>
        <Card
          header={cardHeader}
          body={cardBody}
          auth={{ actions: getAllowedActions().actions }}
        />
      </div>
    );
  };
}
