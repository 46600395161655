import { ExcelDownload } from '@erp_core/erp-icons/icons/web/excel-download';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  MonthSelector,
  renderBulkDownload,
  renderCardComponent,
  renderSearchBox,
  XlsxDownloadMapper,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useState } from 'react';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { EmployeeProfileInterface } from '../../../../models/interfaces/hrd/employee/empProfile';

export function renderWorkLogReport({
  employeeProfileService,
  useEmployees,
}: {
  employeeProfileService: EmployeeProfileInterface;
  useEmployees: UseEmployeeProfiles;
}): () => JSX.Element {
  const Card = renderCardComponent();

  return function WorkLogReport(): JSX.Element {
    const [loading, setLoading] = useState<boolean>(false);

    const [selectedEmployee, setSelectedEmployee] = useState<
      EmployeeProfileType
    >({} as EmployeeProfileType);

    const [month, setMonth] = useState(
      localStorage.getItem('worklog-month') || moment.utc().format('YYYY-MM')
    );

    const SearchBox = renderSearchBox<EmployeeProfileType>({
      useSearchValues: useEmployees,
      extraFilter: {
        crossGroup: 'true',
      },
      onSearchValueSelect: (u) => {
        setSelectedEmployee(u);
      },
    });

    const PendingActions = renderBulkDownload({
      mapperFun: downloadSurveyAnswerReport,
      downloadIcon: ExcelDownload,
    });

    async function downloadSurveyAnswerReport() {
      setLoading(true);
      const items = await employeeProfileService.getSurveyAnswersReport({
        month: month,
        employeeId: selectedEmployee.id,
      });

      const result: {
        mapper: XlsxDownloadMapper;
        data: any[];
        fileName: string;
      }[] = [];

      const suveyAnswerMapper: XlsxDownloadMapper = [
        { columnName: 'Activity', dataBinding: { property: 'question' } },
        { columnName: 'Task', dataBinding: { property: 'job' } },
      ];

      // const answers : any = {}
      const date = moment(`${month}-01`);
      for (let i = 1; i <= date.daysInMonth(); i++) {
        suveyAnswerMapper.push({
          columnName: `${month}-${i < 10 ? '0' : ''}${i}`,
          dataBinding: { property: `${month}-${i < 10 ? '0' : ''}${i}` },
        });
      }

      // Iterating over all items grouped by stockGroupId and downloading items list for each stockgroup separated items
      // _.forEach(stockGroupSeparated, (itemsGroup, stockGroupId) => {
      if (items.length !== 0) {
        const dynamicMapper: XlsxDownloadMapper = [...suveyAnswerMapper];

        const fileName = 'survey-answer-data-report'; // File name based in stock group id
        result.push({ mapper: dynamicMapper, data: items, fileName });
        // result.push({ mapper: dynamicMapper, data: data, fileName });
        // Downloading File
        // xlsxDownloader(dynamicMapper, itemsGroup, fileName);
      }
      // });
      setLoading(false);
      return result;

      // toast('Downloading');
    }

    const CardBody = (
      <div>
        {loading ? (
          <div className='text-center animate-pulse'>Generating Report...</div>
        ) : null}
      </div>
    );

    return (
      <div>
        <Card
          header={{
            title: 'WorkLog Report',
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div>
                    <MonthSelector
                      format='YYYY-MM'
                      initialState={month}
                      onChange={(m) => setMonth(m)}
                    />
                  </div>
                ),
              },
              {
                type: 'jsx',
                jsx: (
                  <div className='flex p-1 w-80 space-x-2 items-center justify-between font-bolder'>
                    <div className='font-bold flex-1'>Select</div>
                    <div>
                      <SearchBox
                        currentSelected={selectedEmployee}
                        searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm'
                      />
                    </div>
                  </div>
                ),
              },
              {
                type: 'jsx',
                jsx: (
                  <div className='border border-gray-200 p-1 rounded-md'>
                    WorkLog Report
                    <PendingActions />
                  </div>
                ),
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: CardBody,
          }}
        />
      </div>
    );
  };
}
