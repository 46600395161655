import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import { EnquiryQuotes } from '@erp_core/erp-types/dist/modules/order';
import moment from 'moment';
import { calculateTransportationCost } from '../../../../../../../../utils/company-group-setting/calculate-transportation-cost';
import { calculateLandingCost } from '../../../../../../../../utils/company-group-setting/canculate-landing-cost';

export const getCompleteData = ({
  enquiryQuotesData,
  rows,
  columns,
  landingCostFormula,
  item,
}: {
  enquiryQuotesData?: EnquiryQuotes[];
  rows: { id: string; name: string }[];
  columns: { id: string; name: string }[];
  landingCostFormula: string;
  item: ItemRes;
}) => {
  const data = rows?.map((r, index) => {
    switch (r.id) {
      case 'quantity':
        return columns
          .map((c) => {
            const quote = enquiryQuotesData?.find((q) => q.vendor.id === c.id);
            if (!quote) {
              return `
                        ${enquiryQuotesData?.[0]?.purchaseEnquiry?.purchaseRequisitions[0]?.details?.quantity} 
                        ${enquiryQuotesData?.[0]?.purchaseEnquiry?.purchaseRequisitions[0]?.itemDetails.uom}
                        `; //expected value
            }
            return (quote.details?.quoteDetails as any)?.[0]?.quantity || '';
          })
          .slice(1);

      case 'Basic rate':
        return columns
          .map((c) => {
            const quote = enquiryQuotesData?.find((q) => q.vendor.id === c.id);
            if (!quote) {
              return 'No expected rate.'; //expected value
            }
            return quote.details?.quoteDetails?.[0]?.rate || '';
          })
          .slice(1);

      case 'Category':
        return columns
          .map((c) => {
            const quote = enquiryQuotesData?.find((q) => q.vendor.id === c.id);
            if (!quote) {
              return 'Could be trader or manufacturer.'; //expected value
            }
            return quote.vendor?.details?.partyProfile?.vendorType || '';
          })
          .slice(1);

      case 'createdAt':
        return columns
          .map((c) => {
            const quote = enquiryQuotesData?.find((q) => q.vendor.id === c.id);
            if (!quote) {
              return moment(
                enquiryQuotesData?.[0]?.purchaseEnquiry?.createdAt
              ).format('DD-MM-YYYY, h:mm A'); //expected value
            }
            return (
              moment(quote.details?.quoteDetails?.[0]?.[r.id]).format(
                'DD-MM-YYYY, h:mm A'
              ) || ''
            );
          })
          .slice(1);

      case 'paymentTerms':
        return columns
          .map((c) => {
            const quote = enquiryQuotesData?.find((q) => q.vendor.id === c.id);
            if (!quote) {
              return Array.isArray(
                enquiryQuotesData?.[0]?.purchaseEnquiry?.details?.terms?.payment
                  ?.paymentTerms
              )
                ? enquiryQuotesData?.[0]?.purchaseEnquiry?.details?.terms?.payment?.paymentTerms
                    ?.map((x) => {
                      return `${x.percent}% ${x.paymentType} - {if n>0 Then "${x.noOfDays}" Days}.`;
                    })
                    .join(', ') || ''
                : ''; //expected value
            }
            return quote.details?.quoteDetails?.[0]?.[r.id] || '';
          })
          .slice(1);

      case 'termOfDelivery':
        return columns
          .map((c) => {
            const quote = enquiryQuotesData?.find((q) => q.vendor.id === c.id);
            if (!quote) {
              return enquiryQuotesData?.[0]?.purchaseEnquiry?.details?.terms
                ?.delivery?.deliveryTerms?.name; //expected value
            }
            return quote.details?.quoteDetails?.[0]?.[r.id] || '';
          })
          .slice(1);

      case 'item-specs':
        return columns
          .map((c) => {
            const quote = enquiryQuotesData?.find((q) => q.vendor.id === c.id);
            if (!quote) {
              return item?.details?.specification?.[r.name] || ''; //expected value get from item specs
            }
            return quote.details?.quoteDetails?.[0]?.[r.name] || '';
          })
          .slice(1);

      case 'landingCost':
        return columns
          .map((c) => {
            const quote = enquiryQuotesData?.find((q) => q.vendor.id === c.id);
            if (!quote) {
              return;
            }
            const quoteDetail = quote.details?.quoteDetails?.[0] || null;
            const landingCost =
              calculateLandingCost({
                pattern: landingCostFormula,
                parameters: {
                  baseCost: quoteDetail?.rate,
                  transportCost: calculateTransportationCost({
                    pattern: '',
                    parameters: {
                      termsOfDelivery: quoteDetail?.termOfDelivery,
                      transportersCostFactor: 'lump-sum', // TODO
                      lumpSumAmount: 3000,
                      rate: quote.details?.quoteDetails?.[0]?.rate,
                      quantity: quote.details?.quoteDetails?.[0]?.quantity,
                    },
                  }), // TODO Calculator for transportation cost
                  quantity: quote.details?.quoteDetails?.[0]?.quantity,
                },
              }).landingCost || 0;
            return landingCost;
          })
          .slice(1);

      default:
        return columns
          .map((c) => {
            const quote = enquiryQuotesData?.find((q) => q.vendor.id === c.id);
            if (!quote) {
              return ''; //expected value get from item specs
            }
            return quote.details?.quoteDetails?.[0]?.[r.id] || '';
          })
          .slice(1);
    }
  });

  return data;
};
