/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */

import {
  MachineSchedule,
  MachineScheduleFilter,
} from '@erp_core/erp-types/dist/modules/planning';
import { useState } from 'react';
import { UseResource } from '../../../resource/use-resource';
import { UseResources } from '../../../resource/use-resources';

export type UseMachineSchedules = UseResources<
  MachineSchedule,
  MachineScheduleFilter
>;

export type UseMachineSchedule = UseResource<MachineSchedule>;

export type UseGetMachineSchedule = () => {
  getByMachineId(id: string): Promise<void>;
  data: MachineSchedule | null;
};
export const createGetMachineScheduleHook = ({ useService }) =>
  function useGetMachineSchedule() {
    const [data, setData] = useState<MachineSchedule | null>(null);
    const getByMachineId = async (id: string) => {
      const res = await useService().getByMachineId(id);
      setData(res);
    };
    return { getByMachineId, data };
  };
