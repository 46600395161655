export function paymentTerms(
  paymentTerms?: Array<{
    percent: number;
    paymentType: string;
    noOfDays: number;
  }>
) {
  return Array.isArray(paymentTerms)
    ? paymentTerms?.map((x) => {
        return `${x.percent}% ${x.paymentType} - {if n>0 Then "${x.noOfDays}" Days}`;
      }) || ['-']
    : ['-'];
}
