import {
  MisconductRuleBookType,
  RuleRequestType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  LoadingButton,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';
import {
  EyeIcon,
  EyeSlashIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { useContext, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../../contexts/current';
import { UserContext } from '../../../../../contexts/user';
import { UseMisconductRuleBooks } from '../../../../../hooks/hrd/disciplinary/misconduct-rule-book/use-misconduct-rule-books';
import { UseRuleRequest } from '../../../../../hooks/hrd/disciplinary/rule-request/use-rule-request';
import { renderAddRuleRequestForm } from '../form/rule-request-addform';

export type SearchCategory = {
  id: string;
  name: string;
};
export function MisconductRuleBook({
  useMisconductRuleBooks,
  useRuleRequest,
  misconductId,
}: {
  useMisconductRuleBooks: UseMisconductRuleBooks;
  useRuleRequest: UseRuleRequest;
  misconductId: string;
}): JSX.Element {
  const [searchRule, setSearchRule] = useState('');

  const [
    selectedRuleData,
    setSelectedRuleData,
  ] = useState<MisconductRuleBookType | null>();

  const { user: currentUser } = useContext(UserContext);
  const { company, companyGroup } = useContext(CurrentContext);

  const {
    data: misconductsRules,
    getAll: getMisconductRuleBooks,
  } = useMisconductRuleBooks();

  const { syncSet: setRuleRequest } = useRuleRequest();

  useEffect(() => {}, [misconductsRules]);

  useEffect(() => {
    getMisconductRuleBooks();
    // eslint-disable-next-line
  }, []);

  const Card = renderCardComponent();
  const cardHeader: CardHeader = {
    title: 'Misconduct Rule Book',
    actions: [
      {
        type: 'jsx',
        jsx: (
          <div className='w-76'>
            <input
              className='w-100 border border-gray-200'
              placeholder='Search'
              type='text'
              value={searchRule}
              autoFocus
              onChange={(e) => {
                setSearchRule(e.target.value);
              }}
            />
          </div>
        ),
      },
      {
        type: 'button',
        button: {
          name: 'Request a new rule',
          behaviour: 'modal',
          modal: {
            title: 'Add Rule',
            content: ({ onClose }) => {
              const Form = renderAddRuleRequestForm();
              return (
                <Form
                  onSave={async (form) => {
                    handleRuleRequestAdd(form);
                    onClose();
                  }}
                />
              );
            },
          },
        },
      },
    ],
  };

  const handleRuleRequestAdd = async (form: Partial<RuleRequestType>) => {
    try {
      const finalData = {
        ...form,
        authorizer: {
          id: currentUser.id,
          name: currentUser.name,
        },
        status: 'pending',
        details: {
          misconductId: misconductId,
        },
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      } as RuleRequestType;
      await setRuleRequest(finalData);
      toast('Data added sucessfully');
    } catch (error) {
      toast('Something went wrong');
    }
  };

  const CopyIdtoClipboard = () => {
    try {
      toast('Copied to clipboard successfully');
    } catch (error) {
      toast('Something went wrong');
    }
  };

  const StatusAction = ({ r }: { r: MisconductRuleBookType }) => {
    return (
      <div className='flex flex-col p-2 space-y-2'>
        {
          <CopyToClipboard text={r.id}>
            <LoadingButton
              defaultStyle='font-semibold hover:text-black text-gray-600 w-fit text-xs text-left hover:underline'
              behaviorFn={async () => CopyIdtoClipboard()}
              text='Copy to clipboard'
            />
          </CopyToClipboard>
        }
      </div>
    );
  };

  const Table = renderTableComponent();

  const tableHeader: TableHeader = [
    [{ name: 'Misconduct Type' }, { name: 'Description' }, { name: 'Action' }],
  ];

  const tableData =
    misconductsRules
      ?.filter((item) => {
        return searchRule.toLowerCase() === ''
          ? item
          : item.description.toLowerCase().includes(searchRule) ||
              item.name.toLowerCase().includes(searchRule);
      })
      .map((r, idx) => ({
        cells: [
          { value: r.type },
          { value: r.description },
          {
            value: (
              <>
                <Popover className='relative'>
                  {({ open }) => (
                    <>
                      <Popover.Button>
                        <PencilSquareIcon className='w-6 h-6 text-blue-600 cursor-pointer' />
                      </Popover.Button>
                      <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                        <StatusAction r={r} />
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
                {selectedRuleData === r ? (
                  <EyeSlashIcon
                    className='w-4 mx-1 text-green-700 inline'
                    onClick={() => {
                      setSelectedRuleData(null);
                    }}
                  />
                ) : (
                  <EyeIcon
                    className='w-4 mx-1 inline'
                    onClick={() => {
                      setSelectedRuleData(r);
                    }}
                  />
                )}
              </>
            ),
          },
        ],
      })) || [];

  const tableBody: TableBody = tableData;

  const cardBody: CardBody = {
    type: 'jsx-component',
    body: (
      <div>
        <div className='w-full'>
          <Table header={tableHeader} body={tableBody} />
          {selectedRuleData ? (
            <ShowMisconductRulesDetails rules={selectedRuleData} />
          ) : null}
        </div>
      </div>
    ),
  };

  return (
    <>
      <div className='p-16 border border-gray-200 rounded-lg h-18 border rounded-md cursor-pointer p-2'>
        <Card header={cardHeader} body={cardBody} />
      </div>
    </>
  );
}

function ShowMisconductRulesDetails({
  rules,
}: {
  rules: MisconductRuleBookType;
}): JSX.Element {
  return (
    <div className='border border-gray-200 rounded-lg p-2'>
      <div className='text-center text-lg font-semibold'>Misconduct Rules</div>
      <div className='p-2 border border-gray-200 flex flex-wrap'>
        <span className='inline-grid grid-cols-2 gap-4'>
          <span>
            <b>Type:</b>
          </span>
          <span>{rules.type}</span>
          <span>
            <b>Description:</b>
          </span>
          <span>{rules.description}</span>
        </span>
      </div>
    </div>
  );
}
