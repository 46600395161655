import {
  LoanType,
  RepaymentScheduleType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../../contexts/current';
import { UserContext } from '../../../../../contexts/user';
import { UseLoan } from '../../../../../hooks/loans/loan/use-loan';
import { UseLoans } from '../../../../../hooks/loans/loan/use-loans';
import { UseRepaymentSchedule } from '../../../../../hooks/loans/repayment-schedule/use-repayment-schedule';
import { UseRepaymentSchedules } from '../../../../../hooks/loans/repayment-schedule/use-repayment-schedules';
import { UserRendererInterface } from '../../../../common/fragments/user';
import { LoanV2Type } from '../../../loan-management/types/loan-v2-type';
import { EmployeeRepaymentScheduleForm } from './component/emp-repayment-schedule';
import { AdvanceRepaymentForm } from './form/adv-payment-form';
import { EmployeeAddLoanForm } from './form/emp-add-loan-form';

type RenderEmployeeLoanProps = {
  userRendererService: UserRendererInterface;
  useLoan: UseLoan;
  useLoans: UseLoans;
  useRepaymentSchedules: UseRepaymentSchedules;
  employee: EmployeeProfileType;
  useRepaymentSchedule: UseRepaymentSchedule;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
};

export function renderEmployeeLoan(): ({
  useLoan,
  useLoans,
  useRepaymentSchedules,
  employee,
  useRepaymentSchedule,
  userRendererService,
}: RenderEmployeeLoanProps) => JSX.Element {
  return function EmployeeLoan({
    useLoan,
    useLoans,
    useRepaymentSchedules,
    employee,
    useRepaymentSchedule,
    actions,
    userRendererService,
  }: RenderEmployeeLoanProps): JSX.Element {
    // const { Confirm } = useConfirm();
    const { user: currentUser } = useContext(UserContext);
    const { companyGroup } = useContext(CurrentContext);

    const { syncSet: setLoan } = useLoan();

    const { syncSet: setRepaymentSchedule } = useRepaymentSchedule();

    const { data: loans, getAll: getLoans } = useLoans();

    const {
      // eslint-disable-next-line
      data: repaymentScheduls,
      getAll: getRepaymentScheduls,
    } = useRepaymentSchedules();

    useEffect(() => {}, [loans]);

    useEffect(() => {
      getLoans({ employeeId: employee.id });
      getRepaymentScheduls();
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Loans',
      actions: [
        {
          auth: 'HRD:EMP-PROFILE-EDIT',
          type: 'button',
          button: {
            name: 'Request Loan',
            behaviour: 'modal',
            modal: {
              title: 'Request Loan',
              content: ({ onClose }) => {
                const Form = EmployeeAddLoanForm({ useLoans });

                return (
                  <Form
                    onSave={async (form) => {
                      saveLoan(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const saveLoan = async (form: Partial<LoanType>) => {
      try {
        const finalData = {
          ...form,
          status: 'pending',
          employee: {
            id: employee.id,
            name: employee.name,
          },
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          rateOfInterest: '12',
          name: moment().unix().toString(),
          details: '',
          authorizer: { id: currentUser.id, name: currentUser.name },
        } as LoanType;

        await setLoan(finalData as LoanV2Type);
        toast('Data added sucessfully');
        getLoans({ employeeId: employee.id });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const UpdateLoanStatus = async (
      data: RepaymentScheduleType,
      upLoanId: string
    ) => {
      if (upLoanId) {
        const finalData = ({
          id: upLoanId,
          details: {
            advPaymentStatus: 'requested',
          },
        } as unknown) as LoanV2Type;
        await setLoan(finalData);
      }
    };

    const UpdateSkipLoanStatus = async (upLoanId: string) => {
      if (upLoanId) {
        const finalData = {
          id: upLoanId,
          details: {
            skipRepaymentStatus: 'requested',
          },
        } as LoanType;
        await setLoan(finalData as LoanV2Type);
      }
    };

    const saveRepaymentSchedule = async (
      form: Partial<RepaymentScheduleType>
    ) => {
      try {
        const finalData = {
          ...form,
        } as RepaymentScheduleType;

        await setRepaymentSchedule(finalData as RepaymentScheduleType);
        UpdateLoanStatus(finalData, finalData.loans.id);
        toast('Data added sucessfully');
        getLoans({ employeeId: employee.id });
      } catch (error) {
        toast('Something went wrong');
      }
    };
    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Date' },
        { name: 'Amount' },
        { name: 'Rate of Interest %' },
        { name: 'Tenure (in Months)' },
        { name: 'Status' },
        { name: 'Last Expected Date of Loan' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        { name: 'Actions' },
      ],
    ];

    const cancelLoanReq = async (id: string) => {
      const finalData = {
        id,
        status: 'cancelled',
      };
      await setLoan(finalData as any);
      toast('Cancelled sucessfully');
      getLoans({ employeeId: employee.id });
    };

    const nextMonthYear = moment().add(1, 'month').format('YYYY-MM');

    const skipRepaymentSchedule = async (data: LoanType) => {
      try {
        const repaymentData = repaymentScheduls?.find(
          (x) => x.loans.id === data.id && x.monthYear === nextMonthYear + '-07'
        );
        const finalData = {
          id: repaymentData?.id,
          details: {
            skipRepaymentSatus: 'requested',
          } as any,
        } as RepaymentScheduleType;

        await setRepaymentSchedule(finalData as RepaymentScheduleType);
        UpdateSkipLoanStatus(repaymentData?.loans.id || '');
        toast('Data added sucessfully');
        getLoans({ employeeId: employee.id });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableData: TableBody =
      loans?.map((l, idx) => ({
        rowData: {
          loan: l,
        },
        cells: [
          {
            value: l.details.disbursedDate
              ? l.details.disbursedDate[0]?.date
              : '-',
          },
          { value: l.amount },
          { value: l.rateOfInterest },
          { value: l.tenure },
          { value: l.status },
          { value: l.details.expectedLastDateOfPayment || '-' },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={l.createdBy?.id}
                name={l.createdBy?.name}
                extraInfo={moment.utc(l.createdAt).fromNow()}
              />
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Repayment Schedule',
                  show: ({ loan }) => loan.status === 'notify-to-disburse',
                  behaviour: 'modal',
                  modal: {
                    title: 'Repayment Schedule',
                    size: 'large',
                    content: ({ data: { loan }, onClose }) => {
                      return (
                        <EmployeeRepaymentScheduleForm
                          useRepaymentSchedules={useRepaymentSchedules}
                          loanId={loan.id}
                          useLoans={useLoans}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Skip Repayment',
                  show: ({ loan }) =>
                    loan.status === 'notify-to-disburse' &&
                    loan.details.advPaymentStatus !== 'requested' &&
                    loan.details.skipRepaymentStatus !== 'requested',
                  behaviour: 'confirm',
                  onConfirm: ({ loan }) => {
                    const nextMonthYear = moment()
                      .add(1, 'month')
                      .format('YYYY-MM');
                    return {
                      title: `Are you sure you want to request for skip repayment of upcoming month ${nextMonthYear}?`,
                      type: 'warning',
                      onConfirm: async () => {
                        await skipRepaymentSchedule(loan);
                      },
                    };
                  },
                },
                {
                  name: 'Advance Re-Payment',
                  behaviour: 'modal',
                  show: ({ loan }) =>
                    loan.status === 'notify-to-disburse' &&
                    loan.details.advPaymentStatus !== 'requested' &&
                    loan.details.skipRepaymentStatus !== 'requested',
                  modal: {
                    size: 'large',
                    title: 'Advance Re-Payment',
                    content: ({ data: { loan }, onClose }) => {
                      return (
                        <AdvanceRepaymentForm
                          useRepaymentSchedules={useRepaymentSchedules}
                          loanId={loan.id}
                          month={nextMonthYear}
                          onSave={async (form) => {
                            await saveRepaymentSchedule(form);
                            onClose();
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Advance Repayment',
                  behaviour: 'modal',
                  show: ({ loan }) =>
                    loan.status === 'notify-to-disburse' &&
                    loan.details.advPaymentStatus !== 'requested' &&
                    loan.details.skipRepaymentStatus !== 'requested',
                  modal: {
                    title: 'Advance Repayment',
                    size: 'large',
                    content: ({ data: { loan }, onClose }) => {
                      return (
                        <AdvanceRepaymentForm
                          useRepaymentSchedules={useRepaymentSchedules}
                          loanId={loan.id}
                          month={nextMonthYear}
                          onSave={async (form) => {
                            await saveRepaymentSchedule(form);
                            onClose();
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Cancel',
                  show: ({ loan }) => loan.status === 'pending',
                  behaviour: 'confirm',
                  onConfirm: ({ loan }) => {
                    return {
                      title: 'Are you sure you want to cancel loan?',
                      type: 'warning',
                      onConfirm: async () => {
                        await cancelLoanReq(loan.id);
                      },
                    };
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <div className='border border-gray-200 rounded-lg p-2'>
        <Card header={cardHeader} body={cardBody} auth={{ actions }} />
      </div>
    );
  };
}
