import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { ItemProperty } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import {
  LoadingButton,
  MultiRangeSlider,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useState } from 'react';
import { UseAppearances } from '../../../../hooks/admin/constants/appearance/use-appearances';
import { UseColors } from '../../../../hooks/admin/constants/color/use-colors';
import { UseGsts } from '../../../../hooks/admin/constants/gst/use-gsts';
import { UseMetrics } from '../../../../hooks/admin/constants/metrics/use-metrics';
import { UseOdours } from '../../../../hooks/admin/constants/odour/use-odours';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { SearchSelectRenderer } from './common';
import { EditPropertyValProps } from './types';

export const renderEditObjectPropertyChangeReq = ({
  useMetrics,
  useGsts,
  useAppearances,
  useOdours,
  useColors,
  useEmployees,
}: {
  useMetrics: UseMetrics;
  useGsts: UseGsts;
  useOdours: UseOdours;
  useColors: UseColors;
  useAppearances: UseAppearances;
  useEmployees: UseEmployeeProfiles;
}) => {
  const Table = renderTableComponent();
  return function EditObjectPropertyChangeReq({
    itemProperty,
    currentValue,
    onSave,
    entityId,
  }: EditPropertyValProps) {
    const [newValues, setNewValues] = useState<any>({});
    const [reason, setReason] = useState<string>();

    function getPropertyEditor(
      itemProperty: ItemProperty,
      key: string
    ): JSX.Element {
      const isReadOnly =
        itemProperty.owner?.id !== entityId &&
        itemProperty.childrenProperties &&
        itemProperty.childrenProperties[key].valueType === 'fixed';

      if (
        itemProperty.childrenProperties &&
        itemProperty.childrenProperties[key].name &&
        ['input', 'number', 'formula'].includes(
          itemProperty.childrenProperties[key].type
        )
      ) {
        return (
          <>
            <input
              readOnly={isReadOnly}
              type={
                itemProperty.childrenProperties[key].type === 'number'
                  ? 'number'
                  : 'text'
              }
              step={
                itemProperty.childrenProperties[key].type === 'number'
                  ? 'any'
                  : undefined
              }
              placeholder={itemProperty.placeholder || ''}
              className='border-gray-100 p-1'
              value={newValues[itemProperty.childrenProperties[key].name] || ''}
              onChange={(evt) => {
                if (evt.target.value) {
                  const property =
                    (itemProperty.childrenProperties &&
                      itemProperty.childrenProperties[key].name) ||
                    '';
                  setNewValues((v) => ({
                    ...v,
                    ...{ [property]: evt.target.value },
                  }));
                }
              }}
            />
            {isReadOnly ? (
              <div className='text-right italic'>[readonly]</div>
            ) : null}
          </>
        );
      }

      if (
        itemProperty.childrenProperties &&
        itemProperty.childrenProperties[key] &&
        itemProperty.childrenProperties[key].name &&
        itemProperty.childrenProperties[key].type === 'select'
      ) {
        return (
          <>
            <select
              disabled={isReadOnly}
              className='border-gray-100 p-1'
              value={newValues[itemProperty.childrenProperties[key].name] || ''}
              onChange={(evt) => {
                if (
                  evt.target.value &&
                  itemProperty.childrenProperties &&
                  itemProperty.childrenProperties[key]
                ) {
                  const property =
                    (itemProperty.childrenProperties &&
                      itemProperty.childrenProperties[key].name) ||
                    '';
                  setNewValues((v) => ({
                    ...v,
                    ...{ [property]: evt.target.value },
                  }));
                }
              }}
            >
              <option value=''>Select</option>
              {itemProperty.childrenProperties[key]?.selectOptions?.map(
                (op) => {
                  return (
                    <option key={op} value={op}>
                      {op}
                    </option>
                  );
                }
              )}
            </select>
            {isReadOnly ? (
              <div className='text-right italic'>[readonly]</div>
            ) : null}
          </>
        );
      }

      if (
        itemProperty.childrenProperties &&
        itemProperty.childrenProperties[key] &&
        itemProperty.childrenProperties[key].name &&
        itemProperty.childrenProperties[key].type === 'searchable-select'
      ) {
        return (
          <SearchSelectRenderer
            useGsts={useGsts}
            useMetrics={useMetrics}
            useAppearances={useAppearances}
            useEmployees={useEmployees}
            useColors={useColors}
            useOdours={useOdours}
            currentValue={newValues[itemProperty.childrenProperties[key].name]}
            searchSelectOptions={
              itemProperty.childrenProperties &&
              itemProperty.childrenProperties[key] &&
              (itemProperty.childrenProperties[key].searchSelectOptions as any)
            }
            onChange={(newValue) => {
              if (itemProperty.childrenProperties) {
                let property =
                  (itemProperty.childrenProperties &&
                    itemProperty.childrenProperties[key].name) ||
                  '';
                if (property) {
                  setNewValues((v) => ({
                    ...v,
                    ...{
                      [property]: {
                        id: newValue.id,
                        name: newValue.name,
                      },
                    },
                  }));
                }
              }
            }}
          />
        );
      }

      if (
        itemProperty.childrenProperties &&
        itemProperty.childrenProperties[key] &&
        itemProperty.childrenProperties[key].name &&
        ['object'].includes(itemProperty.childrenProperties[key].type)
      ) {
        return (
          <>
            {itemProperty.childrenProperties &&
              _.keys(
                itemProperty.childrenProperties[key].childrenProperties
              ).map((childKey) => {
                const isNestedReadOnly =
                  itemProperty.owner?.id !== entityId &&
                  itemProperty.childrenProperties &&
                  itemProperty.childrenProperties[key] &&
                  itemProperty.childrenProperties[key].childrenProperties &&
                  (itemProperty.childrenProperties[key]
                    .childrenProperties as any)[childKey] &&
                  (itemProperty.childrenProperties[key]
                    .childrenProperties as any)[childKey]?.valueType ===
                    'fixed';

                return (
                  <div key={childKey} className='my-1 border border-gray-200'>
                    <div className='text-center font-bold'>{childKey}</div>
                    <div>
                      {itemProperty.childrenProperties &&
                      itemProperty.childrenProperties[key] &&
                      itemProperty.childrenProperties[key].childrenProperties &&
                      (itemProperty.childrenProperties[key]
                        .childrenProperties as any)[childKey] &&
                      ['input', 'number'].includes(
                        (itemProperty.childrenProperties[key]
                          .childrenProperties as any)[childKey].type
                      ) ? (
                        <>
                          <input
                            disabled={isNestedReadOnly}
                            className='border-gray-100 p-1'
                            type={
                              (itemProperty.childrenProperties[key]
                                .childrenProperties as any)[childKey].type
                                ? 'number'
                                : 'text'
                            }
                            step={
                              (itemProperty.childrenProperties[key]
                                .childrenProperties as any)[childKey].type
                                ? 'any'
                                : undefined
                            }
                            value={
                              (itemProperty.childrenProperties &&
                                newValues[
                                  itemProperty.childrenProperties[key].name
                                ] &&
                                newValues[
                                  itemProperty.childrenProperties[key].name
                                ][childKey]) ||
                              ''
                            }
                            onChange={(evt) => {
                              if (evt.target.value) {
                                let property =
                                  (itemProperty.childrenProperties &&
                                    itemProperty.childrenProperties[key]
                                      .name) ||
                                  '';
                                if (property) {
                                  const propValue = newValues[property] || {};
                                  propValue[childKey] = evt.target.value;
                                  setNewValues((v) => ({
                                    ...v,
                                    ...{ [property]: propValue },
                                  }));
                                }
                              }
                            }}
                          />
                          {isNestedReadOnly ? (
                            <div className='text-right italic'>[readonly]</div>
                          ) : null}
                        </>
                      ) : null}

                      {itemProperty.childrenProperties &&
                      (itemProperty.childrenProperties as any)[key]
                        .childrenProperties[childKey].type === 'select' ? (
                        <select
                          disabled={isNestedReadOnly}
                          className='border-gray-100 p-1'
                          value={
                            (itemProperty.childrenProperties &&
                              newValues[
                                itemProperty.childrenProperties[key].name
                              ] &&
                              newValues[
                                itemProperty.childrenProperties[key].name
                              ][childKey]) ||
                            ''
                          }
                          onChange={(evt) => {
                            if (evt.target.value) {
                              let property =
                                (itemProperty.childrenProperties &&
                                  itemProperty.childrenProperties[key].name) ||
                                '';
                              if (property) {
                                const propValue = newValues[property] || {};
                                propValue[childKey] = evt.target.value;
                                setNewValues((v) => ({
                                  ...v,
                                  ...{ [property]: propValue },
                                }));
                              }
                            }
                          }}
                        >
                          <option value={''}>Select</option>
                          {itemProperty.childrenProperties &&
                            itemProperty.childrenProperties[key] &&
                            itemProperty.childrenProperties[key]
                              .childrenProperties &&
                            (itemProperty.childrenProperties[key]
                              .childrenProperties as any)[childKey] &&
                            (itemProperty.childrenProperties[key]
                              .childrenProperties as any)[
                              childKey
                            ].selectOptions.map((so) => (
                              <option key={so} value={so}>
                                {so}
                              </option>
                            ))}
                        </select>
                      ) : null}

                      {itemProperty.childrenProperties &&
                      itemProperty.childrenProperties[key] &&
                      itemProperty.childrenProperties[key].childrenProperties &&
                      (itemProperty.childrenProperties[key] as any)
                        .childrenProperties[childKey] &&
                      (itemProperty.childrenProperties[key] as any)
                        .childrenProperties[childKey].type ===
                        'searchable-select' ? (
                        <SearchSelectRenderer
                          useGsts={useGsts}
                          useMetrics={useMetrics}
                          useAppearances={useAppearances}
                          useColors={useColors}
                          useOdours={useOdours}
                          useEmployees={useEmployees}
                          currentValue={
                            (itemProperty.childrenProperties &&
                              newValues[
                                itemProperty.childrenProperties[key].name
                              ] &&
                              newValues[
                                itemProperty.childrenProperties[key].name
                              ][childKey]) ||
                            ''
                          }
                          searchSelectOptions={
                            itemProperty.childrenProperties &&
                            itemProperty.childrenProperties[key] &&
                            itemProperty.childrenProperties[key]
                              .childrenProperties &&
                            (itemProperty.childrenProperties[key]
                              .childrenProperties as any)[childKey] &&
                            (itemProperty.childrenProperties[key]
                              .childrenProperties as any)[childKey]
                              .searchSelectOptions
                          }
                          onChange={(newValue) => {
                            if (itemProperty.childrenProperties) {
                              let property =
                                (itemProperty.childrenProperties &&
                                  itemProperty.childrenProperties[key].name) ||
                                '';
                              if (property) {
                                const propValue = newValues[property] || {};

                                if (newValue.id !== propValue[childKey]?.id) {
                                  propValue[childKey] = {
                                    id: newValue.id,
                                    name: newValue.name,
                                  };
                                }

                                setNewValues((v) => ({
                                  ...v,
                                  ...{ [property]: propValue },
                                }));
                              }
                            }
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                );
              })}
          </>
        );
      }

      if (
        itemProperty.childrenProperties &&
        itemProperty.childrenProperties[key] &&
        itemProperty.childrenProperties[key].name &&
        itemProperty.childrenProperties[key].type === 'value-uom'
      ) {
        return (
          <div className='flex'>
            <input
              className='border-gray-100 p-1'
              type='number'
              step='any'
              defaultValue={
                (itemProperty.childrenProperties &&
                  newValues[itemProperty.childrenProperties[key].name] &&
                  newValues[itemProperty.childrenProperties[key].name]
                    ?.value) ||
                ''
              }
              onBlur={(evt) => {
                const newValue = parseFloat(evt.target.value);
                if (itemProperty.childrenProperties) {
                  let property =
                    (itemProperty.childrenProperties &&
                      itemProperty.childrenProperties[key].name) ||
                    '';

                  const currentValue = newValues[property] || {
                    value: '',
                    uom: { id: '', name: '' },
                  };
                  if (newValue !== currentValue?.value) {
                    currentValue.value = newValue;
                  }

                  setNewValues((v) => ({
                    ...v,
                    ...{ [property]: currentValue },
                  }));
                }
              }}
            />
            <SearchSelectRenderer
              useGsts={useGsts}
              useMetrics={useMetrics}
              useAppearances={useAppearances}
              useColors={useColors}
              useOdours={useOdours}
              useEmployees={useEmployees}
              currentValue={
                (itemProperty.childrenProperties &&
                  newValues[itemProperty.childrenProperties[key].name] &&
                  newValues[itemProperty.childrenProperties[key].name]?.uom) ||
                ''
              }
              searchSelectOptions='useMetrics'
              onChange={(newValue) => {
                if (itemProperty.childrenProperties) {
                  let property =
                    (itemProperty.childrenProperties &&
                      itemProperty.childrenProperties[key].name) ||
                    '';

                  const currentValue = newValues[property] || {
                    value: '',
                    uom: { id: '', name: '' },
                  };
                  if (newValue.id !== currentValue?.uom?.id) {
                    currentValue.uom = {
                      id: newValue.id,
                      name: newValue.name,
                    };
                  }

                  setNewValues((v) => ({
                    ...v,
                    ...{ [property]: currentValue },
                  }));
                }
              }}
            />
          </div>
        );
      }

      if (
        itemProperty.childrenProperties &&
        itemProperty.childrenProperties[key] &&
        itemProperty.childrenProperties[key].type === 'range'
      ) {
        return (
          <MultiRangeSlider
            mode={
              itemProperty.childrenProperties[key].range?.type === 'double'
                ? 'multi'
                : 'single'
            }
            onChange={(x) => {
              console.log(x);
              if (itemProperty.childrenProperties) {
                let property =
                  (itemProperty.childrenProperties &&
                    itemProperty.childrenProperties[key].name) ||
                  '';

                const currentValue = newValues[property] || {
                  minValue: 0,
                  maxValue: 0,
                };
                if (
                  x.min !== currentValue?.minValue ||
                  x.max !== currentValue?.maxValue
                ) {
                  currentValue.minValue = x.min;
                  currentValue.maxValue = x.max;
                }

                console.log({ [property]: currentValue });

                setNewValues((v) => ({
                  ...v,
                  ...{ [property]: currentValue },
                }));
              }
            }}
            min={parseFloat(
              `${itemProperty.childrenProperties[key].range?.min || '0'}`
            )}
            max={parseFloat(
              `${itemProperty.childrenProperties[key].range?.max || '0'}`
            )}
            initialMinValue={
              (itemProperty.childrenProperties &&
                newValues[itemProperty.childrenProperties[key].name] &&
                newValues[itemProperty.childrenProperties[key].name]
                  ?.minValue) ||
              0
            }
            initialMaxValue={
              (itemProperty.childrenProperties &&
                newValues[itemProperty.childrenProperties[key].name] &&
                newValues[itemProperty.childrenProperties[key].name]
                  ?.maxValue) ||
              0
            }
          />
        );
      }

      return (
        <>
          unknown-type:{' '}
          {itemProperty.childrenProperties &&
            itemProperty?.childrenProperties[key]?.type}
        </>
      );
    }

    const TableBody: TableBody = (itemProperty.childrenProperties &&
    _.keys(itemProperty.childrenProperties).length
      ? _.keys(itemProperty.childrenProperties)
      : []
    ).map((key) => {
      return {
        cells: [
          {
            value:
              (itemProperty.childrenProperties &&
                itemProperty.childrenProperties[key].name) ||
              '',
          },
          {
            value:
              (itemProperty.childrenProperties &&
                itemProperty.childrenProperties[key].type) ||
              '',
          },
          {
            value: renderValue(currentValue, itemProperty, key),
          },
          {
            value:
              itemProperty.childrenProperties &&
              itemProperty.childrenProperties[key].name
                ? getPropertyEditor(itemProperty, key)
                : '',
          },
        ],
      };
    });

    return (
      <div>
        Edit Property: {itemProperty.name}
        <Table
          header={[
            [
              { name: 'Property' },
              { name: 'Type' },
              { name: 'Old Value' },
              { name: 'New Value' },
            ],
          ]}
          body={TableBody}
        />
        <div className='flex justify-center'>
          <label className='font-bold '>Reason : </label>
          <input
            type='text'
            className='border border-gray text-left h-50'
            onChange={(evt) => {
              setReason(evt.target.value);
            }}
          />
        </div>
        <div className='flex justify-center'>
          <LoadingButton
            behaviorFn={async () =>
              await onSave({
                reason: reason || '',
                changeRequest: {
                  currentValue: {
                    value: {
                      data: currentValue,
                    },
                  },
                  newValue: {
                    value: {
                      data: newValues,
                    },
                  },
                },
              })
            }
            defaultStyle='bg-green-500 text-white p-1'
            text='Save'
          />
        </div>
      </div>
    );
  };
};

function renderValue(
  currentValue: IdName,
  itemProperty: ItemProperty,
  key: string
) {
  if (
    itemProperty &&
    itemProperty.childrenProperties &&
    itemProperty.childrenProperties[key] &&
    currentValue
  ) {
    if (currentValue[itemProperty.childrenProperties[key].name]) {
      if (
        typeof currentValue[itemProperty.childrenProperties[key].name] ===
        'string'
      ) {
        return currentValue[itemProperty.childrenProperties[key].name];
      }

      if (
        typeof currentValue[itemProperty.childrenProperties[key].name] ===
        'object'
      ) {
        return JSON.stringify(
          currentValue[itemProperty.childrenProperties[key].name]
        );
      }
      console.log(currentValue[itemProperty.childrenProperties[key].name]);
      return 'unhandled';
    }
  }

  return '';
}
