import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { UsePremiums } from '../../../../../../hooks/hrd/insurance/premiums/use-premiums';
import { UserRendererInterface } from '../../../../../common/fragments/user';

export function EmployeePremiumsForm({
  usePremiums,
  policyId,
  userRendererService,
}: {
  usePremiums: UsePremiums;
  policyId: string;
  userRendererService: UserRendererInterface;
}): JSX.Element {
  const { data: premiums, getAll: getPremiums } = usePremiums();

  useEffect(() => {}, [premiums]);

  useEffect(() => {
    getPremiums({
      policyId: policyId,
    });
    // eslint-disable-next-line
  }, []);

  const premiumRcds = _.orderBy(
    premiums,
    (e) => [moment(e.monthYear).format('YYYYMM')],
    ['asc', 'asc']
  );

  const Card = renderCardComponent();
  const cardHeader: CardHeader = {
    title: 'Premiums',
  };

  const Table = renderTableComponent();

  const tableHeader: TableHeader = [
    [
      { name: 'Month (YYYY-MM)' },
      { name: 'Amount' },
      { name: 'Status' },
      { name: 'Paid By' },
    ],
  ];

  const tableData: TableBody =
    premiumRcds?.map((r, idx) => ({
      cells: [
        { value: r.monthYear },
        { value: r.amount },
        { value: r.status },
        {
          value: (
            <userRendererService.userCard
              link={true}
              size='small'
              id={r.lastModifiedBy?.id}
              name={r.lastModifiedBy?.name}
              extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
            />
          ),
        },
      ],
    })) || [];

  const tableBody: TableBody = tableData;

  const cardBody: CardBody = {
    type: 'jsx-component',
    body: (
      <div>
        <div className='w-full'>
          <Table header={tableHeader} body={tableBody} />
        </div>
      </div>
    ),
  };

  return (
    <>
      <div className='p-16 border border-gray-200 rounded-lg h-18 border rounded-md cursor-pointer p-2'>
        <Card header={cardHeader} body={cardBody} />
      </div>
    </>
  );
}
