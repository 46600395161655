import { SalaryParamGroup } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param-group';
import { UseEmployeeCategories } from '../../../../../hooks/admin/constants/employee-category/use-employee-categories';
import { EditValueForm } from './edit-value-form';

export function Edit({
  onClose,
  obj,
  propName,
  formType,
  options,
  showTextArea,
  setSalParams,
  salParams,
  useEmployeeCategories,
}: {
  onClose: () => void;
  obj: any;
  propName: string;
  formType: 'text' | 'select' | 'searchable-select';
  options?: Array<string>;
  useEmployeeCategories?: UseEmployeeCategories;
  showTextArea?: boolean;
  setSalParams: (value: React.SetStateAction<SalaryParamGroup>) => void;
  salParams: SalaryParamGroup;
}) {
  return (
    <EditValueForm
      formType={formType}
      options={options}
      showTextArea={showTextArea}
      onClose={(val) => {
        obj[propName] = val;
        setSalParams({ ...salParams });
        onClose();
      }}
      useEmployeeCategories={useEmployeeCategories}
      initialValue={obj[propName]}
      propName={propName}
    />
  );
}
