import { RepaymentScheduleType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import { UseRepaymentSchedules } from '../../../../../../hooks/loans/repayment-schedule/use-repayment-schedules';

export type AdvPaymentFormType = {
  advanceRepaymentAmount: string;
  date: string;
  paymentMode: string;
};

type AdvPaymentFormProps = {
  useRepaymentSchedules: UseRepaymentSchedules;
  loanId: string;
  month: string;
  onSave: (data: RepaymentScheduleType) => Promise<void>;
  isEdit?: boolean;
};

export function AdvanceRepaymentForm({
  useRepaymentSchedules,
  loanId,
  month,
  onSave,
  isEdit,
}: AdvPaymentFormProps): JSX.Element {
  const {
    data: repaymentSchedules,
    getAll: getRepaymentSchedules,
  } = useRepaymentSchedules();

  useEffect(() => {}, [repaymentSchedules]);

  useEffect(() => {
    getRepaymentSchedules({
      loanId: loanId,
    });
    // eslint-disable-next-line
  }, []);

  const advPayingMonth = moment().add(1, 'month').format('YYYY-MM') + '-07';
  const data = repaymentSchedules?.find((x) => x.monthYear === advPayingMonth);

  // const validateForm = async (form) => {
  //   if (isEdit) return '';
  //   if (!form.date) return '';

  //   // if (
  //   //   moment(form.date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
  //   // ) {
  //   //   return {
  //   //     date: `date should be greater than or equal to ${moment().format(
  //   //       'DD-MM-YYYY'
  //   //     )} `,
  //   //   } as any;
  //   // }
  //   return;
  // };

  const formProps: FormProps<AdvPaymentFormType> = {
    fieldsData: [
      {
        property: 'advanceRepaymentAmount',
        type: 'input',
        label: 'Advance Amount',
        required: true,
      },
      {
        property: 'paymentMode',
        type: 'select',
        options: [
          { value: '', text: 'select' },
          { value: 'Salary', text: 'Salary' },
          { value: 'Cash', text: 'Cash' },
          { value: 'Netbanking', text: 'Netbanking' },
          { value: 'Cheque', text: 'Cheque' },
        ],
      },
      {
        property: 'date',
        type: 'date',
        label: 'Date',
        required: true,
        readonly: isEdit,
        // validate: validateForm,
      },
    ],
    initialFormState: {
      paymentMode: '',
      advanceRepaymentAmount: '',
      date: moment().format('YYYY-MM-DD'),
    },
    mapTToU: mapPFDToP,
    style: 'md:w-1/2',
    onSubmit: async (l) => {
      await onSave({
        id: data?.id || '',
        loans: { id: loanId },
        details: {
          advPaymentAmt:
            l.advanceRepaymentAmount || data?.details?.advPaymentAmt,
          advPaymentDate: l.date || data?.details?.advPaymentDate,
          advPaymentMode: l.paymentMode || data?.details?.advPaymentMode,
          advPaymentStatus: 'requested',
        } as any,
      } as RepaymentScheduleType);
    },
  };

  const Form = renderFormV2<AdvPaymentFormType>(formProps);

  return <Form />;

  function mapPFDToP(b: FormDataType<AdvPaymentFormType>): AdvPaymentFormType {
    return (b as unknown) as AdvPaymentFormType;
  }
}
