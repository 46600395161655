import {
  SalaryFilterType,
  SalaryType,
} from '@erp_core/erp-types/dist/types/modules/payroll/salary';
import {
  LoadingButton,
  MonthSelector,
  renderCardComponent,
  renderFileViewerUploader,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UsePayrollReport } from '../../../../hooks/hrd/payroll-reports/use-payroll-report';
import { UsePayrollReports } from '../../../../hooks/hrd/payroll-reports/use-payroll-reports';
import { UseSalaries } from '../../../../hooks/hrd/salary/use-salaries';
import { UseSalary } from '../../../../hooks/hrd/salary/use-salary';
import { PayrollReport } from '../../../types/payroll-reports';

export function renderBankSalaries({
  usePayrollReports,
  usePayrollReport,
  useFileTransfer,
  useSalaries,
  useSalary,
}: {
  usePayrollReport: UsePayrollReport;
  usePayrollReports: UsePayrollReports;
  useFileTransfer: UseFileTransfer;
  useSalaries: UseSalaries;
  useSalary: UseSalary;
}) {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return function BankSalaries(): JSX.Element {
    const { data: reports, getAll: getReports } = usePayrollReports();
    const { syncSet: setPayrollReport } = usePayrollReport();
    const FileViewer = renderFileViewerUploader();
    const { getAllSync: getSalaries } = useSalaries();
    const { syncSet: setSalary } = useSalary();

    const [month, setMonth] = useState<string>(
      localStorage.getItem('account-reports') || moment().format('YYYY-MM')
    );

    useEffect(() => {
      const curr = localStorage.getItem('account-reports');
      if (!curr || curr !== month) {
        localStorage.setItem('account-reports', month);
      }

      getReports({
        monthYear: month,
      });
      // eslint-disable-next-line
    }, [month]);

    return (
      <Card
        header={{
          title: 'Report',
          actions: [
            {
              type: 'jsx',
              jsx: (
                <MonthSelector
                  format='YYYY-MM'
                  onChange={(date) => setMonth(date)}
                />
              ),
            },
          ],
        }}
        body={{
          type: 'jsx-component',
          body: (
            <div>
              <Table
                header={[
                  [
                    { name: 'Name' },
                    { name: 'MonthYear' },
                    { name: 'Type' },
                    { name: 'Document' },
                    { name: 'Status' },
                  ],
                ]}
                body={(reports || []).map((x) => {
                  return {
                    rowData: {
                      report: x,
                    },
                    cells: [
                      { value: x.name },
                      { value: x.monthYear },
                      { value: x.type },
                      {
                        value: (
                          <FileViewer
                            url={x.url}
                            useFileTransfer={useFileTransfer}
                            type='private'
                            path={x.url}
                            onUpload={async () => {}}
                            mode='view'
                          />
                        ),
                      },
                      { value: x.status },
                    ],
                  };
                })}
                actions={[
                  {
                    name: 'Mark as released',
                    show: ({ report }: { report: PayrollReport }) => {
                      if (
                        report.type === 'bank-details' &&
                        report.status === 'created'
                      ) {
                        return true;
                      }

                      return false;
                    },
                    behaviour: 'modal',
                    modal: {
                      title: 'Mark Bank-Salaries as released',
                      content: ({
                        data: { report },
                        onClose,
                      }: {
                        data: { report: PayrollReport };
                        onClose: () => void;
                      }) => {
                        return (
                          <ConfirmTransactionSubmit
                            report={report}
                            getSalaries={getSalaries}
                            setSalary={setSalary}
                            setPayrollReport={setPayrollReport}
                            onClose={onClose}
                          />
                        );
                      },
                    },
                  },
                ]}
              />
            </div>
          ),
        }}
      />
    );
  };
}

function ConfirmTransactionSubmit({
  report,
  getSalaries,
  setSalary,
  onClose,
  setPayrollReport,
}: {
  report: PayrollReport;
  getSalaries: (filter?: SalaryFilterType) => Promise<SalaryType[]>;
  setSalary: (s: SalaryType) => Promise<SalaryType>;
  setPayrollReport: (s: PayrollReport) => Promise<PayrollReport>;
  onClose: () => void;
}): JSX.Element {
  const [txn, setTxn] = useState<string>('');
  return (
    <div>
      <div>
        Are you sure you want to mark bank report {report.details?.batch} as
        consumed (released)?
      </div>
      <div>
        Please provide the Transaction Id recorded by the bank for the above
        payment
      </div>
      <div>
        <input
          className='border border-gray-200 p-1'
          defaultValue={txn}
          onBlur={(evt) => {
            setTxn(evt.target.value);
          }}
        />
      </div>
      <div>
        {report.details?.batch ? (
          <LoadingButton
            text='Save'
            behaviorFn={async () => {
              const [year, month] = report.monthYear.split('-');
              const sals = await getSalaries({ month, year });
              const filteredSals = sals.filter(
                (x) =>
                  x.releaseDetails?.batchNumber === report.details?.batch &&
                  x.status === 'sent-for-release'
              );
              if (filteredSals.length) {
                console.log(filteredSals);
                console.log(txn, 'will be saved', report.details?.batch);
                for (const sal of filteredSals) {
                  console.log({
                    id: sal.id,
                    status: 'released',
                    releaseDetails: { bulkTransactionId: txn },
                  });
                  await setSalary({
                    id: sal.id,
                    status: 'released',
                    releaseDetails: { bulkTransactionId: txn },
                  } as any);
                }

                await setPayrollReport({
                  id: report.id,
                  status: 'consumed',
                } as any);

                toast.success('Disbursement successfully recorded!');
                onClose();
              }
            }}
            behaviourParams={{}}
          />
        ) : null}
      </div>
    </div>
  );
}
