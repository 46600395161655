import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import { EmployeeCategory } from '@erp_core/erp-types/dist/modules/constants';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseEmployeeCategories } from '../../../../../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseEmployeeDesignations } from '../../../../../../../hooks/admin/constants/employee-designation/use-employee-designations';

export const renderAttendanceRuleSimpleForm = ({
  onSave,
  useEmployeeCategories,
  useEmployeeDesignations,
}: {
  onSave: (form) => Promise<void>;
  useEmployeeCategories: UseEmployeeCategories;
  useEmployeeDesignations: UseEmployeeDesignations;
}) => {
  type FormType = {
    categories: { id: string; name: string }[];
    designations: string;
  };

  return function ({
    categories,
    comGrpSet,
    ruleName,
  }: {
    categories: EmployeeCategory[];
    comGrpSet: CompanyGroupSetting;
    ruleName: string;
  }) {
    if (!categories || categories.length === 0) return <div></div>;

    const initialFormState: {
      categories: UserIdName[];
      designations: UserIdName[];
    } = {
      categories: [],
      designations: [],
    };

    comGrpSet.details.hrd?.attendanceRules[ruleName]?.categories?.forEach(
      (ss: { id: string; name: string; value: any }) => {
        initialFormState.categories.push({ id: ss.id, name: ss.name });
      }
    );

    comGrpSet.details.hrd?.attendanceRules[ruleName]?.designations?.forEach(
      (ss: { id: string; name: string; value: any }) => {
        initialFormState.designations.push({ id: ss.id, name: ss.name });
      }
    );

    const Form = renderFormV2<FormType>({
      fieldsData: [
        {
          type: 'searchable-multi-select',
          searchOptions: {
            useSearch: useEmployeeCategories,
            onSearchValueSelect: (e) => {},
          },
          label: 'Grade',
          property: 'categories',
        },

        {
          type: 'searchable-multi-select',
          searchOptions: {
            useSearch: useEmployeeDesignations,
            onSearchValueSelect: (e) => {},
          },
          label: 'Designations',
          property: 'designations',
        },
      ],
      initialFormState: initialFormState,
      onSubmit: async (form) => {
        await onSave(form);
      },
      allowUnchanged: true,
      mapTToU: (p) => p as FormType,
      button: { style: 'w-fit' },
      style: 'mb-4',
    });

    return (
      <div>
        <Form />
      </div>
    );
  };
};
