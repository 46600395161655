import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import { DepartmentAppType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseDepartment } from '../../../../../hooks/hrd/department/use-department';
import { UseDepartments } from '../../../../../hooks/hrd/department/use-departments';
import { UseEmployeeProfiles } from '../../../../../hooks/hrd/employee/profile/use-employee-profiles';

type RenderDepartmentForm = {
  useEmployees: UseEmployeeProfiles;
  useDepartments: UseDepartments;
  useDepartment: UseDepartment;
};

export type DepartmentFormType = {
  name: string;
  description: string;
  hod: UserIdName;
  parentDepartmentId: UserIdName;
  expenseType: 'unknown' | 'direct' | 'indirect';
};

export function renderAddDepartmentForm({
  useEmployees,
  useDepartments,
  useDepartment,
}: RenderDepartmentForm) {
  return function AddDepartmentForm({
    onSave,
    data,
  }: {
    onSave: any;
    data?: DepartmentAppType;
  }): JSX.Element {
    const { data: deptData, get } = useDepartment();

    useEffect(() => {
      if (data?.parentDepartmentId) {
        get(data?.parentDepartmentId);
      }
      // eslint-disable-next-line
    }, [data?.parentDepartmentId]);

    const formProps: FormProps<DepartmentFormType> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          label: 'Name',
          required: true,
        },
        {
          property: 'description',
          type: 'input',
          label: 'Description',
          required: true,
        },
        {
          property: 'hod',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useEmployees,
            onSearchValueSelect: () => {},
            filter: { crossGroup: 'true' },
          },
          required: true,
          label: 'HOD',
        },
        {
          property: 'parentDepartmentId',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useDepartments,
            onSearchValueSelect: () => {},
            filter: { crossGroup: 'true' },
          },
          label: 'Parent Department Name',
        },
        {
          property: 'expenseType',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Direct', value: 'direct' },
            { text: 'Indirect', value: 'indirect' },
            { text: 'Unknown', value: 'unknown' },
          ],
        },
      ],
      initialFormState: {
        name: data?.name || '',
        description: data?.description || '',
        hod: data?.hod || '',
        expenseType: data?.details.expenseType || 'unknown',
        parentDepartmentId:
          ({ id: deptData?.id, name: deptData?.name } as UserIdName) ||
          ({ id: '', name: '' } as UserIdName),
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      allowUnchanged: true,
      onSubmit: onSave,
    };

    const Form = renderFormV2<DepartmentFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<DepartmentFormType>
    ): DepartmentFormType {
      return (b as unknown) as DepartmentFormType;
    }
  };
}
