// import * as cronjsMatcher from '@datasert/cronjs-matcher';
import {
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { EyeIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../contexts/user';
import { UseEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseQuestionnaireSurveyAnswers } from '../../../../hooks/hrd/questionnaire/questionnairesurveyans/usequestionnairesurveyanswers';

export type RenderTopicProps = {
  useEmployeeProfile: UseEmployeeProfile;
  useQuestionnaireSurveyAnswers: UseQuestionnaireSurveyAnswers;
};

export function renderEmployeeJobTask({
  useEmployeeProfile,
  useQuestionnaireSurveyAnswers,
}: RenderTopicProps): (EmployeeProfileType) => JSX.Element {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return function EmployeeTask({ id }: { id: string }): JSX.Element {
    const date = moment().format('YYYY-MM');
    const currentDate = moment().format('YYYY-MM-DD');
    const navigate = useNavigate();
    const { user: currentUser } = useContext(UserContext);
    const { data: employeeData, get: getEmployeeDetail } = useEmployeeProfile();

    const {
      data: surveyAnsData,
      getAll: getSurveyAns,
    } = useQuestionnaireSurveyAnswers();

    useEffect(() => {
      if (currentUser?.id) {
        getEmployeeDetail(currentUser.id);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    useEffect(() => {}, [surveyAnsData]);

    useEffect(() => {
      getSurveyAns({
        from: `more-than::${date}-00`,
        to: `less-than::${date}-32`,
        createdBy: currentUser.id,
      });
      // eslint-disable-next-line
    }, []);

    if (employeeData?.details?.workLogs?.workLogs.length === 0) {
      return (
        <div className='p-16 border border-gray-200 rounded-lg h-18 border rounded-md cursor-pointer p-2'>
          No WorkLog created....
        </div>
      );
    }

    // const currentDate = `${moment().format('YYYY-MM-DD')}T00:00:00Z`;

    // const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    // console.log(cronjsMatcher.isTimeMatches('0 0 * * *', currentDate,'Asia/Kolkata'));

    // console.log('currentDate',currentDate)

    if (employeeData?.details?.workLogs?.workLogs?.length > 0) {
      return (
        <div>
          <label className='block uppercase tracking-wide text-gray-700 text-xl font-bold mb-8'>
            Work Logs
          </label>
          {employeeData.details.workLogs.workLogs
            .filter(
              (worklog) => worklog.status === 'on'
              // && cronjsMatcher.isTimeMatches(worklog.frequency, currentDate)
            )
            .map((i, index) => {
              const submitted = Boolean(
                surveyAnsData?.find(
                  (x) =>
                    x.topic.id === i.linkId &&
                    moment(x.createdAt).format('YYYY-MM-DD') === currentDate
                )
              );
              return (
                <>
                  {
                    <Card
                      key={index}
                      disclosure={true}
                      header={{
                        title: i.name,
                        actions: [
                          {
                            type: 'button',
                            button: {
                              style: 'bg-green-600 text-white ml-2 rounded-sm',
                              name: 'Previous WorkLog',
                              behaviour: 'confirm',
                              onConfirm: () => ({
                                title:
                                  'Are you sure you want to submit Previous worklog?',
                                message:
                                  'Please provide date in: YYYY-MM-DD format',
                                type: 'warning',
                                showInput: true,
                                onConfirm: async (input) => {
                                  navigate(
                                    '/miscellaneous/questionnaire/survey-answers/' +
                                      i.linkId +
                                      `/date/${moment(input).format(
                                        'YYYY-MM-DD'
                                      )}`
                                  );
                                },
                              }),
                            },
                          },
                          {
                            type: 'button',
                            button: {
                              style: submitted
                                ? 'bg-gray-600 text-white ml-2 rounded-sm'
                                : 'bg-green-600 text-white ml-2 rounded-sm',
                              name: submitted ? 'Submitted' : 'Submit',
                              behaviour: 'click',
                              onClick: async () => {
                                if (!submitted) {
                                  navigate(
                                    '/miscellaneous/questionnaire/survey-answers/' +
                                      i.linkId +
                                      `/date/${moment().format('YYYY-MM-DD')}`
                                  );
                                }
                              },
                            },
                          },
                        ],
                      }}
                      body={{
                        type: 'jsx-component',
                        body: (
                          <div className='p-8 border border-gray-200 rounded-lg h-18 border rounded-md'>
                            <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
                              <Table
                                header={[
                                  [
                                    { name: 'Topic Name' },
                                    { name: 'Date' },
                                    { name: 'Created At' },
                                    { name: 'View' },
                                  ],
                                ]}
                                body={(surveyAnsData || [])
                                  .filter(
                                    (item) =>
                                      item.createdBy.id === currentUser.id &&
                                      item.topic.id === i.linkId
                                  )
                                  .sort((a, b) => {
                                    const aDate =
                                      a.details?.date || a.createdAt;
                                    const bDate =
                                      b.details?.date || b.createdAt;
                                    return moment(bDate).diff(
                                      moment(aDate),
                                      'day'
                                    );
                                  })
                                  .map((item) => {
                                    return {
                                      cells: [
                                        {
                                          value: item.topic.name,
                                        },
                                        {
                                          value: moment(
                                            item.details?.date || item.createdAt
                                          ).format('DD MMM YYYY'),
                                        },
                                        {
                                          value: moment(item.createdAt).format(
                                            'DD MMM YYYY, hh:mm A'
                                          ),
                                        },
                                        {
                                          value: (
                                            <EyeIcon
                                              onClick={() => {
                                                navigate(
                                                  `/miscellaneous/questionnaire/${i.linkId}/survey-answers/${item.id}`
                                                );
                                              }}
                                              className='w-5 inline text-blue-700'
                                            />
                                          ),
                                        },
                                      ],
                                    };
                                  })}
                              />
                            </div>
                          </div>
                        ),
                      }}
                    />
                  }
                </>
              );
            })}
        </div>
      );
    }

    return (
      <div className='p-16 border border-gray-200 rounded-lg h-18 border rounded-md cursor-pointer p-2'>
        No WorkLog created....
      </div>
    );
  };
}
