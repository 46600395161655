import { InformationCircleIcon } from '@heroicons/react/24/outline';

export function Strip({
  message,
  styleCSS,
  type,
}: {
  message: string;
  styleCSS?: string;
  type?: 'info';
}): JSX.Element {
  return (
    <div
      className={`p-2 border rounded-md bg-indigo-100 text-center capitalize text-xl ${
        styleCSS || ''
      }`}
    >
      {type === 'info' ? (
        <InformationCircleIcon className='inline w-8 h-8 text-indigo-900' />
      ) : null}
      {message}
    </div>
  );
}
