/* eslint-disable react-hooks/exhaustive-deps */
import { Clock } from '@erp_core/erp-icons/icons/web/clock';
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { OvertimeType } from '@erp_core/erp-types/dist/types/modules/hrd/overtime';
import {
  CardBody,
  CardHeader,
  MonthSelector,
  MultiSelect,
  renderCardComponent,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { CurrencyRupeeIcon, GiftIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseOvertime } from '../../../../hooks/hrd/employee/profile/overtime/use-overtime';
import { UseOvertimes } from '../../../../hooks/hrd/employee/profile/overtime/use-overtimes';
import { UseEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseLocationHolidays } from '../../../../hooks/planning/location-holiday/use-location-holidays';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderOvertimeReportingForm } from '../forms/overtime-reporting';
import { saveNewOvertime } from '../util/add-overtime';
import { BulkOvertimeActionForm } from './form/bulk-overtime-approval-form';
import { EmployeeOTRedeemSelector } from './form/set-ot-redemption';

export const renderMonthOvertimes = ({
  useUser,
  useUsers,
  useOvertime,
  useOvertimes,
  useEmployeeProfiles,
  useUserAuthorization,
  // useCompanyGroupSettings,
  useCurrentUserRoles,
  userRendererService,
  useHolidays,
}: {
  useUser: UseEmployeeProfile;
  useUsers: UseEmployeeProfiles;
  useOvertime: UseOvertime;
  useOvertimes: UseOvertimes;
  useEmployeeProfiles: UseEmployeeProfiles;
  useUserAuthorization: UseUserAuthorization;
  useCurrentUserRoles: UseCurrentUserRoles;
  userRendererService: UserRendererInterface;
  useHolidays: UseLocationHolidays;
}) => {
  // const AuthWrapper = renderAuthWrapper({ useUserAanyuthorization });

  return function Overtimes() {
    const { data: overtimes, getAll: getOvertimes, loading } = useOvertimes();
    const { syncSet: setOvertime } = useOvertime();
    const { user: currentUser } = useContext(UserContext);
    const { cgSetting } = useContext(CurrentContext);
    const { getAll: getAllUsers, data: users } = useUsers();
    const { getSync: getUserSync } = useUser();
    const { data: employees, getAll: getAllEmployees } = useEmployeeProfiles();
    const { data: currentUserRoles } = useCurrentUserRoles();
    const { data: holidays, getAll: getHolidays } = useHolidays();
    const { get: getAllowedActions } = useUserAuthorization();

    useEffect(() => {
      getAllEmployees({ crossGroup: 'true' });
      // eslint-disable-next-line
    }, []);
    const [date, setDate] = useState(
      localStorage.getItem('overtime-date') || moment.utc().format('YYYY-MM')
    );

    const [year, setYear] = useState(moment(date).format('YYYY'));

    useEffect(() => {
      getOvertimes({ date, crossGroup: 'true', sortBy: 'date', order: 'desc' });
      localStorage.setItem('overtime-date', date);
      if (moment(date).format('YYYY') !== year) {
        setYear(moment(date).format('YYYY'));
      }
    }, [date]);

    useEffect(() => {
      getHolidays({ date: year });
    }, [year]);

    useEffect(() => {
      getAllUsers({ crossGroup: 'true' });
      // eslint-disable-next-line
    }, []);

    const OvertimeForm = renderOvertimeReportingForm({
      useUsers,
      useOvertimes,
    });

    const editOvertime = async (form: any, id: string) => {
      const finalData = {
        id,
        ...form,
      };
      await setOvertime(finalData);
      toast('Overtime edited sucessfully');
      getOvertimes({ date, crossGroup: 'true', sortBy: 'date', order: 'desc' });
    };

    const cancelOvertime = async (id: string) => {
      const finalData = {
        id,
        status: 'cancelled',
      };
      await setOvertime(finalData as any);
      toast('Cancelled sucessfully');
      getOvertimes({ date, crossGroup: 'true', sortBy: 'date', order: 'desc' });
    };

    const approveOvertime = async (id: string) => {
      const finalData = {
        id,
        status: 'approved',
      };
      await setOvertime(finalData as any);
      toast('Approved sucessfully');
      getOvertimes({ date, crossGroup: 'true', sortBy: 'date', order: 'desc' });
    };

    const unapproveOvertime = async (id: string) => {
      const finalData = {
        id,
        status: 'issued',
      };
      await setOvertime(finalData as any);
      toast('Unapproved sucessfully');
      getOvertimes({ date, crossGroup: 'true', sortBy: 'date', order: 'desc' });
    };

    const Card = renderCardComponent();
    const CardHeader: CardHeader = {
      title: 'Overtimes',
      icon: (
        <Clock className='w-8 h-8 inline stroke-none text-indigo-900 self-center' />
      ),
      subheading: (
        <span className='text-blue-700 font-bold'>
          {moment(date).format('MMM YYYY')}
        </span>
      ),
      actions: [
        {
          type: 'jsx',
          jsx: (
            <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
              <span className='font-bold truncate'>Select month</span>
              <MonthSelector
                format='YYYY-MM'
                initialState={date}
                onChange={(m) => setDate(m)}
              />
            </div>
          ),
        },
        {
          type: 'button',
          button: {
            name: 'Add Overtime',
            behaviour: 'modal',
            modal: {
              title: 'Add Overtime',
              content: ({ onClose }) => {
                return (
                  <OvertimeForm
                    onSave={(form) => {
                      saveNewOvertime({
                        form,
                        getUserSync,
                        currentUser,
                        onClose,
                        setOvertime,
                      });
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();
    const TableHeader: TableHeader = [
      [
        { name: 'Date', style: 'hidden md:table-cell' },
        { name: 'Employee' },
        {
          name: 'Hours',

          style: 'hidden md:table-cell',
        },
        {
          name: 'IssuedBy',
          style: 'hidden md:table-cell',
        },
        {
          name: 'Reason',
          style: 'hidden md:table-cell',
        },
        {
          name: 'Status',
          style: 'hidden md:table-cell',
        },
      ],
    ];

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'Edit',
          show: ({ overtime }: { overtime: OvertimeType }) => {
            // we want to give ability to edit only when the gatepass
            // is in pending state.
            if (['issued'].includes(overtime.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Edit',
            content: ({
              data: { overtime },
              onClose,
            }: {
              data: {
                overtime: OvertimeType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <OvertimeForm
                    onSave={async (e) => {
                      await editOvertime(e, overtime.id);
                      onClose();
                    }}
                    data={overtime as any}
                    isEdit={true}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Approve',
          show: ({ overtime }: { overtime: OvertimeType }) => {
            if (
              ['issued'].includes(overtime.status) &&
              users?.find((u) => u.id === overtime.employee.id)?.details
                ?.authorizations?.attendanceAuthorizer?.id === currentUser.id
            ) {
              return true;
            }

            if (
              ['issued'].includes(overtime.status) &&
              currentUserRoles?.find((x) => x.name === 'System-Admin')
            ) {
              return true;
            }

            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ overtime }) => {
            return {
              title: 'Are you sure you want to approve overtime?',
              message: `Approve overtime for ${overtime.employee.name}?`,
              type: 'warning',
              onConfirm: async () => {
                await approveOvertime(overtime.id);
              },
            };
          },
        },
        {
          name: 'Un-Approve',
          show: ({ overtime }: { overtime: OvertimeType }) => {
            if (
              ['approved'].includes(overtime.status) &&
              users?.find((u) => u.id === overtime.employee.id)?.details
                ?.authorizations?.attendanceAuthorizer?.id === currentUser.id
            ) {
              return true;
            }

            if (
              ['approved'].includes(overtime.status) &&
              currentUserRoles?.find((x) =>
                ['System-Admin', 'HR Officer'].includes(x.name)
              )
            ) {
              return true;
            }

            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ overtime }) => {
            return {
              title: 'Are you sure you want to un-approve overtime?',
              message: `un-approve overtime for ${overtime.employee.name}?`,
              type: 'warning',
              onConfirm: async () => {
                await unapproveOvertime(overtime.id);
              },
            };
          },
        },
        {
          name: 'Cancel',
          show: ({ overtime }: { overtime: OvertimeType }) => {
            if (['issued'].includes(overtime.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ overtime }) => {
            return {
              title: 'Are you sure you want to cancel overtime?',
              message: `Cancel overtime for ${overtime.employee.name}?`,
              type: 'warning',
              onConfirm: async () => {
                await cancelOvertime(overtime.id);
              },
            };
          },
        },
        {
          name: 'Set-redemption',
          auth: 'UI:OT-REDEMPTION:VIEW',
          show: ({ overtime }: { overtime: OvertimeType }) => {
            if (!cgSetting) {
              return false;
            }
            if (['approved'].includes(overtime.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Set-redemption',
            content: ({
              data: { overtime },
              onClose,
            }: {
              data: {
                overtime: OvertimeType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <div className='text-center p-1 border border-gray-200 rounded my-1'>
                    Requested OT Redemption:{' '}
                    {overtime.details?.requestedRedemptionFlow || 'none'}
                  </div>
                  <>
                    <EmployeeOTRedeemSelector
                      isLocationOff={Boolean(
                        holidays?.find((x) => x.date === overtime.date)
                      )}
                      companyGroupSetting={cgSetting}
                      employees={employees || []}
                      overtime={overtime}
                      setOvertime={setOvertime}
                      getOvertimes={getOvertimes}
                      date={date}
                      onClose={onClose}
                    />
                  </>
                </div>
              );
            },
          },
        },
      ];
    }

    function renderTableBodyMapper(overtimes?: OvertimeType[]): TableBody {
      return (
        overtimes?.map((p) => ({
          rowData: {
            overtime: p,
          },
          cells: [
            {
              style: 'hidden md:table-cell',
              value: p.date,
            },
            {
              value: (
                <div>
                  <div>
                    <span className='inline-flex'>
                      <userRendererService.userCard
                        link={true}
                        size='small'
                        id={p.employee.id}
                        name={p.employee.name}
                        extraInfo={p.company.name}
                      />
                      {p?.details?.redemptionFlow === 'salary' ? (
                        <CurrencyRupeeIcon
                          className='w-5 h-5'
                          title='Redeem as Salary'
                        />
                      ) : p?.details?.redemptionFlow === 'comp-off' ? (
                        <GiftIcon
                          className='w-5 h-5'
                          title='Redeem as Comp-off'
                        />
                      ) : (
                        <></>
                      )}
                    </span>
                  </div>
                  <div className='md:hidden'>
                    <div>
                      Date: {p.date} for {p.hours}, applied by {p.issuedBy.name}
                    </div>
                    <div>Status: {p.status}</div>
                    <div>Reason: {p.reason}</div>
                  </div>
                </div>
              ),
            },
            {
              style: 'hidden md:table-cell',
              value: p.hours,
            },
            {
              style: 'hidden md:table-cell',
              value: p.issuedBy.name,
            },
            {
              style: 'hidden md:table-cell',
              value: p.reason,
            },
            {
              style: 'hidden md:table-cell',
              value: p.status,
            },
          ],
        })) || []
      );
    }
    const TableBody: TableBody = renderTableBodyMapper(overtimes);

    const filteredOT = _.sortBy(
      overtimes?.filter((x) => {
        const emp = employees?.find((e) => e.id === x.employee.id);
        if (
          !x?.details?.redemptionFlow &&
          emp &&
          (emp.details?.authorizations?.attendanceAuthorizer?.id ||
            emp.details?.authorizations?.attendanceSupervisor?.id) ===
            currentUser.id
        ) {
          return true;
        }
        return false;
      }),
      (ot) => {
        switch (ot.status) {
          case 'issued':
            return 0;
          case 'approved':
            return 1;
          case 'redeemed':
            return 2;
          default:
            return 3;
        }
      }
    );

    const FilteredTableBody: TableBody = renderTableBodyMapper(filteredOT);

    const MultiSelect: MultiSelect = {
      actions: [
        {
          name: 'Approve',
          show: () => true,
          behaviour: 'modal',
          modal: {
            title: 'Approve',
            content: ({ entries, onClose }) => {
              return (
                <BulkOvertimeActionForm
                  list={filteredOT.filter((x, idx) => entries.includes(idx))}
                  onClose={onClose}
                  setOvertime={setOvertime}
                  currentUser={currentUser}
                  getOvertime={getOvertimes}
                  date={date}
                />
              );
            },
          },
        },
      ],
    };

    const CardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            {loading ? (
              <div className='flex my-24 justify-center'>
                <Recat className='h-5 inline animate-pulse mx-4' />
              </div>
            ) : (
              <>
                <div className='my-5'>
                  <div className='text-center p-1 border border-gray-200 font-bold'>
                    Requests for you
                  </div>
                  <Table
                    header={TableHeader}
                    body={FilteredTableBody}
                    multiSelect={MultiSelect}
                    actions={addActions()}
                    auth={{ actions: getAllowedActions().actions }}
                  />
                </div>

                <div className='my-5'>
                  <div className='text-center p-1 border border-gray-200 font-bold'>
                    All Requests
                  </div>
                  <Table
                    header={TableHeader}
                    body={TableBody}
                    actions={addActions()}
                    auth={{ actions: getAllowedActions().actions }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card header={CardHeader} body={CardBody} />
      </>
    );
  };
};
