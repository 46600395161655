/* eslint-disable react-hooks/exhaustive-deps */
import { PaymentTerm } from '@erp_core/erp-types/dist/modules/constants';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableBodyRow,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UsePaymentTerm } from '../../../hooks/constants/payment-term/use-payment-term';
import {
  PaymentTermFilter,
  UsePaginatedPaymentTerms,
  UsePaymentTerms,
} from '../../../hooks/constants/payment-term/use-payment-terms';
import { renderAddPaymentTermsForm } from './form/add-payment-term';

export function renderPaymentTerms({
  usePaginatedPaymentTerms,
  usePaymentTerms,
  usePaymentTerm,
}: {
  usePaginatedPaymentTerms: UsePaginatedPaymentTerms;
  usePaymentTerms: UsePaymentTerms;
  usePaymentTerm: UsePaymentTerm;
}): () => JSX.Element {
  return function PaymentTerms(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      PaymentTerm,
      PaymentTermFilter
    >();

    const { getAll: getPaymentTerms } = usePaymentTerms();

    const { syncSet: setPaymentTerm } = usePaymentTerm();

    useEffect(() => {
      getPaymentTerms();
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Payment Terms',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Payment Terms',
            behaviour: 'modal',
            modal: {
              title: 'Add Payment Terms',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveCurrency(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddPaymentTermsForm();

    const saveCurrency = async (form: Partial<PaymentTerm>) => {
      try {
        const finalData = {
          ...form,
        } as PaymentTerm;
        await setPaymentTerm(finalData as PaymentTerm);
        toast('Data added sucessfully');
        getPaymentTerms();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [
      [{ name: 'Payment Term' }, { name: 'Description' }],
    ];

    const bodyMapper: (d: PaymentTerm) => TableBodyRow = (d) => {
      return {
        cells: [{ value: d.name }, { value: d.description || '--' }],
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full h-full'>
            <Table
              header={tableHeader}
              useResources={usePaymentTerms}
              bodyMapper={bodyMapper}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedPaymentTerms,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
