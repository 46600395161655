import { RepaymentScheduleType } from '@erp_core/erp-types/dist/modules/hrd';
import { LoanFilter } from '@erp_core/erp-types/dist/types/modules/hrd/loan';
import { RepaymentScheduleFilter } from '@erp_core/erp-types/dist/types/modules/loan/repayment-schedule';
import { LoanHttpRepo } from '../datalayer/repository/http/hrd/loans/loan';
import { RepaymentScheduleHttpRepo } from '../datalayer/repository/http/hrd/loans/repayment-schedule';
import { createUseDisbursementValue } from '../hooks/loans/loan/use-loan-disbursement';
import { createUseDisbursementPostValue } from '../hooks/loans/loan/use-loan-post-disbursement';
import { createUseAdvPaymentValue } from '../hooks/loans/repayment-schedule/use-advance-payment';
import { createUseSkipRepaymentValue } from '../hooks/loans/repayment-schedule/use-repayment-skipped';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { LoanInterface } from '../models/interfaces/loans/loan';
import { RepaymentScheduleInterface } from '../models/interfaces/loans/repayment-schedule';
import { LoanV2Type } from '../modules/hrd/loan-management/types/loan-v2-type';
import { erpV2Api } from './admin';
import { LOANS_SERVER_URI } from './server-uri';

export const loanService = new LoanHttpRepo(LOANS_SERVER_URI, erpV2Api);
export const useLoan = createUseResource<LoanV2Type, LoanFilter, LoanInterface>(
  {
    useService: () => loanService,
  }
);

export const useLoans = createUseResources<
  LoanV2Type,
  LoanFilter,
  LoanInterface
>({
  useService: () => loanService,
});

export const useDisbursementData = createUseDisbursementValue({
  useDisbursementValueService: () => loanService,
});

export const useDisbursementPostData = createUseDisbursementPostValue({
  useDisbursementValuePostService: () => loanService,
});

export const loanRepaymentScheduleService = new RepaymentScheduleHttpRepo(
  LOANS_SERVER_URI,
  erpV2Api
);
export const useRepaymentSchedule = createUseResource<
  RepaymentScheduleType,
  RepaymentScheduleFilter,
  RepaymentScheduleInterface
>({
  useService: () => loanRepaymentScheduleService,
});

export const useRepaymentSchedules = createUseResources<
  RepaymentScheduleType,
  RepaymentScheduleFilter,
  RepaymentScheduleInterface
>({
  useService: () => loanRepaymentScheduleService,
});

export const useSkipRepaymentData = createUseSkipRepaymentValue({
  useSkipRepaymentService: () => loanRepaymentScheduleService,
});

export const useAdvPaymentData = createUseAdvPaymentValue({
  useAdvPaymentService: () => loanRepaymentScheduleService,
});
