/* eslint-disable no-unused-vars */
import { useState } from 'react';
import {
  BaseResourceType,
  FilterType,
  ResourceWithLogsInterface,
} from '../../models/interfaces/generic-resource';

type Options = {
  enableLoading?: boolean;
  enableResource?: boolean;
};

export type Log = {
  event: string;
  time: string;
  action: string;
  details: string;
  changes?: Array<{
    path: string;
    pathToShow: string;
    initialValue: string;
    finalValue: string;
  }>;
};

export type UseResource<T extends BaseResourceType> = () => {
  data: T;
  set: (s: T) => void;
  syncSet: (s: T, o?: Options) => Promise<T>;
  getSync: (id: string, o?: Options) => Promise<T>;
  get: (id: string) => void;
  loading: boolean;
  delete: (s: string) => void;
  getLogs: (id: string) => Promise<Array<Log>>;
};

export interface UseResourceOptions<U> {
  useService: () => U;
}

export function createUseResource<
  T extends BaseResourceType,
  U extends FilterType,
  V extends ResourceWithLogsInterface<T, U>
>({ useService }: UseResourceOptions<V>): UseResource<T> {
  return () => {
    const [resource, setResource] = useState<T>({} as T);
    const [loading, setLoading] = useState<boolean>(false);

    const resourceService = useService();

    const apiCall = async (id: string) => {
      setLoading(true);
      const ra = await resourceService.get(id);
      setResource(ra);
      setLoading(false);
    };

    const set = async (resource: T) => {
      setLoading(true);
      const c = await resourceService.set(resource);
      setResource(c);
      setLoading(false);
    };

    // pass options to disable state change inside this hook
    const syncSet = async (
      resource: T,
      options?: { enableLoading?: boolean; enableResource?: boolean }
    ) => {
      const { enableLoading, enableResource } = options || {
        enableLoading: true,
        enableResource: true,
      };
      enableLoading && setLoading(true);
      const c = await resourceService.set(resource);
      enableResource && setResource(c);
      enableLoading && setLoading(false);
      return c;
    };

    // pass options to disable state change inside this hook
    const getSync = async (
      id: string,
      options?: { enableLoading?: boolean; enableResource?: boolean }
    ) => {
      const { enableLoading, enableResource } = options || {
        enableLoading: false,
        enableResource: false,
      };
      enableLoading && setLoading(true);
      const c = await resourceService.get(id);
      enableResource && setResource(c);
      enableLoading && setLoading(false);
      return c;
    };

    const del = async (id: string) => {
      setLoading(true);
      await resourceService.delete(id);
      setLoading(false);
    };

    const getLogs = async (id: string): Promise<Array<Log>> => {
      return resourceService.getLogs(id);
    };

    return {
      data: resource,
      set: (s: T) => {
        set(s);
      },
      loading,
      syncSet: (s: T, o?: Options) => syncSet(s, o),
      getSync: (id: string, o?: Options) => getSync(id, o),
      delete: (id: string) => {
        del(id);
      },
      get: (id: string) => {
        apiCall(id);
      },
      getLogs: (id: string): Promise<Array<Log>> => {
        return getLogs(id);
      },
    };
  };
}
