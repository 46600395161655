import { LeaveAppType } from '@erp_core/erp-types/dist/modules/hrd';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';

export type EmployeesLeaveFormType = {
  employee: IdName;
  type: TypePattern;
  quantity: number;
  duration: 'half-day' | 'full-day';
  justification: string;
};

// Note: Comp-off not to be set from here
export const leaveTypeList: string[] = [
  'casual-leave',
  'sick-leave',
  'privilege-leave',
  // 'unpaid-leave',
  'compensatory-off',
];

type RenderEmployeesLeaveForm = {
  useEmployeeProfiles: UseEmployeeProfiles;
};

type TypePattern =
  | 'casual-leave'
  | 'privilege-leave'
  | 'sick-leave'
  | 'unpaid-leave'
  | 'compensatory-off'
  | 'on-job-accident-leave'
  | 'maternity-leave'
  | 'layoff-leave';

export const renderAddEmployeesLeaveForm = ({
  useEmployeeProfiles,
}: RenderEmployeesLeaveForm) => {
  return function EmployeesLeaveForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (d: EmployeesLeaveFormType) => Promise<void>;
    isEdit?: boolean;
    data?: LeaveAppType;
  }) {
    async function validateDuration(
      form: FormDataType<EmployeesLeaveFormType>
    ): Promise<{
      [k: string]: string;
    }> {
      // if (form.dateStart && form.dateEnd && form.dateStart !== form.dateEnd &&  form.duration === 'half-day'
      // ) {
      //   return {
      //     'duration': 'Half-day is not possible for the selected date range',
      //   }
      // }

      const result: any = {};

      if (
        form.duration === 'half-day' &&
        ['maternity-leave', 'layoff-leave'].includes(form.type as string)
      ) {
        result.duration = `${form.type} cannot be added as half day`;
      }

      if (parseFloat(`${form.quantity}`) < 0) {
        result.quantity = `${form.type} cannot be negative`;
      }

      return result;
    }

    const formProps: FormProps<EmployeesLeaveFormType> = {
      fieldsData: [
        {
          property: 'employee',
          type: 'searchable-select',
          label: 'Employee Name',
          required: true,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            onSearchValueSelect: () => {},
            filter: { crossGroup: 'true' },
          },
          readonly: isEdit,
        },
        {
          property: 'type',
          type: 'select',
          label: 'Type',
          required: true,
          options: [
            { text: 'Select', value: '' },
            ...leaveTypeList.map((lt) => {
              return { value: lt, text: lt };
            }),
          ],
        },
        {
          property: 'quantity',
          type: 'number',
          label: 'Quantity',
          required: true,
        },
        {
          property: 'duration',
          type: 'select',
          label: 'Duration',
          required: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'Full Day', value: 'full-day' },
            { text: 'Half Day', value: 'half-day' },
          ],
          validate: validateDuration,
        },
        {
          property: 'justification',
          type: 'input',
          label: 'Justification',
          required: true,
        },
      ],
      initialFormState: {
        type: data?.type || '',
        employee: data?.employee || '',
        quantity: 0,
        duration: data?.duration || '',
        justification: data?.justification || '',
      },
      allowUnchanged: !isEdit,
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EmployeesLeaveFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<EmployeesLeaveFormType>
    ): EmployeesLeaveFormType {
      return b as EmployeesLeaveFormType;
    }
  };
};
