import { renderHistory } from '@erp_core/erp-ui-components';
import { UseSalesOrder } from '../../../../hooks/order/sales/order/use-order';

export function renderHistoryTab({
  id,
  useSalesOrder,
}: {
  id: string;
  useSalesOrder: UseSalesOrder;
}): () => JSX.Element {
  return function HistoryTab(): JSX.Element {
    const History = renderHistory();

    return <History useResource={useSalesOrder} id={id} />;
  };
}
