import { LoanType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseLoans } from '../../../../hooks/loans/loan/use-loans';

type RenderAddLoanFormProps = {
  useUsers: UseEmployeeProfiles;
  useLoans: UseLoans;
};

export function renderAddLoanForm({
  useUsers,
  useLoans,
}: RenderAddLoanFormProps) {
  return function AddLoanForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (data: LoanType) => Promise<void>;
    isEdit?: boolean;
    data?: LoanType;
  }): JSX.Element {
    const { data: loans, getAll: getLoans } = useLoans();

    useEffect(() => {}, [loans]);

    useEffect(() => {
      getLoans();
      // eslint-disable-next-line
    }, []);

    const rateOfInterestData = loans?.find((x) => x.id === data?.id)
      ?.rateOfInterest;

    let rateOfInterest;
    if (rateOfInterestData) {
      rateOfInterest = rateOfInterestData;
    }

    const formProps: FormProps<Partial<LoanType>> = {
      fieldsData: [
        {
          property: 'employee',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useUsers,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
          label: 'Employee Name',
          required: true,
          readonly: data?.status === 'pending' ? true : false,
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
          readonly: data?.status === 'pending' ? true : false,
        },
        {
          property: 'amount',
          type: 'number',
          label: 'Amount',
          required: true,
        },
        {
          property: 'tenure',
          type: 'number',
          label: 'Tenure(in months)',
          required: true,
        },
        {
          property: 'rateOfInterest',
          type: 'input',
          label: 'Rate Of Interest',
          required: data?.status === 'pending' ? true : false,
          dependentOn: () => {
            return data?.status === 'pending';
          },
        },
        {
          property: 'status',
          type: 'select',
          options: [
            { value: '', text: 'select' },
            { value: 'approved', text: 'approved' },
            { value: 'rejected', text: 'rejected' },
          ],
          dependentOn: () => {
            return data?.status === 'pending';
          },
        },
      ],
      initialFormState: {
        employee: data?.employee || '',
        amount: data?.amount || '',
        tenure: data?.tenure || '',
        reason: data?.reason || '',
        rateOfInterest: rateOfInterest || '',
        status: data?.status || '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          id: data?.id,
          employee: l.employee || data?.employee,
          amount: l.amount || data?.amount,
          tenure: l.tenure || data?.tenure,
          reason: l.reason || data?.reason,
          status: l.status || data?.status,
          rateOfInterest: l?.rateOfInterest || rateOfInterest,
        } as LoanType);
      },
    };

    const Form = renderFormV2<Partial<LoanType>>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<Partial<LoanType>>): LoanType {
      return (b as unknown) as LoanType;
    }
  };
}
