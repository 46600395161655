/* eslint-disable react-hooks/exhaustive-deps */
import { Vendor } from '@erp_core/erp-types/dist/modules/order';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../contexts/current';
import { UsePurchaseOrders } from '../../../../hooks/inventory/purchase/purchase-order/use-purchase-orders';
import { generatePoName } from '../../../../utils/company-group-setting/generate-po-name';
// import { FormDataType, FormProps, renderFormV2 } from 'v2/components/form/generic-form-renderer-v2';

type CreatePoFormData = {
  prDetails?: JSX.Element;
  enquiryDetails?: JSX.Element;
  vendorBusinessTerms?: JSX.Element;
  deliverySchedule?: JSX.Element;
  paymentSchedule?: JSX.Element;
  quantity: number;
  onSave: (data: any) => Promise<void>;
  vendors?: Vendor[];
  onVendorChange?: any;
  vendorContacts?: any;
  vendorDetails?: JSX.Element;
};

type CreatePoFormType = {
  vendorContact: JSX.Element;
  vendorDetails?: JSX.Element;
  attachments: any;
  quantity: number;
  remarks: string;
  vendor: string;
  enquiryDetails: JSX.Element;
  prDetails: JSX.Element;
  vendorBusinessTerms: JSX.Element;
  deliverySchedule?: JSX.Element;
  paymentSchedule?: JSX.Element;
};

export const renderCreatePoForm = ({
  usePurchaseOrders,
}: {
  usePurchaseOrders: UsePurchaseOrders;
}) => {
  return function CreatePoForm({
    onSave,
    onVendorChange,
    ...data
  }: CreatePoFormData) {
    const { getAllSync: getAllOrders } = usePurchaseOrders();
    const { cgSetting } = useContext(CurrentContext);
    // const { getAllSync } = useCompanyGroupSettings();
    // eslint-disable-next-line
    const [currentState, setCurrentState] = useState<any>();

    const getVendorContacts = (vendor?: Vendor) => {
      const defaultOption = [{ text: 'select', value: '' }];
      const vendorOptions = vendor?.items.map((vi) => ({
        text: `${vi.details?.contactPerson?.name} - ${vi.details?.supplierLocation?.name}`,
        value: vi.id,
      }));
      return [...defaultOption, ...(vendorOptions || [])];
    };

    // eslint-disable-next-line
    const [options, setOptions] = useState<any>(
      getVendorContacts(data.vendors?.[0])
    );

    // const onCustomChange=(e: any)=>{
    //   // COmmenthing this out as such thing need not be doen from outside  form
    //     // if(e.key==='vendor'){
    //     //     const updated = onVendorChange(e.formData, data.vendors)
    //     //     const selectedVendor = data.vendors?.find((v)=>v.id===e.value);
    //     //     const vendorContacts =  getVendorContacts(selectedVendor)
    //     //     setOptions(vendorContacts)
    //     //     setCurrentState( updated || e.formData)
    //     //     return updated
    //     // }
    //     // return e.formData as any
    // }

    const onSubmit = async (form) => {
      if (!form.quantity) {
        form.quantity = data.quantity;
      }
      if (!form.vendor.id) {
        form.vendor = { id: data.vendors?.[0]?.id };
      }
      if (data.prDetails && !form.vendorContact) {
        toast('vendor contact not selected');
        return;
      }
      form.orderName = await generatePoName({
        getAllOrders,
        cgSetting,
        // getSyncCompanySettings: getAllSync,
      });
      await onSave(form);
    };

    const handleVendorChange = (form) => {
      // onVendorChange(form, data.vendors)
      // setOptions([{text: 'hello', value: '1'}])
      if (!data.prDetails) {
        return false;
      }
      return true;
    };

    const formProps: FormProps<Partial<CreatePoFormType>> = {
      fieldsData: [
        {
          property: 'enquiryDetails',
          label: 'Enquiry Details',
          type: 'input',
          dependentOn: () => {
            if (!data.enquiryDetails) {
              return false;
            }
            return true;
          },
        },
        {
          property: 'prDetails',
          label: 'PR Details',
          type: 'input',
          dependentOn: () => {
            if (!data.prDetails) {
              return false;
            }
            return true;
          },
        },
        {
          property: 'vendorDetails',
          label: 'Vendor Details',
          type: 'input',
          dependentOn: () => {
            if (!data.vendorDetails) {
              return false;
            }
            return true;
          },
        },
        { property: 'vendorBusinessTerms', label: 'Terms', type: 'input' },
        { property: 'deliverySchedule', type: 'input' },
        { property: 'paymentSchedule', type: 'input' },
        {
          property: 'vendor',
          type: 'select',
          options: (data.vendors || [])?.map((data) => ({
            text: data.name,
            value: data.id,
          })),
          dependentOn: handleVendorChange,
        },
        {
          property: 'vendorContact',
          label: 'Vendor Contact',
          type: 'select',
          options: options,
          dependentOn: () => {
            if (!data.prDetails) {
              return false;
            }
            return true;
          },
        },
        {
          property: 'quantity',
          label: 'Quantity',
          type: 'number',
          required: true,
        },
        { property: 'attachments', label: 'Attachments:', type: 'file' },
        {
          property: 'remarks',
          label: 'Remarks',
          type: 'input',
          required: true,
        },
      ],
      style: 'md:w-1/2',
      initialFormState: {
        attachments: '',
        quantity: data.quantity,
        remarks: '',
        enquiryDetails: data.enquiryDetails || <></>,
        prDetails: data.prDetails || <></>,
        vendorBusinessTerms: data.vendorBusinessTerms || <></>,
        deliverySchedule: data.deliverySchedule || <></>,
        paymentSchedule: data.paymentSchedule || <></>,
        vendorContact: '',
        vendor: '',
        vendorDetails: data.vendorDetails || <></>,
      },
      mapTToU: mapPFDToP,
      onSubmit: onSubmit,
      button: {
        style: 'w-fit',
      },
      allowUnchanged: true,
    };

    const Form = renderFormV2<Partial<CreatePoFormType>>(formProps);

    return (
      <div>
        <Form
          // onCustomChange={onCustomChange}
          currentState={currentState}
        />
      </div>
    );

    function mapPFDToP(
      b: FormDataType<Partial<CreatePoFormType>>
    ): Partial<CreatePoFormType> {
      return {
        ...b,
        vendor: { id: b.vendor },
      } as any;
    }
  };
};
