/* eslint-disable react-hooks/exhaustive-deps */
import { RotationalShiftDayType } from '@erp_core/erp-types/dist/modules/planning';
import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { UseCompanies } from '../../../../hooks/admin/company-admin/use-companies-admin';
import { UseEmployeeCategories } from '../../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../../../hooks/file-transfer/use-public-transfer';
import { UseAttendances } from '../../../../hooks/hrd/attendance/use-attendances';
import { UseAttendanceMonthlySummary } from '../../../../hooks/hrd/attendance/use-monthly-summary';
import { UseDepartments } from '../../../../hooks/hrd/department/use-departments';
import { UseMisconduct } from '../../../../hooks/hrd/disciplinary/misconduct/use-misconduct';
import { UseMisconducts } from '../../../../hooks/hrd/disciplinary/misconduct/use-miscounducts';
import { UseEmployeeEducation } from '../../../../hooks/hrd/employee-education-dtls/use-employee-education';
import { UseEmployeeEducations } from '../../../../hooks/hrd/employee-education-dtls/use-employee-educations';
import { UseEmployeeFamilies } from '../../../../hooks/hrd/employee-family-dtls/use-employee-families';
import { UseEmployeeFamily } from '../../../../hooks/hrd/employee-family-dtls/use-employee-family';
import { UseDocument } from '../../../../hooks/hrd/employee-registration-docs/use-document';
import { UseDocuments } from '../../../../hooks/hrd/employee-registration-docs/use-documents';
import { UseEmployeeRegistration } from '../../../../hooks/hrd/employee-registration-dtls/use-registration';
import { UseEmployeeRegistrations } from '../../../../hooks/hrd/employee-registration-dtls/use-registrations';
import { UseGatePass } from '../../../../hooks/hrd/employee/profile/gate-pass/use-gate-pass';
import { UseGatePasses } from '../../../../hooks/hrd/employee/profile/gate-pass/use-gate-passes';
import { UseEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UsePolicies } from '../../../../hooks/hrd/insurance/policy/use-policies';
import { UsePolicy } from '../../../../hooks/hrd/insurance/policy/use-policy';
import { UsePremiums } from '../../../../hooks/hrd/insurance/premiums/use-premiums';
import { UseLeaves } from '../../../../hooks/hrd/leave/use-leaves';
import { UseSalaryAdvance } from '../../../../hooks/hrd/salary-advance/use-salary-advance';
import { UseSalaryAdvanceEligibleAmt } from '../../../../hooks/hrd/salary-advance/use-salary-advance-eligibleAmount';
import { UseSalaryAdvances } from '../../../../hooks/hrd/salary-advance/use-salary-advances';
import { UseSalaryParams } from '../../../../hooks/hrd/salary-parameter/use-salary-params';
import { UseLoan } from '../../../../hooks/loans/loan/use-loan';
import { UseLoans } from '../../../../hooks/loans/loan/use-loans';
import { UseAdvPaymentData } from '../../../../hooks/loans/repayment-schedule/use-advance-payment';
import { UseRepaymentSchedule } from '../../../../hooks/loans/repayment-schedule/use-repayment-schedule';
import { UseRepaymentSchedules } from '../../../../hooks/loans/repayment-schedule/use-repayment-schedules';
import { UseSkipRepaymentData } from '../../../../hooks/loans/repayment-schedule/use-repayment-skipped';
import { UseEmpShiftSchedule } from '../../../../hooks/planning/emp-shift-schedule/use-emp-shift-schedule';
import { UseEmpShiftSchedules } from '../../../../hooks/planning/emp-shift-schedule/use-emp-shift-schedules';
import { UseShiftSchedules } from '../../../../hooks/planning/shift-schedule/use-shift-schedules';
import { employeeIconMapperByGrade, ShiftIcon } from '../../../../utils/common';
import { renderEmployeeJobProfile } from '../emp-job-profile-tab';
import { renderEmployeeAttendance } from '../employee-attendance';
import { renderEmployeeAuthorisers } from '../employee-authoriser/authoriser-tab';
import { renderEmployeeDisciplinary } from '../employee-disciplinary/disciplinary-tab/disciplinary-tab';
import { renderEmployeeLeave } from '../employee-leave/leave-tab';
// import { renderEmployeeLetter } from '../employee-letter/letter-tab/letter';
import { UserContext } from '../../../../contexts/user';
import { UseEmployeeLetters } from '../../../../hooks/hrd/employee-letters/use-employee-letters';
import { UseRotationalShiftDays } from '../../../../hooks/hrd/rotational-shift-day/use-rotational-shift-days';
import { LeaveInterface } from '../../../../models/interfaces/hrd/leave';
import { renderMonthGatePasses } from '../../../../modules/hrd/attendance/gate-passes';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderEmployeeLetters } from '../employee-letter';
import { renderEmployeeLoanAdvance } from '../employee-loan-advance/loan-advance-tab';
import { renderHistoryTab } from './history-tab';
import { renderEmployeeProfile } from './profile-tab';

// eslint-disable-next-line
export function renderEmployeeV2Profile({
  useEmployeeProfile,
  useEmployeeProfiles,
  useUserAuthorization,
  useFileTransfer,
  usePublicTransfer,
  useEmployeeCategories,
  useEmpShiftSchedules,
  useRotationalShiftDays,
  useGatePass,
  useGatePasses,
  useLeaves,
  useAttendanceMonthlySummary,
  useCompanies,
  useMisconducts,
  useMisconduct,
  useSalaryAdvances,
  useSalaryAdvance,
  useSalaryAdvanceEligibleAmt,
  usePolicy,
  usePolicies,
  usePremiums,
  useLoan,
  useLoans,
  useRepaymentSchedules,
  useSkipRepaymentData,
  useAdvPaymentData,
  useRepaymentSchedule,
  useSalaryParams,
  useEmployeeRegistrations,
  useEmployeeRegistration,
  useDocument,
  useDocuments,
  useAttendances,
  useDepartments,
  useEmployeeEducations,
  useEmployeeEducation,
  useEmployeeFamilies,
  useEmployeeFamily,
  useCurrentUserRoles,
  useEmployeeLetters,
  userRendererService,
  useShiftSchedules,
  leaveService,
}: {
  useEmployeeProfile: UseEmployeeProfile;
  useEmployeeProfiles: UseEmployeeProfiles;
  useUserAuthorization: UseUserAuthorization;
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
  useEmployeeCategories: UseEmployeeCategories;
  useGatePass: UseGatePass;
  useGatePasses: UseGatePasses;
  useCurrentUserRoles: UseCurrentUserRoles;
  useEmpShiftSchedule: UseEmpShiftSchedule;
  useEmpShiftSchedules: UseEmpShiftSchedules;
  useRotationalShiftDays: UseRotationalShiftDays;
  useUsers: UseUsers;
  useLeaves: UseLeaves;
  useAttendanceMonthlySummary: UseAttendanceMonthlySummary;
  useCompanies: UseCompanies;
  useMisconducts: UseMisconducts;
  useMisconduct: UseMisconduct;
  useSalaryAdvances: UseSalaryAdvances;
  useSalaryAdvance: UseSalaryAdvance;
  useSalaryAdvanceEligibleAmt: UseSalaryAdvanceEligibleAmt;
  usePolicies: UsePolicies;
  usePolicy: UsePolicy;
  usePremiums: UsePremiums;
  useLoan: UseLoan;
  useLoans: UseLoans;
  useRepaymentSchedules: UseRepaymentSchedules;
  useSkipRepaymentData: UseSkipRepaymentData;
  useAdvPaymentData: UseAdvPaymentData;
  useRepaymentSchedule: UseRepaymentSchedule;
  useSalaryParams: UseSalaryParams;
  useEmployeeRegistrations: UseEmployeeRegistrations;
  useEmployeeRegistration: UseEmployeeRegistration;
  useEmployeeEducations: UseEmployeeEducations;
  useEmployeeEducation: UseEmployeeEducation;
  useEmployeeFamilies: UseEmployeeFamilies;
  useEmployeeFamily: UseEmployeeFamily;
  useDocument: UseDocument;
  useDocuments: UseDocuments;
  useAttendances: UseAttendances;
  useDepartments: UseDepartments;
  useEmployeeLetters: UseEmployeeLetters;
  userRendererService: UserRendererInterface;
  useShiftSchedules: UseShiftSchedules;
  leaveService: LeaveInterface;
}): () => JSX.Element {
  const Card = renderCardComponent();

  return function EmployeeV2Profile(): JSX.Element {
    const { id } = useParams();
    const [isHr, setIsHr] = useState<boolean>(false);

    const {
      data: shiftDays,
      getAll: getRotationalShiftDays,
    } = useRotationalShiftDays();

    const [actions, setActions] = useState<{
      [key: string]: {
        action: boolean;
        locations?: string[] | undefined;
      };
    }>({});

    const NewTabs = renderTabsV2Component();
    const { data: currentUserRoles } = useCurrentUserRoles();
    const {
      data: employeeProfile,
      get: getProfile,
      syncSet: setEmployeeSync,
      loading,
      set: setEmployee,
    } = useEmployeeProfile();
    // const {
    //   data: employeeShiftSchedule,
    //   getAll: getEmployeeShiftSchedule,
    // } = useEmpShiftSchedules();
    // const [companyGroupSetting, setCompanyGroupSetting] = useState<
    //   CompanyGroupSetting
    // >();
    const { get: getAllowedActions } = useUserAuthorization();
    const { user: currentUser } = useContext(UserContext);
    // const { getAllSync: getCompanyGroupSettings } = useCompanyGroupSettings();

    // const getCGS = async () => {
    //   const cgs = await getCompanyGroupSettings();
    //   if (cgs.length > 0) setCompanyGroupSetting(cgs[0]);
    // };

    function getSchedule() {
      const shiftDay: RotationalShiftDayType | null = shiftDays?.length
        ? shiftDays[0]
        : null;

      const location = shiftDay
        ? `${shiftDay.details?.workArea?.name} - ${employeeProfile.details?.workLocation}`
        : employeeProfile.details?.workLocation;
      const shift = shiftDay
        ? shiftDay.details?.shiftId?.name ||
          employeeProfile?.details?.jobProfile?.shiftType
        : employeeProfile?.details?.jobProfile?.shiftType;
      const shiftStart =
        (employeeProfile.details?.jobProfile?.shiftType === 'General'
          ? '09:00:00'
          : employeeProfile?.details?.jobProfile?.startTime) || 'unknown-time';
      const shiftEnd =
        (employeeProfile.details?.jobProfile?.shiftType === 'General'
          ? '18:00:00'
          : employeeProfile?.details?.jobProfile?.endTime) || 'unknown-time';

      const shiftTiming = shiftDay ? '' : ` from ${shiftStart} to ${shiftEnd}`;

      return (
        <span className='text-gray-700'>
          Working at {location || 'unknown-location'}
          {' in '} {shift} shift
          {shiftTiming}
        </span>
      );
    }
    useEffect(() => {
      if (id) {
        getProfile(id);
        // getCGS();
        setActions(getAllowedActions().actions);
      }
    }, [id]);

    useEffect(() => {
      if (currentUserRoles) {
        if (currentUserRoles.find((x) => x.name === 'HR Officer')) {
          setIsHr(true);
        }
      }
    }, [currentUserRoles]);

    useEffect(() => {
      if (!employeeProfile) return;
      // SHift sch to be fetched only for Fixed and Rotational.
      // For general its a company fixed. and flexy is completely independent
      if (
        ['Rotational', 'Fixed'].includes(
          employeeProfile.details?.jobProfile?.shiftType
        )
      ) {
        // getEmployeeShiftSchedule({
        //   employeeId: employeeProfile.id,
        //   date: moment().format('YYYY-MM-DD'),
        // });

        getRotationalShiftDays({
          date: moment().format('YYYY-MM-DD'),
          employeeId: employeeProfile.id,
        });
      }
    }, [employeeProfile]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const header: CardHeader = {
      title: (
        <span>
          {employeeIconMapperByGrade({
            grade: employeeProfile.details?.grade,
            gender:
              employeeProfile.details?.gender === 'Female' ? 'female' : 'male',
          })}{' '}
          {employeeProfile?.name || '??'}
        </span>
      ),
      icon: employeeProfile.details?.photo ? (
        <img
          alt={employeeProfile.name}
          src={employeeProfile.details.photo}
          className='w-24 h-24 self-center'
        />
      ) : (
        <span className='text-gray-800 w-24 h-full text-4xl'>
          {employeeIconMapperByGrade({
            grade: employeeProfile.details?.grade,
            gender:
              employeeProfile.details?.gender === 'Female' ? 'female' : 'male',
            iconSize: 'large',
          })}
        </span>
      ),
      subheading: (
        <>
          <div className='text-gray-700'>
            Employee #: {employeeProfile.details?.employeeId || '??'}
            &nbsp;&nbsp;|&nbsp;&nbsp; Designation:{' '}
            {employeeProfile.details?.designation || '??'}
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified at{' '}
            {moment.utc(employeeProfile.lastModifiedAt).fromNow()}
          </div>
          <div className='text-gray-700 italic'>
            {ShiftIcon(employeeProfile.details?.jobProfile?.shiftType)}{' '}
            {employeeProfile.details?.jobProfile?.shiftType}: {getSchedule()}
            {/* {employeeShiftSchedule?.[0] ? (
              <span className='text-gray-700'>
                Working at {employeeShiftSchedule?.[0]?.sisterLocation}
                {' in '} {employeeShiftSchedule?.[0]?.schedule.name}
                {' from '}{' '}
                {moment(
                  employeeShiftSchedule?.[0]?.schedule?.startTime,
                  'HH:mm:ss'
                ).format('hh:mm A')}
                {' to '}{' '}
                {moment(
                  employeeShiftSchedule?.[0]?.schedule?.endTime,
                  'HH:mm:ss'
                ).format('hh:mm A')}
              </span>
            ) : (
              <span className='text-gray-700'> No work schedule found.</span>
            )} */}
          </div>
        </>
      ),
    };

    const Profile = renderEmployeeProfile({
      useShiftSchedules,
      userRendererService,
      useFileTransfer,
      usePublicTransfer,
      useUserAuthorization,
      useEmployeeCategories,
      useCompanies,
      useSalaryParams,
      useEmployeeRegistrations,
      useEmployeeRegistration,
      useDocument,
      useDocuments,
      id,
      useDepartments,
      useEmployeeEducations,
      useEmployeeEducation,
      useEmployeeFamilies,
      useEmployeeFamily,
      isHr,
      actions,
    });

    // const Letter = renderEmployeeLetter({
    //   employee: employeeProfile,
    //   setEmployee,
    //   useFileTransfer,
    //   useCurrentCompanyGroup,
    //   useCurrentCompany,
    // });

    const Letter = renderEmployeeLetters({
      useEmployeeLetters,
      useFileTransfer,
    });

    const JobProfile = renderEmployeeJobProfile();

    const Authoriser = renderEmployeeAuthorisers({ useEmployeeProfiles });

    const Leave = renderEmployeeLeave({
      useEmployees: useEmployeeProfiles,
      useLeaves,
      useFileTransfer,
      id,
      userRendererService,
      leaveService,
    });

    const Gatepass = renderMonthGatePasses({
      queryEmpId: id,
      mode: 'employee',
      useEmployee: useEmployeeProfile,
      useGatePass,
      useGatePasses,
      useEmployees: useEmployeeProfiles,
      useEmployeeProfiles,
      useUserAuthorization,
      useCurrentUserRoles,
      userRendererService,
    });

    const Attendance = renderEmployeeAttendance({
      useAttendanceMonthlySummary,
      useAttendances,
    });

    const LoanAdvance = renderEmployeeLoanAdvance({
      data: employeeProfile,
      useSalaryAdvances,
      useSalaryAdvance,
      useSalaryAdvanceEligibleAmt,
      usePolicy,
      usePolicies,
      usePremiums,
      useFileTransfer,
      setEmployee: setEmployee,
      useLoan,
      useLoans,
      useRepaymentSchedules,
      useSkipRepaymentData,
      useAdvPaymentData,
      useRepaymentSchedule,
      userRendererService,
    });

    const History = renderHistoryTab({ id, useEmployeeProfile });
    const DisciplinaryTab = renderEmployeeDisciplinary({
      employee: employeeProfile,
      // setEmployee,
      // id,
      // useCurrentUser,
      useMisconducts,
      useFileTransfer,
      useMisconduct,
      useEmployeeProfiles,
      userRendererService,
    });

    const newCategories: DataArray = [
      {
        name: 'profile',
        Fn: () => (
          <Profile
            data={employeeProfile}
            loading={loading}
            setEmployee={setEmployee}
          />
        ),
      },
      {
        name: 'job-profile',
        Fn: () => (
          <JobProfile
            data={employeeProfile}
            setEmployee={setEmployeeSync}
            actions={actions}
            isHr={isHr}
          />
        ),
      },
      // {
      //   name: 'letters',
      //   Fn: Letter,
      // },

      {
        name: 'leave',
        Fn: Leave,
      },
      {
        name: 'Gatepass',
        Fn: Gatepass,
      },
    ];

    const allowedActions = getAllowedActions();

    if (allowedActions.actions['HRD:EMP-PROFILE:EDIT']?.action) {
      newCategories.push({
        name: 'attendance',
        Fn: Attendance,
      });
      newCategories.push({
        name: 'disciplinary-actions',
        Fn: DisciplinaryTab,
      });
      newCategories.push({ name: 'history', Fn: History });
    }

    if (isHr || currentUser?.id === employeeProfile.id) {
      newCategories.push({
        name: 'letters',
        Fn: () => <Letter id={employeeProfile.id} />,
      });
    }

    if (
      allowedActions.actions['UI:LON-ADV:VIEW']?.action ||
      currentUser?.id === employeeProfile.id
    ) {
      newCategories.push({
        name: 'loan-advance',
        Fn: () => <LoanAdvance actions={actions} isHr={isHr} />,
      });
    }

    if (
      allowedActions.actions['UI:EMP-AUTHZ:VIEW']?.action ||
      currentUser?.id === employeeProfile.id
    ) {
      newCategories.push({
        name: 'authorisers',
        Fn: () => (
          <Authoriser
            id={id}
            isHr={isHr}
            loading={loading}
            setEmployee={setEmployeeSync}
            employee={employeeProfile}
          />
        ),
      });
    }

    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={false} />,
    };

    return <Card header={header} body={body} />;
  };
}
