import { Revision } from '@erp_core/erp-types/dist/modules/inventory';
import { LoadingButton } from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UserContext } from '../../../../../contexts/user';
import { UseRevision } from '../../../../../hooks/inventory/revision/use-revision';
import { StockGroupInterface } from '../../../../../models/interfaces/inventory/stock-group';

export function renderUploadStockgroupItems({
  stockGroupService,
  useRevision,
}: {
  stockGroupService: StockGroupInterface;
  useRevision: UseRevision;
}) {
  return function UploadStockgroupItems({
    id,
    onClose,
  }: {
    id: string;
    onClose: () => void;
  }) {
    const [resultReceived, setResultReceived] = useState<boolean>(false);
    const [remarks, setRemarks] = useState<
      Array<{ remark: string; changeRequest?: any }>
    >([]);
    const [selectedRemarks, setSelectedRemarks] = useState<Array<boolean>>([]);
    const [errors, setErrors] = useState<Array<string>>([]);
    const [showCRButton, setShowCRButton] = useState<boolean>(false);
    const { syncSet: setRevision } = useRevision();
    const { user: currentUser } = useContext(UserContext);

    useEffect(() => {
      const itemFound = selectedRemarks.find((x) => x === true);
      if (errors.length === 0 && itemFound && !showCRButton) {
        setShowCRButton(true);
      } else if (!itemFound && showCRButton) {
        setShowCRButton(false);
      } else if (errors.length && showCRButton) {
        setShowCRButton(false);
      }
      // eslint-disable-next-line
    }, [selectedRemarks, remarks]);
    return (
      <div>
        <input
          type='file'
          onChange={(evt) => {
            const file = evt.target.files && evt.target.files[0];

            if (file) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                if (reader.result && typeof reader.result === 'string') {
                  setResultReceived(false);
                  // setSheetData(reader.result);
                  stockGroupService
                    .uploadSheetItemStockgroup(id, reader.result)
                    .then((res) => {
                      console.log(res);
                      setResultReceived(true);
                      if (res.errors) {
                        setErrors(res.errors);
                      }
                      if (res.remarks) {
                        setRemarks(res.remarks);
                        setSelectedRemarks(res.remarks.map(() => false));
                      }
                    });
                }
              };
            }
          }}
        />

        <div>
          {errors.length ? (
            <div>
              Errors:
              {errors.map((x, idx) => (
                <div key={idx}>{x}</div>
              ))}
            </div>
          ) : null}
          <div>
            <div className='flex'>
              <div className='flex-auto font-semibold'>Remarks</div>
              <div className='flex-none font-semibold'>Select</div>
            </div>
            {resultReceived && remarks.length === 0
              ? 'Seems like there are no changes detected in uploaded file'
              : null}
            {remarks.map((x, idx) => (
              <div key={idx} className='flex'>
                <div className='flex-auto'>{x.remark}</div>
                <div className='flex-none'>
                  {x.changeRequest ? (
                    <input
                      checked={selectedRemarks[idx]}
                      onChange={(e) => {
                        const newList = [...selectedRemarks];
                        newList[idx] = e.target.checked;
                        setSelectedRemarks(newList);
                      }}
                      id={`${idx}`}
                      type='checkbox'
                    />
                  ) : null}
                </div>
              </div>
            ))}
            {showCRButton ? (
              <div className='mx-auto'>
                <LoadingButton
                  text='Create Bulk Revisions'
                  behaviourParams={{}}
                  behaviorFn={async () => {
                    const reason = `Bulk change request ${moment().format(
                      'YYYY-MM-DD'
                    )}`;
                    for (let i = 0; i < remarks.length; i++) {
                      if (selectedRemarks[i]) {
                        console.log('raising', remarks[i].changeRequest);
                        const cr = remarks[i].changeRequest;
                        const finalRevRequest = {
                          resource: cr.resource,
                          name: cr.name,
                          reason: reason,
                          type: cr.type,
                          isLatest: cr.isLatest,
                          status: cr.status,
                          requestedBy: {
                            id: currentUser.id,
                            name: currentUser.name,
                          },
                          operation: 'value-updated',
                          changeRequest: cr.changeRequest,
                          details: cr.details,
                        } as Revision;

                        console.log('finalData', finalRevRequest);
                        await setRevision(finalRevRequest);
                        toast('Change request submitted successfully');
                      }
                    }
                    onClose();
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };
}
