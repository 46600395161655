import { useNavigate, useParams } from 'react-router-dom';
import { UseQuestionnaireQuestions } from '../../../../hooks/hrd/questionnaire/questionnairequestion/usequestionnairequestions';
import { UseQuestionnaireSurveyAnswer } from '../../../../hooks/hrd/questionnaire/questionnairesurveyans/usequestionnairesurveyanswer';
import { SurveyAnswersType } from '../../../../models/types/questionnairetopic/survey-answers';
import { renderSurveyAnswerForm, SurveyAnswerType } from './form';

export function renderSurveyAnswersPage({
  useQuestionnaireQuestions,
  useQuestionnaireSurveyAnswer,
}: {
  useQuestionnaireQuestions: UseQuestionnaireQuestions;
  useQuestionnaireSurveyAnswer: UseQuestionnaireSurveyAnswer;
}): () => JSX.Element {
  const SurveyAnswersForm = renderSurveyAnswerForm({
    useQuestionnaireQuestions,
  });
  return function SurveyAnswersPage(): JSX.Element {
    const { id, date } = useParams();
    const navigate = useNavigate();
    const { syncSet: saveSurveyAnswer } = useQuestionnaireSurveyAnswer();

    async function onSave(req: {
      topic: { id: string };
      details: { surveyAns: SurveyAnswerType };
    }) {
      const data = req;
      if (date) {
        (data.details as any).date = date;
      }
      await saveSurveyAnswer(data as SurveyAnswersType);
      navigate('/miscellaneous/questionnaire/survey-successful');
    }

    if (id) {
      return <SurveyAnswersForm id={id} onSave={onSave} date={date} />;
    }

    return <div></div>;
  };
}
