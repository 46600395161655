import {
  ConfiguratorFilterType,
  ConfiguratorType,
} from '@erp_core/erp-types/dist/modules/notification';
import { NotificationRepo } from '../datalayer/repository/http/notification';
import { NotificationConfiguratorHttpRepo } from '../datalayer/repository/http/notification/configurator';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { NotificationConfiguratorInterface } from '../models/interfaces/notification/configurator';
import { erpV2Api } from './admin';
import { NOTIFICATON_SERVER_URI } from './server-uri';

export const notificationService = new NotificationRepo(NOTIFICATON_SERVER_URI);

// Notification Configurator Service
export const notificationConfiguratorService = new NotificationConfiguratorHttpRepo(
  NOTIFICATON_SERVER_URI,
  erpV2Api
);
export const useNotificationConfigurator = createUseResource<
  ConfiguratorType,
  ConfiguratorFilterType,
  NotificationConfiguratorInterface
>({ useService: () => notificationConfiguratorService });
export const useNotificationConfigurators = createUseResources<
  ConfiguratorType,
  ConfiguratorFilterType,
  NotificationConfiguratorInterface
>({ useService: () => notificationConfiguratorService });
