/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { PurchaseOrder } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
// import { useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import { UseTemplate } from '../../../../../../hooks/admin/communication-template/use-template';
import { UseUsers } from '../../../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../../../hooks/file-transfer/use-file-transfer';
import { UseQuestionnaireQuestions } from '../../../../../../hooks/hrd/questionnaire/questionnairequestion/usequestionnairequestions';
import { UsePurchaseOrderFulfilment } from '../../../../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilment';
import { UsePurchaseOrderFulfilments } from '../../../../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilments';
import { UsePurchaseOrder } from '../../../../../../hooks/inventory/purchase/purchase-order/use-purchase-order';
import { ItemInterface } from '../../../../../../models/interfaces/inventory/item';
import { renderGoodsReceived } from '../../../../../../modules/purchase/goods-received';
import { renderCreateEditPurchaseOrderFulfilmentForm } from './forms/add-fulfilment';

export const renderPurchaseOrderFulfilmentDetails = ({
  usePurchaseOrderFulfilment,
  usePurchaseOrder,
  usePurchaseOrderFulfilments,
  itemService,
  useFileTransfer,
  useQuestionnaireQuestions,
  useTemplate,
  useUsers,
}: {
  usePurchaseOrderFulfilment: UsePurchaseOrderFulfilment;
  usePurchaseOrderFulfilments: UsePurchaseOrderFulfilments;
  usePurchaseOrder: UsePurchaseOrder;
  itemService: ItemInterface;
  useQuestionnaireQuestions: UseQuestionnaireQuestions;
  useTemplate: UseTemplate;
  useFileTransfer: UseFileTransfer;
  useUsers: UseUsers;
}) => {
  return function PurchaseOrderFulfilmentDetails({
    purchaseOrder,
  }: // cgs,
  {
    purchaseOrder: PurchaseOrder;
    // cgs: CompanyGroupSetting;
  }) {
    // const { id } = useParams();
    // const {
    //   data: fulfillments,
    //   getAll: getFulfillments,
    // } = usePurchaseOrderFulfilments();

    // useEffect(() => {
    //   getFulfillments({ purchaseOrderId: id });
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Purchase Orders Fulfilments (GRN)',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: `Add PO fulfilment (GRN) for ${purchaseOrder.grade?.name}`,
              content: ({ onClose }) => {
                const Form = renderCreateEditPurchaseOrderFulfilmentForm({
                  usePurchaseOrderFulfilments,
                  usePurchaseOrderFulfilment,
                });
                return <Form purchaseOrder={purchaseOrder} onClose={onClose} />;
              },
            },
          },
        },
      ],
    };

    const GoodsReceived = renderGoodsReceived({
      usePurchaseOrderFulfilment,
      usePurchaseOrderFulfilments,
      itemService,
      useQuestionnaireQuestions,
      useTemplate,
      useFileTransfer,
      useUsers,
    });

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <GoodsReceived selectedPO={purchaseOrder} />
        </>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
};
