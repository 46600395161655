import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  FormDataType,
  FormProps,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { PencilIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseMetrics } from '../../../../hooks/admin/constants/metrics/use-metrics';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseItems } from '../../../../hooks/inventory/item/use-items';
import { UseClient } from '../../../../hooks/order/sales/client/use-client';
import { UseClients } from '../../../../hooks/order/sales/client/use-clients';
import { UseLeadCustomer } from '../../../../hooks/order/sales/lead-customer/use-lead-customer';
import { UseLeadCustomers } from '../../../../hooks/order/sales/lead-customer/use-lead-customers';
// import { LeadCustomerEnquiryType } from '../../../../models/types/order/sales/lead-customer-enquiry';
import { CategoryIcons } from '../../../inventory/items/item-categories';

export type SalesEnquiryFields = {
  date: string;
  enquiryMode: string;
  enquiryRefNo: string;
  assignedTo: IdName;
  type: string;
  stage: string;
  customer: IdName;
  contact: string;
  deliveryLocation: string;
  products?: any;
  description: string;
};

type Props = { onSave: any; enqdata?: any };

export function renderAddSalesEnquiryV2Form({
  useItems,
  useEmployeeProfiles,
  useLeadCustomers,
  useLeadCustomer,
  useClient,
  useClients,
  useMetrics,
}: {
  useItems: UseItems;
  useEmployeeProfiles: UseEmployeeProfiles;
  useLeadCustomer: UseLeadCustomer;
  useLeadCustomers: UseLeadCustomers;
  useMetrics: UseMetrics;
  useClient: UseClient;
  useClients: UseClients;
}): (props: Props) => JSX.Element {
  return function AddSalesEnquiryV2Form({
    onSave,
    enqdata,
  }: Props): JSX.Element {
    const initialOptions = [{ value: '', text: 'Select' }];
    const incomingCustomer = enqdata?.leadCustomer || undefined;

    const [customer, setCustomer] = useState<IdName | undefined>(
      incomingCustomer
    );
    // const { get: getLeadCustomer, data: leadCustomerData } = useLeadCustomer();
    const { get: getClient, data: clientData } = useClient();

    useEffect(() => {
      if (customer?.id) {
        // getLeadCustomer(customer.id);
        getClient(customer.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    const Form1 = renderFormV2({
      fieldsData: [
        {
          property: 'customer',
          type: 'searchable-select',
          label: 'Customer',
          required: true,
          searchOptions: {
            useSearch: useClients,
            onSearchValueSelect: (e) => {},
          },
        },
      ],
      initialFormState: {
        customer: { id: '', name: '' },
      },
      mapTToU: (p: any) => p,
      onSubmit: async (formData) => {
        if (formData.customer.id) {
          setCustomer({
            id: formData.customer.id,
            name: formData.customer.name,
          });
        }
      },
      style: 'w-1/2',
    });

    const formProps: FormProps<SalesEnquiryFields> = {
      formName: `Customer Name: ${customer?.name}`,
      fieldsData: [
        {
          property: 'date',
          type: 'date',
          label: 'Date',
          required: true,
        },
        {
          property: 'enquiryMode',
          type: 'select',
          required: true,
          label: 'Mode of Enquiry',
          options: [
            { text: 'Select', value: '' },
            { text: 'Verbal', value: 'verbal' },
            { text: 'Email', value: 'email' },
            { text: 'Phone Call', value: 'phone-call' },
            { text: 'Message', value: 'Message' },
            { text: 'Whatsapp', value: 'Whatsapp' },
            { text: 'Notes', value: 'notes' },
          ],
        },
        {
          property: 'enquiryRefNo',
          type: 'input',
          label: 'Enquiry Reference No.',
          required: true,
        },
        {
          property: 'assignedTo',
          type: 'searchable-select',
          label: 'Assigned To',
          required: true,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            onSearchValueSelect: () => {},
            //Note: Here filter to be on Sales & Marketing, Business Dev.
          },
        },
        {
          property: 'type',
          type: 'select',
          required: true,
          label: 'Type',
          options: [
            { text: 'Select', value: '' },
            { text: 'Existing Business', value: 'Existing Business' },
            { text: 'New Business', value: 'New Business' },
          ],
        },
        {
          property: 'stage',
          type: 'select',
          label: 'Stage',
          required: true,
          readonly: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'Lead Generated', value: 'Lead Generated' },
            { text: 'Contacted', value: 'Contacted' },
            { text: 'Meeting Scheduled', value: 'Meeting Scheduled' },
            { text: 'Sample Sent', value: 'Sample Sent' },
            { text: 'Qualified to Buy', value: 'Qualified to Buy' },
            {
              text: 'Enquiry-Draft',
              value: 'Enquiry-Draft',
            },
            {
              text: 'Enquiry',
              value: 'Enquiry',
            },
            { text: 'Disqualified', value: 'Disqualified' },
            { text: 'Closed Lost', value: 'Closed Lost' },
          ],
        },
        // {
        //   property: 'customer',
        //   type: 'searchable-select',
        //   label: 'Customer',
        //   // required: true,
        //   searchOptions: {
        //     useSearch: useLeadCustomers,
        //     onSearchValueSelect: (e) => {
        //       console.log('onSearchValue Select field ',e)
        //       // setCustomerId(e?.id);
        //     },
        //   },
        // },
        {
          property: 'contact',
          type: 'select',
          label: 'Contact',
          required: true,
          options: [
            ...initialOptions,
            ...(enqdata
              ? incomingCustomer?.details?.contact?.map((m) => ({
                  value: m.name,
                  text: m.name,
                })) || []
              : clientData?.details?.contact?.map((m) => ({
                  value: m.name,
                  text: m.name,
                })) || []),
          ],
        },
        {
          property: 'deliveryLocation',
          label: 'Delivery Location',
          type: 'select',
          required: true,
          options: [
            ...initialOptions,
            ...(enqdata
              ? incomingCustomer?.details?.locations?.map((m) => ({
                  value: m.name,
                  text: m.name,
                })) || []
              : clientData?.details?.locations?.map((m) => ({
                  value: m.name,
                  text: m.name,
                })) || []),
          ],
        },
        {
          property: 'description',
          type: 'input',
          label: 'Description',
          required: true,
        },
        // {
        //   property: 'products',
        //   label: 'Products',
        //   type: 'jsx',
        //   groupName:'Products',
        //   renderObject({ formData, field, onSave }) {
        //     return(
        //       <div className='p-1 border border-gray-200 rounded-sm'>
        //         <ProductsFormRender
        //           formData={formData}
        //           field={field.property}
        //           onSave={onSave}
        //           useMetrics={useMetrics}
        //           useItems={useItems}
        //         />
        //       </div>
        //     )
        //   },
        //   // type: 'searchable-select',
        //   // required: true,
        //   // searchOptions: {
        //   //   useSearch: useItems,
        //   //   onSearchValueSelect: () => { },
        //   //   searchOptionsBody: {
        //   //     customBody(data) {
        //   //       return (
        //   //         <div>
        //   //           {CategoryIcons[data?.category?.shortName] || null}
        //   //           {data?.name}
        //   //         </div>
        //   //       );
        //   //     },
        //   //   },
        //   //   // Note: Further going it should
        //   //   // accept more values of further
        //   //   // eg. FA/FG, FG/WIP (short-form which has FG in it)
        //   //   filter: { category: 'FG' },
        //   // },
        // }
      ],
      initialFormState: {
        date: enqdata?.details?.date || moment().format('YYYY-MM-DD') || '',
        assignedTo: enqdata?.assignedTo || { id: '', name: '' },
        type: enqdata?.details?.type || '',
        stage: 'Enquiry-Draft' || '',
        customer: { id: customer?.id || '', name: customer?.name || '' } ||
          enqdata?.leadCustomer || { id: '', name: '' },
        contact: incomingCustomer?.details?.contact[0]?.name || '',
        // products: {} as any,
        description: enqdata?.details?.description || '',
        enquiryMode: enqdata?.details?.enquiryMode || '',
        enquiryRefNo: enqdata?.details?.enquiryReferenceNum || '',
        deliveryLocation: enqdata?.details?.deliveryLocation || '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      // style: 'w-1/2',
      allowUnchanged: true,
    };
    const NewForm = renderFormV2<SalesEnquiryFields>(formProps);

    if (!customer) {
      return <Form1 />;
    }

    return <NewForm />;

    function mapPFDToP(
      b: FormDataType<SalesEnquiryFields>
    ): SalesEnquiryFields {
      return (b as unknown) as SalesEnquiryFields;
    }
  };
}

type AddProductProps = {
  saveProduct: (s: any) => void;
  avoidSubmitBehavior?: boolean;
};

// Add Product Form
export function renderAddProductForm({
  useMetrics,
  useItems,
}: {
  useMetrics: UseMetrics;
  useItems: UseItems;
}): (p: AddProductProps) => JSX.Element {
  return function AddProductsForm({
    saveProduct,
    avoidSubmitBehavior,
  }: AddProductProps): JSX.Element {
    const onSave = async (data) => {
      saveProduct(data);
      toast.success('Saved product!');
    };

    const Form = renderFormV2({
      button: {
        avoidSubmitBehavior,
      },
      fieldsData: [
        {
          property: 'product',
          label: 'Product(Grade)',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useItems,
            onSearchValueSelect: () => {},
            searchOptionsBody: {
              customBody(data) {
                return (
                  <div>
                    {data.categories?.map((x) => (
                      <CategoryIcons category={x.category} key={x.id} />
                    ))}
                    {data?.name}
                  </div>
                );
              },
            },
            // Note: Further going it should
            // accept more values of further
            // eg. FA/FG, FG/WIP (short-form which has FG in it)
            filter: { category: 'FG' },
          },
        },
        {
          property: 'endUse',
          type: 'input',
          label: 'End Use',
          required: true,
        },
        {
          property: 'totalQuantity',
          type: 'number',
          label: 'Total Quantity',
          required: true,
        },
        {
          property: 'totalQuantityUom',
          required: true,
          label: 'Unit of Measurement', //From item purUom
          type: 'searchable-select',
          searchOptions: { useSearch: useMetrics, onSearchValueSelect(u) {} },
        },
        {
          property: 'productInfo',
          type: 'jsx',
          renderObject: ({ formData, field, onSave }) => {
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                <ProductsInfoRender
                  formData={formData}
                  field={field.property}
                  onSave={onSave}
                  useMetrics={useMetrics}
                />
              </div>
            );
          },
        },
      ],
      onSubmit: onSave,
      initialFormState: {
        product: { id: '', name: '' },
        endUse: '',
        totalQuantity: '',
        totalQuantityUom: { id: '', name: '' },
      },
      mapTToU: (r) => {
        return r;
      },
    });
    return <Form />;
  };
}

type AddProductInfoProps = {
  mode: 'add' | 'edit';
  initialValues: any;
  saveProductInfo: (s: any) => void;
  avoidSubmitBehavior?: boolean;
};

// Products Information Form
export function renderAddProductsInfoForm({
  useMetrics,
}: {
  useMetrics: UseMetrics;
}): (p: AddProductInfoProps) => JSX.Element {
  return function AddProductsForm({
    mode,
    initialValues,
    saveProductInfo,
    avoidSubmitBehavior,
  }: AddProductInfoProps): JSX.Element {
    const onSave = async (data) => {
      saveProductInfo(data);
      toast.success('Added product details!');
    };

    const Form = renderFormV2({
      button: {
        avoidSubmitBehavior,
      },
      fieldsData: [
        {
          property: 'quantity',
          type: 'number',
          required: true,
          label: 'Quantity',
          readonly: mode === 'edit',
        },
        {
          property: 'uom',
          required: true,
          label: 'Unit of Measurement', //From item purUom
          type: 'searchable-select',
          searchOptions: { useSearch: useMetrics, onSearchValueSelect(u) {} },
        },
        {
          property: 'deliveryDate',
          type: 'date',
          required: true,
          label: 'Delivery Date',
        },
      ],
      onSubmit: onSave,
      initialFormState: {
        quantity: initialValues.quantity || '',
        uom: {
          id: initialValues.uom?.id || '',
          name: initialValues.uom?.name || '',
        },
        deliveryDate: initialValues.deliveryDate || '',
      },
      mapTToU: (r) => {
        return r;
      },
      style: 'w-1/2',
    });
    return <Form />;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ProductsFormRender({
  formData,
  field,
  onSave,
  useMetrics,
  useItems,
}): JSX.Element {
  const [props, setProps] = useState<any>(formData[field] || {});
  // const [selectedProp, setSelectedProp] = useState<any | null>(null);
  const Table = renderTableComponent();
  const AddProduct = renderAddProductForm({ useMetrics, useItems });

  return (
    <div>
      <div className='flex row-reverse'>
        <span
          className='bg-green-500 p-1 ronded-sm float-right cursor-pointer'
          onClick={() => {
            onSave(props);
            toast.success('Saved product!');
          }}
        >
          Save
        </span>
      </div>

      <Table
        header={[
          [
            { name: 'Product' },
            { name: 'End use' },
            { name: 'Total Quantity' },
            { name: 'Total Quantity UOM' }, //From Item PurUOM
            // {
            //
            //
            //   name: '',
            // },
          ],
        ]}
        body={_.keys(props).map((x) => {
          return {
            cells: [
              { value: props[x].product?.name },
              { value: props[x].endUse },
              { value: props[x].totalQuantity },
              { value: props[x].totalQuantityUom?.name },
              // {
              //   value: (
              //     <PencilIcon
              //       className='w-5 h-5 inline px-1'
              //       onClick={() => {
              //         setSelectedProp(props[x]);
              //       }}
              //     />
              //   ),
              // },
            ],
          };
        })}
      />

      {/* {selectedProp ? (
        <AddProduct
          mode={'edit'}
          avoidSubmitBehavior={true}
          initialValues={selectedProp}
          saveProduct={(x) => {
            setProps((p) => ({ ...p, ...{ [x.product]: x } }));
            setSelectedProp(null);
          }}
        />
      ) : ( */}
      <AddProduct
        // mode={'add'}
        // initialValues={{}}
        avoidSubmitBehavior={true}
        saveProduct={(x) => {
          if (x.product?.name) {
            // console.log(x);
            setProps((p) => ({ ...p, ...{ [x.product.name]: x } }));
          }
        }}
      />
      {/* )} */}
    </div>
  );
}

function ProductsInfoRender({
  formData,
  field,
  onSave,
  useMetrics,
}): JSX.Element {
  const [props, setProps] = useState<any>(formData[field] || {});
  const [selectedProp, setSelectedProp] = useState<any | null>(null);
  const AddProductInfo = renderAddProductsInfoForm({ useMetrics });
  const Table = renderTableComponent();

  return (
    <div>
      <div className='flex row-reverse'>
        <span
          className='bg-green-500 p-1 ronded-sm float-right cursor-pointer'
          onClick={() => {
            onSave(props);
            toast.success('Saved product details!');
          }}
        >
          Save
        </span>
      </div>

      <Table
        header={[
          [
            { name: 'Quantity' },
            { name: 'UOM' },
            { name: 'Delivery Date' },
            {
              name: '',
            },
          ],
        ]}
        body={_.keys(props).map((x) => {
          return {
            cells: [
              { value: props[x].quantity },
              { value: props[x].uom?.name },
              { value: props[x].deliveryDate },
              {
                value: (
                  <PencilIcon
                    className='w-5 h-5 inline px-1'
                    onClick={() => {
                      setSelectedProp(props[x]);
                    }}
                  />
                ),
              },
            ],
          };
        })}
      />

      {selectedProp ? (
        <AddProductInfo
          mode={'edit'}
          avoidSubmitBehavior={true}
          initialValues={selectedProp}
          saveProductInfo={(x) => {
            setProps((p) => ({ ...p, ...{ [x.quantity]: x } }));
            setSelectedProp(null);
          }}
        />
      ) : (
        <AddProductInfo
          mode={'add'}
          initialValues={{}}
          avoidSubmitBehavior={true}
          saveProductInfo={(x) => {
            if (x.quantity) {
              // console.log(x);
              setProps((p) => ({ ...p, ...{ [x.quantity]: x } }));
            }
          }}
        />
      )}
    </div>
  );
}
