import { ChangeAssesmentHttpRepo } from '../datalayer/repository/http/quality-assurance/change-assesment';
import { ClosureDatePeriodHttpRepo } from '../datalayer/repository/http/quality-assurance/closure-date-period.ts';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { ChangeAssesmentInterface } from '../models/interfaces/quality-assurance/change-assesment/index.js';
import { ClosureDatePeriodInterface } from '../models/interfaces/quality-assurance/closure-date-period/index.js';
import {
  ChangeAssesmentFilterType,
  ChangeAssesmentType,
} from '../models/types/quality-assurance/change-assesment.js';
import {
  ClosureDatePeriodFilterType,
  ClosureDatePeriodType,
} from '../models/types/quality-assurance/closure-date-period.js';
import { erpV2Api } from './admin';
import { QUALITY_ASSURANCE_SERVER_URI } from './server-uri';

export const changeAssesmentService = new ChangeAssesmentHttpRepo(
  QUALITY_ASSURANCE_SERVER_URI,
  erpV2Api
);
export const useChangeAssesment = createUseResource<
  ChangeAssesmentType,
  ChangeAssesmentFilterType,
  ChangeAssesmentInterface
>({
  useService: () => changeAssesmentService,
});

export const useChangeAssesments = createUseResources<
  ChangeAssesmentType,
  ChangeAssesmentFilterType,
  ChangeAssesmentInterface
>({
  useService: () => changeAssesmentService,
});

export const closureDatePeriodService = new ClosureDatePeriodHttpRepo(
  QUALITY_ASSURANCE_SERVER_URI,
  erpV2Api
);
export const useClosureDatePeriod = createUseResource<
  ClosureDatePeriodType,
  ClosureDatePeriodFilterType,
  ClosureDatePeriodInterface
>({
  useService: () => closureDatePeriodService,
});

export const useClosureDatePeriods = createUseResources<
  ClosureDatePeriodType,
  ClosureDatePeriodFilterType,
  ClosureDatePeriodInterface
>({
  useService: () => closureDatePeriodService,
});
