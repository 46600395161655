import { renderHistory } from '@erp_core/erp-ui-components';
import { UseVendor } from '../../../../../hooks/inventory/purchase/vendors/use-vendor';

export function renderHistoryTab({
  id,
  useVendor,
}: {
  id: string;
  useVendor: UseVendor;
}): () => JSX.Element {
  return function HistoryTab(): JSX.Element {
    const History = renderHistory();

    return <History useResource={useVendor} id={id} />;
  };
}
