import { CompanyGroup } from '@erp_core/erp-types/dist/types/modules/admin/company-group';
import { renderCardComponent, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCompanyGroupAdmin } from '../../../hooks/admin/company-group-admin/use-company-group-admin';

export function renderCompanyGroupAdmin({
  useCompanyGroupAdmin,
}: {
  useCompanyGroupAdmin: UseCompanyGroupAdmin;
}) {
  const Card = renderCardComponent();
  return function CompanyGroupAdmin() {
    const { syncSet: setCompanyGroup } = useCompanyGroupAdmin();
    return (
      <div>
        <Card
          header={{
            title: 'Company Groups',
            actions: [
              {
                type: 'button',
                button: {
                  name: 'Add',
                  behaviour: 'modal',
                  modal: {
                    title: 'Add Company Group',
                    size: 'large',
                    content: ({ onClose }) => (
                      <AddCompanyGroup
                        setCompanyGroup={setCompanyGroup}
                        onClose={onClose}
                      />
                    ),
                  },
                },
              },
            ],
          }}
          body={{ type: 'jsx-component', body: <div></div> }}
        />
      </div>
    );
  };
}

function AddCompanyGroup({
  onClose,
  setCompanyGroup,
}: {
  onClose: () => void;
  setCompanyGroup: (s: CompanyGroup) => Promise<CompanyGroup>;
}) {
  const Form = renderFormV2<{
    companyGroupName: string;
    companyGroupDescription: string;
    companyGroupDomain: string;
    companyGroupShortName: string;
    companyName: string;
    companyShortName: string;
    locationName: string;
    locationShortName: string;
    systemAdminName: string;
    systemAdminEmail: string;
  }>({
    style: 'w-1/2',
    mapTToU: (p) => p as any,
    initialFormState: {
      companyGroupName: '',
      companyGroupDescription: '',
      companyGroupShortName: '',
      companyGroupDomain: '',
      companyName: '',
      companyShortName: '',
      locationName: '',
      locationShortName: '',
      systemAdminName: '',
      systemAdminEmail: '',
    },
    fieldsData: [
      {
        groupName: '1.Company Group',
        property: 'companyGroupName',
        label: 'Name',
        required: true,
        type: 'input',
      },
      {
        groupName: '1.Company Group',
        property: 'companyGroupDescription',
        label: 'Description',
        required: true,
        type: 'input',
      },
      {
        groupName: '1.Company Group',
        property: 'companyGroupDomain',
        label: 'Domain',
        required: true,
        type: 'input',
      },
      {
        groupName: '1.Company Group',
        property: 'companyGroupShortName',
        label: 'Short Name',
        required: true,
        type: 'input',
      },
      {
        groupName: '2.Company',
        property: 'companyName',
        label: 'Company Name',
        required: true,
        type: 'input',
      },
      {
        groupName: '2.Company',
        property: 'companyShortName',
        label: 'Company Short Name',
        required: true,
        type: 'input',
      },
      {
        groupName: '3.Location',
        property: 'locationName',
        label: 'Location Name',
        required: true,
        type: 'input',
      },
      {
        groupName: '3.Location',
        property: 'locationShortName',
        label: 'Location Short Name',
        required: true,
        type: 'input',
      },
      {
        groupName: '4.System Admin',
        property: 'systemAdminName',
        label: 'Name',
        required: true,
        type: 'input',
      },
      {
        groupName: '4.System Admin',
        property: 'systemAdminEmail',
        label: 'Email',
        required: true,
        type: 'email',
      },
    ],
    onSubmit: async (form) => {
      await setCompanyGroup({
        name: form.companyGroupName,
        subDomain: form.companyGroupDomain,
        description: form.companyGroupDescription,
        shortName: form.companyGroupShortName,
        systemAdminName: form.systemAdminName,
        systemAdminEmail: form.systemAdminEmail,
        companyName: form.companyName,
        companyShortName: form.companyShortName,
        locationName: form.locationName,
        locationShortName: form.locationShortName,
      } as any);
      onClose();
    },
  });
  return <Form />;
}
