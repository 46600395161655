import { VouchersType } from '@erp_core/erp-types/dist/modules/accounts';
import { renderCardComponent } from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { UseLedgers } from '../../../../../hooks/accounts/tally/ledger/use-ledgers';
import { UseVoucher } from '../../../../../hooks/accounts/vouchers/use-voucher';
import { UseGodowns } from '../../../../../hooks/inventory/godown-inventory/use-godowns';
import { UseGrades } from '../../../../../hooks/inventory/grade/use-grades';
import { UseItemCategories } from '../../../../../hooks/inventory/item/item-category/use-item-categories';
import { UseVendor } from '../../../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseVendors } from '../../../../../hooks/inventory/purchase/vendors/use-vendors';
import { UseClient } from '../../../../../hooks/order/sales/client/use-client';
import { UseClients } from '../../../../../hooks/order/sales/client/use-clients';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';
import { renderConsumptionVoucher } from './consumption-voucher';
import { renderGodownTransfer } from './godown-transfer';
import { renderPurchaseOrder } from './purchase-order';
import { renderSalesOrder } from './sales-order';

export function CreateVoucher({
  useVoucher,
  useVendors,
  useVendor,
  useClients,
  useClient,
  useItemCategories,
  useGrades,
  useGodowns,
  itemService,
  onClose,
}: {
  useVoucher: UseVoucher;
  useVendor: UseVendor;
  useLedgers: UseLedgers;
  useVendors: UseVendors;
  useClients: UseClients;
  useClient: UseClient;
  useItemCategories: UseItemCategories;
  useGrades: UseGrades;
  useGodowns: UseGodowns;
  itemService: ItemInterface;
  onClose: () => void;
}) {
  const [selectedVoucher, setSelectedVoucher] = useState<string>('');
  const { syncSet: setVoucher } = useVoucher();
  const Card = renderCardComponent();
  async function onSave(s: VouchersType) {
    await setVoucher(s);
    onClose();
  }
  const PurchaseOrderVoucher = renderPurchaseOrder({
    itemService,
    useGrades,
    useVendor,
    useItemCategories,
    useVendors,
  });
  const GodownTransferVoucher = renderGodownTransfer({
    useGodowns,
    useGrades,
    itemService,
  });
  const ConsumptionVoucher = renderConsumptionVoucher({
    useGodowns,
    useGrades,
    itemService,
  });

  const SalesOrderVoucher = renderSalesOrder({
    useClients,
    useClient,
    useItemCategories,
    useGrades,
    itemService,
  });

  return (
    <div>
      <Card
        header={{
          title: 'Create Voucher',
          subheading: (
            <div>
              <select
                value={selectedVoucher}
                onChange={(evt) => setSelectedVoucher(evt.target.value)}
              >
                <option value=''>Select</option>
                <option value='purchase-order'>Purchase Order</option>
                <option value='godown-transfer'>Godown Transfer</option>
                <option value='sales-order'>Sales Order</option>
                <option value='consumption-voucher'>Consumption Voucher</option>
              </select>
            </div>
          ),
        }}
        body={{
          type: 'jsx-component',
          body: (
            <div>
              {selectedVoucher === '' ? (
                <div className='animate-pulse text-center text-3xl'>
                  Select Voucher
                </div>
              ) : null}
              {selectedVoucher === 'purchase-order' ? (
                <PurchaseOrderVoucher onSave={onSave} />
              ) : null}
              {selectedVoucher === 'godown-transfer' ? (
                <GodownTransferVoucher onSave={onSave} />
              ) : null}
              {selectedVoucher === 'sales-order' ? (
                <SalesOrderVoucher onSave={onSave} />
              ) : null}
              {selectedVoucher === 'consumption-voucher' ? (
                <ConsumptionVoucher onSave={onSave} />
              ) : null}
            </div>
          ),
        }}
      />
    </div>
  );
}
