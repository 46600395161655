import { LoanType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseLoans } from '../../../../../../hooks/loans/loan/use-loans';

type RenderAddLoanForm = {
  useLoans: UseLoans;
};

export function EmployeeAddLoanForm({ useLoans }: RenderAddLoanForm) {
  return function AddLoanForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (data: LoanType) => Promise<void>;
    isEdit?: boolean;
    data?: LoanType;
  }): JSX.Element {
    const { data: loans, getAll: getLoans } = useLoans();

    useEffect(() => {}, [loans]);

    useEffect(() => {
      getLoans();
      // eslint-disable-next-line
    }, []);

    const formProps: FormProps<Partial<LoanType>> = {
      fieldsData: [
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
        {
          property: 'amount',
          type: 'number',
          label: 'Amount',
          required: true,
        },
        {
          property: 'tenure',
          type: 'number',
          label: 'Tenure(in months)',
          required: true,
        },
      ],
      initialFormState: {
        employee: '',
        amount: '',
        tenure: '',
        reason: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          amount: l.amount || data?.amount,
          tenure: l.tenure || data?.tenure,
          reason: l.reason || data?.reason,
        } as LoanType);
      },
    };

    const Form = renderFormV2<Partial<LoanType>>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<Partial<LoanType>>): LoanType {
      return (b as unknown) as LoanType;
    }
  };
}
