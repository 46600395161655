import { MisconductType } from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
import {
  CardBody,
  CardHeader,
  MonthSelector,
  renderCardComponent,
  renderFileViewerUploader,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseMisconductRuleBooks } from '../../../../hooks/hrd/disciplinary/misconduct-rule-book/use-misconduct-rule-books';
import { UseMisconduct } from '../../../../hooks/hrd/disciplinary/misconduct/use-misconduct';
import { UseMisconducts } from '../../../../hooks/hrd/disciplinary/misconduct/use-miscounducts';
import { UseRuleRequest } from '../../../../hooks/hrd/disciplinary/rule-request/use-rule-request';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UserRendererInterface } from '../../../common/fragments/user';
import { AcknowledgeFormComponent } from './component/acknowledge';
import { issueCriticalChargeSheetFormComponent } from './component/issueCriticalChargeSheet';
import { issueCriticalShowaCauseFormComponent } from './component/issueCriticalShowCuase';
import { issueMemoFormComponent } from './component/issueMemo';
import { issueShowaCauseFormComponent } from './component/issueShowCause';
import { renderAddMisconductForm } from './form/misconduct-addform';
import { ShowMisconductDoc } from './utility/misconduct-upload-fun';

export type RenderMisconductProps = {
  useMisconducts: UseMisconducts;
  useMisconduct: UseMisconduct;
  useEmployees: UseEmployeeProfiles;
  useUserAuthorization: UseUserAuthorization;
  useFileTransfer: UseFileTransfer;
  useMisconductRuleBooks: UseMisconductRuleBooks;
  useCurrentUserRoles: UseCurrentUserRoles;
  useRuleRequest: UseRuleRequest;
  userRenderService: UserRendererInterface;
};

export function renderMisconduct({
  useMisconducts,
  useMisconduct,
  useEmployees,
  useUserAuthorization,
  useFileTransfer,
  useMisconductRuleBooks,
  useCurrentUserRoles,
  useRuleRequest,
  userRenderService,
}: RenderMisconductProps): () => JSX.Element {
  return function Misconduct(): JSX.Element {
    const FileViewerUploader = renderFileViewerUploader();
    const { company, companyGroup } = useContext(CurrentContext);

    const { user: currentUser } = useContext(UserContext);

    const { data: currentUserRoles } = useCurrentUserRoles();

    const [date, setDate] = useState(
      localStorage.getItem('disciplinary-month') ||
        moment.utc().format('YYYY-MM')
    );

    const { syncSet: setMisconduct } = useMisconduct();

    const { data: misconducts, getAll: getMisconducts } = useMisconducts();

    const { syncSet: setMisconductStatus } = useMisconduct();

    const { get: getAllowedActions } = useUserAuthorization();

    useEffect(() => {}, [misconducts]);

    useEffect(() => {
      getMisconducts({ date });
      localStorage.setItem('disciplinary-month', date);
      // eslint-disable-next-line
    }, [date]);

    const handleMisconductAdd = async (form: Partial<MisconductType>) => {
      try {
        const finalData = {
          ...form,
          authorizer: {
            id: currentUser.id,
            name: currentUser.name,
          },
          inspector: {
            id: currentUser.id,
            name: currentUser.name,
          },
          status: 'pending',
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          company: {
            id: company.id,
            name: company.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as MisconductType;
        await setMisconduct(finalData);
        toast('Data added sucessfully');
        getMisconducts({ date });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Misconduct',
      subheading: (
        <span className='text-blue-700 font-bold'>
          {moment(date).format('MMM YYYY')}
        </span>
      ),
      actions: [
        {
          type: 'jsx',
          jsx: (
            <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
              <span className='font-bold truncate'>Select month</span>
              <MonthSelector
                format='YYYY-MM'
                initialState={date}
                onChange={(m) => setDate(m)}
              />
            </div>
          ),
        },
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Misconduct',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await handleMisconductAdd(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddMisconductForm({ useUsers: useEmployees });

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Employee' },
        { name: 'Date' },
        { name: 'Category' },
        { name: 'Reason' },
        { name: 'Amount' },
        { name: 'Status' },
        { name: 'Document' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    const AcknowledgeForm = AcknowledgeFormComponent();

    const IssueShowCauseForm = issueShowaCauseFormComponent();

    const IssueMemoForm = issueMemoFormComponent();

    const IssueCriticalShowCauseForm = issueCriticalShowaCauseFormComponent();

    const IssueCriticalChargesheetForm = issueCriticalChargeSheetFormComponent();

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'Acknowledge',
          auth: 'UI:ACK-MIS:VIEW',
          show: ({ misconduct }: { misconduct: MisconductType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (['unknown'].includes(misconduct.category)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Acknowledge',
            size: 'large',
            content: ({
              data: { misconduct },
              onClose,
            }: {
              data: {
                misconduct: MisconductType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <AcknowledgeForm
                    userRendererService={userRenderService}
                    data={misconduct}
                    onSave={async (form) => {
                      await approveRequest(form);
                      onClose();
                    }}
                    useMisconductRuleBooks={useMisconductRuleBooks}
                    useMisconducts={useMisconducts}
                    employeeId={misconduct.employee.id}
                    minorMisconductCnt={
                      misconducts?.filter(
                        (x) =>
                          x.category === 'minor' &&
                          x.employee.id === misconduct.employee.id
                      ).length || 0
                    }
                    useRuleRequest={useRuleRequest}
                    misconductId={misconduct.id}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Issue Show Cause',
          show: ({ misconduct }: { misconduct: MisconductType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (['major'].includes(misconduct.category)) {
              if (
                !misconduct.details.issueMemo &&
                !misconduct.details.issueShowCause
              ) {
                return true;
              }
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Issue Show Cause',
            content: ({
              data: { misconduct },
              onClose,
            }: {
              data: {
                misconduct: MisconductType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <IssueShowCauseForm
                    data={misconduct}
                    onSave={async (form) => {
                      await issueShowCauseRequest(form);
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Issue Memo',
          show: ({ misconduct }: { misconduct: MisconductType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (['major'].includes(misconduct.category)) {
              if (
                !misconduct.details.issueMemo &&
                !misconduct.details.issueShowCause
              ) {
                return true;
              }
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Issue Memo',
            content: ({
              data: { misconduct },
              onClose,
            }: {
              data: {
                misconduct: MisconductType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <IssueMemoForm
                    data={misconduct}
                    onSave={async (form) => {
                      await issueMemoRequest(form);
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Issue Show Cause',
          show: ({ misconduct }: { misconduct: MisconductType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (['critical'].includes(misconduct.category)) {
              if (
                !misconduct.details.issueCriticalShowCause &&
                !misconduct.details.issueCriticalChargeSheet
              ) {
                return true;
              }
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Issue Show Cause',
            content: ({
              data: { misconduct },
              onClose,
            }: {
              data: {
                misconduct: MisconductType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <IssueCriticalShowCauseForm
                    data={misconduct}
                    onSave={async (form) => {
                      await issueCriticalShowCauseRequest(form);
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Issue Chargesheet',
          show: ({ misconduct }: { misconduct: MisconductType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (['critical'].includes(misconduct.category)) {
              if (
                !misconduct.details.issueCriticalShowCause &&
                !misconduct.details.issueCriticalChargeSheet
              ) {
                return true;
              }
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Issue ChargeSheet',
            content: ({
              data: { misconduct },
              onClose,
            }: {
              data: {
                misconduct: MisconductType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <IssueCriticalChargesheetForm
                    data={misconduct}
                    onSave={async (form) => {
                      await issueCriticalChargesheetRequest(form);
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Attach Proof',
          show: ({ misconduct }: { misconduct: MisconductType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (['pending'].includes(misconduct.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Attach Proof',
            content: ({
              data: { misconduct },
              onClose,
            }: {
              data: {
                misconduct: MisconductType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <FileViewerUploader
                    mode='upload'
                    url={misconduct.details.document || ''}
                    useFileTransfer={useFileTransfer}
                    path={`${companyGroup.id}/${company.id}/misconduct/${misconduct.id}/proofs.pdf`.replaceAll(
                      ' ',
                      '-'
                    )}
                    type='private'
                    onUpload={async (path) => {
                      let misconductdoc = misconduct.details.document || {};
                      if (misconductdoc) {
                        misconductdoc = path.url;
                        await setMisconduct({
                          id: misconduct.id,
                          details: {
                            document: misconductdoc,
                          },
                        } as MisconductType);
                      }
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Employee Response',
          show: ({ misconduct }: { misconduct: MisconductType }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            let eligibleToUndertake = currentUser.id === misconduct.employee.id;
            if (!eligibleToUndertake) {
              if (currentUserRoles?.find((x) => x.name === 'HR Officer')) {
                eligibleToUndertake = true;
              }
            }
            if (eligibleToUndertake) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Employee Response',
            content: ({
              data: { misconduct },
              onClose,
            }: {
              data: {
                misconduct: MisconductType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <FileViewerUploader
                    mode='upload'
                    url={misconduct.details.employeedocument || ''}
                    useFileTransfer={useFileTransfer}
                    path={`${companyGroup.id}/${company.id}/misconduct/${misconduct.id}/response.pdf`.replaceAll(
                      ' ',
                      '-'
                    )}
                    type='private'
                    onUpload={async (path) => {
                      let misconductdoc = misconduct.details || {};
                      if (misconductdoc) {
                        misconductdoc.employeedocument = path.url;
                        await setMisconduct({
                          id: misconduct.id,
                          details: {
                            employeedocument: misconductdoc,
                          },
                        } as MisconductType);
                      }
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
      ];
    }

    const approveRequest = async (data: MisconductType) => {
      try {
        const finalData = {
          ...data,
        };
        await setMisconductStatus(finalData);
        toast('Data updated sucessfully');
        getMisconducts({ date });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const issueShowCauseRequest = async (data: MisconductType) => {
      try {
        const finalData = {
          ...data,
        };
        await setMisconductStatus(finalData);
        toast('Data updated sucessfully');
        getMisconducts({ date });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const issueMemoRequest = async (data: MisconductType) => {
      try {
        const finalData = {
          ...data,
        };
        await setMisconductStatus(finalData);
        toast('Data updated sucessfully');
        getMisconducts({ date });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const issueCriticalShowCauseRequest = async (data: MisconductType) => {
      try {
        const finalData = {
          ...data,
        };
        await setMisconductStatus(finalData);
        toast('Data updated sucessfully');
        getMisconducts({ date });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const issueCriticalChargesheetRequest = async (data: MisconductType) => {
      try {
        const finalData = {
          ...data,
        };
        await setMisconductStatus(finalData);
        toast('Data updated sucessfully');
        getMisconducts({ date });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableData =
      misconducts?.map((r, idx) => ({
        rowData: {
          misconduct: r,
        },
        cells: [
          {
            value: (
              <userRenderService.userCard
                link={true}
                size='small'
                id={r.employee?.id}
                name={r.employee.name}
              />
            ),
          },
          { value: r.date },
          { value: r.category },
          { value: r.reason },
          { value: r.amount },
          { value: r.status },
          {
            value: (
              <ShowMisconductDoc
                r={r}
                useFileTransfer={useFileTransfer}
                useMisconduct={useMisconduct}
              />
            ),
          },
          {
            value: (
              <userRenderService.userCard
                link={true}
                size='small'
                id={r.createdBy?.id}
                name={r.createdBy?.name}
                extraInfo={moment.utc(r.createdAt).fromNow()}
              />
            ),
          },
          {
            value: (
              <userRenderService.userCard
                link={true}
                size='small'
                id={r.lastModifiedBy?.id}
                name={r.lastModifiedBy?.name}
                extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
              />
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={addActions()}
              auth={{ actions: getAllowedActions().actions }}
            />
          </div>
        </div>
      ),
    };
    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
}
