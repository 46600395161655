import { renderHistory } from '@erp_core/erp-ui-components';
import { UseManufacturingVoucher } from '../../../../../../hooks/order/work-order/mf-voucher/use-manufacturing-voucher';

export function renderHistoryTab({
  id,
  useManufacturingVoucher,
}: {
  id: string;
  useManufacturingVoucher: UseManufacturingVoucher;
}): () => JSX.Element {
  return function HistoryTab(): JSX.Element {
    const History = renderHistory();

    return <History useResource={useManufacturingVoucher} id={id} />;
  };
}
