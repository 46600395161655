import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UseDepartments } from '../../../../hooks/hrd/department/use-departments';
import { UseChangeAssesment } from '../../../../hooks/quality-assurance/change-assesment/use-change-assesment';
import { UseChangeAssesments } from '../../../../hooks/quality-assurance/change-assesment/use-change-assesments';
import { ChangeAssesmentType } from '../../../../models/types/quality-assurance/change-assesment';
import { activities } from '../../units/activities';

type RenderAcknowledgeChangeControl = {
  useChangeAssesment: UseChangeAssesment;
  useChangeAssesments: UseChangeAssesments;
  useDepartments: UseDepartments;
};

export type Resp = {
  [key: string]: {
    dept: IdName;
    docDetails: string;
    date: string;
  };
};

export type dpt = {
  [key: string]: {
    dept: string;
  };
};

export const renderActivitiesResponsibilities = ({
  useChangeAssesment,
  useChangeAssesments,
  useDepartments,
}: RenderAcknowledgeChangeControl) => {
  return function ActivitiesResponsibilities({
    id,
    onClose,
  }: {
    id: string;
    onClose: () => any;
  }) {
    const { syncSet: setChangeAssesment } = useChangeAssesment();

    const { user: currentUser } = useContext(UserContext);

    const { company, companyGroup } = useContext(CurrentContext);

    const {
      data: changeAssesments,
      getAll: getChangeAssesments,
    } = useChangeAssesments();

    const [resp, setResp] = useState<Resp>({});

    const [dept, setDepartment] = useState<dpt>({});

    // eslint-disable-next-line
    const { data: departments, getAll: getDepartments } = useDepartments();

    useEffect(() => {
      getDepartments();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      getChangeAssesments();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departments]);

    console.log('departments', departments);

    const depts = departments;

    const changeAssesmentsData = activities;

    useEffect(() => {
      if (changeAssesmentsData?.length) {
        const pl: Resp = {};
        for (let item of changeAssesmentsData) {
          pl[item.name] = {
            dept: { id: '', name: '' },
            docDetails: '',
            date: '',
          };
        }
        console.log('pl', pl);
        setResp(pl);
        const dpt: dpt = {};
        for (let item of changeAssesmentsData) {
          dpt[item.name] = {
            dept: '',
          };
        }
        setDepartment(dpt);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeAssesmentsData]);

    const Table = renderTableComponent();

    function setDept(item: string, propertyName: string, value: string) {
      const dptName = departments?.find((x) => x.id === value)?.name;
      console.log('item', dptName);

      dept[item][propertyName] = dptName;
      setDepartment({
        ...dept,
      });

      console.log('dept', dept);
      return dptName;
    }

    function handleChange(item: string, propertyName: string, value: string) {
      const dptName = departments?.find((x) => x.id === value)?.name;
      // resp[item][propertyName] = { id: value, name: dptName };
      if (propertyName === 'dept') {
        resp[item][propertyName] = { id: value, name: dptName || '' };
      } else {
        resp[item]['date'] = moment().format('DD-MMM-YYYY');
        resp[item][propertyName] = value;
      }
      setResp({
        ...resp,
      });
      console.log('resp', resp);
    }

    async function submitAck() {
      try {
        const itemData = changeAssesments?.find((x) => x.id === id);
        if (itemData) {
          await setChangeAssesment(({
            id: itemData.id,
            details: {
              ...itemData?.details,
              activityResp: resp,
            },
            status: 'activities',
            companyGroup: {
              id: companyGroup.id,
              name: companyGroup.name,
            },
            company: {
              id: company.id,
              name: company.name,
            },
            createdBy: {
              id: currentUser.id,
              name: currentUser.name,
            },
            lastModifiedBy: {
              id: currentUser.id,
              name: currentUser.name,
            },
          } as unknown) as ChangeAssesmentType);

          toast('Data added sucessfully');
          onClose();
          getChangeAssesments();
        }
      } catch (error) {
        toast('Something went wrong');
      }
    }

    const tableHeader: TableHeader = [
      [
        { name: 'List', rowSpan: 2 },
        { name: 'Activity/Document Details', rowSpan: 2 },
        { name: 'Department', rowSpan: 2 },
      ],
    ];

    const tableData =
      activities?.map((item, idx) => ({
        cells: [
          { value: item?.name },
          {
            value: (
              <>
                <input
                  type='text'
                  value={resp[item?.name]?.docDetails || ''}
                  className='border border-black text-left h-50 w-30'
                  onChange={(e) => {
                    handleChange(item?.name, 'docDetails', e.target.value);
                  }}
                ></input>
              </>
            ),
          },
          {
            value: (
              <>
                <select
                  className='border p-2 w-20 border-l border-black text-center'
                  value={resp[item?.name]?.dept.id}
                  onChange={(e) => {
                    setDept(item?.name, 'dept', e.target.value);
                    handleChange(item?.name, 'dept', e.target.value);
                  }}
                >
                  <option value=''>NA</option>
                  {depts
                    ? depts.map((dpt, idx) => {
                        return (
                          <option key={idx} value={dpt.id}>
                            {dpt.name}
                          </option>
                        );
                      })
                    : null}
                </select>
              </>
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Activity to be carried out and documents to be revised',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Submit',
            behaviour: 'click',
            onClick: async () => submitAck(),
          },
        },
      ],
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <Table header={tableHeader} body={tableBody} />
        </>
      ),
    };

    return (
      <>
        <Card header={header} body={body} />
      </>
    );
  };
};
