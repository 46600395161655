import {
  CombinedAttDataType,
  EmployeeProfileType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  MonthSelector,
  renderCardComponent,
  renderSearchBox,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
// import { renderAuthWrapper } from '../../../../components/auth';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseAttendance } from '../../../../hooks/hrd/attendance/use-attendance';
import { UseAttendances } from '../../../../hooks/hrd/attendance/use-attendances';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
// import { calculateShiftDuration } from '../util/calculate-shift-duration';
// import { Chip } from '../util/segregate-punches';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UseGatePass } from '../../../../hooks/hrd/employee/profile/gate-pass/use-gate-pass';
import { UseOvertime } from '../../../../hooks/hrd/employee/profile/overtime/use-overtime';
import { UseOvertimes } from '../../../../hooks/hrd/employee/profile/overtime/use-overtimes';
import { UseEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseLeave } from '../../../../hooks/hrd/leave/use-leave';
import { UseLeaves } from '../../../../hooks/hrd/leave/use-leaves';
import { UseOffSiteWorkHour } from '../../../../hooks/hrd/off-site-work-hours/use-off-site-work-hour';
import { AttendanceInterface } from '../../../../models/interfaces/hrd/attendance';
import { LeaveInterface } from '../../../../models/interfaces/hrd/leave';
import { LogRecordInterface } from '../../../../models/interfaces/planning/log-record';
import { UserRendererInterface } from '../../../../modules/common/fragments/user';
import { renderActions } from '../day-book/components/table/table-actions';
import { renderAttendanceBody } from '../day-book/components/table/table-body';
import { Header } from '../day-book/components/table/table-header';
import { saveNewOvertime } from '../util/add-overtime';
import {
  calculateWeekOffs,
  computePayableDayRatios,
} from '../util/payable-day';
import {
  gatepassCount,
  manualPunchCount,
  offsiteCount,
  otSalaryHours,
} from '../util/segregate-punches';

export function createEmployeeAttendancePage({
  useEmployees,
  useLeaves,
  leaveService,
  useAttendances,
  useCurrentUserRoles,
  useAttendance,
  useUserAuthorization,
  attendanceService,
  userRendererService,
  useEmployeeProfile,
  useGatePass,
  useOffSiteWorkHour,
  useOvertimes,
  useOvertime,
  logRecordService,
  useLeave,
}: {
  useLeaves: UseLeaves;
  useLeave: UseLeave;
  leaveService: LeaveInterface;
  attendanceService: AttendanceInterface;
  useAttendances: UseAttendances;
  useAttendance: UseAttendance;
  useEmployees: UseEmployeeProfiles;
  useUserAuthorization: UseUserAuthorization;
  useCurrentUserRoles: UseCurrentUserRoles;
  userRendererService: UserRendererInterface;
  useEmployeeProfile: UseEmployeeProfile;
  useGatePass: UseGatePass;
  useOffSiteWorkHour: UseOffSiteWorkHour;
  useOvertimes: UseOvertimes;
  useOvertime: UseOvertime;
  logRecordService: LogRecordInterface;
}): () => JSX.Element {
  let Card = renderCardComponent();
  let Table = renderTableComponent();

  return function EmployeeAttendancePage(): JSX.Element {
    const [selectedEmployee, setSelectedEmployee] = useState<
      EmployeeProfileType
    >({} as EmployeeProfileType);
    const [attendances, setAttendances] = useState<CombinedAttDataType[]>([]);
    // const { getAll: getAllAttendances, data: attendances } = useAttendances();
    const { syncSet: setAttendance } = useAttendance();
    const { get: getAllowedActions } = useUserAuthorization();
    // const [employeeAttendances, setEmployeeAttendances] = useState<
    //   Array<AttendanceType>
    // >([]);

    const { data: currentUserRoles } = useCurrentUserRoles();
    // const { Confirm, openConfirm } = useConfirm();
    // const AuthWrapper = renderAuthWrapper({ useUserAuthorization });
    const [loading, setLoading] = useState<boolean>(false);
    const { user: currentUser } = useContext(UserContext);
    const {
      company: currentCompany,
      cgSetting: companyGroupSetting,
    } = useContext(CurrentContext);

    const [isHr, setIsHr] = useState<boolean>(false);
    const [isSystemAdmin, setIsSystemAdmin] = useState<boolean>(false);
    const { getSync: getEmployeeSync } = useEmployeeProfile();
    const { syncSet: setGatePass } = useGatePass();
    const { syncSet: setOffsite } = useOffSiteWorkHour();
    const { syncSet: setOvertime } = useOvertime();
    const { getAllSync: getLeavesSync } = useLeaves();
    const { syncSet: setLeave } = useLeave();

    useEffect(() => {
      if (
        currentUserRoles?.length &&
        currentUserRoles.find((x) => x.name === 'HR Officer')
      ) {
        setIsHr(true);
      }

      setIsSystemAdmin(
        currentUserRoles?.find((x) => x.name === 'System-Admin') ? true : false
      );
    }, [currentUserRoles]);

    const SearchBox = renderSearchBox<EmployeeProfileType>({
      useSearchValues: useEmployees,
      extraFilter: {
        crossGroup: 'true',
      },
      onSearchValueSelect: (u) => {
        setSelectedEmployee(u);
      },
    });

    const [month, setMonth] = useState(
      localStorage.getItem('attendance-month') || moment.utc().format('YYYY-MM')
    );

    useEffect(() => {
      localStorage.setItem('attendance-month', month);
    }, [month]);

    useEffect(() => {
      if (selectedEmployee.id) {
        // getAllAttendances({
        //   employeeId: `equal::${selectedEmployee.id}`,
        //   from: `more-than::${month}-01`,
        //   to: `less-than::${month}-31`,
        // });
        setLoading(true);

        attendanceService
          .getEmployeeBook(month, selectedEmployee.id)
          .then((res) => {
            setAttendances(_.sortBy(res, 'date'));
            setLoading(false);
          });
      }
      // eslint-disable-next-line
    }, [selectedEmployee, month]);

    // useEffect(() => {
    //   if (attendances?.length) {
    //     setEmployeeAttendances(_.sortBy(attendances, 'date'));
    //   }
    // }, [attendances]);

    // function renderFinalizationDetails({
    //   e,
    //   shiftHours,
    //   payableDayRatio,
    // }: {
    //   e: AttendanceType;
    //   shiftHours: number;
    //   payableDayRatio: number;
    // }) {
    //   return (
    //     <AuthWrapper
    //       showUnAuthorizedWarning={true}
    //       action='UI:BLK-ATTN-FINL:VIEW'
    //       children={
    //         <div>
    //           {e.finalized ? (
    //             <>
    //               <div>
    //                 {e.details?.summary?.autofinalized ? (
    //                   <div>
    //                     <BoltIcon className='text-amber-500 animate-bounce w-4 inline' />
    //                     {e.details.summary.autofinalizeReason}
    //                   </div>
    //                 ) : null}
    //               </div>
    //               <div>
    //                 Payable Day: {e.details?.summary?.payableDay ? 'Yes' : 'No'}
    //               </div>
    //               <div>
    //                 PayableHours: {e.details?.summary?.payableWorkHours}Hrs
    //               </div>
    //               <div>Shift Duration: {shiftHours}</div>
    //               <div>Payable Day Ratio: {payableDayRatio}</div>
    //               {e.details?.summary?.payableDayPenalty ? (
    //                 <div>
    //                   Penalty: {e.details.summary.lateMarkMins || 0}Hrs.
    //                 </div>
    //               ) : null}
    //               {e.details?.summary?.overtime?.reward ? (
    //                 <div>
    //                   Overtime reward type: {e.details.summary.overtime?.reward}
    //                   {e.details.summary.overtime?.reward === 'salary' ? (
    //                     <div>
    //                       Overtime Hours:{' '}
    //                       {e.details.summary.addSalaryForOvertime?.overtimeHours?.toFixed(
    //                         2
    //                       )}
    //                     </div>
    //                   ) : null}
    //                   {e.details.summary.overtime?.reward === 'comp-off' ? (
    //                     <div>
    //                       Comp-offs:{' '}
    //                       {e.details.summary.overtime.totalCompOffEarned}
    //                     </div>
    //                   ) : null}
    //                 </div>
    //               ) : null}
    //               {(e.details?.summary as any)?.leaves?.length ? (
    //                 <div>
    //                   {(e.details?.summary as any).leaves[0].type} leave
    //                   redeemed ({(e.details?.summary?.leaves?.length || 0) / 2}{' '}
    //                   count)
    //                 </div>
    //               ) : null}
    //               {e.details?.summary?.payableDayPenalty
    //                 ? 'Payable Day Penalty'
    //                 : null}
    //               {e.details?.summary?.layoffRule?.type ? (
    //                 <div>
    //                   <div>Type: {e.details?.summary?.layoffRule?.type}</div>
    //                   <div>Rule: {e.details?.summary?.layoffRule?.value}</div>
    //                 </div>
    //               ) : null}
    //             </>
    //           ) : (
    //             <>Finalization Pending</>
    //           )}
    //         </div>
    //       }
    //     />
    //   );
    // }

    // const tableBody: TableBody =
    //   employeeAttendances.map((e) => {
    //     const shiftHours =
    //       e.details?.summary &&
    //       e.details.summary.hasOwnProperty('shiftDuration')
    //         ? e.details?.summary?.shiftDuration
    //         : calculateShiftDuration({ employee: selectedEmployee });
    //     const payableDayRatio = parseFloat(
    //       ((e.details?.summary?.payableWorkHours || 0) / shiftHours).toFixed(2)
    //     );
    //     return {
    //       cells: [
    //         { value: <div>{e.date}</div> },
    //         {
    //           value: (
    //             <div>
    //               {e.details?.punches?.map((x, tidx) => (
    //                 <span
    //                   className='text-xs mx-0.5 p-0.5 border border-gray-200 rounded-full'
    //                   key={tidx}
    //                 >
    //                   <Chip p={x} date={e.date} />
    //                 </span>
    //               ))}
    //             </div>
    //           ),
    //         },
    //         {
    //           value: renderFinalizationDetails({
    //             e,
    //             payableDayRatio,
    //             shiftHours,
    //           }),
    //         },
    //         {
    //           value: (
    //             <>
    //               {e.status}
    //               {currentUserRoles?.find((x) => x.name === 'System-Admin') &&
    //               employeeAttendances.filter((x) => x.date === e.date).length >
    //                 1 ? (
    //                 <TrashIcon
    //                   className='inline mx-5 text-red-500 w-5 cursor-pointer'
    //                   onClick={() => {
    //                     openConfirm({
    //                       title:
    //                         'Are you sure you want to delete the attendance?',
    //                       onConfirm: async () => {
    //                         await setAttendance({
    //                           id: e.id,
    //                           disabled: true,
    //                         } as any);
    //                         // getAllAttendances({
    //                         //   employeeId: `equal::${selectedEmployee.id}`,
    //                         //   from: `more-than::${month}-01`,
    //                         //   to: `less-than::${month}-31`,
    //                         // });
    //                         setLoading(true);
    //                         attendanceService.getEmployeeBook(month, selectedEmployee.id).then(res =>
    //                           {
    //                             setAttendances(res);
    //                             setLoading(false);
    //                           });
    //                       },
    //                     });
    //                   }}
    //                 />
    //               ) : null}
    //             </>
    //           ),
    //         },
    //       ],
    //     };
    //   }) || [];

    const Actions = renderActions({
      isHr,
      setAttendance,
      leaveService,
      currentUser,
      useEmployees,
      useLeaves,
      getEmployeeSync,
      setGatePass,
      setOffsite,
      useOvertimes,
      saveNewOvertime,
      setOvertime,
      currentCompany,
      companyGroupSetting,
      fetchData: async () => {
        attendanceService.getEmployeeBook(month, selectedEmployee.id);
      },
      isSystemAdmin,
      attendanceService,
      logRecordService,
      getLeavesSync,
      setLeave,
    });

    const actions = Actions();

    const AttBody = renderAttendanceBody({
      userRendererService,
      currentUser,
      getAllAttendanceSync: () => {
        return attendanceService.getEmployeeBook(month, selectedEmployee.id);
      },
      setAttendance,
      currentUserRoles: currentUserRoles || [],
      useUserAuthorization,
      fetchData: async () => {
        attendanceService.getEmployeeBook(month, selectedEmployee.id);
      },
    });

    const newtableBody: TableBody = AttBody({
      type: 'employee-book',
      list: attendances.map((c) => ({ attendance: c })),
    });

    const payableRatios = computePayableDayRatios(attendances);
    const weekoffs = calculateWeekOffs(month, attendances, selectedEmployee);
    const summary: Array<{
      name: string;
      value: string | number | JSX.Element;
    }> = [
      {
        name: 'Total Attendance',
        value: attendances.length,
      },
      {
        name: 'Total Finalized',
        value: attendances.filter((x) => x.finalized).length || 0,
      },
      {
        name: 'Paid Leaves',
        value: attendances.filter(
          (x) =>
            x.finalized &&
            x.details?.summary?.autofinalizeReason === 'leaves-approved' &&
            x.details?.summary?.leaves?.find((x) =>
              [
                'sick-leave',
                'compensatory-off',
                'casual-leave',
                'privilege-leave',
              ].includes(x.type)
            )
        ).length,
      },
      {
        name: 'Unpaid Leaves',
        value: attendances.filter(
          (x) =>
            x.finalized &&
            x.details?.summary?.autofinalizeReason === 'leaves-approved' &&
            x.details?.summary?.leaves?.find((x) =>
              ['unpaid-leave'].includes(x.type)
            )
        ).length,
      },
      {
        name: 'Late Marks',
        value: attendances.filter((x) => x.isLateForShift).length || 0,
      },
      {
        name: 'LayOff Attendance',
        value: attendances.filter((x) => {
          if (
            x.finalized &&
            x.details?.summary?.layoffRule?.type === 'on-attendance'
          ) {
            return true;
          }
          return false;
        }).length,
      },
      {
        name: 'LayOff on LO-Leave',
        value: attendances.filter((x) => {
          if (
            x.finalized &&
            x.details?.summary?.layoffRule?.type === 'on-lay-off-leave'
          ) {
            return true;
          }
          return false;
        }).length,
      },
      {
        name: 'LayOff on Non-LO-Leave',
        value: attendances.filter((x) => {
          if (
            x.finalized &&
            x.details?.summary?.layoffRule?.type === 'on-non-lay-off-leave'
          ) {
            return true;
          }
          return false;
        }).length,
      },
      {
        name: 'Payable Days Ratio',
        value:
          payableRatios.adjusted !== payableRatios.total ? (
            <div>
              {payableRatios.total} (Adjusted: {payableRatios.adjusted})
            </div>
          ) : (
            payableRatios.total
          ),
      },
      {
        name: 'Week-Offs',
        value:
          weekoffs.scheduledWeekOff === weekoffs.eligibleWeekOff ? (
            weekoffs.eligibleWeekOff
          ) : (
            <>
              <div>Scheduled: {weekoffs.scheduledWeekOff}</div>
              <div>Eligible: {weekoffs.eligibleWeekOff}</div>
            </>
          ),
      },
      {
        name: 'Finalized Total Work Hours',
        value: parseFloat(
          attendances
            .filter((x) => x.finalized)
            .reduce((prev, curr) => {
              if (curr.details?.summary?.totalWorkHours) {
                return prev + curr.details?.summary?.totalWorkHours;
              }
              return prev;
            }, 0)
            .toFixed(2)
        ),
      },
      {
        name: 'Finalized Total Payable Hours',
        value: parseFloat(
          attendances
            .filter((x) => x.finalized)
            .reduce((prev, curr) => {
              if (curr.details?.summary?.payableWorkHours) {
                return prev + curr.details?.summary?.payableWorkHours;
              }
              return prev;
            }, 0)
            .toFixed(2)
        ),
      },
      {
        name: 'Manual Punches',
        value: manualPunchCount(attendances),
      },
      {
        name: 'Gatepasses',
        value: gatepassCount(attendances),
      },
      {
        name: 'Offsites',
        value: offsiteCount(attendances),
      },
      {
        name: 'OT - Salary Hours',
        value: otSalaryHours(attendances),
      },
    ];

    return (
      <div className='pb-52'>
        <Card
          header={{
            title: `Employee: ${
              selectedEmployee.id ? selectedEmployee.name : 'None Selected'
            }`,
            icon: (
              <BookOpenIcon className='w-8 h-8 inline stroke-indigo-900 self-center' />
            ),
            subheading: <div className='flex items-center'></div>,
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div className='flex w-72 p-1 space-x-2 items-center justify-between font-bolder'>
                    <span className='font-bold truncate'>Select Month</span>
                    <MonthSelector
                      format='YYYY-MM'
                      initialState={month}
                      onChange={(m) => setMonth(m)}
                    />
                  </div>
                ),
              },
              {
                type: 'jsx',
                jsx: (
                  <div className='flex p-1 w-80 space-x-2 items-center justify-between font-bolder'>
                    <div className='font-bold flex-1'>Select</div>
                    <div>
                      <SearchBox
                        currentSelected={selectedEmployee}
                        searchBoxStyle='p-2 bg-gray-100 border border-gray-200 rounded-sm'
                      />
                    </div>
                  </div>
                ),
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                {selectedEmployee?.id ? (
                  <div className='p-1 my-1 border border-gray-200 rounded-md'>
                    <div className='text-center font-bold text-2xl'>
                      Attendance Monthly Summary
                    </div>
                    <div className='flex flex-wrap'>
                      {summary
                        ?.filter((x) => x.value)
                        ?.map((x, idx) => (
                          <div
                            key={idx}
                            className='w-1/6 border border-gray-200'
                          >
                            <div className='text-center text-xl'>{x.name}</div>
                            <div className='text-center text-2xl'>
                              {x.value}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ) : null}
                <Table
                  isDataLoading={loading}
                  header={Header({ type: 'employee-book' })}
                  body={newtableBody}
                  auth={{ actions: getAllowedActions().actions }}
                  actions={actions}
                />

                {/* {tableBody.length ? (
                  <Table
                    header={[
                      [
                        { name: 'Date' },
                        { name: 'Timestamps' },
                        { name: 'Payable Day/Hours' },
                        { name: 'Status' },
                      ],
                    ]}
                    body={tableBody}
                  />
                ) : (
                  <div className='mx-auto p-5 text-center text-xl'>
                    {selectedEmployee.id ? (
                      <>
                        <div>
                          Seems like {selectedEmployee.name} does not have any
                          Attendance
                        </div>
                      </>
                    ) : (
                      <>Select an Employee to see Attendance</>
                    )}
                  </div>
                )} */}
              </div>
            ),
          }}
        />
        {/* <Confirm /> */}
      </div>
    );
  };
}
