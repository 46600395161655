/* eslint-disable no-unused-vars */
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseItems } from '../../../../hooks/inventory/item/use-items';

type Props = {
  onSave: any;
};

// eslint-disable-next-line
export function renderAddMultiItemsForm({
  useItems,
}: {
  useItems: UseItems;
}): (props: Props) => JSX.Element {
  return function AddMultiItemsForm({ onSave }: Props): JSX.Element {
    const Form = renderFormV2({
      onSubmit: async (form) => {
        onSave(form);
        //await setClientdetails(form);
      },
      style: 'w-1/3',
      mapTToU: (f) => f,
      initialFormState: {
        itemName: '',
        quantity: '',
        rate: '',
        packaging: '',
        attachments: '',
        remark: '',
        targetDate: '',
      },
      fieldsData: [
        {
          property: 'itemName',
          label: 'Item Name',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useItems,
            onSearchValueSelect: (v) => {
              // console.log(v);
            },
          },
          required: true,
        },
        {
          property: 'quantity',
          label: 'Quantity',
          type: 'number',
          required: true,
        },
        {
          property: 'rate',
          label: 'Rate',
          type: 'input',
          required: true,
        },
        {
          property: 'targetDate',
          label: 'Target Date',
          type: 'date',
          required: true,
        },
        {
          property: 'packaging',
          label: 'Packaging',
          type: 'input',
          required: true,
        },
        {
          property: 'attachments',
          label: 'Attachments',
          type: 'input',
          required: true,
        },
        {
          property: 'remark',
          label: 'Remark',
          type: 'input',
          required: true,
        },
      ],
    });

    return <Form />;
  };
}
