import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  MonthSelector,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseEmployeeProfiles } from '../../../hooks/hrd/employee/profile/use-employee-profiles';

type PaymentType = {
  id: string;
  name: string;
  type: 'deduction' | 'earning';
  amount: string | number;
  reason: string;
  status: string;
};

export function renderAdhocPayments({
  useEmployees,
  useEmployee,
}: {
  useEmployees: UseEmployeeProfiles;
  useEmployee: UseEmployeeProfile;
}) {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return function AdhocPayments(): JSX.Element {
    const [date, setDate] = useState(
      localStorage.getItem('adhoc-payments-date') ||
        moment.utc().format('YYYY-MM')
    );
    const { getAllSync: getEmployees, data: allEmployees } = useEmployees();
    const { syncSet: setEmployee } = useEmployee();
    const [payments, setPayments] = useState<Array<PaymentType>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
      if (date && localStorage.getItem('adhoc-payments-date') !== date) {
        localStorage.setItem('adhoc-payments-date', date);
      }
    }, [date]);

    useEffect(() => {
      setLoading(true);
      getEmployees({
        crossGroup: 'true',
      }).then((emps) => {
        const pmts: Array<PaymentType> = [];
        for (const emp of emps) {
          if (emp.details?.adhocPayments) {
            for (const ap of emp.details?.adhocPayments) {
              const monthYear = moment(ap.monthYear, 'MM/YYYY').format(
                'YYYY-MM'
              );
              if (monthYear === date) {
                pmts.push({
                  id: emp.id,
                  name: emp.name,
                  amount: ap.amount,
                  reason: ap.reason,
                  type: ap.type,
                  status: ap.status,
                });
              }
            }
          }
        }

        setPayments(pmts);
        setLoading(false);
      });
      // eslint-disable-next-line
    }, [date]);

    return (
      <Card
        header={{
          title: 'Adhoc Payments',
          actions: [
            {
              type: 'jsx',
              jsx: (
                <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
                  <span className='font-bold truncate'>Select month</span>
                  <MonthSelector
                    format='YYYY-MM'
                    initialState={date}
                    onChange={(m) => setDate(m)}
                  />
                </div>
              ),
            },
            {
              type: 'button',
              button: {
                name: 'Add',
                behaviour: 'modal',
                modal: {
                  title: 'Add Payment',
                  size: 'normal',
                  content: ({ onClose }) => {
                    return (
                      <AddEditPayment
                        payment={null}
                        setEmployee={setEmployee}
                        allEmployees={allEmployees || []}
                        useEmployees={useEmployees}
                        onClose={onClose}
                        date={date}
                      />
                    );
                  },
                },
              },
            },
          ],
        }}
        body={{
          type: 'jsx-component',
          body: (
            <Table
              isDataLoading={loading}
              header={[
                [
                  { name: 'Employee' },
                  { name: 'Type' },
                  { name: 'Amount' },
                  { name: 'Reason' },
                  { name: 'Status' },
                ],
              ]}
              body={payments.map((x) => {
                return {
                  rowData: {
                    payment: x,
                  },
                  cells: [
                    { value: x.name },
                    { value: x.type },
                    { value: x.amount },
                    { value: x.reason },
                    { value: x.status },
                  ],
                };
              })}
              actions={[
                {
                  name: 'Edit',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit',
                    content: ({ data: { payment }, onClose }) => {
                      console.log(payment);
                      return (
                        <AddEditPayment
                          payment={payment}
                          setEmployee={setEmployee}
                          allEmployees={allEmployees || []}
                          useEmployees={useEmployees}
                          onClose={onClose}
                          date={date}
                        />
                      );
                    },
                  },
                },
              ]}
            />
          ),
        }}
      />
    );
  };
}

function AddEditPayment({
  payment,
  useEmployees,
  allEmployees,
  date,
  onClose,
  setEmployee,
}: {
  payment: PaymentType | null;
  allEmployees: EmployeeProfileType[];
  useEmployees: UseEmployeeProfiles;
  date: string;
  onClose: () => void;
  setEmployee: (s: EmployeeProfileType) => Promise<EmployeeProfileType>;
}): JSX.Element {
  const Form = renderFormV2({
    initialFormState: {
      type: payment?.type || '',
      employee: { id: payment?.id || '', name: payment?.name || '' },
      amount: payment?.amount || 0,
      reason: payment?.reason || '',
    },
    style: 'w-1/2',
    fieldsData: [
      {
        property: 'employee',
        type: 'searchable-select',
        label: 'Employee Name',
        readonly: payment ? true : false,
        required: true,
        searchOptions: {
          useSearch: useEmployees,
          onSearchValueSelect: () => {},
          filter: { crossGroup: 'true' },
        },
      },
      {
        property: 'type',
        type: 'select',
        readonly: payment ? true : false,
        required: true,
        label: 'Type',
        options: [
          { text: 'Select', value: '' },
          { text: 'Deduction', value: 'deduction' },
          { text: 'Earning', value: 'earning' },
        ],
      },
      {
        property: 'amount',
        type: 'number',
        label: 'Amount',
        required: true,
      },
      {
        label: 'Reason',
        property: 'reason',
        type: 'input',
        required: true,
      },
    ],
    mapTToU: (p) => p,
    onSubmit: async (data: any) => {
      if (payment) {
        const selectedEmployee = allEmployees?.find((x) => x.id === payment.id);
        if (selectedEmployee) {
          const adhocPayments = selectedEmployee.details?.adhocPayments || [];
          const monthYear = moment(date, 'YYYY-MM').format('MM/YYYY');
          const pt = adhocPayments.find(
            (m) => m.type === payment.type && m.monthYear === monthYear
          );

          if (pt) {
            pt.amount = data.amount || payment.amount;
            pt.reason = data.reason || payment.reason;

            await setEmployee({
              id: selectedEmployee.id,
              details: {
                adhocPayments,
              },
            } as any);
            toast.success('Successfully updated Adhoc Payment');
          }
        }
      } else if (data.employee.id) {
        const selectedEmployee = allEmployees?.find(
          (x) => x.id === data.employee.id
        );
        if (selectedEmployee) {
          const adhocPayments = selectedEmployee.details?.adhocPayments || [];
          const monthYear = moment(date, 'YYYY-MM').format('MM/YYYY');
          if (
            !adhocPayments.find(
              (m) => m.type === data.type && m.monthYear === monthYear
            )
          ) {
            // Add only when there is no entry for the same type and month year in that employee
            adhocPayments.push({
              monthYear: monthYear,
              type: data.type,
              amount: data.amount,
              status: 'approved' as any,
              reason: data.reason,
            });
            await setEmployee({
              id: selectedEmployee.id,
              details: {
                adhocPayments,
              },
            } as any);
            toast.success('Successfully added Adhoc Payment');
          } else {
            alert(
              `Seems like the ${selectedEmployee.name} already has adhoc payments created for ${monthYear}`
            );
          }
        }
      }
      onClose();
    },
  });

  return <Form />;
}
