import { PurchaseOrderFulfilment } from '@erp_core/erp-types/dist/modules/order';
import { useEffect, useState } from 'react';
import { UseQuestionnaireQuestions } from '../../../hooks/hrd/questionnaire/questionnairequestion/usequestionnairequestions';
import { ItemInterface } from '../../../models/interfaces/inventory/item';
import { renderSurveyAnswerForm } from '../../../modules/miscellaneous/questionnaire/survey-answers/form';

export type InspectionFormProps = {
  setPurchaseFulfillment: (
    s: PurchaseOrderFulfilment
  ) => Promise<PurchaseOrderFulfilment>;
  item: PurchaseOrderFulfilment;
  onClose: () => void;
};

export function renderInspectionForm({
  itemService,
  useQuestionnaireQuestions,
}: {
  itemService: ItemInterface;
  useQuestionnaireQuestions: UseQuestionnaireQuestions;
}): (p: InspectionFormProps) => JSX.Element {
  const SurveyForm = renderSurveyAnswerForm({
    useQuestionnaireQuestions,
  });
  return function InspectionForm({
    item,
    onClose,
    setPurchaseFulfillment,
  }: InspectionFormProps): JSX.Element {
    const [properties, setProperties] = useState<any[]>([]);
    useEffect(() => {
      if (item.purchaseOrder?.details?.itemDetails?.grade?.item?.id) {
        itemService
          .getProperties(
            item.purchaseOrder?.details?.itemDetails?.grade?.item?.id
          )
          .then((res) => {
            setProperties(res);
            console.log(res);
          });
      }
      // eslint-disable-next-line
    }, []);

    if (properties.length) {
      const topicId = properties.find((x) => x.name === 'coa-questions')?.value
        ?.data;
      if (topicId) {
        return (
          <SurveyForm
            id={topicId}
            dynamicData={{
              grn: item,
              purchaseOrder: item.purchaseOrder,
              item: item.purchaseOrder.details?.itemDetails,
              properties,
            }}
            onSave={async (p) => {
              setPurchaseFulfillment({
                id: item.id,
                details: {
                  inspectionAnswers: p, // TODO add in erp types
                },
              } as any);
              console.log(p);
              onClose();
            }}
          />
        );
      }

      return <div>Unable to find COA Questions</div>;
    }

    return <div>Loading..</div>;
  };
}
