import { UseAppearance } from '../../../hooks/admin/constants/appearance/use-appearance';
import { UseAppearances } from '../../../hooks/admin/constants/appearance/use-appearances';
import { UsePaginatedAppearances } from '../../../hooks/admin/constants/appearance/use-paginated-appearances';
import { UseColor } from '../../../hooks/admin/constants/color/use-color';
import { UseColors } from '../../../hooks/admin/constants/color/use-colors';
import { UsePaginatedColors } from '../../../hooks/admin/constants/color/use-paginated-color';
import { UseCurrencies } from '../../../hooks/admin/constants/currency/use-currencies';
import { UseCurrency } from '../../../hooks/admin/constants/currency/use-currency';
import { UsePaginatedCurrencies } from '../../../hooks/admin/constants/currency/use-paginated-currencies';
import { UseEmployeeCategories } from '../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseEmployeeCategory } from '../../../hooks/admin/constants/employee-category/use-employee-category';
import { UsePaginatedEmployeeCategories } from '../../../hooks/admin/constants/employee-category/use-paginated-employee-categories';
import { UseEmployeeDesignation } from '../../../hooks/admin/constants/employee-designation/use-employee-designation';
import { UseEmployeeDesignations } from '../../../hooks/admin/constants/employee-designation/use-employee-designations';
import { UsePaginatedEmployeeDesignations } from '../../../hooks/admin/constants/employee-designation/use-paginated-employee-designations';
import { UseGst } from '../../../hooks/admin/constants/gst/use-gst';
import { UsePaginatedGST } from '../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseGsts } from '../../../hooks/admin/constants/gst/use-gsts';
import { UseHsn } from '../../../hooks/admin/constants/hsn/use-hsn';
import { UseHsns } from '../../../hooks/admin/constants/hsn/use-hsns';
import { UsePaginatedHsns } from '../../../hooks/admin/constants/hsn/use-paginated-hsns';
import { UseMetric } from '../../../hooks/admin/constants/metrics/use-metric';
import { UseMetrics } from '../../../hooks/admin/constants/metrics/use-metrics';
import { UsePaginatedMetrics } from '../../../hooks/admin/constants/metrics/use-paginated-metrics';
import { UseOdour } from '../../../hooks/admin/constants/odour/use-odour';
import { UseOdours } from '../../../hooks/admin/constants/odour/use-odours';
import { UsePaginatedOdours } from '../../../hooks/admin/constants/odour/use-paginated-odours';
import { UseDeliveryTerm } from '../../../hooks/constants/delivery-term/use-delivery-term';
import {
  UseDeliveryTerms,
  UsePaginatedDeliveryTerms,
} from '../../../hooks/constants/delivery-term/use-delivery-terms';
import { UsePaymentTerm } from '../../../hooks/constants/payment-term/use-payment-term';
import {
  UsePaginatedPaymentTerms,
  UsePaymentTerms,
} from '../../../hooks/constants/payment-term/use-payment-terms';
import { UseItemCategories } from '../../../hooks/inventory/item/item-category/use-item-categories';
import { UseItemCategory } from '../../../hooks/inventory/item/item-category/use-item-category';
import { UsePaginatedItemCategories } from '../../../hooks/inventory/item/item-category/use-paginated-item-categories';
import { renderAppearance } from './appearance';
import { renderColor } from './color';
import { renderCurrency } from './currency';
import { renderDeliveryModes } from './delivery-modes';
import { renderDeliveryTerms } from './delivery-terms';
import { renderEmployeeCategory } from './employee-category';
import { renderEmployeeDesignation } from './employee-designation';
import { renderGst } from './gst';
import { renderHsn } from './hsn';
import { renderItemCategoryV2 } from './item-category';
import { renderMetrics } from './metrics';
import { renderOdour } from './odour';
import { renderPaymentTerms } from './payment-terms';

type CreateConstantsCardType = {
  useCurrencies: UseCurrencies;
  usePaginatedCurrencies: UsePaginatedCurrencies;
  useEmployeeCategories: UseEmployeeCategories;
  usePaginatedEmployeeCategories: UsePaginatedEmployeeCategories;
  useMetrics: UseMetrics;
  useItemCategories: UseItemCategories;
  useDeliveryTerms: UseDeliveryTerms;
  usePaginatedDeliveryTerms: UsePaginatedDeliveryTerms;
  useGsts: UseGsts;
  usePaginatedMetrics: UsePaginatedMetrics;
  usePaginatedItemCategories: UsePaginatedItemCategories;
  usePaginatedGST: UsePaginatedGST;
  usePaginatedPaymentTerms: UsePaginatedPaymentTerms;
  usePaymentTerms: UsePaymentTerms;
  useEmployeeDesignations: UseEmployeeDesignations;
  usePaginatedEmployeeDesignations: UsePaginatedEmployeeDesignations;
  useCurrency: UseCurrency;
  useItemCategory: UseItemCategory;
  useMetric: UseMetric;
  useGst: UseGst;
  useDeliveryTerm: UseDeliveryTerm;
  usePaymentTerm: UsePaymentTerm;
  useEmployeeDesignation: UseEmployeeDesignation;
  useEmployeeCategory: UseEmployeeCategory;
  useAppearances: UseAppearances;
  usePaginatedAppearances: UsePaginatedAppearances;
  useAppearance: UseAppearance;
  useOdour: UseOdour;
  usePaginatedOdours: UsePaginatedOdours;
  useOdours: UseOdours;
  useColor: UseColor;
  usePaginatedColors: UsePaginatedColors;
  useColors: UseColors;
  useHsn: UseHsn;
  useHsns: UseHsns;
  usePaginatedHsns: UsePaginatedHsns;
};

export function renderConstants({
  useCurrencies,
  usePaginatedCurrencies,
  useEmployeeCategories,
  usePaginatedEmployeeCategories,
  useMetrics,
  useItemCategories,
  useGsts,
  useDeliveryTerms,
  usePaginatedDeliveryTerms,
  usePaginatedMetrics,
  usePaginatedGST,
  usePaginatedItemCategories,
  usePaginatedPaymentTerms,
  usePaymentTerms,
  useEmployeeDesignations,
  usePaginatedEmployeeDesignations,
  useCurrency,
  useItemCategory,
  useMetric,
  useGst,
  useDeliveryTerm,
  usePaymentTerm,
  useEmployeeDesignation,
  useEmployeeCategory,
  useAppearances,
  usePaginatedAppearances,
  useAppearance,
  useOdour,
  usePaginatedOdours,
  useOdours,
  useColor,
  usePaginatedColors,
  useColors,
  useHsns,
  usePaginatedHsns,
  useHsn,
}: CreateConstantsCardType): () => JSX.Element {
  const Currency = renderCurrency({
    useCurrencies,
    usePaginatedCurrencies,
    useCurrency,
  });
  const EmployeeCategory = renderEmployeeCategory({
    useEmployeeCategories,
    usePaginatedEmployeeCategories,
    useEmployeeCategory,
  });
  const EmployeeDesignation = renderEmployeeDesignation({
    useEmployeeDesignations,
    usePaginatedEmployeeDesignations,
    useEmployeeCategories,
    useEmployeeDesignation,
  });
  const Metrics = renderMetrics({ useMetrics, usePaginatedMetrics, useMetric });
  const ItemCategory = renderItemCategoryV2({
    useItemCategories,
    usePaginatedItemCategories,
    useItemCategory,
  });
  const Gst = renderGst({ useGsts, usePaginatedGST, useGst });
  const DeliveryTerms = renderDeliveryTerms({
    useDeliveryTerms,
    usePaginatedDeliveryTerms,
    useDeliveryTerm,
  });
  const DeliveryModes = renderDeliveryModes();
  const PaymentTerms = renderPaymentTerms({
    usePaginatedPaymentTerms,
    usePaymentTerms,
    usePaymentTerm,
  });
  const Appearance = renderAppearance({
    useAppearances,
    usePaginatedAppearances,
    useAppearance,
  });

  const Odour = renderOdour({
    useOdour,
    usePaginatedOdours,
    useOdours,
  });

  const Color = renderColor({
    useColor,
    usePaginatedColors,
    useColors,
  });

  const Hsn = renderHsn({
    useHsn,
    usePaginatedHsns,
    useHsns,
  });

  const sections: Array<{ Section: () => JSX.Element; colSpan: string }> = [
    { Section: Currency, colSpan: 'col-span-1' },
    { Section: ItemCategory, colSpan: 'col-span-2' },
    { Section: Metrics, colSpan: 'col-span-1' },
    { Section: Gst, colSpan: 'col-span-1' },
    { Section: DeliveryTerms, colSpan: 'col-span-1' },
    { Section: DeliveryModes, colSpan: 'col-span-1' },
    { Section: PaymentTerms, colSpan: 'col-span-1' },
    { Section: EmployeeCategory, colSpan: 'col-span-1' },
    { Section: EmployeeDesignation, colSpan: 'col-span-1' },
    { Section: Appearance, colSpan: 'col-span-1' },
    { Section: Odour, colSpan: 'col-span-1' },
    { Section: Color, colSpan: 'col-span-1' },
    { Section: Hsn, colSpan: 'col-span-2' },
  ];

  return function Constants(): JSX.Element {
    return (
      <div className='space-y-2 grid grid-cols-3 gap-4'>
        {sections.map((s, idx) => {
          return (
            <div key={idx} className={`h-full w-full space-x-2 ${s.colSpan}`}>
              <s.Section />
            </div>
          );
        })}
      </div>
    );
  };
}
