import { FormDataType, renderFormV2 } from '@erp_core/erp-ui-components';
import { LeaveRulesType } from '..';
import { UseEmployeeCategories } from '../../../../../../hooks/admin/constants/employee-category/use-employee-categories';

export type LeaveRuleFormDataType = {
  leaveName?: string | undefined;
  leaveType?: string | undefined;
  dependOnGrade?: string | undefined;
  grades?: Array<{ id: string; name: string }>;
  dependOnGender?: string | undefined;
  gender?: string | undefined;
  allowedDuringProbation?: string | undefined;
  availType?: string | undefined;
  maxLeavesAllotment?: string;
  document?: string;
  noticePeriod?: string;
  encashmentAllowed?: string | undefined;
  leavesRedeemed?: string;
  allowCarryForward?: string;
  leavesExpire?: string;
};

export function renderLeaveRuleForm({
  useEmployeeCategories,
}: {
  useEmployeeCategories: UseEmployeeCategories;
}) {
  return function Form({
    data,
    onSave,
  }: {
    data: LeaveRulesType;
    onSave: (data) => Promise<void>;
  }) {
    const Form = renderFormV2<LeaveRuleFormDataType>({
      fieldsData: [
        {
          type: 'input',
          property: 'leaveName',
          label: 'Name of the Leave',
        },
        {
          type: 'select',
          property: 'leaveType',
          label: 'Leave Type',
          options: [
            { value: 'select', text: 'select' },
            { text: 'casual-leave', value: 'casual-leave' },
            { text: 'privilege-leave', value: 'privilege-leave' },
            { text: 'sick-leave', value: 'sick-leave' },
            { text: 'unpaid-leave', value: 'unpaid-leave' },
            { text: 'on-job-accident-leave', value: 'on-job-accident-leave' },
            { text: 'maternity-leave', value: 'maternity-leave' },
          ],
        },
        {
          type: 'select',
          property: 'dependOnGrade',
          label: 'Dependent on Grade ?',
          options: [
            { value: 'select', text: 'select' },
            { value: 'yes', text: 'Yes' },
            { value: 'no', text: 'No' },
          ],
        },
        {
          type: 'searchable-multi-select',
          searchOptions: {
            useSearch: useEmployeeCategories,
            onSearchValueSelect: (e) => {},
          },
          label: 'Grade',
          property: 'grades',
          // dependentOn: (formData) => {
          //   return formData?.dependOnGrade === 'yes';
          // },
        },
        {
          type: 'select',
          property: 'dependOnGender',
          label: 'Dependent on Gender ?',
          options: [
            { value: 'select', text: 'select' },
            { value: 'yes', text: 'Yes' },
            { value: 'no', text: 'No' },
          ],
        },
        {
          type: 'select',
          property: 'gender',
          label: 'Gender',
          options: [
            { value: 'select', text: 'select' },
            { value: 'male', text: 'Male' },
            { value: 'female', text: 'Female' },
            { value: 'both', text: 'Both' },
          ],
          dependentOn: (formData) => {
            return formData?.dependOnGender === 'yes';
          },
        },
        {
          type: 'select',
          property: 'allowedDuringProbation',
          label: 'Allowed during Probation ?',
          options: [
            { value: 'select', text: 'select' },
            { value: 'yes', text: 'Yes' },
            { value: 'no', text: 'No' },
          ],
        },
        {
          type: 'select',
          property: 'availType',
          label: 'Should be available in bank or on demand ?',
          options: [
            { value: 'select', text: 'select' },
            { value: 'leave-bank', text: 'In Leave Bank' },
            { value: 'on-demand', text: 'On-Demand' },
          ],
        },
        {
          type: 'input',
          property: 'maxLeavesAllotment',
          label: 'At a given time, how many leaves will be allotted ?',
          dependentOn: (formData) => {
            return formData?.availType === 'leave-bank';
          },
        },
        {
          type: 'select',
          property: 'document',
          label: 'Document submission required?',
          options: [
            { value: 'select', text: 'select' },
            { value: 'yes', text: 'Yes' },
            { value: 'no', text: 'No' },
          ],
        },
        {
          type: 'input',
          property: 'noticePeriod',
          label: 'Notice Period required for leaves ?',
        },
        {
          type: 'select',
          property: 'encashmentAllowed',
          label: 'Encashment Allowed',
          options: [
            { value: 'select', text: 'select' },
            { value: 'yes', text: 'Yes' },
            { value: 'no', text: 'No' },
          ],
        },
        {
          type: 'input',
          property: 'leavesRedeemed',
          label: 'At a given time, how many leaves to be redeemed ?',
        },
        {
          type: 'select',
          property: 'allowCarryForward',
          label: 'Are leaves carry forwarded to next year ?',
          options: [
            { value: 'select', text: 'select' },
            { value: 'yes', text: 'Yes' },
            { value: 'no', text: 'No' },
          ],
        },
        {
          type: 'input',
          property: 'leavesExpire',
          label: 'After how many days, leaves will expire ?',
          dependentOn: (formData) => {
            return formData?.allowCarryForward === 'yes';
          },
        },
      ],
      initialFormState: {
        leaveName: data.basicInformation.leaveName || '',
        leaveType: data.basicInformation.leaveType || '',
        dependOnGrade: data.basicInformation.dependOnGrade || '',
        grades: data.basicInformation.grades || [],
        dependOnGender: data.basicInformation.dependOnGender || '',
        gender: data.basicInformation.gender || '',
        allowedDuringProbation:
          data.basicInformation.allowedDuringProbation || '',
        availType: data.availmentRules.availType || '',
        maxLeavesAllotment: data.availmentRules.maxLeavesAllotment || '',
        document: data.availmentRules.document || '',
        noticePeriod: data.availmentRules.noticePeriod || '',
        encashmentAllowed: data.redemptionRules.encashmentAllowed || '',
        leavesRedeemed: data.redemptionRules.leavesRedeemed || '',
        allowCarryForward: data.expirationRules.allowCarryForward || '',
        leavesExpire: data.expirationRules.leavesExpire || '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      allowUnchanged: true,
    });

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<LeaveRuleFormDataType>>
    ): LeaveRuleFormDataType {
      return {
        leaveName: b.leaveName as string,
        leaveType: b.leaveType as string,
        dependOnGrade: b.dependOnGrade as string,
        grades: b.grades as any,
        dependOnGender: b.dependOnGender as string,
        gender: b.gender as string,
        allowedDuringProbation: b.allowedDuringProbation as string,
        availType: b.availType as string,
        maxLeavesAllotment: b.maxLeavesAllotment as string,
        document: b.document as string,
        noticePeriod: b.noticePeriod as string,
        encashmentAllowed: b.encashmentAllowed as string,
        leavesRedeemed: b.leavesRedeemed as string,
        allowCarryForward: b.allowCarryForward as string,
        leavesExpire: b.leavesExpire as string,
      };
    }
  };
}
