import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { ItemProperty } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseMetrics } from '../../../../hooks/admin/constants/metrics/use-metrics';
import { ChangeRequestDataType } from './types';

type EditValueUOMChangeReqProps = {
  itemProperty: ItemProperty;
  iscurrentLevelOwnerShip: boolean;
  currentValue: {
    value: number;
    uom: IdName;
  };
  onSave: (data: ChangeRequestDataType) => Promise<void>;
};

type EditPropertyIdNameChangeReqFormType = {
  currentValue: number;
  currentUOM: IdName;
  newValue: string;
  newUOM: IdName;
  reason: string;
};

export const renderEditValueUOMPropertyChangeReq = ({
  useMetrics,
}: {
  useMetrics: UseMetrics;
}) => {
  return function EditValueUOMPropertyChangeReq({
    itemProperty,
    iscurrentLevelOwnerShip,
    currentValue,
    onSave,
  }: EditValueUOMChangeReqProps) {
    const useSearchResource = () => {
      return useMetrics;
    };

    const searchResource = useSearchResource();
    if (!searchResource) {
      return <></>;
    }

    const formProps: FormProps<EditPropertyIdNameChangeReqFormType> = {
      fieldsData: [
        {
          property: 'currentValue',
          type: 'jsx',
          label: 'Current Value',
          readonly: true,
          renderObject: ({ formData, field, onSave }) => {
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                {formData.currentValue as string}{' '}
                {(formData.currentUOM as any)?.name || ''}
              </div>
            );
          },
        },
        {
          property: 'newValue',
          type: 'input',
          label: 'New Value',
          required: true,
          validate: async (form) => {
            if (form.newValue) {
              if (form.newValue === 'NA') {
                return {};
              }
              const pattern = new RegExp('^[-+]?[0-9]+(.[0-9]+)?$');
              const test = pattern.test(form.newValue as string);
              if (!test) {
                return {
                  newValue: 'Invalid Value: Enter Number or NA',
                } as any;
              }
            }

            return {};
          },
        },
        {
          property: 'newUOM',
          type: 'searchable-select',
          readonly:
            itemProperty.valueType === 'fixed-uom' && !iscurrentLevelOwnerShip,
          required: true,
          label: 'New UOM',
          searchOptions: {
            useSearch: searchResource,
            // selectTarget: (u) => {
            //   console.log(u);
            //   return {
            //     id: u.id,
            //     name: u.icon
            //   }
            // },
            onSearchValueSelect(u) {},
            searchOptionsBody: {
              customBody: (p) => (
                <div>
                  <div className='text-gray-600'>
                    {p.name} <b className='text-black'>{p.icon}</b>
                  </div>
                </div>
              ),
            },
          },
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
      ],
      initialFormState: {
        currentValue: currentValue.value,
        currentUOM: currentValue.uom || { id: '', name: '' },
        newValue: currentValue.value,
        newUOM: currentValue.uom || { id: '', name: '' },
        reason: '',
      },
      onSubmit: async (f) => {
        await onSave({
          reason: f.reason || '',
          changeRequest: {
            currentValue: {
              value: {
                data: {
                  value: f.currentValue,
                  uom: f.currentUOM,
                },
              },
            },
            newValue: {
              value: {
                data: {
                  value: f.newValue,
                  uom: f.newUOM?.id
                    ? f.newUOM
                    : currentValue.uom || { id: '', name: '' },
                },
              },
            },
          },
        });
      },
      mapTToU(p) {
        return (p as unknown) as EditPropertyIdNameChangeReqFormType;
      },
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EditPropertyIdNameChangeReqFormType>(formProps);

    return (
      <>
        <Form />;
      </>
    );
  };
};
