import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import { UseLoans } from '../../../../hooks/loans/loan/use-loans';
import { UseRepaymentSchedules } from '../../../../hooks/loans/repayment-schedule/use-repayment-schedules';

export function RepaymentScheduleForm({
  useRepaymentSchedules,
  useLoans,
  loanId,
}: {
  useRepaymentSchedules: UseRepaymentSchedules;
  useLoans: UseLoans;
  loanId: string;
}): JSX.Element {
  const {
    data: repaymentSchedules,
    getAll: getRepaymentSchedules,
  } = useRepaymentSchedules();

  const { data: loans, getAll: getLoans } = useLoans();

  useEffect(() => {}, [repaymentSchedules]);

  useEffect(() => {
    getRepaymentSchedules({
      loanId: loanId,
      order: 'asc',
      sortBy: 'monthYear',
    });

    getLoans();
    // eslint-disable-next-line
  }, []);

  const disbursementDt = loans
    ?.filter((x) => x.id === loanId)
    .map((y) => y.details.disbursedDate)
    .flat();

  const disbursementDate = disbursementDt ? disbursementDt[0]?.date : '';
  const disburseementAmt = disbursementDt
    ? Number(disbursementDt[0]?.amount)
    : 0;

  const Card = renderCardComponent();
  const cardHeader: CardHeader = {
    title: 'Repayment Schedule',
  };

  const Table = renderTableComponent();

  const tableHeader: TableHeader = [
    [
      { name: 'Date' },
      { name: 'Type' },
      { name: 'Disbursement Amount' },
      { name: 'Opening Balance' },
      { name: 'Interest Carried Over' },
      { name: 'Repayment Amount' },
      { name: 'Principal' },
      { name: 'Interest' },
      { name: 'Status' },
    ],
  ];

  const calcPrincipal: number[] = [];
  const calcInterests: number[] = [];

  // eslint-disable-next-line no-lone-blocks
  {
    repaymentSchedules?.forEach(
      (r, idx) =>
        calcPrincipal.push(r.principal) &&
        calcInterests.push(Number(r.interest))
    );
  }

  // store sum of interest
  var interestSum = 0;
  for (var i = 0; i < calcInterests.length; i++) {
    interestSum += calcInterests[i];
  }

  // store sum of principal
  var principalSum = 0;
  for (var j = 0; j < calcPrincipal.length; j++) {
    principalSum += calcPrincipal[j];
  }

  type GetDisbursementResponseData = {
    month: string;
    noOfDays: number;
    disbursementAmount: number;
    openingBalance: number;
    interestCarriedOver: number;
    repaymentAmount: number;
    monthlyInterest: number;
    interestContribution: number;
    principalContribution: number;
    closingBalance: number;
    status: string;
    type: string;
  };

  let disbursementEntries: GetDisbursementResponseData[] = [];
  if (repaymentSchedules) {
    disbursementEntries.push({
      month: moment(disbursementDate).format('YYYY-MM-DD'),
      noOfDays: 0, // i === 0 ? currentDate > firstDateOfRepayment ? getNoOfDays(monthsBetween[i]) : leftDays + getNoOfDays(monthsBetween[i]) : getNoOfDays(monthsBetween[i]),
      disbursementAmount: disburseementAmt || 0,
      openingBalance: 0,
      interestCarriedOver: 0,
      repaymentAmount: 0,
      monthlyInterest: 0,
      interestContribution: 0,
      principalContribution: 0,
      closingBalance: 0,
      status: '-',
      type: '-',
    });

    for (let i = 0; i < repaymentSchedules.length; i++) {
      disbursementEntries.push({
        month: repaymentSchedules[i].monthYear,
        noOfDays: 0, // i === 0 ? currentDate > firstDateOfRepayment ? getNoOfDays(monthsBetween[i]) : leftDays + getNoOfDays(monthsBetween[i]) : getNoOfDays(monthsBetween[i]),
        disbursementAmount: 0,
        openingBalance: Number(repaymentSchedules[i].details.openingBal) || 0,
        interestCarriedOver:
          Number(repaymentSchedules[i].details.interestCarriedOver) || 0,
        repaymentAmount: Number(repaymentSchedules[i].details.repayment) || 0,
        monthlyInterest: Number(repaymentSchedules[i].interest) || 0,
        interestContribution: Number(repaymentSchedules[i].interest) || 0,
        principalContribution: Number(repaymentSchedules[i].principal) || 0,
        closingBalance: Number(repaymentSchedules[i].details.closingBal) || 0,
        status: repaymentSchedules[i].status || '-',
        type: repaymentSchedules[i].type || '-',
      });
    }
  }

  const tableData =
    disbursementEntries?.map((r, idx) => ({
      cells: [
        { value: r.month },
        { value: r.type },
        { value: r.disbursementAmount || '0' },
        { value: r.openingBalance || '0' },
        { value: r.interestCarriedOver || '0' },
        { value: r.repaymentAmount || '0' },
        { value: r.principalContribution },
        { value: r.interestContribution },
        { value: r.status },
      ],
    })) || [];

  const tableBody: TableBody = tableData;

  const cardBody: CardBody = {
    type: 'jsx-component',
    body: (
      <div>
        <div className='w-full'>
          <Table header={tableHeader} body={tableBody} />
          <div>
            <span className='m-2'>
              Total Principal : {principalSum.toFixed(2)}
            </span>
            <span className='m-2'>
              Total Interest : {interestSum.toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    ),
  };

  return (
    <>
      <div className='p-16 border border-gray-200 rounded-lg h-18 border rounded-md cursor-pointer p-2'>
        <Card header={cardHeader} body={cardBody} />
      </div>
    </>
  );
}
