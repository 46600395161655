import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { BooleanType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';

export function enrolledForPTEC(emp: EmployeeProfileType): BooleanType {
  if (
    emp.details?.employeeRegistrations?.find(
      (x) => x.regDocumentType === 'Professional Tax Enrollment Certificate'
    )
  ) {
    return 'Yes';
  }

  return 'No';
}
