import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTabsComponent,
} from '@erp_core/erp-ui-components';
import { CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UsePurchaseRequisition } from '../../../../../hooks/inventory/purchase/purchase-requisition/use-purchase-requisition';
import { UseBom } from '../../../../../hooks/order/work-order/bom/use-bom';
import { PurchaseRequisitionInterface } from '../../../../../models/interfaces/order/purchase/purchase-requisition';
import { renderBOMName } from '../../../../../modules/common/fragments/bom-name';
import { renderHistoryTab } from '../history-tab';
import { renderPRProfile } from './pr-profile';

export type RenderPRDetailsProp = {
  hooks: {
    usePurchaseRequisition: UsePurchaseRequisition;
    useBom: UseBom;
  };
  services: {
    purchaseRequisitionService: PurchaseRequisitionInterface;
  };
};

export function renderPRDetails({
  hooks: { usePurchaseRequisition, useBom },
  services: { purchaseRequisitionService },
}: RenderPRDetailsProp): () => JSX.Element {
  return function PRDetails(): JSX.Element {
    const { id } = useParams();
    const { data, get: getPr } = usePurchaseRequisition();
    const Tabs = renderTabsComponent();
    const Card = renderCardComponent();
    const BOMName = renderBOMName({ useBom });

    useEffect(() => {
      if (id) {
        getPr(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const header: CardHeader = {
      title: `${data.name || id}`,
      icon: <CubeIcon className='text-gray-800 w-24 h-full self-center' />,
      subheading: (
        <>
          <div className='text-gray-700 italic'>
            Status:{' '}
            <span className='border rounded-md bg-indigo-100 text-indigo-900 px-1 py-0.5'>
              {data.status || 'open'}
            </span>{' '}
            Item:{' '}
            <a
              className='font-bold'
              href={`/inventory/masters/items/${data?.itemDetails?.id}/properties`}
              target='_blank'
              rel='noreferrer'
            >
              {data?.itemDetails?.name}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; Quantity:{' '}
            <b>
              {data?.details?.quantity || '??'} {data?.itemDetails?.uom}
            </b>
            &nbsp;&nbsp;|&nbsp;&nbsp; BOMs:{' '}
            {data?.bomIds?.map((id) => (
              <BOMName id={id} />
            ))}
          </div>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${data.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' /> {data.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${data.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {data.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(data.lastModifiedAt).fromNow()}
          </div>
        </>
      ),
    };

    const categories: Map<string, () => JSX.Element> = new Map<
      string,
      () => JSX.Element
    >([
      [
        'Details',
        renderPRProfile({
          id,
          usePurchaseRequisition,
        }),
      ],
      ['History', renderHistoryTab({ id, usePurchaseRequisition })],
    ]);

    const body: CardBody = {
      type: 'jsx-component',
      body: <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={0} />,
    };

    return <Card header={header} body={body} />;
  };
}
