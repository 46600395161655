import {
  HsnFilterType,
  HsnType,
} from '@erp_core/erp-types/dist/modules/constants';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseHsn } from '../../../hooks/admin/constants/hsn/use-hsn';
import { UseHsns } from '../../../hooks/admin/constants/hsn/use-hsns';
import { UsePaginatedHsns } from '../../../hooks/admin/constants/hsn/use-paginated-hsns';
import { renderAddHsnForm } from './form/add-hsn-form';

export type RenderHsnProps = {
  useHsns: UseHsns;
  usePaginatedHsns: UsePaginatedHsns;
  useHsn: UseHsn;
};

export function renderHsn({
  useHsns,
  usePaginatedHsns,
  useHsn,
}: RenderHsnProps): () => JSX.Element {
  return function Hsn(): JSX.Element {
    const Table = renderTableWithMapperComponent<HsnType, HsnFilterType>();
    const { getAll: getHsns } = useHsns();

    const { syncSet: setHsn } = useHsn();

    useEffect(() => {
      getHsns();
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Hsn',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Hsn',
            behaviour: 'modal',
            modal: {
              title: 'Add Hsn',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveHsn(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddHsnForm();

    const saveHsn = async (form: Partial<HsnType>) => {
      try {
        const finalData = {
          ...form,
        } as HsnType;
        await setHsn(finalData as HsnType);
        toast('Data added sucessfully');
        getHsns();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Description' },
        { name: 'HSN Code' },
        { name: 'GST Rate' },
        { name: 'Chapter' },
        { name: 'Cess' },
        { name: 'Effective From' },
      ],
    ];

    const bodyMapper = (r: HsnType) => {
      const cells: Array<TableCell> = [
        { value: r.name },
        { value: r.description },
        { value: r.hsnCode },
        { value: r.gstRate },
        { value: r.chapter },
        { value: r.cess },
        { value: r.effectiveFrom },
      ];
      return {
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='h-full w-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              useResources={useHsns}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedHsns,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
