import { BOMRes } from '@erp_core/erp-types/dist/modules/order';
import {
  LoadingButton,
  renderModal,
  renderPureHistory,
} from '@erp_core/erp-ui-components';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { OverviewFlow } from '../../../../../components/flow-chart';
import { UserContext } from '../../../../../contexts/user';
import { UseBatchItemAllocate } from '../../../../../hooks/inventory/item/batch/use-batch-item-allocate';
import { UseBatchItemGodowns } from '../../../../../hooks/inventory/item/batch/use-batch-item-godowns';
import { UsePhysicalStock } from '../../../../../hooks/inventory/item/stock/use-physical-stock';
import { UseVirtualStock } from '../../../../../hooks/inventory/item/stock/use-virtual-stock';
import { UseVirtualItemAllocate } from '../../../../../hooks/inventory/purchase/purchase-order/use-allocate-virtual-item';
import { UsePurchaseOrders } from '../../../../../hooks/inventory/purchase/purchase-order/use-purchase-orders';
import { UseBom } from '../../../../../hooks/order/work-order/bom/use-bom';
import { renderPhysicalStock } from '../../bom-register/fragments/physical-stock';
import { renderVirtualStock } from '../../bom-register/fragments/virtual-stock';
import {
  CommonAllocateFormRequestType,
  CommonAllocationForm,
} from '../../forms/common-allocation';
import { createBOMFlowGraph } from './utils/get-bom-node-flow';

export type RenderBomProfileProps = {
  id: string;
  useBom: UseBom;
  usePhysicalStock: UsePhysicalStock;
  useVirtualStock: UseVirtualStock;
  useBatchItemGodowns: UseBatchItemGodowns;
  useBatchGodownItemAllocate: UseBatchItemAllocate;
  usePurchaseOrders: UsePurchaseOrders;
  useVirtualItemAllocate: UseVirtualItemAllocate;
};

export function renderBomProfile({
  id,
  useBom,
  usePhysicalStock,
  useVirtualStock,
  useBatchItemGodowns,
  useBatchGodownItemAllocate,
  usePurchaseOrders,
  useVirtualItemAllocate,
}: RenderBomProfileProps): () => JSX.Element {
  const History = renderPureHistory();

  return function BomProfile() {
    const { data, get: getBom, syncSet: setBom } = useBom();
    const { data: physicalStock, get: getPhysicalStock } = usePhysicalStock();
    const { data: virtualStock, get: getVirtualStock } = useVirtualStock();
    const { user: currentUser } = useContext(UserContext);
    const [comment, setComment] = useState('');

    const psUnallocated = physicalStock ? physicalStock.unAllocated : 0;

    const vsUnallocated = virtualStock ? virtualStock.unAllocated : 0;

    useEffect(() => {
      getBom(id);
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (data.rawItem?.id) {
        getPhysicalStock(data.rawItem.id);
        getVirtualStock(data.rawItem.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.id]);

    const [modalState, setModalState] = useState<{
      visible: boolean;
      content: JSX.Element;
      title: string;
    }>({
      visible: false,
      content: <></>,
      title: '',
    });

    const PhysicalStock = renderPhysicalStock({ usePhysicalStock });
    const VirtualStock = renderVirtualStock({ useVirtualStock });

    const handleSaveAllocation = () => {
      setModalState((ms) => ({ ...ms, visible: false }));
      toast('Allocated Sucessfully');
    };

    const sendComment = async () => {
      if (!comment || !data || !data.id || !currentUser) {
        return;
      }
      await setBom({
        id: data.id,
        details: {
          activity: [
            {
              time: moment().utc().format(),
              event: comment.trim(),
              action: 'comment',
              user: currentUser,
            },
            ...(data.details.activity || []),
          ],
        } as any,
      } as BOMRes);
      setComment('');
      toast('Comment sent successfully');
      getBom(id);
    };

    const handlePhysicalAllocation = () => {
      const req: CommonAllocateFormRequestType = {
        id: data.id,
        name: data.name,
        rawItem: {
          id: data.rawItem.id,
          name: data.rawItem.name,
          quantity: data.details.rawMaterial.quantity,
          uom: data.details.rawMaterial.uom,
        },
      };

      setModalState({
        visible: true,
        title: 'Allocate Physically',
        content: (
          <CommonAllocationForm
            usePurchaseOrders={usePurchaseOrders}
            useBatchItemGodowns={useBatchItemGodowns}
            bomData={req}
            useAllocationResource={useBatchItemGodowns}
            onSave={handleSaveAllocation}
            onCancel={() => setModalState((ms) => ({ ...ms, visible: false }))}
            useAllocate={useBatchGodownItemAllocate}
            allocationType='physical'
            allocationResourceFilter={{
              item_id: req.rawItem.id,
              reference_id: 'null',
            }}
            allocationResourceValueFn={(
              resourceArray: Array<any>,
              i: any
            ): { id: string; quantity: number } => {
              return {
                id: resourceArray[i].id,
                quantity: 0,
              };
            }}
            allocationResourceQtyFn={(
              resourceArray: any[],
              idx: any
            ): number => {
              return resourceArray[idx].quantity;
            }}
          />
        ),
      });
    };

    const handleVirtualAllocation = () => {
      const req: CommonAllocateFormRequestType = {
        id: data.id,
        name: data.name,
        rawItem: {
          id: data.rawItem.id,
          name: data.rawItem.name,
          quantity: data.details.rawMaterial.quantity,
          uom: data.details.rawMaterial.uom,
        },
      };

      setModalState({
        visible: true,
        title: 'Allocate Virtually',
        content: (
          <CommonAllocationForm
            usePurchaseOrders={usePurchaseOrders}
            useBatchItemGodowns={useBatchItemGodowns}
            useAllocationResource={usePurchaseOrders}
            bomData={req}
            useAllocate={useVirtualItemAllocate}
            onSave={handleSaveAllocation}
            onCancel={() => setModalState((ms) => ({ ...ms, visible: false }))}
            allocationType='virtual'
            allocationResourceFilter={{
              itemId: req.rawItem.id,
              referenceId: 'null',
            }}
            allocationResourceValueFn={(resourceArray: any[], i: any) => {
              return {
                id: resourceArray[i].purchaseOrderItems[0].id,
                quantity: 0,
              };
            }}
            allocationResourceQtyFn={(resourceArray: any[], i: any) => {
              return resourceArray[i].purchaseOrderItems[0].quantity;
            }}
          />
        ),
      });
    };

    const Modal = renderModal();

    return (
      <div>
        <div className='w-full h-48 border border-gray-200 mx-auto'>
          <OverviewFlow data={data} getFlowGraph={createBOMFlowGraph} />
        </div>
        <div className='w-full p-4'>
          <div className='border border-gray-200 rounded-lg p-2'>
            <h2 className='text-xl'>Stock Details of {data.rawItem?.name}</h2>
            <div className='border border-gray-200 my-2'>
              <div className='flex'>
                <div className='flex-none w-2/8 h-auto'>
                  <div className='text-4xl bg-slate-100 p-2 h-full'>
                    {psUnallocated}
                  </div>
                </div>
                <div className='grow p-2'>
                  <div className='text-bold text-xl'>
                    Total Physical Stock Available
                  </div>
                  <div>
                    {data.rawItem ? (
                      <div className='text-gray-800'>
                        <PhysicalStock
                          className={'inline text-left italic'}
                          id={data.rawItem.id}
                          uom={data.details.rawMaterial?.uom || 'un'}
                          view='combo'
                        />
                        of Total Physical {data.rawItem.name} Stock is Allocated
                        / Unallocated to open BOMs
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='flex-none p-4'>
                  {psUnallocated > 0 ? (
                    <LoadingButton
                      defaultStyle='bg-blue-600 text-white border rounded p-2'
                      behaviorFn={async () => handlePhysicalAllocation()}
                      text='Allocate'
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <Modal
              isVisible={modalState.visible}
              title={modalState.title}
              body={modalState.content}
              onClose={() => setModalState((ms) => ({ ...ms, visible: false }))}
            />
            <div className='border border-gray-200 my-2'>
              <div className='flex'>
                <div className='flex-none w-2/8 h-auto'>
                  <div className='text-4xl bg-slate-100 p-2 h-full'>
                    {vsUnallocated}
                  </div>
                </div>
                <div className='grow p-2'>
                  <div className='text-bold text-xl'>
                    Total Virtual Stock Available
                  </div>
                  <div>
                    {data.rawItem ? (
                      <div className='text-gray-800'>
                        <VirtualStock
                          className={'inline text-left italic'}
                          id={data.rawItem.id}
                          uom={data.details.rawMaterial?.uom || 'un'}
                          view='combo'
                        />
                        of Total Virtual {data.rawItem.name} Stock is Allocated
                        / Unallocated from open Purchase Orders
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='flex-none p-4'>
                  {vsUnallocated > 0 ? (
                    <LoadingButton
                      defaultStyle='bg-blue-600 text-white border rounded p-2'
                      behaviorFn={async () => handleVirtualAllocation()}
                      text='Allocate'
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='px-4 flex justify-between'>
          <input
            type='text'
            className='border w-11/12 rounded p-2'
            placeholder='Add comment...'
            name='comment'
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          <PaperAirplaneIcon
            className='w-10 h-10 p-2 rounded-full bg-blue-600 hover:to-blue-700 font-bold text-white'
            onClick={() => {
              sendComment();
            }}
          />
        </div>
        <div className='w-full p-4'>
          {data.details?.activity ? (
            <History logs={data.details.activity} />
          ) : null}
        </div>
      </div>
    );
  };
}
