import { Revision } from '@erp_core/erp-types/dist/modules/inventory';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { ItemProperty } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import {
  LoadingButton,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useState } from 'react';
import { UseGsts } from '../../../../../../../hooks/admin/constants/gst/use-gsts';
import { UseMetrics } from '../../../../../../../hooks/admin/constants/metrics/use-metrics';

type EditPropertyValueProps = {
  itemProperty: ItemProperty;
  currentValue: IdName;
  onSave: (data: Partial<Revision>) => Promise<void>;
  useResources: string;
};

export type EditPropertyIdNamePropertyValueFormType = {
  currentValue: IdName;
  newValue: IdName;
};

export const renderEditArrayObjectPropertyValue = ({
  useMetrics,
  useGsts,
}: {
  useMetrics: UseMetrics;
  useGsts: UseGsts;
}) => {
  return function EditArrayObjectPropertyValue({
    itemProperty,
    currentValue,
    onSave,
    useResources,
  }: EditPropertyValueProps) {
    // const useSearchResource = (useResources: string) => {
    //   switch (useResources) {
    //     case 'useMetrics':
    //       return useMetrics;
    //     case 'useGST':
    //       return useGsts;
    //     default:
    //       return null;
    //   }
    // };

    const Table = renderTableComponent();

    // const searchResource = useSearchResource(useResources);
    // if (!searchResource) {
    //   return <></>;
    // }

    const [newValues, setNewValues] = useState<any[]>(
      currentValue && Array.isArray(currentValue) ? [...currentValue] : []
    );

    function getPropertyEditor(
      itemProperty: ItemProperty,
      key: string,
      val: any
    ): JSX.Element {
      if (
        itemProperty.childrenListProperties &&
        itemProperty.childrenListProperties[key].name &&
        ['input', 'number'].includes(
          itemProperty.childrenListProperties[key].type
        )
      ) {
        return (
          <input
            className='border-gray-100 p-1'
            value={val[itemProperty.childrenListProperties[key].name] || ''}
            onChange={(evt) => {
              if (evt.target.value) {
                if (itemProperty.childrenListProperties) {
                  val[itemProperty.childrenListProperties[key].name] =
                    evt.target.value;
                  setNewValues((v) => [...v]);
                }
              }
            }}
          />
        );
      }

      return <></>;
    }

    let currentList: any = JSON.parse(JSON.stringify(currentValue)) || [];
    if (!Array.isArray(currentList)) {
      currentList = [];
    }
    const TableBody: TableBody =
      itemProperty.childrenListProperties &&
      _.keys(itemProperty.childrenListProperties).length &&
      newValues.length
        ? newValues.map((data, idx) => {
            return {
              cells: _.keys(itemProperty.childrenListProperties).map((clp) => {
                if (itemProperty.childrenListProperties) {
                  return {
                    value: (
                      <div>
                        <div>
                          New Value:{' '}
                          {getPropertyEditor(itemProperty, clp, data)}
                        </div>
                      </div>
                    ),
                  };
                }
                return {
                  value: <>-</>,
                };
              }),
            };
          })
        : [];

    return (
      <div>
        <div className='flex'>
          <div className='flex-auto text-center'>
            Edit Property {itemProperty.name}
          </div>
          <div className='flex-none'>
            <LoadingButton
              defaultStyle='bg-green-500 text-white'
              behaviorFn={async () => {
                setNewValues((nv) => [...nv, ...[{}]]);
              }}
              text='Add'
            />
          </div>
        </div>

        <Table
          header={[
            [
              {
                colSpan: (_.keys(itemProperty.childrenListProperties) || [])
                  .length,

                name: 'Properties',
                style: 'text-center',
              },
            ],
            (_.keys(itemProperty.childrenListProperties) || []).map((prop) => {
              return {
                name: prop,
                style: 'text-center',
              };
            }),
          ]}
          body={TableBody}
        />

        <div className='flex justify-center'>
          <LoadingButton
            behaviorFn={async () =>
              await onSave(({
                changeRequest: {
                  currentValue: {
                    value: {
                      data: currentValue,
                    },
                  },
                  newValue: {
                    value: {
                      data: newValues,
                    },
                  },
                },
              } as unknown) as Revision)
            }
            defaultStyle='bg-green-500 text-white p-1'
            text='Save'
          />
        </div>
      </div>
    );
  };
};
