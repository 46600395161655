import {
  PolicyFilter,
  PolicyType,
  PremiumsFilter,
  PremiumsType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { PolicyHttpRepo } from '../datalayer/repository/http/hrd/insurance/policy';
import { PremiumsHttpRepo } from '../datalayer/repository/http/hrd/insurance/premiums';
import { createUseResource } from '../hooks/resource/use-resource';
import { createUseResources } from '../hooks/resource/use-resources';
import { PolicyInterface } from '../models/interfaces/hrd/insurance/policy';
import { PremiumsInterface } from '../models/interfaces/hrd/insurance/premiums';
import { erpV2Api } from './admin';
import { INSURANCE_SERVER_URI } from './server-uri';

export const insurancePolicyService = new PolicyHttpRepo(
  INSURANCE_SERVER_URI,
  erpV2Api
);
export const usePolicy = createUseResource<
  PolicyType,
  PolicyFilter,
  PolicyInterface
>({
  useService: () => insurancePolicyService,
});

export const usePolicies = createUseResources<
  PolicyType,
  PolicyFilter,
  PolicyInterface
>({
  useService: () => insurancePolicyService,
});

export const insurancePremiumService = new PremiumsHttpRepo(
  INSURANCE_SERVER_URI,
  erpV2Api
);
export const usePremium = createUseResource<
  PremiumsType,
  PremiumsFilter,
  PremiumsInterface
>({
  useService: () => insurancePremiumService,
});

export const usePremiums = createUseResources<
  PremiumsType,
  PremiumsFilter,
  PremiumsInterface
>({
  useService: () => insurancePremiumService,
});
