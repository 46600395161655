import { BullHorn } from '@erp_core/erp-icons/icons/web/bullhorn';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseNotificationAction } from '../../../hooks/admin/notification-admin/use-authorization';
import { UseNotificationActions } from '../../../hooks/admin/notification-admin/use-authorizations';
import { UseRoles } from '../../../hooks/admin/role-admin/use-roles-admin';
import { renderEditNotificationActionForm } from './edit-notification-form';

type RenderNotificationAction = {
  useNotificationActions: UseNotificationActions;
  useNotificationAction: UseNotificationAction;
  useRoles: UseRoles;
};
export const renderNotificationAction = ({
  useNotificationAction,
  useNotificationActions,
  useRoles,
}: RenderNotificationAction) => {
  return function NotificationAction() {
    const { data, getAll: getNotifications } = useNotificationActions();
    const { delete: deleteNotificationAdmin } = useNotificationAction();

    useEffect(() => {
      getNotifications();
      // eslint-disable-next-line
    }, []);

    const EditNotificationActionForm = renderEditNotificationActionForm({
      useNotificationAction,
      useNotificationActions,
      useRoles,
    });
    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Notification Actions',
      icon: (
        <BullHorn className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Notification',
            behaviour: 'modal',
            modal: {
              title: 'Add Notification Action',
              content: ({ onClose }) => {
                const na: any = {
                  id: '',
                  name: '',
                  roles: [],
                };

                return (
                  <EditNotificationActionForm
                    notificationAction={na}
                    onClose={() => {
                      getNotifications();
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };
    const Table = renderTableComponent();
    const tableHeader: TableHeader = [[{ name: 'Name' }, { name: 'Roles' }]];

    const tableBody: TableBody = data
      ? data.map((n) => ({
          rowData: { notificationAction: n },
          cells: [
            { value: n.name },
            {
              value: (
                <div>
                  {n.roles.map((r) => (
                    <>{r.name}, </>
                  ))}
                </div>
              ),
            },
          ],
        }))
      : [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Edit',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit',
                    content: ({ data, onClose }) => {
                      return (
                        <EditNotificationActionForm
                          notificationAction={data.notificationAction}
                          onClose={() => {
                            getNotifications();
                            onClose();
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Delete',
                  show: () => true,
                  behaviour: 'confirm',
                  onConfirm: ({ notificationAction }) => {
                    return {
                      title: `Are you sure you want to delete this Notification ${notificationAction.name}? This cannot be undone.`,
                      type: 'warning',
                      onConfirm: async () => {
                        deleteNotificationAdmin(notificationAction.id);
                        getNotifications();
                      },
                    };
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
};
