/* eslint-disable react-hooks/exhaustive-deps */
import { ApprovedBadge } from '@erp_core/erp-icons/icons/web/approved-badge';
import { RejectedBadge } from '@erp_core/erp-icons/icons/web/rejected-badge';
import { Vendor } from '@erp_core/erp-types/dist/modules/order';
import {
  LoadingButton,
  renderCardComponent,
} from '@erp_core/erp-ui-components';

import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import {
  renderTableComponent,
  TableBody,
  TableHeader,
  UnderConstruction,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseVendors } from '../../../../../hooks/inventory/purchase/vendors/use-vendors';
import { UseClients } from '../../../../../hooks/order/sales/client/use-clients';

type RenderVendorProps = {
  hooks: {
    useVendors: UseVendors;
    useClients: UseClients;
  };
  itemId: string;
};

/* eslint-disable no-unused-vars */
export function renderVendorTab({
  itemId,
  hooks: { useVendors, useClients },
}: RenderVendorProps) {
  return function () {
    const Card = renderCardComponent();

    const { data: vendors, getAll: getAllVendors } = useVendors();
    const { data: clients, getAll: getAllClients } = useClients();

    const [vendorApprovalType, setVendorApprovalType] = useState<
      'approved' | 'unapproved' | 'all'
    >('all');
    const [finalVendors, setFinalVendors] = useState<Vendor[]>();

    useEffect(() => {
      if (vendorApprovalType === 'all') {
        setFinalVendors(vendors);
      }
      if (vendorApprovalType === 'approved') {
        const res = vendors?.filter((v) => v.items[0].approved === true);
        setFinalVendors(res);
      }
      if (vendorApprovalType === 'unapproved') {
        const res = vendors?.filter((v) => v.items[0].approved === false);
        setFinalVendors(res);
      }
    }, [vendorApprovalType]);

    useEffect(() => {
      getAllVendors({
        itemId: itemId,
      });
      getAllClients({
        itemId: itemId,
      });
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      setFinalVendors(vendors);
    }, [vendors, clients]);

    const Table = renderTableComponent();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const vendorHeader: TableHeader = [
      [
        { name: 'Vendor Name' },
        { name: 'Description' },
        { name: 'Status', colSpan: 3 },
      ],
    ];
    const venderBody: TableBody = (finalVendors || []).map((v) => {
      return {
        cells: [
          { value: v.name },
          { value: v.description },
          {
            value: (
              <div className='p-1'>
                {v.items[0]?.approved ? (
                  <span className='border border-green-600 rounded-md px-2 py-1 text-sm text-green-600'>
                    <ApprovedBadge className='inline w-5 text-green-600' />
                    <span className='capitalize'>approved</span>
                  </span>
                ) : (
                  <span className='border border-red-600 rounded-md px-2 py-1 text-sm text-red-600'>
                    <RejectedBadge className='inline w-5 text-red-600' />
                    <span className='capitalize'>unapproved</span>
                  </span>
                )}
              </div>
            ),
            style: 'text-center',
          },
        ],
      };
    });

    const customerHeader: TableHeader = [
      [{ name: 'Customer Name' }, { name: 'Status', colSpan: 3 }],
    ];
    const customerBody: TableBody = (clients || []).map((client) => {
      return {
        cells: [
          { value: client.name },
          {
            value: (
              <div className='p-1'>
                {client.items[0].status === 'approved' ? (
                  <span className='border border-green-600 rounded-md px-2 py-1 text-sm text-green-600'>
                    <ApprovedBadge className='inline w-5 text-green-600' />
                    <span className='capitalize'>
                      {client.items[0].status || ''}
                    </span>
                  </span>
                ) : (
                  <span className='border border-red-600 rounded-md px-2 py-1 text-sm text-red-600'>
                    <RejectedBadge className='inline w-5 text-red-600' />
                    <span className='capitalize'>
                      {client.items[0].status || ''}
                    </span>
                  </span>
                )}
              </div>
            ),
            style: 'text-center',
          },
        ],
      };
    });

    return (
      <>
        <div className='space-y-3'>
          <Card
            header={{
              title: 'Vendors List',
              actions: [
                {
                  type: 'button',
                  button: {
                    behaviour: 'modal',
                    name: 'Add Vendor',
                    modal: {
                      title: 'Add vendor',
                      content: () => <div>{UnderConstruction()}</div>,
                    },
                  },
                },
              ],
            }}
            body={{
              type: 'jsx-component',
              body: vendors ? (
                <div>
                  <div>
                    <LoadingButton
                      defaultStyle={`p-2 border rounded-lg mx-1 mb-4 ${
                        vendorApprovalType === 'all' ? 'bg-blue-300' : ''
                      }`}
                      behaviorFn={async () => setVendorApprovalType('all')}
                      text='All'
                    />
                    <LoadingButton
                      defaultStyle={`p-2 border rounded-lg mx-1 mb-4 ${
                        vendorApprovalType === 'approved' ? 'bg-blue-300' : ''
                      }`}
                      behaviorFn={async () => setVendorApprovalType('approved')}
                      text='Approved'
                    />
                    <LoadingButton
                      defaultStyle={`p-2 border rounded-lg mx-1 mb-4 ${
                        vendorApprovalType === 'unapproved' ? 'bg-blue-300' : ''
                      }`}
                      behaviorFn={async () =>
                        setVendorApprovalType('unapproved')
                      }
                      text='Unapproved'
                    />
                  </div>
                  <Table header={vendorHeader} body={venderBody} />
                </div>
              ) : (
                <Recat className='h-5 inline animate-pulse mx-4' />
              ),
            }}
          />
          <Card
            header={{
              title: 'Customers List',
            }}
            body={{
              type: 'jsx-component',
              body: clients ? (
                <Table header={customerHeader} body={customerBody} />
              ) : (
                <Recat className='h-5 inline animate-pulse mx-4' />
              ),
            }}
          />
        </div>
      </>
    );
  };
}
