/* eslint-disable no-unused-vars */
import { ActionType } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseActionAdmin } from '../../../hooks/admin/action-admin/use-action-admin';

export type RenderEditActionFormProps = {
  useActionAdmin: UseActionAdmin;
  closeEditModal: () => void;
};

export function renderEditActionForm({
  useActionAdmin,
  closeEditModal,
}: RenderEditActionFormProps): (ActionType) => JSX.Element {
  return function EditActionForm({
    r,
    onClose,
  }: {
    r: ActionType;
    onClose: () => void;
  }): JSX.Element {
    const { syncSet: setActionAdmin } = useActionAdmin();

    const saveAction = async (form) => {
      if (r.id) form['id'] = r.id;
      await setActionAdmin({ ...form });
      closeEditModal();
      onClose();
    };

    const formProps: FormProps<Partial<ActionType>> = {
      fieldsData: [
        { property: 'name', label: 'Action Name', type: 'input' },
        { property: 'description', label: 'Action Description', type: 'input' },
      ],
      initialFormState: {
        name: r.name,
        description: r.description,
      },
      mapTToU: mapPFDToP,
      style: '',
      onSubmit: saveAction,
    };

    const Form = renderFormV2<Partial<ActionType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<ActionType>>
    ): Partial<ActionType> {
      return (b as unknown) as ActionType;
    }
  };
}
