import { Toaster } from 'react-hot-toast';

import { RoutesComponent } from './routes';

import { Config } from './models/interfaces/common/app-config';

export function App({ config }: { config: Config }): JSX.Element {
  return (
    <main className='h-full max-h-screen min-h-screen bg-indigo-900'>
      <RoutesComponent config={config} />
      <Toaster
        position='bottom-right'
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
          },
        }}
      />
    </main>
  );
}
