/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../contexts/current';
import { UserContext } from '../../../contexts/user';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseClosureDatePeriod } from '../../../hooks/quality-assurance/closure-date-period/use-closure-date-period';
import { UseClosureDatePeriods } from '../../../hooks/quality-assurance/closure-date-period/use-closure-date-periods';
import { ClosureDatePeriodType } from '../../../models/types/quality-assurance/closure-date-period';
import { UserRendererInterface } from '../../common/fragments/user';
import { renderAddClosureDatePeriodForm } from './form/add-closure-date-period';

type RenderClosureDatePeriod = {
  useClosureDatePeriods: UseClosureDatePeriods;
  useClosureDatePeriod: UseClosureDatePeriod;
  useUserAuthorization: UseUserAuthorization;
  userRendererService: UserRendererInterface;
};
export const renderClosureDatePeriod = ({
  useClosureDatePeriods,
  useClosureDatePeriod,
  useUserAuthorization,
  userRendererService,
}: RenderClosureDatePeriod) => {
  return function ClosureDatePeriod() {
    const {
      data: closureDatePeriods,
      getAll: getClosureDatePeriods,
    } = useClosureDatePeriods();

    const { syncSet: setClosureDatePeriod } = useClosureDatePeriod();

    const { get: getAllowedActions } = useUserAuthorization();

    useEffect(() => {}, [getClosureDatePeriods]);

    const { company, companyGroup } = useContext(CurrentContext);
    const { user: currentUser } = useContext(UserContext);

    useEffect(() => {
      getClosureDatePeriods();
      // eslint-disable-next-line
    }, []);

    console.log('closureDatePeriods', closureDatePeriods);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Closure Date Period',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Closure Date Period',
            behaviour: 'modal',
            modal: {
              title: 'Add Closure Date Period',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      saveClosureDatePeriod(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddClosureDatePeriodForm();

    const saveClosureDatePeriod = async (
      form: Partial<ClosureDatePeriodType>
    ) => {
      try {
        const finalData = {
          ...form,
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          company: {
            id: company.id,
            name: company.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as ClosureDatePeriodType;
        await setClosureDatePeriod(finalData as ClosureDatePeriodType);
        toast('Data added sucessfully');
        getClosureDatePeriods();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Change Control Requisition Type' },
        { name: 'Period (in days)' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'Edit',
          show: ({
            closureDatePeriod,
          }: {
            closureDatePeriod: ClosureDatePeriodType;
          }) => {
            // we want to give ability to edit only when the gatepass
            // is in pending state.

            return true;
          },
          behaviour: 'modal',
          modal: {
            title: 'Edit',
            content: ({
              data: { closureDatePeriod },
              onClose,
            }: {
              data: {
                closureDatePeriod: ClosureDatePeriodType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <Form
                    onSave={async (e) => {
                      await onEditClosureDatePeriod(e, closureDatePeriod.id);
                      onClose();
                    }}
                    data={closureDatePeriod as any}
                  />
                </div>
              );
            },
          },
        },
      ];
    }

    const onEditClosureDatePeriod = async (
      form: ClosureDatePeriodType,
      id: string
    ) => {
      const finalData = {
        id,
        type: form?.type || '',
        days: form?.days || '',
      } as ClosureDatePeriodType;
      await setClosureDatePeriod(finalData);
      toast('Closure date period edited sucessfully');
      getClosureDatePeriods();
    };

    const tableBody: TableBody =
      closureDatePeriods?.map((l, idx) => ({
        rowData: {
          closureDatePeriod: l,
        },
        cells: [
          { value: l.type },
          { value: l.days },
          {
            value: (
              <userRendererService.userCard
                size='small'
                link={true}
                id={l.createdBy?.id}
                name={l.createdBy?.name}
                extraInfo={moment.utc(l.createdAt).fromNow()}
              />
            ),
          },
          {
            value: (
              <userRendererService.userCard
                size='small'
                link={true}
                id={l.lastModifiedBy?.id}
                name={l.lastModifiedBy?.name}
                extraInfo={moment.utc(l.createdAt).fromNow()}
              />
            ),
          },
        ],
      })) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={addActions()}
              auth={{ actions: getAllowedActions().actions }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <div>
          <Card header={cardHeader} body={cardBody} />
        </div>
      </>
    );
  };
};
