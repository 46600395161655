import { VendorLocation } from '@erp_core/erp-types/dist/modules/order';
import { VouchersType } from '@erp_core/erp-types/dist/types/modules/accounts/vouchers';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { Grade } from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import {
  LoadingButton,
  renderCardComponent,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useContext, useState } from 'react';
import { CurrentContext } from '../../../../../contexts/current';
import { UseGrades } from '../../../../../hooks/inventory/grade/use-grades';
import { UseItemCategories } from '../../../../../hooks/inventory/item/item-category/use-item-categories';
import { UseClient } from '../../../../../hooks/order/sales/client/use-client';
import { UseClients } from '../../../../../hooks/order/sales/client/use-clients';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';

type ItemType = {
  grade: Grade;
  quantity: number;
  rate: number;
  uom: string;
  discountPercent: number;
  deliverySchedule: Array<{
    batchNumber: number;
    date: string;
    quantity: number;
  }>;
  amount: number;
  gst: number;
};

export type SOVoucher = {
  name: string;
  voucherNumber: string;
  itemCategory: IdName;
  date: string;
  referenceNumber: string;
  client: IdName;
  clientAddress: VendorLocation;
  paymentTerms: Array<{
    percent: number;
    paymentType: string;
    noOfDays: number;
  }>;
  salesLedger: IdName;
  items: Array<ItemType>;
  transportationPaidBy: 'seller' | 'buyer' | 'unknown';
  transportationCost: '' | 'part-of-invoice' | 'separate-invoice';
  total: number;
  remarks: string;
};

export function renderSalesOrder({
  useClients,
  useClient,
  useItemCategories,
  useGrades,
  itemService,
}: {
  useClients: UseClients;
  useClient: UseClient;
  useGrades: UseGrades;
  useItemCategories: UseItemCategories;
  itemService: ItemInterface;
}) {
  return function SalesOrderVoucher({
    so,
    onSave,
  }: {
    so?: VouchersType;
    onSave(s: VouchersType): Promise<void>;
  }) {
    const [voucher, setVoucher] = useState<SOVoucher>(
      so ? (so.details as SOVoucher) : ({} as SOVoucher)
    );
    const [editBasicDetails, setEditBasicDetails] = useState<boolean>(false);

    const { location } = useContext(CurrentContext);

    const Form1 = renderFormV2({
      formName: 'SO Details',
      initialFormState: {
        name: voucher.name || '',
        voucherNumber: voucher.voucherNumber || '',
        itemCategory: voucher.itemCategory || { id: '', name: '' },
        date: voucher.date || '',
        referenceNumber: voucher.referenceNumber || '',
        client: voucher.client || { id: '', name: '' },
        remarks: voucher.remarks || '',
        transportationPaidBy: voucher.transportationPaidBy || '',
        transportationCost: voucher.transportationCost || '',
      },
      style: 'w-1/6',
      fieldsData: [
        {
          property: 'name',
          label: 'SO Number',
          type: 'input',
          required: true,
        },
        {
          property: 'referenceNumber',
          type: 'input',
          required: true,
        },
        {
          property: 'date',
          type: 'date',
          required: true,
        },
        {
          property: 'voucherNumber',
          type: 'input',
          required: true,
        },
        {
          property: 'itemCategory',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useItemCategories,
            onSearchValueSelect(u) {},
          },
          required: true,
        },
        {
          property: 'client',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useClients,
            onSearchValueSelect(u) {},
          },
          required: true,
        },
        {
          property: 'transportationPaidBy',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Seller', value: 'seller' },
            { text: 'Buyer', value: 'buyer' },
            { text: 'Unknown', value: 'unknown' },
          ],
          required: true,
        },
        {
          property: 'transportationCost',
          type: 'select',
          dependentOn: (formData) => {
            return formData.transportationPaidBy === 'buyer';
          },
          options: [
            { text: 'Select', value: '' },
            { text: 'Part of Invoice', value: 'part-of-invoice' },
            { text: 'Separate Invoice', value: 'separate-invoice' },
          ],
        },
        {
          property: 'remarks',
          type: 'input',
        },
      ],
      mapTToU: (x) => x,
      onSubmit: async (form) => {
        console.log(form);
        let clientAddress: any = voucher.clientAddress || undefined;
        let clientPaymentTerms: any = voucher.paymentTerms;
        if (form.client) {
          clientAddress = undefined;
        }
        setVoucher((x) => ({
          ...x,
          ...{ clientAddress, paymentTerms: clientPaymentTerms },
          ...(form as any),
        }));
        setEditBasicDetails(false);
      },
    });
    const Card = renderCardComponent();
    return (
      <div>
        {editBasicDetails ? (
          <Form1 />
        ) : (
          <Card
            header={{
              title: 'Sales Order Details',
              actions: [
                {
                  type: 'button',
                  button: {
                    name: 'Edit',
                    behaviour: 'click',
                    onClick: async () => {
                      setEditBasicDetails(true);
                    },
                  },
                },
              ],
            }}
            body={{
              type: 'columns',
              body: [
                [
                  { key: 'SO Number', value: voucher.name },
                  { key: 'Reference #', value: voucher.referenceNumber },
                ],
                [
                  { key: 'Date', value: voucher.date },
                  { key: 'Voucher #', value: voucher.voucherNumber },
                ],
                [
                  { key: 'Item Category', value: voucher.itemCategory?.name },
                  { key: 'Client', value: voucher.client?.name },
                ],
                [
                  {
                    key: 'Transportation Paid By',
                    value:
                      voucher.transportationPaidBy === 'buyer'
                        ? `${voucher.transportationPaidBy} (${voucher.transportationCost})`
                        : voucher.transportationPaidBy,
                  },
                  { key: 'Remarks', value: voucher.remarks },
                ],
              ],
            }}
          />
        )}

        <div className='flex flex-wrap'>
          <div className='w-1/3'>
            <Card
              header={{ title: `${location.company?.name} Details` }}
              body={{
                type: 'jsx-component',
                body: (
                  <div>
                    <div>
                      {location.details?.invoiceTo?.address?.addressLine1},{' '}
                      {location.details?.invoiceTo?.address?.addressLine2}
                    </div>
                    <div>
                      {location.details?.invoiceTo?.address.city} -{' '}
                      {location.details?.invoiceTo?.address.pincode},{' '}
                      {location.details?.invoiceTo?.address.district}
                    </div>
                    <div>
                      {location.details?.invoiceTo?.address.state},{' '}
                      {location.details?.invoiceTo?.address.country}
                    </div>
                  </div>
                ),
              }}
            />
          </div>
        </div>

        <LoadingButton
          text={'Save'}
          behaviorFn={async () => {
            await onSave({
              id: so?.id || '',
              dateTime: voucher.date,
              name: voucher.name,
              description: '',
              details: voucher,
              voucherType: 'sales-order',
            } as VouchersType);
          }}
        />
      </div>
    );
  };
}
