import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import {
  CardBody,
  CardHeader,
  LoadingButton,
  renderCardComponent,
  renderFileViewerUploader,
  renderTableWithMapperComponent,
  TableActionsType,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../contexts/current';
import { UserContext } from '../../../contexts/user';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UseDepartments } from '../../../hooks/hrd/department/use-departments';
import { UseEmployeeProfiles } from '../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseChangeAssesment } from '../../../hooks/quality-assurance/change-assesment/use-change-assesment';
import { UseChangeAssesments } from '../../../hooks/quality-assurance/change-assesment/use-change-assesments';
import { UseClosureDatePeriods } from '../../../hooks/quality-assurance/closure-date-period/use-closure-date-periods';
import {
  ChangeAssesmentFilterType,
  ChangeAssesmentType,
} from '../../../models/types/quality-assurance/change-assesment';
import { renderAcknowledgeChangeControl } from './component/acknowledge';
import { renderAckApproveRejectForm } from './forms/acknowledgeApp';
import { renderActivitiesResponsibilities } from './forms/activities-responsibilities';
import { renderAddChangeControlReqForm } from './forms/add-change-control-req';
import { renderClassificationOfchangeForm } from './forms/classification-of-change';
import { renderClosureControlForm } from './forms/closure-of-control';
import { renderSelectionCrossFunctionalTeamForm } from './forms/cross-functional-teams';
import { renderCustomerCommentsForm } from './forms/customer-comments';
import { renderAddDeptCommentsForm } from './forms/dept-comments';
import { renderEffectivenessEvaluationForm } from './forms/effectiveness-after-closure';
import { renderExpertOpinionForm } from './forms/expert-opinion';
import { renderExtensionForm } from './forms/extention-form';
import { renderAddImplementationCompletionForm } from './forms/implementation-completion-activities-docs';
import { renderAddIntimationDeptCommentsForm } from './forms/intimation-dept-comments';
import { renderManagementCommentsForm } from './forms/management-committee-comments';
import { renderQaApprovalForm } from './forms/qa-final-approval';
import { renderAddStorgedCopiesForm } from './forms/soft-copies-storage';
import { renderRegularizingChangeForm } from './forms/temporary-regulaizing-change';

type RenderChangeControlReqProps = {
  useChangeAssesments: UseChangeAssesments;
  useChangeAssesment: UseChangeAssesment;
  useDepartments: UseDepartments;
  useUserAuthorization: UseUserAuthorization;
  useEmployees: UseEmployeeProfiles;
  useFileTransfer: UseFileTransfer;
  useClosureDatePeriods: UseClosureDatePeriods;
};

export function renderChangeControlReqDetails({
  useChangeAssesments,
  useChangeAssesment,
  useDepartments,
  useUserAuthorization,
  useEmployees,
  useFileTransfer,
  useClosureDatePeriods,
}: RenderChangeControlReqProps): () => JSX.Element {
  return function ChangeControlReqDetails(): JSX.Element {
    const {
      data: changeAssesments,
      getAll: getChangeAssesments,
    } = useChangeAssesments();

    const FileViewerUploader = renderFileViewerUploader();

    const { syncSet: setChangeAssesment } = useChangeAssesment();

    const { get: getAllowedActions } = useUserAuthorization();

    const { user: currentUser } = useContext(UserContext);

    const { data: departments, getAll: getDepartments } = useDepartments();

    const { company, companyGroup } = useContext(CurrentContext);

    useEffect(() => {}, [changeAssesments, departments]);

    const { data: employees, getAll: getEmployees } = useEmployees();

    const currentUserDept = employees?.find((x) => x.id === currentUser.id)
      ?.department?.name;

    type ImplementationActivitiesDocsForm = {
      documents: Array<{
        list: string;
        numberOldDoc: string;
        versionOldDoc: string;
        numberNewDoc: string;
        versionNewDoc: string;
        effectiveDate: string;
      }>;
      responsibleperson: UserIdName;
      qadocs: string;
      date: Date;
    };

    type SoftCopiesStorageForms = {
      softcopies: Array<{
        docname: string;
        docno: string;
        docversion: string;
        storageloc: string;
        storedby: string;
        verifiedby: UserIdName;
      }>;
    };

    useEffect(() => {
      getChangeAssesments();
      getDepartments();
      getEmployees({ crossGroup: 'true' });
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Change Control Requistion',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Change Control Requisition',
            behaviour: 'modal',
            modal: {
              title: 'Add Change Control Requisition',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveChangeControlRequisition(form);
                      onClose();
                    }}
                  />
                );
              },
              size: 'large',
            },
          },
        },
      ],
    };

    const ActivitiesResp = renderActivitiesResponsibilities({
      useChangeAssesment,
      useChangeAssesments,
      useDepartments,
    });

    const AckForm = renderAckApproveRejectForm();

    const ClassificationChangeForm = renderClassificationOfchangeForm({
      useClosureDatePeriods,
    });

    const SlectionCrossTeams = renderSelectionCrossFunctionalTeamForm({
      useDepartments,
      useEmployees,
    });

    const ImpactForm = renderAcknowledgeChangeControl({
      useChangeAssesment,
      useChangeAssesments,
    });

    const DeptComments = renderAddDeptCommentsForm({
      useDepartments,
    });

    const ManagementComments = renderManagementCommentsForm();

    const CustomerComments = renderCustomerCommentsForm({ useFileTransfer });

    const QaApproval = renderQaApprovalForm({ useDepartments });

    const RegularizingChangeForm = renderRegularizingChangeForm();

    const ImplementationCompletionForm = renderAddImplementationCompletionForm({
      useEmployees,
    });

    const SoftCopiesStorageForm = renderAddStorgedCopiesForm({ useEmployees });

    const IntimationDeptComments = renderAddIntimationDeptCommentsForm({
      useDepartments,
    });

    const ClosureControlForm = renderClosureControlForm();

    const EffectivenessEvaluationForm = renderEffectivenessEvaluationForm();

    const ExpertOpinion = renderExpertOpinionForm();

    const Extention = renderExtensionForm();

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'Activities and Responsibilities',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (
              ['evaluation-accepted'].includes(changeAssesment.status) &&
              changeAssesment.details.typeOfChange === 'permanent'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Activities and Responsibilities',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <ActivitiesResp id={changeAssesment.id} onClose={onClose} />
                </div>
              );
            },
          },
        },
        {
          name: 'HOD Approval',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (['draft'].includes(changeAssesment.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'HOD approval',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <Form
                    onSave={async (e) => {
                      await hodApproval(e, changeAssesment.id, changeAssesment);
                      onClose();
                    }}
                    data={changeAssesment as any}
                    isEdit={true}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'QA Comments',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (['pending'].includes(changeAssesment.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'QA Comments',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <AckForm
                    onSave={async (e) => {
                      await ackApproveReject(e, changeAssesment.id);
                      onClose();
                    }}
                    data={changeAssesment as any}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Evaluation',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (['ccf-accepted'].includes(changeAssesment.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Evaluation',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <ImpactForm id={changeAssesment.id} onClose={onClose} />
                </div>
              );
            },
          },
        },
        {
          name: 'Evaluation QA Approval',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (['evaluation'].includes(changeAssesment.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Evaluation QA Approval',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <ImpactForm id={changeAssesment.id} onClose={onClose} />
                </div>
              );
            },
          },
        },
        {
          name: 'Classification Of Change',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (
              ['evaluation-accepted', 'activities'].includes(
                changeAssesment.status
              )
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Classification of Change',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <ClassificationChangeForm
                    onSave={async (e) => {
                      await saveClassificationChange(e, changeAssesment.id);
                      onClose();
                    }}
                    data={changeAssesment as any}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Selection of cross functional team',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (['qacommented-major-minor'].includes(changeAssesment.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Selection of cross functional team',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <SlectionCrossTeams
                    data={changeAssesment}
                    dept={currentUserDept || ''}
                    onSave={async (e) => {
                      await saveCrossFunctionalTeams(e, changeAssesment.id);
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Departmental comments',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (['cross-team-selected'].includes(changeAssesment.status)) {
              if (currentUserDept !== undefined) {
                const CrossfunctionalTeamsRcds =
                  changeAssesment.details?.crossFunctionalTeam;

                for (let i = 0; i < CrossfunctionalTeamsRcds.length; i++) {
                  if (
                    changeAssesment.details?.crossfunctionTeamExpert.id ===
                    currentUser.id
                  ) {
                    return true;
                  }
                  if (
                    CrossfunctionalTeamsRcds[i].dept.name === currentUserDept
                  ) {
                    return true;
                  }
                }
              }
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Departmental comments',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <DeptComments
                    data={changeAssesment}
                    dept={currentUserDept || ''}
                    onSave={async (e) => {
                      await saveDeptComments(
                        e,
                        changeAssesment.id,
                        changeAssesment
                      );
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Management Committee Comments',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (
              ['department-commented', 'expert-commented'].includes(
                changeAssesment.status
              ) &&
              changeAssesment.details.classificationMajorMinor === 'Major'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Management Committee Comments',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <ManagementComments
                    data={changeAssesment}
                    onSave={async (e) => {
                      await saveManagementComments(e, changeAssesment.id);
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Customer Comments',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (
              ['management-commented'].includes(changeAssesment.status) &&
              changeAssesment.details.classificationMajorMinor === 'Major' &&
              changeAssesment.details.typeOfChange === 'permanent'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Customer Comments',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <CustomerComments
                    data={changeAssesment}
                    onSave={async (e) => {
                      await saveCustomerComments(e, changeAssesment.id);
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'QA Review',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (
              ['management-commented', 'customer-commented'].includes(
                changeAssesment.status
              )
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'QA review',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <QaApproval
                    data={changeAssesment}
                    onSave={async (e) => {
                      await saveQaApproval(e, changeAssesment.id);
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Regularizing Change',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (
              ['qa-approved'].includes(changeAssesment.status) &&
              changeAssesment.details.typeOfChange === 'temporary'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Regularizing Change',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <RegularizingChangeForm
                    data={changeAssesment}
                    onSave={async (e) => {
                      await saveRegularizingChange(e, changeAssesment.id);
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Copy To Clipboard',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (
              ['qa-approved'].includes(changeAssesment.status) &&
              changeAssesment.details.typeOfChange === 'permanent'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Copy To Clipboard',
            content: ({ data: { changeAssesment }, onClose }) => {
              return (
                <CopyToClipboard text={changeAssesment.id}>
                  <LoadingButton
                    defaultStyle='font-semibold hover:text-black text-gray-600 w-fit text-xs text-left hover:underline'
                    behaviorFn={async () => {
                      CopyIdtoClipboard();
                      onClose();
                    }}
                    text='Copy to clipboard'
                  />
                </CopyToClipboard>
              );
            },
          },
        },
        {
          name:
            'Implementation - Completion status of activities and documents',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (
              ['qa-approved'].includes(changeAssesment.status) &&
              changeAssesment.details.typeOfChange === 'permanent'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title:
              'Implementation - Completion status of activities and documents',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <ImplementationCompletionForm
                    data={changeAssesment}
                    onSave={async (e) => {
                      await saveImplementationActivitiesDocs(
                        e,
                        changeAssesment.id,
                        changeAssesment
                      );
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Upload Annexure',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the leave
            // is in pending state.
            if (['draft'].includes(changeAssesment.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Upload Annexure',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <FileViewerUploader
                    mode='upload'
                    url={changeAssesment.details.annexure || ''}
                    useFileTransfer={useFileTransfer}
                    path={`${companyGroup.id}/${company.id}/requisition/annexure/${changeAssesment.id}/annexure.pdf`.replaceAll(
                      ' ',
                      '-'
                    )}
                    type='private'
                    onUpload={async (path) => {
                      let annexuredoc = changeAssesment.details.annexure || {};
                      if (annexuredoc) {
                        annexuredoc = path.url;
                        await setChangeAssesment(({
                          id: changeAssesment.id,
                          details: {
                            annexure: annexuredoc,
                          },
                        } as unknown) as ChangeAssesmentType);
                      }
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Storage of soft copies of documents for issuance',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (['activities-completed'].includes(changeAssesment.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Storage of soft copies of documents for issuance',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <SoftCopiesStorageForm
                    changeAssesmentData={changeAssesment}
                    data={changeAssesment}
                    onSave={async (e) => {
                      await saveSoftCopiesStorage(
                        e,
                        changeAssesment.id,
                        changeAssesment
                      );
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Intimation to Related Departments after the change',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (['soft-copies-stored'].includes(changeAssesment.status)) {
              if (currentUserDept !== undefined) {
                const CrossfunctionalTeamsRcds =
                  changeAssesment.details?.crossFunctionalTeam;

                for (let i = 0; i < CrossfunctionalTeamsRcds.length; i++) {
                  if (
                    changeAssesment.details?.crossfunctionTeamExpert.id ===
                    currentUser.id
                  ) {
                    return true;
                  }
                  if (
                    CrossfunctionalTeamsRcds[i].dept.name === currentUserDept
                  ) {
                    return true;
                  }
                }
              }
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Intimation to Related Departments after the change',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <IntimationDeptComments
                    data={changeAssesment}
                    dept={currentUserDept || ''}
                    onSave={async (e) => {
                      await saveIntimationDeptComments(
                        e,
                        changeAssesment.id,
                        changeAssesment
                      );
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Closure of change control',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (
              ['intimation-department-commented'].includes(
                changeAssesment.status
              )
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Closure of change control',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <ClosureControlForm
                    onSave={async (e) => {
                      await saveClosureControl(e, changeAssesment.id);
                      onClose();
                    }}
                    data={changeAssesment as any}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Effectiveness of change after closure',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (
              ['closed'].includes(changeAssesment.status) &&
              changeAssesment.details.typeOfChange === 'permanent'
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Effectiveness of change after closure',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <EffectivenessEvaluationForm
                    onSave={async (e) => {
                      await saveEffectivenessEvaluationForm(
                        e,
                        changeAssesment.id
                      );
                      onClose();
                    }}
                    data={changeAssesment as any}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Expert`s opinion',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (
              ['cross-team-selected', 'department-commented'].includes(
                changeAssesment.status
              ) &&
              changeAssesment.details.classificationMajorMinor === 'Major' &&
              changeAssesment.details?.crossfunctionTeamExpert.id ===
                currentUser.id
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Expert`s opinion',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <ExpertOpinion
                    data={changeAssesment}
                    onSave={async (e) => {
                      await saveExpertOpinion(
                        e,
                        changeAssesment.id,
                        changeAssesment
                      );
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Extention',
          show: ({
            changeAssesment,
          }: {
            changeAssesment: ChangeAssesmentType;
          }) => {
            // we want to give ability to edit only when the revision
            // is in pending state.
            if (['closed'].includes(changeAssesment.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Extention',
            content: ({
              data: { changeAssesment },
              onClose,
            }: {
              data: {
                changeAssesment: ChangeAssesmentType;
              };
              onClose: () => any;
            }) => {
              return (
                <div>
                  <Extention
                    data={changeAssesment}
                    onSave={async (e) => {
                      await saveExtention(
                        e,
                        changeAssesment.id,
                        changeAssesment
                      );
                      onClose();
                    }}
                  />
                </div>
              );
            },
          },
        },
      ];
    }

    const Form = renderAddChangeControlReqForm({
      useDepartments,
      useFileTransfer,
    });

    const ackApproveReject = async (form: any, id: string) => {
      const finalData = {
        id,
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const saveClosureControl = async (form: any, id: string) => {
      const finalData = {
        id,
        ...form,
        status: 'closed',
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const saveEffectivenessEvaluationForm = async (form: any, id: string) => {
      const finalData = {
        id,
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const saveImplementationActivitiesDocs = async (
      formData: ImplementationActivitiesDocsForm,
      id: string,
      changeAssesment: ChangeAssesmentType
    ) => {
      try {
        // console.log('Final Form Data', formData);
        const finalData = ({
          id,
          details: {
            ...changeAssesment?.details,
            implementationActivitiesDocs: {
              documents: formData.documents.map((i) => ({
                list: i.list,
                numberOldDoc: i.numberOldDoc,
                versionOldDoc: i.versionOldDoc,
                numberNewDoc: i.versionOldDoc,
                versionNewDoc: i.versionOldDoc,
                effectiveDate: i.versionOldDoc,
              })),
              responsibleperson: {
                id: formData.responsibleperson,
                name: '',
              },
              qadocs: formData.qadocs,
              date: moment(),
            },
          },
          status: 'activities-completed',
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          company: {
            id: company.id,
            name: company.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as unknown) as ChangeAssesmentType;
        // console.log('finalData',finalData)
        await setChangeAssesment(finalData);
        toast('Data submitted sucessfully');
        getChangeAssesments();
      } catch (err) {
        // console.log('Error');
      }
    };

    const saveSoftCopiesStorage = async (
      formData: SoftCopiesStorageForms,
      id: string,
      changeAssesment: ChangeAssesmentType
    ) => {
      try {
        // console.log('Final Form Data', formData);
        const finalData = ({
          id,
          details: {
            ...changeAssesment?.details,
            softCopiesStorage: {
              softcopies: formData.softcopies.map((i) => ({
                docname: i.docname,
                docno: i.docno,
                docversion: i.docversion,
                storageloc: i.storageloc,
                verifiedby: {
                  id: i.verifiedby,
                  name: '',
                },
                storedby: {
                  id: i.storedby,
                  name: '',
                },
              })),
            },
          },
          status: 'soft-copies-stored',
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          company: {
            id: company.id,
            name: company.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as unknown) as ChangeAssesmentType;
        // console.log('finalData',finalData)
        await setChangeAssesment(finalData);
        toast('Data submitted sucessfully');
        getChangeAssesments();
      } catch (err) {
        // console.log('Error');
      }
    };

    const saveClassificationChange = async (form: any, id: string) => {
      const finalData = {
        id,
        status: 'qacommented-major-minor',
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const saveDeptComments = async (
      form: any,
      id: string,
      changeAssesment: ChangeAssesmentType
    ) => {
      const crossTeamDptRcds = changeAssesment.details.crossFunctionalTeam;
      const deptCommentsRcds = changeAssesment.details.deptComments;
      // let deptFindFlag = false;
      let cnt = 0;
      if (deptCommentsRcds) {
        for (let i = 0; i < crossTeamDptRcds.length; i++) {
          if (
            deptCommentsRcds.find(
              (x) => x.dept.id === crossTeamDptRcds[i].dept.id
            )
          ) {
            // deptFindFlag = true
            cnt++;
          }
        }
      }

      const finalData = {
        id,
        status:
          crossTeamDptRcds.length === cnt
            ? 'department-commented'
            : changeAssesment.status,
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const saveExtention = async (
      form: any,
      id: string,
      changeAssesment: ChangeAssesmentType
    ) => {
      const finalData = {
        id,
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const saveExpertOpinion = async (
      form: any,
      id: string,
      changeAssesment: ChangeAssesmentType
    ) => {
      const finalData = {
        id,
        status: 'expert-commented',
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const saveIntimationDeptComments = async (
      form: any,
      id: string,
      changeAssesment: ChangeAssesmentType
    ) => {
      const crossTeamDptRcds = changeAssesment.details.crossFunctionalTeam;
      const deptCommentsRcds = changeAssesment.details.intimationDeptComments;
      // let deptFindFlag = false;
      let cnt = 0;
      if (deptCommentsRcds) {
        for (let i = 0; i < crossTeamDptRcds.length; i++) {
          if (
            deptCommentsRcds.find(
              (x) => x.dept.id === crossTeamDptRcds[i].dept.id
            )
          ) {
            // deptFindFlag = true
            cnt++;
          }
        }
      }

      const finalData = {
        id,
        status:
          crossTeamDptRcds.length === cnt
            ? 'intimation-department-commented'
            : changeAssesment.status,
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    function showAnnexure(changeAssesment: ChangeAssesmentType) {
      if (changeAssesment.details.annexure) {
        return (
          <div>
            {!changeAssesment.details.annexure
              ? 'Pending Annexure'
              : 'Annexure'}{' '}
            <div>
              <FileViewerUploader
                mode='view'
                url={changeAssesment.details.annexure || ''}
                useFileTransfer={useFileTransfer}
                path={`${companyGroup.id}/${company.id}/requisition/annexure/${changeAssesment.id}/annexure.pdf`.replaceAll(
                  ' ',
                  '-'
                )}
                type='private'
                onUpload={async (path) => {
                  let annexuredoc = changeAssesment.details.annexure || {};
                  if (annexuredoc) {
                    annexuredoc = path.url;
                    await setChangeAssesment(({
                      id: changeAssesment.id,
                      details: {
                        annexure: annexuredoc,
                      },
                    } as unknown) as ChangeAssesmentType);
                  }
                }}
              />
            </div>
          </div>
        );
      }
      return <div></div>;
    }

    const saveManagementComments = async (form: any, id: string) => {
      const finalData = {
        id,
        status: 'management-commented',
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const saveCustomerComments = async (form: any, id: string) => {
      const finalData = {
        id,
        status: 'customer-commented',
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const saveQaApproval = async (form: any, id: string) => {
      const finalData = {
        id,
        status:
          form.details.qaFinalApproval.status === 'accepted'
            ? 'qa-approved'
            : 'qa-rejected',
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const saveRegularizingChange = async (form: any, id: string) => {
      const finalData = {
        id,
        status: 'closed',
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const saveCrossFunctionalTeams = async (form: any, id: string) => {
      const finalData = {
        id,
        status: 'cross-team-selected',
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const CopyIdtoClipboard = () => {
      try {
        toast('Copied to clipboard successfully');
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const hodApproval = async (
      form: any,
      id: string,
      changeAssesment: ChangeAssesmentType
    ) => {
      const finalData = {
        id,
        ...form,
        details: {
          ...changeAssesment?.details,
        },
        status: form.status === 'approved' ? 'pending' : 'hod-rejected',
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };
      console.log('finalData', finalData);
      await setChangeAssesment(finalData);
      toast('Data submitted sucessfully');
      getChangeAssesments();
    };

    const saveChangeControlRequisition = async (
      form: Partial<ChangeAssesmentType>
    ) => {
      try {
        const finalData = {
          ...form,
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          company: {
            id: company.id,
            name: company.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as ChangeAssesmentType;
        await setChangeAssesment(finalData as ChangeAssesmentType);
        toast('Data added sucessfully');
        getChangeAssesments();
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Table = renderTableWithMapperComponent<
      ChangeAssesmentType,
      ChangeAssesmentFilterType
    >();

    const tableHeader: TableHeader = [
      [
        { name: 'Department' },
        { name: 'Employee Seeking Change' },
        { name: 'Type of Change' },
        { name: 'Change Related To' },
        { name: 'Document No' },
        { name: 'Document Name' },
        { name: 'Current Status' },
        { name: 'Status' },
        { name: 'Annexure' },
        { name: 'Download' },
      ],
    ];

    const bodyMapper = (l: ChangeAssesmentType) => {
      const perdoc = `${l.companyGroup.id}/${l.company.id}/change-control/${l.id}/permanent-change-control.pdf`;
      const tempdoc = `${l.companyGroup.id}/${l.company.id}/change-control/${l.id}/temporary-change-control.pdf`;
      const doc = `${l.companyGroup.id}/${l.company.id}/change-control/${l.id}/change-control-requisition.pdf`;
      const cells: Array<TableCell> = [
        {
          value: l.details.department?.name || '',
          link: `/quality-assurance/change-control/requisition/${l.id}`,
        },
        { value: l.details.employeeSeekingChange?.name || '' },
        { value: l.details.typeOfChange || '' },
        {
          value: (
            <>
              {l.details?.changeRelatedTo?.map((x) => (
                <span
                  key={x.id}
                  className='px-3 border border-gray-200 rounded-full'
                >
                  {x.name}
                </span>
              )) || null}
            </>
          ),
        },
        { value: l.details.docNo || '' },
        { value: l.details.docName || '' },
        { value: l.details.currentStatus || '' },
        { value: l.status || '-' },
        { value: showAnnexure(l) || '-' },
        // TO Do : add status after impact if any
        {
          value: (
            <>
              <>
                {[
                  'evaluation',
                  'cross-team-selected',
                  'qacommented-major-minor',
                  'activities',
                  'department-commented',
                  'management-commented',
                  'customer-commented',
                  'qa-approved',
                  'qa-rejected',
                  'closed',
                  'activities-completed',
                  'soft-copies-stored',
                  'intimation-department-commented',
                  'expert-commented',
                ].includes(l.status) ? (
                  <FileViewerUploader
                    mode='view'
                    url={doc || ''}
                    useFileTransfer={useFileTransfer}
                    path={doc}
                    type='private'
                    onUpload={async (path) => {}}
                  />
                ) : null}
              </>
              <>
                {[
                  'evaluation',
                  'cross-team-selected',
                  'qacommented-major-minor',
                  'activities',
                  'department-commented',
                  'management-commented',
                  'customer-commented',
                  'qa-approved',
                  'qa-rejected',
                  'closed',
                  'activities-completed',
                  'soft-copies-stored',
                  'intimation-department-commented',
                  'expert-commented',
                ].includes(l.status) ? (
                  <FileViewerUploader
                    mode='view'
                    url={
                      l.details.typeOfChange === 'permanent' ? perdoc : tempdoc
                    }
                    useFileTransfer={useFileTransfer}
                    path={
                      l.details.typeOfChange === 'permanent' ? perdoc : tempdoc
                    }
                    type='private'
                    onUpload={async (path) => {}}
                  />
                ) : null}
              </>
            </>
          ),
        },
      ];

      return {
        rowData: {
          changeAssesment: l,
        },
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              useResources={useChangeAssesments}
              actions={addActions()}
              auth={{ actions: getAllowedActions().actions }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <div>
          <Card header={cardHeader} body={cardBody} />
        </div>
      </>
    );
  };
}
