import {
  PurchaseEnquiry,
  PurchaseRequisition,
  VendorLocation,
} from '@erp_core/erp-types/dist/modules/order';
import { Terms } from '@erp_core/erp-types/dist/types/modules/order/purchase/purchase-enquiry';
import { VendorContact } from '@erp_core/erp-types/dist/types/modules/order/purchase/vendor';
import {
  DateSelector,
  LoadingButton,
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UsePurchaseRequisitions } from '../../../../../hooks/inventory/purchase/purchase-requisition/use-purchase-requisitions';
import { UseVendors } from '../../../../../hooks/inventory/purchase/vendors/use-vendors';
import { UseBom } from '../../../../../hooks/order/work-order/bom/use-bom';
import { renderBOMName } from '../../../../common/fragments/bom-name';
import {
  DeliverySchedule,
  DeliveryScheduleType,
} from '../components/delivery-schedule';
import { EditVendorInEnquiry } from './enquiry/edit-vendor';

export function renderVendorSelectForEnquiry({
  purchaseRequisition,
  onClose,
  useVendors,
  usePurchaseRequisitions,
  setPurchaseEnquiry,
  setPurchaseRequisition,
  deliveryTermsOptions,
  modeOfTransportOptions,
  useBom,
}: {
  purchaseRequisition: PurchaseRequisition;
  onClose: () => void;
  useVendors: UseVendors;
  useBom: UseBom;
  usePurchaseRequisitions: UsePurchaseRequisitions;
  setPurchaseEnquiry: (s: PurchaseEnquiry) => Promise<PurchaseEnquiry>;
  setPurchaseRequisition: (
    s: PurchaseRequisition
  ) => Promise<PurchaseRequisition>;
  deliveryTermsOptions: Array<{ id: string; name: string }>;
  modeOfTransportOptions: Array<{ id: string; name: string }>;
}): () => JSX.Element {
  const Table = renderTableComponent();

  return function VendorSelectForEnquiry(): JSX.Element {
    const BOMName = renderBOMName({ useBom });

    const { getSync: getBom } = useBom();

    const [boms, setBoms] = useState<
      Array<{ id: string; name: string; quantity: number }>
    >([]);

    useEffect(() => {
      if (purchaseRequisition.bomIds?.length) {
        new Promise((resolve) => resolve(true)).then(async () => {
          const res: { id: string; name: string; quantity: number }[] = [];
          for (const bomId of purchaseRequisition.bomIds) {
            const bomInfo = await getBom(bomId);
            res.push({
              id: bomInfo.id,
              name: bomInfo.name,
              quantity: bomInfo.details?.rawMaterial?.quantity,
            });
          }
          setBoms(res);
        });
      }
      // eslint-disable-next-line
    }, [purchaseRequisition]);

    const [draftPE, setDraftPE] = useState<{
      name: string;
      validity: number;
      quantity: number;
      date: string;
    }>({
      name: `PE-${moment().format('YYYYMMDD-HHmm')}`,
      validity: 15,
      quantity: 0,
      date: '',
    });

    const [shortListedVendors, setShortListedVendors] = useState<
      Array<{
        id: string;
        name: string;
        location: VendorLocation;
        contact: VendorContact;
        verified: boolean;
        selected: boolean;
        terms: Terms;
      }>
    >([]);
    const { data: vendors, getAll: getVendors } = useVendors();
    const {
      data: purchaseRequisitions,
      getAll: getPRs,
    } = usePurchaseRequisitions();
    const [deliverySchedule, setDeliverySchedule] = useState<
      Array<DeliveryScheduleType>
    >([]);
    // eslint-disable-next-line
    const [paymentSchedule, setPaymentSchedule] = useState<Array<any>>([]);
    const [schSummary, setSchSummary] = useState<{
      delivery: { total: number; scheduled: number };
      payment: { total: number; scheduled: number };
    }>({
      delivery: { total: 0, scheduled: 0 },
      payment: { total: 100, scheduled: 0 },
    });

    useEffect(() => {
      setSchSummary({
        delivery: {
          total: draftPE.quantity || 0,
          scheduled: deliverySchedule.reduce((prev, sc) => {
            return prev + sc.quantity;
          }, 0),
        },
        payment: {
          total: 100,
          scheduled: paymentSchedule.reduce((prev, sc) => {
            return prev + sc.payment;
          }, 0),
        },
      });
    }, [deliverySchedule, paymentSchedule, vendors, draftPE]);

    useEffect(() => {
      // we update the target date with the earliest pr.
      if (purchaseRequisitions?.length) {
        let date = draftPE.date;
        (purchaseRequisitions || []).forEach((pr) => {
          if (!date || moment(pr.details.targetDate).isBefore(moment(date))) {
            date = pr.details.targetDate;
          }
        });

        if (!date || draftPE.date !== date) {
          // We alse set the initial total quantity
          setDraftPE((d) => ({
            ...d,
            ...{
              date: date,
              quantity:
                purchaseRequisitions?.reduce((prev, pr) => {
                  return prev + pr.details.quantity;
                }, 0) || 0,
            },
          }));
        }
      }
      // eslint-disable-next-line
    }, [purchaseRequisitions]);

    useEffect(() => {
      if (vendors?.length) {
        const slv: Array<{
          id: string;
          name: string;
          location: VendorLocation;
          contact: VendorContact;
          verified: boolean;
          selected: boolean;
          terms: Terms;
        }> = [];
        vendors.forEach((v) => {
          slv.push({
            id: v.id,
            name: v.name,
            location:
              v.details?.locations?.find(
                (c) =>
                  c.id ===
                  v.items?.find(
                    (x) => x.item?.id === purchaseRequisition?.grade?.item?.id
                  )?.details?.supplierLocation?.id
              ) ||
              (v.details?.locations && v.details?.locations[0]) ||
              ({} as any),
            contact:
              v.details?.contact?.find(
                (c) =>
                  c.id ===
                  v.items?.find(
                    (x) => x.item?.id === purchaseRequisition?.grade?.item?.id
                  )?.details?.contactPerson?.id
              ) ||
              (v.details?.contact && v.details?.contact[0]) ||
              ({} as any),
            selected: v.items?.find(
              (x) => x.item.id === purchaseRequisition.grade?.item?.id
            )?.approved
              ? true
              : false,
            verified: v.items?.find(
              (x) => x.item.id === purchaseRequisition.grade?.item?.id
            )?.approved
              ? true
              : false,
            terms: {
              delivery: {
                deliveryTerms: v.details?.termsOfBusiness?.deliveryTerms || {
                  id: '',
                  name: '',
                },
                modeOfTransport: v.details?.termsOfBusiness
                  ?.modeOfTransport || { id: '', name: '' },
                deliveryMode: { id: 'A', name: '' },
              },
              payment: {
                paymentTerms: v.details?.termsOfBusiness?.paymentTerms || {
                  id: '',
                  name: '',
                },
              },
            } as any,
          });
        });

        setShortListedVendors(slv);
      }
    }, [vendors]);

    useEffect(() => {
      if (vendors?.length) {
        const slv = shortListedVendors;
        setShortListedVendors([...slv]);
      }
      // eslint-disable-next-line
    }, [draftPE]);

    useEffect(() => {
      if (purchaseRequisition.grade?.item?.id) {
        getVendors({
          itemId: purchaseRequisition.grade?.item?.id,
        });

        getPRs({
          itemId: purchaseRequisition.grade?.item?.id,
          status: 'open',
        });
      }
      // eslint-disable-next-line
    }, []);
    const Card = renderCardComponent();
    return (
      <div>
        <Card
          header={{
            title: (
              <>
                <span className='mx-1'>{draftPE.name}</span>
                <span className='mx-1'>{purchaseRequisition.grade?.name}</span>
                <span className='mx-1'>
                  {purchaseRequisition.grade?.item?.name}
                </span>
              </>
            ),
          }}
          body={{
            type: 'jsx-component',
            body: (
              <>
                <div className='flex pb-12'>
                  <div className='w-1/3'>
                    <Card
                      header={{
                        title: 'Open Purchase Requisitions',
                      }}
                      body={{
                        type: 'jsx-component',
                        body: (
                          <div>
                            <Table
                              header={[
                                [
                                  { name: 'Name' },
                                  { name: 'Target Date' },
                                  { name: 'Quantity' },
                                  { name: 'BOMs' },
                                ],
                              ]}
                              body={(purchaseRequisitions || [])
                                .sort((a, b) => {
                                  if (
                                    a.details.targetDate &&
                                    b.details.targetDate
                                  ) {
                                    return moment(a.details.targetDate).diff(
                                      moment(b.details.targetDate)
                                    );
                                  }
                                  return 0;
                                })
                                ?.map((v) => {
                                  return {
                                    cells: [
                                      { value: v.name },
                                      { value: v.details.targetDate },
                                      { value: v.details.quantity },
                                      {
                                        value: (
                                          <div>
                                            {v.bomIds?.map((id, idx) => (
                                              <div>
                                                <BOMName
                                                  key={`${id}-${idx}`}
                                                  id={id}
                                                />
                                                <span>
                                                  -{' '}
                                                  {
                                                    boms.find(
                                                      (x) => x.id === id
                                                    )?.quantity
                                                  }
                                                </span>
                                              </div>
                                            ))}
                                          </div>
                                        ),
                                      },
                                    ],
                                  };
                                })}
                            />
                          </div>
                        ),
                      }}
                    />
                    <div className='border border-gray-200 p-1'>
                      <div className='flex flex-wrap justify-center'>
                        <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                          <div className='mx-1'>
                            <span className='font-bold'>Target Date: </span>
                            <span className='mx-1'>
                              <DateSelector
                                initialState={draftPE.date}
                                format='YYYY-MM-DD'
                                onChange={(date) =>
                                  setDraftPE((d) => ({
                                    ...d,
                                    ...{ date: date },
                                  }))
                                }
                              />
                            </span>
                          </div>
                        </div>
                        <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                          <div className='mx-1'>
                            <span className='font-bold'>Quantity: </span>
                            <span className='mx-1'>
                              <input
                                type='number'
                                className='w-16 border border-gray-200'
                                defaultValue={draftPE.quantity}
                                min={0}
                                onBlur={(evt) => {
                                  setDraftPE((d) => ({
                                    ...d,
                                    ...{ quantity: parseInt(evt.target.value) },
                                  }));
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                          <div className='mx-1'>
                            <span className='font-bold'>Validity Days: </span>
                            <span className='mx-1'>
                              <input
                                type='number'
                                className='w-16 border border-gray-200'
                                defaultValue={draftPE.validity}
                                min={0}
                                onBlur={(evt) => {
                                  setDraftPE((d) => ({
                                    ...d,
                                    ...{ validity: parseInt(evt.target.value) },
                                  }));
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Card
                      header={{
                        title: 'Delivery Schedule',
                        subheading: (
                          <div className='flex'>
                            <div className='border border-gray-200 p-1 m-1 flex mx-1'>
                              <div className='mx-1'>
                                <span className='font-bold'>
                                  Total Quantity:{' '}
                                </span>
                                <span>{schSummary.delivery.total}</span>
                              </div>
                              <div className='mx-1'>
                                <span className='font-semibold'>
                                  Scheduled:{' '}
                                </span>
                                <span>{schSummary.delivery.scheduled}</span>
                              </div>
                            </div>
                          </div>
                        ),
                        actions: [
                          {
                            type: 'button',
                            show: () => true,
                            button: {
                              name: 'Add',
                              behaviour: 'click',
                              onClick: async () => {
                                setDeliverySchedule((s) => [
                                  ...s,
                                  {
                                    idx: s.length,
                                    name: `Batch-${s.length + 1}`,
                                    daysAfterOrder: 0,
                                    quantity: 0,
                                    boms: [],
                                  },
                                ]);
                              },
                            },
                          },
                        ],
                      }}
                      body={{
                        type: 'jsx-component',
                        body: (
                          <DeliverySchedule
                            availableBoms={boms}
                            deliverySchedule={deliverySchedule}
                            setDeliverySchedule={setDeliverySchedule}
                          />
                        ),
                      }}
                    />
                  </div>
                  <div className='w-2/3'>
                    <Card
                      header={{
                        title: 'Vendors Providing this Item',
                      }}
                      body={{
                        type: 'jsx-component',
                        body: (
                          <div>
                            <Table
                              actions={[
                                {
                                  name: 'Edit',
                                  behaviour: 'modal',
                                  show: () => true,
                                  modal: {
                                    title: 'Edit',
                                    content: ({
                                      data: { vendor },
                                      onClose,
                                    }) => {
                                      return (
                                        <EditVendorInEnquiry
                                          modeOfTransportOptions={
                                            modeOfTransportOptions
                                          }
                                          deliveryTermsOptions={
                                            deliveryTermsOptions
                                          }
                                          vendor={vendor}
                                          vendors={vendors || []}
                                          onSave={async (v) => {
                                            console.log(v);
                                            const newSLV = [
                                              ...shortListedVendors,
                                            ];
                                            const f = newSLV.find(
                                              (x) => x.id === v.id
                                            );
                                            if (f) {
                                              f.location = v.location;
                                              f.contact = v.contact;
                                              f.terms = v.terms;
                                              setShortListedVendors(newSLV);
                                            }
                                            onClose();
                                          }}
                                        />
                                      );
                                    },
                                  },
                                },
                              ]}
                              header={[
                                [
                                  { name: 'Name' },
                                  { name: 'Location' },
                                  { name: 'Contact' },
                                  { name: 'Payment Terms' },
                                  {
                                    name: 'Delivery Terms',
                                  },
                                  {
                                    name: 'Mode of Transport',
                                  },

                                  { name: '' },
                                ],
                              ]}
                              body={shortListedVendors?.map((v) => {
                                return {
                                  rowData: {
                                    vendor: v,
                                  },
                                  cells: [
                                    {
                                      value: (
                                        <>
                                          {v.name}
                                          {v.verified ? (
                                            <CheckBadgeIcon className='text-green-500 w-5 mx-1 inline' />
                                          ) : null}
                                        </>
                                      ),
                                    },
                                    {
                                      value: (
                                        <>
                                          <div>
                                            {v.location?.address?.addressLine1},
                                          </div>
                                          <div>
                                            {v.location?.address?.addressLine2},
                                          </div>
                                          <div>
                                            {v.location?.address?.city},
                                          </div>
                                        </>
                                      ),
                                    },
                                    {
                                      value: (
                                        <>
                                          <div>{v.contact?.name},</div>
                                          <div>{v.contact?.phones},</div>
                                          <div>{v.contact?.emails},</div>
                                        </>
                                      ),
                                    },
                                    {
                                      value: `${
                                        Array.isArray(
                                          v.terms?.payment?.paymentTerms
                                        )
                                          ? v.terms?.payment?.paymentTerms
                                              ?.map((x) => {
                                                return `${x.percent}% ${x.paymentType} - {if n>0 Then "${x.noOfDays}" Days}.`;
                                              })
                                              .join(', ') || '-'
                                          : '-'
                                      }`,
                                    },
                                    {
                                      value: `${
                                        v.terms?.delivery?.deliveryTerms
                                          ?.name || '-'
                                      }`,
                                    },
                                    {
                                      value: `${
                                        v.terms?.delivery?.modeOfTransport
                                          ?.name || '-'
                                      }`,
                                    },
                                    {
                                      value: (
                                        <input
                                          type='checkbox'
                                          checked={v.selected}
                                          onChange={(evt) => {
                                            const found = shortListedVendors.find(
                                              (x) => x === v
                                            );
                                            if (found) {
                                              found.selected = !found.selected;
                                              setShortListedVendors([
                                                ...shortListedVendors,
                                              ]);
                                            }
                                          }}
                                        />
                                      ),
                                    },
                                  ],
                                };
                              })}
                            />
                          </div>
                        ),
                      }}
                    />
                  </div>
                </div>
              </>
            ),
          }}
        />
        <div className='flex justify-center my-2'>
          {deliverySchedule.length &&
          schSummary?.delivery?.scheduled === schSummary?.delivery?.total &&
          shortListedVendors.filter((x) => x.selected)?.length ? (
            <LoadingButton
              text='Create Draft Enquiry'
              behaviourParams={{}}
              behaviorFn={async () => {
                if (purchaseRequisitions?.length) {
                  const pe = await setPurchaseEnquiry({
                    name: draftPE.name,
                    purchaseRequisitions: [],
                    details: {
                      itemDetails: {
                        quantity:
                          draftPE.quantity ||
                          _.reduce(
                            purchaseRequisitions || [],
                            (prev, curr) => {
                              return prev + curr.details?.quantity || 0;
                            },
                            0
                          ),
                        grade: purchaseRequisitions[0].grade,
                        uom: purchaseRequisitions[0].itemDetails?.uom,
                      },
                      vendors: shortListedVendors
                        .filter((x) => x.selected)
                        .map((x) => {
                          return {
                            id: x.id,
                            name: x.name,
                            terms: x.terms,
                            location: x.location,
                            contact: x.contact,
                          };
                        }),
                      terms: {} as any,
                      schedule: {
                        delivery: deliverySchedule,
                        amountIn: 'per',
                        payment: paymentSchedule,
                      },
                      activity: [],
                      validity: draftPE.validity,
                    },
                    grade: purchaseRequisitions[0].grade,
                    status: 'enquiry-draft',
                    enquiryQuotes: [],
                  } as any);
                  if (pe.id) {
                    // TODO: Figure out why this doesnt work within PE request
                    for (const pr of purchaseRequisitions || []) {
                      if (pr.status === 'pr-open') {
                        await setPurchaseRequisition({
                          id: pr.id,
                          status: 'enquiry-draft',
                          purchaseEnquiry: {
                            id: pe.id,
                            name: pe.name,
                          },
                        } as any);
                      }
                    }
                  }

                  toast.success('Draft Purchase Enquiry Created');
                }
                onClose();
              }}
              defaultStyle='bg-green-500 text-white p-1 rounded'
            />
          ) : null}
        </div>
      </div>
    );
  };
}
