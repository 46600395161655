/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { UseCompanyGroupSetting } from '../../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseEmployeeCategories } from '../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseEmployeeDesignations } from '../../../hooks/admin/constants/employee-designation/use-employee-designations';
import { UseLocations } from '../../../hooks/admin/location-admin/use-locations';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UseAttendanceDevice } from '../../../hooks/hrd/attendance-device/use-attendance-device';
import { UseAttendanceDevices } from '../../../hooks/hrd/attendance-device/use-attendance-devices';
import { UseLocationHoliday } from '../../../hooks/planning/location-holiday/use-location-holiday';
import { UseLocationHolidays } from '../../../hooks/planning/location-holiday/use-location-holidays';
import { UseShiftSchedule } from '../../../hooks/planning/shift-schedule/use-shift-schedule';
import { UseShiftSchedules } from '../../../hooks/planning/shift-schedule/use-shift-schedules';
import { CompanyGroupSettingInterface } from '../../../models/interfaces/admin/company-group-settings';
import { createCompanyGroupSettingPage } from './company-group';
import { createHrdConfig } from './hrd';
import { createInventoryConfigPage } from './inventory-config';
import { createInventoryTemplatesPage } from './inventory-templates';
import { createCompanyGroupSettingLogs } from './logs';
import { createPurchaseConfigPage } from './purchase-config';
import { createQualityAssuranceConfigPage } from './quality-assurance-config';
import { createSalesConfigPage } from './sales-config';
import { createWorkOrderConfigPage } from './work-order-config';

type Props = {
  useCompanyGroupSetting: UseCompanyGroupSetting;
  companyGroupSettingService: CompanyGroupSettingInterface;
  useLocations: UseLocations;
  useAttendanceDevices: UseAttendanceDevices;
  useAttendanceDevice: UseAttendanceDevice;
  useFileTransfer: UseFileTransfer;
  useEmployeeCategories: UseEmployeeCategories;
  useEmployeeDesignations: UseEmployeeDesignations;
  useShiftSchedules: UseShiftSchedules;
  useShiftSchedule: UseShiftSchedule;
  useLocationHoliday: UseLocationHoliday;
  useLocationHolidays: UseLocationHolidays;
};

export const renderCompanyGroupConfig = ({
  useCompanyGroupSetting,
  companyGroupSettingService,
  useFileTransfer,
  useLocations,
  useAttendanceDevice,
  useAttendanceDevices,
  useEmployeeCategories,
  useEmployeeDesignations,
  useShiftSchedule,
  useShiftSchedules,
  useLocationHoliday,
  useLocationHolidays,
}: Props) => {
  return function () {
    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Config',
    };

    const InventoryTemplates = createInventoryTemplatesPage({
      useFileTransfer,
    });

    const InventoryConfig = createInventoryConfigPage({
      useCompanyGroupSetting,
    });
    const WorkOrderConfig = createWorkOrderConfigPage({
      useCompanyGroupSetting,
    });
    const QualityAssuranceConfig = createQualityAssuranceConfigPage({
      useCompanyGroupSetting,
    });
    const CompanyGroupSettingLogs = createCompanyGroupSettingLogs({
      companyGroupSettingService,
    });
    const PurchaseConfig = createPurchaseConfigPage({
      useCompanyGroupSetting,
    });
    const SalesConfig = createSalesConfigPage({
      useCompanyGroupSetting,
    });
    const CompanyGroupConfig = createCompanyGroupSettingPage({
      useCompanyGroupSetting,
      useLocations,
      useShiftSchedule,
      useShiftSchedules,
      useLocationHoliday,
      useLocationHolidays,
    });

    const HrdConfig = createHrdConfig({
      useAttendanceDevice,
      useAttendanceDevices,
      useEmployeeCategories,
      useCompanyGroupSetting,
      useEmployeeDesignations,
    });

    const newCategories: DataArray = [
      { name: 'inventory', Fn: InventoryConfig },
      { name: 'inventory-templates', Fn: InventoryTemplates },
      { name: 'work-order', Fn: WorkOrderConfig },
      { name: 'quality-assurance', Fn: QualityAssuranceConfig },
      { name: 'purchase', Fn: PurchaseConfig },
      { name: 'sales', Fn: SalesConfig },
      { name: 'company-group', Fn: CompanyGroupConfig },
      { name: 'hrd', Fn: HrdConfig },
      { name: 'history', Fn: CompanyGroupSettingLogs },
    ];

    const NewTabs = renderTabsV2Component();

    return (
      <div>
        <Card
          header={cardHeader}
          body={{
            type: 'jsx-component',
            body: <NewTabs data={newCategories} manipulateLocation={true} />,
          }}
        />
      </div>
    );
  };
};
