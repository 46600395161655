import { Godown } from '@erp_core/erp-types/dist/modules/inventory';
import { GodownDetails } from '@erp_core/erp-types/dist/types/modules/inventory/godown';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import {
  UserGroupIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { CurrentContext } from '../../../../contexts/current';
import { UseUsers } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseGodown } from '../../../../hooks/inventory/godown-inventory/use-godown';
import { UseGodowns } from '../../../../hooks/inventory/godown-inventory/use-godowns';
import { UseGodownsTree } from '../../../../hooks/inventory/godown-inventory/use-godowns-tree';
import { UsePaginatedGodowns } from '../../../../hooks/inventory/godown-inventory/use-paginated-godowns';
import { GodownFilterType } from '../../../../models/interfaces/inventory/generic/godown';
import { ItemFilter } from '../../../../models/types/inventory/item';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderAddGodownForm } from './add-godown';

export type RenderGodownsListProps = {
  useGodown: UseGodown;
  useGodownsTree: UseGodownsTree;
  useGodowns: UseGodowns;
  usePaginatedGodowns: UsePaginatedGodowns;
  useUserAuthorization: UseUserAuthorization;
  useUsers: UseUsers;
  userRendererService: UserRendererInterface;
};

export function renderGodownList({
  useGodown,
  useGodownsTree,
  useGodowns,
  usePaginatedGodowns,
  useUserAuthorization,
  useUsers,
  userRendererService,
}: RenderGodownsListProps): () => JSX.Element {
  return function GodownList(): JSX.Element {
    const { syncSet: setGodown } = useGodown();
    const { location, company } = useContext(CurrentContext);
    const { get: getAllowedActions } = useUserAuthorization();

    const Table = renderTableWithMapperComponent<Godown, GodownFilterType>();
    const tableHeader: TableHeader = [
      [
        { name: <>Godown Name</> },
        { name: <>Type</> },
        { name: <>Parent Godown</> },
        { name: <>Category</> },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    const bodyMapper = (i: Godown) => {
      const cells: Array<TableCell> = [
        {
          value: (
            <>
              <div>
                {i.type === 'supervisor-work-area' ? (
                  <UserGroupIcon className='inline w-4 text-blue-800' />
                ) : null}
                {i.type === 'work-area' ? (
                  <UsersIcon className='inline w-4 text-blue-800' />
                ) : null}
                {i.name || ''}
              </div>
            </>
          ),
          link: `/inventory/masters/godowns/${i.id}/profile`,
        },
        {
          value: <>{i.type}</>,
        },
        {
          value: (
            <div className='p-1'>
              {i.parentGodownId === 'root' ? 'Root Godown' : i.parentGodownId}
            </div>
          ),
        },
        { value: <span className='capitalize'>{i.details.category}</span> },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={i.lastModifiedBy?.id}
              name={i.lastModifiedBy?.name}
            />
          ),
        },
      ];

      return {
        cells,
      };
    };

    const addGodown = async (form) => {
      const save: Partial<Godown> = {};
      if (form.name) save['name'] = form.name;
      form.parentGodownId.id
        ? (save['parentGodownId'] = form.parentGodownId.id)
        : (save['parentGodownId'] = 'root');
      if (location) save['locationId'] = location.id;
      if (company) save['companyId'] = company.id;
      save['details'] = {
        address: form?.address,
      } as GodownDetails;
      save['description'] = '';

      await setGodown(save as Godown);
    };

    const Card = renderCardComponent();

    const header: CardHeader = {
      title: 'Godown List',
      actions: [
        {
          type: 'button',
          auth: 'UI:BTN-ADD-GODOWN:VIEW',
          button: {
            name: 'Add Godown',
            behaviour: 'modal',
            modal: {
              title: 'Add Godown',
              content: ({ onClose }) => {
                const AddGodownForm = renderAddGodownForm({
                  useGodowns: useGodowns,
                });
                return (
                  <AddGodownForm
                    onSave={async (form) => {
                      await addGodown(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const filter: Filter<ItemFilter> = {
      version: 'v2',
      sortFields: [
        {
          key: 'GodownName',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'type',
          value: 'all',
          type: 'drop-down',
          options: [
            { text: 'all', value: 'all' },
            { text: 'virtual', value: 'virtual' },
            { text: 'physical', value: 'physical' },
            { text: 'physical-currrent', value: 'physical-current' },
            { text: 'physical-fixed', value: 'physical-fixed' },
            { text: 'work-area', value: 'work-area' },
            { text: 'supervisor-work-area', value: 'supervisor-work-area' },
          ],
        },
        {
          key: 'lastModifiedBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: GodownFilterType) => {
        const filterData: GodownFilterType = {};

        // if (filterSelection.Status !== 'all') {
        //   filterData.status = filterSelection.Status;
        // }
        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }
        if (filterSelection.lastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.lastModifiedBy;
        }

        if (filterSelection.type !== 'all' && filterSelection.type !== '') {
          filterData.type = filterSelection.type;
        }

        return filterData as GodownFilterType;
      },
      filterResources: {
        lastModifiedBy: {
          searchOptions: {
            useSearch: useUsers,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              auth={{ actions: getAllowedActions().actions }}
              header={tableHeader}
              bodyMapper={bodyMapper}
              useResources={useGodownsTree}
              filter={filter}
              pagination={{
                enabled: true,
                usePaginatedResources: usePaginatedGodowns,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card
          header={header}
          body={body}
          auth={{ actions: getAllowedActions().actions }}
        />
      </>
    );
  };
}
