import { renderHistory } from '@erp_core/erp-ui-components';
import { UseItem } from '../../../../../hooks/inventory/item/use-item';

export function renderHistoryTab({
  id,
  // itemV2Service,
  useItem,
}: {
  id: string;
  // itemV2Service: ItemV2Interface;
  useItem: UseItem;
}): () => JSX.Element {
  return function HistoryTab(): JSX.Element {
    const History = renderHistory();

    return <History useResource={useItem} id={id} />;
  };
}
