/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  LeadCustomerEnquiryItems,
  LeadCustomerEnquiryType,
  LeadCustomerType,
} from '@erp_core/erp-types/dist/modules/order';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { Card, ColumnizedContent } from '@erp_core/erp-ui-components';
import toast from 'react-hot-toast';
import { UseMetrics } from '../../../hooks/admin/constants/metrics/use-metrics';
import { UseEmployeeProfiles } from '../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseItems } from '../../../hooks/inventory/item/use-items';
import { UseClientItem } from '../../../hooks/order/sales/client-item/use-client-item';
import { UseClient } from '../../../hooks/order/sales/client/use-client';
import { UseClients } from '../../../hooks/order/sales/client/use-clients';
import { UseLeadCustomer } from '../../../hooks/order/sales/lead-customer/use-lead-customer';
import { UseLeadCustomers } from '../../../hooks/order/sales/lead-customer/use-lead-customers';
import { ItemInterface } from '../../../models/interfaces/inventory/item';
import { renderAddSalesEnquiryV2Form } from '../sales-enquiry/forms/add-sales-enquiry-v2';
import { renderAddSalesLeadForm } from '../sales-enquiry/forms/add-sales-lead';
import { createLeadCustomerEnquiryProfileCard } from './card';
import { renderEnquiryFollowupList } from './enquiry-follow-ups';
import { renderEnquiryProductsTable } from './enquiry-products-table';

type CreateLeadCustomerCardType = {
  leadCustomerEnquiry: LeadCustomerEnquiryType;
  loading: boolean;
  setLeadCustomerEnquiry: (
    s: LeadCustomerEnquiryType
  ) => Promise<LeadCustomerEnquiryType>;
  useItems: UseItems;
  useEmployeeProfiles: UseEmployeeProfiles;
  useLeadCustomers: UseLeadCustomers;
  useLeadCustomer: UseLeadCustomer;
  useMetrics: UseMetrics;
  useClient: UseClient;
  useClients: UseClients;
  useClientItem: UseClientItem;
  itemService: ItemInterface;
};

export function renderLeadCustomerEnquiryDetailsProfile({
  leadCustomerEnquiry,
  setLeadCustomerEnquiry,
  loading,
  useItems,
  useEmployeeProfiles,
  useLeadCustomers,
  useLeadCustomer,
  useMetrics,
  useClient,
  useClients,
  useClientItem,
  itemService,
}: CreateLeadCustomerCardType): () => JSX.Element {
  return function ClientProfile(): JSX.Element {
    const leadCustomerEnquiryProfileInitialData: Card = {
      header: {
        title: 'Enquiry Profile',
        actions: [
          {
            type: 'button',
            button: {
              behaviour: 'modal',
              name: 'Edit',
              modal: {
                title: 'Edit',
                content: ({ onClose }) => {
                  return <DisplayModal onClose={onClose} />;
                },
              },
            },
          },
        ],
      },
      body: {
        type: 'columns',
        body: [],
      },
    };
    const initialItemData = {
      profileData: leadCustomerEnquiryProfileInitialData,
    };

    const LeadEnquiryProfileCard = createLeadCustomerEnquiryProfileCard({
      initialData: initialItemData.profileData,
      mapperFunction: mapLeadCustomerEnquiryProfileBody,
      leadCustomerEnquiry,
      loading,
    });

    const ClientEnquiryProfileCard = createLeadCustomerEnquiryProfileCard({
      initialData: initialItemData.profileData,
      mapperFunction: mapClientEnquiryProfileBody,
      leadCustomerEnquiry,
      loading,
    });

    const EnquiryProductsTable = renderEnquiryProductsTable({
      loading,
      leadCustomerEnquiry,
      setLeadCustomerEnquiry,
      useItems,
      useClient,
      useClientItem,
      itemService,
    });

    const FollowUps = renderEnquiryFollowupList({
      leadCustomerEnquiry,
      setLeadCustomerEnquiry,
      loading,
      useLeadCustomer,
      useItems,
      useEmployeeProfiles,
      useLeadCustomers,
      useMetrics,
      useClient,
      useClients,
    });

    const EditLeadProfileForm = renderAddSalesLeadForm({
      useItems,
      useEmployeeProfiles,
      useLeadCustomers,
      useLeadCustomer,
    });

    const EditClientEnquiryProfile = renderAddSalesEnquiryV2Form({
      useItems,
      useEmployeeProfiles,
      useLeadCustomers,
      useLeadCustomer,
      useMetrics,
      useClient,
      useClients,
    });

    const handleEditLeadSales = async (formData: any) => {
      // setShowModal(false);
      const finalData: Partial<LeadCustomerEnquiryType> = {
        id: leadCustomerEnquiry?.id,
        leadCustomer: {
          id: formData?.customer?.id,
          name: formData?.customer?.name,
        } as LeadCustomerType,
        assignedTo: formData?.assignedTo as IdName,
        generatedBy: formData?.leadGeneratedBy as IdName,
        // item: formData?.product || { id: '', name: '' },
        enquiryItems: [
          {
            item: {
              id: formData.product?.id,
              name: formData.product?.name,
            },
            details: {},
          },
        ] as LeadCustomerEnquiryItems[],
        details: {
          date: formData?.date || '',
          type: formData?.type || '',
          stage: formData?.stage || '',
          customerContact: (formData?.contact as string) || '',
          description: formData?.description || '',
          targetPrice: formData?.targetPrice || '',
          exportDataPrice: formData?.exportDataPrice || '',
          priceAvail: formData?.prevailPrice || '',
          currentSupplier: formData?.currentSupplier || '',
        },
      };
      await setLeadCustomerEnquiry(finalData as LeadCustomerEnquiryType);
      toast('Profile Edited Successfully');
    };

    const handleEditClientEnquiry = async (formData: any) => {
      const finalData: Partial<LeadCustomerEnquiryType> = {
        id: leadCustomerEnquiry?.id,
        leadCustomer: {
          id: formData?.customer?.id,
          name: formData?.customer?.name,
        } as LeadCustomerType,
        assignedTo: formData?.assignedTo as IdName,
        // generatedBy: formData?.leadGeneratedBy as IdName,
        // enquiryItems: [{
        //   item: {
        //     id: formData.product?.id,
        //     name: formData.product?.name,
        //   },
        //   details: {},
        // }] as LeadCustomerEnquiryItems[],
        details: {
          date: formData?.date || '',
          type: formData?.type || '',
          stage: formData?.stage || '',
          customerContact: (formData?.contact as string) || '',
          description: formData?.description || '',
          enquiryMode: formData?.enquiryMode || '',
          enquiryReferenceNum: formData?.enquiryRefNo || '',
          deliveryLocation: formData?.deliveryLocation || '',
        },
      };
      await setLeadCustomerEnquiry(finalData as LeadCustomerEnquiryType);
      toast('Profile Edited Successfully');
    };

    const displayProductTableStages = ['Enquiry-Draft', 'Enquiry'];

    function DisplayModal({ onClose }: { onClose: () => void }): JSX.Element {
      if (
        displayProductTableStages.includes(
          leadCustomerEnquiry?.details?.stage || ''
        )
      ) {
        return (
          <EditClientEnquiryProfile
            onSave={async (formData) => {
              await handleEditClientEnquiry(formData);
              onClose();
            }}
            enqdata={leadCustomerEnquiry}
          />
        );
      }

      return (
        <EditLeadProfileForm
          onSave={async (formData) => {
            await handleEditLeadSales(formData);
            onClose();
          }}
          leadEnqdata={leadCustomerEnquiry}
        />
      );
    }

    return (
      <div className='w-full space-y-3'>
        {displayProductTableStages.includes(
          leadCustomerEnquiry?.details?.stage || ''
        ) ? (
          <ClientEnquiryProfileCard id={leadCustomerEnquiry?.id} />
        ) : (
          <LeadEnquiryProfileCard id={leadCustomerEnquiry?.id} />
        )}
        {displayProductTableStages.includes(
          leadCustomerEnquiry?.details?.stage || ''
        ) ? (
          <EnquiryProductsTable />
        ) : (
          <></>
        )}
        <FollowUps />
      </div>
    );
  };
}

const mapLeadCustomerEnquiryProfileBody = (
  data: LeadCustomerEnquiryType
): ColumnizedContent => [
  [
    {
      key: 'Generated By:',
      value: data?.generatedBy?.name || '-',
      type: 'text',
    },
    {
      key: 'Assigned To:',
      value: data?.assignedTo?.name || '-',
      type: 'text',
    },
  ],
  [
    {
      key: 'Lead Customer:',
      value: data?.leadCustomer?.name || '-',
      type: 'text',
    },
    {
      key: 'Customer Contact:',
      value: data?.details?.customerContact || '-',
      type: 'text',
    },
    {
      key: 'Current Supplier:',
      value: data?.details?.currentSupplier || '-',
      type: 'text',
    },
  ],
  [
    {
      key: 'Type:',
      value: data?.details?.type || '-',
      type: 'text',
    },
    {
      key: 'Stage:',
      value: data?.details?.stage || '-',
      type: 'text',
    },
    {
      key: 'Description:',
      value: data?.details?.description || '-',
      type: 'text',
    },
  ],
  [
    {
      key: 'Target Price:',
      value: data?.details?.targetPrice || '-',
      type: 'text',
    },
    {
      key: 'Export Data Price:',
      value: data?.details?.exportDataPrice || '-',
      type: 'text',
    },
    {
      key: 'Prevailing Price in India Market:',
      value: data?.details?.priceAvail || '-',
      type: 'text',
    },
  ],
];

const mapClientEnquiryProfileBody = (
  data: LeadCustomerEnquiryType
): ColumnizedContent => [
  [
    {
      key: 'Mode of Enquiry :',
      value: data?.details?.enquiryMode || '-',
      type: 'text',
    },
    {
      key: 'Enquiry Reference No.:',
      value: data?.details?.enquiryReferenceNum || '-',
      type: 'text',
    },
    {
      key: 'Assigned To:',
      value: data?.assignedTo?.name || '-',
      type: 'text',
    },
  ],
  [
    {
      key: 'Lead Customer:',
      value: data?.leadCustomer?.name || '-',
      type: 'text',
    },
    {
      key: 'Customer Contact:',
      value: data?.details?.customerContact || '-',
      type: 'text',
    },
    {
      key: 'Delivery Location:',
      value: data?.details?.deliveryLocation || '-',
      type: 'text',
    },
  ],
  [
    {
      key: 'Type:',
      value: data?.details?.type || '-',
      type: 'text',
    },
    {
      key: 'Stage:',
      value: data?.details?.stage || '-',
      type: 'text',
    },
    {
      key: 'Description:',
      value: data?.details?.description || '-',
      type: 'text',
    },
  ],
];
