import { LoadingButton } from '@erp_core/erp-ui-components';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { ProjectedLeaves } from '.';
import { LeaveInterface } from '../../../../models/interfaces/hrd/leave';

type RenderPersistRefillLeavesProps = {
  projectedLeaves: ProjectedLeaves;
  leaveService: LeaveInterface;
};

type RefillLeaveBank = {
  employeeId: string;
  employeeName: string;
  privilege: number;
  sick: number;
  compOff: number;
  casual: number;
};

export function renderPersistRefillLeaves({
  projectedLeaves,
  leaveService,
}: RenderPersistRefillLeavesProps): ({
  onClose,
}: {
  onClose: () => void;
}) => JSX.Element {
  return function ({ onClose }: { onClose: () => void }): JSX.Element {
    const [justification, setJustification] = useState('');

    let result: RefillLeaveBank[] = [];

    async function saveLeaveBank(justification: string) {
      //  console.log('projectedLeaves',projectedLeaves)
      var output = Object.entries(projectedLeaves).map(([key, value]) => ({
        key,
        value,
      }));

      output.forEach((v) => {
        if (v.value.selected === true) {
          result.push({
            employeeId: v.key,
            employeeName: v.value.name,
            privilege: v.value.privilege,
            sick: v.value.sick,
            compOff: v.value.compoff,
            casual: v.value.casual,
          } as RefillLeaveBank);
        }
      });

      const reqBodyData = {
        leaves: result,
        justification: justification,
      };
      // console.log(reqBodyData)

      const resp = await leaveService.refillLeaveBank(reqBodyData);
      if (resp.success === true) {
        toast.success('Refilled leaves successfully!');
      } else {
        toast.error('Something went wrong!');
      }
    }

    return (
      <>
        <input
          className='border w-11/12 rounded p-2'
          type='text'
          placeholder='Enter refill leave justification'
          onChange={(e) => {
            e.preventDefault();
            setJustification(e.target.value);
          }}
          required
          value={justification}
        />
        <LoadingButton
          text='Submit'
          behaviorFn={async () => {
            await saveLeaveBank(justification);
            await onClose();
          }}
          behaviourParams={{}}
          defaultStyle='px-4 py-2 m-1.5 bg-green-600 border rounded-lg'
        />
      </>
    );
  };
}
