import { Godown } from '@erp_core/erp-types/dist/modules/inventory';
import { VouchersType } from '@erp_core/erp-types/dist/types/modules/accounts/vouchers';
import { Grade } from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import {
  LoadingButton,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { UseGodowns } from '../../../../../hooks/inventory/godown-inventory/use-godowns';
import { UseGrades } from '../../../../../hooks/inventory/grade/use-grades';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';

export type ItemDetails = {
  grade: Grade;
  batch: string; // BatchItemGodown[];
  quantity: number;
  rate: number;
  amount: number;
};

export type GodownTransferVoucher = {
  name: string;
  voucherNumber: string;
  date: string;
  mrs: string;
  bom: string;
  godown: Godown;
  remarks: string;
  consumption: Array<ItemDetails>;
  production: Array<ItemDetails>;
};

export function renderConsumptionVoucher({
  useGrades,
  useGodowns,
}: {
  useGodowns: UseGodowns;
  useGrades: UseGrades;
  itemService: ItemInterface;
}) {
  const Table = renderTableComponent();
  const Card = renderCardComponent();
  return function ConsumptionVoucher({
    gt,
    onSave,
  }: {
    gt?: VouchersType;
    onSave(s: VouchersType): Promise<void>;
  }) {
    const [voucher, setVoucher] = useState<GodownTransferVoucher>(
      gt ? (gt.details as GodownTransferVoucher) : ({} as GodownTransferVoucher)
    );
    const [editBasicDetails, setEditBasicDetails] = useState<boolean>(false);

    const Form1 = renderFormV2({
      formName: 'Consumption Voucher',
      initialFormState: {
        name: voucher.name || '',
        voucherNumber: voucher.voucherNumber || '',
        date: voucher.date || '',
        mrs: voucher.mrs || '',
        bom: voucher.bom || '',
        godown: voucher.godown || { id: '', name: '' },
        remarks: voucher.remarks || '',
      },
      style: 'w-1/6',
      fieldsData: [
        {
          property: 'name',
          label: 'GT Number',
          type: 'input',
          required: true,
        },
        {
          property: 'voucherNumber',
          type: 'input',
          required: true,
        },
        {
          property: 'date',
          type: 'date',
          required: true,
        },
        {
          property: 'mrs',
          type: 'input',
          required: true,
        },
        {
          property: 'bom',
          type: 'input',
          required: true,
        },
        {
          property: 'godown',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useGodowns,
            onSearchValueSelect(u) {},
          },
          required: true,
        },
        {
          property: 'remarks',
          type: 'input',
        },
      ],
      mapTToU: (x) => x,
      onSubmit: async (form) => {
        setVoucher((x) => ({
          ...x,
          ...(form as any),
        }));
        setEditBasicDetails(false);
      },
    });
    return (
      <div>
        {editBasicDetails ? (
          <Form1 />
        ) : (
          <Card
            header={{
              title: 'Consumption Voucher',
              actions: [
                {
                  type: 'button',
                  button: {
                    name: 'Edit',
                    behaviour: 'click',
                    onClick: async () => {
                      setEditBasicDetails(true);
                    },
                  },
                },
              ],
            }}
            body={{
              type: 'columns',
              body: [
                [{ key: 'GT Number', value: voucher.name }],
                [{ key: 'Voucher #', value: voucher.voucherNumber }],
                [{ key: 'Date', value: voucher.date }],
                [{ key: 'Godown', value: voucher.godown?.name }],
                [{ key: 'Remarks', value: voucher.remarks }],
              ],
            }}
          />
        )}

        <div className='flex'>
          <div className='w-1/2'>
            <Card
              header={{
                title: 'Consumption Details',
                actions: [
                  {
                    type: 'button',
                    button: {
                      behaviour: 'modal',
                      name: 'Add',
                      modal: {
                        title: 'Add',
                        content: ({ onClose }) => {
                          return (
                            <EditConsumptionDetails
                              useGrades={useGrades}
                              td={{} as ItemDetails}
                              onSave={async (td) => {
                                const newVoucher = { ...voucher };
                                if (!newVoucher.consumption) {
                                  newVoucher.consumption = [];
                                }
                                newVoucher.consumption.push(td);
                                setVoucher(newVoucher);
                                onClose();
                              }}
                            />
                          );
                        },
                      },
                    },
                  },
                ],
              }}
              body={{
                type: 'jsx-component',
                body: (
                  <Table
                    header={[
                      [
                        { name: 'Item' },
                        { name: 'Batch #' },
                        { name: 'Quantity' },
                        { name: 'Rate' },
                        { name: 'Amount' },
                      ],
                    ]}
                    body={(voucher.consumption || [])?.map((x, idx) => {
                      return {
                        rowData: {
                          item: x,
                          index: idx,
                        },
                        cells: [
                          { value: x.grade?.name },
                          {
                            value: (
                              <>
                                {x.batch}
                                {/* {x.batch?.map((y, idx) => (
                                  <span key={idx}>{y.name}</span>
                                ))} */}
                              </>
                            ),
                          },
                          { value: x.quantity },
                          { value: x.rate },
                          { value: x.amount },
                        ],
                      };
                    })}
                    actions={[
                      {
                        name: 'Edit',
                        behaviour: 'modal',
                        show: () => true,
                        modal: {
                          title: 'Edit',
                          content: ({ data: { item, index }, onClose }) => {
                            return (
                              <EditConsumptionDetails
                                useGrades={useGrades}
                                td={item}
                                onSave={async (td) => {
                                  const newVoucher = { ...voucher };
                                  newVoucher.consumption[index] = td;
                                  setVoucher(newVoucher);
                                  onClose();
                                }}
                              />
                            );
                          },
                        },
                      },
                    ]}
                  />
                ),
              }}
            />
          </div>
          <div className='w-1/2'>
            <Card
              header={{
                title: 'Production Details',
                actions: [
                  {
                    type: 'button',
                    button: {
                      behaviour: 'modal',
                      name: 'Add',
                      modal: {
                        title: 'Add',
                        content: ({ onClose }) => {
                          return (
                            <EditConsumptionDetails
                              useGrades={useGrades}
                              td={{} as ItemDetails}
                              onSave={async (td) => {
                                const newVoucher = { ...voucher };
                                if (!newVoucher.production) {
                                  newVoucher.production = [];
                                }
                                newVoucher.production.push(td);
                                setVoucher(newVoucher);
                                onClose();
                              }}
                            />
                          );
                        },
                      },
                    },
                  },
                ],
              }}
              body={{
                type: 'jsx-component',
                body: (
                  <Table
                    header={[
                      [
                        { name: 'Item' },
                        { name: 'Batch #' },
                        { name: 'Quantity' },
                        { name: 'Rate' },
                        { name: 'Amount' },
                      ],
                    ]}
                    body={(voucher.production || [])?.map((x, idx) => {
                      return {
                        rowData: {
                          item: x,
                          index: idx,
                        },
                        cells: [
                          { value: x.grade?.name },
                          {
                            value: (
                              <>
                                {x.batch}
                                {/* {x.batch?.map((y, idx) => (
                                  <span key={idx}>{y.name}</span>
                                ))} */}
                              </>
                            ),
                          },
                          { value: x.quantity },
                          { value: x.rate },
                          { value: x.amount },
                        ],
                      };
                    })}
                    actions={[
                      {
                        name: 'Edit',
                        behaviour: 'modal',
                        show: () => true,
                        modal: {
                          title: 'Edit',
                          content: ({ data: { item, index }, onClose }) => {
                            return (
                              <EditConsumptionDetails
                                useGrades={useGrades}
                                td={item}
                                onSave={async (td) => {
                                  const newVoucher = { ...voucher };
                                  newVoucher.production[index] = td;
                                  setVoucher(newVoucher);
                                  onClose();
                                }}
                              />
                            );
                          },
                        },
                      },
                    ]}
                  />
                ),
              }}
            />
          </div>
        </div>

        <LoadingButton
          text={'Save'}
          behaviorFn={async () => {
            await onSave({
              id: gt?.id || '',
              dateTime: voucher.date,
              name: voucher.name,
              description: '',
              details: voucher,
              voucherType: 'consumption-voucher',
            } as VouchersType);
          }}
        />
      </div>
    );
  };
}

function EditConsumptionDetails({
  td,
  onSave,
  useGrades,
}: {
  td: ItemDetails;
  onSave: (td: ItemDetails) => Promise<void>;
  useGrades: UseGrades;
}) {
  const Form = renderFormV2({
    initialFormState: {
      grade: td.grade || { id: '', name: '' },
      batch: td.batch || '',
      quantity: td.quantity || 0,
      rate: td.rate || 0,
    },
    fieldsData: [
      {
        property: 'grade',
        type: 'searchable-select',
        required: true,
        searchOptions: {
          useSearch: useGrades,
          selectTarget: (form) => {
            return {
              id: form.id,
              name: form.name,
              item: {
                id: form.item.id,
                name: form.item.name,
              },
            };
          },
          onSearchValueSelect: (u: any) => {},
          // filter?: any;
          searchOptionsBody: {
            customBody: (data: Grade) => {
              return (
                <div>
                  <div className='font-bold'>{data.name}</div>
                  <div>{data.item?.name}</div>
                </div>
              );
            },
          },
        },
      },
      { property: 'batch', type: 'input', required: true },
      { property: 'quantity', type: 'number', required: true },
      { property: 'rate', type: 'number', required: true },
    ],
    mapTToU: (u) => u,
    onSubmit: async (form) => {
      const final: ItemDetails = { ...td, ...(form as any) };
      final.quantity = parseFloat(`${final.quantity}`);
      final.rate = parseFloat(`${final.rate}`);
      final.amount = final.quantity * final.rate;
      await onSave({ ...td, ...(form as any) });
    },
  });

  return <Form />;
}
