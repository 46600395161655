type GradeData = {
  gradeName: string;
  data: {
    parameter: string;
    lessThan: number;
    greaterThan: number;
  }[];
}[];

export const getGrade = (prop: {
  gradeData: GradeData;
  parameter: string;
  value: number;
}) => {
  const { gradeData, parameter, value } = prop;
  const grade = gradeData.find((g) => {
    return g.data.find(
      (t) =>
        value >= Number(t.greaterThan) &&
        value <= Number(t.lessThan) &&
        t.parameter === parameter
    );
  });
  if (grade) {
    return grade.gradeName;
  }
  return null;
};
