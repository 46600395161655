/* eslint-disable no-unused-vars */
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseItems } from '../../../../hooks/inventory/item/use-items';
import { UseClients } from '../../../../hooks/order/sales/client/use-clients';

type Props = {
  data: {};
  onSave: any;
};

// eslint-disable-next-line
export function renderAddSalesEnquiryForm({
  useItems,
  useClients,
}: {
  useItems: UseItems;
  useClients: UseClients;
}): (props: Props) => JSX.Element {
  return function AddSalesEnquiryForm({ data, onSave }: Props): JSX.Element {
    const Form = renderFormV2({
      onSubmit: async (form) => {
        onSave({
          name: form.name,
          respondBy: form.respondBy,
          expireBy: form.expireBy,
          client: form.client,
          remark: form.remark,
          attachments: '',
          item: {
            id: form.id,
            quantity: form.quantity,
            color: form.color,
            grade: form.grade,
            packaging: form.packaging,
            stateOfMatter: form.stateOfMatter,
          },
          schedule: {
            batchQuantity: form.batchQuantity,
            batchNeededBy: form.batchNeededBy,
          },
        });
      },
      mapTToU: (f) => f,
      initialFormState: {
        name: '',
        respondBy: '',
        expireBy: '',
        client: '',
        remark: '',
        attachments: '',

        id: '',
        quantity: '',
        color: '',
        grade: '',
        packaging: '',
        stateOfMatter: '',

        batchQuantity: '',
        batchNeededBy: '',
      },
      fieldsData: [
        {
          property: 'name',
          label: 'Name',
          type: 'input',
        },
        {
          property: 'respondBy',
          label: 'Respond By',
          type: 'date',
        },
        {
          property: 'expireBy',
          label: 'Expire By',
          type: 'date',
        },
        {
          groupName: 'item',
          property: 'id',
          label: 'Item',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useItems,
            onSearchValueSelect: (v) => {
              console.log(v);
            },
          },
        },
        {
          groupName: 'item',
          property: 'quantity',
          label: 'Quantity',
          type: 'number',
        },
        {
          groupName: 'item',
          property: 'color',
          label: 'Color',
          type: 'input',
        },
        {
          groupName: 'item',
          property: 'grade',
          label: 'Grade',
          type: 'input',
        },
        {
          groupName: 'item',
          property: 'packaging',
          label: 'Packaging',
          type: 'input',
        },
        {
          groupName: 'item',
          property: 'stateOfMatter',
          label: 'State of matter',
          type: 'input',
        },
        {
          groupName: 'schedule',
          property: 'batchQuantity',
          label: 'Quantity',
          type: 'number',
        },
        {
          groupName: 'schedule',
          property: 'batchNeededBy',
          label: 'Needed by',
          type: 'date',
        },
        {
          property: 'client',
          label: 'Client',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useClients,
            onSearchValueSelect: (v) => {
              // console.log(v);
            },
          },
        },
        {
          property: 'remark',
          label: 'Remark',
          type: 'input',
        },
        {
          property: 'attachments',
          label: 'Attachments',
          type: 'input',
        },
      ],
    });
    return <Form />;
  };
}
