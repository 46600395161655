/* eslint-disable react-hooks/exhaustive-deps */
import { NotificationLoggerResponse } from '@erp_core/erp-types/dist/types/modules/notification/notification-logger';
import { LoadingButton } from '@erp_core/erp-ui-components';
import {
  ArrowLeftOnRectangleIcon,
  BellIcon,
  ClipboardDocumentCheckIcon,
  EyeIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import socketIOClient from 'socket.io-client';
// eslint-disable-next-line no-unused-vars
import { NOTIFICATION_SERVER } from '../../config/server-uri';
import { NotificationInterface } from '../../models/interfaces/notification';

type Props = {
  notificationService: NotificationInterface;
};

function Notification({ notificationService }: Props) {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationData, setNotificationData] = useState<
    Array<NotificationLoggerResponse>
  >([]);
  const [visibleButtons, setVisibleButtons] = useState<Array<string>>([]);

  const handleNotificationClick = (log: NotificationLoggerResponse): null => {
    if (
      !log.notification.needs_acknowledgement &&
      log.notification.priority !== 'urgent'
    )
      return null;

    const isVisible = visibleButtons.find((i) => i === log.id);
    if (isVisible) {
      setVisibleButtons((bt) => bt.filter((i) => i !== log.id));
    } else {
      setVisibleButtons((bt) => [...bt, log.id]);
    }
    return null;
  };

  const setUserNotifications = async () => {
    const currentUser = localStorage.getItem('current-user');
    const userId = JSON.parse(currentUser || '{}').id;
    if (!userId) return setNotificationData([]);
    const resp = await notificationService.getUserNotifications({
      userId,
    } as any);
    if (resp) {
      setNotificationData(
        resp.sort((r, q) => (r.created_at <= q.created_at ? 1 : -1))
      );
    }
  };

  const handleMenuClick = () => {
    setShowNotification(!showNotification);
  };

  const handleSeenClick = async (e: any, logId: string) => {
    e.stopPropagation();
    const resp = await notificationService.markSeen(logId);
    if (resp.success) {
      setUserNotifications();
    }
  };

  const handleAcknowlwdgeClick = async (e: any, logId: string) => {
    e.stopPropagation();
    const resp = await notificationService.acknowledge(logId);
    if (resp.success) {
      setUserNotifications();
    }
  };

  const handleCloseClick = async (e: any, logId: string) => {
    e.stopPropagation();
    const resp = await notificationService.dismiss(logId);
    if (resp.success) {
      setUserNotifications();
    }
  };

  const bgSelector = (
    priority: string,
    needsAcknowledgement?: boolean
  ): string => {
    if (priority === 'urgent') return 'bg-orange-50';
    if (needsAcknowledgement) return 'bg-indigo-50';
    if (priority === 'high') return 'bg-amber-50';
    if (priority === 'medium') return 'bg-lime-50';
    if (priority === 'low') return 'bg-zinc-50';
    return 'bg-zinc-50';
  };

  const selectIcon = (log: NotificationLoggerResponse) => {
    if (log.acknowledged_at)
      return (
        <ClipboardDocumentCheckIcon
          title='Acknowledged'
          className='h-6 w-6 mr-3'
        />
      );
    if (log.seen_at) return <EyeIcon title='Seen' className='h-6 w-6 mr-3' />;
    if (log.notification.needs_acknowledgement)
      return (
        <ArrowLeftOnRectangleIcon
          title='Waiting for acknowledgement'
          className='h-6 w-6 mr-3'
        />
      );
    return (
      <InformationCircleIcon title='Information' className='h-6 w-6 mr-3' />
    );
  };

  const needsAction = (log: NotificationLoggerResponse): boolean => {
    if (log.acknowledged_at) return false;
    if (log.notification.needs_acknowledgement) return true;
    if (log.seen_at) return false;
    if (log.notification.priority === 'urgent') return true;
    return false;
  };

  useEffect(() => {
    const currentUser = localStorage.getItem('current-user');
    if (currentUser) {
      const userId = JSON.parse(currentUser).id;
      const socket = socketIOClient(NOTIFICATION_SERVER, {
        // path: '/api/v1/notification-service',
        transports: ['websocket'],
      });
      socket.emit('notification-service', { userId }, (err) => {
        if (err) {
          console.log('notification-socket-error', err);
        }
      });
      socket.on('after-connection-established', () => {
        setUserNotifications();
      });
      socket.on('push-notification', (data) => {
        setUserNotifications();
        toast(
          <div
            className='cursor-pointer'
            onClick={() => setShowNotification(true)}
          >
            <div>{data.title}</div>
            <div>{data.description}</div>
          </div>
        );
      });
      socket.on('connect_error', (err) => {
        console.log(`connect_error due to ${err.message}`);
      });

      // eslint-disable-next-line
      () => socket.disconnect();
    }
  }, []);

  return (
    <>
      <div className='cursor-pointer ml-1' onClick={handleMenuClick}>
        <BellIcon
          className={`${
            notificationData.length > 0 ? 'animate-pulse' : ''
          } h-7 w-7 inline-flex text-blue-400`}
        />
        {notificationData.length > 0 ? (
          <span className='absolute top-1 animate-bounce right-0 m-1 px-1 py-0.5 bg-red-500 rounded-full text-xs text-white'>
            {notificationData.length < 10 ? notificationData.length : '9+'}
          </span>
        ) : (
          <></>
        )}
      </div>
      <div
        className={`${
          showNotification
            ? 'fixed flex justify-end top-[56px] right-[10px] backdrop-blur-sm'
            : 'hidden'
        } w-full`}
      >
        <div
          onClick={() => {
            setShowNotification(!showNotification);
          }}
          className='flex-1 blur-md'
        />
        <div
          className={
            'overflow-y-scroll transition-transform ease-in duration-500 block h-[94vh] w-[650px] z-50 rounded'
          }
        >
          {notificationData.length > 0 ? (
            <div>
              {notificationData?.map((log, index) => (
                <div
                  key={index}
                  className={`${
                    needsAction(log) && 'cursor-pointer'
                  } block m-3 border w-11/12 hover:border-gray-300 box-border rounded-3xl px-4 py-4 ${bgSelector(
                    log.notification.priority,
                    log.notification.needs_acknowledgement
                  )}`}
                  onClick={
                    needsAction(log)
                      ? () => handleNotificationClick(log)
                      : () => null
                  }
                >
                  <div className='flex'>
                    <div>{selectIcon(log)}</div>
                    <div className='flex-col flex-1'>
                      <div className='flex space-x-4 items-center'>
                        <div className='font-black text-base flex-1 text-black'>
                          {log.notification.title}
                        </div>

                        <div className=''>
                          {moment(log.notification.scheduled_at).fromNow()}
                        </div>

                        <XMarkIcon
                          title='Close'
                          className='h-4 w-4 font-bold text-gray-800 hover:text-black cursor-pointer'
                          onClick={(e) => handleCloseClick(e, log.id)}
                        />
                      </div>

                      <div className=' text-base text-gray-800'>
                        {log.notification.description}
                      </div>
                    </div>
                  </div>

                  {log.notification.link && (
                    <LoadingButton
                      text='Open'
                      defaultStyle='bg-blue-400 hover:bg-blue-500 space-x-4 mt-2 px-3 py-1 rounded-md text-white text-sm '
                      behaviourParams={{}}
                      behaviorFn={async () => {
                        window.location.href = `${log.notification.link}`;
                      }}
                    />
                  )}

                  {visibleButtons.find((i) => i === log.id) && (
                    <div className='flex space-x-4 mt-2'>
                      {log.notification.needs_acknowledgement &&
                        log.acknowledged_at === null && (
                          <button
                            type='button'
                            className='bg-gray-50 rounded-xl text-black text-sm '
                            onClick={(e) => handleAcknowlwdgeClick(e, log.id)}
                          >
                            Acknowledge
                          </button>
                        )}
                      {log.notification.priority === 'urgent' &&
                        log.seen_at === null && (
                          <button
                            type='button'
                            className='bg-cyan-200 rounded-xl text-black text-sm'
                            onClick={(e) => handleSeenClick(e, log.id)}
                          >
                            Mark Seen
                          </button>
                        )}
                    </div>
                  )}
                </div>
              ))}
              <div className='my-14 text-center mx-auto'>
                {' '}
                You are all caught up, nothing to show here.
              </div>
            </div>
          ) : (
            <div className='m-6'>
              {' '}
              You are all caught up, nothing to show here.
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Notification;
