import { ItemProperty } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import {
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { useState } from 'react';

export type AddPropertyProps = {
  level: number;
  mode: 'add' | 'edit';
  initialValues: any;
  classifiers: { name: string; description: string }[];
  auth: { type: string; id: string; name: string }[];
  saveItemProp: (s: ItemProperty) => void;
  avoidSubmitBehavior?: boolean;
};

export function renderAddProperty(): (p: AddPropertyProps) => JSX.Element {
  return function AddProperty({
    level,
    mode,
    saveItemProp,
    classifiers,
    auth,
    initialValues,
    avoidSubmitBehavior,
  }: AddPropertyProps): JSX.Element {
    const newClassifers = classifiers.map((x) => {
      return {
        ...x,
        id: x.name,
      };
    });

    async function convertToItemProps(f: any) {
      const x: any = {
        classifiers:
          f.classifiers?.map((x) => ({ name: x.id })) ||
          initialValues.classifiers,
        name: _.kebabCase(f.name || initialValues.name),
        valueType: f.valueType || initialValues.valueType,
        placeholder: f.placeholder || initialValues.placeholder,
        validPattern: f.validPattern || undefined,
        description: f.description || initialValues.description,
        type: f.type || initialValues.type,
        selectOptions: f.selectOptions,
        searchSelectOptions: f.searchSelectOptions,
        required: f.mandatory === 'yes' || initialValues.required || false,
        childrenProperties: f.childrenProperties || undefined,
        childrenListProperties: f.childrenListProperties || undefined,
        complexArrayObjectProperties:
          f.complexArrayObjectProperties || undefined,
      };

      if (x.type === 'range') {
        x.range = {
          type: f.rangeType || initialValues?.range?.type || '',
          min: f.rangeMin || initialValues?.range?.min || 0,
          max: f.rangeMax || initialValues?.range?.max || 0,
        };
      }

      saveItemProp(x);
    }

    function convertToInitialState(f: any) {
      return {
        classifiers:
          f.classifiers?.map((x) => {
            const q = newClassifers.find((y) => y.id === x.name);
            if (q) {
              return q;
            }

            return { id: '' };
          }) || [],
        name: _.kebabCase(f.name),
        description: f.description,
        validPattern: f.validPattern,
        type: f.type,
        mandatory: f.required ? 'yes' : 'no',
        selectOptions: f.selectOptions,
        searchSelectOptions: f.searchSelectOptions,
        childrenProperties: f.childrenProperties,
        childrenListProperties: f.childrenListProperties,
        complexArrayObjectProperties: f.complexArrayObjectProperties,
        placeholder: f.placeholder,
        valueType: f.valueType,
      };
    }
    const Form = renderFormV2({
      button: {
        avoidSubmitBehavior,
      },
      fieldsData: [
        {
          groupName: '1. General',
          property: 'name',
          type: 'input',
          required: true,
          label: 'Name',
          readonly: mode === 'edit',
        },
        {
          groupName: '1. General',
          property: 'description',
          type: 'input',
          label: 'Description',
        },
        {
          groupName: '1. General',
          property: 'classifiers',
          type: 'searchable-multi-select',
          dependentOn: (f) => {
            return level === 1 && mode === 'add';
          },
          searchOptions: {
            useSearch: () => {
              const [filtered, setFiltered] = useState<
                { name: string; description: string }[]
              >(newClassifers);
              return {
                data: filtered,
                getAll: (filter) => {
                  if (filter.search) {
                    setFiltered(
                      newClassifers.filter((x) =>
                        x.name
                          .toLowerCase()
                          .includes((filter.search || '').toLowerCase())
                      )
                    );
                  } else {
                    setFiltered(newClassifers);
                  }
                },
              };
            },
            onSearchValueSelect: (e) => {},
          },
          label: 'Classifiers',
        },
        {
          groupName: '2. Type',
          property: 'type',
          type: 'select',
          readonly: mode === 'edit',
          options: [
            { text: 'Select Type', value: '' },
            { text: 'Text', value: 'input' },
            { text: 'Number', value: 'number' },
            { text: 'Date', value: 'date' },
            { text: 'Value UOM', value: 'value-uom' },
            { text: 'Range', value: 'range' },
            { text: 'Formula', value: 'formula' },
            { text: 'Select', value: 'select' },
            { text: 'Check List', value: 'checklist' },
            { text: 'Searchable Select', value: 'searchable-select' },
            {
              text: 'Searchable Multi Select',
              value: 'searchable-multiselect',
            },
            { text: 'Image', value: 'image' },
            { text: 'Object', value: 'object' },
            { text: 'Array Object ', value: 'array-object' },
            { text: 'Fixed Array Object ', value: 'fixed-array-object' },
            { text: 'Complex Array Object', value: 'complex-array-object' },
          ],
          required: true,
          label: 'Type',
        },
        {
          groupName: '2. Type',
          property: 'rangeType',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Single', value: 'single' },
            { text: 'Double', value: 'double' },
          ],
          dependentOn: (formData) => {
            return formData.type === 'range';
          },
        },
        {
          groupName: '2. Type',
          property: 'rangeMin',
          type: 'number',
          dependentOn: (formData) => {
            return formData.type === 'range';
          },
        },
        {
          groupName: '2. Type',
          property: 'rangeMax',
          type: 'number',
          dependentOn: (formData) => {
            return formData.type === 'range';
          },
        },
        {
          groupName: '2. Type',
          property: 'valueType',
          type: 'select',
          label: 'Value Type',
          options: [
            { text: 'Select', value: '' },
            { value: 'fixed', text: 'Fixed' },
            { value: 'derived', text: 'Derived (Formula)' },
            { value: 'variable', text: 'Variable' },
            { value: 'fixed-uom', text: 'Fixed UOM' },
          ],
        },
        {
          groupName: '2. Type',
          property: 'selectOptions',
          type: 'tags',
          label: 'Select Options',
          dependentOn: (formData) => {
            return formData.type === 'select' || formData.type === 'checklist';
          },
        },
        {
          groupName: '2. Type',
          property: 'searchSelectOptions',
          type: 'select',
          label: 'Select Searchable Options',
          options: [
            { text: 'Select Search Type', value: '' },
            { text: 'Metrics', value: 'useMetrics' },
            { text: 'GST', value: 'useGST' },
            { text: 'Appearance', value: 'useAppearances' },
            { text: 'Color', value: 'useColors' },
            { text: 'Odour', value: 'useOdours' },
            { text: 'Employee', value: 'useEmployees' },
          ],
          dependentOn: (formData) => {
            return (
              formData.type === 'searchable-select' ||
              formData.type === 'searchable-multiselect'
            );
          },
        },
        {
          width: 'w-full',
          groupName: '2. Type',
          property: 'childrenProperties',
          type: 'jsx',
          dependentOn: (formData) => {
            return formData.type === 'object';
          },
          renderObject: ({ formData, field, onSave }) => {
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                <ObjectRender
                  level={level}
                  formData={formData}
                  field={field.property}
                  onSave={onSave}
                />
              </div>
            );
          },
        },
        {
          groupName: '2. Type',
          property: 'childrenListProperties',
          type: 'jsx',
          dependentOn: (formData) => {
            return (
              typeof formData.type === 'string' &&
              ['fixed-array-object', 'array-object'].includes(formData.type)
            );
          },
          renderObject: ({ formData, field, onSave }) => {
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                <ObjectRender
                  level={level}
                  formData={formData}
                  field={field.property}
                  onSave={onSave}
                />
              </div>
            );
          },
        },
        {
          groupName: '2. Type',
          property: 'complexArrayObjectProperties',
          type: 'jsx',
          dependentOn: (formData) => {
            return (
              typeof formData.type === 'string' &&
              ['complex-array-object'].includes(formData.type)
            );
          },
          renderObject: ({ formData, field, onSave }) => {
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                <ComplexArrayObjectRender
                  level={level}
                  formData={formData}
                  field={field.property}
                  onSave={onSave}
                />
              </div>
            );
          },
        },
        {
          groupName: '1. General',
          property: 'mandatory',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
          required: true,
          label: 'Mandatory',
        },
        {
          groupName: '2. Type',
          property: 'placeholder',
          type: 'input',
          label: 'Placeholder',
          dependentOn: (formData) => {
            return formData.type === 'input';
          },
        },
        {
          groupName: '2. Type',
          property: 'validPattern',
          type: 'input',
          label: 'Validation Pattern',
        },
      ],
      onSubmit: convertToItemProps,
      initialFormState: convertToInitialState(initialValues),
      mapTToU: (r) => {
        return r;
      },
      style: 'mb-4 w-1/3',
    });
    return <Form />;
  };
}

function ObjectRender({ level, formData, field, onSave }): JSX.Element {
  const [props, setProps] = useState<any>(formData[field] || {});
  const [selectedProp, setSelectedProp] = useState<any | null>(null);
  const AddProp = renderAddProperty();
  const Table = renderTableComponent();

  return (
    <div>
      <div className='flex row-reverse'>
        <span
          className='bg-green-500 p-1 ronded-sm float-right cursor-pointer'
          onClick={() => {
            onSave(props);
          }}
        >
          Save
        </span>
      </div>

      <Table
        header={[
          [
            { name: 'Name' },
            { name: 'Type' },
            { name: 'Mandatory' },
            {
              name: '',
            },
          ],
        ]}
        body={_.keys(props).map((x) => {
          return {
            cells: [
              { value: props[x].name },
              { value: props[x].type },
              { value: props[x].required ? 'Yes' : 'No' },
              {
                value: (
                  <>
                    <PencilIcon
                      className='w-5 h-5 inline px-1'
                      onClick={() => {
                        setSelectedProp(props[x]);
                      }}
                    />
                    <TrashIcon
                      className='text-red-500 w-5 h-5 inline px-1'
                      onClick={() => {
                        delete props[x];
                        const newProps = JSON.parse(JSON.stringify(props));
                        setProps(newProps);
                      }}
                    />
                  </>
                ),
              },
            ],
          };
        })}
      />

      {selectedProp ? (
        <AddProp
          mode={'edit'}
          level={level + 1}
          avoidSubmitBehavior={true}
          initialValues={selectedProp}
          classifiers={[]}
          auth={[]}
          saveItemProp={(x) => {
            setProps((p) => ({ ...p, ...{ [x.name]: x } }));
            setSelectedProp(null);
          }}
        />
      ) : (
        <AddProp
          level={level + 1}
          mode={'add'}
          avoidSubmitBehavior={true}
          initialValues={{}}
          classifiers={[]}
          auth={[]}
          saveItemProp={(x) => {
            if (x.name) {
              setProps((p) => ({ ...p, ...{ [x.name]: x } }));
            }
          }}
        />
      )}
    </div>
  );
}

type SelectedProp = {
  propIndex: number;
  type: 'header-prop' | 'body-prop' | 'trailer-prop';
  typeIndex: number;
  prop: Prop;
};

type Prop = {
  name: string;
  valueType: string;
  type: string;
  required: boolean;
};

type ComplexArrayProp = {
  headerRows: Array<Array<Prop>>;
  variableRow: Array<Prop>;
  trailerRows: Array<Array<Prop>>;
};

function ComplexArrayObjectRender({
  level,
  formData,
  field,
  onSave,
}): JSX.Element {
  const [props, setProps] = useState<ComplexArrayProp>(
    formData[field] || {
      headerRows: [],
      variableRow: [],
      trailerRows: [],
    }
  );
  const [selectedProp, setSelectedProp] = useState<SelectedProp | null>(null);
  const AddAdvanceProp = renderAddAdvanceProperty();

  return (
    <div>
      <div className='flex border border-gray-200 p-1 rounded-md'>
        <div className='flex-auto text-center font-semibold'>
          Complex Array Object Properties
        </div>
        <div
          className='flex-none bg-green-500 p-1 ronded-sm float-right cursor-pointer'
          onClick={() => {
            onSave(props);
          }}
        >
          Save
        </div>
      </div>

      <div className='border border-gray-200 my-1 rounded-sm'>
        <div className='flex'>
          <div className='flex-auto text-center font-semibold'>
            Fixed Header Rows
          </div>
          <div className='flex-none'>
            <PlusIcon
              className='w-5 h-5 inline'
              onClick={() => {
                const newProps: ComplexArrayProp = JSON.parse(
                  JSON.stringify(props)
                );
                newProps.headerRows.push([]);
                setProps(newProps);
              }}
            />{' '}
            Row
          </div>
        </div>
        <div>
          {props.headerRows?.map((hr, hrIdx) => (
            <div key={hrIdx}>
              <div className='italic text-center'>
                Row {hrIdx + 1}
                <TrashIcon
                  className='ml-1 text-red-500 w-5 h-5 inline px-1'
                  onClick={() => {
                    props.headerRows.splice(hrIdx, 1);
                    const newProps: ComplexArrayProp = JSON.parse(
                      JSON.stringify(props)
                    );
                    setProps(newProps);
                  }}
                />
              </div>
              <div className='flex flex-wrap'>
                {hr.map((p, pIdx) => (
                  <div
                    key={pIdx}
                    className={`border ${
                      selectedProp?.prop === p
                        ? 'border-green-500'
                        : 'border-gray-200'
                    } p-1`}
                  >
                    <div>Name: {p.name}</div>
                    <div>Value-Type: {p.valueType}</div>
                    <div>Type: {p.type}</div>
                    <div>Mandatory: {p.required ? 'Yes' : 'No'}</div>
                    <PencilIcon
                      className='w-5 h-5 inline px-1'
                      onClick={() => {
                        setSelectedProp({
                          propIndex: pIdx,
                          typeIndex: hrIdx,
                          type: 'header-prop',
                          prop: p,
                        });
                      }}
                    />
                    {selectedProp?.prop === p ? (
                      <TrashIcon
                        className='text-red-500 w-5 h-5 inline px-1'
                        onClick={() => {
                          props.headerRows[hrIdx].splice(pIdx, 1);
                          const newProps: ComplexArrayProp = JSON.parse(
                            JSON.stringify(props)
                          );
                          setProps(newProps);
                        }}
                      />
                    ) : null}
                  </div>
                ))}
                <PlusIcon
                  className='w-12 h-12'
                  onClick={() => {
                    const newProps: ComplexArrayProp = JSON.parse(
                      JSON.stringify(props)
                    );
                    newProps.headerRows[hrIdx].push({
                      name: '',
                      valueType: '',
                      type: '',
                      required: false,
                    });
                    setProps(newProps);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='border border-gray-200 my-1 rounded-sm'>
        <div className='text-center font-semibold'>Variable Row</div>
        <div className='flex flex-wrap'>
          {props.variableRow?.map((vr, vrIdx) => (
            <div
              key={vrIdx}
              className={`border ${
                selectedProp?.prop === vr
                  ? 'border-green-500'
                  : 'border-gray-200'
              } p-1`}
            >
              <div>Name: {vr.name}</div>
              <div>Value-Type: {vr.valueType}</div>
              <div>Type: {vr.type}</div>
              <div>Mandatory: {vr.required ? 'Yes' : 'No'}</div>
              <PencilIcon
                className='w-5 h-5 inline px-1'
                onClick={() => {
                  setSelectedProp({
                    propIndex: vrIdx,
                    typeIndex: 0,
                    type: 'body-prop',
                    prop: vr,
                  });
                }}
              />
              {selectedProp?.prop === vr ? (
                <TrashIcon
                  className='text-red-500 w-5 h-5 inline px-1'
                  onClick={() => {
                    props.variableRow.splice(vrIdx, 1);
                    const newProps: ComplexArrayProp = JSON.parse(
                      JSON.stringify(props)
                    );
                    setProps(newProps);
                  }}
                />
              ) : null}
            </div>
          ))}
          <PlusIcon
            className='w-12 h-12'
            onClick={() => {
              const newProps: ComplexArrayProp = JSON.parse(
                JSON.stringify(props)
              );
              newProps.variableRow.push({
                name: '',
                valueType: '',
                type: '',
                required: false,
              });
              setProps(newProps);
            }}
          />
        </div>
      </div>

      <div className='border border-gray-200 my-1 rounded-sm'>
        <div className='flex'>
          <div className='flex-auto text-center font-semibold'>
            Fixed Trailer Rows
          </div>
          <div className='flex-none'>
            <PlusIcon
              className='w-5 h-5 inline'
              onClick={() => {
                const newProps: ComplexArrayProp = JSON.parse(
                  JSON.stringify(props)
                );
                newProps.trailerRows.push([]);
                setProps(newProps);
              }}
            />{' '}
            Row
          </div>
        </div>
        <div>
          {props.trailerRows?.map((tr, trIdx) => (
            <div key={trIdx}>
              <div className='italic text-center'>
                Row {trIdx + 1}
                <TrashIcon
                  className='ml-1 text-red-500 w-5 h-5 inline px-1'
                  onClick={() => {
                    props.trailerRows.splice(trIdx, 1);
                    const newProps: ComplexArrayProp = JSON.parse(
                      JSON.stringify(props)
                    );
                    setProps(newProps);
                  }}
                />
              </div>
              <div className='flex flex-wrap'>
                {tr.map((p, pIdx) => (
                  <div
                    key={pIdx}
                    className={`border ${
                      selectedProp?.prop === p
                        ? 'border-green-500'
                        : 'border-gray-200'
                    } p-1`}
                  >
                    <div>Name: {p.name}</div>
                    <div>Value-Type: {p.valueType}</div>
                    <div>Type: {p.type}</div>
                    <div>Mandatory: {p.required ? 'Yes' : 'No'}</div>
                    <PencilIcon
                      className='w-5 h-5 inline px-1'
                      onClick={() => {
                        setSelectedProp({
                          propIndex: pIdx,
                          typeIndex: trIdx,
                          type: 'trailer-prop',
                          prop: p,
                        });
                      }}
                    />
                    {selectedProp?.prop === p ? (
                      <TrashIcon
                        className='text-red-500 w-5 h-5 inline px-1'
                        onClick={() => {
                          props.trailerRows[trIdx].splice(pIdx, 1);
                          const newProps: ComplexArrayProp = JSON.parse(
                            JSON.stringify(props)
                          );
                          setProps(newProps);
                        }}
                      />
                    ) : null}
                  </div>
                ))}
                <PlusIcon
                  className='w-12 h-12'
                  onClick={() => {
                    const newProps: ComplexArrayProp = JSON.parse(
                      JSON.stringify(props)
                    );
                    newProps.trailerRows[trIdx].push({
                      name: '',
                      valueType: '',
                      type: '',
                      required: false,
                    });
                    setProps(newProps);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedProp ? (
        <AddAdvanceProp
          mode={selectedProp.prop['name'] ? 'edit' : 'add'}
          level={level + 1}
          avoidSubmitBehavior={true}
          initialValues={selectedProp.prop}
          classifiers={[]}
          auth={[]}
          saveItemProp={(x) => {
            setProps((p) => {
              const newProps: ComplexArrayProp = { ...p };
              if (selectedProp.type === 'body-prop') {
                newProps.variableRow[selectedProp.propIndex] = {
                  ...newProps.variableRow[selectedProp.propIndex],
                  ...x,
                };
              }

              if (selectedProp.type === 'header-prop') {
                newProps.headerRows[selectedProp.typeIndex][
                  selectedProp.propIndex
                ] = {
                  ...newProps.headerRows[selectedProp.typeIndex][
                    selectedProp.propIndex
                  ],
                  ...x,
                };
              }

              if (selectedProp.type === 'trailer-prop') {
                newProps.trailerRows[selectedProp.typeIndex][
                  selectedProp.propIndex
                ] = {
                  ...newProps.trailerRows[selectedProp.typeIndex][
                    selectedProp.propIndex
                  ],
                  ...x,
                };
              }
              console.log(newProps, p);
              return p;
            });
            setSelectedProp(null);
          }}
        />
      ) : null}
    </div>
  );
}

export function renderAddAdvanceProperty(): (
  p: AddPropertyProps
) => JSX.Element {
  return function AddProperty({
    level,
    mode,
    saveItemProp,
    classifiers,
    auth,
    initialValues,
    avoidSubmitBehavior,
  }: AddPropertyProps): JSX.Element {
    const newClassifers = classifiers.map((x) => {
      return {
        ...x,
        id: x.name,
      };
    });

    async function convertToItemProps(f: any) {
      const x: any = {
        name: _.kebabCase(f.name || initialValues.name),
        description: f.description || initialValues.description,
        valueType: f.valueType || initialValues.valueType,
        type: f.type || initialValues.type,
        selectOptions: f.selectOptions,
        searchSelectOptions: f.searchSelectOptions,
        childrenProperties: f.childrenProperties,
        required: f.mandatory === 'yes' || initialValues.required || false,
        placeholder: f.placeholder || initialValues.placeholder,
      };

      if (x.type === 'range') {
        x.range = {
          type: f.rangeType || initialValues?.range?.type || '',
          min: f.rangeMin || initialValues?.range?.min || 0,
          max: f.rangeMax || initialValues?.range?.max || 0,
        };
      }

      saveItemProp(x);
    }

    function convertToInitialState(f: any) {
      return {
        classifiers:
          f.classifiers?.map((x) => {
            newClassifers.find((y) => y.id === x.name);
            if (newClassifers) {
              return newClassifers;
            }

            return { id: '' };
          }) || [],
        name: _.kebabCase(f.name),
        description: f.description,
        valueType: f.valueType,
        type: f.type,
        mandatory: f.required ? 'yes' : 'no',
        selectOptions: f.selectOptions,
        searchSelectOptions: f.searchSelectOptions,
        childrenProperties: f.childrenProperties,
        childrenListProperties: f.childrenListProperties,
        complexArrayObjectProperties: f.complexArrayObjectProperties,
        placeholder: f.placeholder,
        rangeType: f.range?.type || '',
        rangeMin: f.range?.min || 0,
        rangeMax: f.range?.max || 0,
      };
    }
    const Form = renderFormV2({
      button: {
        avoidSubmitBehavior,
      },
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          required: true,
          label: 'Name',
          readonly: mode === 'edit',
        },
        {
          property: 'description',
          type: 'input',
          label: 'Description',
        },
        {
          property: 'valueType',
          type: 'select',
          label: 'Value Type',
          options: [
            { text: 'Select', value: '' },
            { value: 'fixed', text: 'Fixed' },
            { value: 'derived', text: 'Derived (Formula)' },
            { value: 'variable', text: 'Variable' },
          ],
        },
        {
          property: 'type',
          type: 'select',
          readonly: mode === 'edit',
          options: [
            { text: 'Select Type', value: '' },
            { text: 'Text', value: 'input' },
            { text: 'Number', value: 'number' },
            { text: 'Date', value: 'date' },
            { text: 'Value UOM', value: 'value-uom' },
            { text: 'Range', value: 'range' },
            { text: 'Formula', value: 'formula' },
            { text: 'Select', value: 'select' },
            { text: 'Searchable Select', value: 'searchable-select' },
            { text: 'Image', value: 'image' },
            { text: 'Object', value: 'object' },
          ],
          required: true,
          label: 'Type',
        },
        {
          groupName: 'Range',
          property: 'rangeType',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Single', value: 'single' },
            { text: 'Double', value: 'double' },
          ],
          dependentOn: (formData) => {
            return formData.type === 'range';
          },
        },
        {
          groupName: 'Range',
          property: 'rangeMin',
          type: 'number',
          dependentOn: (formData) => {
            return formData.type === 'range';
          },
        },
        {
          groupName: 'Range',
          property: 'rangeMax',
          type: 'number',
          dependentOn: (formData) => {
            return formData.type === 'range';
          },
        },
        {
          property: 'placeholder',
          type: 'input',
          label: 'Placeholder',
          dependentOn: (formData) => {
            return formData.type === 'input';
          },
        },
        {
          property: 'selectOptions',
          type: 'tags',
          label: 'Select Options',
          dependentOn: (formData) => {
            return formData.type === 'select';
          },
        },
        {
          property: 'searchSelectOptions',
          type: 'select',
          label: 'Select Searchable Options',
          options: [
            { text: 'Select Search Type', value: '' },
            { text: 'Metrics', value: 'useMetrics' },
            { text: 'GST', value: 'useGST' },
            { text: 'Appearance', value: 'useAppearances' },
            { text: 'Color', value: 'useColors' },
            { text: 'Odour', value: 'useOdours' },
            { text: 'Employee', value: 'useEmployees' },
          ],
          dependentOn: (formData) => {
            return formData.type === 'searchable-select';
          },
        },
        {
          width: 'w-full',
          property: 'childrenProperties',
          type: 'jsx',
          dependentOn: (formData) => {
            return formData.type === 'object';
          },
          renderObject: ({ formData, field, onSave }) => {
            return (
              <div className='p-1 border border-gray-200 rounded-sm'>
                <ObjectRender
                  level={level}
                  formData={formData}
                  field={field.property}
                  onSave={onSave}
                />
              </div>
            );
          },
        },
        {
          property: 'mandatory',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
          required: true,
          label: 'Mandatory',
        },
      ],
      onSubmit: convertToItemProps,
      initialFormState: convertToInitialState(initialValues),
      mapTToU: (r) => {
        return r;
      },
      style: 'mb-4 w-1/3',
    });
    return <Form />;
  };
}
