/* eslint-disable no-unused-vars */
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import {
  CompanyGroupSetting,
  CompanyGroupSettingDetails,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  AdvancedLoadingButton,
  LoadingButton,
} from '@erp_core/erp-ui-components';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { UseCompanyGroupSetting } from '../../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseLocations } from '../../../../../hooks/admin/location-admin/use-locations';

export type RenderEditBomNamePatternFormProps = {
  closeModal: () => void;
  useCompanyGroupSetting: UseCompanyGroupSetting;
  useLocations: UseLocations;
  refreshData: () => void;
};

export function renderSisterLocationForm({
  closeModal,
  refreshData,
  useCompanyGroupSetting,
  useLocations,
}: RenderEditBomNamePatternFormProps) {
  return function ({
    comGrpSetDetails,
    companyGroupId,
  }: {
    comGrpSetDetails: CompanyGroupSettingDetails;
    companyGroupId: string;
  }): JSX.Element {
    const sisterLocations =
      comGrpSetDetails.companyGroup?.location?.sisterLocations || {};
    const keys = Object.keys(sisterLocations);
    const { syncSet: setCompanyGroupSetting } = useCompanyGroupSetting();

    const save = async (locationName: string, data: MergeLocation) => {
      const finalData: CompanyGroupSetting = {
        details: {
          companyGroup: {
            ...comGrpSetDetails?.companyGroup,
            location: {
              ...comGrpSetDetails?.companyGroup?.location,
              sisterLocations: {
                ...sisterLocations,
                [locationName.toLowerCase()]: data,
              },
            },
          },
        },
      } as CompanyGroupSetting;
      await setCompanyGroupSetting(finalData);
      closeModal();
      refreshData();
    };

    const url = `https://erp-public-data.s3.ap-south-1.amazonaws.com/${companyGroupId}/`;
    return (
      <div>
        <div>
          <AdvancedLoadingButton
            text='Configure new sister Location'
            defaultStyle='bg-blue-500 hover:bg-blue-600 font-bold text-white p-2 rounded shadow'
            behaviour='modal'
            modal={{
              title: 'Add New Sister location',
              content: ({ onClose }) => {
                return (
                  <AddNewSisterLocation
                    useLocations={useLocations}
                    onSave={save}
                  />
                );
              },
            }}
          />
        </div>

        <div className='py-2 mt-2'>
          <h3>{keys.length} Sister Locations found</h3>
          {keys.length === 0 ? (
            <div className='flex flex-col justify-center items-center'>
              <MagnifyingGlassIcon className='text-gray-500 w-14 h-14' />
              <div>No sister locations available</div>
            </div>
          ) : null}
          {keys.map((name, index) => {
            const obj = sisterLocations[name];
            return (
              <div className='p-2 border my-1'>
                <div>
                  <span className='font-bold'>Location Name:</span> {name}
                </div>
                <div className='p-2'>
                  {obj.map((o) => {
                    return (
                      <div className=''>
                        {o.company?.name}{' '}
                        <img
                          className='h-6 inline-block'
                          src={`${url}${o.company?.id}.png`}
                          alt={o.company?.name}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
}

const useDebouncedValue = (value: string) => {
  const [debounced, setDebounced] = useState(value);
  useEffect(() => {
    const t = setTimeout(() => setDebounced(value), 500);
    return () => clearTimeout(t);
  }, [value]);
  return debounced;
};

type MergeLocation = {
  id: string;
  company: { id: string; name: string };
  location: { id: string; name: string };
}[];
const AddNewSisterLocation = ({
  useLocations,
  onSave,
}: {
  useLocations: UseLocations;
  onSave: (locationName: string, data: MergeLocation) => void;
}) => {
  const [name, setName] = useState('');
  const { data: locations, getAll, loading } = useLocations();
  const locationName = useDebouncedValue(name);

  useEffect(() => {
    getAll({ name: `equal::${locationName}`, companyId: 'all', type: 'real' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationName]);

  const submit = () => {
    if (locations && locations.length > 1) {
      const final: MergeLocation = locations.map((l) => {
        return {
          id: `${l.id}-${l.company.id}`,
          location: { id: l.id, name: l.name },
          company: { id: l.company.id, name: l.company.name },
        };
      });
      onSave(locationName.toLowerCase(), final);
    }
  };

  return (
    <div>
      <div className='font-bold'>
        Location name should be same to create relation between locations.
      </div>
      <div>Enter location name</div>
      <input
        className='border border-gray-400'
        type='text'
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <div className='min-h-[120px] p-2 border'>
        {!locations || locations.length === 0 ? (
          <div className='w-full h-full flex flex-col justify-center items-center'>
            {loading ? (
              <Recat className='h-5 inline animate-pulse mx-4' />
            ) : (
              <div className='flex flex-col justify-center items-center'>
                <MagnifyingGlassIcon className='w-14 text-gray-500 h-14' />
                <div>No results</div>
              </div>
            )}
          </div>
        ) : (
          locations.map((l) => {
            return (
              <div key={l.id}>
                {l.name} in {l.company.name}{' '}
                <img
                  className='h-6 inline-block'
                  src={l.company.logo}
                  alt={l.company.name}
                />
              </div>
            );
          })
        )}
        {locations && locations.length > 1 ? (
          <LoadingButton
            behaviorFn={async () => submit()}
            defaultStyle='bg-blue-500 p-2 hover:bg-blue-600 text-white font-bold rounded'
            text='Create Relation'
          />
        ) : null}
      </div>
    </div>
  );
};
