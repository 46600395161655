import {
  Company,
  CompanyGroupSetting,
} from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import moment from 'moment';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';

export type ManualPunchesEmployeeType = {
  date: string;
  type: TypePattern;
  employee: { id: string; name: string };
  reason: string;
  createdBy: IdName;
  location: IdName;
};
type TypePattern = 'check-in' | 'check-out' | 'not-determined';

type RenderManualPunchesEmployeeForm = {
  useEmployeeProfiles: UseEmployeeProfiles;

  defaultData?: {
    employee?: EmployeeProfileType;
    date: string;
  };
};

type Props = {
  onSave: (data: Partial<ManualPunchesEmployeeType>) => Promise<void>;
  companyGroupSetting?: CompanyGroupSetting;
  currentCompany: Company;
};

export const renderManualPunchesEmployeeForm = ({
  useEmployeeProfiles,
  defaultData,
}: RenderManualPunchesEmployeeForm) => {
  return function ManualPunchesEmployeeForm({
    onSave,
    companyGroupSetting,
    currentCompany,
  }: Props) {
    const sisLocations =
      companyGroupSetting?.details.companyGroup?.location?.sisterLocations ||
      {};

    const sisLocationNames = Object.keys(sisLocations);

    const formProps: FormProps<Partial<ManualPunchesEmployeeType>> = {
      fieldsData: [
        { property: 'date', type: 'datetime-local', label: 'Date' },
        {
          property: 'employee',
          type: defaultData?.employee ? 'input' : 'searchable-select',
          label: 'Employee',
          readonly: defaultData?.employee ? true : false,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            onSearchValueSelect: () => {},
          },
        },
        {
          property: 'location',
          type: 'select',
          label: 'Location',
          options: [
            { value: '', text: 'select' },
            ...sisLocationNames.map((s) => ({ text: s, value: s })),
          ],
          required: true,
        },
        {
          property: 'type',
          type: 'select',
          label: 'Type',
          required: true,
          options: [
            { text: 'select', value: '' },
            { text: 'check in', value: 'check-in' },
            { text: 'check out', value: 'check-out' },
          ],
        },
        {
          property: 'reason',
          label: 'Reason',
          type: 'input',
          required: true,
        },
      ],
      initialFormState: {
        date:
          moment(`${defaultData?.date}T09:00:00`).format('YYYY-MM-DDTHH:MM') ||
          '',
        employee: defaultData?.employee
          ? defaultData.employee.name
          : { id: '', name: '' },
        type: '',
        location: defaultData?.employee?.details.workLocation || '',
        reason: '',
      },
      allowUnchanged: true,
      onSubmit: async (data) => {
        if (defaultData?.employee) data.employee = defaultData.employee;

        if (data.location) {
          const location = sisLocations[(data.location as unknown) as string];
          if (location) {
            const myLocation = location.find(
              (l) => l.company?.id === currentCompany.id
            );
            if (myLocation) {
              data.location = {
                id: myLocation?.location?.id || '',
                name: myLocation?.location?.name || '',
              };
            }
          }
        }
        await onSave(data);
      },
      mapTToU: (b) => b as ManualPunchesEmployeeType,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<Partial<ManualPunchesEmployeeType>>(formProps);

    return <Form />;
  };
};
