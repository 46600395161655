/* eslint-disable no-unused-vars */
import { useState } from 'react';
import {
  BaseResourceType,
  FilterType,
  PaginatedData,
  SimpleResourceInterface,
} from '../../models/interfaces/generic-resource';

export type UsePaginatedResources<T, U> = () => {
  data?: PaginatedData<T>;
  getWithPagination: (filter?: U) => void;
  loading: boolean;
};

export interface UseGetAllResources<V> {
  useService: () => V;
}

export function createUsePaginatedResources<
  T extends BaseResourceType,
  U extends FilterType,
  V extends SimpleResourceInterface<T, U>
>({ useService }: UseGetAllResources<V>): UsePaginatedResources<T, U> {
  return () => {
    const resourceAdminService = useService();
    const [loading, setLoading] = useState<boolean>(false);
    const [paginatedData, setPaginatedData] = useState<PaginatedData<T>>({
      metaInfo: {
        currentPage: 1,
        totalCount: 0,
        totalPages: 1,
      },
      data: [],
    });

    const apiCall = async (filter?: U) => {
      setLoading(true);
      const r = (await resourceAdminService.getAll(filter)) as PaginatedData<T>;
      setPaginatedData(r);
      setLoading(false);
    };

    // useEffect(() => { apiCall(); }, []);

    return {
      data: paginatedData,
      getWithPagination: (filter?: U) => {
        apiCall(filter);
      },
      loading,
    };
  };
}
