import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseLocationAdmin } from '../../../../hooks/admin/location-admin/use-location-admin';
import { renderLocationProfile } from './location-details';

export type RenderLocationDetailsProp = {
  hooks: {
    useLocationAdmin: UseLocationAdmin;
  };
};

export function renderLocationDetails({
  hooks: { useLocationAdmin },
}: RenderLocationDetailsProp): () => JSX.Element {
  return function LocationDetails(): JSX.Element {
    const { id } = useParams();
    const TabsV2 = renderTabsV2Component();
    const { data, get: getLocationDetail, loading } = useLocationAdmin();
    const Card = renderCardComponent();

    useEffect(() => {
      if (id) {
        getLocationDetail(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const header: CardHeader = {
      title: `${data.name || id}`,
    };

    const LocationDetails = renderLocationProfile({ loading });

    const newCategories: DataArray = [
      {
        name: 'location-profile',
        Fn: () => (
          <div>
            <LocationDetails location={data} />
          </div>
        ),
      },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: <TabsV2 data={newCategories} manipulateLocation={true} />,
    };

    return <Card header={header} body={body} />;
  };
}
