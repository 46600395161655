import { LeadCustomerEnquiryType } from '@erp_core/erp-types/dist/modules/order';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseItems } from '../../../../hooks/inventory/item/use-items';
import { UseLeadCustomer } from '../../../../hooks/order/sales/lead-customer/use-lead-customer';
import { UseLeadCustomers } from '../../../../hooks/order/sales/lead-customer/use-lead-customers';
import { CategoryIcons } from '../../../inventory/items/item-categories';

export type SalesLeadFields = {
  date: string;
  leadGeneratedBy: IdName;
  assignedTo: IdName;
  type: string;
  stage: string;
  customer: IdName;
  contact: string;
  product: IdName;
  description: string;
  currentSupplier: string;
  targetPrice: string;
  exportDataPrice: string;
  prevailPrice: string;
};

type Props = { onSave: any; leadEnqdata?: LeadCustomerEnquiryType };
export function renderAddSalesLeadForm({
  useItems,
  useEmployeeProfiles,
  useLeadCustomers,
  useLeadCustomer,
}: {
  useItems: UseItems;
  useEmployeeProfiles: UseEmployeeProfiles;
  useLeadCustomer: UseLeadCustomer;
  useLeadCustomers: UseLeadCustomers;
}): (props: Props) => JSX.Element {
  return function AddSalesLeadForm({
    onSave,
    leadEnqdata,
  }: Props): JSX.Element {
    const initialOptions = [{ value: '', text: 'Select' }];
    const incomingCustomer = leadEnqdata?.leadCustomer || undefined;
    const [customer, setCustomer] = useState<IdName | undefined>(
      incomingCustomer
    );
    const { get: getLeadCustomer, data: leadCustomerData } = useLeadCustomer();

    useEffect(() => {
      if (customer?.id) {
        getLeadCustomer(customer?.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    const Form1 = renderFormV2({
      fieldsData: [
        {
          property: 'customer',
          type: 'searchable-select',
          label: 'Customer',
          required: true,
          searchOptions: {
            useSearch: useLeadCustomers,
            onSearchValueSelect: (e) => {},
          },
        },
      ],
      initialFormState: {
        customer: { id: '', name: '' },
      },
      mapTToU: (p: any) => p,
      onSubmit: async (formData) => {
        if (formData.customer.id) {
          setCustomer({
            id: formData.customer.id,
            name: formData.customer.name,
          });
        }
      },
      style: 'w-1/2',
    });

    const formProps: FormProps<SalesLeadFields> = {
      formName: `Customer Name: ${customer?.name}`,
      fieldsData: [
        {
          property: 'date',
          type: 'date',
          label: 'Date',
          required: true,
        },
        {
          property: 'leadGeneratedBy',
          type: 'searchable-select',
          label: 'Lead Generated By',
          required: true,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            onSearchValueSelect: () => {},
          },
        },
        {
          property: 'assignedTo',
          type: 'searchable-select',
          label: 'Assigned To',
          required: true,
          searchOptions: {
            useSearch: useEmployeeProfiles,
            onSearchValueSelect: () => {},
            //Note: Here filter to be on Sales & Marketing, Business Dev.
          },
        },
        {
          property: 'type',
          type: 'select',
          required: true,
          label: 'Type',
          options: [
            { text: 'Select', value: '' },
            { text: 'Existing Business', value: 'Existing Business' },
            { text: 'New Business', value: 'New Business' },
          ],
        },
        {
          property: 'stage',
          type: 'select',
          label: 'Stage',
          required: true,
          readonly: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'Lead Generated', value: 'Lead Generated' },
            { text: 'Contacted', value: 'Contacted' },
            { text: 'Meeting Scheduled', value: 'Meeting Scheduled' },
            { text: 'Sample Sent', value: 'Sample Sent' },
            { text: 'Qualified to Buy', value: 'Qualified to Buy' },
            {
              text: 'Won-Converted to Enquiry',
              value: 'Won -Converted to Enquiry',
            },
            { text: 'Disqualified', value: 'Disqualified' },
            { text: 'Closed Lost', value: 'Closed Lost' },
          ],
        },
        {
          property: 'contact',
          type: 'select',
          label: 'Contact',
          required: true,
          options: [
            ...initialOptions,
            ...(leadCustomerData?.details?.contact?.map((m) => ({
              value: m.name,
              text: m.name,
            })) || []),
          ],
        },
        {
          property: 'product',
          type: 'searchable-select',
          label: 'Product(Grade)',
          required: true,
          searchOptions: {
            useSearch: useItems,
            onSearchValueSelect: () => {},
            searchOptionsBody: {
              customBody(data) {
                return (
                  <div>
                    {data.categories?.map((x) => (
                      <CategoryIcons category={x.category} key={x.id} />
                    ))}
                    {data?.name}
                  </div>
                );
              },
            },
            // Note: Further going it should
            // accept more values of further
            // eg. FA/FG, FG/WIP (short-form which has FG in it)
            filter: { category: 'FG' },
          },
        },
        {
          property: 'description',
          type: 'input',
          label: 'Description',
          required: true,
        },
        {
          property: 'currentSupplier',
          type: 'input',
          label: 'Current Supplier',
          // required: true,
        },
        // TODO: Update when type number will have suffix currency type
        {
          property: 'targetPrice',
          type: 'number',
          label: 'Target Price',
          // required: true,
        },
        {
          property: 'exportDataPrice',
          type: 'number',
          label: 'Price from Export Data',
          // required: true,
        },
        {
          property: 'prevailPrice',
          type: 'number',
          label: 'Prevailing price in India Market',
          // required: true,
        },
        //
      ],
      initialFormState: {
        date: leadEnqdata?.details?.date || moment().format('YYYY-MM-DD') || '',
        leadGeneratedBy: leadEnqdata?.generatedBy || { id: '', name: '' },
        assignedTo: leadEnqdata?.assignedTo || { id: '', name: '' },
        type: leadEnqdata?.details?.type || '',
        stage: leadEnqdata?.details?.stage || 'Lead Generated' || '',
        customer: { id: customer?.id || '', name: customer?.name || '' } ||
          leadEnqdata?.leadCustomer || { id: '', name: '' },
        contact: leadEnqdata?.details?.customerContact || '',
        product: {
          id: leadEnqdata?.enquiryItems[0].item?.id || '',
          name: leadEnqdata?.enquiryItems[0].item?.name || '',
        },
        description: leadEnqdata?.details?.description || '',
        currentSupplier: leadEnqdata?.details?.currentSupplier || '',
        targetPrice: leadEnqdata?.details?.targetPrice || '',
        exportDataPrice: leadEnqdata?.details?.exportDataPrice || '',
        prevailPrice: leadEnqdata?.details?.priceAvail || '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      allowUnchanged: true,
    };
    const NewForm = renderFormV2<SalesLeadFields>(formProps);

    if (!customer) {
      return <Form1 />;
    }

    return <NewForm />;

    function mapPFDToP(b: FormDataType<SalesLeadFields>): SalesLeadFields {
      return (b as unknown) as SalesLeadFields;
    }
  };
}
