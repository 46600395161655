import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { genericConfigSelector } from '@erp_core/erp-utils';

export async function isEmployeeTrackable({
  employee,
  companyGroupSetting,
}: {
  employee: EmployeeProfileType;
  companyGroupSetting: CompanyGroupSetting;
}): Promise<boolean> {
  let attendanceTrackConfig = await genericConfigSelector({
    // getCompanyGroupSettings,
    resourceConfigGetter: async () => {
      if (employee.details.attendanceRules?.trackPunches == null) return null; // undefined == null is true
      return employee.details.attendanceRules?.trackPunches === false;
    },
    companyGroupConfigGetter: async () => {
      return !companyGroupSetting?.details.hrd?.attendanceRules.trackPunches?.categories?.find(
        (rule) =>
          employee.details.grade && rule?.id === employee.details.grade.id
      );
    },
  });

  return attendanceTrackConfig?.value || false;
}
