import { ItemRes, Revision } from '@erp_core/erp-types/dist/modules/inventory';
import { ItemProperty } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { useEffect, useState } from 'react';
import { UseAppearances } from '../../../../../hooks/admin/constants/appearance/use-appearances';
import { UseColors } from '../../../../../hooks/admin/constants/color/use-colors';
import { UseGsts } from '../../../../../hooks/admin/constants/gst/use-gsts';
import { UseMetrics } from '../../../../../hooks/admin/constants/metrics/use-metrics';
import { UseOdours } from '../../../../../hooks/admin/constants/odour/use-odours';
import { UseCurrentUserRoles } from '../../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseRoles } from '../../../../../hooks/admin/role-admin/use-roles-admin';
import { UseUsers } from '../../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UseEmployeeProfiles } from '../../../../../hooks/hrd/employee/profile/use-employee-profiles';
import { UseRevision } from '../../../../../hooks/inventory/revision/use-revision';
import { UseRevisions } from '../../../../../hooks/inventory/revision/use-revisions';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';
import { renderProperties } from '../../../properties/properties';

export type NewPropsProp = {
  useMetrics: UseMetrics;
  useGsts: UseGsts;
  useAppearances: UseAppearances;
  useOdours: UseOdours;
  useColors: UseColors;
  useEmployees: UseEmployeeProfiles;
  useCurrentUserRoles: UseCurrentUserRoles;
  useRevision: UseRevision;
  useRevisions: UseRevisions;
  useRoles: UseRoles;
  useUsers: UseUsers;
  itemService: ItemInterface;
  item: ItemRes;
  setItem: (s: ItemRes) => Promise<ItemRes>;
  setRevision: (r: Revision) => Promise<Revision>;
  useFileTransfer: UseFileTransfer;
};

export function renderPropertiesTab(): (x: NewPropsProp) => JSX.Element {
  return function NewProps({
    itemService,
    item,
    setItem,
    useMetrics,
    useGsts,
    useAppearances,
    useColors,
    useOdours,
    useCurrentUserRoles,
    useRevisions,
    useRoles,
    useUsers,
    setRevision,
    useFileTransfer,
    useEmployees,
  }: NewPropsProp): JSX.Element {
    const Properties = renderProperties<ItemRes>({
      useMetrics,
      useGsts,
      useAppearances,
      useColors,
      useOdours,
      useCurrentUserRoles,
      useRevisions,
      useFileTransfer,
      useRoles,
      useUsers,
      useEmployees,
    });
    const [currentTab, setCurrentTab] = useState<string>('');
    const [tabs, setTabs] = useState<Array<string>>([]);
    const [body, setBody] = useState<JSX.Element>(<div></div>);
    const [properties, setProperties] = useState<Array<ItemProperty>>([]);

    useEffect(() => {
      let tabs: Array<string> = [];
      properties.forEach((p) => {
        p?.classifiers?.forEach((c) => {
          if (c.name && !tabs.includes(c.name)) {
            tabs.push(c.name);
          }
        });
      });

      tabs = [
        // 'Master',
        ...tabs.sort(),
        'Draft',
      ];

      setTabs(tabs);

      if (tabs.length) {
        setCurrentTab(tabs[0]);
      }
      // eslint-disable-next-line
    }, [properties]);

    useEffect(() => {
      if (item?.id) {
        itemService.getProperties(item.id).then((c) => {
          setProperties(c);
        });
      }
      // eslint-disable-next-line
    }, [item]);

    useEffect(() => {
      if (currentTab) {
        let Props = <div></div>;

        switch (currentTab) {
          case 'Master': {
            Props = (
              <Properties
                renderOptions={{
                  renderAuth: true,
                  renderAdd: false,
                  renderEditValue: true,
                }}
                entityService={itemService}
                entity={item}
                setEntity={setItem}
                type='item'
                setRevision={setRevision}
              />
            );
            break;
          }

          default: {
            Props = (
              <Properties
                renderOptions={{
                  renderAuth: true,
                  classifier: currentTab,
                  renderAdd: false,
                  renderEditValue: true,
                }}
                entityService={itemService}
                entity={item}
                setEntity={setItem}
                type='item'
                setRevision={setRevision}
              />
            );
          }
        }

        setBody(Props);
      }
      // eslint-disable-next-line
    }, [currentTab, item]);

    const selectedTabStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 bg-white shadow text-blue-700 border-b-2 border-b-blue-600';
    const nonSelectedStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 hover:bg-white/[0.12] hover:border-b-2 hover:border-b-gray-400 hover:text-blue-700';

    return (
      <div>
        <div className='flex w-full'>
          {tabs.map((x, idx) => (
            <div
              key={x || idx}
              onClick={() => {
                if (x !== currentTab) {
                  setCurrentTab(x);
                }
              }}
              className={currentTab === x ? selectedTabStyle : nonSelectedStyle}
            >
              {x}
            </div>
          ))}
        </div>
        <div className='w-full'>
          <div className='w-full'>{body}</div>
        </div>
      </div>
    );
  };
}
