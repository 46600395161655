import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { useContext } from 'react';
import { UseCompanies } from '../../../../../hooks/admin/company-admin/use-companies-admin';
import { UseEmployeeCategories } from '../../../../../hooks/admin/constants/employee-category/use-employee-categories';
import { UseUserAuthorization } from '../../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../../../../hooks/file-transfer/use-public-transfer';
import { UseDepartments } from '../../../../../hooks/hrd/department/use-departments';
import { UseDocument } from '../../../../../hooks/hrd/employee-registration-docs/use-document';
import { UseDocuments } from '../../../../../hooks/hrd/employee-registration-docs/use-documents';
import { UseEmployeeRegistration } from '../../../../../hooks/hrd/employee-registration-dtls/use-registration';
import { UseEmployeeRegistrations } from '../../../../../hooks/hrd/employee-registration-dtls/use-registrations';
import { UseSalaryParams } from '../../../../../hooks/hrd/salary-parameter/use-salary-params';
import { renderEmployeeTimeLine } from '../../employee-timeline';
import { renderBankDetails } from './bank-details';
import { renderContactDetails } from './contact-details';
// import { renderDressCode } from './dress-code';
import { CurrentContext } from '../../../../../contexts/current';
import { UseEmployeeEducation } from '../../../../../hooks/hrd/employee-education-dtls/use-employee-education';
import { UseEmployeeEducations } from '../../../../../hooks/hrd/employee-education-dtls/use-employee-educations';
import { UseEmployeeFamilies } from '../../../../../hooks/hrd/employee-family-dtls/use-employee-families';
import { UseEmployeeFamily } from '../../../../../hooks/hrd/employee-family-dtls/use-employee-family';
import { UseShiftSchedules } from '../../../../../hooks/planning/shift-schedule/use-shift-schedules';
import { UserRendererInterface } from '../../../../common/fragments/user';
import { renderEmployeeEducationDetails } from './education-details/employee-education-details';
import { renderEmployeeProfile1 } from './employee-profile';
import { renderEmployeeFamilyDetails } from './family/employee-family-details';
import { renderPersonalDetails } from './personal-details';
import { renderEmployeeRegistrationDetails } from './registration-detail/employee-registration-details';

type CreateEmployeeProfileCardType = {
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
  useUserAuthorization: UseUserAuthorization;
  useEmployeeCategories: UseEmployeeCategories;
  id: string;
  useCompanies: UseCompanies;
  useSalaryParams: UseSalaryParams;
  useEmployeeRegistrations: UseEmployeeRegistrations;
  useEmployeeRegistration: UseEmployeeRegistration;
  useDocument: UseDocument;
  useDocuments: UseDocuments;
  useDepartments: UseDepartments;
  useEmployeeEducations: UseEmployeeEducations;
  useEmployeeEducation: UseEmployeeEducation;
  useEmployeeFamilies: UseEmployeeFamilies;
  useEmployeeFamily: UseEmployeeFamily;
  useShiftSchedules: UseShiftSchedules;
  userRendererService: UserRendererInterface;
  isHr: boolean;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
};

type Props = {
  data: EmployeeProfileType;
  loading: boolean;
  setEmployee: (s: EmployeeProfileType) => void;
};

export function renderEmployeeProfile({
  useFileTransfer,
  usePublicTransfer,
  useEmployeeCategories,
  useUserAuthorization,
  useCompanies,
  useSalaryParams,
  useEmployeeRegistrations,
  useEmployeeRegistration,
  useEmployeeEducations,
  useEmployeeEducation,
  useEmployeeFamilies,
  useEmployeeFamily,
  useDocument,
  useDocuments,
  id,
  useDepartments,
  isHr,
  actions,
  userRendererService,
  useShiftSchedules,
}: CreateEmployeeProfileCardType): (p: Props) => JSX.Element {
  const BankDetails = renderBankDetails(useFileTransfer);
  const Profile = renderEmployeeProfile1({
    useShiftSchedules,
    usePublicTransfer,
    useEmployeeCategories,
    useUserAuthorization,
    useCompanies,
    useSalaryParams,
    useDepartments,
  });

  const EmployeeRegistration = renderEmployeeRegistrationDetails();

  const EmployeeEducations = renderEmployeeEducationDetails();

  const EmployeeFamilies = renderEmployeeFamilyDetails();

  const PersonalDetails = renderPersonalDetails();

  const ContactDetails = renderContactDetails();

  // const DressCode = renderDressCode();

  return function EmployeeProfile({
    data,
    setEmployee,
    loading,
  }: {
    data: EmployeeProfileType;
    setEmployee: (s: EmployeeProfileType) => void;
    loading: boolean;
  }): JSX.Element {
    const Timeline = renderEmployeeTimeLine({
      data,
      useUserAuthorization,
    });
    const { cgSetting } = useContext(CurrentContext);

    return (
      <>
        {' '}
        <div>
          <Profile
            isHr={isHr}
            emp={data}
            loading={loading}
            setEmployee={setEmployee}
            companyGroupSetting={cgSetting}
            id={id}
          />
        </div>
        <br />
        <div>
          <PersonalDetails
            setEmployee={setEmployee}
            employee={data}
            actions={actions}
          />
        </div>
        <br />
        <div>
          <Timeline setEmployee={setEmployee} />
        </div>
        <br />
        <div>
          <ContactDetails
            setEmployee={setEmployee}
            employee={data}
            isHr={isHr}
            actions={actions}
          />
        </div>
        <br />
        <br />
        <div>
          <EmployeeFamilies
            userRendererService={userRendererService}
            actions={actions}
            employee={data}
            useEmployeeFamilies={useEmployeeFamilies}
            useEmployeeFamily={useEmployeeFamily}
            useUserAuthorization={useUserAuthorization}
          />
        </div>
        <br />
        <div>
          <EmployeeEducations
            userRendererService={userRendererService}
            employee={data}
            isHr={isHr}
            useEmployeeEducations={useEmployeeEducations}
            useEmployeeEducation={useEmployeeEducation}
            useFileTransfer={useFileTransfer}
            useUserAuthorization={useUserAuthorization}
          />
        </div>
        <br />
        {/* <div>
          <DressCode employee={data} setEmployee={setEmployee} />
        </div> */}
        <br />
        <div>
          <EmployeeRegistration
            userRendererService={userRendererService}
            isHr={isHr}
            employee={data}
            actions={actions}
            useUserAuthorization={useUserAuthorization}
            useEmployeeRegistrations={useEmployeeRegistrations}
            useEmployeeRegistration={useEmployeeRegistration}
            useDocument={useDocument}
            useFileTransfer={useFileTransfer}
            useDocuments={useDocuments}
          />
        </div>
        <br />
        {/* <div>{data.id ? <Insurance id={data.id} /> : null}</div> */}
        <br />
        <div>
          {data.id ? (
            <BankDetails
              isHr={isHr}
              useUserAuthorization={useUserAuthorization}
              actions={actions}
              id={data.id}
              setEmployee={setEmployee}
              employee={data}
            />
          ) : null}
        </div>
      </>
    );
  };
}

export function empFullName(data) {
  return `${data.details?.fullName?.prefix}  ${data.details?.fullName?.firstName}  ${data.details?.fullName?.middleName}  ${data.details?.fullName?.lastName}`;
}

export function empPermanentAddress(data) {
  return `${data?.details?.contactDetails?.permanentAddress?.addressLine1 || ''}
  ${data?.details?.contactDetails?.permanentAddress?.addressLine2 || ''} ${
    data?.details?.contactDetails?.permanentAddress?.city || ''
  } ${data?.details?.contactDetails?.permanentAddress?.pincode || ''} 
  ${data?.details?.contactDetails?.permanentAddress?.district || ''} ${
    data?.details?.contactDetails?.permanentAddress?.state || ''
  }`;
}

export function empCurrentAddress(data) {
  return `${data?.details?.contactDetails?.currentAddress?.addressLine1 || ''}
  ${data?.details?.contactDetails?.currentAddress?.addressLine2 || ''} ${
    data?.details?.contactDetails?.currentAddress?.city || ''
  } ${data?.details?.contactDetails?.currentAddress?.pincode || ''} 
  ${data?.details?.contactDetails?.currentAddress?.district || ''} ${
    data?.details?.contactDetails?.currentAddress?.state || ''
  } `;
}
