import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { ShiftScheduleType } from '@erp_core/erp-types/dist/modules/planning';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBodyRow,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../contexts/current';
import { UseShiftSchedule } from '../../../hooks/planning/shift-schedule/use-shift-schedule';
import { UseShiftSchedules } from '../../../hooks/planning/shift-schedule/use-shift-schedules';
import { renderShiftScheduleForm } from './forms';

export type RenderShiftScheduleV2ListProps = {
  useShiftSchedules: UseShiftSchedules;
  useShiftSchedule: UseShiftSchedule;
};

export function renderShiftScheduleV2List({
  useShiftSchedules,
  useShiftSchedule,
}: RenderShiftScheduleV2ListProps): () => JSX.Element {
  return function ShiftScheduleList(): JSX.Element {
    const { cgSetting } = useContext(CurrentContext);
    const {
      data: shiftSchedules,
      getAll: getShiftSchedules,
    } = useShiftSchedules();

    const {
      syncSet: setSchedule,
      delete: deleteSchedule,
      loading,
    } = useShiftSchedule();

    useEffect(() => {
      // TODO: Add current location
      getShiftSchedules();
      // eslint-disable-next-line
    }, []);

    const groupedShiftSchedules = _.groupBy(shiftSchedules, 'location');

    const onSaveSchedule = async (form: any) => {
      const finalData = form;
      finalData.siblingLocation = finalData.location;
      await setSchedule(finalData);
      toast('Schedule added sucessfully');
      getShiftSchedules();
    };

    // const cgsDetails = cgs?.[0]?.details;
    const keys = _.keys(
      cgSetting?.details?.companyGroup?.location?.sisterLocations || []
    );

    const onEditSchedule = async (form: any, id: string) => {
      const finalData = {
        id,
        ...form,
      };
      await setSchedule(finalData);
      toast('Schedule edited sucessfully');
      getShiftSchedules();
    };

    const Table = renderTableComponent();

    const Card = renderCardComponent();

    const getCardHeader = (title: string) => {
      const header: CardHeader = {
        title: `${title}`,
      };
      return header;
    };

    const getCardBody = (data: ShiftScheduleType[]) => {
      const tableHeader: TableHeader = [
        [
          { name: 'Name' },
          { name: 'Start time' },
          { name: 'End Time' },
          { name: 'Shift Duration' },
          { name: 'Frequency' },
          { name: 'Break Details' },
          // { name: 'Actions' },
        ],
      ];
      const tableBody: TableBodyRow[] =
        data?.map((e) => {
          const cells: Array<TableCell> = [
            { value: e?.name || '', link: `/hrd/shift-schedule/${e.id}/shift` },
            { value: e?.startTime },
            { value: e?.endTime },
            { value: e?.shiftDuration },
            { value: e.frequency },
            {
              value: (
                <>
                  {e.breakStart && e.breakEnd && e.breakName ? (
                    <>
                      <div>
                        <span className='font-semibold'>{e.breakName}:</span> (
                        {(e as any).breakDuration || '-'}mins)
                      </div>
                      <div className='italic'>
                        <span className='font-semibold'>Window:</span>{' '}
                        {e.breakStart} to {e.breakEnd}
                      </div>
                    </>
                  ) : null}
                </>
              ),
            },
          ];

          return {
            rowData: {
              shiftSchedule: e,
            },
            cells,
          };
        }) || [];
      const body: CardBody = {
        type: 'jsx-component',
        body: (
          <Table
            header={tableHeader}
            body={tableBody}
            actions={[
              {
                name: 'Edit',
                behaviour: 'modal',
                show: () => true,
                modal: {
                  title: 'Edit Schedule',
                  content: ({ data: { shiftSchedule }, onClose }) => {
                    const Form = renderShiftScheduleForm();
                    const formData = {
                      ...shiftSchedule,
                      // locations: keys.map((k) => ({ text: k, value: k })),
                    };
                    return (
                      <Form
                        onSave={async (e) => {
                          console.log(e, shiftSchedule.id);
                          e.siblingLocation = e.location;
                          await onEditSchedule(e, shiftSchedule.id);
                          onClose();
                        }}
                        locations={keys.map((k) => ({ text: k, value: k }))}
                        data={formData as any}
                        isEdit={true}
                      />
                    );
                  },
                },
              },
              {
                name: 'Delete',
                behaviour: 'confirm',
                show: () => true,
                onConfirm: ({ shiftSchedule }) => {
                  console.log(shiftSchedule);
                  return {
                    title: `Are you sure you want to delete ${shiftSchedule.name}?`,
                    onConfirm: async () => {
                      await deleteSchedule(shiftSchedule.id);
                    },
                  };
                },
              },
            ]}
          />
        ),
      };

      return body;
    };

    return (
      <div className='m-3'>
        <Card
          header={{
            title: 'Shift Schedule',
            actions: [
              {
                type: 'button',
                button: {
                  name: 'Add shift schedule',
                  behaviour: 'modal',
                  modal: {
                    title: 'Add Schedule',
                    content: ({ onClose }) => {
                      const Form = renderShiftScheduleForm();
                      const formData = {};
                      return (
                        <Form
                          onSave={async (e) => {
                            onSaveSchedule(e);
                            onClose();
                          }}
                          data={formData as any}
                          locations={keys.map((k) => ({ text: k, value: k }))}
                        />
                      );
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: loading ? (
              <Recat className='h-5 inline animate-pulse mx-4' />
            ) : (
              <div>
                {Object.entries(groupedShiftSchedules).map((gs, index) => {
                  return (
                    <div key={index} className='mb-4'>
                      <Card
                        header={getCardHeader(gs[0])}
                        body={getCardBody(gs[1])}
                      />
                    </div>
                  );
                })}
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
