import { renderHistory } from '@erp_core/erp-ui-components';
import { UseClient } from '../../../hooks/order/sales/client/use-client';

export function renderHistoryTab({
  id,
  useClient,
}: {
  id: string;
  useClient: UseClient;
}): () => JSX.Element {
  return function HistoryTab(): JSX.Element {
    const History = renderHistory();

    return <History useResource={useClient} id={id} />;
  };
}
