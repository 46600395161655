import moment from 'moment';

export function getESICApplicablility(salaryRev: any, date: string) {
  console.log(salaryRev, date);
  if (
    !salaryRev?.esicApplicability ||
    salaryRev?.esicApplicability.data.value === 'Unknown'
  ) {
    // throw new Error('Invalid Salary Revision. ESIC not computed');
    return 'No';
  }

  if (salaryRev.esicApplicability.data.until) {
    // Check date against until
    if (moment(date).isBefore(salaryRev.esicApplicability.data.until)) {
      return salaryRev.esicApplicability.data.value;
    }

    return salaryRev.esicApplicability.data.value === 'Yes' ? 'No' : 'Yes';
  }

  return salaryRev.esicApplicability.data.value;
}
