/* eslint-disable no-unused-vars */
import { ResourceType } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseModules } from '../../../hooks/admin/module-admin/use-modules-admin';
import { UseResourceAdmin } from '../../../hooks/admin/use-resource-admin/use-resource-admin';

export type RenderEditResourceFormProps = {
  useResourceAdmin: UseResourceAdmin;
  useModules: UseModules;
};

export function renderEditResourceAdminForm({
  useResourceAdmin,
  useModules,
}: RenderEditResourceFormProps): (ResourceAdminType) => JSX.Element {
  return function EditResourceForm({
    r,
    onClose,
  }: {
    r: ResourceType;
    onClose: () => void;
  }): JSX.Element {
    const { syncSet: setResourceAdmin } = useResourceAdmin();

    const saveResource = async (form) => {
      if (r.id) form.id = r.id;
      if (form.shortName) form['shortName'] = form.shortName.toUpperCase();
      await setResourceAdmin(form);
      onClose();
    };

    const formProps: FormProps<Partial<ResourceType>> = {
      fieldsData: [
        { property: 'name', label: 'Resource Name', type: 'input' },
        { property: 'shortName', label: 'Resource Short Name', type: 'input' },
        {
          type: 'searchable-select',
          label: 'Module',
          property: 'module',
          searchOptions: {
            useSearch: useModules,
            onSearchValueSelect: () => {},
          },
        },
        {
          property: 'isLocationSpecific',
          label: 'Is this resource location specific?',
          type: 'select',
          options: [
            { value: 'np', text: 'NO' },
            { value: 'yes', text: 'YES' },
          ],
        },
      ],

      initialFormState: {
        name: r.name,
        shortName: r.shortName,
        module: (r.module as unknown) as string,
        isLocationSpecific: !r.isLocationSpecific ? 'no' : 'yes',
      },
      mapTToU: mapPFDToP,
      onSubmit: saveResource,
    };

    const Form = renderFormV2<Partial<ResourceType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<ResourceType>>
    ): Partial<ResourceType> {
      return (b as unknown) as ResourceType;
    }
  };
}
