import {
  renderFileViewerUploader,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseEmployeeLetters } from '../../../../hooks/hrd/employee-letters/use-employee-letters';

export type EmployeeLetterPropType = {
  id: string;
};

export function renderEmployeeLetters({
  useEmployeeLetters,
  useFileTransfer,
}: {
  useEmployeeLetters: UseEmployeeLetters;
  useFileTransfer: UseFileTransfer;
}): (props: EmployeeLetterPropType) => JSX.Element {
  const Table = renderTableComponent();
  const FileViewerUploader = renderFileViewerUploader();

  return function EmployeeLetters({ id }: EmployeeLetterPropType): JSX.Element {
    const {
      data: employeeLetters,
      getAll: getEmployeeLetters,
    } = useEmployeeLetters();

    useEffect(() => {
      getEmployeeLetters({
        employeeId: id,
      });
      // eslint-disable-next-line
    }, [id]);

    return (
      <div>
        <Table
          header={[
            [
              { name: 'Type' },
              { name: 'Date' },
              { name: 'Document' },
              { name: 'Published On' },
            ],
          ]}
          body={(employeeLetters || []).map((x) => {
            return {
              cells: [
                { value: x.type },
                { value: x.date },
                {
                  value: (
                    <>
                      <FileViewerUploader
                        mode='view'
                        url={x.document || ''}
                        useFileTransfer={useFileTransfer}
                        path={x.document}
                        type='private'
                        onUpload={async (path) => {}}
                      />
                    </>
                  ),
                },
                { value: moment(x.createdAt).fromNow() },
              ],
            };
          })}
        />
      </div>
    );
  };
}
