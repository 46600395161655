/* eslint-disable prefer-template  */

import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseDaybooks } from '../../../hooks/accounts/use-daybooks';
import { renderBillPayable } from './bill-payable';
import { renderBillReceivable } from './bill-receivable';

type CreateBillCardType = {
  useDaybooks: UseDaybooks;
};

export function renderBill({
  useDaybooks,
}: CreateBillCardType): () => JSX.Element {
  return function Bill(): JSX.Element {
    const [bill, setBill] = useState('selectBillType');
    const [
      billReceivableContentVisible,
      setBillReceivableContentVisible,
    ] = useState(false);
    const [billPayableContentVisible, setBillPayableContentVisible] = useState(
      false
    );
    const { getAll } = useDaybooks();

    useEffect(() => {
      getAll();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
      bill === 'Bills-Payable'
        ? setBillPayableContentVisible(true)
        : setBillPayableContentVisible(false);
      bill === 'Bills-Receivable'
        ? setBillReceivableContentVisible(true)
        : setBillReceivableContentVisible(false);
    }, [bill]);

    const handleOnChange = (e) => {
      setBill(e.target.value);
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Bills',
      actions: [],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <div className='container border-b mt-3'>
            <div className='mt-4'>
              <select
                className='border-collapse border-black border p-2  ml-2 mb-2'
                value={bill}
                onChange={handleOnChange}
              >
                <option value='selectBillType'>
                  <b>Select Bill Type</b>
                </option>
                <option value='Bills-Payable'>Bills-Payable</option>
                <option value='Bills-Receivable'>Bills-Receivable</option>
              </select>
            </div>
            {billReceivableContentVisible &&
              renderBillReceivable({
                useDaybooks,
              })}
            {billPayableContentVisible && renderBillPayable({ useDaybooks })}
          </div>
        </>
      ),
    };

    return <Card header={cardHeader} body={cardBody} />;
  };
}
