/* eslint-disable no-unused-vars */
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseEmployeeProfiles } from '../../../../hooks/hrd/employee/profile/use-employee-profiles';

type Props = {
  data: {};
  onSave: any;
};

// eslint-disable-next-line
export function renderAddImplementationCompletionForm({
  useEmployees,
}: {
  useEmployees: UseEmployeeProfiles;
}): (props: Props) => JSX.Element {
  return function AddAddImplementationCompletionForm({
    data,
    onSave,
  }: Props): JSX.Element {
    const Form = renderFormV2({
      mapTToU: (f) => f,
      onSubmit: async (form) => {
        onSave(form);
      },
      initialFormState: {
        documents: '',
        responsibleperson: { id: '', name: '' },
        qadocs: '',
      },
      fieldsData: [
        {
          property: 'documents',
          label: 'Documents',
          type: 'input',
          // multiFields: [
          //   {
          //     name: 'list',
          //     label: 'List',
          //     type: 'text',
          //   },
          //   {
          //     name: 'numberOldDoc',
          //     label: 'Number (Old document)',
          //     type: 'text',
          //   },
          //   {
          //     name: 'versionOldDoc',
          //     label: 'Version (Old document)',
          //     type: 'text',
          //   },
          //   {
          //     name: 'numberNewDoc',
          //     label: 'Number (New document)',
          //     type: 'text',
          //   },
          //   {
          //     name: 'versionNewDoc',
          //     label: 'Version (New document)',
          //     type: 'text',
          //   },

          //   {
          //     name: 'effectiveDate',
          //     label: 'Effective date',
          //     type: 'date',
          //   },
          // ],
        },
        {
          property: 'responsibleperson',
          label: 'Training to responsible persons completed on:',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useEmployees,
            onSearchValueSelect: (v) => {
              console.log(v);
            },
          },
        },
        {
          property: 'qadocs',
          label: 'Which documents are submitted to QA with CCF?',
          type: 'input',
        },
      ],
    });
    return <Form />;
  };
}
